
import { recruitmentsTypes } from "../../ActionTypes/recruitmentsTypes";
import { RecruitmentsActions, RecruitmentsState } from "../../types/type";

const initialState: RecruitmentsState = {
  pending: false,
  recruitments: [],
  error: null,
};

export default (state = initialState, action: RecruitmentsActions,) => {
  switch (action.type) {
    case recruitmentsTypes.FETCH_RECRUIMENTS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitmentsTypes.FETCH_RECRUIMENTS_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitments: action.payload.recruitments,
        error: null
      };
    case recruitmentsTypes.FETCH_RECRUIMENTS_FAILURE:
      return {
        ...state,
        pending: false,
        recruitments: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
