import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchMatomeListFailure, fetchMatomeListSuccess } from "../../actions/matomeListActions/matomeListActions";
import { matomeListTypes } from "../../ActionTypes/matomeListTypes";

  var getMatomeList = () => axios.get(API_BASE_ADDRESS + '/recruit_site/list' , {headers: { Prefer: 'example=example-1', Accept: 'application/json' }}); 


function* fetchMatomeListSaga() {
  try {
    const response: AxiosResponse = yield call(getMatomeList);
    yield put(
      fetchMatomeListSuccess({
        matome: response.data      
      })
    );
  } catch (e:any) {
    yield put(
      fetchMatomeListFailure({
        error: e.message
      })
    );
  }
}

function* MatomeListSaga() {
  yield takeLatest(matomeListTypes.FETCH_MATOME_LIST_REQUEST, fetchMatomeListSaga);
}

export default MatomeListSaga;
