import { articlesImagesTypes } from "../../ActionTypes/articlesImagesTypes";
import { ArticlesImagesFailure, ArticlesImagesFailurePayload, ArticlesImagesRequest, ArticlesImagesSuccess, ArticlesImagesSuccessPayload } from "../../types/type";

export const articlesImagesRequest = (page:any): ArticlesImagesRequest => ({
  type: articlesImagesTypes.FETCH_ARTICLES_IMAGES_REQUEST,
  page,
});

export const articlesImagesSuccess = (
  payload: ArticlesImagesSuccessPayload,
): ArticlesImagesSuccess => ({
  type: articlesImagesTypes.FETCH_ARTICLES_IMAGES_SUCCESS,
  payload
});

export const articlesImagesFailure = (
  payload: ArticlesImagesFailurePayload
): ArticlesImagesFailure => ({
  type: articlesImagesTypes.FETCH_ARTICLES_IMAGES_FAILURE,
  payload
});
