import { useState } from "react";


function Guide1() {

    
    return (
        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">1.アカウント登録について</h3>
          <p>この記事は約3分で読めます。</p>
    
          <p>このページではサイト利用に必要なアカウント登録について説明します。</p>
    
          <p>
            1.パラちゃんねる求人サイトTOP画面より <a href="/login/" style={{ display: 'inline', color: '#00a0e9' }}>ログイン／登録</a> をクリックします。
          </p>
    
          <img src="/assets/img/guide/first.png" alt=""/>
    
          <p>2.新規会員登録をクリックします。</p>
    
          <img src="/assets/img/guide/01/02.png" alt=""/>
    
          <p>3.メールアドレス、パスワードを入力し、会員登録をクリックしてください。<br/>
          パスワードは8文字以上32文字以下で入力してください。<br/>
          大文字と小文字を1文字以上含めた半角英数字で入力してください。</p>
          <img src="/assets/img/guide/01/03.png" alt=""/>
    
          <p>4.登録したメールアドレスに、仮メールが送信されます。</p>
          –
          <p>お送りしたメールをご確認いただき、そちらから登録の手順を実行してください。<br/>
          ※メールが届かない場合は、間違ったメールアドレスを登録しているか、<br/>
          迷惑メールフォルダに振り分けられている可能性があります。<br/>
          ご確認いただき再度ご登録をお願いします。</p>
          <img src="/assets/img/guide/01/04.png" alt=""/>
    
          <p>5.メールの中にある、本登録完了用URLをクリックして会員登録を完了させてください。</p>
          –
          <p>※このURLの有効期限はご登録いただいてから「24時間以内」となります。<br/>
          ※有効期限を過ぎてしまった場合は、再度「新規会員登録」の手続きを行ってください。<br/>
          ※上記URLが２行に渡っている場合等、正しくページにアクセスできないことがあります。<br/>
          その場合は、URLをコピーして、ブラウザのアドレス画面に直接貼り付けアクセスしてください。<br/>
          ※本通知に覚えがない場合は、メールを破棄してください。<br/>
          このまま本登録を行わなければ、24時間経過後に仮登録内容は消去されます。</p>
          <img src="/assets/img/guide/01/05.png" alt=""/>
    
          <p>6.プロフィールを入力する画面へ遷移します。<br/>
          名前、フリガナ、生年月日を入力し、次へをクリックしてください。</p>
          <img src="~/assets/img/guide/01/06.png" alt=""/>
    
          <p>7.障がいについて記載する画面へ遷移します。障がい種別、手帳の有無について入力し次へをクリックしてください。</p>
          <img src="/assets/img/guide/01/07.png" alt=""/>
    
          <p>8.希望条件を記載する画面へ遷移します。<br/>
          新卒採用かどうか、希望する勤務地、希望職種を選択し、<br/>
          利用規約とプライバシーポリシーの内容を確認し、同意する。にチェックを付け、<br/>
          完了をクリックしてください。その前のページに戻りたい場合は戻るをクリックしてください。</p>
          <img src="/assets/img/guide/01/08.png" alt=""/>
          <p>ここまでがアカウント登録になります。</p>
          <a href="/guide/" className="btn type3 nuxt-link-active">一覧へ戻る</a>
        </section>
      </main>
       
    );
  }
  
  export default Guide1;