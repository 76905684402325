import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchRecruiterPricingRequest } from '../../../redax/actions/recruiterPricingActions/recruiterPricingActions';
import { fetchRecruiterPlanRequest } from '../../../redax/actions/recruiterPlanActions/recruiterPlanActions';
import './planPricing.scss';
const Planupdate = () => {
  const pricing = useSelector((state: RootState) => state.recruiterPricing);
  const plan = useSelector((state: RootState) => state.recruiterPlan);
  const [pricingData, setPricingData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPath, setCurrenPath] = useState();

  useEffect(() => {
    if (!localStorage.getItem('jobParachannelAuthToken')) {
      navigate('/recruiter_lp/');
    } else {
      dispatch(fetchRecruiterPricingRequest());
      dispatch(fetchRecruiterPlanRequest());
    }
    window.scrollTo(0, 0);
    
  }, [dispatch]);

  useEffect(()=>{
    setPricingData(pricing.recruiterPricing.plan_lists);
    setCurrenPath(plan.recruiterPlan.current_plan_id);
    
  },[pricing, plan])  

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Attach the event listener on component mount
    window.addEventListener('resize', handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  return (
    <div style={{marginBottom:"4%"}}>
      <h1 style={{ textAlign: "center", fontSize: "32px", fontWeight: "bold", marginTop: "100px" }}>
        ニーズに合った最適なプランを選択
      </h1>
      <br />
      <h2 style={{ textAlign: "center", fontSize: "20px", color: "red" }}>
        プランは月跨ぎでの変更がおすすめです。変更はすぐに適応されますのでご注意ください
      </h2>
      <br />
      <div style={windowSize.width >= 1024 ? { display: "flex", justifyContent: "center", marginTop: "100px" } : { display:"flex",flexDirection:"column",alignItems:"center", marginTop: "100px" }}>
      { pricingData && pricingData.map((e:any) => (

        <div className='planCard' 
          >
          <h2 style={e.name == 'スタンダードプラン' ? {whiteSpace: "nowrap", fontSize: "24px", fontWeight: "bold", height:"70px", width:"200px" } : {fontSize: "24px", fontWeight: "bold", height:"70px", width:"200px" }}>{e.name}</h2>
          <br />
          <p style={{ color: "#B2B2B2" }}>{e.id == 1 ? `まずはお試しで` : e.id == 2 ? `スカウト代行付き` : e.id == 3 ? `無制限に採用可能` : ""}</p>
          <h2 style={{ textAlign: "center", fontSize: "32px", fontWeight: "bold" }}>¥ {e.price.toLocaleString()}</h2>
          <br />
          <div style={{textAlign:"start"}}>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>機能一覧</p>
            <p>️<img src="/assets/img/right.png" alt="" style={{width:"10px", display:"inline-block"}}/> 求人掲載{e.recruitment_limit ? `${e.recruitment_limit}求人` : '上限なし'}</p>
            <p>️<img src="/assets/img/right.png" alt="" style={{width:"10px", display:"inline-block"}}/> スカウト枠{e.days_scout_limit}件/日</p>
            <p>️<img src="/assets/img/right.png" alt="" style={{width:"10px", display:"inline-block"}}/> {e.id == 1 ? `スカウト情報: 一部` : `スカウト情報: 全部 ` }</p>
            {/* <p>️<img src="/assets/img/right.png" alt="" style={{width:"10px", display:"inline-block"}}/> {`コラム記事(${e.month_article_limit}記事/月)`}</p> */}
            <p>️<img src="/assets/img/right.png" alt="" style={{width:"10px", display:"inline-block"}}/> 事務サポート</p>
          </div>
          { e.id != currentPath ? 
            <button onClick={() => { navigate(`/recruiter/plandetail/`,  {state: {data: e.id}}) }} className='scoutsBtn'>プラン変更</button> :
            <button onClick={() => { navigate(`/recruiter/plandetail/`,  {state: {data: e.id}}) }} style={{backgroundColor:"gray" , border:"gray"}} className='scoutsBtn'>現在のプラン</button>
          }    
          <br />
        </div>    
      ))
    }
    <br />
    </div>
      <br />
      <br />
      <div style={{textAlign:"center"}}>
        <button onClick={() => { window.location.href = ('/recruiter/plan')}} style={{textAlign:"center"}} className='scoutsBtn'>戻る</button>
      </div>

      <div className="qa-wrapper">
        <div className="header">
          <h4 className="title">よくある質問</h4>
        </div>
        <div className="body">
          <div className="qa-block">
            <div className="question-wrapper">
              <p className="text">Q. 無料プランと有料プランの違いは何ですか？</p>
            </div>
            <div className="answer-wrapper">
              <p className="text">
                ①求人数・スカウト数・コラム数の違いがあります。<br />
                ②有料アカウントはプレミアム求人として専用の求人ボードに掲載され、SNS広告で配信されます。<br />
                ③最低保証やスカウト代行などのオプションも追加で購入することが可能です。
              </p>
            </div>
          </div>
          <div className="qa-block">
            <div className="question-wrapper">
              <p className="text">Q. 無料プランから有料プランの切り替えに必要な情報は何がありますか？</p>
            </div>
            <div className="answer-wrapper">
              <p className="text">
                請求書の宛名となる企業名・氏名をご記入いただきますが、その他情報は必要ありません。<br />
                請求はプラン変更後の翌月1日に請求書がシステム上で発行されます。請求書をダウンロードの上、金額をお振込みください。<br />
                クレジットカードによる引き落としはありません。
              </p>
            </div>
          </div>
          <div className="qa-block">
            <div className="question-wrapper">
              <p className="text">Q. 月末でプラン変更した場合、請求金額は日割り計算されますか？</p>
            </div>
            <div className="answer-wrapper">
              <p className="text">
                毎月1日起算日としているため月中での日割り計算はできません。その為、プラン変更は月初に対応頂くことをオススメいたします。
              </p>
            </div>
          </div>
          <div className="qa-block">
            <div className="question-wrapper">
              <p className="text">Q. 間違えて有料プランを変更してしまった場合は、どうすればよいですか？</p>
            </div>
            <div className="answer-wrapper">
              <p className="text">
                運営事務局にて変更手続きをいたします。お問い合わせフォームよりお問い合わせください。
              </p>
            </div>
          </div>
          <div className="qa-block">
            <div className="question-wrapper">
              <p className="text">Q. 追加オプションの最低保証とは何ですか？</p>
            </div>
            <div className="answer-wrapper">
              <p className="text">
                月間のエントリー数が0の場合に最低保証を適用します。<br />
                【例】<br />
                スタンダードプラン5000円、最低保証オプション1000円の場合、<br />
                エントリー0件なら、最低保証オプション1,000円（税別）の請求となり、エントリー1件以上ならスタンダードプラン5,000円（税別）＋最低保証オプション1,000円（税別）で6,000円（税別）となります。<br />
              </p>
            </div>
          </div>
          <div className="qa-block">
            <div className="question-wrapper">
              <p className="text">Q. 追加オプションのスカウト代行とは何ですか？</p>
            </div>
            <div className="answer-wrapper">
              <p className="text">
                運営事務局が採用担当者に代わりスカウトを実施します。障害者雇用の場合、障害種別、職場環境、働き方などマッチング条件が細かく多いためネット上だけでマッチング精度を高めることは難しく、現時点のスカウト条件は希望勤務地のみで、まずは求職者に興味を持ってもらうことを目的としています。障害者雇用では求人企業から直接求職者へスカウトが届くことがあまりなく、スカウト経由の求人応募率は4.9％と高くなっています。
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Planupdate;
