import { articlesCreateTypes } from "../../ActionTypes/articlesCreateTypes";
import { FetchArticlesCreateFailure, FetchArticlesCreateFailurePayload, FetchArticlesCreateRequest, FetchArticlesCreateSuccess, FetchArticlesCreateSuccessPayload } from "../../types/type";


export const fetchArticlesCreateRequest = (body: any): FetchArticlesCreateRequest => ({
  type: articlesCreateTypes.FETCH_ARTICLES_CREATE_REQUEST,
  body,
});

export const fetchArticlesCreateSuccess = (
  payload: FetchArticlesCreateSuccessPayload,
): FetchArticlesCreateSuccess => ({
  type: articlesCreateTypes.FETCH_ARTICLES_CREATE_SUCCESS,
  payload
});

export const fetchArticlesCreateFailure = (
  payload: FetchArticlesCreateFailurePayload
): FetchArticlesCreateFailure => ({
  type: articlesCreateTypes.FETCH_ARTICLES_CREATE_FAILURE,
  payload
});
