import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { savedConditionsDeleteTypes } from "../../ActionTypes/savedConditionsDeleteTypes";
import { fetchSavedConditionsDeleteFailure, fetchSavedConditionsDeleteSuccess } from "../../actions/savedConditionsDeleteActions/savedConditionsDeleteActions";

var getSavedConditionsDelete = (queryParams:any) => axios.delete( API_BASE_ADDRESS + '/saved_conditions/' + queryParams, {headers: { Accept: '*/*', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 

function* fetchSavedConditionsDeleteSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getSavedConditionsDelete, queryParams.queryParams);
    yield put(
      fetchSavedConditionsDeleteSuccess({
        savedConditionsDelete: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchSavedConditionsDeleteFailure({
        error: e.message
      })
    );
  }
}

function* SavedConditionsDeleteSaga() {
  yield takeLatest(savedConditionsDeleteTypes.FETCH_SAVED_CONDITIONS_DELETE_REQUEST, fetchSavedConditionsDeleteSaga);
}

export default SavedConditionsDeleteSaga;
