
import { adminRecommendApplicantsTypes } from "../../ActionTypes/adminRecommendApplicantsTypes";
import { } from "../../ActionTypes/adminScoutMatchingTypes";
import { AdminRecommendApplicantsState, AdminRecommendApplicantsActionss } from "../../types/type";


const initialState: AdminRecommendApplicantsState = {
  pending: false,
  adminApplicants: [],
  error: null,
};

export default (state = initialState, action: AdminRecommendApplicantsActionss,) => {
  switch (action.type) {
    case adminRecommendApplicantsTypes.FETCH_ADMIN_RECOMMEND_APPLICANTS_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminRecommendApplicantsTypes.FETCH_ADMIN_RECOMMEND_APPLICANTS_SUCCESS:
      return {
        ...state,
        pending: true,
        adminApplicants: action.payload.adminApplicants,
        error: null
      };
    case adminRecommendApplicantsTypes.FETCH_ADMIN_RECOMMEND_APPLICANTS_FAILURE:
      return {
        ...state,
        pending: false,
        adminApplicants: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
