import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruitmentsStatusChangeTypes } from "../../ActionTypes/recruitmentsStatusChangeTypes";
import { fetchRecruitmentsStatusChangeFailure, fetchRecruitmentsStatusChangeSuccess } from "../../actions/recruitmentsStatusChangeActions/recruitmentsStatusChangeActions";


var postRecruitmentsStatusChange = (id:any, queryParams: any) => axios.put(API_BASE_ADDRESS + '/recruitments/' + id, {status_id: queryParams},  {
headers: {
  Authorization: localStorage.getItem('jobParachannelAuthToken'),
  'Content-Type': 'application/json',
  Accept: '*/*',
}}); 

function* fetchRecruitmentsStatusChangeSaga(id: any) {
  try {
    const response: AxiosResponse = yield call(postRecruitmentsStatusChange , id.id, id.queryParams);
    yield put(
      fetchRecruitmentsStatusChangeSuccess({
        recruitmentsStatusChange: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitmentsStatusChangeFailure({
        error: e.message
      })
    );
  }
}

function* RecruitmentsStatusChangeSaga() {
  yield takeLatest(recruitmentsStatusChangeTypes.FETCH_RECRUIMENTS_STATUS_CHANGE_REQUEST, fetchRecruitmentsStatusChangeSaga);
}

export default RecruitmentsStatusChangeSaga;
