

import { articlesTypes } from "../../ActionTypes/articlesTypes";
import {
  FetchArticlesFailure,
  FetchArticlesFailurePayload,
  FetchArticlesRequest,
  FetchArticlesSuccess,
  FetchArticlesSuccessPayload,
} from "../../types/type";

export const fetchArticlesRequest = (): FetchArticlesRequest => ({
  type: articlesTypes.FETCH_ARTICLES_REQUEST,
});

export const fetchArticlesSuccess = (
  payload: FetchArticlesSuccessPayload,
): FetchArticlesSuccess => ({
  type: articlesTypes.FETCH_ARTICLES_SUCCESS,
  payload
});

export const fetchArticlesFailure = (
  payload: FetchArticlesFailurePayload
): FetchArticlesFailure => ({
  type: articlesTypes.FETCH_ARTICLES_FAILURE,
  payload
});
