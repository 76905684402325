import { settingsTypes } from "../../ActionTypes/settingsTypes";
import { FetchSettingsFailure, FetchSettingsFailurePayload, FetchSettingsRequest, FetchSettingsSuccess, FetchSettingsSuccessPayload } from "../../types/type";

export const fetchSettingsRequest = (email:any, name: any): FetchSettingsRequest => ({
  type: settingsTypes.FETCH_SETTINGS_REQUEST,
  email,
  name
});

export const fetchSettingsSuccess = (
  payload: FetchSettingsSuccessPayload,
): FetchSettingsSuccess => ({
  type: settingsTypes.FETCH_SETTINGS_SUCCESS,
  payload
});

export const fetchSettingsFailure = (
  payload: FetchSettingsFailurePayload
): FetchSettingsFailure => ({
  type: settingsTypes.FETCH_SETTINGS_FAILURE,
  payload
});
