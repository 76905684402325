import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../redax/reducers/rootReducer";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import { fetchTopRecruiterRequest } from "../../../../redax/actions/topRecruiterActions/topRecruiterActions";
import { fetchScoutsRecruitmentRequest } from "../../../../redax/actions/scoutsRecruitmentActions/scoutsRecruitmentActions";
import { fetchScoutsRequest } from "../../../../redax/actions/scoutsActions/scoutsActions";
import Loader from "../../../loader/loader";

function HistoryIndex() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [resourceId, setResourceId] = useState(null);
    const loginData = useSelector((state: RootState) => state.login);

    const topRecruiter = useSelector((state: RootState) => state.topRecruiter);
    const scouts = useSelector((state: RootState) => state.scouts);
    const scoutsRecruitment = useSelector((state: RootState) => state.scoutsRecruitment);

    const [familyName, setFamylyName] = useState(null);
    const [page, setPage] = useState(1);
    const [loadScreen, setLoadScreen] = useState(true);
    const [pages, setPages] = useState<any>(null);
    const [recruitments, setRecruitments] = useState([]);
    const [scoutsList, setScouts] = useState([]);
    const pageTitle = '企業様マイページ｜パラちゃんねる';
    document.title = pageTitle;
    
    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
        //   dispatch(fetchLoginRequest('', '',''))
        } else (
            navigate('/recruiter/login/')
        )
      }, [dispatch]);


    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/recruiter/init/');
            }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            dispatch(fetchTopRecruiterRequest(loginData.login.resource.id,''));
            dispatch(fetchScoutsRecruitmentRequest());
            dispatch(fetchScoutsRequest(0, page));
        }
      }, [loginData.pending, page]);
      
      function logout() {
        if (localStorage.entryFlag) {
            localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
            localStorage.removeItem('jobParachannelAuthToken')
            navigate('/');
            window.location.reload();
        }
    }

    useEffect(() => {
        if (scoutsRecruitment.pending) {
            setRecruitments(scoutsRecruitment.scoutsRecruitment.recruitments);
        } else if(scoutsRecruitment.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [scoutsRecruitment]);

    useEffect(() => {
        if (scouts.pending) {
            setScouts(scouts.scouts.scouts);
            setPages(scouts.scouts.pagenation.total_pages);
            setLoadScreen(false);
        } else if(scouts.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [scouts]);

    function searchCandidates(status: any, scoutId: any) {
        navigate('/recruiter/scouts/candidate?status=' + status + '&scoutId=' + scoutId);
    }

    const pageIncrement = () => {
        if (page >= pages) {
          setPage(pages);
          return;
        }
        setPage((prevPage) => prevPage + 1);
    }

    const pageDecrement = () => {
    if (page <= 1) {
        setPage(1);
        return;
    }
    setPage((prevPage) => prevPage - 1);
    }

    const updatePage = (p: any) => {
    setPage(p);
    }

    return (
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className ="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">スカウト</h2>
                <ul>
                <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            {scoutsList && scoutsList.length > 0 ?
            <div id="scouts" className="history">
                <h3>スカウト応募者一覧</h3>
                <div className="cont1">
                <ul>
                {recruitments && recruitments.map((recruitment: any, index: any) => (
                    <li key={recruitment.id}>
                    <h4>{ recruitment.job_title }</h4>
                    <p><span><span>{ recruitment.apply_count }</span>/</span>{ recruitment.sent_count }</p>
                    <p>応募数/送付数</p>
                    </li>
                ))}
                </ul>
                </div>
                <div className="cont2">
                <ul>
                {scoutsList && scoutsList.map((scout: any, index: any) => (
                    <li key={scout.id}>
                        <div>
                            <a href={'/recruiter/scouts/history/' + scout.id}>
                            <p>{ scout.created_at }</p>
                            <div className="tag_box">
                                <h5>スカウトした求人</h5>
                                <ul>
                                {recruitments && scout.recruitments.map((recruitment: any, index: any) => (
                                    <li key={recruitment.id}><a href="#">{ recruitment.title }</a></li>
                                ))}
                                </ul>
                            </div>
                            </a>
                        </div>
                        <ul className="link">
                            <li><a href="#" onClick={() => searchCandidates('sent', scout.id)}>送付<span><span>{ scout.sent_count }</span>件</span></a></li>
                            <li><a href="#" onClick={() => searchCandidates('opened', scout.id)}>開封<span><span>{scout.opened_count}</span>件</span></a></li>
                        </ul>
                    </li>
                ))}
                </ul>

                {/* <!-- ページャー --> */}
                {pages !== null ? 
                <ul className="pagination">
                    <li className={`arrow prev ${page <= 1 ? 'disabled' : ''}`}>
                        <a onClick={pageDecrement}><img src="/assets/img/company/icon06.svg" alt="次へ"/></a>
                    </li>
                    {Array.from({ length: pages }, (_, i) => i + 1).map((p) => (
                        <li key={p} className={`page ${page === p ? 'active' : ''}`}>
                        <a href="#" onClick={() => updatePage(p)}>
                            {p}
                        </a>
                        </li>
                    ))}
                    <li className={`arrow next ${page >= pages ? 'disabled' : ''}`}>
                        <a href="#" onClick={pageIncrement}><img src="/assets/img/company/icon07.svg" alt="前へ"/></a>
                    </li>
                </ul>
                : <></>}
                </div>
            </div>
            :
            <p>スカウトはありません</p>}
        </section>
    </main>
    );
  }
  export default HistoryIndex;