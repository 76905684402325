import { adminCompanyNewTypes } from "../../ActionTypes/adminCompanyNewTypes";
import { AdminCompanyNewActions, AdminCompanyNewState } from "../../types/type";



const initialState: AdminCompanyNewState = {
  pending: false,
  company: [],
  error: null,
};

export default (state = initialState, action: AdminCompanyNewActions,) => {
  switch (action.type) {
    case adminCompanyNewTypes.FETCH_ADMIN_COMPANY_NEW_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminCompanyNewTypes.FETCH_ADMIN_COMPANY_NEW_SUCCESS:
      return {
        ...state,
        pending: true,
        company: action.payload.company,
        error: null
      };
    case adminCompanyNewTypes.FETCH_ADMIN_COMPANY_NEW_FAILURE:
      return {
        ...state,
        pending: false,
        company: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
