import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../adminTop/adminTop.css';
import { RootState } from '../../redax/reducers/rootReducer';
import Loader from '../loader/loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { fetchAdminApplicantsDetailRequest } from '../../redax/actions/adminApplicantsDetailActions/adminApplicantsDetailActions';
import { fetchAdminApplicantsPostRequest } from '../../redax/actions/adminApplicantsPostActions/adminApplicantsPostActions';
import AdminNav from '../adminTop/AdminNav';
import { fetchAdminCompanyDetailRequest } from '../../redax/actions/adminCompanyDetailActions/adminCompanyDetailActions';
import { fetchAdminNewsCompanyDetailRequest } from '../../redax/actions/adminNewsCompanyDetailActions/adminNewsCompanyDetailActions';
import { fetchAdminCompanyRecruiterRequest } from '../../redax/actions/adminComanyRecruiterActions/adminComanyRecruiterActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from "@fortawesome/free-solid-svg-icons";
import { fetchAdminNewsNewRequest } from '../../redax/actions/adminNewsNewActions/adminNewsNewActions';
import { fetchAdminCompanyNewRequest } from '../../redax/actions/adminCompanyNewActions/adminCompanyNewActions';
const AdminCompanyDetail = () => {
  const dispatch = useDispatch();
  const adminCompanyDetail = useSelector((state: RootState) => state.adminCompanyDetail);
  const adminNewsCompanyDetail = useSelector((state: RootState) => state.adminNewsCompanyDetail);
  const adminCompanyRecruiter = useSelector((state: RootState) => state.adminCompanyRecruiter);
  const adminNewsNew = useSelector((state: RootState) => state.adminNewsNew);
  const [loadScreen, setLoadScreen] = useState(true);
  const [btn, setBtn] = useState(false);
  const [companyList, setCompanyList] = useState<any>([]);
  const [newsCompanyList, setNewsCompanyList] = useState<any>([]);
  const [purposeOfPostings, setPurposeOfPostings] = useState<any>([]);
  const [recruiters, setRecruiters] = useState<any>([]);
  const [recruitments, setRecruitments] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newsTitle, setNewsTitle] = useState('');
  const [newsMessage, setNewsMessage] = useState('');
  const [newsUrl, setNewsUrl] = useState('');
  const [newsContent, setNewsContent] = useState('');
  const adminCompanyNew = useSelector((state: RootState) => state.adminCompanyNew);




  
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
      if (localStorage.getItem('jobParachannelAuthAdminToken')) {
        dispatch(fetchAdminCompanyDetailRequest(id));
        dispatch(fetchAdminNewsCompanyDetailRequest(id));
      } 
  }, [dispatch]); 

  useEffect(() => {
      if (adminCompanyDetail.pending) {
        setCompanyList(adminCompanyDetail.company);
        setPurposeOfPostings(adminCompanyDetail.purpose_of_postings)
        setRecruiters(adminCompanyDetail.recruiters);
        setRecruitments(adminCompanyDetail.recruitments);
        // setComapny(adminRecruitmentDetail.company);
        setLoadScreen(false)
      } 
  }, [adminCompanyDetail]); 
  


  useEffect(() => {
    if (adminCompanyNew.pending && btn) {
      window.location.href ='/admin/companies';
    } 
}, [adminCompanyNew,btn]); 
  


  

  useEffect(() => {
    if (adminCompanyRecruiter.pending ) {
        window.location.reload();
    } 
}, [adminCompanyRecruiter]); 
  useEffect(() => {
    if (adminNewsCompanyDetail.pending ) {
        setNewsCompanyList(adminNewsCompanyDetail.newsArray);
    } 
}, [adminNewsCompanyDetail]); 


  
  
  function formatDate (value: any) {
    return dayjs(value).locale(ja).format('YYYY.MM.DD (ddd) HH:mm');
}
function fetchDelete () {
  if (recruitments.length != 0) {
    alert("企業に紐づく求人があるため削除できません、\n企業を削除する前に紐づく求人を削除してください")
    return
  }
  let str = "求人を削除しますか？"
  if (!window.confirm(str)) { return }
  dispatch(fetchAdminCompanyNewRequest('','DELETE',id)); 
  setBtn(true)
}

// function calcGender () {
//   switch (applicantsList.gender_id) {
//     case 1: return '男性'
//     case 2: return '女性'
//     case 3: return '回答しない'
//     default: return null
//   }
// }

function deleteComapnies(recruiterid:any){
  let str = "この採用担当者を削除してもよろしいですか？"
  if (!window.confirm(str)) { return }
  dispatch(fetchAdminCompanyRecruiterRequest('','DELETE', id,recruiterid))
}

useEffect(() => {
  if (adminNewsNew.pending ) {
    window.location.reload();
  }
}, [adminNewsNew]);


function sendNews () {

  let validation_messages = ''
  if (!newsTitle) {
    validation_messages += 'タイトル\n'
  }
  if (!newsMessage) {
    validation_messages += '本文\n'
  }

  if (validation_messages) {
    alert('未入力項目があります\n\n' + validation_messages)
    return
  }

  if (!window.confirm('この内容でお知らせを送信しますか？')) {
    return false
  }
  let news = {
    title: newsTitle,
    content: newsContent,
    target_type: 2,
    url: newsUrl,
    applicant_id: null,
    company_id: id,
    sub_url1: null,
    sub_content1: null,
    sub_url2: null,
    sub_content2: null,
    message: newsMessage
  }
  dispatch(fetchAdminNewsNewRequest(news));
}
  

  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader></Loader> : <></>}
      <section id='openjob'>
        <div className='adminPageWrapper'>
          <div className="header">
              <h2 className="title">企業概要</h2>
          </div>  
          <div className='body openJobDetail'>
            <div className="">
                <div className="textWrapper">
                  <a onClick={() => navigate('/admin/companies')} className='blueBtn'>＜ 一つ前に戻る</a>
                </div>
              </div>
              {companyList && companyList.length !==0 ?
              <>
               <div className='step1 '>
                <div className='detailDflex'>
                  <h1>基本情報</h1>
                </div>
                <div className='bordered' style={{width: "100%"}}>
                  <div style={{display:'flex'}}>
                    <div style={{width: "20%"}}>
                      <div className='imgBox'>
                          <img src={companyList.logo}></img>
                      </div>
                    </div>
                    <div className='detailTextBox' style={{width: "40%", padding: "0px 6px 6px 6px"}}>
                      <h4>会社名 <span>{ companyList.name }</span></h4>
                      <h4>フリガナ: <span>{ companyList.name_kana }</span></h4>
                      <h4>法人番号: <span>{ companyList.corporate_number }</span></h4>
                      <h4>登録日: <span> {formatDate(companyList.created_at)}</span></h4>
                      <h4>最終ログイン日: <span>{companyList.last_login_at ? formatDate(companyList.last_login_at) : <></>}</span></h4>
                    </div>
                    <div className='detailTextBox' style={{width: "40%", padding: "0px 6px 6px 6px"}}>
                        <h4>URL<span>{ companyList.url }</span></h4>
                        <h4>郵便番号 <span>{companyList.postcode}</span></h4>
                        <h4>住所<span> { companyList.prefecture.name }{ companyList.city.name }{ companyList.address }</span></h4>
                        <h4>業種分類 { companyList.industry.name ? <span className='borders1' style={{display:'inline'}}>{companyList.industry.name}</span> : <></> }</h4>
                        <h4>設立年月 { companyList.founded.year ?  <span>{ companyList.founded.year } 年{ companyList.founded.month } 月</span> : <></>} </h4>
                    </div>
                  </div>
                </div>
                <div className='mt20'>
                  <h1>会社概要</h1>
                </div>
                <table>
                    <tr>
                      <td>
                        <p>事業内容</p>
                      </td>
                      <td>
                          <p>{ companyList.business_content }</p>
                      </td>
                    </tr>
                    <tr>
                      <td><p>従業員数</p></td>
                      <td><p>{ companyList.number_of_employees }名</p></td>
                    </tr>
                    <tr>
                      <td><p>資本金</p></td>
                      <td>
                        <p>{ companyList.capital }</p>
                      </td>
                    </tr>
                    <tr>
                      <td><p>売上高</p></td>
                      <td><p>{ companyList.sales }</p></td>
                    </tr>
                    <tr>
                      <td><p>障害者雇用実績</p></td>
                      <td>
                        
                        {companyList.people_with_disabilities.map((data: any, index:any) => (
                          <div className='dFlex'>
                            <div className='wid50'><p>{data.handicap_type_name}</p></div>
                            <div className='wid50'>{data.number_of_employees_name ? <p className='borders1'>{data.number_of_employees_name}</p> : <></>}</div>
                          </div>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td><p>株式</p></td>
                      <td>{companyList.stock_listing.name ? <p className='borders1'>{companyList.stock_listing.name}</p> : <></>} </td>
                    </tr>
                    <tr>
                      <td>
                        <p>社風</p>
                      </td>
                      <td>
                        {companyList.corporate_cultures && companyList.corporate_cultures.map((data: any, index:any) => (
                          <p className='borders1' style={{margin:'5px'}}>{data.name}</p>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td><p>登録:求人掲載目的</p></td>
                      <td>
                      {purposeOfPostings && purposeOfPostings.map((data: any, index:any) => (
                          <p className='borders1' style={{margin:'5px'}}>{data.name}</p>
                        ))}
                      
                      </td>
                    </tr>
                </table>
                <div className='mt20'  style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <button className='deleteBtn'  style={{padding:'10px 100px', fontSize:'18px'}} onClick={()=> fetchDelete()}>削除</button>
                <button className='createBtn' onClick={() =>navigate('/admin/companies/' +id +'/edit' , { state: { companyDetail: {
                  company: {
                    logo: companyList.logo,
                    images: companyList.images,
                    corporateNumber: companyList.corporate_number,
                    name: companyList.name,
                    nameKana: companyList.name_kana,
                    url: companyList.url,
                    postcode: companyList.postcode,
                    prefectureId: companyList.prefecture.id,
                    prefectureName: companyList.prefecture.name,
                    cityId: companyList.city.id,
                    cityName: companyList.city.name,
                    address: companyList.address,
                    industryId: companyList.industry.id,
                    businessContent: companyList.business_content,
                    numberOfEmployees: companyList.number_of_employees,
                    capital: companyList.capital,
                    sales: companyList.sales,
                    stockListingId: companyList.stock_listing.id,
                  },
                  peopleWithDisabilities: companyList.people_with_disabilities,
                  prefectureName: companyList.prefecture.name,
                  cityName: companyList.city.name,
                  founded: companyList.founded,
                  // peopleWithDisabilities: peopleWithDisabilities,
                  corporateCultures: companyList.corporate_cultures.map((v: { id: any; }) => v.id),
                  purposeOfPostings: purposeOfPostings.map((v: { id: any; }) => v.id),
                } } })}  style={{padding:'10px 100px', fontSize:'18px'}}>編集</button>
                </div>
                <div className='mt20'  style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                  <h1>採用担当者</h1>
                </div>
                <table>
                  {recruiters && recruiters.map((data: any, index:any) => (
                      <tr>
                      <td><p>採用担当者 { index + 1 }</p></td>
                      <td>
                        <div className='dFlex'>
                            <div className='wid50'><p>ID</p></div>
                            <div className='wid50'>{data.id}</div>
                        </div>
                        <div className='dFlex'>
                            <div className='wid50'><p>ご担当者名</p></div>
                            <div className='wid50'>{data.name}</div>
                        </div>
                        <div className='dFlex'>
                            <div className='wid50'><p>カナ</p></div>
                            <div className='wid50'>{data.name_kana}</div>
                        </div>
                        <div className='dFlex'>
                            <div className='wid50'><p>部署</p></div>
                            <div className='wid50'>{data.department}</div>
                        </div>
                        <div className='dFlex'>
                            <div className='wid50'><p>電話番号</p></div>
                            <div className='wid50'>{data.phone_number}</div>
                        </div>
                        <div className='dFlex'>
                            <div className='wid50'><p>メールアドレス</p></div>
                            <div className='wid50'>{data.email}</div>
                        </div>
                        <button className='createBtn' style={{marginRight:'10px'}} onClick={()=> navigate('/admin/companies/' +id +"/recruiter/" + data.id, { state: { recruiterData: data } })}>採用担当者 編集</button>
                        {data.is_main_recruiter ? <></> : <button className='deleteBtn' style={{fontSize:'14px'}} onClick={()=> deleteComapnies(data.id)}>削除</button>}
                        

                      </td>
                    </tr>
                  ))}
                </table>
                <button className='createBtn mt20' onClick={()=> navigate('/admin/companies/'+id+'/recruiter')}>採用担当者の新規作成</button>
                
                <div className='mt20'>
                  <h1>企業に紐ずく求人</h1>
                </div>
                
                {recruitments && recruitments.map((data: any, index:any) => (
                  <div className='bordered' onClick={()=> navigate('/admin/openjob_list/' +data.id)} key={data.id}>
                    <div style={{display:'flex'}}>
                      <div className='imgBox' style={{marginRight:'150px'}}>
                          <img src={data.thumbnail}></img>
                      </div>
                      <div className='detailTextBox' style={{marginRight:'150px'}}>
                        <h4>会社名 <span> { companyList.name }</span></h4>
                        <h4>職種名 <span>{ data.job_title }</span></h4>
                        <h4>登録日/更新日<span></span></h4>
                        <h4>事業所番号<span></span></h4>
                        <h4>職種<span></span></h4>

                      </div>
                      <div className='detailTextBox' style={{marginRight:'150px'}}>
                        <h4>募集する企業区分 <span></span></h4>
                        <h4>雇用形態<span></span></h4>
                        <h4>採用人数<span></span></h4>
                        <h4>現在のステータス<span></span></h4>
                      </div>
                    </div>
                  </div>
                  ))}
                <button className='createBtn mt20' onClick={()=>navigate('/admin/openjob_list/new' , { state: { companyId: id } })}>求人新規作成</button>
            </div>
              </>
              : <></>}
              <div className='step1'>
                <div className='mt20 '>
                    <h1>過去のお知らせ</h1>
                </div>
                <table id ='newsTable'>
                <tr>
                    <th>♯</th>
                    <th>リンク先</th>
                    <th>日時</th>
                    <th>タイトル</th>
                    <th>ターゲット</th>
                    <th  style={{textAlign:'center'}}>区分</th>
                  </tr>
                {newsCompanyList && newsCompanyList.map((data: any, index:any) => (
                    <tr>    
                    <td>
                      <p>{index + 1}</p>
                    </td>
                    <td>{data.url}</td>
                    <td>{formatDate(data.created_at)}</td>
                    <td>{data.title}</td>
                    <td>{data.target.name}</td>
                    <td  style={{textAlign:'center'}}> 
                      <p  className='detailBtnBlue'>  {data.target_type_name}</p>
                     </td>
                  </tr>
                ))}
                </table>


                <button className='createBtn mt20' onClick={()=> setModalOpen(true)}>お知らせを作成</button>
              </div>
          </div>
        </div>
      </section>
      {modalOpen ? 
      <div className ="modal_box" >
        <div className="modal_inner" style={{overflow:'hidden'}}>
          <div style={{padding:'30px'}}>
            <h5>企業へのメッセージ</h5>
            <div>
              <p >タイトルを入力する</p>
              <input type="text" placeholder='タイトルを入力してください' onChange={(e)=> setNewsTitle(e.target.value)} />
            </div>
            <div className='mt20'>
              <p >お知らせ内容</p>
              <textarea  placeholder='お知らせしたい内容を入力してください' onChange={(e)=> setNewsMessage(e.target.value)} ></textarea>
            </div>
            <div className='mt20'>
              <p>URLを入力してください</p>
              <input type="text" placeholder='URLをを入力してください'  onChange={(e)=> setNewsUrl(e.target.value)} />
            </div>
            <div className='mt20'>
              <p>リンクの文字列を入力してください</p>
              <input type="text" placeholder='リンクの文字列を入力入力してください'  onChange={(e)=> setNewsContent(e.target.value)}/>
            </div>
            <button className='createBtn mt20' onClick={sendNews}>送信する</button>
          </div>
          
          <p className="close" onClick={()=> setModalOpen(false)}><FontAwesomeIcon icon={faX} /></p>
        </div>
        <div className="overlay" onClick={()=> setModalOpen(false)} ></div>
      </div>
      
      : <></>}
      
    </main>
  );
};

export default AdminCompanyDetail;
