
import { recruitmentsTypes } from "../../ActionTypes/recruitmentsTypes";
import {
  FetchRecruitmentsFailure,
  FetchRecruitmentsFailurePayload,
  FetchRecruitmentsRequest,
  FetchRecruitmentsSuccess,
  FetchRecruitmentsSuccessPayload,
} from "../../types/type";

export const fetchRecruitmentsRequest = (): FetchRecruitmentsRequest => ({
  type: recruitmentsTypes.FETCH_RECRUIMENTS_REQUEST,
});

export const fetchRecruitmentsSuccess = (
  payload: FetchRecruitmentsSuccessPayload,
): FetchRecruitmentsSuccess => ({
  type: recruitmentsTypes.FETCH_RECRUIMENTS_SUCCESS,
  payload
});

export const fetchRecruitmentsFailure = (
  payload: FetchRecruitmentsFailurePayload
): FetchRecruitmentsFailure => ({
  type: recruitmentsTypes.FETCH_RECRUIMENTS_FAILURE,
  payload
});
