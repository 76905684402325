import { helloTypes } from "../../ActionTypes/helloTypes";
import { HelloActions, HelloState } from "../../types/type";


const initialState: HelloState = {
  pending: false,
  hello: [],
  error: null,
};

export default (state = initialState, action: HelloActions,) => {
  switch (action.type) {
    case helloTypes.FETCH_HELLO_REQUEST:
      return {
        ...state,
        pending: false
      };
    case helloTypes.FETCH_HELLO_SUCCESS:
      return {
        ...state,
        pending: true,
        hello: action.payload.hello,
        error: null
      };
    case helloTypes.FETCH_HELLO_FAILURE:
      return {
        ...state,
        pending: false,
        hello: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
