import axios, { AxiosResponse } from "axios";
import { API_BASE_ADDRESS } from "../../API";
import { call, put, takeLatest } from "redux-saga/effects";
import { PutRegionTypes } from "../../ActionTypes/putRegionTypes";
import { fetchPutRegionFailure, fetchPutRegionSuccess } from "../../actions/putRegionActions/putRegionActions";

var getMessages = (applicants_id: any, queryParams: any) => axios.put(API_BASE_ADDRESS + '/applicants/' + applicants_id, queryParams, {
  headers: {    
    Prefer: 'example=example-1',
    'Content-Type': 'application/json',
    Authorization: localStorage.jobParachannelAuthToken
  }
}); 
function* fetchPutRegionSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getMessages, queryParams.applicants_id, queryParams.queryParams);
    yield put(
      fetchPutRegionSuccess({
        applicant: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchPutRegionFailure({
        error: e.applicant
      })
    );
  }
}

function* PutRegionSaga() {
  yield takeLatest(PutRegionTypes.FETCH_PUT_REGION_REQUEST, fetchPutRegionSaga);
}

export default PutRegionSaga;
