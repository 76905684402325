import { applicationCompanyTypes } from "../../ActionTypes/applicationCompanyTypes";
import { ApplicationCompanyActions, ApplicationCompanyState, } from "../../types/type";


const initialState: ApplicationCompanyState = {
  pending: false,
  applicationCompany: [],
  error: null,
};

export default (state = initialState, action: ApplicationCompanyActions,) => {
  switch (action.type) {
    case applicationCompanyTypes.FETCH_APPLICATION_COMPANY_REQUEST:
      return {
        ...state,
        pending: false
      };
    case applicationCompanyTypes.FETCH_APPLICATION_COMPANY_SUCCESS:
      return {
        ...state,
        pending: true,
        applicationCompany: action.payload.applicationCompany,
        error: null
      };
    case applicationCompanyTypes.FETCH_APPLICATION_COMPANY_FAILURE:
      return {
        ...state,
        pending: false,
        applicationCompany: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
