import { companiesTypes } from "../../ActionTypes/companiesTypes";
import { CompaniesActions, CompaniesState } from "../../types/type";



const initialState: CompaniesState = {
  pending: false,
  companies: [],
  error: null,
};

export default (state = initialState, action: CompaniesActions,) => {
  switch (action.type) {
    case companiesTypes.FETCH_COMPANIES_REQUEST:
      return {
        ...state,
        pending: false
      };
    case companiesTypes.FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        pending: true,
        companies: action.payload.companies,
        error: null
      };
    case companiesTypes.FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        pending: false,
        companies: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
