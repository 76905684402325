import { invitationTypes } from "../../ActionTypes/invitationTypes";
import { InvitationActions, InvitationState } from "../../types/type";



const initialState: InvitationState = {
  pending: false,
  invitation: [],
  error: null,
};

export default (state = initialState, action: InvitationActions,) => {
  switch (action.type) {
    case invitationTypes.FETCH_INVITATIONS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case invitationTypes.FETCH_INVITATIONS_SUCCESS:
      return {
        ...state,
        pending: true,
        invitation: action.payload.invitation,
        error: null
      };
    case invitationTypes.FETCH_INVITATIONS_FAILURE:
      return {
        ...state,
        pending: false,
        invitation: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
