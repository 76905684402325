import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchApplicantDecideFailure, fetchApplicantDecideSuccess } from "../../actions/applicantDecideActions/applicantDecideActions";
import { applicantDecideTypes } from "../../ActionTypes/applicantDecideTypes";

const getApplicantDecide = (id: any, data: any, types: any) => {
  if(types == 'get') {
    return axios.get(API_BASE_ADDRESS + '/applications/decide/', {
      headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken }
    });
  } else {
    return axios.post(API_BASE_ADDRESS + '/applications/' + id + '/decide/', data,  {
      headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken }
    });
  }
}

function* fetchApplicantDecideSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getApplicantDecide, action.id, action.data, action.types);
    yield put(
      fetchApplicantDecideSuccess({
        decide: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchApplicantDecideFailure({
        error: e.message
      })
    );
  }
}

function* ApplicantDecideSaga() {
  yield takeLatest(applicantDecideTypes.FETCH_APPLICANT_DECIDE_REQUEST, fetchApplicantDecideSaga);
}
export default ApplicantDecideSaga;