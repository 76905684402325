import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchAdminRecruitmentListFailure, fetchAdminRecruitmentListSuccess } from "../../actions/adminRecruitmentListActions/adminRecruitmentListActions";
import { adminRecruitmentListTypes } from "../../ActionTypes/adminRecruitmentListTypes";

const getAdminRecruitmentList = (data: any) => { 
  const config = {
    headers: { 
      Prefer: 'example=example-1', Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/recruitments?page=' + data.page + '&keyword=' + data.keyword + '&deleted=' + data.deleted + '&per=' + 50, config); 
}


function* fetchAdminRecruitmentListSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminRecruitmentList, action.data);
    yield put(
      fetchAdminRecruitmentListSuccess({
        recruitments: response.data.recruitments,
        pagenation: response.data.pagenation,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminRecruitmentListFailure({
        error: e.message
      })
    );
  }
}

function* AdminRecruitmentListSaga() {
  yield takeLatest(adminRecruitmentListTypes.FETCH_ADMIN_RECRUITMENT_LIST_REQUEST, fetchAdminRecruitmentListSaga);
}
export default AdminRecruitmentListSaga;