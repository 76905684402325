import { applicationsTypes } from "../../ActionTypes/applicationsTypes";
import { ApplicationsActions, ApplicationsState } from "../../types/type";



const initialState: ApplicationsState = {
  pending: false,
  applications: [],
  error: null,
};

export default (state = initialState, action: ApplicationsActions,) => {
  switch (action.type) {
    case applicationsTypes.FETCH_APPLICATIONS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case applicationsTypes.FETCH_APPLICATIONS_SUCCESS:
      return {
        ...state,
        pending: true,
        applications: action.payload.applications,
        error: null
      };
    case applicationsTypes.FETCH_APPLICATIONS_FAILURE:
      return {
        ...state,
        pending: false,
        applications: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
