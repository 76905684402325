import { RecruiterPaymentlistTypes } from "../../ActionTypes/recruiterInvoiceTypes";
import {  FetchRecruiterPaymentlistFailure, FetchRecruiterPaymentlistFailurePayload, FetchRecruiterPaymentlistRequest, FetchRecruiterPaymentlistSuccess, FetchRecruiterPaymentlistSuccessPayload } from "../../types/type";


export const fetchPaymentlistRequest = (): FetchRecruiterPaymentlistRequest => ({
  type: RecruiterPaymentlistTypes.FETCH_RECRUITER_PAYMENTLIST_REQUEST,
});

export const fetchPaymentlistSuccess = (
  payload: FetchRecruiterPaymentlistSuccessPayload,
): FetchRecruiterPaymentlistSuccess => ({
  type: RecruiterPaymentlistTypes.FETCH_RECRUITER_PAYMENTLIST_SUCCESS,
  payload
});

export const fetchPaymentlistFailure = (
  payload: FetchRecruiterPaymentlistFailurePayload
): FetchRecruiterPaymentlistFailure => ({
  type: RecruiterPaymentlistTypes.FETCH_RECRUITER_PAYMENTLIST_FAILURE,
  payload
});
