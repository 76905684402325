import { useEffect, useRef, useState } from 'react';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import './profile.scss';
import ProfileTab from './tab';
import ProfileIndex from '.';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchApplicantsRequest } from '../../../redax/actions/applicantsActions/applicantsActions';
import { fetchRegionsRequest } from '../../../redax/actions/regionsActions/regionsActions';
import { useNavigate } from 'react-router-dom';
import Loader from '../../loader/loader';
import { fetchPutRegionRequest } from '../../../redax/actions/putRegionActions/putRegionActions';

export default function ProfilePage3() {
    const [loadScreen, setLoadScreen] = useState(true);
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [authenticate, setAuthenticate] = useState(null);
    const navigate = useNavigate(); 
    const dispatch = useDispatch();
    const loginData = useSelector((state: RootState) => state.login);
    const applicants = useSelector((state: RootState) => state.applicants);
    const regions = useSelector((state: RootState) => state.regions);
    const [tempolary, settempolary] = useState(false);

    const [job_career_counts, setJobCareerCounts] = useState<any[]>([]);
    const [industries, setIndustries] = useState<any[]>([]);
    const [job_categories, setJobCategories] = useState<any[]>([]);
    const [job_category_types, setJobCategoryTypes] = useState<any[]>([]);
    const putregion = useSelector((state: RootState) => state.putregion);

    const [job_career_count_id, setJobCareerCountId] = useState<any>(null);
    const [company, setCompany] = useState('');
    const [arrival_date, setArrivalDate] = useState<any>('');
    const [departure_date, setDepartureDate] = useState<any>(null);
    const [industry_id, setIndustryId] = useState(null);
    const [job_category_id, setJobCategoryId] = useState(null);
    const [job_category_type, setJobCategoryType] = useState(null);
    const [business_content, setBusinessContent] = useState('');
    const [companyData, setCompanyData] = useState('');
    const [arrival_dateData, setArrivalDateData] = useState('');
    const [departure_dateData, setDepartureDateData] = useState('');
    const [industry_idData, setIndustryIdData] = useState<any>(null);
    const [job_category_idData, setJobCategoryIdData] = useState(null);
    const [job_category_typeData, setJobCategoryTypeData] = useState(null);
    const [business_contentData, setBusinessContentData] = useState('');
    const [job_career_appeals, setJobCareerAppeals] = useState<any[]>([]);
    const [job_category_id_1, setJobCategoryId1] = useState(null);
    const [job_category_id_2, setJobCategoryId2] = useState(null);
    const [job_category_id_3, setJobCategoryId3] = useState(null);
    const [year_1, setYear1] = useState<any>('');
    const [year_2, setYear2] = useState<any>('');
    const [month_1, setMonth1] = useState<any>('');
    const [month_2, setMonth2] = useState<any>('');
    const [job_type_1, setJobType1] = useState<any>(null);
    const [job_type_2, setJobType2] = useState<any>(null);
    const [job_type_3, setJobType3] = useState<any>(null);
    const [navigation_book, setNavigationBook] = useState(null);
    const [edited, setEdited] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const pageTitle = 'WEB履歴書（職務経歴）｜パラちゃんねる';
    document.title = pageTitle;
    const queryParams = new URLSearchParams(window.location.search);
    const refs = useRef<any>(null);
    const [businessContentError, setBusinessContentError] = useState(false);
    const [businessContentErrorCount, setBusinessContentErrorCount] = useState<any>(null);    
    const [currentSlide, setCurrentSlide] = useState(0);
    useEffect(() => {
        if (!localStorage.getItem('jobParachannelAuthToken')) {
          navigate('/login');
        } 
      }, [navigate, dispatch]);

    useEffect(() => {
        dispatch(fetchRegionsRequest());
        if(queryParams.get('tab') != null || queryParams.get('next') != null) {
            setTimeout(() => {              
                refs.current?.scrollIntoView({behavior:"smooth", block:"start"})
            }, 0);
        }
    }, [dispatch]);

    useEffect(() => {
        if(!edited) return;

        window.addEventListener("beforeunload", confirmSave);

        return () => window.removeEventListener("beforeunload", confirmSave);

    },[edited]);

    function confirmSave (event: any) {
        event.preventDefault();
        if (edited) {
            event.returnValue = "編集中のデータは保存されていませんが、移動しますか？"
        }
    }
    
     useEffect(() => {
      if (loginData.pending) {
        if(!loginData.login.is_init_complete) {
          navigate('/mypage/init/');
        }
        setResourceId(loginData.login.resource.id);
        setFamylyName(loginData.login.resource.family_name);
        setAuthenticate(loginData.login.resource.id);
        dispatch(fetchApplicantsRequest(loginData.login.resource.id, 'get', 0));
      }
    }, [dispatch, loginData.pending]);

    useEffect(() => {
        if (applicants.pending && !firstLoad) {    
            const applicantData = applicants.applicants.applicant;
            setCompany(applicantData.job_career_recent.company);
            setArrivalDate(applicantData.job_career_recent.arrival_date);
            setDepartureDate(applicantData.job_career_recent.departure_date);
            setIndustryId(applicantData.job_career_recent.industry_id);
            console.log(applicantData.job_career_recent.industry_id);
            setJobCategoryId(applicantData.job_career_recent.job_category_id);
            setBusinessContent(applicantData.job_career_recent.business_content);
            if(applicantData.job_career_recent.business_content != null && applicantData.job_career_recent.business_content != '') {
                if(applicantData.job_career_recent.business_content.length > 200) {
                  setBusinessContentError(true);
                  setBusinessContentErrorCount(applicantData.job_career_recent.business_content.length);
                } else {
                  setBusinessContentError(false);        
                }
              }
            setJobCareerAppeals(applicantData.job_career_appeals);
            setJobCategoryId1(applicantData.experience_job_category.job_category_id_1);
            setJobCategoryId2(applicantData.experience_job_category.job_category_id_2);
            setJobCategoryId3(applicantData.experience_job_category.job_category_id_3);
            setNavigationBook(applicantData.navigation_book)
            setFirstLoad(true);
            setLoadScreen(false);
            if(applicantData.educational_background.new_graduate == true){
                setJobCareerCountId(1);
            } else {
                // setJobCareerCountId(null);
                setJobCareerCountId(applicantData.job_career_count_id);

            }
        } else if(applicants.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }

    }, [applicants, firstLoad]);

    useEffect(() => {
        if (regions.pending) {
            const regionsData = regions.regions;            
            setJobCareerCounts(regionsData.job_career_counts);
            setIndustries(regionsData.industries);
            setJobCategories(regionsData.job_categories);
            setJobCategoryTypes(regionsData.job_category_types);
        }
    }, [regions.pending]);

    useEffect(() => {
        if (putregion.pending) {
          if (localStorage.entryFlag) {
            if(window.confirm("エントリーページへ戻りますか？")) {
              let recruitment_id = localStorage.entryFlag;
              localStorage.removeItem('entryFlag');
              window.location.href = '/recruitments/' + recruitment_id + '/entry/';
              return;
            }
          }
        
          if (tempolary) {
              alert('保存しました');
              navigate('/mypage/');
              return;
          }
        //   navigate('/mypage/profile4/');
        window.location.href = '/mypage/profile4/?next=true';
        } else if(putregion.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
      }, [putregion]);

    const findValue = (value: any, masterName: string | number) => {
        const dataSet = regions.regions[masterName];
        if (dataSet === undefined) {
            return null;
        }
        return value && value !== undefined ? dataSet.find((v: { id: number; }) => v.id === Number(value))?.name : null;
    };
    const [jobCareerModal, setJobCareerModal] = useState(false);
    const jobCareerModalToggle = () => {
        setJobCareerModal(!jobCareerModal);
    };

    const [recentCareerModal, setRecentCareerModal] = useState(false);
    const recentCareerModalToggle = () => {
        setEdited(true);
        setRecentCareerModal(!recentCareerModal);
        setCompanyData('');
        setArrivalDateData('');
        setDepartureDateData('');
        setIndustryIdData(null);
        setJobCategoryIdData(null);;
        setJobCategoryTypeData(null);
        setYear1('');
        setYear2('');
        setMonth1('');
        setMonth2('');
        setCurrentSlide(0);
    };

    const [appealCareerModal, setAppealCareerModal] = useState(false);
    const appealCareerModalToggle = () => {
        setEdited(true);
        setAppealCareerModal(!appealCareerModal);
        setCompanyData('');
        setArrivalDateData('');
        setDepartureDateData('');
        setIndustryIdData(null);
        setJobCategoryIdData(null);;
        setJobCategoryTypeData(null);
        setYear1('');
        setYear2('');
        setMonth1('');
        setMonth2('');
        setCurrentSlide(0);
    };

    const handleDeleteAppealCareer = (index: number) => {
        setEdited(true);
        const updatedAppeals = [
          ...job_career_appeals];
        updatedAppeals.splice(index, 1);
        setJobCareerAppeals(updatedAppeals);
    };

    const [updateflg, setUpdateflg] = useState(false);
    const [indexflg, setindexflg] = useState<number>(0);
    const handleFlgAppealCareer = (index: number) => {
        setEdited(true);
        setindexflg(index);
        setUpdateflg(true);
        setAppealCareerModal(true);
    };

    const [company_name, setCompanyName] = useState('');
    const handleInputCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyName(e.target.value);
    };

    // function handleYearTwo() {    
    //     setArrivalDate(`${year_1}-${month_1}`);
    //     setDepartureDate(`${9999}-${12}`);
    //     setRecentCareerModal(false);
    //     setCurrentSlide(0); 
    // }

    // function handleAppealCareerYearTwo() {  
    //     const newAppeal = {
    //         company: companyData, 
    //         arrival_date: `${year_1}-${month_1}`,
    //         departure_date:  `${9999}-${12}`,
    //         industry_id: industry_idData,
    //         job_category_id: null,
    //         business_content: business_contentData,
    //     };
    //     setJobCareerAppeals([...job_career_appeals, newAppeal]);
    //     setCompanyName('');
    //     setCompanyData('');
    //     setArrivalDateData('');
    //     setDepartureDateData('');
    //     setIndustryIdData(null);
    //     setJobCategoryIdData(null);
    //     setJobCategoryTypeData(null);
    //     setYear1('');
    //     setYear2('');
    //     setMonth1('');
    //     setMonth2('');
    //     setAppealCareerModal(false);
    //     setCurrentSlide(0); 
    // }

    const currentYear = new Date().getFullYear();
    const startYear = 1953;
    const yearArray = [];

    if(year_1) {
        for (let year = currentYear; year >= Number(year_1); year--) {
            yearArray.push(year);
        }
    } else {
        for (let year = currentYear; year >= startYear; year--) {
            yearArray.push(year);
        }
    } 
    let months = [
        1, 2, 3, 4,
        5, 6, 7, 8,
        9, 10, 11, 12
    ];

    if (month_1 && parseInt(year_1) == parseInt(year_2)) {
        const startIndex = months.indexOf(Number(month_1));
        const filteredMonths = months.slice(startIndex);
        months = filteredMonths;
    }

    const handleInputDate = () => {
        setArrivalDate(`${year_1}-${month_1}`)
        if(year_2 != null){
            setDepartureDate(`${year_2}-${month_2}`)
        }
    };
    const handleInputRecentCareer = () => {
        setCompany(companyData);
        setCompanyName('');
        setCompanyData('');
        setArrivalDateData('');
        setDepartureDateData('');
        setIndustryIdData(null);
        setJobCategoryIdData(null);;
        setJobCategoryTypeData(null);
        setYear1('');
        setYear2('');
        setMonth1('');
        setMonth2('');
        recentCareerModalToggle();
    };

    const handleInputBussinesContent = (event: any) => {
        setEdited(true);
        if(event.target.value.length > 200) {
            setBusinessContentError(true);
            setBusinessContentErrorCount(event.target.value.length);
          } else {
            setBusinessContentError(false);      
          }
        setBusinessContent(event.target.value);
    };

    const handleInputAppealBussinesContent = (event: any, index: number) => {
        setEdited(true);
        const text = (event.target.value);
        const maxLenCount = (event.target.value.length);        
        let maxlenCheck = false;
        const updatevalue = {
            business_content: text,
            business_content_maxLength: maxlenCheck,
            business_content_lengthCount: maxLenCount,
          };
        
        const updatedAppeals = [...job_career_appeals];
        updatedAppeals[index] = {
        ...updatedAppeals[index], 
        ...updatevalue, 
        };
        if(maxLenCount > 200) {
            maxlenCheck = true;
            const updatevalue2 = {
              business_content: text,
                business_content_maxLength: maxlenCheck,
                business_content_lengthCount: maxLenCount,
            }; 
            updatedAppeals[index] = {
                ...updatedAppeals[index], 
                ...updatevalue2, 
            };
        }  
        setJobCareerAppeals(updatedAppeals);
    };

    const [jobCategoryModal1, setJobcategoryModal1] = useState(false);
    const jobcategoryModalToggle1 = () => {
        setEdited(true);
      setJobcategoryModal1(!jobCategoryModal1);
      setJobcategoryModalSlide(0);
    };
    const [jobCategoryModal2, setJobcategoryModal2] = useState(false);
    const jobcategoryModalToggle2 = () => {
        setEdited(true);
      setJobcategoryModal2(!jobCategoryModal2);
    };
    const [jobCategoryModal3, setJobcategoryModal3] = useState(false);
    const [jobCategoryModalSlide, setJobcategoryModalSlide] = useState(0);
    const jobcategoryModalToggle3 = () => {
        setEdited(true);
      setJobcategoryModal3(!jobCategoryModal3);
    };
    const [jobCategoryModal, setJobcategoryModal] = useState(1);
    const jobcategoryModalToggle = () => {
        setEdited(true);
        if(jobCategoryModal == 1) {
            setJobcategoryModal1(!jobCategoryModal1);
            setJobcategoryModalSlide(0);
        } else if(jobCategoryModal == 2) {
            setJobcategoryModal2(!jobCategoryModal2);
            setJobcategoryModalSlide(0);
        } else {
            setJobcategoryModal3(!jobCategoryModal3);
            setJobcategoryModalSlide(0);
        }  
    };

    function prev() {
        let slide = currentSlide - 1;

        if (recentCareerModal || appealCareerModal) {
            if(!year_2  && !month_2 && currentSlide == 5){
                setCurrentSlide(3);
                setJobcategoryModalSlide(0);
            } else {
                setCurrentSlide(slide);
                setJobcategoryModalSlide(0);
            }
        } else {
            setCurrentSlide(slide);
            setJobcategoryModalSlide(0);
        }

       
    }

    function complete (val: any, id: any) {
        if(id == 0) {
          if(jobCategoryModal == 1 && (job_type_2 == val || job_type_3 == val)) {
            return;
          }
          if(jobCategoryModal == 2 && (job_type_1 == val || job_type_3 == val)) {
            return;
          }
          if(jobCategoryModal == 3 && (job_type_2 == val || job_type_1 == val)) {
            return;
          }
        }
        if(jobCategoryModal == 1) {
          if(id == 0) {
            setJobType1(val);
            setJobcategoryModalSlide(1);
          } else {
            setJobType1(val.target.value);
            setJobcategoryModalSlide(1);
          }
        } else if(jobCategoryModal == 2) {
          if(id == 0) {
            setJobType2(val);
            setJobcategoryModalSlide(1);
          } else {
            setJobType2(val.target.value);
            setJobcategoryModalSlide(1);
          }
        } else {
          if(id == 0) {
            setJobType3(val);
            setJobcategoryModalSlide(1);
          } else {
            setJobType3(val.target.value);
            setJobcategoryModalSlide(1);
          }
        }
    }

    function onDepartureDate() {
        setDepartureDate(null)
    }

    const formatYM = (dateString : any) => {
        if (dateString === '9999-12') {
            return `在職中`;
        } else if (dateString === null) {
            return ''
        } 
        const [year, month] = dateString.split('-').map((component: any) => parseInt(component));
        return `${year}年${month}月`;
    };

    const handleInputCopy = () => {
        const newAppeal = {
            company: company, 
            arrival_date: arrival_date,
            departure_date:  departure_date,
            industry_id: industry_id,
            job_category_id: job_category_id,
            business_content: business_content,
          };
          setJobCareerAppeals([...job_career_appeals, newAppeal]);
    };
    const handleInputAppealCareer = (data: any) => {
        if (updateflg == true) {
            handleUpdateAppealCareer(indexflg, data);
        } else {
            const newAppeal = {
                company: companyData, 
                arrival_date: arrival_dateData,
                departure_date:  departure_dateData,
                industry_id: industry_idData,
                job_category_id: data,
                business_content: business_contentData,
            };
            setJobCareerAppeals([...job_career_appeals, newAppeal]);
            setCompanyName('');
            setCompanyData('');
            setArrivalDateData('');
            setDepartureDateData('');
            setIndustryIdData(null);
            setJobCategoryIdData(null);
            setJobCategoryTypeData(null);
            setYear1('');
            setYear2('');
            setMonth1('');
            setMonth2('');
            appealCareerModalToggle();
        }
        
    };
    const handleUpdateAppealCareer = (index: number, data: any) => {
        const updatevalue = {
            company: companyData, 
            arrival_date: arrival_dateData,
            departure_date:  departure_dateData,
            industry_id: industry_idData,
            job_category_id: data,
          };
        
          const updatedAppeals = [...job_career_appeals];
          updatedAppeals[index] = {
            ...updatedAppeals[index], 
            ...updatevalue, 
          };
          setJobCareerAppeals(updatedAppeals);
          setUpdateflg(false);
          setindexflg(0);
          setCompanyName('');
          setCompanyData('');
          setArrivalDateData('');
          setDepartureDateData('');
          setIndustryIdData(null);
          setJobCategoryIdData(null);;
          setJobCategoryTypeData(null);
          setYear1('');
          setYear2('');
          setMonth1('');
          setMonth2('');
          appealCareerModalToggle();
    };
    const handleInputAppealDate = () => {
        setArrivalDateData(`${year_1}-${month_1}`)
        setDepartureDateData(`${year_2}-${month_2}`)
    };

    function submit (e: any, tempolary1 = false) {
        if(!tempolary1) {
            e.preventDefault();
        }
        let error = false
        
        const data = {
          applicant: {
            job_career_count_id:job_career_count_id,
            experience_job_category:{
                job_category_id_1: job_category_id_1,
                job_category_id_2: job_category_id_2,
                job_category_id_3: job_category_id_3,
            },
            job_career_appeals: job_career_appeals,
            job_career_recent: {
                arrival_date: arrival_date,
                business_content: business_content,
                company: company,
                departure_date: departure_date,
                industry_id: industry_id,
                job_category_id: job_category_id,
            }
          }
        }
        setEdited(false);
        settempolary(tempolary1);
        setLoadScreen(true);
        dispatch(fetchPutRegionRequest(resourceId, data));
    }

    return (
      <main id="mypage">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" />
        {loadScreen ? <Loader /> : <></>}
        <section id="mypage_inner">
            <ProfileIndex authenticate={authenticate} navigation_book_url={navigation_book}/>
            <ProfileTab ref={refs} index={3} />
            <form className="profile wrapper">
                <div className="cont1">
                    <h3 className="ttl">職務経歴</h3>
                    <p className="ex_info">※ <span className="required">必須</span>が付いている項目はエントリーに必要な情報です。 </p>
                    <div className="wrapper">
                    <table>
                        <tbody className="other">
                        <tr>
                        <th>職歴数 <span className="any">任意</span></th>
                            <td className="nest">
                                <div className="nest_child select_cont">
                                    <p className={job_career_count_id ? 'select comp' : 'select'}>
                                    <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={jobCareerModalToggle}>
                                        {findValue(job_career_count_id, 'job_career_counts') || '未登録'}
                                    </a>
                                    </p>
                                </div>
                            </td>
                            {jobCareerModal ?
                            <div className="modal_box selectRadio1">
                                <div className="modal_inner">
                                <h4>職歴数</h4>
                                <form>
                                    <h5 className="modal_ttl">ご自身の職歴数を選択してください</h5>
                                    <div className="slider_item">
                                        {job_career_counts.map((d: any) => (
                                            <label key={d.id}>
                                                <input type="radio" name={d.name} defaultChecked={job_career_count_id == d.id} value={d.id} onClick={() => {setEdited(true);jobCareerModalToggle(); setJobCareerCountId(d.id);}} />
                                                <span>{ d.name }</span>
                                            </label>
                                        ))} 
                                    </div>
                                </form>
                                <p className="close" onClick={jobCareerModalToggle}>
                                    <span className="material-icons">close</span>
                                </p>
                                </div>
                                <div className="overlay"onClick={jobCareerModalToggle}></div>
                            </div>
                        : null }
                        </tr>
                        {job_career_count_id !== 1 &&
                        <><tr>
                        <th>直近の職歴 <span className="required">必須</span>
                        </th>
                        <td className="nest">
                            <div className="box">
                                {!job_category_id ?
                                    <div className="nest_child acc_list_cont">
                                        <p className="acc_ttl fixed">
                                            <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={recentCareerModalToggle}>直近の職歴を追加する</a>
                                        </p>
                                    </div>
                                    :
                                    <div className="nest_child">
                                        <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                                        <div className="txt_cont">
                                            <h4>直近の職歴</h4>
                                            <p>{company} / {findValue(industry_id, 'industries')}<br/>
                                            {findValue(job_category_id, 'job_categories')}<br/>
                                            {formatYM(arrival_date)}〜{formatYM(departure_date) || '現在'  }</p>
                                        </div>
                                        <div className="icon_cont">
                                            <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" alt="memo_icon" onClick={recentCareerModalToggle}/></span>
                                        </div></a>
                                        <div className="textarea_cont">
                                            <h4>業務内容（２００文字以内）</h4>
                                            <textarea cols={30} rows={10} value={business_content} onChange={handleInputBussinesContent}></textarea>
                                            {/* <p className="text_length_alert"></p> */}
                                            {businessContentError == true ?
                                                <p className="text_length_alert">{businessContentErrorCount}文字オーバー</p>
                                            : <></>}
                                        </div>
                                    </div>
                                }
                            </div>
                        </td>
                        {recentCareerModal &&  (
                          <div data-v-28bf6a70="" role="dialog" className="modal_box">
                          <div className="modal_inner">
                            <h4>直近の職歴</h4>
                            {currentSlide > 0 ?
                                <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                            : null }
                            <form>
                              <h5 className="modal_ttl">
                                {(currentSlide == 0 || !companyData) && '会社名を入力してください'  ||
                                ((companyData && !year_1) || currentSlide == 1) && '就業を開始した年を選択してください' || 
                                ((year_1 && !month_1) || currentSlide == 2) && '就業を開始した月を選択してください' ||
                                month_1 && !year_2 && currentSlide == 3 && '就業を終了した年を選択してください' ||
                                currentSlide == 3 && '就業を終了した年を選択してください' ||
                                (!year_2  && !month_2 && currentSlide == 5) && '業界を選択してください' || 
                                (currentSlide == 5) && '業界を選択してください' || 
                                ((year_2 && !month_2) || currentSlide == 4) && '就業を終了した月を選択してください' ||
                                month_2 && !industry_idData && '業界を選択してください' ||
                                ((industry_idData && !job_category_typeData) ||currentSlide == 6 ) && '職種のカテゴリを選択してください' || 
                                
                                job_category_typeData && !job_category_idData && '職種を選択してください'}</h5>
                              <div data-v-3d1a4f76="" dir="ltr" className="slider slick-slider slick-initialized">
                                <div data-v-3d1a4f76="" className="slick-list">
                                  <div data-v-3d1a4f76="" className="slick-track" style={{opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%"}}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{width: "100%", display: "inline-block"}}>
                                          {currentSlide == 0 && (
                                          <><label className="input_text">
                                                <input type="text" value={company_name} onChange={handleInputCompany} />
                                            </label><a id="btn_next" onClick={() => { company_name ? setCompanyData(company_name):setCompanyData('未登録'); setCurrentSlide(1);}}> 次へ</a></>                           
                                          )}   
                                          {currentSlide == 1 &&
                                              yearArray.map((year) => (
                                                  <label className="column3" key={year}>
                                                    <input type="radio" name="" value={year} onClick={() => { setYear1(year.toString()); setCurrentSlide(2);}} defaultChecked={year_1 === year.toString()}/>
                                                    <span>{year} 年</span>
                                                  </label>
                                                ))
                                          }
                                          {currentSlide == 2 &&
                                              months.map((month) => (
                                                <label className="column3" key={month}>
                                                    <input type="radio" name="" value={month} onClick={() => { setMonth1(month.toString()); setCurrentSlide(3);}} defaultChecked={month_1 === month.toString()}/>
                                                    <span>{month} 月</span>
                                                </label>
                                            ))
                                          }
                                          {currentSlide == 3 && (
                                            <label className="column3" >
                                                <input type="radio" name="" value={'在職中'} onClick={() => { setYear2(null); setMonth2(null); setCurrentSlide(5);onDepartureDate()}} checked={year_2 === null} />
                                                    <span>在職中</span>
                                                </label>
                                          )}
                                          {currentSlide == 3 &&
                                              yearArray
                                                .map((year) => (
                                                  <label className="column3" key={year}>
                                                    <input type="radio" name="" value={year} onClick={() => { setYear2(year.toString()); setCurrentSlide(4);}} defaultChecked={year_2 === year.toString()}/>
                                                    <span>{year} 年</span>
                                                  </label>
                                                ))
                                          }
                                          {currentSlide == 4 &&
                                              months
                                                .map((month) => (
                                                    <label  className="column3" key={month}>
                                                        <input type="radio" name="" value={month} onClick={() => { setMonth2(month.toString()); setCurrentSlide(5);}} defaultChecked={month_2 === month.toString()}/>
                                                        <span>{month} 月</span>
                                                    </label>
                                                ))
                                          }
                                          {currentSlide == 5 &&
                                              industries
                                                .map((d: any, index: any) => (
                                                    <label className="column3" key={index}>
                                                        <input type="radio" name="" value={d.id } onClick={() => { setIndustryIdData(d.id); setIndustryId(d.id); handleInputDate(); setCurrentSlide(6);}} defaultChecked = {industry_id === d.id}/>
                                                        <span>{ d.name }</span>
                                                    </label>
                                                ))
                                          }
                                          {currentSlide == 6 &&
                                              job_category_types
                                              .map((d: any, index: any) => (
                                                <label className="column3" key={index}>
                                                    <input type="radio" name="" value={d.id} onClick={() => { setJobCategoryTypeData(d.id); setJobCategoryType(d.id); setCurrentSlide(7);}} defaultChecked = {job_category_type === d.id}/>
                                                    <span>{ d.name }</span>
                                                </label>
                                            ))
                                          }
                                          {currentSlide == 7 &&
                                              job_categories
                                                .filter(data => data.job_category_type_id === job_category_typeData) 
                                                .map((d: any, index: any) => (
                                                    <label className="column3" key={index}>
                                                        <input type="radio" name="" value={d.id} onClick={() => {setJobCategoryIdData(d.id); setJobCategoryId(d.id); handleInputRecentCareer(); setCurrentSlide(0);}} defaultChecked = {job_category_id === d.id}/>
                                                        <span>{ d.name }</span>
                                                    </label>
                                                ))
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close" onClick={recentCareerModalToggle}><span className="material-icons">close</span></p>
                          </div>
                          <div className="overlay" onClick={recentCareerModalToggle}></div>
                          </div>
                          )}
                        </tr>
                        <tr>
                            <th>アピールしたい職歴 <span className="any">任意</span>
                            </th>
                            <td className="nest">
                                {job_career_appeals.length === 0 ?
                                    <button className="btn type4 copy_btn" onClick={handleInputCopy}>
                                        <span></span>直近の職歴と同じ </button>
                                :null}
                                <div className="box">
                                {job_career_appeals.length === 0 ?
                                    <div className="nest_child acc_list_cont">
                                        <p className="acc_ttl fixed">
                                            <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={appealCareerModalToggle}>アピールしたい職歴を追加する</a>
                                        </p>
                                    </div>
                                    :
                                    <div className="nest_child">
                                        {job_career_appeals.map((item, index) => (
                                        <><a key={index} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed" onClick={() => appealCareerModalToggle()}>
                                        <div className="txt_cont">
                                            <h4>アピールしたい職歴</h4>
                                            <p>
                                                {item.company || '会社名未入力'} / {findValue(item.industry_id, 'industries') || '未登録'} <br />
                                                {findValue(item.job_category_id, 'job_categories') || '未登録'} <br />
                                                {formatYM(item.arrival_date)} 〜 {formatYM(item.departure_date) || '現在'}
                                            </p>
                                        </div>
                                        <div className="icon_cont">
                                            <span className="edit">
                                                <img src="/assets/img/mypage/memo_icon.svg" onClick={() => handleFlgAppealCareer(index)} alt="memo_icon" />
                                            </span>
                                        </div>
                                        </a><div className="textarea_cont">
                                               <h4>業務内容（２００文字以内）</h4>
                                               <textarea cols={30} rows={10} value={item.business_content} 
                                               onChange={(event) => {handleInputAppealBussinesContent(event, index)}}></textarea>
                                               {/* <p className="text_length_alert"></p> */}
                                               {job_career_appeals[index].business_content_maxLength && job_career_appeals[index].business_content_maxLength == true ?
                                                    <p className="text_length_alert">{job_career_appeals[index].business_content_lengthCount}文字オーバー</p>
                                                : <></>}
                                            </div>
                                            <p className="acc_ttl del">
                                               <a onClick={() => handleDeleteAppealCareer(index)}>アピールしたい職歴を削除する</a>
                                            </p></>

                                        ))}
                                        <div className="nest_child acc_list_cont">
                                        <p className="acc_ttl">
                                            <a onClick={() => appealCareerModalToggle()} title="ポップアップが立ち上がります。TABキーを押して選択してください。">
                                            アピールしたい職歴を追加する
                                            </a>
                                        </p>

                                        </div>
                                    </div>
                                }
                                </div>
                            </td>
                            {appealCareerModal &&  (
                            <div data-v-28bf6a70="" role="dialog" className="modal_box">
                            <div className="modal_inner">
                                <h4>アピールしたい職歴</h4>
                                {currentSlide > 0 ?
                                    <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                                : null }
                                <form>
                                <h5 className="modal_ttl">{
                                (!companyData || currentSlide == 0) && '0会社名を入力してください' ||
                                ((companyData && !year_1) || currentSlide == 1) && '1就業を開始した年を選択してください' ||
                                ((year_1 && !month_1) || currentSlide == 2) && '2就業を開始した月を選択してください' ||
                                month_1 && !year_2 && currentSlide == 3 && '3就業を終了した年を選択してください' ||
                                currentSlide == 3 && '3就業を終了した年を選択してください' ||
                                (!year_2 && !month_2 && currentSlide == 5) && '5業界を選択してください' ||
                                (currentSlide == 5) && '5業界を選択してください' ||
                                 ((year_2 && !month_2) || currentSlide == 4) && '4就業を終了した月を選択してください' ||
                                month_2 && !industry_idData && '5業界を選択してください' ||
                                ((industry_idData && !job_category_typeData) || currentSlide == 6) && '6職種のカテゴリを選択してください' || 
                                job_category_typeData && !job_category_idData && '7職種を選択してください'}</h5>
                                <div data-v-3d1a4f76="" dir="ltr" className="slider slick-slider slick-initialized">
                                    <div data-v-3d1a4f76="" className="slick-list">
                                    <div data-v-3d1a4f76="" className="slick-track" style={{opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%"}}>
                                        <div aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                        <div>
                                            <div tabIndex={-1} className="slider_item" style={{width: "100%", display: "inline-block"}}>
                                            {currentSlide == 0 && (
                                            <><label className="input_text">
                                                    <input type="text" value={company_name} onChange={handleInputCompany} />
                                                </label><a id="btn_next" onClick={() => { company_name ? setCompanyData(company_name):setCompanyData('未登録'); setCurrentSlide(1);}}> 次へ</a></>                           
                                            )}   
                                            {currentSlide == 1 &&
                                                yearArray.map((year) => (
                                                    <label className="column3" key={'appealCareerArrivalYear_'+year}>
                                                        <input type="radio" name="" value={year} onClick={() => { setYear1(year.toString()); setCurrentSlide(2); }} defaultChecked={year_1 === year.toString()}/>
                                                        <span>{year} 年</span>
                                                    </label>
                                                    ))
                                            }
                                            {currentSlide == 2 &&
                                                months.map((month) => (
                                                    <label className="column3" key={'appealCareerArrivalMonth_'+month}>
                                                        <input type="radio" name="" value={month} onClick={() => { setMonth1(month.toString()); setCurrentSlide(3);}} defaultChecked={month_1 === month.toString()}/>
                                                        <span>{month} 月</span>
                                                    </label>
                                                ))
                                            }
                                            {currentSlide == 3 && (
                                                <label className="column3" >
                                                    <input type="radio" name="" value={'在職中'} onClick={() => { setYear2(null); setMonth2(null); setCurrentSlide(5);onDepartureDate()}} checked={year_2 === null} />
                                                        <span>在職中</span>
                                                    </label>
                                            )}
                                            {currentSlide == 3 &&
                                                yearArray
                                                    .map((year) => (
                                                    <label className="column3" key={'appealCareerDepartureYear_'+year}>
                                                        <input type="radio" name="" value={year} onClick={() => { setYear2(year.toString()); setCurrentSlide(4);}} defaultChecked={year_2 === year.toString()}/>
                                                        <span>{year} 年</span>
                                                    </label>
                                                    ))
                                            }
                                            {currentSlide == 4 &&
                                                months
                                                    .map((month) => (
                                                        <label  className="column3" key={'appealCareerDepartureMonth_'+month}>
                                                            <input type="radio" name="" value={month} onClick={() => { setMonth2(month.toString()); setCurrentSlide(5);}} defaultChecked={month_2 === month.toString()}/>
                                                            <span>{month} 月</span>
                                                        </label>
                                                    ))
                                            }
                                            {currentSlide == 5 &&
                                                industries
                                                    .map((d: any, index: any) => (
                                                        <label className="column3" key={index}>
                                                            <input type="radio" name="" value={d.id} onClick={() => { setIndustryIdData(d.id); handleInputAppealDate(); setCurrentSlide(6);}} defaultChecked = { industry_idData === d.id} />
                                                            <span>{ d.name }</span>
                                                        </label>
                                                    ))
                                            }
                                            {currentSlide == 6 &&
                                                job_category_types
                                                .map((d: any, index: any) => (
                                                    <label className="column3" key={index}>
                                                        <input type="radio" name="" value={d.id} onClick={() => { setJobCategoryTypeData(d.id); setCurrentSlide(7); }} defaultChecked = { job_category_typeData === d.id}/>
                                                        <span>{ d.name }</span>
                                                    </label>
                                                ))
                                            }
                                            {currentSlide == 7 &&
                                                job_categories
                                                    .filter(data => data.job_category_type_id === job_category_typeData) 
                                                    .map((d: any, index: any) => (
                                                        <label className="column3" key={index}>
                                                            <input type="radio" name="" value={d.id} onClick={() => {handleInputAppealCareer(d.id); setCurrentSlide(0);}} />
                                                            <span>{ d.name }</span>
                                                        </label>
                                                    ))
                                            }
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </form>
                                <p className="close" onClick={appealCareerModalToggle}><span className="material-icons">close</span></p>
                            </div>
                            <div className="overlay" onClick={appealCareerModalToggle}></div>
                            </div>
                          )}
                        </tr>
                        <tr>
                            <th>アピールしたい経験職種 <br className="pc_only" />
                                <span className="any">任意</span>
                            </th>
                            <td className="nest">
                                <div className="box">
                                    <div className="nest_child select_cont">
                                        <h4>経験職種1</h4>
                                        <p className={job_category_id_1 ? 'select comp' : 'select'}>
                                            <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={(e) => {jobcategoryModalToggle1(); setJobcategoryModal(1); 
                                                setJobcategoryModalSlide(0);}}>
                                                {findValue(job_category_id_1, 'job_categories') || '未登録' }
                                            </a>
                                        </p>
                                    </div>
                                    
                                    <div className="nest_child select_cont">
                                        <h4>経験職種2</h4>
                                        <p className={job_category_id_2 ? 'select comp' : 'select'}>
                                            <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={(e) => {jobcategoryModalToggle2(); setJobcategoryModal(2);
                                                setJobcategoryModalSlide(0);}}>
                                                {findValue(job_category_id_2, 'job_categories') || '未登録' }
                                            </a>
                                        </p>
                                    </div>

                                    <div className="nest_child select_cont">
                                        <h4>経験職種3</h4>
                                        <p className={job_category_id_3 ? 'select comp' : 'select'}>
                                            <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={(e) => {jobcategoryModalToggle3(); setJobcategoryModal(3);                                                
                                                setJobcategoryModalSlide(0);}} >
                                                {findValue(job_category_id_3, 'job_categories') || '未登録' }
                                            </a>
                                        </p>
                                    </div>
                                    {jobCategoryModal1 && jobCategoryModal == 1 ? (
                                        <div className="modal_box selectRadio1">
                                            <div className="modal_inner">
                                                <h4>経験職種1</h4>
                                                {jobCategoryModalSlide > 0 ?
                                                    <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                                                : null }
                                                <form>
                                                    <h5 className="modal_ttl">経験したことのある職種を選択してください</h5>
                                                    <div className="slider_item">
                                                        {jobCategoryModalSlide == 0 &&
                                                            <label className="column3">
                                                                <input type="radio" name="radio1" 
                                                                    onClick={() => {jobcategoryModalToggle1(); setJobType1(null); setJobCategoryId1(null)}} />
                                                                <span>なし</span>
                                                            </label>
                                                        }
                                                        {jobCategoryModalSlide == 0 &&                                                            
                                                            job_category_types.map((d: any) => (
                                                            <label key={d.id}>
                                                                <input type="radio" name={d.name} defaultChecked={job_type_1 == d.id} value={d.id} 
                                                                    onClick={(e) => {complete(e, 1)}}
                                                                />
                                                                <span>{d.name}</span>
                                                            </label>
                                                        ))}
                                                        {jobCategoryModalSlide == 1 && job_categories
                                                            .filter(data => data.job_category_type_id == job_type_1 && data.id != job_category_id_1 && data.id != job_category_id_2 && data.id != job_category_id_3)
                                                            .map((d: any) => (
                                                            <label key={d.id}>
                                                                <input type="radio" name={d.name} defaultChecked={job_category_id_1 == d.id} value={d.id} 
                                                                    onClick={() => { jobcategoryModalToggle(); setJobCategoryId1(d.id);}} />
                                                                <span>{d.name}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </form>
                                                <p className="close" onClick={jobcategoryModalToggle}>
                                                    <span className="material-icons">close</span>
                                                </p>
                                            </div>
                                            <div className="overlay" onClick={jobcategoryModalToggle}></div>
                                        </div>
                                    ) : null}
                                    {jobCategoryModal2 && jobCategoryModal == 2 ? (
                                        <div className="modal_box selectRadio1">
                                            <div className="modal_inner">
                                                <h4>経験職種2</h4>
                                                {jobCategoryModalSlide > 0 ?
                                                    <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                                                : null }
                                                <form>
                                                    <h5 className="modal_ttl">経験したことのある職種を選択してください</h5>
                                                    <div className="slider_item">
                                                        {jobCategoryModalSlide == 0 &&
                                                            <label className="column3">
                                                                <input type="radio" name="radio1" onClick={() => {jobcategoryModalToggle2(); setJobType2(null); setJobCategoryId2(null)}} />
                                                                <span>なし</span>
                                                            </label>
                                                        }
                                                        {jobCategoryModalSlide == 0 &&
                                                            job_category_types.map((d: any) => (
                                                            <label key={d.id}>
                                                                <input type="radio" name={d.name} defaultChecked={job_type_2 == d.id} value={d.id} 
                                                                    onClick={(e) => {complete(e, 1)}}
                                                                />
                                                                <span>{d.name}</span>
                                                            </label>
                                                        ))}
                                                        {jobCategoryModalSlide == 1 && job_categories
                                                            .filter(data => data.job_category_type_id == job_type_2 && data.id != job_category_id_1 && data.id != job_category_id_2 && data.id != job_category_id_3)
                                                            .map((d: any) => (
                                                            <label key={d.id}>
                                                                <input type="radio" name={d.name} defaultChecked={job_category_id_2 == d.id} value={d.id} 
                                                                    onClick={() => { jobcategoryModalToggle(); setJobCategoryId2(d.id);}}/>
                                                                <span>{d.name}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </form>
                                                <p className="close" onClick={jobcategoryModalToggle}>
                                                    <span className="material-icons">close</span>
                                                </p>
                                            </div>
                                            <div className="overlay" onClick={jobcategoryModalToggle}></div>
                                        </div>
                                    ) : null}
                                    {jobCategoryModal3 && jobCategoryModal == 3 ? (
                                        <div className="modal_box selectRadio1">
                                            <div className="modal_inner">
                                                <h4>経験職種3</h4>
                                                {jobCategoryModalSlide > 0 ?
                                                    <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                                                : null }
                                                <form>
                                                    <h5 className="modal_ttl">経験したことのある職種を選択してください</h5>
                                                    <div className="slider_item">
                                                        {jobCategoryModalSlide == 0 &&
                                                            <label className="column3">
                                                                <input type="radio" name="radio1" onClick={() => {jobcategoryModalToggle3(); setJobType3(null); setJobCategoryId3(null)}} />
                                                                <span>なし</span>
                                                            </label>
                                                        }
                                                        {jobCategoryModalSlide == 0 &&
                                                            job_category_types.map((d: any) => (
                                                            <label key={d.id}>
                                                                <input type="radio" name={d.name} defaultChecked={job_type_3 == d.id} value={d.id} 
                                                                    onClick={(e) => {complete(e, 1)}}
                                                                />
                                                                <span>{d.name}</span>
                                                            </label>
                                                        ))}
                                                        {jobCategoryModalSlide == 1 && job_categories
                                                            .filter(data => data.job_category_type_id == job_type_3 && data.id != job_category_id_1 && data.id != job_category_id_2 && data.id != job_category_id_3) 
                                                            .map((d: any) => (
                                                            <label key={d.id}>
                                                                <input type="radio" name={d.name} defaultChecked={job_category_id_3 == d.id} value={d.id} 
                                                                    onClick={() => { jobcategoryModalToggle(); setJobCategoryId3(d.id);}} 
                                                                    />
                                                                <span>{d.name}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </form>
                                                <p className="close" onClick={jobcategoryModalToggle}>
                                                    <span className="material-icons">close</span>
                                                </p>                                                        
                                            </div>
                                            <div className="overlay" onClick={jobcategoryModalToggle}></div>
                                        </div>
                                    ) : null}
                                </div>
                            </td>
                        </tr></>
                        }
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="btn_box">
                    <button type="button" onClick={(e) => submit(e, true)} className="btn type3">一時保存する</button> 
                    <button type="submit" onClick={(e) => submit(e, false)} className="btn type3 orange">保存して次へ         
                    <img src="/assets/img/mypage/arrow02.svg" alt=""/>
                </button>
                </div>
                </form>
            </section>
      </main>
    );
}