import { adminNewsCompanyDetailTypes } from "../../ActionTypes/adminNewsCompanyDetailTypes";
import { FetchAdminNewsCompanyDetailRequest, FetchAdminNewsCompanyDetailSuccessPayload, FetchAdminNewsCompanyDetailSuccess, FetchAdminNewsCompanyDetailFailurePayload, FetchAdminNewsCompanyDetailFailure } from "../../types/type";

export const fetchAdminNewsCompanyDetailRequest = (data: any): FetchAdminNewsCompanyDetailRequest => ({
  type: adminNewsCompanyDetailTypes.FETCH_ADMIN_NEWS_COMPANY_DETAIL_REQUEST,
  data
});

export const fetchAdminNewsCompanyDetailSuccess = (
  payload: FetchAdminNewsCompanyDetailSuccessPayload
): FetchAdminNewsCompanyDetailSuccess => ({
  type: adminNewsCompanyDetailTypes.FETCH_ADMIN_NEWS_COMPANY_DETAIL_SUCCESS,
  payload
});

export const fetchAdminNewsCompanyDetailFailure = (
  payload: FetchAdminNewsCompanyDetailFailurePayload
): FetchAdminNewsCompanyDetailFailure => ({
  type: adminNewsCompanyDetailTypes.FETCH_ADMIN_NEWS_COMPANY_DETAIL_FAILURE,
  payload
});