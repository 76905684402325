import { settingsTypes } from "../../ActionTypes/settingsTypes";
import { SettingsActions, SettingsState } from "../../types/type";



const initialState: SettingsState = {
  pending: false,
  settings: [],
  error: null,
};

export default (state = initialState, action: SettingsActions,) => {
  switch (action.type) {
    case settingsTypes.FETCH_SETTINGS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case settingsTypes.FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        pending: true,
        settings: action.payload.settings,
        error: null
      };
    case settingsTypes.FETCH_SETTINGS_FAILURE:
      return {
        ...state,
        pending: false,
        settings: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
