import { PutRegionTypes } from "../../ActionTypes/putRegionTypes";
import { FetchPutRegionFailure, FetchPutRegionFailurePayload, FetchPutRegionRequest, FetchPutRegionSuccess, FetchPutRegionSuccessPayload } from "../../types/type";


export const fetchPutRegionRequest = (applicants_id: any, queryParams: any): FetchPutRegionRequest => ({
  type: PutRegionTypes.FETCH_PUT_REGION_REQUEST,
  applicants_id,
  queryParams
});

export const fetchPutRegionSuccess = (
  payload: FetchPutRegionSuccessPayload,
): FetchPutRegionSuccess => ({
  type: PutRegionTypes.FETCH_PUT_REGION_SUCCESS,
  payload
});

export const fetchPutRegionFailure = (
  payload: FetchPutRegionFailurePayload
): FetchPutRegionFailure => ({
  type: PutRegionTypes.FETCH_PUT_REGION_FAILURE,
  payload
});
