
import { matomeListTypes } from "../../ActionTypes/matomeListTypes";
import { FetchMatomeListFailure, FetchMatomeListFailurePayload, FetchMatomeListRequest, FetchMatomeListSuccess, FetchMatomeListSuccessPayload } from "../../types/type";

export const fetchMatomeListRequest = (): FetchMatomeListRequest => ({
  type: matomeListTypes.FETCH_MATOME_LIST_REQUEST,
});

export const fetchMatomeListSuccess = (
  payload: FetchMatomeListSuccessPayload,
): FetchMatomeListSuccess => ({
  type: matomeListTypes.FETCH_MATOME_LIST_SUCCESS,
  payload
});

export const fetchMatomeListFailure = (
  payload: FetchMatomeListFailurePayload
): FetchMatomeListFailure => ({
  type: matomeListTypes.FETCH_MATOME_LIST_FAILURE,
  payload
});
