import { companiesTypes } from "../../ActionTypes/companiesTypes";
import { FetchCompaniesFailure, FetchCompaniesFailurePayload, FetchCompaniesRequest, FetchCompaniesSuccess, FetchCompaniesSuccessPayload } from "../../types/type";


export const fetchCompaniesRequest = (id:any, types: any, data: any): FetchCompaniesRequest => ({
  type: companiesTypes.FETCH_COMPANIES_REQUEST,
  id,
  types,
  data,
});

export const fetchCompaniesSuccess = (
  payload: FetchCompaniesSuccessPayload,
): FetchCompaniesSuccess => ({
  type: companiesTypes.FETCH_COMPANIES_SUCCESS,
  payload
});

export const fetchCompaniesFailure = (
  payload: FetchCompaniesFailurePayload
): FetchCompaniesFailure => ({
  type: companiesTypes.FETCH_COMPANIES_FAILURE,
  payload
});
