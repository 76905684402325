import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruitmentsListTypes } from "../../ActionTypes/recruitmentsListTypes";
import { fetchRecruitmentsListFailure, fetchRecruitmentsListSuccess } from "../../actions/recruitmentsListActions/recruitmentsListActions";

// var numb  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

// var getRecruitmentsList = (queryParams:any) => axios.get(API_BASE_ADDRESS + '/recruitments?' + queryParams , {headers: { Accept: 'application/json'}}); 
var getRecruitmentsList = (id:any, types: any, queryParams: any) => {
  if(types == 'get') {
    if(!localStorage.jobParachannelAuthToken) {
      return axios.get(API_BASE_ADDRESS + '/recruitments?' + queryParams, {headers: { Accept: 'application/json'}});
    } else {
      return axios.get(API_BASE_ADDRESS + '/recruitments?' + queryParams, {headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken}});
    }
  } else {
    return axios.post(API_BASE_ADDRESS + '/recruitments/' , queryParams, {headers: { Accept: 'application/json'}});
  }
}

function* fetchRecruitmentsListSaga(id: any) {
  try {
    const response: AxiosResponse = yield call(getRecruitmentsList, id.id, id.types, id.queryParams);

    yield put(
      fetchRecruitmentsListSuccess({
        recruitmentsList: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitmentsListFailure({
        error: e.message
      })
    );
  }
}

function* RecruitmentsListSaga() {
  yield takeLatest(recruitmentsListTypes.FETCH_RECRUIMENTS_LIST_REQUEST, fetchRecruitmentsListSaga);
}

export default RecruitmentsListSaga;
