import { PutRegionTypes } from "../../ActionTypes/putRegionTypes";
import { PutRegionActions, PutRegionState } from "../../types/type";



const initialState: PutRegionState = {
  pending: false,
  error: null,
};

export default (state = initialState, action: PutRegionActions,) => {
  switch (action.type) {
    case PutRegionTypes.FETCH_PUT_REGION_REQUEST:
      return {
        ...state,
        pending: false
      };
    case PutRegionTypes.FETCH_PUT_REGION_SUCCESS:
      return {
        ...state,
        pending: true,
        error: null
      };
    case PutRegionTypes.FETCH_PUT_REGION_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
