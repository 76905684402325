import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { savedConditionsCreateTypes } from "../../ActionTypes/savedConditionsCreateTypes";
import { fetchSavedConditionsCreateFailure, fetchSavedConditionsCreateSuccess } from "../../actions/savedConditionsCreateActions/savedConditionsCreateActions";

var getSavedConditionsCreate = (id: any, queryParams: any) => axios.post( API_BASE_ADDRESS + '/saved_conditions/?applicant_id=' + id, queryParams, {headers: { Accept: '*/*', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 

function* fetchSavedConditionsCreateSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getSavedConditionsCreate, queryParams.id, queryParams.queryParams);
    yield put(
      fetchSavedConditionsCreateSuccess({
        savedConditionsCreate: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchSavedConditionsCreateFailure({
        error: e.message
      })
    );
  }
}

function* SavedConditionsCreateSaga() {
  yield takeLatest(savedConditionsCreateTypes.FETCH_SAVED_CONDITIONS_CREATE_REQUEST, fetchSavedConditionsCreateSaga);
}

export default SavedConditionsCreateSaga;
