import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruitmentsCompanyTypes } from "../../ActionTypes/recruitmentsCompanyTypes";
import { fetchRecruitmentsCompanyFailure, fetchRecruitmentsCompanySuccess } from "../../actions/recruitmentsCompanyActions/recruitmentsCompanyActions";

// var numb  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

var getRecruitmentsCompany = (company_id:any, page:any) => axios.get(API_BASE_ADDRESS + '/recruitments/?company_id=' + company_id + '&page=' + page, {headers: { Accept: 'application/json',Authorization: localStorage.getItem('jobParachannelAuthToken')}}); 

function* fetchRecruitmentsCopmanySaga(actions: any) {
  try {
    const response: AxiosResponse = yield call(getRecruitmentsCompany, actions.company_id, actions.page);
    yield put(
      fetchRecruitmentsCompanySuccess({
        company: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitmentsCompanyFailure({
        error: e.message
      })
    );
  }
}

function* RecruitmentsCompanySaga() {
  yield takeLatest(recruitmentsCompanyTypes.FETCH_RECRUIMENTS_COMPANY_REQUEST, fetchRecruitmentsCopmanySaga);
}

export default RecruitmentsCompanySaga;
