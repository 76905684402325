import { adminApplicantsPostTypes } from "../../ActionTypes/adminApplicantsPostTypes";
import { FetchAdminApplicantsPostFailure, FetchAdminApplicantsPostFailurePayload, FetchAdminApplicantsPostRequest, FetchAdminApplicantsPostSuccess, FetchAdminApplicantsPostSuccessPayload } from "../../types/type";


export const fetchAdminApplicantsPostRequest = (body: any, method:any, id:any): FetchAdminApplicantsPostRequest => ({
  type: adminApplicantsPostTypes.FETCH_ADMIN_APPLICANTS_POST_REQUEST,
  body,
  method,
  id,
});

export const fetchAdminApplicantsPostSuccess = (
  payload: FetchAdminApplicantsPostSuccessPayload
): FetchAdminApplicantsPostSuccess => ({
  type: adminApplicantsPostTypes.FETCH_ADMIN_APPLICANTS_POST_SUCCESS,
  payload
});

export const fetchAdminApplicantsPostFailure = (
  payload: FetchAdminApplicantsPostFailurePayload
): FetchAdminApplicantsPostFailure => ({
  type: adminApplicantsPostTypes.FETCH_ADMIN_APPLICANTS_POST_FAILURE,
  payload
});