import { columnsTypes } from "../../ActionTypes/columnsTypes";
import { FetchColumnsFailure, FetchColumnsFailurePayload, FetchColumnsRequest, FetchColumnsSuccess, FetchColumnsSuccessPayload} from "../../types/type";


export const fetchColumnsRequest = (id:any): FetchColumnsRequest => ({
  type: columnsTypes.FETCH_COLUMNS_REQUEST,
  id,
});

export const fetchColumnsSuccess = (
  payload: FetchColumnsSuccessPayload,
): FetchColumnsSuccess => ({
  type: columnsTypes.FETCH_COLUMNS_SUCCESS,
  payload
});

export const fetchColumnsFailure = (
  payload: FetchColumnsFailurePayload
): FetchColumnsFailure => ({
  type: columnsTypes.FETCH_COLUMNS_FAILURE,
  payload
});
