import axios, { AxiosResponse } from "axios";
import { API_BASE_ADDRESS } from "../../API";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchRecruiterReceiptPostFailure, fetchRecruiterReceiptPostSuccess } from "../../actions/recruiterReceiptPostActions/recruiterReceiptPostActions";
import { recruiterReceiptPostTypes } from "../../ActionTypes/recruiterReceiptPostTypes";

var postReceipt = (data: any) => axios.post(API_BASE_ADDRESS + "/recruiter/receipt_address", data , {
  headers: {
  Authorization: localStorage.getItem('jobParachannelAuthToken'),
  Accept: '*/*',
  }
})
.then(response=>{
  return response;
})
.catch(function (error) {
  return error;
}); 

function* fetchRecruiterReceiptPostSaga(queryParams: any) {
  const response: AxiosResponse = yield call(postReceipt, queryParams.data);
  try {
    yield put(
      fetchRecruiterReceiptPostSuccess({
        recruiterReceipt: response.data, 
        status: response.status        
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruiterReceiptPostFailure({
        error: e.message
      })
    );
  }
}

function* recruiterReceiptPostSaga() {
  yield takeLatest(recruiterReceiptPostTypes.FETCH_RECRUITER_RECEIPT_POST_REQUEST, fetchRecruiterReceiptPostSaga);
}

export default recruiterReceiptPostSaga;
