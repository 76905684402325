import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminNewsListTypes } from "../../ActionTypes/adminNewsListTypes";
import { fetchAdminNewsListFailure, fetchAdminNewsListSuccess } from "../../actions/adminNewsListActions/adminNewsListActions";


const getAdminNewsList = (data: any) => { 
  const config = {
    headers: { 
      Prefer: 'example=example-1', Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/news?page='  + data.page, config); 
}


function* fetchAdminNewsListSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminNewsList, action.data);
    yield put(
      fetchAdminNewsListSuccess({
        newsArray: response.data.newsArray,
        pagenation: response.data.pagenation,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminNewsListFailure({
        error: e.message
      })
    );
  }
}

function* AdminNewsListSaga() {
  yield takeLatest(adminNewsListTypes.FETCH_ADMIN_NEWS_LIST_REQUEST, fetchAdminNewsListSaga);
}
export default AdminNewsListSaga;