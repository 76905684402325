import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminApplicantsDetailTypes } from "../../ActionTypes/adminApplicantsDetailTypes";
import { fetchAdminApplicantsDetailFailure, fetchAdminApplicantsDetailSuccess } from "../../actions/adminApplicantsDetailActions/adminApplicantsDetailActions";

const getAdminApplicantsDetail = (id: any) => { 
  const config = {
    headers: { 
      Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/applicants/' + id , config); 
}


function* fetchAdminApplicantsDetailSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminApplicantsDetail, action.id);
    yield put(
      fetchAdminApplicantsDetailSuccess({
        applicant: response.data.applicant,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminApplicantsDetailFailure({
        error: e.message
      })
    );
  }
}

function* AdminApplicantsDetailSaga() {
  yield takeLatest(adminApplicantsDetailTypes.FETCH_ADMIN_APPLICANTS_DETAIL_REQUEST, fetchAdminApplicantsDetailSaga);
}
export default AdminApplicantsDetailSaga;