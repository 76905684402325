import { scoutsCompanyTypes } from "../../ActionTypes/scoutsCompayTypes";
import { ScoutsCompanyActions, ScoutsCompanyState } from "../../types/type";



const initialState: ScoutsCompanyState = {
  pending: false,
  scoutsCompany: [],
  error: null,
};

export default (state = initialState, action: ScoutsCompanyActions,) => {
  switch (action.type) {
    case scoutsCompanyTypes.FETCH_SCOUTS_COMPANY_REQUEST:
      return {
        ...state,
        pending: false
      };
    case scoutsCompanyTypes.FETCH_SCOUTS_COMPANY_SUCCESS:
      return {
        ...state,
        pending: true,
        scoutsCompany: action.payload.scoutsCompany,
        error: null
      };
    case scoutsCompanyTypes.FETCH_SCOUTS_COMPANY_FAILURE:
      return {
        ...state,
        pending: false,
        scoutsCompany: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
