import { adminRecruitmentDetailTypes } from "../../ActionTypes/adminRecruitmentDetailTypes";
import { AdminRecruitmentDetailActions, AdminRecruitmentDetailState } from "../../types/type";



const initialState: AdminRecruitmentDetailState = {
  pending: false,
  recruitments: [],
  company:[],
  error: null,
};

export default (state = initialState, action: AdminRecruitmentDetailActions,) => {
  switch (action.type) {
    case adminRecruitmentDetailTypes.FETCH_ADMIN_RECRUITMENT_DETAIL_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminRecruitmentDetailTypes.FETCH_ADMIN_RECRUITMENT_DETAIL_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitments: action.payload.recruitments,
        company: action.payload.company,
        error: null
      };
    case adminRecruitmentDetailTypes.FETCH_ADMIN_RECRUITMENT_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        recruitments: [],
        company:[],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
