import { MessagesPostTypes } from "../../ActionTypes/messagesPostTypes ";
import { FetchMessagesPostFailure, FetchMessagesPostFailurePayload, FetchMessagesPostSuccess, FetchMessagesPostSuccessPayload, FetchMessagesPostRequest } from "../../types/type";


export const fetchMessagesPostRequest = (data: any, message: any): FetchMessagesPostRequest => ({
  type: MessagesPostTypes.FETCH_MESSAGES_POST_REQUEST,
  data,
  message
});

export const fetchMessagesPostSuccess = (
  payload: FetchMessagesPostSuccessPayload,
): FetchMessagesPostSuccess => ({
  type: MessagesPostTypes.FETCH_MESSAGES_POST_SUCCESS,
  payload
});

export const fetchMessagesPostFailure = (
  payload: FetchMessagesPostFailurePayload
): FetchMessagesPostFailure => ({
  type: MessagesPostTypes.FETCH_MESSAGES_POST_FAILURE,
  payload
});
