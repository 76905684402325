
import { adminScoutsCompanyTypes } from "../../ActionTypes/adminScoutsCompanyTypes";
import { AdminScoutsCompanyActions, AdminScoutsCompanyState } from "../../types/type";

const initialState: AdminScoutsCompanyState = {
  pending: false,
  companies: [],
  error: null,
};

export default (state = initialState, action: AdminScoutsCompanyActions,) => {
  switch (action.type) {
    case adminScoutsCompanyTypes.FETCH_ADMIN_SCOUTS_COMPANY_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminScoutsCompanyTypes.FETCH_ADMIN_SCOUTS_COMPANY_SUCCESS:
      return {
        ...state,
        pending: true,
        companies: action.payload.companies,
        error: null
      };
    case adminScoutsCompanyTypes.FETCH_ADMIN_SCOUTS_COMPANY_FAILURE:
      return {
        ...state,
        pending: false,
        companies: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
