import { columnFetchListTypes } from "../../ActionTypes/columnFetchListTypes";
import { ColumnFetchListFailure, ColumnFetchListFailurePayload, ColumnFetchListRequest, ColumnFetchListSuccess, ColumnFetchListSuccessPayload } from "../../types/type";

export const columnFetchListRequest = (companyId:any): ColumnFetchListRequest => ({
  type: columnFetchListTypes.FETCH_COLUMN_FETCH_LIST_REQUEST,
  companyId,
});

export const columnFetchListSuccess = (
  payload: ColumnFetchListSuccessPayload,
): ColumnFetchListSuccess => ({
  type: columnFetchListTypes.FETCH_COLUMN_FETCH_LIST_SUCCESS,
  payload
});

export const columnFetchListFailure = (
  payload: ColumnFetchListFailurePayload
): ColumnFetchListFailure => ({
  type: columnFetchListTypes.FETCH_COLUMN_FETCH_LIST_FAILURE,
  payload
});
