import { openAiTypes } from "../../ActionTypes/openAiTypes";
import { FetchOpenAiFailure, FetchOpenAiFailurePayload, FetchOpenAiRequest, FetchOpenAiSuccess, FetchOpenAiSuccessPayload } from "../../types/type";



export const fetchOpenAiRequest = (body:any): FetchOpenAiRequest => ({
  type: openAiTypes.FETCH_OPENAI_REQUEST,
  body
});

export const fetchOpenAiSuccess = (
  payload: FetchOpenAiSuccessPayload,
): FetchOpenAiSuccess => ({
  type: openAiTypes.FETCH_OPENAI_SUCCESS,
  payload
});

export const fetchOpenAiActionsFailure = (
  payload: FetchOpenAiFailurePayload
): FetchOpenAiFailure => ({
  type: openAiTypes.FETCH_OPENAI_FAILURE,
  payload
});
