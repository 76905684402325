
import { adminAdministratorsRegionsTypes } from "../../ActionTypes/adminAdministratorsRegionsTypes";
import { AdminAdministratorsRegionsActions, AdminAdministratorsRegionsState } from "../../types/type";


const initialState: AdminAdministratorsRegionsState = {
  pending: false,
  regions: [],
  error: null,
};

export default (state = initialState, action: AdminAdministratorsRegionsActions,) => {
  switch (action.type) {
    case adminAdministratorsRegionsTypes.FETCH_ADMIN_ADMINISTRATORS_REGIONS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case adminAdministratorsRegionsTypes.FETCH_ADMIN_ADMINISTRATORS_REGIONS_SUCCESS:
      return {
        ...state,
        pending: true,
        regions: action.payload.regions,
        error: null
      };
    case adminAdministratorsRegionsTypes.FETCH_ADMIN_ADMINISTRATORS_REGIONS_FAILURE:
      return {
        ...state,
        pending: false,
        regions: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
