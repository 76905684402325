import { openGoogleAiTypes } from "../../ActionTypes/openGoogleAiTypes";
import { FetchOpenAiGoogleFailure, FetchOpenAiGoogleFailurePayload, FetchOpenAiGoogleSuccess, FetchOpenAiGoogleSuccessPayload, FetchOpenAiGoogleRequest } from "../../types/type";

export const fetchOpenAiGoogleRequest = (body:any): FetchOpenAiGoogleRequest => ({
    type: openGoogleAiTypes.FETCH_OPENAI_HISTORY_REQUEST,
    body,
  });
  
  export const fetchOpenAiGoogleSuccess = (
    payload: FetchOpenAiGoogleSuccessPayload,
  ): FetchOpenAiGoogleSuccess => ({
    type: openGoogleAiTypes.FETCH_OPENAI_HISTORY_SUCCESS,
    payload
  });
  
  export const fetchOpenAiGoogleActionsFailure = (
    payload: FetchOpenAiGoogleFailurePayload
  ): FetchOpenAiGoogleFailure => ({
    type: openGoogleAiTypes.FETCH_OPENAI_HISTORY_FAILURE,
    payload
  });