import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import { RootState } from '../../../redax/reducers/rootReducer';
import Loader from '../../loader/loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchAdminRecruitmentDetailRequest } from '../../../redax/actions/adminRecruitmentDetailActions/adminRecruitmentDetailActions';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { fetchAdminRecruitmentPostRequest } from '../../../redax/actions/adminRecruitmentPostActions/adminRecruitmentPostActions';
import { fetchAdminRecruitmentCopyRequest } from '../../../redax/actions/adminRecruitmentCopyActions/adminRecruitmentCopyActions';

const AdminOpenJobDetail = () => {
  const dispatch = useDispatch();
  const adminRecruitmentDetail = useSelector((state: RootState) => state.adminRecruitmentDetail);
  const adminRecruitmentCopy = useSelector((state: RootState) => state.adminRecruitmentCopy);
  const adminRecruitmentPost = useSelector((state: RootState) => state.adminRecruitmentPost);
  const [loadScreen, setLoadScreen] = useState(true);
  const [btn, setBtn] = useState(false);
  const [copyBtn, setCopyBtnBtn] = useState(false);
  const [recruitmentList, setRecruitmentListList] = useState<any>([]);
  const [recruitmentTargetList, setRecruitmentTargetList] = useState<any>([]);
  const [recruitmentAppealItem1List, setRecruitmentAppealItem1List] = useState<any>([]);
  const [recruitmentAppealItem2List, setRecruitmentAppealItem2List] = useState<any>([]);
  const [recruitmentAppealItem3List, setRecruitmentAppealItem3List] = useState<any>([]);
  const [isDisplayDeleted, setIsDisplayDeleted] = useState(false);
  const [company, setComapny] = useState<any>([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const params = location.state;
  // if(params != null) {
  //   console.log(params.isDeletedList)
  //   if(params.isDeletedList == true) {
  //     setIsDisplayDeleted(true);
  //   }
  // }
  


  useEffect(() => {
      if (localStorage.getItem('jobParachannelAuthAdminToken')) {
        dispatch(fetchAdminRecruitmentDetailRequest(id));
      } 
  }, [dispatch]); 

  useEffect(() => {
      if (adminRecruitmentDetail.pending) {
        setRecruitmentListList(adminRecruitmentDetail.recruitments);
        if(adminRecruitmentDetail.recruitments.target.length !== 0) {
          setRecruitmentTargetList(adminRecruitmentDetail.recruitments.target);
        }
        if(adminRecruitmentDetail.recruitments.appeal_item.appeal_item_1.length !== 0) {
          setRecruitmentAppealItem1List(adminRecruitmentDetail.recruitments.appeal_item.appeal_item_1);
        }
        if(adminRecruitmentDetail.recruitments.appeal_item.appeal_item_2.length !== 0) {
          setRecruitmentAppealItem2List(adminRecruitmentDetail.recruitments.appeal_item.appeal_item_2);
        }
        if(adminRecruitmentDetail.recruitments.appeal_item.appeal_item_3.length !== 0) {
          setRecruitmentAppealItem3List(adminRecruitmentDetail.recruitments.appeal_item.appeal_item_3);
        }
        setComapny(adminRecruitmentDetail.company);
        setLoadScreen(false)
      } 
  }, [adminRecruitmentDetail]); 

  useEffect(() => {
    if (adminRecruitmentCopy.pending && copyBtn) {
        navigate('/admin/openjob_list/');
    } 
}, [adminRecruitmentCopy,copyBtn]); 


  



  useEffect(() => {
    if (adminRecruitmentPost.pending && btn) {
      navigate('/admin/openjob_list/')
    } 
}, [adminRecruitmentPost, btn]); 


  
  
  function formatDate (value: any) {
    return dayjs(value).locale(ja).format('YYYY.MM.DD (ddd) HH:mm');
}
function fetchDelete () {
  let str = "";
  if(recruitmentList.deleted) {
    str = "この求人を削除してよろしいですか？";
  } else {    
    str = "削除しますか？";
  }
  if (!window.confirm(str)) { return }
  dispatch(fetchAdminRecruitmentPostRequest('','DELETE',id)); 
  setBtn(true)
}


function fetchCopy () {
  let str = "コピーしますか？"
  if (!window.confirm(str)) { return }
  dispatch(fetchAdminRecruitmentCopyRequest(id));
  setCopyBtnBtn(true)
}
  

  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader></Loader> : <></>}
      <section id='openjob'>
        <div className='adminPageWrapper'>
          <div className="header">
              <h2 className="title">求人詳細</h2>
          </div>  
          <div className='body openJobDetail'>
            <div className="">
                <div className="textWrapper">
                  <a onClick={() => navigate('/admin/openjob_list')} className='blueBtn'>＜ 一覧に戻る</a>
                </div>
              </div>
              {recruitmentList && recruitmentList.length !==0 && company && company.length !== 0 ?
              <>
               <div className='step1 '>
                <div className='detailDflex'>
                  <h1>STEP1　基本情報</h1>
                  <div>
                    <button className='orangeBtn' onClick={()=> fetchCopy()}>複製</button>
                    <button className='createBtn' style={{padding:'5px 15px'}} onClick={() =>navigate('/admin/openjob_list/' +id +'/edit')}>編集</button>
                  </div>
                </div>
                <div className='bordered' style={{width: "100%"}}>                
                  <div style={{display:'flex'}}>
                      <div style={{width: "20%"}}>
                        <div className='imgBox'>
                          <img src={recruitmentList.images[0].url}></img>
                        </div>
                      </div>
                      <div className='detailTextBox' style={{width: "40%", padding: "0px 6px 6px 6px"}}>
                        <h4>会社名  <span className='m-3'>{company.name}</span></h4>
                        <h4>職種名  <span className='m-3'>{recruitmentList.job_title}</span></h4>
                        <h4>登録日/更新日  <span className='m-3'>{recruitmentList.created_at ?formatDate(recruitmentList.created_at) : <></>}/{recruitmentList.updated_at ?formatDate(recruitmentList.updated_at) : <></>}</span></h4>
                        <h4>事業所番号  <span className='m-3'>{recruitmentList.office_number}</span></h4>
                        <h4>職種  <span className='borders2 m-3'>{recruitmentList.job_category }</span></h4>
                      </div>
                      <div className='detailTextBox' style={{width: "40%", padding: "0px 6px 6px 6px"}}>
                        <h4>募集する企業区分{recruitmentList.type_of_recruiting_company  ? <span className='borders2 m-3'>{ recruitmentList.type_of_recruiting_company }</span> : <></>}</h4> 
                        <h4>雇用形態  {recruitmentList.employment_status ? <span className='borders2 m-3'>{recruitmentList.employment_status}</span> : <></>} </h4>
                        <h4>採用人数 {recruitmentList.target.number_of_employees ? <span className='borders2 m-3'> {recruitmentList.target.number_of_employees} 名</span> : <></>}</h4>
                        <h4>現在のステータス { recruitmentList.status ? <span className='borders m-3'>{ recruitmentList.status }</span> : <></>} </h4>
                      </div>
                  </div>
                </div>
                <table border={1}>
                  <tr>
                    <td><p>業務内容</p></td>
                    <td><p>{ recruitmentList.business_content ? recruitmentList.business_content : <></> }</p></td>
                  </tr>
                  <tr>
                    <td><p>必要な経験:資格</p></td>
                    <td>
                     <p> { recruitmentList.required_experience }</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    <p>雇用期間</p>
                    </td>
                    <td>
                      <div className='dFlex'>
                        <div className='wid50'><p>雇用期間有無</p></div>
                        <div className='wid50'>{ recruitmentList.period.contract_period_exists ?  <p className='blueText'>{ recruitmentList.period.contract_period_exists }</p>: <></> }</div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>契約期間</p></div>
                        <div className='wid50'>{ recruitmentList.period.contract_period ? <p className='borders1'>{ recruitmentList.period.contract_period }</p> : <></> }</div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>契約更新</p></div>
                        <div className='wid50'><p className='blueText'>{ recruitmentList.period.contract_renewal_exists }</p></div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>その他補足</p></div>
                        <div className='wid50'><p>{ recruitmentList.period.contract_period_supplemental }</p></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><p>試用期間</p></td>
                    <td>
                    <div className='dFlex'>
                        <div className='wid50'><p>試用期間有無</p></div>
                        <div className='wid50'><p className='blueText'>{ recruitmentList.period.trial_period_exists }</p></div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>試用期間</p></div>
                        <div className='wid50'>{ recruitmentList.period.trial_period ? <p className='borders1'>{ recruitmentList.period.trial_period }</p> : <></> }</div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>その他補足</p></div>
                        <div className='wid50'><p>{ recruitmentList.period.trial_period_supplemental }</p></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><p>正社員登用</p></td>
                    <td><p className='blueText'>{ recruitmentList.period.full_time_employee_exists }</p></td>
                  </tr>
                  <tr>
                    <td><p>住所(勤務地)</p></td>
                    <td>

                    <div className='dFlex'>
                        <div className='wid50'><p>働き方・テレワーク</p></div>
                        <div className='wid50'><p className='blueText'>{ recruitmentList.work_location.work_from_home }</p></div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>支店・部署名</p></div>
                        <div className='wid50'><p>{ recruitmentList.work_location.department }</p></div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>住所</p></div>
                        <div className='wid50'><p>{ recruitmentList.work_location.postcode } { recruitmentList.work_location.prefecture }{ recruitmentList.work_location.city }{ recruitmentList.work_location.address }</p></div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>最寄り駅</p></div>
                        <div className='wid50'><p>{ recruitmentList.work_location.nearest_station }</p></div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>最寄り駅からの交通手段</p></div>
                        <div className='wid50'><p>{ recruitmentList.work_location.transportation }</p></div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>転勤の有無</p></div>
                        <div className='wid50'><p className='blueText'>{ recruitmentList.work_location.nationwide }</p></div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'> <p>勤務地補足</p> </div>
                        <div className='wid50'><p>{ recruitmentList.work_location.work_location_supplemental }</p></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><p>勤務時間</p></td>
                    <td>
                    <div className='dFlex'>
                        <div className='wid50'><p>勤務時間</p></div>
                        <div className='wid50'>
                          {recruitmentList.work_time.start_time1 ? <p>{recruitmentList.work_time.start_time1} ～ { recruitmentList.work_time.end_time1 }</p> :<></>}
                          {recruitmentList.work_time.start_time2 ? <p>{recruitmentList.work_time.start_time2} ～ { recruitmentList.work_time.end_time2 }</p> :<></>}
                          {recruitmentList.work_time.start_time3 ? <p>{recruitmentList.work_time.start_time3} ～ { recruitmentList.work_time.end_time3 }</p> :<></>}
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>勤務時間補足</p></div>
                        <div className='wid50'><p>{ recruitmentList.work_time.work_time_supplemental }</p></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><p>休憩時間</p></td>
                    <td>
                      <div className='dFlex'>
                        <div className='wid50'><p>休憩時間 </p></div>
                        <div className='wid50'><p>{ recruitmentList.work_time.rest_time }</p></div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>休憩時間補足 </p></div>
                        <div className='wid50'><p>{ recruitmentList.work_time.rest_time_supplemental }</p></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><p>勤務日数/残業時間</p></td>
                    <td>
                      <div className='dFlex'>
                        <div className='wid50'>
                          <p>勤務日</p>
                        </div>
                        <div className='wid50'>
                        { recruitmentList.work_time.number_of_working_days  ? <p>{ recruitmentList.work_time.number_of_working_days } 日/週</p> : <></>} 
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'>
                          <p>勤務日補足</p>
                        </div>
                        <div className='wid50'>
                          <p>{ recruitmentList.work_time.number_of_working_days_supplemental } </p>
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'>
                          <p>残業時間</p>
                        </div>
                        <div>
                          <p className='blueText'>{ recruitmentList.work_time.overtime_hours_exists } </p>
                        </div>
                      </div>  
                      <div className='dFlex'>
                        <div className='wid50'>
                          <p>残業時間補足</p>
                        </div>
                        <div className='wid50'>
                          <p>{ recruitmentList.work_time.overtime_hours_supplemental } </p>
                        </div>
                      </div>  
                      
                    </td>
                  </tr>
                  <tr>
                    <td><p>休日</p></td>
                    <td>
                    <div className='dFlex'>
                        <div className='wid50'>
                          <p>休日</p>
                        </div>
                        <div className='wid50'>
                          {recruitmentList.work_time.holidays && recruitmentList.work_time.holidays.length !== 0?
                          <div className='dFlex'>
                            {recruitmentList.work_time.holidays.map((data: any, index:any) => (
                               <div key={"holidays_" + index}><p className='borders1'>{data}</p></div>
                            ))}
                          </div>
                          : <></>}
                        </div>
                      </div>  
                      <div className='dFlex'>
                        <div className='wid50'>
                          <p>年間休日</p>
                        </div>
                        <div>
                        { recruitmentList.work_time.annual_holiday ? <p> { recruitmentList.work_time.annual_holiday }日</p> : <></> }
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'>
                          <p>休日タグ</p>
                        </div>
                        <div className='wid50'>
                        {recruitmentList.work_time.vacations && recruitmentList.work_time.vacations.length !== 0?
                          <div className='dFlex' style={{flexWrap:'wrap'}}>
                            {recruitmentList.work_time.vacations.map((data: any, index:any) => (
                               <div key={"vacations_" + index}> <p className='borders1'>{data}</p></div>
                            ))}
                          </div>
                          : <></>}
                          
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div  className='wid50'><p>その他補足</p></div>
                        <div className='wid50'>
                        <p>{ recruitmentList.work_time.holidays_supplemental }</p>
                        </div>
                      </div>
                      
                    </td>
                  </tr>
                  <tr>
                    <td><p>給与:賞与</p></td>
                    <td>
                      <div className='dFlex'>
                        <div className='wid50'><p>給与方式</p></div>
                          <div className='wid50'>
                          { recruitmentList.salary.salary_form  ? <p className='borders1'>{ recruitmentList.salary.salary_form }</p> : <></>}  
                          </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'>
                          <p>
                            基本給
                          </p>
                        </div>
                        <div className='wid50'>
                         {recruitmentList.salary.lower_monthly_basic_salary &&  recruitmentList.salary.upper_monthly_basic_salary ? <p>{ recruitmentList.salary.lower_monthly_basic_salary } ～ { recruitmentList.salary.upper_monthly_basic_salary }</p> : <></>} 
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'>
                          <p>各種手当</p>
                        </div>
                        <div className='wid50'>
                          {recruitmentList.salary.allowances && recruitmentList.salary.allowances.length !== 0?
                            <div className='dFlex'>
                              {recruitmentList.salary.allowances.map((data: any, index:any) => (
                                <div key={"allowances_" + index}> <p className='borders1'>{data}</p></div>
                              ))}
                            </div>
                            : <></>}
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'>
                          <p>各種手当補足</p>
                        </div>
                        <div className='wid50'>
                          <p>{ recruitmentList.salary.allowances_supplemental }</p>
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'>
                          <p>賞与</p>
                        </div>
                        <div className='wid50'>
                          <p className='blueText'>{ recruitmentList.salary.annual_number_of_bonus_exists }</p>
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'>
                          <p>想定年収</p>
                        </div>
                        <div className='wid50'>
                          {recruitmentList.salary.lower_estimated_annual_income && recruitmentList.salary.upper_estimated_annual_income ? <p>{ recruitmentList.salary.lower_estimated_annual_income } ～ { recruitmentList.salary.upper_estimated_annual_income }</p> : <></>}
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'>
                          <p>昇給</p>
                        </div>
                        <div className='wid50'>
                          <p className='blueText'>{ recruitmentList.salary.annual_number_of_raises_exists }</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                      <td>
                        <p>
                          福利厚生/定年
                        </p>
                      </td>
                      <td>
                      <div className='dFlex'>
                        <div className='wid50'><p>社会保険</p></div>
                        <div className='wid50'>
                        {recruitmentList.social_insurances && recruitmentList.social_insurances.length !== 0?
                          <div className='dFlex'>
                            {recruitmentList.social_insurances.map((data: any, index:any) => (
                               <div key={"social_insurances_" + index}> <p className='borders1'>{data}</p></div>
                            ))}
                          </div>
                          : <></>}
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>退職制度</p></div>
                        <div className='wid50'>
                          <p>{ recruitmentList.retirement_money_exists }</p>
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>定年</p></div>
                        <div className='wid50'>
                          {recruitmentList.retirement_age ?  <p>
                            { recruitmentList.retirement_age }歳
                          </p>: <></>}
                          
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>その他補足</p></div>
                        <div className='wid50'>
                          <p>
                            { recruitmentList.benefit_supplemental}
                          </p>
                        </div>
                      </div>
                    </td>
                    </tr>
                  <tr>
                    <td>
                      <p>選考方法</p>
                    </td>
                    <td>
                    {recruitmentList.selection_methods && recruitmentList.selection_methods.length !== 0?
                          <div className='dFlex'>
                            {recruitmentList.selection_methods.map((data: any, index:any) => (
                               <div key={"selection_methods+" + index}> <p className='borders1'>{data}</p></div>
                            ))}
                          </div>
                          : <></>}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        その他特記事項（400文字以内）
                      </p>
                    </td>
                    <td>
                      <p>{ recruitmentList.selection_method_supplemental }</p>
                    </td>
                  </tr>
                </table>                
            </div>

            <div className='step2'  style={{marginTop:'20px'}}>
              <h1>STEP2　採用要件・アピール項目</h1>
              <table>
                <tr>
                  <td><p>採用種別</p></td>
                  <td><p className='blueText'>{ recruitmentList.new_graduate ? '新卒' : '中途' }</p></td>
                </tr>
                {recruitmentTargetList && recruitmentTargetList.length !== 0 ?
                <>
                  <tr>
                    <td><p>採用スケジュール</p></td>
                    <td>{ recruitmentTargetList.schedule ? <p className='borders1'>{ recruitmentTargetList.schedule }</p> : <></>}</td>
                  </tr>
                  <tr>
                    <td><p>地域</p></td>
                    <td>
                    {recruitmentTargetList.prefecture && recruitmentTargetList.prefecture.length !== 0?
                      <div className='dFlex'>
                        {recruitmentTargetList.prefecture.map((data: any, index:any) => (
                            <div key={"prefecture_" + index}> <p className='blueText'>{data}</p></div>
                        ))}
                      </div>
                      : <></>}
                    </td>
                  </tr>
                  <tr>
                    <td><p>障害区分</p></td>
                    <td>
                      <div className='dFlex'>
                        {recruitmentTargetList.handicap_types.map((data: any, index:any) => (
                            <div key={"handicap_types" + index}> <p className='blueText'>{data}</p></div>
                        ))}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><p>エントリー時に聞きたい内容</p></td>
                    <td>
                    <div className='dFlex'>
                        <div className='wid50'><p>アピール項目</p></div>
                        <div className='wid50'>
                          <p>
                            { recruitmentAppealItem1List.name}
                          </p>
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>アピール項目2</p></div>
                        <div className='wid50'>
                          <p>
                            { recruitmentAppealItem2List.name}
                          </p>
                        </div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>アピール項目3</p></div>
                        <div className='wid50'>
                          <p>
                            { recruitmentAppealItem3List.name}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>                        
                </>
                : <></>}
              </table>
            </div>
            
            <div className='step3' style={{marginTop:'20px'}}>
                <h1>STEP3 プレビュー設定項目</h1>
                <table>
                    <tr>
                      <td><p>アピール項目</p></td>
                      <td>
                        <div className='dFlex'>
                          <div className='wid50'><p>社風・職場の雰囲気</p></div>
                          <div className='wid50'>
                            <p>{ recruitmentList.corporate_culture_text }</p>
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>1日の業務の流れ</p></div>
                          <div className='wid50'>
                            <p>{ recruitmentList.business_flow }</p>
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>先輩社員からのコメント</p></div>
                          <div className='wid50'>
                            <p>{ recruitmentList.superior_message }</p>
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>キャリアパス</p></div>
                          <div className='wid50'>
                            <p>{ recruitmentList.career_path }</p>
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>現場での合理的配慮例</p></div>
                          <div className='wid50'>
                            <p>{ recruitmentList.consideration_condition_text }</p>
                          </div>
                        </div>

                        <div className='dFlex'>
                          <div className='wid50'><p>採用担当メッセージ</p></div>
                          <div className='wid50'>
                            <p>{ recruitmentList.hr_message }</p>
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>動画</p></div>
                          <div className='wid50'>
                            <a href={recruitmentList.movie_path}>
                              { recruitmentList.movie_path}
                            </a>
                          </div>
                        </div>
                        
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>求める人物像</p>
                      </td>
                      <td>
                      {recruitmentList.target.compatibilities && recruitmentList.target.compatibilities.length !== 0?
                        <div className='dFlex'>
                          {recruitmentList.target.compatibilities.map((data: any, index:any) => (
                             <div key={"compatibilities_" + index}> <p className='borders1'>{data}</p></div>
                          ))}
                        </div>
                        : <></>}
                      </td>
                    </tr>
                    <tr>
                      <td><p>現場での合理的配慮例</p></td>
                      <td><p>{ recruitmentList.consideration_condition_text }</p></td>
                    </tr>
                    <tr>
                      <td><p>職場環境</p></td>
                      <td>
                      <div className='dFlex'>
                      {recruitmentList.work_environment_text?.map((data: any, index:any) => (
                             <div> <p key={'work_environment_text' + index} className='borders1'>{data}</p></div>
                          ))}
                      </div>
                      </td>
                    </tr>
                    <tr>
                      <td><p>一覧画面用</p></td>
                      <td>
                        <>
                        <div className='dFlex'>
                          <div className='wid50'><p>業務内容</p></div>
                          <div className='wid50'>
                            {recruitmentList.preview_display_texts ? <p>{ recruitmentList.preview_display_texts[0] }</p> : <></>}
                           
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>業務内容</p></div>
                          <div className='wid50'>
                          {recruitmentList.preview_display_texts ? <p>{ recruitmentList.preview_display_texts[1] }</p> : <></>}
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>業務内容</p></div>
                          <div className='wid50'>
                            {recruitmentList.preview_display_texts ? <p>{ recruitmentList.preview_display_texts[2] }</p> : <></>}
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>業務内容</p></div>
                          <div className='wid50'>
                          {recruitmentList.preview_display_texts ? <p>{ recruitmentList.preview_display_texts[3] }</p> : <></>}
                          </div>
                        </div>
                        </>
                      </td>
                    </tr>
                </table>
            </div>
              </>
              : <></>}
              <div  style={{display:'flex',position:'relative', alignItems:'center', justifyContent:'center',marginTop:'20px'}}>
              {
                  recruitmentList.deleted ?
                  <>
                    <button className='deleteBtn'  style={{padding:'10px 100px', fontSize:'18px'}} onClick={()=> fetchDelete()}>削除済みから復旧する</button>
                  </>
                  :
                  <>
                    <button className='deleteBtn'  style={{padding:'10px 100px', fontSize:'18px'}} onClick={()=> fetchDelete()}>削除</button>
                    <button className='createBtn' onClick={() =>navigate('/admin/openjob_list/' +id +'/edit')}  style={{padding:'10px 100px', fontSize:'18px'}}>編集</button>
                  </>
              }
                
              </div>
              
          </div>
        </div>
      </section>
    </main>
  );
};

export default AdminOpenJobDetail;