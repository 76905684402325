import { emailChangeTokenTypes } from "../../ActionTypes/emailChangeTokenTypes";
import { FetchEmailChangeTokenFailure, FetchEmailChangeTokenFailurePayload, FetchEmailChangeTokenRequest, FetchEmailChangeTokenSuccess, FetchEmailChangeTokenSuccessPayload } from "../../types/type";

export const fetchEmailChangeTokenRequest = (token:any,): FetchEmailChangeTokenRequest => ({
  type: emailChangeTokenTypes.FETCH_EMAIL_CHANGE_TOKEN_REQUEST,
  token,
});

export const fetchEmailChangeTokenSuccess = (
  payload: FetchEmailChangeTokenSuccessPayload,
): FetchEmailChangeTokenSuccess => ({
  type: emailChangeTokenTypes.FETCH_EMAIL_CHANGE_TOKEN_SUCCESS,
  payload
});

export const fetchEmailChangeTokenFailure = (
  payload: FetchEmailChangeTokenFailurePayload
): FetchEmailChangeTokenFailure => ({
  type: emailChangeTokenTypes.FETCH_EMAIL_CHANGE_TOKEN_FAILURE,
  payload
});
