import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { regionsTypes } from "../../ActionTypes/regionsTypes";
import { fetchRegionsFailure, fetchRegionsSuccess } from "../../actions/regionsActions/regionsActions";

// var numb  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
 if (window.location.href.includes('consideration_conditions') && window.location.href.includes('recruitments')){
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?consideration_conditions=true', {headers: { Accept: 'application/json', Prefer: 'example=example-1'}});
} else if (window.location.href.includes('recruiter/company')){
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?industries=true&companies=true&consideration_conditions=true&number_of_employees=true', {headers: { Accept: 'application/json', Prefer: 'example=example-1'}});
}else if (window.location.href.includes('recruiter/recruitments/create/step_1')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?job_categories=true&recruitments=true&contract_periods=true&trial_periods=true&work_from_home=true', {headers: { Accept: 'application/json', Prefer: 'example=example-1'}});
} 
// else if (window.location.href.includes('recruiter/recruitments/create/step_2')) {
//   var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?recruitments=true&regions=true&handicap_types=true&ideal_candidates_unique=true&consideration_conditions=true&appeal_items=true', {headers: { Accept: 'application/json', Prefer: 'example=example-1'}});
// } 
else if (window.location.href.includes('recruiter/recruitments/')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?regions=true&handicap_types=true&consideration_conditions=true&ideal_candidates_unique=true&job_categories=true&recruitments=true&appeal_items=true&consideration_condition_types=true&work_environments=true&work_from_home=true', {headers: { Accept: 'application/json', Prefer: 'example=example-1'}});
}else if (window.location.href.includes('recruiter/scouts')){
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?handicap_types=true&handicaps=true&regions=true&recruitments=true&job_categories=true&applicants=true&qualifications=true&languages=true&oa_skills=true&work_from_home=true', {headers: { Accept: 'application/json', Prefer: 'example=example-1'}});
} else if (window.location.href.includes('/entry')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?regions=true&applicants=true&industries=true&job_categories=true&appeal_items=true&oa_skills=true&qualifications=true&languages=true&handicaps=true&handicap_types=true&consideration_conditions=true&work_styles=true&recruitments=true&work_from_home=true', {headers: { Accept: 'application/json'}}); 
} else if (window.location.href.includes('recruitments')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?regions=true&job_categories=true&handicap_types=true&consideration_conditions=true&recruitments=true&applicants=true&work_styles=true', {headers: { Accept: 'application/json'}}); 
} else if (window.location.href.includes('/mypage/profile/')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?applicants=true', {headers: { Accept: 'application/json'}}); 
} else if (window.location.href.includes('/mypage/profile2/')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?handicaps=true&handicap_types=true&consideration_conditions=true', {headers: { Accept: 'application/json'}}); 
} else if (window.location.href.includes('/mypage/profile3/')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?industries=true&job_categories=true&applicants=true', {headers: { Accept: 'application/json'}}); 
} else if (window.location.href.includes('/mypage/profile4/')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?oa_skills=true&qualifications=true&languages=true', {headers: { Accept: 'application/json'}}); 
} else if (window.location.href.includes('/mypage/profile6/')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?regions=true&industries=true&job_categories=true&recruitments=true&applicants=true&work_styles=true&work_from_home=true', {headers: { Accept: 'application/json'}}); 
} 
// else if (window.location.pathname.split('/mypage/').length > 1 && window.location.pathname.split('/mypage/')[1].includes('profile7')) {
//   var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?appeal_items=true', {headers: { Accept: 'application/json'}}); 
// }
 else if (window.location.href.includes('init')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?regions=true&handicaps=true&handicap_types=true&job_categories=true&applicants=true', {headers: { Accept: 'application/json', Prefer: 'example=example-1'}});
} else if (window.location.href.includes('recruiter/applications')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?applications=true&handicap_types=true' , {headers: { Accept: 'application/json'}}); 
} else if (window.location.href.includes('admin/applications')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?applications=true' , {headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }}); 
} else if (window.location.href.includes('/admin/administrator_new')) {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?administrators=true' , {headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }}); 
}  else {
  var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?regions=true&handicaps=true&handicap_types=true&job_categories=true&recruitments=true&search_regions=true', {headers: { Accept: 'application/json'}});
}

function* fetchRegionsSaga() {
  try {
    const response: AxiosResponse = yield call(getRegions);
    yield put(
      fetchRegionsSuccess({
        regions: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchRegionsFailure({
        error: e.message
      })
    );
  }
}

function* RegionsSaga() {
  yield takeLatest(regionsTypes.FETCH_REGIONS_REQUEST, fetchRegionsSaga);
}

export default RegionsSaga;
