import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchDownloadCareerFailure, fetchDownloadCareerSuccess } from "../../actions/downloadCareerActions/downloadCareerActions";
import { DownloadCareerTypes } from "../../ActionTypes/downloadCareerTypes ";

var getDownloadCareer = (applicantId:any) => axios.get(API_BASE_ADDRESS + "/applicants/" + applicantId + '/career/', {headers: {
  mode: 'cors',
  Authorization: localStorage.getItem('jobParachannelAuthToken') ? localStorage.getItem('jobParachannelAuthToken') : localStorage.getItem('jobParachannelAuthAdminToken'),
  Accept: '*/*',
}
}); 
function* fetchDownloadCareerSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getDownloadCareer, queryParams.applicantId);
    yield put(
      fetchDownloadCareerSuccess({
        applicantId: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchDownloadCareerFailure({
        error: e.applicantId
      })
    );
  }
}

function* DownloadCareerSaga() {
  yield takeLatest(DownloadCareerTypes.FETCH_DOWNLOAD_CAREER_REQUEST, fetchDownloadCareerSaga);
}

export default DownloadCareerSaga;
