import { scoutAlertTypes } from "../../ActionTypes/scoutsAlertTypes";
import { FetchScoutAlertFailure, FetchScoutAlertFailurePayload, FetchScoutAlertRequest, FetchScoutAlertSuccess, FetchScoutAlertSuccessPayload } from "../../types/type";


export const fetchScoutAlertRequest = (id: any): FetchScoutAlertRequest => ({
  type: scoutAlertTypes.FETCH_SCOUT_ALERT_REQUEST,
  id,
});

export const fetchScoutAlertSuccess = (
  payload: FetchScoutAlertSuccessPayload,
): FetchScoutAlertSuccess => ({
  type: scoutAlertTypes.FETCH_SCOUT_ALERT_SUCCESS,
  payload
});

export const fetchScoutAlertFailure = (
  payload: FetchScoutAlertFailurePayload
): FetchScoutAlertFailure => ({
  type: scoutAlertTypes.FETCH_SCOUT_ALERT_FAILURE,
  payload
});
