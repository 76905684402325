import { savedConditionsCreateTypes } from "../../ActionTypes/savedConditionsCreateTypes";
import { FetchSavedConditionsCreateFailure, FetchSavedConditionsCreateFailurePayload, FetchSavedConditionsCreateRequest, FetchSavedConditionsCreateSuccess, FetchSavedConditionsCreateSuccessPayload } from "../../types/type";


export const fetchSavedConditionsCreateRequest = (id: any, queryParams: any): FetchSavedConditionsCreateRequest => ({
  type: savedConditionsCreateTypes.FETCH_SAVED_CONDITIONS_CREATE_REQUEST,
  id,
  queryParams,
});

export const fetchSavedConditionsCreateSuccess = (
  payload: FetchSavedConditionsCreateSuccessPayload,
): FetchSavedConditionsCreateSuccess => ({
  type: savedConditionsCreateTypes.FETCH_SAVED_CONDITIONS_CREATE_SUCCESS,
  payload
});

export const fetchSavedConditionsCreateFailure = (
  payload: FetchSavedConditionsCreateFailurePayload
): FetchSavedConditionsCreateFailure => ({
  type: savedConditionsCreateTypes.FETCH_SAVED_CONDITIONS_CREATE_FAILURE,
  payload
});
