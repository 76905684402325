import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../../redax/reducers/rootReducer";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import { fetchScoutsSavedConditionsRequest } from "../../../../redax/actions/scoutsSavedConditionsActions/scoutsSavedConditionsActions";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import Loader from "../../../loader/loader";


function ScoutSavedCondions() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [resourceId, setResourceId] = useState(null);
    const loginData = useSelector((state: RootState) => state.login);
    const scoutsSavedConditions = useSelector((state: RootState) => state.scoutsSavedConditions);
    const [familyName, setFamylyName] = useState(null);
    const [loadScreen, setLoadScreen] = useState(true);
    const [saved_conditions_list, setList] = useState<any>([]);
    const location = useLocation();
    const data = location.state;

    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
        //   dispatch(fetchLoginRequest('', '',''))
        } else (
            navigate('/')
        )
      }, [dispatch]);


    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/recruiter/init/');
            }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            dispatch(fetchScoutsSavedConditionsRequest(0, 'get'));
        }
    }, [loginData.pending]);

    useEffect(() => {
        if (scoutsSavedConditions.pending && scoutsSavedConditions != null) {
            setLoadScreen(false);
            if(scoutsSavedConditions.saved_conditions.company_scout_saved_conditions != null) {
                setList(scoutsSavedConditions.saved_conditions.company_scout_saved_conditions);
            } 
        } else if(scoutsSavedConditions.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [scoutsSavedConditions]);

    function logout() {
        if (localStorage.entryFlag) {
            localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
            localStorage.removeItem('jobParachannelAuthToken')
            navigate('/');
            window.location.reload();
        }
    }

    function deleteAlert (id: any, title: any) {
        if(window.confirm('以下の保存条件を削除しますか？\n' + '【' + title + '】')) {
            dispatch(fetchScoutsSavedConditionsRequest(id, 'delete'));
        }
               
        setTimeout(function() {
            dispatch(fetchScoutsSavedConditionsRequest(0, 'get')); 
        }, 1500);
    }

    useEffect(() => {
        if (scoutsSavedConditions.pending && scoutsSavedConditions != null) {
            setLoadScreen(false);
            if(scoutsSavedConditions.saved_conditions.company_scout_saved_conditions != null) {
                setList(scoutsSavedConditions.saved_conditions.company_scout_saved_conditions);
            } 
        } else if(scoutsSavedConditions.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [scoutsSavedConditions]);

    function loadBack() {
        let l = data.queryParamString != null ? data.queryParamString : ''
        window.location.href = '/recruiter/scouts/?' + l;
    }
      

    return (
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className ="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">スカウト</h2>
                <ul>
                <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            <div id="scouts" className="saved_conditions">
                <a href="#" style={{top: "-32px"}} onClick={loadBack} className="btn back"><img src="/assets/img/company/icon06.svg" alt=""/>戻る</a>
                <h3>検索条件一覧</h3>
                {saved_conditions_list && saved_conditions_list.length ?
                    <ul>
                        {saved_conditions_list && saved_conditions_list.map((data: any, i: any) => (
                            <li key={data.id}>
                                <a href={`/recruiter/scouts/?${data.params}`}>{ data.name }</a>
                                <a href="javascript:void(0)" onClick={() => deleteAlert(data.id,data.name)}>削除する</a>
                            </li>
                        ))}
                    </ul>
                :
                    <ul>
                        <li><p>保存した条件はありません</p></li>
                    </ul>
                }
            </div>
        </section>
    </main>
    );
  }
  
  export default ScoutSavedCondions;