
import { handicapsTypes } from "../../ActionTypes/handicapsTypes";
import {  HandicapsActions, HandicapsState } from "../../types/type";

const initialState: HandicapsState = {
  pending: false,
  handicaps: [],
  error: null,
};

export default (state = initialState, action: HandicapsActions,) => {
  switch (action.type) {
    case handicapsTypes.FETCH_HANDICAPS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case handicapsTypes.FETCH_HANDICAPS_SUCCESS:
      return {
        ...state,
        pending: true,
        handicaps: action.payload.handicaps,
        error: null
      };
    case handicapsTypes.FETCH_HANDICAPS_FAILURE:
      return {
        ...state,
        pending: false,
        handicaps: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
