import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruitersTypes } from "../../ActionTypes/recruitersTypes";
import { fetchRecruitersFailure, fetchRecruitersSuccess } from "../../actions/recruitersActions/recruitersActions";

var getRecruiters = (id: any, types: any, sid: any) => {
  if(types == 'get') {
    return axios.get(API_BASE_ADDRESS + '/companies/' + id + '/recruiters', {headers: { Accept: 'application/json',
      Authorization: localStorage.getItem('jobParachannelAuthToken')}});
  } else {
    return axios.delete(API_BASE_ADDRESS + '/companies/' + id + '/recruiters/' + sid, {headers: { Accept: 'application/json',
    Authorization: localStorage.getItem('jobParachannelAuthToken')}});
  }
}

function* fetchRecruitersSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getRecruiters, action.id, action.types, action.sid);
    yield put(
      fetchRecruitersSuccess({
        recruiters: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitersFailure({
        error: e.message
      })
    );
  }
}

function* RecruitersSaga() {
  yield takeLatest(recruitersTypes.FETCH_RECRUITERS_REQUEST, fetchRecruitersSaga);
}

export default RecruitersSaga;
