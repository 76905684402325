import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchColumnsFailure, fetchColumnsSuccess } from "../../actions/columnsActions/columnsActions";
import { columnsTypes } from "../../ActionTypes/columnsTypes";


var getColumns = (id:any) => axios.get(API_BASE_ADDRESS + '/companies/' + id, {headers: { Accept: 'application/json', Prefer: 'example=test-1'}}); 

function* fetchColumnsSaga(id: any) {
  try {
    const response: AxiosResponse = yield call(getColumns, id.id);
    yield put(
      fetchColumnsSuccess({
        columns: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchColumnsFailure({
        error: e.message
      })
    );
  }
}

function* ColumnsSaga() {
  yield takeLatest(columnsTypes.FETCH_COLUMNS_REQUEST, fetchColumnsSaga);
}

export default ColumnsSaga;
