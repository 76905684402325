import { useState } from "react";


function Guide8() {

    
    return (
        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">8.求人投稿について</h3>
          <p>この記事は約3分で読めます。</p>
    
          <p>このページでは求人投稿について説明します。</p>
    
          <p>1.TOPページ(<a href="/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/</a>) から画面右上の「採用担当者様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/first.png" alt=""/>
    
          <p>2.「既に会員の企業様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/08/02.png" alt=""/>
    
          <p>3.登録したメールアドレス・パスワードにて、ログインしてください。<br/>
          パスワードをお忘れの方はこちらから再設定してください。<br/>
          <a href="/recruiter/password/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/recruiter/password/</a></p>
          <img src="/assets/img/guide/08/03.png" alt=""/>
    
          <p>4.マイページ画面左メニューの「求人投稿・管理」をクリックし、新規を選びます。<br/>
          なお、パラちゃんねるでは、2種類の求人作成の方法があります。<br/>
          ・ハローワークの求人番号で求人作成<br/>
          ・パラちゃんねる上で求人作成</p>
          <img src="/assets/img/guide/08/04.png" alt=""/>
    
          <p>5.（ハローワークの求人番号で求人作成の場合）<br/>
          4.の画面にて求人番号13桁を入力すると、求人票の各項目が自動転載されます。<br/>
          内容を確認し、必須項目を中心に埋めていきます。</p>
          <img src="/assets/img/guide/08/05.png" alt=""/>
    
    
          <p>6.（ハローワークの求人番号で求人作成の場合）<br/>
          採用要件・アピール項目を入力してください。<br/>
          ※求職者とのマッチングに使用するデータとなります。求職者には表示されません。</p>
          <img src="/assets/img/guide/08/06.png" alt=""/>
    
          <p>7.プレビュー画面でここまでの入力項目の確認、画像の添付をしてください。<br/>
          (合理的配慮の選択は必須項目)</p>
          <img src="/assets/img/guide/08/07.png" alt=""/>
    
          <p>&lt;ポイント&gt;<br/>
          「アピール項目の追加」をすると求職者からの応募率が向上します。<br/>
          文例を元にご作成ください。</p>
    
          <p>求人の完成サンプルは<a href="/recruitments/6501/" style={{display: 'inline',color: '#00a0e9'}}>こちら</a></p>
    
          <p>続いて、求人管理・修正・削除には<a href="/guide/9/" style={{display: 'inline',color: '#00a0e9'}}>こちら</a>をお読みください。</p>
          <a href="/guide/" className="btn type3 nuxt-link-active">一覧へ戻る</a>
        </section>
      </main>
    );
  }
  
  export default Guide8;