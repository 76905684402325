import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchCompaniesFailure, fetchCompaniesSuccess } from "../../actions/companiesActions/companiesActions";
import { companiesTypes } from "../../ActionTypes/companiesTypes";
import { fetchPreviewFailure, fetchPreviewSuccess } from "../../actions/previewActions/previewActions";
import { previewTypes } from "../../ActionTypes/previewTypes";


var getPreview = (id:any) => axios.get(API_BASE_ADDRESS + '/companies/' + id + '/preview', {headers: { Prefer: 'example=test-1', Accept: 'application/json'}}); 

function* fetchPreviewSaga(id: any) {
  try {
    const response: AxiosResponse = yield call(getPreview, id.id);
    yield put(
      fetchPreviewSuccess({
        preview: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchPreviewFailure({
        error: e.message
      })
    );
  }
}

function* PreviewSaga() {
  yield takeLatest(previewTypes.FETCH_PREVIEW_REQUEST, fetchPreviewSaga);
}

export default PreviewSaga;
