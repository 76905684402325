import { articlesDeleteTypes } from "../../ActionTypes/articlesDeleteTypes";
import { FetchArticlesDeleteFailure, FetchArticlesDeleteFailurePayload, FetchArticlesDeleteRequest, FetchArticlesDeleteSuccess, FetchArticlesDeleteSuccessPayload } from "../../types/type";

export const fetchArticlesDeleteRequest = (id:any): FetchArticlesDeleteRequest => ({
  type: articlesDeleteTypes.FETCH_ARTICLES_DELETE_REQUEST,
  id,
});

export const fetchArticlesDeleteSuccess = (
  payload: FetchArticlesDeleteSuccessPayload,
): FetchArticlesDeleteSuccess => ({
  type: articlesDeleteTypes.FETCH_ARTICLES_DELETE_SUCCESS,
  payload
});

export const fetchArticlesDeleteFailure = (
  payload: FetchArticlesDeleteFailurePayload
): FetchArticlesDeleteFailure => ({
  type: articlesDeleteTypes.FETCH_ARTICLES_DELETE_FAILURE,
  payload
});
