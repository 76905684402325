import React, { useEffect, useState } from 'react';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { fetchApplicantRequest } from '../../../redax/actions/applicantActions/applicantActions';
import { fetchApplicationsRequest } from '../../../redax/actions/applicationsActions/applicationsActions';
import { fetchRegionsRequest } from '../../../redax/actions/regionsActions/regionsActions';
import { fetchFavCountRequest } from "../../../redax/actions/favCountActions/favCountActions";
import { useNavigate } from 'react-router-dom';
import Loader from '../../loader/loader';
import './applications.scss';

function MypageApplications() {    
    const [jsonObject, setJsonObject] = useState<any>(null);
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [value, setValue] = React.useState('one');
    const [isActive, setIsActive] = useState(1);
    const dispatch = useDispatch();
    let param: any = '';
    const [AppData, setAppValue] = useState<any[]>([]);
    const [AppPageData, setAppPagValue] = useState<any>(null);
    const [masterRegionData, setMasterRegionData] = useState<any[]>([]);
    const loginData = useSelector((state: RootState) => state.login);
    const applications = useSelector((state: RootState) => state.applications);
    const { regions  } = useSelector((state: RootState) => state.regions);
    const [page, setCurrentPage] = useState(1);
    let salaryForms: any = [];
    const navigate = useNavigate();
    const [loadScreen, setLoadScreen] = useState(true);
    const [warnTab1, setWarnTab1] = useState(false);
    const [warnTab2, setWarnTab2] = useState(false);
    const [warnTab3, setWarnTab3] = useState(false);
    const [warnTab4, setWarnTab4] = useState(false);
    const favCount = useSelector((state: RootState) => state.favCount);
    const pageTitle = '応募管理｜パラちゃんねる';
      document.title = pageTitle;
      useEffect(() => {
        if (!localStorage.getItem('jobParachannelAuthToken')) {
          navigate('/login');
        } 
      }, [navigate, dispatch]);

    useEffect(() => {
        if (window.location.href.includes('tab=3')) {
          setIsActive(2);
        }
        if (window.location.href.includes('tab=4')) {
          setIsActive(4);
        }
      }, []);
      
    useEffect(() => {
    //   dispatch(fetchLoginRequest('', '', ''));
    }, [dispatch]);

    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/mypage/init/');
            }
          dispatch(fetchApplicantRequest(loginData.login.resource.id));
          setResourceId(loginData.login.resource.id);
          setFamylyName(loginData.login.resource.family_name);
          if(isActive === 1){
            param = '/applications/for_applicants?applicant_id=' + loginData.login.resource.id + '&status=entry&page=' + page
          } else if (isActive === 2) {            
            param = '/recruitments/favorite?applicant_id=' + loginData.login.resource.id + '&page=' + page
          } else if (isActive === 3) {
            param = '/applications/for_applicants?applicant_id=' + loginData.login.resource.id + '&status=unofficially&page=' + page
          } else if (isActive === 4) {
            param = '/applications/for_applicants?applicant_id=' + loginData.login.resource.id + '&status=close&page=' + page
          }          
        //   checkRegion();
            setWarnTab1(false);
            setWarnTab2(false);
            setWarnTab3(false);
            setWarnTab4(false);
          dispatch(fetchApplicationsRequest(param, 'get', null));
        }
    }, [dispatch, loginData.pending, isActive, page]);

    useEffect(() => {
        if(applications.pending){
            setAppValue(applications.applications.applications);
            setAppPagValue(applications.applications.pagenation.total_pages);
            for(let i = 0; i < applications.applications.applications.length; i++) {
                if(applications.applications.applications[i].unread == true) {
                    if(isActive === 1) {
                        setWarnTab1(true);
                    } else if(isActive === 2) {
                        setWarnTab2(true);
                    } else if(isActive === 3) {
                        setWarnTab3(true);
                    } else {
                        setWarnTab4(true);
                    }
                    break;
                }
            }
            setLoadScreen(false);
        } else if(applications.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [applications, AppPageData]);

    useEffect(() => {
        dispatch(fetchRegionsRequest());
    }, [dispatch]);

    useEffect(() => {
        if(regions !== undefined) {
            setMasterRegionData(regions.salary_forms);
        }  
    },[regions,masterRegionData]);

    if(masterRegionData !== undefined){

        for(let i = 0; i < masterRegionData.length; i++) {

            salaryForms.push(masterRegionData[i].name);

        }

    }
    
    const pageIncriment = () => {
        if (page >= AppPageData) {
            setCurrentPage(AppPageData);
            setLoadScreen(false);
            return
        }
        setCurrentPage(page + 1);
        changeTabPagenation(isActive, page)
    }

    const pageDecriment = () => {
        if (page <= 1) {
            setCurrentPage(1);
            setLoadScreen(false);
            return
        }
        setCurrentPage(page - 1);
        changeTabPagenation(isActive, page);
    }

    const setPage = (i: any) => {        
        changeTabPagenation(isActive, i);
        setCurrentPage(i);
        if(i == page) {
            setLoadScreen(false);
        }
    }

    const changeTab = (tabIndex: any) => {
        setIsActive(tabIndex);
        setLoadScreen(true);
    };

    const changeTabPagenation = (tabIndex: any, tabpage: any) => {
        setIsActive(tabIndex);
        setLoadScreen(true);
        // page = typeof page === 'number' ? page : 1
        let uriPath = null
        // switch (isActive){
        //     case 1:  // エントリー中の求人
        //     uriPath = '/applications/for_applicants?applicant_id=' + applications.applications.applications.applicant.id + '&status=entry&page=' + page
        //     break
        //     case 3:  // お気に入りの求人
        //     uriPath = '/recruitments/favorite?applicant_id=' + applications.applications.applications.applicant.id + '&page=' + page
        //     break
        //     case 4:  // 終了済み求人
        //     uriPath = '/applications/for_applicants?applicant_id=' + applications.applications.applications.applicant.id + '&status=close&page=' + page
        //     break
        //     case 5:  // 内定中の求人
        //     uriPath = '/applications/for_applicants?applicant_id=' + applications.applications.applications.applicant.id + '&status=unofficially&page=' + page
        //     break
        //     default:  // スカウトを受け取った求人
        //     throw ('タブ遷移中のエラー')
        // }
    };

    function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
        }
    }

    function favoriteSwitch (data: any) {
        if (window.confirm('「' + data.job_title + '/' + data.company_name + '」のお気に入り登録を解除しますか？')) {
            setLoadScreen(true);
            dispatch(fetchFavCountRequest(data.id, 'post', 'recruitment'));
        }
    }

    useEffect(() => {
        if(favCount.pending) {
            setLoadScreen(false);
            setCurrentPage(1);
            changeTab(2);
        } else if(favCount.error != null) {
            setLoadScreen(false);
            window.alert('お気に入り登録解除に失敗しました。');
        }
    }, [favCount]);

    return (
        <main id="mypage">
             <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" />
             {loadScreen ? <Loader /> : <></>}
             <section  id="mypage_inner" className="recruit_management_cont">
                <ul className="bread_crumb">
                    <li><a href="/">トップ</a></li>
                    <li><a href="/mypage/">マイページ</a></li>
                    <li><a href="/mypage/applications/">応募管理</a></li>
                </ul>
                <div className="ttl_box">
                    <h2 className="ttl">応募管理</h2>
                    <ul>
                    <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                    </ul>
                </div>
                <div className="tab_anchor">
                    <a href="javascript:void(0)" onClick={() => changeTab(1)} className={isActive === 1 && warnTab1 ? 'active new_icon' : isActive === 1 ? 'active' : ''}>エントリー中の求人</a>
                    <a href="javascript:void(0)" onClick={() => changeTab(2)} className={isActive === 2 && warnTab2 ? 'active new_icon' : isActive === 2 ? 'active' : ''}>お気に入りの求人</a>
                    <a href="javascript:void(0)" onClick={() => changeTab(3)} className={isActive === 3 && warnTab3 ? 'active new_icon' : isActive === 3 ? 'active' : ''}>内定中の求人</a>
                    <a href="javascript:void(0)" onClick={() => changeTab(4)} className={isActive === 4 && warnTab4 ? 'active new_icon' : isActive === 4 ? 'active' : ''}>終了済み求人</a>
                </div>
                <p className="note">※仕様上、応募求人に関する返信が「10日間」以上経過した場合、<br className="sp_only"></br>自動で「終了済み求人」に変更されます。</p>
                                
                <ul className="recurit_list">
                    {AppData.length == 0 ?
                        <li>
                            <p>該当の求人はありません</p>
                        </li>
                    :<></>}   
                    {AppData.map((data: any) => (
                        <li key={data.id}>
                            <a href={isActive === 2 ? '/recruitments/' + data.id : '/mypage/applications/' + data.application_id}>
                                <div className="img_cont">
                                    <img src={data.thumbnail} alt={data.job_title}></img>
                                    {isActive === 2 && (<a href="javascript:void(0)" onClick={() => favoriteSwitch(data)}><span className="like_btn on"></span></a>)}
                                </div>
                                <div className="txt_cont">
                                    {data.unread ?
                                    <a href={isActive === 2 ? '/recruitments/' + data.id : '/mypage/applications/' + data.application_id}><span className="unread">未読メッセージがあります</span></a>
                                    : <></>}
                                    <p className="company">
                                    {data.job_title} / {data.company_name}
                                    </p>
                                    <p className="outline">
                                    { salaryForms[data.salary_form_id-1] }【 { data.lower_monthly_basic_salary.toLocaleString() } 〜 { data.upper_monthly_basic_salary.toLocaleString() } 円】
                                    </p>
                                    <span className="occupation">
                                        { data.employment_status }
                                    </span>
                                    {isActive !== 3 && data.status.id != "" ? 
                                    <span className={'category type'+ data.status.id}>
                                        { data.status.name}
                                    </span>:""
                                    }
                                    <div className="work_ex">
                                        <p>{ data.preview_display_text }</p>
                                    </div>
                                </div>
                            </a>
                        </li>
                    ))}                          
                </ul>
                {/* {AppPageData > 0 ?  */}
                    <div className="pageer_cont">
                        <a className={AppPageData <= 1 ? 'prev disabled' : 'prev'} onClick={() => pageDecriment()}>前へ</a> 
                        <ol className="pager">
                            {[...Array(AppPageData)].map((pages: any, i) => (
                                <li>
                                    <a href="javascript:void(0)" className={page == i+1 ? 'current' : ''} onClick={() => setPage(i+1)}> {i+1} </a>
                                </li>
                            ))} 
                        </ol> 
                        <a className={AppPageData >= 1 ? 'next disabled' : 'next'} onClick={() => pageIncriment()}>次へ</a>
                    </div>
                <></>
                
             </section>
        </main>
    );
  }
  
  export default MypageApplications;