import { adminCompanyRecruiterTypes } from "../../ActionTypes/adminCompanyRecruiterTypes";
import { AdminCompanyRecruiterState, AdminCompanyRecruiterActions } from "../../types/type";



const initialState: AdminCompanyRecruiterState = {
  pending: false,
  recruiter: [],
  error: null,
};

export default (state = initialState, action: AdminCompanyRecruiterActions,) => {
  switch (action.type) {
    case adminCompanyRecruiterTypes.FETCH_ADMIN_COMPANY_RECRUITER_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminCompanyRecruiterTypes.FETCH_ADMIN_COMPANY_RECRUITER_SUCCESS:
      return {
        ...state,
        pending: true,
        recruiter: action.payload.recruiter,
        error: null
      };
    case adminCompanyRecruiterTypes.FETCH_ADMIN_COMPANY_RECRUITER_FAILURE:
      return {
        ...state,
        pending: false,
        recruiter: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
