import { adminApplicantsListTypes } from "../../ActionTypes/adminApplicantsListTypes";
import { AdminApplicantsListActions, AdminApplicantsListState,  } from "../../types/type";

const initialState: AdminApplicantsListState = {
  pending: false,
  applicants: [],
  pagenation: {},
  error: null,
};

export default (state = initialState, action: AdminApplicantsListActions,) => {
  switch (action.type) {
    case adminApplicantsListTypes.FETCH_ADMIN_APPLICANTS_LIST_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminApplicantsListTypes.FETCH_ADMIN_APPLICANTS_LIST_SUCCESS:
      return {
        ...state,
        pending: true,
        applicants: action.payload.applicants,
        pagenation: action.payload.pagenation,
        error: null
      };
    case adminApplicantsListTypes.FETCH_ADMIN_APPLICANTS_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        applicants: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
