import { ChangeUpdateStatusTypes } from "../../ActionTypes/changeUpdateStatusTypes";
import { FetchChangeUpdateStatusFailure, FetchChangeUpdateStatusFailurePayload, FetchChangeUpdateStatusRequest, FetchChangeUpdateStatusSuccess, FetchChangeUpdateStatusSuccessPayload } from "../../types/type";


export const fetchChangeUpdateStatusRequest = (application_id: any, status_id: any): FetchChangeUpdateStatusRequest => ({
  type: ChangeUpdateStatusTypes.FETCH_CHANGE_UPDATE_STATUS_REQUEST,
  application_id,
  status_id
});

export const fetchChangeUpdateStatusSuccess = (
  payload: FetchChangeUpdateStatusSuccessPayload,
): FetchChangeUpdateStatusSuccess => ({
  type: ChangeUpdateStatusTypes.FETCH_CHANGE_UPDATE_STATUS_SUCCESS,
  payload
});

export const fetchChangeUpdateStatusFailure = (
  payload: FetchChangeUpdateStatusFailurePayload
): FetchChangeUpdateStatusFailure => ({
  type: ChangeUpdateStatusTypes.FETCH_CHANGE_UPDATE_STATUS_FAILURE,
  payload
});
