import { ChangeUpdateApplicantsTypes } from "../../ActionTypes/changeUpdateApplicantsTypes ";
import { FetchChangeUpdateApplicantsFailure, FetchChangeUpdateApplicantsFailurePayload, FetchChangeUpdateApplicantsRequest, FetchChangeUpdateApplicantsSuccess, FetchChangeUpdateApplicantsSuccessPayload } from "../../types/type";


export const fetchChangeUpdateApplicantsRequest = (authenticate_resource_id: any, applicant: any): FetchChangeUpdateApplicantsRequest => ({
  type: ChangeUpdateApplicantsTypes.FETCH_CHANGE_UPDATE_APPLICANTS_REQUEST,
  authenticate_resource_id,
  applicant
});

export const fetchChangeUpdateApplicantsSuccess = (
  payload: FetchChangeUpdateApplicantsSuccessPayload,
): FetchChangeUpdateApplicantsSuccess => ({
  type: ChangeUpdateApplicantsTypes.FETCH_CHANGE_UPDATE_APPLICANTS_SUCCESS,
  payload
});

export const fetchChangeUpdateApplicantsFailure = (
  payload: FetchChangeUpdateApplicantsFailurePayload
): FetchChangeUpdateApplicantsFailure => ({
  type: ChangeUpdateApplicantsTypes.FETCH_CHANGE_UPDATE_APPLICANTS_FAILURE,
  payload
});
