import { scoutsTypes } from "../../ActionTypes/scoutsTypes";
import { ScoutsActions, ScoutsState } from "../../types/type";


const initialState: ScoutsState = {
  pending: false,
  scouts: [],
  error: null,
};

export default (state = initialState, action: ScoutsActions,) => {
  switch (action.type) {
    case scoutsTypes.FETCH_SCOUTS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case scoutsTypes.FETCH_SCOUTS_SUCCESS:
      return {
        ...state,
        pending: true,
        scouts: action.payload.scouts,
        error: null
      };
    case scoutsTypes.FETCH_SCOUTS_FAILURE:
      return {
        ...state,
        pending: false,
        scouts: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
