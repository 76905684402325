import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { loginTypes } from "../../ActionTypes/loginTypes";
import { fetchLoginFailure, fetchLoginSuccess } from "../../actions/loginActions/loginActions";
import { useNavigate } from "react-router-dom";


const getLogin = (email: any, password: any, resource_type: any) =>
  email != '' ? axios.post(API_BASE_ADDRESS + '/authenticate', {
    email: email,
    password: password,
    resource_type: resource_type
  }, {
    headers: { Accept: 'application/json' }
  }) : axios.get(API_BASE_ADDRESS + '/authenticate', {
    headers: {
      Authorization: localStorage.getItem('jobParachannelAuthToken') ? localStorage.getItem('jobParachannelAuthToken') : localStorage.getItem('jobParachannelAuthAdminToken'),
      Accept: '*/*',
    }
  });

function* fetchLoginSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getLogin, action.email, action.password, action.resource_type);
    if(action.email !== ''){

      const authToken = response.headers['authorization'];

      if (authToken) {
        // localStorage.clear();
        if(action.resource_type == 'Administrator') {
          localStorage.jobParachannelAuthAdminToken = authToken;
        } else {
          localStorage.jobParachannelAuthToken = authToken;
        }
        localStorage.load = '1';
        yield put({ type: 'YOUR_SUCCESS_ACTION', payload: response.data });
      } else {
        throw new Error('Authorization header not found');
      }
      yield put(fetchLoginSuccess({ login: response.data }));

    } else {
      yield put(fetchLoginSuccess({ login: response.data }));
    }

  } catch (e: any) {
    yield put(fetchLoginFailure({ error: e.message }));
  }
}

function* LoginSaga() {
  yield takeLatest(loginTypes.FETCH_LOGIN_REQUEST, fetchLoginSaga);
}

export default LoginSaga;