import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { articlesTypes } from "../../ActionTypes/articlesTypes";
import { fetchArticlesFailure, fetchArticlesSuccess } from "../../actions/articlesActions/articlesActions";
import { API_BASE_ADDRESS } from "../../API";
import { articlesDeleteTypes } from "../../ActionTypes/articlesDeleteTypes";
import { fetchArticlesDeleteFailure, fetchArticlesDeleteSuccess } from "../../actions/articlesDeleteActions/articlesDeleteActions";

var getArticlesDelete = (id:any) => axios.delete( API_BASE_ADDRESS + '/articles/' + id, {headers: { Accept: '*/*', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 

function* fetchArticlesDeleteSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getArticlesDelete, queryParams.id);
    yield put(
      fetchArticlesDeleteSuccess({
        articles: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchArticlesDeleteFailure({
        error: e.message
      })
    );
  }
}

function* ArticlesDeleteSaga() {
  yield takeLatest(articlesDeleteTypes.FETCH_ARTICLES_DELETE_REQUEST, fetchArticlesDeleteSaga);
}

export default ArticlesDeleteSaga;
