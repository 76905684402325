import { adminOpenJobRegionsTypes } from "../../ActionTypes/adminOpenJobRegionsTypes";
import { FetchAdminOpenJobRegionsFailure, FetchAdminOpenJobRegionsFailurePayload, FetchAdminOpenJobRegionsRequest, FetchAdminOpenJobRegionsSuccess, FetchAdminOpenJobRegionsSuccessPayload } from "../../types/type";



export const fetchAdminOpenJobRegionsRequest = (): FetchAdminOpenJobRegionsRequest => ({
  type: adminOpenJobRegionsTypes.FETCH_ADMIN_OPEN_JOB_REGIONS_REQUEST,
});

export const fetchAdminOpenJobRegionsSuccess = (
  payload: FetchAdminOpenJobRegionsSuccessPayload,
): FetchAdminOpenJobRegionsSuccess => ({
  type: adminOpenJobRegionsTypes.FETCH_ADMIN_OPEN_JOB_REGIONS_SUCCESS,
  payload
});

export const fetchAdminOpenJobRegionsFailure = (
  payload: FetchAdminOpenJobRegionsFailurePayload
): FetchAdminOpenJobRegionsFailure => ({
  type: adminOpenJobRegionsTypes.FETCH_ADMIN_OPEN_JOB_REGIONS_FAILURE,
  payload
});
