import { scoutsCompanyTypes } from "../../ActionTypes/scoutsCompayTypes";
import { FetchScoutsCompanyFailure, FetchScoutsCompanyFailurePayload, FetchScoutsCompanyRequest, FetchScoutsCompanySuccess, FetchScoutsCompanySuccessPayload } from "../../types/type";



export const fetchScoutsCompanyRequest = (queryParams:any): FetchScoutsCompanyRequest => ({
  type: scoutsCompanyTypes.FETCH_SCOUTS_COMPANY_REQUEST,
  queryParams,
});

export const fetchScoutsCompanySuccess = (
  payload: FetchScoutsCompanySuccessPayload,
): FetchScoutsCompanySuccess => ({
  type: scoutsCompanyTypes.FETCH_SCOUTS_COMPANY_SUCCESS,
  payload
});

export const fetchScoutsCompanyFailure = (
  payload: FetchScoutsCompanyFailurePayload
): FetchScoutsCompanyFailure => ({
  type: scoutsCompanyTypes.FETCH_SCOUTS_COMPANY_FAILURE,
  payload
});
