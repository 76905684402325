import { adminCompanyNewTypes } from "../../ActionTypes/adminCompanyNewTypes";
import { FetchAdminCompanyNewRequest, FetchAdminCompanyNewSuccessPayload, FetchAdminCompanyNewSuccess, FetchAdminCompanyNewFailurePayload, FetchAdminCompanyNewFailure } from "../../types/type";


export const fetchAdminCompanyNewRequest = (body: any, method:any, id:any): FetchAdminCompanyNewRequest => ({
  type: adminCompanyNewTypes.FETCH_ADMIN_COMPANY_NEW_REQUEST,
  body,
  method,
  id,
});

export const fetchAdminCompanyNewSuccess = (
  payload: FetchAdminCompanyNewSuccessPayload
): FetchAdminCompanyNewSuccess => ({
  type: adminCompanyNewTypes.FETCH_ADMIN_COMPANY_NEW_SUCCESS,
  payload
});

export const fetchAdminCompanyNewFailure = (
  payload: FetchAdminCompanyNewFailurePayload
): FetchAdminCompanyNewFailure => ({
  type: adminCompanyNewTypes.FETCH_ADMIN_COMPANY_NEW_FAILURE,
  payload
});