import { adminRecruitmentCsvTypes } from "../../ActionTypes/adminRecruitmentCsvTypes";
import {  AdminRecruitmentCsvActions, AdminRecruitmentCsvState } from "../../types/type";


const initialState: AdminRecruitmentCsvState = {
  pending: false,
  csv: [],
  error: null,
};

export default (state = initialState, action: AdminRecruitmentCsvActions,) => {
  switch (action.type) {
    case adminRecruitmentCsvTypes.FETCH_ADMIN_RECRUITMENT_CSV_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminRecruitmentCsvTypes.FETCH_ADMIN_RECRUITMENT_CSV_SUCCESS:
      return {
        ...state,
        pending: true,
        csv: action.payload.csv,
        error: null
      };
    case adminRecruitmentCsvTypes.FETCH_ADMIN_RECRUITMENT_CSV_FAILURE:
      return {
        ...state,
        pending: false,
        applicant: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
