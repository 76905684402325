import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchAdminScoutMatchingJobsFailure, fetchAdminScoutMatchingJobsSuccess } from "../../actions/adminScoutMatchingJobsActions/adminScoutMatchingJobsActions";
import { adminScoutMatchingJobsTypes } from "../../ActionTypes/adminScoutMatchingTypes";

const getAdminScoutJobs = () => {
 
    return axios.get(API_BASE_ADDRESS + '/admin/scout_matching/jobs', {
      headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }
    });
  
}

function* fetchApplicantDecideSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminScoutJobs);
    yield put(
      fetchAdminScoutMatchingJobsSuccess({
        jobs: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminScoutMatchingJobsFailure({
        error: e.message
      })
    );
  }
}

function* AdminScoutMatchingJobsSaga() {
  yield takeLatest(adminScoutMatchingJobsTypes.FETCH_ADMIN_SCOUT_MATCHING_JOBS_REQUEST, fetchApplicantDecideSaga);
}
export default AdminScoutMatchingJobsSaga;