
import { matomeListTypes } from "../../ActionTypes/matomeListTypes";
import { MatomeListActions, MatomeListState } from "../../types/type";


const initialState: MatomeListState = {
  pending: false,
  matome: [],
  error: null,
};

export default (state = initialState, action: MatomeListActions,) => {
  switch (action.type) {
    case matomeListTypes.FETCH_MATOME_LIST_REQUEST:
      return {
        ...state,
        pending: false
      };
    case matomeListTypes.FETCH_MATOME_LIST_SUCCESS:
      return {
        ...state,
        pending: true,
        matome: action.payload.matome,
        error: null
      };
    case matomeListTypes.FETCH_MATOME_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        matome: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
