import { topRecruiterTypes } from "../../ActionTypes/topRecruiterTypes";
import { FetchTopRecruiterFailure, FetchTopRecruiterFailurePayload, FetchTopRecruiterRequest, FetchTopRecruiterSuccess, FetchTopRecruiterSuccessPayload } from "../../types/type";




export const fetchTopRecruiterRequest = (id:any, selected_id:any): FetchTopRecruiterRequest => ({
  type: topRecruiterTypes.FETCH_TOP_RECRUITER_REQUEST,
  id,
  selected_id,
});

export const fetchTopRecruiterSuccess = (
  payload: FetchTopRecruiterSuccessPayload,
): FetchTopRecruiterSuccess => ({
  type: topRecruiterTypes.FETCH_TOP_RECRUITER_SUCCESS,
  payload
});

export const fetchTopRecruiterFailure = (
  payload: FetchTopRecruiterFailurePayload
): FetchTopRecruiterFailure => ({
  type: topRecruiterTypes.FETCH_TOP_RECRUITER_FAILURE,
  payload
});
