import { recruitmentsPublicListTypes } from "../../ActionTypes/recruitmentsPublicListTypes";
import { FetchRecruitmentsPublicListActions, RecruitmentsPublicListState } from "../../types/type";



const initialState: RecruitmentsPublicListState = {
  pending: false,
  recruitments_public_list: [],
  error: null,
};

export default (state = initialState, action: FetchRecruitmentsPublicListActions,) => {
  switch (action.type) {
    case recruitmentsPublicListTypes.FETCH_RECRUITMENTS_PUBLIC_LIST_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitmentsPublicListTypes.FETCH_RECRUITMENTS_PUBLIC_LIST_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitments_public_list: action.payload.recruitments_public_list,
        error: null
      };
    case recruitmentsPublicListTypes.FETCH_RECRUITMENTS_PUBLIC_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        recruitments_public_list: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
