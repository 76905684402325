import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruitmentsStepTwoTypes } from "../../ActionTypes/recruitmentsStepTwoTypes";
import { fetchRecruitmentsStepTwoFailure, fetchRecruitmentsStepTwoSuccess } from "../../actions/recruitmentsStepTwoActions/recruitmentsStepTwoActions";

var postRecruitmentsStepTwo = (id:any, data: any) => axios.put(API_BASE_ADDRESS + '/recruitments/' + id, data, {
headers: {
  Authorization: localStorage.getItem('jobParachannelAuthToken'),
  'Content-Type': 'application/json',
  Accept: '*/*',
}})
.then(response=>{
  return response;
})
.catch(function (error) {
  return error;
}); 

function* fetchRecruitmentsStepTwoSaga(id: any) {
  const response: AxiosResponse = yield call(postRecruitmentsStepTwo , id.id, id.data);
  try {
    yield put(
      fetchRecruitmentsStepTwoSuccess({
        recruitmentsStepTwo: response.data,
        status: response.status
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitmentsStepTwoFailure({
        error: e.message
      })
    );
  }
}

function* RecruitmentsStepTwoSaga() {
  yield takeLatest(recruitmentsStepTwoTypes.FETCH_RECRUIMENTS_STEP_TWO_REQUEST, fetchRecruitmentsStepTwoSaga);
}

export default RecruitmentsStepTwoSaga;
