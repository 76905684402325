import React, { useEffect } from 'react'
import AdminNav from './AdminNav'
import "./adminTop.css"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { fetchLoginRequest } from '../../redax/actions/loginActions/loginActions';

function AdminScouts() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!localStorage.getItem('jobParachannelAuthAdminToken')) {
        navigate('/admin/login');
        } else {
        dispatch(fetchLoginRequest('', '', ''));
        }
    }, [dispatch]);

  return (
    <main id='adminHome'>
      <AdminNav/>
      <section className='sectionScouts' style={{width:"100%"}}>
            <div className='scoutsTtitle'>ご利用や操作方法に関してお困りの方へ</div>
            <div style={{padding:"5% 10%",maxWidth:"100%",}}>
                <ul className ="bread_crumb" style={{marginTop:"30px", marginBottom:"50px"}}>
                    <li><a href="/" style={{textDecoration:"none"}}>トップ</a></li>
                    <li><a href="/recruiter/">企業様マイページ</a></li>
                </ul>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <h2 className='scoutsH2'>スカウト</h2>
                    <h3 className='scoutsSp'>スカウト残数  <strong style={{color: "#00A0E9", marginLeft:"5px"}}> 10名</strong></h3>
                </div>
                <hr style={{height:"1px", background:"#D5D5D5"}}/>
                <div style={{display:"flex", paddingLeft:"16px", paddingTop:"19px", paddingBottom:"19px", border:"1px solid #D9D9D9"}}>
                    <h4 className='scoutsH4' style={{width:"15%"}}>おすすめ条件</h4>
                    <a href="#" className='scoutsActive'>求人Aのおすすめ</a>
                    <a href="#" className='scoutsNotActive'>求人Bのおすすめ</a>
                    <a href="#" className='scoutsNotActive'>求人Cのおすすめ</a>
                </div>
                <div style={{display:"flex", paddingLeft:"16px", paddingTop:"19px", paddingBottom:"19px", border:"1px solid #D9D9D9"}}>
                    <h4 className='scoutsH4'style={{width:"15%"}}>他の条件を選択</h4>
                    <a href="#" className='scoutsNotActive'>25歳以上</a>
                </div>
                
                <h3 className='scoutsSp'style={{marginTop:"30px", marginBottom:"30px"}}>選択中の条件内容</h3>
                <div style={{display:"flex", paddingLeft:"16px", paddingTop:"19px", paddingBottom:"19px", border:"1px solid #D9D9D9",justifyContent:"space-between", paddingRight:"16px"}}>
                    <h4 className='scoutsH4'>障害種別（複数可）<strong style={{color: "#00A0E9", marginLeft:"5px"}}> 身体障害</strong></h4>
                    <h4 className='scoutsH4'>障害手帳の有無<strong style={{color: "#00A0E9", marginLeft:"5px"}}> 有</strong></h4>
                    <h4 className='scoutsH4'>就労支援の有無<strong style={{color: "#00A0E9", marginLeft:"5px"}}> 有</strong></h4>
                    <h4 className='scoutsH4'>勤務地<strong style={{color: "#00A0E9", marginLeft:"5px"}}> 東京</strong></h4>
                    <h4 className='scoutsH4'>最終ログイン<strong style={{color: "#00A0E9", marginLeft:"5px"}}> ７日以内</strong></h4>
                    <h4 className='scoutsH4'>年齢<strong style={{color: "#00A0E9", marginLeft:"5px"}}> 20~25歳</strong></h4>
                    <h4 className='scoutsH4'>直近の職歴<strong style={{color: "#00A0E9", marginLeft:"5px"}}> 有</strong></h4>                    
                </div>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop:"50px",}}>
                    <button className='scoutsButton'> 詳細な条件を設定</button>
                </div>
                <hr style={{height:"1px", background:"#D5D5D5",marginBottom:"30px", marginTop:"30px"}}/>
                <h3 className='scoutsSp'>検索結果 1120/1400</h3>
                <br />
                <div style={{display:"flex", alignItems:"center"}}>
                    <button className='adminScoutsBtn'>人材一覧</button>
                    <button className='adminScoutsBtn1'>スカウトリスト</button>
                </div>
                <div className='' style={{}}>
                    <div style={{display:"flex", border:"1px solid #D9D9D9", padding:"20px"}}>
                        <img src="/assets/img/star.png" alt="star" style={{width:"28px", height:"28px", marginRight:"20px"}} />
                        <h3 className='scoutsSp' style={{marginRight:"20px"}}>全員にスカウト候補チェック</h3>
                        <button className='starRightBtn'>スカウトに一括追加</button>
                    </div>
                    <div style={{display:"flex", border:"1px solid #D9D9D9", padding:"20px", justifyContent:"space-between"}}>
                        <div style={{display:"flex"}}>
                        <img src="/assets/img/img1.jpg" style={{width:"40px",height:"40px",borderRadius:"50%", marginRight:"10px"}} alt="" /><h4 className='scoutsH4'>東京都</h4>
                            <h3 className='scoutsSp' style={{marginRight:"40px"}}>ID:1500</h3>
                            <p className='adminTdH2d' style={{marginRight:"40px"}}>相性0%</p>
                            <img src="/assets/img/star.png" alt="star" style={{width:"28px", height:"28px", marginRight:"10px"}} />
                            <h3 className='scoutsSp'>スカウト候補に追加</h3>
                        </div>
                        <div>
                            <h4 className='scoutsH4'><strong style={{color: "#00A0E9", marginLeft:"5px"}}>直近のログイン日:10日前 </strong></h4>
                        </div>
                    </div>
                    <div style={{border:"1px solid #D9D9D9", padding:"20px",}}>
                        <div style={{display:"flex",  alignItems:"center" ,borderBottom:"1px solid #D9D9D9", width:"100%", paddingBottom:"10px"}}>
                            <img src="/assets/img/gps.png" style={{width:"23px",height:"23px",marginRight:"10px"}} alt="" />
                            <h4 className='scoutsH4' style={{marginRight:"40px"}}>東京都</h4>
                            <h4 className='scoutsH4'style={{marginRight:"40px"}}>年齢<strong style={{color: "#00A0E9", marginLeft:"5px"}}> 28歳</strong></h4>
                            <h4 className='scoutsH4'style={{marginRight:"40px"}}>障害者手帳<strong style={{color: "#00A0E9", marginLeft:"5px"}}> 有</strong></h4>
                            <h4 className='scoutsH4'style={{marginRight:"40px"}}>就労支援の有無<strong style={{color: "#00A0E9", marginLeft:"5px"}}> 無</strong></h4>
                        </div>
                        <br />
                        <h3 className='scoutsSp' style={{marginRight:"20px"}}>直近の職歴 <strong style={{color: "#00A0E9", marginLeft:"5px"}}> 有</strong></h3>
                        <br />
                        <textarea style={{background:"#D9D9D9"}} name="" id="">デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。デフォルトの文章が入ります。</textarea>
                        <br />
                        <h4 className='scoutsH4'><strong style={{color: "#00A0E9", marginLeft:"5px"}}>スキル</strong></h4>
                        <div style={{width:"100%", display:"flex", alignItems:"center", padding:"10px", background:"#D9D9D9"}}>
                            <h4 className='scoutsH4' style={{marginRight:"40px"}}>EXcel</h4>
                            <h4 className='scoutsH4' style={{marginRight:"40px"}}>英検１級</h4>
                            <h4 className='scoutsH4' style={{marginRight:"40px"}}>Word</h4>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div style={{display:"flex",justifyContent:"center"}}>
                            <button className='adminMakeScoutsBtn'>スカウト</button>
                        </div>
                    </div>
                </div>
            </div>
      </section>
    </main>
  )
}

export default AdminScouts
