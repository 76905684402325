import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../redax/reducers/rootReducer";
import { fetchStepRequest } from "../../redax/actions/stepAction/stepAction";
import { useNavigate } from "react-router-dom";


function SignUpComplete() {
  const [stepList] = useState(['仮登録情報入力', '仮登録メール送信', '本登録完了']);
  const [screen, setScreen] = useState(2);
  const [email, setEmail] = useState<string | null>(null);
  const [txt, setTxt] = useState<string | null>('');
  const [txt2, setTxt2] = useState<string | null>('');
  const [isSignUp, setSignUp] = useState<boolean>(false);
  const dispatch = useDispatch();
  const step = useSelector((state: RootState) => state.step);
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const navigate = useNavigate();  

  useEffect(() => {
    if (!queryParameters.get("token")) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if(!isSignUp) {
      dispatch(fetchStepRequest(token));
      setSignUp(true);
    }
    if(step !== undefined && step.pending) {
      setScreen(2);
      // setTimeout( () => {
        // navigate('/mypage/init/');
        // window.location.reload();
        window.location.href = '/mypage/init/';
      // }, 1500);
    } else if(step.error !== null) {
      setTxt('エラーが発生しました。');
      setTxt2('自動でマイページにリダイレクトします。');
    }
  }, [dispatch, isSignUp, step]);

    return (
      <main id="register">
      <section className="wrapper">
        <div className="ttl_box">
          <h2 className="ttl">新規会員登録</h2>
          <p>登録は<span>1分</span>で完了</p>
        </div>
        <ul className="tab_box">
          {stepList.map((data: any, index: any) => (
            <li key= {index} className={screen === index ? 'active' : ''}>
                <span>STEP{index + 1}</span>{data}
            </li>
            ))}
          </ul>

        {screen === 2 ? 
        <div className="wrapper">
          <div className="input_box">
          <h3 className="ttl">{txt}</h3>
          <p>{txt2}</p>
      </div>
      </div>
        : <></>}
      </section>
    </main>
    );
  }
  
  export default SignUpComplete;


