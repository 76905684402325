import { adminRecruitmentCsvTypes } from "../../ActionTypes/adminRecruitmentCsvTypes";
import { FetchAdminRecruitmentCsvFailure, FetchAdminRecruitmentCsvFailurePayload, FetchAdminRecruitmentCsvRequest, FetchAdminRecruitmentCsvSuccess, FetchAdminRecruitmentCsvSuccessPayload } from "../../types/type";


export const fetchAdminRecruitmentCsvRequest = (): FetchAdminRecruitmentCsvRequest => ({
  type: adminRecruitmentCsvTypes.FETCH_ADMIN_RECRUITMENT_CSV_REQUEST,
});

export const fetchAdminRecruitmentCsvSuccess = (
  payload: FetchAdminRecruitmentCsvSuccessPayload
): FetchAdminRecruitmentCsvSuccess => ({
  type: adminRecruitmentCsvTypes.FETCH_ADMIN_RECRUITMENT_CSV_SUCCESS,
  payload
});

export const fetchAdminRecruitmentCsvFailure = (
  payload: FetchAdminRecruitmentCsvFailurePayload
): FetchAdminRecruitmentCsvFailure => ({
  type: adminRecruitmentCsvTypes.FETCH_ADMIN_RECRUITMENT_CSV_FAILURE,
  payload
});