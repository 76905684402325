import React, { useEffect, useState } from 'react';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { fetchMessagesRequest } from '../../../redax/actions/messagesActions/messagesActions';
import { useParams } from 'react-router-dom';
import './applicationsDetail.scss';
import { fetchMessagesPostRequest } from '../../../redax/actions/messagesPostActions/messagesPostActions ';
import { fetchChangeUpdateStatusRequest } from '../../../redax/actions/changeUpdateStatusActions/changeUpdateStatusActions ';
import { fetchInterviewsRequest } from '../../../redax/actions/interviewsActions/interviewsActions';
import { useNavigate } from 'react-router-dom';
import Loader from '../../loader/loader';
import dayjs from 'dayjs';
import { fetchApplicantDecideRequest } from '../../../redax/actions/applicantDecideActions/applicantDecideActions';
import ja from 'dayjs/locale/ja';

function MypageApplicationsDetail() {
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [application, setApplication] = useState<any>([]);
    const [messagesRes, setMessagesRes] = useState<any[]>([]);
    const [recruitment, setRecruitment] = useState<any>([]);
    const [company, setCompany] = useState<any>([]);
    const [applicant, setApplicant] = useState<any>([]);
    const [msg, setMsg] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);    
    const [isDisabled, setDisabled] = useState(false);
    const [accepted, setAccept] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const dispatch = useDispatch();
    let paramMsg = '';
    let paramPostMsg = '';
    let message = '';
    let sentMessage: any [];
    let checkId = [1, 3, 5];
    const [loadScreen, setLoadScreen] = useState(true);
    const loginData = useSelector((state: RootState) => state.login);
    const messages = useSelector((state: RootState) => state.messages);    
    const messagespost = useSelector((state: RootState) => state.messagespost);
    const UpdateStatus = useSelector((state: RootState) => state.UpdateStatus);
    const interviews = useSelector((state: RootState) => state.interviews);
    const decides = useSelector((state: RootState) => state.decide);
    const [oneTime, setOneTime] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const closeModal = () => {
        setModalOpen(false);
    };

    const openModal = () => {
        setModalOpen(true);
    };

    useEffect(() => {
    //   dispatch(fetchLoginRequest('', '', ''));
    }, [dispatch]);

    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/mypage/init/');
            }
          setResourceId(loginData.login.resource.id);
          setFamylyName(loginData.login.resource.family_name); 
          setApplicant(loginData.login.resource);     
          if(id !== null) {               
            paramMsg = '/applications/' + id + '/messages'
            dispatch(fetchMessagesRequest(paramMsg));
          }
        }
    }, [dispatch, loginData.pending, applicant]);

    useEffect(() => {
        if(messages.pending) {
            setApplication(messages.messages.application);
            setMessagesRes(messages.messages.messages);
            setRecruitment(messages.messages.recruitment);
            setCompany(messages.messages.company);
            setLoadScreen(false);
            scroll();
        } else if(messages.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [application, messages, recruitment, company]);

    useEffect(() => {
        if(messagespost !== undefined && messagespost.pending) {
            setMessagesRes(messagespost.messages.messages);
            setLoadScreen(false);
            scroll();
            setMsg('');
        } else if(messagespost.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }  
    }, [messagespost, messagesRes]);

    const scroll = () => {
        // const self = this;
        // setTimeout(function(){
        //   self.$refs.messages.scrollTop = self.$refs.messages.scrollHeight;          
        // }, 0);
        const element = document.getElementById("messages");
        if(element != null) {
            // element!.scrollIntoView();
            element.scrollTo(0, element.scrollHeight);
        }
    };
    
    const sendMessage = () => {
        if(isDisabled) {
            setLoadScreen(true);
            dispatch(fetchMessagesPostRequest(id, {
                application_id: id,
                message: msg }));
                window.location.reload();
            // dispatch(fetchMessagesRequest(paramMsg));
        }
        // const status_id = 1 
        // dispatch(fetchChangeUpdateStatusRequest(id, status_id));
    };

    const changeUpdateStatus = () => {
        const status_id = 10;
        setLoadScreen(true);
        dispatch(fetchChangeUpdateStatusRequest(id, status_id));
        alert("選考を辞退しました")
        window.location.href = '/mypage/applications/?tab=4';
    };
    
    const setMessage = (value: any) => {
        setMsg(value);
    };

    const charaLimit = (inputText: any, limit: any) => {
        if (typeof inputText != "string") { return '' }
        return inputText.length > limit ? (inputText.length - limit) + '文字オーバー' : ''
    };

    const messagesScroll = () => {
        // const self = this
        setTimeout(function(){
          messages.scrollTop = messages.scrollHeight
        }, 0)
    };

    // const setMessage = (value: any) => {
    //     message = value
    //     // console.log(input_box);
    //     input_box.scrollIntoView()
    //   }

    const onInput = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setMsg(event.target.value);
        if(event.target.value !== ''){
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    };
    // useEffect(() => {
    //     if(isDisabled){
    //         setMsg(message);
    //     }
    // }, [msg, message, isDisabled]);

    function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
        }
    }

    function formatDate (value: any) {
        return dayjs(value).locale(ja).format('YYYY.MM.DD (ddd) HH:mm');
    }

    function formatDateRange (start: any, end: any) {
        const dayjsStart = dayjs(start)
        const dayjsEnd = dayjs(end)
        if (dayjsStart.format('YYYYMMDD') === dayjsEnd.format('YYYYMMDD')) {
        return `${dayjsStart.format('YYYY.MM.DD (ddd) HH:mm')} 〜 ${dayjsEnd.format('HH:mm')}`
        } else {
        return `${dayjsStart.format('YYYY.MM.DD (ddd) HH:mm')} 〜 ${dayjsEnd.format('YYYY.MM.DD (ddd) HH:mm')}`
        }
    }

    function setDate(e: any) {
        setStartDate(e.target.value);
    }

    function decide (accept: any) {
        setAccept(accept);
        const str = accept ? '承諾' : '辞退'
        const result = window.confirm('内定を' + str + 'してよろしいですか？')
        if (!result) { return }
        const data = { accept: accept }
        setLoadScreen(true);
        setOneTime(true);
        dispatch(fetchApplicantDecideRequest(id, data, 'post'));
    }

    function confirmed (id: any) {
        if (!startDate) {
            window.alert('面接・職場見学日程を選択してください')
            return
        }
        
        const data = {
            message_id: id,
            start_time: startDate
        }
        setLoadScreen(true);
        setOneTime(true);
        dispatch(fetchInterviewsRequest(data, 'post'));
        window.location.reload();
    }

    useEffect(() => {
        if (interviews.pending && oneTime) {
            setOneTime(false);
            paramMsg = '/applications/' + id + '/messages'
            dispatch(fetchMessagesRequest(paramMsg));
        } else if(interviews.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [interviews, dispatch, oneTime]);

    useEffect(() => {
        if (decides.pending && oneTime) {
            setOneTime(false);
            paramMsg = '/applications/' + id + '/messages'
            dispatch(fetchMessagesRequest(paramMsg));
            const str = accepted ? '承諾' : '辞退';
            alert('内定を' + str + 'しました、企業採用担当者へご連絡しましょう')
            // メッセージテンプレートのセット
            let template =''
            if (accepted) {
                template = '内定のご連絡をいただき、誠にありがとうございます。\n'
                template += '謹んで貴社からの内定をお受けいたします。\n\n'
                template += '入社後は、今までの経験を活かし、\n'
                template += '一日も早く貴社に貢献できるよう努めて参ります。\n'
                template += 'これからもどうぞよろしくお願いします。'
            } else {
                template = 'この度は内定のご連絡をいただき、誠にありがとうございました。\n'
                template += 'このような嬉しいお知らせをいただきながら誠に恐縮なのですが、検討の結果、内定を辞退させていただきたくご連絡を差し上げました。\n\n'
                template += '何卒ご容赦いただきたくお願い申し上げます。'
            }
            setMessage(template);
            setDisabled(true);
        } else if(decides.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [decides, dispatch, oneTime]);

    return (
        <main id="mypage">
             <MypageSideBar resource={resourceId} familyName = {familyName} currentList="ApplicantList"/>
             {loadScreen ? <Loader /> : <></>}
             <section  id="mypage_inner" className="recruit_management_cont message">
                <ul className="bread_crumb">
                    <li><a href="/">トップ</a></li>
                    <li><a href="/mypage/">マイページ</a></li>
                    <li><a href="/mypage/applications/">応募管理</a></li>
                    <li><a>メッセージ</a></li>
                </ul>
                <div className="ttl_box">
                    <h2 className="ttl">メッセージ</h2>
                    <ul>
                    <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                    </ul>
                </div> 
                <div className="ui_cont">
                    <div className="message_cont">
                        <div className="name_box">
                            {company ?
                            <img className="img" src={company.logo} alt=""></img>
                            : <></>}
                            {recruitment ?
                            <h3>{recruitment.job_title}<br></br>
                                <span className="company_name">{recruitment.company_name}</span>
                            </h3> 
                            : <></>}                 
                            <div className="spacer" data-v-e13aa758=""></div> 
                                <a href={`/recruitments/${recruitment?.id}`} data-v-e13aa758="" className="">求人情報を見る</a>
                        </div> 
                        {messagesRes !== undefined ?
                            <ul className="messages" id="messages">
                                {messagesRes.map((m: any) => (
                                    !m.is_auto_message_at_entry ?
                                    <li className={m.sender == 'Applicant' ? 'you' : 'company'}>
                                        {m.is_job_offer ?
                                        <div className="contents">
                                            <p>{m.message}</p>
                                            {!m.confirmed ?
                                            <div className="btn_cont">
                                            <a onClick={() => decide(true)}>内定を承諾する</a>
                                            <a onClick={() => decide(false)}>内定を辞退する</a>
                                            </div>
                                            : <></>}
                                        </div>
                                        : !m.candidates ?
                                        <div className="contents" onChange={() => setMessage(m)}>{m.message}</div>
                                        : m.sender == 'Applicant' ?
                                        <div className="contents white-space">
                                            【面接・職場見学日程について打診しました。】<br/>
                                            <br/>
                                            {company.name}さんへ代わりの面接・職場見学日程について打診しました<br/>
                                            <ul>
                                            <br/>
                                            {m.candidates.map((i: any, index : any) => (
                                            <li key={index}>
                                                日程候補{index + 1}. {formatDate(i)} 〜
                                            </li>
                                            ))}
                                            </ul>
                                        </div>
                                        : 
                                        <div className="contents white-space">
                                            【面接・職場見学日程について打診がありました。】<br/>
                                            <br/>
                                            {company.name}さんから面接・職場見学日程について打診がありました。受け入れるか、別日程を打診しましょう。<br/>
                                            {m.confirmed ?
                                            <ul>
                                            <br/>
                                            {m.candidates.map((i: any, index : any) => (
                                            <li key={index}>
                                                {formatDate(i)} 〜
                                            </li>
                                            ))}
                                            </ul>
                                            :
                                            <div className="radio mar20">
                                            {m.candidates.map((i: any, index : any) => (
                                                <label key={index}>
                                                    <input type="radio" name="candidates" value={i} v-model="startDate" onChange={(e) => setDate(e)}/>
                                                    {formatDate(i)} 〜
                                                    <br/>
                                                </label>
                                            ))}
                                            </div>}                                            
                                            {m.supp_time ?
                                            <p className='mar20'>
                                            想定実施時間 {m.supp_time }分
                                            </p> : <></>}
                                            <p><br/>※システムより自動で送信されています。</p>
                                            {!m.confirmed ?
                                            <div className="btn_cont">
                                            <a href="javascript:void(0)" onClick={(e) => confirmed(m.id)}>日時確定</a>
                                            <a href="javascript:void(0)" onClick={(e: any) => {alert('企業採用担当者へ代わりの日程候補を連絡してください');setMessage('日程候補をお送りします。\n ・○月○日　午前　午後　終日\n ・○月○日　午前　午後　終日');setDisabled(true);}}>代わりの日程を<br/>打診する</a>
                                            </div>
                                            : <></>}
                                        </div>}
                                    </li>
                                    : <></>
                                ))} 
                            </ul> 
                        : <></>} 
                        <div className="input_message_cont">
                            <textarea cols={30} rows={10} placeholder="ここにメッセージ内容を入力（200文字以内）" value={msg} onChange={onInput}></textarea> 
                            <p className="text_length_alert"></p> 
                            <div className="btn_cont">
                                <a href="javascript:void(0)" onClick={() => sendMessage() } className={isDisabled  ? '' : 'disabled'}>
                                    送信する
                                </a>
                            </div> 
                            <div>
                                <a href="/mypage/schedules/" className="change_schedules_page">
                                    スケジュールを確認する
                                </a>
                            </div>
                            {application != undefined && checkId.includes(application.status_id) ? 
                                <div>
                                    <a href="#" className="openModal" onClick={() => openModal()}>
                                        選考を辞退する
                                    </a>
                                </div>
                            : <></>} 
                        </div>
                    </div>
                </div>
                {isModalOpen ?
                    <div className="modal_box">
                        <div className="modal_inner scrollHide">
                            <div className='center'>
                                    <h3>本当に選考を辞退しますか？</h3>
                            </div>
                            <div className='display_flex'>
                                <div className='div_percent'>
                                    <button type="button" className="btn type3" onClick={() => changeUpdateStatus()}>確認する</button>
                                </div>
                                <div className='div_percent'>
                                    <button type="button" className="btn type3" onClick={() => closeModal()}>キャンセル</button>
                                </div>
                            </div>
                        </div>
                        <div className="overlay" onClick={closeModal}></div>
                    </div> : null
                }
             </section>
        </main>
    );
  }
  
  export default MypageApplicationsDetail;