import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchAdminApplicationsListFailure, fetchAdminApplicationsListSuccess } from "../../actions/adminApplicationsListActions/adminApplicationsListActions";
import { adminApplicationsListTypes } from "../../ActionTypes/adminApplicationsListTypes";
import { adminScoutsTypes } from "../../ActionTypes/adminScoutsTypes";
import { fetchAdminScoutsFailure, fetchAdminScoutsSuccess } from "../../actions/adminScoutsActions/adminScoutsActions";

const getAdminScouts = (data: any) => { 
  const config = {
    headers: { 
      Prefer: 'example=example-1', Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/scouts?page='  + data.page  + '&company_id=' + data.company_id, config); 
}


function* fetchAdminScoutsSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminScouts, action.data);
    yield put(
      fetchAdminScoutsSuccess({
        scouts: response.data.scouts,
        pagenation: response.data.pagenation,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminScoutsFailure({
        error: e.message
      })
    );
  }
}

function* AdminScoutsSaga() {
  yield takeLatest(adminScoutsTypes.FETCH_ADMIN_SCOUTS_REQUEST, fetchAdminScoutsSaga);
}
export default AdminScoutsSaga;