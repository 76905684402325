import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { articlesImageFailure, articlesImageSuccess } from "../../actions/articlesImageActions/articlesImageActions";
import { articlesImageTypes } from "../../ActionTypes/articlesImageTypes";

var setArticlesImage = (image:any, types: any, id: any) => {
  if(types == 'post') {
    return axios.post(API_BASE_ADDRESS + '/articles/image', {image: image} , {headers: { Accept: 'application/json',  Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 
  } else {
    return axios.delete(API_BASE_ADDRESS + '/articles/image/' + id, {headers: { Accept: 'application/json',  Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 
  }
}

function* fetchArticlesImageSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(setArticlesImage, action.image, action.types, action.id);

    yield put(
      articlesImageSuccess({
        image: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      articlesImageFailure({
        error: e.message
      })
    );
  }
}

function* ArticlesImageSaga() {
  yield takeLatest(articlesImageTypes.FETCH_ARTICLES_IMAGE_REQUEST, fetchArticlesImageSaga);
}

export default ArticlesImageSaga;
