import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { summaryTypes } from "../../ActionTypes/summaryTypes";
import { fetchSummarySuccess, fetchSummaryFailure } from "../../actions/summaryActions/summaryActions";

const getSummary = () =>
  axios.get(API_BASE_ADDRESS + '/admin/summary' , 
    {headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }}
    );

function* fetchSummarySaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getSummary);
    yield put(
      fetchSummarySuccess({
        summary: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchSummaryFailure({
        error: e.message
      })
    );
  }
}

function* SummarySaga() {
  yield takeLatest(summaryTypes.FETCH_SUMMARY_REQUEST, fetchSummarySaga);
}
export default SummarySaga;