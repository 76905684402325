import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { applicationCreateTypes } from "../../ActionTypes/applicationCreateTypes";
import { fetchApplicationCreateFailure, fetchApplicationCreateSuccess } from "../../actions/applicationsCreateActions/applicationCreateActions";


const getApplications = (body: any) =>
  axios.post(API_BASE_ADDRESS + '/applications' , body, {headers: { Accept: '*/*', Authorization: localStorage.getItem('jobParachannelAuthToken'),}});

function* fetchApplicantSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getApplications, action.body);
    yield put(
      fetchApplicationCreateSuccess({
        body: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchApplicationCreateFailure({
        error: e.message
      })
    );
  }
}

function* ApplicationCreateSaga() {
  yield takeLatest(applicationCreateTypes.FETCH_APPLICATION_CREATE_REQUEST, fetchApplicantSaga);
}
export default ApplicationCreateSaga;