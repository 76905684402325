import { emailChangeTypes } from "../../ActionTypes/emailChangeTypes";
import { EmailChangeActions, EmailChangeState } from "../../types/type";



const initialState: EmailChangeState = {
  pending: false,
  email: [],
  error: null,
};

export default (state = initialState, action: EmailChangeActions,) => {
  switch (action.type) {
    case emailChangeTypes.FETCH_EMAIL_CHANGE_REQUEST:
      return {
        ...state,
        pending: false
      };
    case emailChangeTypes.FETCH_EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        pending: true,
        email: action.payload.email,
        error: null
      };
    case emailChangeTypes.FETCH_EMAIL_CHANGE_FAILURE:
      return {
        ...state,
        pending: false,
        email: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
