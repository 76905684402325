import { adminApplicationsListTypes } from "../../ActionTypes/adminApplicationsListTypes";
import { adminNewsListTypes } from "../../ActionTypes/adminNewsListTypes";
import { AdminNewsListActions, AdminNewsListState } from "../../types/type";

const initialState: AdminNewsListState = {
  pending: false,
  newsArray: [],
  pagenation: {},
  error: null,
};

export default (state = initialState, action: AdminNewsListActions,) => {
  switch (action.type) {
    case adminNewsListTypes.FETCH_ADMIN_NEWS_LIST_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminNewsListTypes.FETCH_ADMIN_NEWS_LIST_SUCCESS:
      return {
        ...state,
        pending: true,
        newsArray: action.payload.newsArray,
        pagenation: action.payload.pagenation,
        error: null
      };
    case adminNewsListTypes.FETCH_ADMIN_NEWS_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        newsArray: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
