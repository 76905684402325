import { useState } from "react";


function Guide12() {

    
    return (

        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">12.各種設定について</h3>
          <p>この記事は約3分で読めます。</p>
    
          <p>このページでは各種設定について説明します。</p>
    
          <p>1.TOPページ(<a href="/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/</a>) から画面右上の「採用担当者様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/first.png" alt=""/>
    
          <p>2.「既に会員の企業様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/12/02.png" alt=""/>
    
          <p>3.登録したメールアドレス・パスワードにて、ログインしてください。<br/>
          パスワードをお忘れの方はこちらから再設定してください。<br/>
          <a href="/recruiter/password/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/recruiter/password/</a></p>
          <img src="/assets/img/guide/12/03.png" alt=""/>
    
          <p>4.マイページ画面左メニューの「設定」をクリックします。<br/>
          設定では、管理者の追加・変更、支払情報(2023年12月まで無料のため入力不要)、
          採用ステータスの変更が可能です。</p>
          <img src="/assets/img/guide/12/04.png" alt=""/>
    
          <p>5.管理者の追加は別担当者や引き継ぎの際に使う機能です。<br/>
          メールアドレスを入力し、送信ボタンを押すと招待リンクが送信されます。</p>
          <img src="/assets/img/guide/12/05.png" alt=""/>
    
          <p>6.ステータス変更を変更すると求人が一括で非公開になります。</p>
          <img src="/assets/img/guide/12/06.png" alt=""/>
          <p>
            基本操作の説明は以上となります。<br/>
            引き続き、パラちゃんねるをご活用ください。
          </p>
          <a href="/guide/" className="btn type3 nuxt-link-active">一覧へ戻る</a>
        </section>
      </main>
       
    );
  }
  
  export default Guide12;