import { adminScoutsCompanyTypes } from "../../ActionTypes/adminScoutsCompanyTypes";
import { FetchAdminScoutsCompanyFailure, FetchAdminScoutsCompanyFailurePayload, FetchAdminScoutsCompanyRequest, FetchAdminScoutsCompanySuccess, FetchAdminScoutsCompanySuccessPayload } from "../../types/type";

export const fetchAdminScoutsCompanyRequest = (): FetchAdminScoutsCompanyRequest => ({
  type: adminScoutsCompanyTypes.FETCH_ADMIN_SCOUTS_COMPANY_REQUEST
});

export const fetchAdminScoutsCompanySuccess = (
  payload: FetchAdminScoutsCompanySuccessPayload
): FetchAdminScoutsCompanySuccess => ({
  type: adminScoutsCompanyTypes.FETCH_ADMIN_SCOUTS_COMPANY_SUCCESS,
  payload
});

export const fetchAdminScoutsCompanyFailure = (
  payload: FetchAdminScoutsCompanyFailurePayload
): FetchAdminScoutsCompanyFailure => ({
  type: adminScoutsCompanyTypes.FETCH_ADMIN_SCOUTS_COMPANY_FAILURE,
  payload
});