import { adminAdministratorsDeleteTypes } from "../../ActionTypes/adminAdministratorsDeleteTypes";
import {  
  FetchAdminAdministratorsDeleteRequest,
  FetchAdminAdministratorsDeleteSuccessPayload,
  FetchAdminAdministratorsDeleteSuccess,
  FetchAdminAdministratorsDeleteFailurePayload,
  FetchAdminAdministratorsDeleteFailure} from "../../types/type";

export const fetchAdminAdministratorsDeleteRequest = (data: any): FetchAdminAdministratorsDeleteRequest => ({
  type: adminAdministratorsDeleteTypes.FETCH_ADMIN_ADMINISTRATORS_DELETE_REQUEST,
  data
});

export const fetchAdminAdministratorsDeleteSuccess = (
  payload: FetchAdminAdministratorsDeleteSuccessPayload
): FetchAdminAdministratorsDeleteSuccess => ({
  type: adminAdministratorsDeleteTypes.FETCH_ADMIN_ADMINISTRATORS_DELETE_SUCCESS,
  payload
});

export const fetchAdminAdministratorsDeleteFailure = (
  payload: FetchAdminAdministratorsDeleteFailurePayload
): FetchAdminAdministratorsDeleteFailure => ({
  type: adminAdministratorsDeleteTypes.FETCH_ADMIN_ADMINISTRATORS_DELETE_FAILURE,
  payload
});