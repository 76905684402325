

import { MessagesTypes } from "../../ActionTypes/messagesTypes";
import {
  FetchMessagesFailure,
  FetchMessagesFailurePayload,
  FetchMessagesRequest,
  FetchMessagesSuccess,
  FetchMessagesSuccessPayload,
} from "../../types/type";

export const fetchMessagesRequest = (message: any): FetchMessagesRequest => ({
  type: MessagesTypes.FETCH_MESSAGES_REQUEST,
  message
});

export const fetchMessagesSuccess = (
  payload: FetchMessagesSuccessPayload,
): FetchMessagesSuccess => ({
  type: MessagesTypes.FETCH_MESSAGES_SUCCESS,
  payload
});

export const fetchMessagesFailure = (
  payload: FetchMessagesFailurePayload
): FetchMessagesFailure => ({
  type: MessagesTypes.FETCH_MESSAGES_FAILURE,
  payload
});
