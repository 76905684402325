import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redax/reducers/rootReducer';
import { fetchPasswordResetRequest } from '../../redax/actions/passwordResetActions/passwordResetActions';
import { useNavigate } from 'react-router-dom';

function Password() {
  const [email, setEmail] = useState<string | null>(null);
  const [screen, setScreen] = useState(0);
  const [error, setError] = useState<boolean>(false);
  const [mistake, setMistake] = useState<boolean>(false);
  const dispatch = useDispatch();
  const passwordReset = useSelector((state: RootState) => state.passwordReset);
  const navigate = useNavigate();

  function emailChange (value: any) {
        setEmail(value);
        let mailformat =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        // /^[a-zA-Z0-9._]+@[a-z]+\.[a=z]{2-6}$/;
        if(setError || setMistake){
        if (value === '') {
            setError(true);
        } else {
            setError(false);
        } 
        if(mailformat.test (value)){
            setMistake(false);
        } else {
            setMistake(true);
        }
    }
  }

  function passwordResetBtn (e: any) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email!)) {
      dispatch(fetchPasswordResetRequest( email, 'Applicant'));
      e.preventDefault();
    }    
  }
  
  useEffect(() => {
    if(passwordReset.pending) {
      setScreen(1);
    } else if(passwordReset.error != null) {
      // navigate('/error?code=' + passwordReset.error.split('status code ')[1]);
      setScreen(2);
    }
  }, [passwordReset]);
  

  return (
    <main id="login">
      <section className="wrapper">
        <h2 className="ttl type3">ログイン</h2>
        <div className="input_box">
          <h3 className="ttl">パスワード再設定</h3>
          
          {screen === 0 ? <p>ご登録のメールアドレスを入力して<br className="sp_only"/>送信ボタンを押してください。</p> : <></>}
          {screen === 1 ?  <p>ご入力いただいたメールアドレス宛に<br className="sp_only"/>パスワード再設定のメールを送信いたしました。<br/>
          お送りしたメールをご確認いただき そちらより再設定の手順を実行ください。</p> : <></>}
          {screen === 2 ?<p>エラーが発生しました。<br/>恐れ入りますが、入力内容にお間違いがないかお確かめの上、再度お試しください。</p> : <></>}
          {screen === 0 ?
          <form>
          <div className='form'>
          <dl>
              <dt>メールアドレス</dt>
              <dd>
                <input
                  type="email"
                  title="メールアドレス"
                  name="email"
                  value={email || ''}
                  onChange={(e) => emailChange(e.target.value)}
                />
                {error ? 
                    <div>
                        <span v-if="!$v.email.required">必須項目です</span>
                    </div>
                : <></>} 

                {mistake ? 
                    <div>
                        <span v-if="!$v.email.email">正しいメールアドレスの形式で入力してください</span>
                    </div>
                : <></>}
              </dd>
            </dl>
            <button type="submit" onClick={(e) => passwordResetBtn(e)} className="btn type3">再設定メール送信</button>
          </div>
          </form>
          : <></>}
          
        </div>
      </section>
    </main>
  );
}

export default Password;