import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchArticlesSubmitFailure, fetchArticlesSubmitSuccess } from "../../actions/articlesSubmitActions/articlesSubmitActions";
import { articlesSubmitTypes } from "../../ActionTypes/articlesSubmitTypes";

var getArticlesSubmit = (id:any, body: any) => axios.patch( API_BASE_ADDRESS + '/articles/' + id, body, 
{headers: { Accept: '*/*', Authorization: localStorage.getItem('jobParachannelAuthToken'),}})
.then(response=>{
  return response;
})
.catch(function (error) {
  return error;
});  

function* fetchArticlesSubmitSaga(queryParams: any) {
  const response: AxiosResponse = yield call(getArticlesSubmit, queryParams.id, queryParams.body);
  try {
    yield put(
      fetchArticlesSubmitSuccess({
        articles: response.data,
        status: response.status        
      })
    );
  } catch (e:any) {
    yield put(
      fetchArticlesSubmitFailure({
        error: e.message
      })
    );
  }
}

function* ArticlesSubmitSaga() {
  yield takeLatest(articlesSubmitTypes.FETCH_ARTICLES_SUBMIT_REQUEST, fetchArticlesSubmitSaga);
}

export default ArticlesSubmitSaga;
