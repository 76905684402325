import {adminCompanyDetailTypes } from "../../ActionTypes/adminCompanyDetailTypes";
import { adminNewsCompanyDetailTypes } from "../../ActionTypes/adminNewsCompanyDetailTypes";
import {  AdminNewsCompanyDetailActions, AdminNewsCompanyDetailState } from "../../types/type";


const initialState: AdminNewsCompanyDetailState = {
  pending: false,
  newsArray: [],
  pagenation: [],
  error: null,
};

export default (state = initialState, action: AdminNewsCompanyDetailActions,) => {
  switch (action.type) {
    case adminNewsCompanyDetailTypes.FETCH_ADMIN_NEWS_COMPANY_DETAIL_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminNewsCompanyDetailTypes.FETCH_ADMIN_NEWS_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        pending: true,
        newsArray: action.payload.newsArray,
        pagenation: action.payload.pagenation,
        error: null
      };
    case adminNewsCompanyDetailTypes.FETCH_ADMIN_NEWS_COMPANY_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        newsArray: [],
        pagenation: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
