import { previewTypes } from "../../ActionTypes/previewTypes";
import { PreviewActions, PreviewState } from "../../types/type";



const initialState: PreviewState = {
  pending: false,
  preview: [],
  error: null,
};

export default (state = initialState, action: PreviewActions,) => {
  switch (action.type) {
    case previewTypes.FETCH_PREVIEW_REQUEST:
      return {
        ...state,
        pending: false
      };
    case previewTypes.FETCH_PREVIEW_SUCCESS:
      return {
        ...state,
        pending: true,
        preview: action.payload.preview,
        error: null
      };
    case previewTypes.FETCH_PREVIEW_FAILURE:
      return {
        ...state,
        pending: false,
        cpreview: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
