import { articlesSubmitTypes } from "../../ActionTypes/articlesSubmitTypes";
import { FetchArticlesSubmitFailure, FetchArticlesSubmitFailurePayload, FetchArticlesSubmitRequest, FetchArticlesSubmitSuccess, FetchArticlesSubmitSuccessPayload } from "../../types/type";


export const fetchArticlesSubmitRequest = (id:any,body: any): FetchArticlesSubmitRequest => ({
  type: articlesSubmitTypes.FETCH_ARTICLES_SUBMIT_REQUEST,
  id,
  body,
});

export const fetchArticlesSubmitSuccess = (
  payload: FetchArticlesSubmitSuccessPayload,
): FetchArticlesSubmitSuccess => ({
  type: articlesSubmitTypes.FETCH_ARTICLES_SUBMIT_SUCCESS,
  payload
});

export const fetchArticlesSubmitFailure = (
  payload: FetchArticlesSubmitFailurePayload
): FetchArticlesSubmitFailure => ({
  type: articlesSubmitTypes.FETCH_ARTICLES_SUBMIT_FAILURE,
  payload
});
