import { applicantsTypes } from "../../ActionTypes/applicantsTypes";
import { ApplicantsActions, ApplicantsState } from "../../types/type";



const initialState: ApplicantsState = {
  pending: false,
  applicants: [],
  error: null,
};

export default (state = initialState, action: ApplicantsActions,) => {
  switch (action.type) {
    case applicantsTypes.FETCH_APPLICANTS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case applicantsTypes.FETCH_APPLICANTS_SUCCESS:
      return {
        ...state,
        pending: true,
        applicants: action.payload.applicants,
        error: null
      };
    case applicantsTypes.FETCH_APPLICANTS_FAILURE:
      return {
        ...state,
        pending: false,
        applicants: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
