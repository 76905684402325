import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './profile.scss';
import { fetchApplicantsRequest } from "../../../redax/actions/applicantsActions/applicantsActions";
import { RootState } from "../../../redax/reducers/rootReducer";
import { MypageChart } from "./mypage_chart";
import { fetchDownloadResumeRequest } from "../../../redax/actions/downloadResumeActions/downloadResumeActions";
import { fetchDownloadCareerRequest } from "../../../redax/actions/downloadCareerActions/downloadCareerActions";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader/loader";

function ProfileIndex(props: { authenticate: any, navigation_book_url: any}) {
    let authenticate: any = [];
    let navigation_book_url = null;
    const [downResumePath, setResumeDownPath] = useState('');
    const [downCareerPath, setCareerDownPath] = useState('');
    const [isActive, setIsActive] = useState(1);
    const navigate = useNavigate(); 
    const [loadScreen, setLoadScreen] = useState(false);
    const pageTitle = 'マイページ｜パラちゃんねる';
    document.title = pageTitle;

    if(props) {
        authenticate  = props.authenticate;
        navigation_book_url = props.navigation_book_url;
    }
    let completeness = [];
    const dispatch = useDispatch();
    useEffect(() => {
        if (authenticate !== null) {
            dispatch(fetchApplicantsRequest(authenticate, 'get', 0));
        }
    }, [authenticate]);

    const { applicants } = useSelector(
        (state: RootState) => state.applicants
    );

    if(applicants.completeness !== undefined){
        completeness = applicants.completeness;
    }

    const downloadResume = useSelector((state: RootState) => state.downloadResume);    
    const downloadCareer = useSelector((state: RootState) => state.downloadCareer);

    useEffect(() => {
        if (downloadResume.pending) {
            setLoadScreen(false);
            setResumeDownPath(downloadResume.applicantId.file);
            window.open(downloadResume.applicantId.file, '_blank1', 'noreferrer');
        } else if(downloadResume.error != null) {
            setLoadScreen(false);
            window.alert('ダウンロードに失敗しました。\n恐れ入りますが、時間をおいて再度お試しください。');
        }
    }, [downloadResume.pending, downloadResume.error]);

    useEffect(() => {
        if (downloadCareer.pending) {
            setLoadScreen(false);
            setCareerDownPath(downloadCareer.applicantId.file);
            window.open(downloadCareer.applicantId.file, '_blank2', 'noreferrer');
        } else if(downloadCareer.error != null) {
            setLoadScreen(false);
            window.alert('ダウンロードに失敗しました。\n恐れ入りますが、時間をおいて再度お試しください。');
        }
    }, [downloadCareer.pending, downloadCareer.error]);

    function downloadResumeClick() {
        setLoadScreen(true);
        dispatch(fetchDownloadResumeRequest(authenticate));
    }

    function downloadCareerClick() {
        setLoadScreen(true);
        dispatch(fetchDownloadCareerRequest(authenticate));
    }
    

    function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
        }
    }

    return (
        <div>
            {loadScreen ? <Loader /> : <></>}
            <ul className="bread_crumb">
                <li>
                <a href="/" className="nuxt-link-active">トップ</a>
                </li>
                <li>
                <a href="/mypage/" className="nuxt-link-active">マイページ</a>
                </li>
                <li>
                <a>web履歴書</a>
                </li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">web履歴書</h2>
                <ul>
                <li>
                    <a href="#" onClick={logout}>
                    <img src="/assets/img/company/icon02.svg" alt=""/>ログアウト </a>
                </li>
                </ul>
            </div>
            {/* <div className="completion">
                <div>
                    <h2>あなたのWEB履歴書の完成度</h2>
                    <div className="graph_box">
                    <div><div><div className="">
                        <MypageChart rate={completeness.rate}/></div></div></div>
                    <p><span>{ completeness.rate }</span>%</p>
                    </div>
                    {completeness != undefined && completeness.rate < 100 ?
                    <p>100%に近づくとエントリー通過率アップ!!</p>
                    :
                    <></>}
                </div>
                <ul>
                <li>
                    <a href="/mypage/profile/?tab=true" className="">
                    <p>プロフィール</p>
                    <span className={ completeness != undefined && completeness.profile ? 'complete' : '' }>
                    {completeness != undefined && completeness.profile ? '入力済み' : '未入力項目あり'}
                    </span>
                    <img src="/assets/img/mypage/arrow01.png" alt=""/>
                    </a>
                </li>
                <li>
                    <a href="/mypage/profile2/?tab=true" className="">
                    <p>障がいについて</p>
                    <span className={ completeness != undefined && completeness.handicap ? 'complete' : '' }>
                    {completeness != undefined && completeness.handicap ? '入力済み' : '未入力項目あり'}
                    </span>
                    <img src="/assets/img/mypage/arrow01.png" alt=""/>
                    </a>
                </li>
                <li>
                    <a href="/mypage/profile3/?tab=true" className="">
                    <p>職務経歴</p>
                    <span className={ completeness != undefined && completeness.job_career ? 'complete' : '' }>
                    {completeness != undefined && completeness.job_career ? '入力済み' : '未入力項目あり'}
                    </span>
                    <img src="/assets/img/mypage/arrow01.png" alt=""/>
                    </a>
                </li>
                <li>
                    <a href="/mypage/profile4/?tab=true" className="">
                    <p>スキル</p>
                    <span className={ completeness != undefined && completeness.skill ? 'complete' : '' }>
                    {completeness != undefined && completeness.skill ? '入力済み' : '未入力項目あり'}
                    </span>
                    <img src="/assets/img/mypage/arrow01.png" alt=""/>
                    </a>
                </li>
                <li>
                    <a href="/mypage/profile5/?tab=true" className="">
                    <p>自己PR</p>
                    <span className={ completeness != undefined && completeness.pr ? 'complete' : '' }>
                    {completeness != undefined && completeness.pr ? '入力済み' : '未入力項目あり'}
                    </span>
                    <img src="/assets/img/mypage/arrow01.png" alt=""/>
                    </a>
                </li>
                <li>
                    <a href="/mypage/profile6/?tab=true" className="">
                    <p>希望条件</p>
                    <span className={ completeness != undefined && completeness.preferred ? 'complete' : '' }>
                    {completeness != undefined && completeness.preferred ? '入力済み' : '未入力項目あり'}
                    </span>
                    <img src="/assets/img/mypage/arrow01.png" alt=""/>
                    </a>
                </li>
                <li>
                    <a href="/mypage/profile7/?tab=true" className="">
                    <p>企業に伝えたい項目</p>
                    <span className={ completeness != undefined && completeness.appeal ? 'complete' : '' }>
                    {completeness != undefined && completeness.appeal ? '入力済み' : '未入力項目あり'}
                    </span>
                    <img src="/assets/img/mypage/arrow01.png" alt=""/>
                    </a>
                </li>
                </ul>
            </div> */}
            <div className="btn_box">
                <a href="#" className="navyblue" onClick={downloadResumeClick}>
                <span>履歴書ダウンロード</span>
                <img src="/assets/img/mypage/download_icon02.svg" alt=""/>
                </a>
                <a href="#" className="navyblue" onClick={downloadCareerClick}>
                <span>職務経歴書ダウンロード</span>
                <img src="/assets/img/mypage/download_icon02.svg" alt=""/>
                </a>
                {navigation_book_url != null ?
                    <a target="_blank" href={navigation_book_url} className="orange">
                    <span>ナビゲーションブック</span>
                    <img src="/assets/img/mypage/download_icon03.svg" alt=""/>
                    </a>
                : <></>}
            </div>
        </div>
    );
  }
  
  export default ProfileIndex;