import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import { RootState } from '../../../redax/reducers/rootReducer';
import Loader from '../../loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAdminScoutsCompanyRequest } from '../../../redax/actions/adminScoutsCompanyActions/adminScoutsCompanyActions';
import { fetchAdminScoutsRequest } from '../../../redax/actions/adminScoutsActions/adminScoutsActions';

const AdminScoutList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const scouts = useSelector((state: RootState) => state.adminScouts);
  const { companies  } = useSelector((state: RootState) => state.adminScoutsCompany);
  const [loadScreen, setLoadScreen] = useState(true);
  
  const [current_page, setCurrentPage] = useState(1);
  const [total_pages, setTotalPages] = useState(1);
  const [total_count, setTotalCount] = useState<any>(1);
  const [scoutsList, setScoutsList] = useState<any>([]);
  const [companiesStatuses, setCompaniesStatuses] = useState<any>([]);
  const [keyword, setKeyword] = useState(''); 
  const [page, setPage] = useState(1);
  const [downCsvPath, setDownCsvPath] = useState('');
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState<any>('');
  
  
  useEffect(() => {
    getScoutsCompany(1,'');
    dispatch(fetchAdminScoutsCompanyRequest());
}, [dispatch]); 

const getScoutsCompany = (page: any, companyId: any) => {
    setLoadScreen(true);
    const data = {
        "page": page,
        "company_id": companyId ? companyId : ''
    }
        dispatch(fetchAdminScoutsRequest(data));
        if(scouts.pagenation !== undefined) {
            setCurrentPage(scouts.pagenation.current_page);
            setTotalPages(scouts.pagenation.total_pages);
          }
}


  useEffect(() => {
    if (scouts.pending) {
        setScoutsList(scouts.scouts);
        setCurrentPage(scouts.pagenation.current_page);
        setTotalPages(scouts.pagenation.total_pages);
        setTotalCount(scouts.pagenation?.total_count || 1);
        setLoadScreen(false)
    }
  }, [scouts]);


  useEffect(() => {
    if (companies) {
      setCompaniesStatuses(companies);
    }
  }, [companies]);


  const shaping_pagenation = () => {
    if (total_pages === null) return [1]; // Add a check for total_pages being null
    if (total_pages === 1) return [1]; // Add a check for total_pages being null
    
    if (total_pages <= 11) {
      return Array.from({ length: total_pages }, (_, index) => index + 1);
    } else if (current_page < 7) {
      return Array.from({ length: 11 }, (_, index) => index + 1);
    } else if (total_pages - current_page <= 5) {
      const diff_current_total = total_pages - current_page;
      const pagenation_start = current_page - (11 - (diff_current_total + 1));
      const pagenation_end = current_page + diff_current_total;
      return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
    } else {
      const pagenation_start = current_page - 5;
      const pagenation_end = current_page + 5;
      return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
    }
};




const pageDecrement = () => {
    if (page <= 1) {
        setPage(1);
        return;
    }
    setPage((prevPage) => prevPage - 1);
    setLoadScreen(true);
    getScoutsCompany(page-1,companyId)
}


const updatePage = (p: any) => {
    setPage(p);
    setLoadScreen(true);
    getScoutsCompany(p, companyId)
}


  const pageIncrement = () => {
    if (page >= total_pages) {
      setPage(total_pages);
      return;
    }
    setPage((prevPage) => prevPage + 1);
    setLoadScreen(true);
    getScoutsCompany(page+1,companyId)
  }


  function handleClick() {
    getScoutsCompany(1,companyId)
}
  
  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader></Loader> : <></>}
      <section>
        <div className="adminPageWrapper">
          <div className="header">
            <h2 className="title">スカウト一覧 </h2>
            <div className="inputBox">
                <select 
                    name="search2" 
                    value={companyId} 
                    onChange={(e) => setCompanyId(Number(e.target.value) || '')}
                    className='typeSelect'
                >
                    <option value='' >全て</option>
                    {companiesStatuses && companiesStatuses.map((status:any) => (
                    <option key={status.id} value={status.id}>
                        {status.name}
                    </option>
                    ))}
                </select>
                <button type="button" className='searchBtn' onClick={handleClick}>絞り込み</button>
            </div>
          </div>   
          <div className="body">
            <div className="adminTable1Wrapper">
              <div className="header">
                <div className="textWrapper">
                  <span className="text">{current_page}/{total_pages} ページ &nbsp;  全{total_count}件</span>
                </div>
                <div>
                </div>
              </div>
              <div className="body">
              
                <table className="table openTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th>日時</th>
                      <th>企業</th>
                      <th>求人名</th>
                      <th>送付 </th>
                      <th>開封</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {scoutsList.length != 0 && scoutsList ?
                  <>
                  {scoutsList.map((scouts: any, index: any) => (
                    <tr className='openTable'>
                      <td>{index +1}</td>
                      <td>{scouts.id}</td>
                      <td>{scouts.created_at}</td>
                      <td>{scouts.company.name}</td>
                      <td>{scouts.recruitments && scouts.recruitments.map((recruitments: any)=> <>{recruitments.title}</>)}</td>
                      <td>{scouts.sent_count}</td>
                      <td>{scouts.opened_count}</td>
                      <td style={{textAlign:'center'}}> <button className='detailBtn' onClick={(e) => {navigate('/admin/scout_list/' + scouts.id)}}>詳細を見る</button> </td>
                    </tr>    
                  ))}
                  </>
                  : <></>}
                  </tbody>
                </table>
                <div className="paginationWrapper">
                    <div className="icon"></div>
                    <ul className="pagination">
                        {current_page && current_page > 1 && (
                        <li className="arrow prev">
                            <a onClick={() => pageDecrement()}>
                            <img src="/assets/img/company/icon06.svg" alt="前へ" />
                            </a>
                        </li>
                        )}

                        {shaping_pagenation().map((n: any) => (
                        <li key={n} className={n === current_page ? 'active' : ''}>
                            <a onClick={() => updatePage(n)}>{n}</a>
                        </li>
                        ))}

                        {current_page && current_page < total_pages && (
                        <li className="arrow next">
                            <a onClick={() => pageIncrement()}>
                            <img src="/assets/img/company/icon07.svg" alt="次へ" />
                            </a>
                        </li>
                        )}
                    </ul>
                </div>
              </div>
            </div>
          </div> 
        </div>      
      </section>
    </main>
  );
};

export default AdminScoutList;
