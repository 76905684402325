import { adminScoutsTypes } from "../../ActionTypes/adminScoutsTypes";
import {  AdminScoutsActions, AdminScoutsState } from "../../types/type";

const initialState: AdminScoutsState = {
  pending: false,
  scouts: [],
  pagenation: {},
  error: null,
};

export default (state = initialState, action: AdminScoutsActions,) => {
  switch (action.type) {
    case adminScoutsTypes.FETCH_ADMIN_SCOUTS_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminScoutsTypes.FETCH_ADMIN_SCOUTS_SUCCESS:
      return {
        ...state,
        pending: true,
        scouts: action.payload.scouts,
        pagenation: action.payload.pagenation,
        error: null
      };
    case adminScoutsTypes.FETCH_ADMIN_SCOUTS_FAILURE:
      return {
        ...state,
        pending: false,
        scouts: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
