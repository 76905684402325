import { DownloadApplicationCsvTypes } from "../../ActionTypes/downloadApplicationCsvTypes";
import { DownloadApplicationCsvActions, DownloadApplicationCsvState } from "../../types/type";

const initialState: DownloadApplicationCsvState = {
  pending: false,
  applicationCsvData: [],
  error: null,
};

export default (state = initialState, action: DownloadApplicationCsvActions,) => {
  switch (action.type) {
    case DownloadApplicationCsvTypes.FETCH_DOWNLOAD_APPLICATIONCSV_REQUEST:
      return {
        ...state,
        pending: false
      };
    case DownloadApplicationCsvTypes.FETCH_DOWNLOAD_APPLICATIONCSV_SUCCESS:
      return {
        ...state,
        pending: true,
        applicationCsvData: action.payload.applicationCsvData,
        error: null
      };
    case DownloadApplicationCsvTypes.FETCH_DOWNLOAD_APPLICATIONCSV_FAILURE:
      return {
        ...state,
        pending: false,
        applicationCsvData: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
