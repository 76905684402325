import { interviewsTypes } from "../../ActionTypes/interviewsTypes";
import { InterviewsActions, InterviewsState } from "../../types/type";



const initialState: InterviewsState = {
  pending: false,
  interviews: [],
  error: null,
};

export default (state = initialState, action: InterviewsActions,) => {
  switch (action.type) {
    case interviewsTypes.FETCH_INTERVIEWS_REQUEST:
      return {
        ...state,
        pending: false,
      };
    case interviewsTypes.FETCH_INTERVIEWS_SUCCESS:
      return {
        ...state,
        pending: true,
        interviews: action.payload.interviews,
        error: null
      };
    case interviewsTypes.FETCH_INTERVIEWS_FAILURE:
      return {
        ...state,
        pending: false,
        interviews: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
