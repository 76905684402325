import { columnsTypes } from "../../ActionTypes/columnsTypes";
import { companiesTypes } from "../../ActionTypes/companiesTypes";
import { ColumnsActions, ColumnsState, CompaniesActions, CompaniesState } from "../../types/type";



const initialState: ColumnsState = {
  pending: false,
  columns: [],
  error: null,
};

export default (state = initialState, action: ColumnsActions,) => {
  switch (action.type) {
    case columnsTypes.FETCH_COLUMNS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case columnsTypes.FETCH_COLUMNS_SUCCESS:
      return {
        ...state,
        pending: true,
        columns: action.payload.columns,
        error: null
      };
    case columnsTypes.FETCH_COLUMNS_FAILURE:
      return {
        ...state,
        pending: false,
        columns: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
