import { scoutsTypes } from "../../ActionTypes/scoutsTypes";
import { FetchScoutsFailure, FetchScoutsFailurePayload, FetchScoutsRequest, FetchScoutsSuccess, FetchScoutsSuccessPayload } from "../../types/type";


export const fetchScoutsRequest = (id: any, page:any): FetchScoutsRequest => ({
  type: scoutsTypes.FETCH_SCOUTS_REQUEST,
  id,
  page,
});

export const fetchScoutsSuccess = (
  payload: FetchScoutsSuccessPayload,
): FetchScoutsSuccess => ({
  type: scoutsTypes.FETCH_SCOUTS_SUCCESS,
  payload
});

export const fetchScoutsFailure = (
  payload: FetchScoutsFailurePayload
): FetchScoutsFailure => ({
  type: scoutsTypes.FETCH_SCOUTS_FAILURE,
  payload
});
