
import { recruitmentsPublicListTypes } from "../../ActionTypes/recruitmentsPublicListTypes";
import { FetchRecruitmentsPublicListFailure, FetchRecruitmentsPublicListFailurePayload, FetchRecruitmentsPublicListRequest, FetchRecruitmentsPublicListSuccess, FetchRecruitmentsPublicListSuccessPayload } from "../../types/type";


export const fetchRecruitmentsPublicListRequest = (id:any): FetchRecruitmentsPublicListRequest => ({
  type: recruitmentsPublicListTypes.FETCH_RECRUITMENTS_PUBLIC_LIST_REQUEST,
  id,
});

export const fetchRecruitmentsPublicListSuccess = (
  payload: FetchRecruitmentsPublicListSuccessPayload,
): FetchRecruitmentsPublicListSuccess => ({
  type: recruitmentsPublicListTypes.FETCH_RECRUITMENTS_PUBLIC_LIST_SUCCESS,
  payload
});

export const fetchRecruitmentsPublicListFailure = (
  payload: FetchRecruitmentsPublicListFailurePayload
): FetchRecruitmentsPublicListFailure => ({
  type: recruitmentsPublicListTypes.FETCH_RECRUITMENTS_PUBLIC_LIST_FAILURE,
  payload
});
