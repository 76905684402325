import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchResetFailure, fetchResetSuccess } from "../../actions/resetActions/resetActions";
import { resetTypes } from "../../ActionTypes/resetTypes";



const getPassword = (password: any, password_confirm: any, query: any) =>
  axios.post(API_BASE_ADDRESS + '/password_reset/?token=' + query , { password: password, password_confirm: password_confirm},  {
    headers: { Accept: 'application/json' }
  });

function* fetchResetSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getPassword, action.password, action.password_confirm, action.query);
    const authToken = response.headers['authorization'];
    if (authToken) {
      localStorage.jobParachannelAuthToken = authToken;
      localStorage.load = '1';
      yield put(
        fetchResetSuccess({
          password: response.data        
        })
      );
    } else {
      throw new Error('Authorization header not found');
    }
  } catch (e:any) {
    yield put(
      fetchResetFailure({
        error: e.message
      })
    );
  }
}

function* ResetSaga() {
  yield takeLatest(resetTypes.FETCH_RESET_REQUEST, fetchResetSaga);
}
export default ResetSaga;