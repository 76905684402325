

import { adminAdministratorsRegionsTypes } from "../../ActionTypes/adminAdministratorsRegionsTypes";
import { FetchAdminAdministratorsRegionsFailure, FetchAdminAdministratorsRegionsFailurePayload, FetchAdminAdministratorsRegionsRequest, FetchAdminAdministratorsRegionsSuccess, FetchAdminAdministratorsRegionsSuccessPayload } from "../../types/type";


export const fetchAdminAdministratorsRegionsRequest = (): FetchAdminAdministratorsRegionsRequest => ({
  type: adminAdministratorsRegionsTypes.FETCH_ADMIN_ADMINISTRATORS_REGIONS_REQUEST,
});

export const fetchAdminAdministratorsRegionsSuccess = (
  payload: FetchAdminAdministratorsRegionsSuccessPayload,
): FetchAdminAdministratorsRegionsSuccess => ({
  type: adminAdministratorsRegionsTypes.FETCH_ADMIN_ADMINISTRATORS_REGIONS_SUCCESS,
  payload
});

export const fetchAdminAdministratorsRegionsFailure = (
  payload: FetchAdminAdministratorsRegionsFailurePayload
): FetchAdminAdministratorsRegionsFailure => ({
  type: adminAdministratorsRegionsTypes.FETCH_ADMIN_ADMINISTRATORS_REGIONS_FAILURE,
  payload
});
