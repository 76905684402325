import { applicantsPrTypes } from "../../ActionTypes/applicantsPrTypes";
import { ApplicantsPrActions, ApplicantsPrState } from "../../types/type";

const initialState: ApplicantsPrState = {
  pending: false,
  pr: [],
  error: null,
};

export default (state = initialState, action: ApplicantsPrActions,) => {
  switch (action.type) {
    case applicantsPrTypes.FETCH_APPLICANTS_PR_REQUEST:
      return {
        ...state,
        pending: false
      };
    case applicantsPrTypes.FETCH_APPLICANTS_PR_SUCCESS:
      return {
        ...state,
        pending: true,
        pr: action.payload.pr,
        error: null
      };
    case applicantsPrTypes.FETCH_APPLICANTS_PR_FAILURE:
      return {
        ...state,
        pending: false,
        pr: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
