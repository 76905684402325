import { scoutsCandidateTypes } from "../../ActionTypes/scoutsCandidateTypes";
import { ScoutsCandidateActions, ScoutsCandidateState } from "../../types/type";


const initialState: ScoutsCandidateState = {
  pending: false,
  candidates: [],
  error: null,
};

export default (state = initialState, action: ScoutsCandidateActions,) => {
  switch (action.type) {
    case scoutsCandidateTypes.FETCH_SCOUTS_CANDIDATE_REQUEST:
      return {
        ...state,
        pending: false
      };
    case scoutsCandidateTypes.FETCH_SCOUTS_CANDIDATE_SUCCESS:
      return {
        ...state,
        pending: true,
        candidates: action.payload.candidates,
        error: null
      };
    case scoutsCandidateTypes.FETCH_SCOUTS_CANDIDATE_FAILURE:
      return {
        ...state,
        pending: false,
        scouts: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
