import { useEffect, useRef, useState } from 'react';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import './profile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApplicantsRequest } from '../../../redax/actions/applicantsActions/applicantsActions';
import { fetchApplicantsPrRequest } from '../../../redax/actions/applicantsPrActions/applicantsPrActions';
import { RootState } from '../../../redax/reducers/rootReducer';
import ProfileTab from './tab';
import ProfileIndex from '.';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { useNavigate } from 'react-router-dom';
import Loader from '../../loader/loader';

export default function ProfilePage5() {
    const [applicant, setApplicant] = useState<any>(null);
    const [content, setContent] = useState<any>('');
    const [self_pr, setself_pr] = useState(null);
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [authenticate, setAuthenticate] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const [loadScreen, setLoadScreen] = useState(true);
    const [isValid, setValid] = useState(false);
    const [tempolary, settempolary] = useState(false);
    const [navigation_book, setNavigationBook] = useState(null); 
    const [edited,setEdited] = useState(false);
    const [over, setOver] = useState<any>(null);
    const pageTitle = 'WEB履歴書（自己PR）｜パラちゃんねる';
    document.title = pageTitle;
    const queryParams = new URLSearchParams(window.location.search);
    const refs = useRef<any>(null);
    useEffect(() => {
        if (!localStorage.getItem('jobParachannelAuthToken')) {
          navigate('/login');
        } 
      }, [navigate, dispatch]);

    useEffect(() => {
        // dispatch(fetchLoginRequest('', '',''));
        if(queryParams.get('tab') != null || queryParams.get('next') != null) {
            setTimeout(() => {              
                refs.current?.scrollIntoView({behavior:"smooth", block:"start"})
            }, 0);
        }
    }, [dispatch]);
    const loginData = useSelector(
        (state: RootState) => state.login
    );
    const applicants = useSelector(
        (state: RootState) => state.applicants
    );
    const pr = useSelector(
        (state: RootState) => state.pr
    );

    useEffect(() => {
        if(!edited) return;
  
        window.addEventListener("beforeunload", confirmSave);
  
        return () => window.removeEventListener("beforeunload", confirmSave);
  
    },[edited]);
  
    function confirmSave (event: any) {
        event.preventDefault();
        if (edited) {
            event.returnValue = "編集中のデータは保存されていませんが、移動しますか？"
        }
    }

    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/mypage/init/');
            }
          setResourceId(loginData.login.resource.id);
          setFamylyName(loginData.login.resource.family_name);
          setAuthenticate(loginData.login.resource.id);
          dispatch(fetchApplicantsRequest(loginData.login.resource.id, 'get', 0));
        }
    }, [dispatch, loginData.pending]);
    
    useEffect(() => {
        if (applicants.pending) {
            setLoadScreen(false);
            setApplicant(applicants.applicants.applicant);
            setContent(applicants.applicants.applicant.self_pr.content);
            setself_pr(applicants.applicants.applicant.self_pr);
            if(applicants.applicants.applicant.self_pr.content) {
                if(!charaLimit(applicants.applicants.applicant.self_pr.content, 400)) {
                    setValid(false);
                }
            }
        } else if(applicants.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [applicants]);

    useEffect(() => {
        if (pr.pending) {
            if (localStorage.entryFlag) {
                if(window.confirm("エントリーページへ戻りますか？")) {
                  let recruitment_id = localStorage.entryFlag;
                  localStorage.removeItem('entryFlag');
                  window.location.href = '/recruitments/' + recruitment_id + '/entry/';
                  return;
                }
            }
            
            if (tempolary) {
                alert('保存しました');
                navigate('/mypage/');
                return;
            }
            // navigate('/mypage/profile6/');
            window.location.href = '/mypage/profile6/?next=true';
        } else if(pr.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [pr]);

    function charaLimit (inputText: any, limit: any) {
        if (typeof inputText != "string") { return '' }
        return inputText.length > limit ? (inputText.length - limit) + '文字オーバー' : ''
    }

    function handleContent(val: any) {
        setEdited(true);
        if(val == '') {
            setOver('');
        } else if(charaLimit (val, 400)) {
            setValid(true);
            setOver(charaLimit (val, 400));
        } else {
            setValid(false);
            setOver('');
        }
        setContent(val);
    }

    function handleContentKey(val: any) {
        setEdited(true);
        if(val.value == '') {
            setOver('');
        } else if(charaLimit (val.value, 400)) {
            setValid(true);
            setOver(charaLimit (val.value, 400));
        } else {
            setValid(false);
            setOver('');
        }
        setContent(val.value);
    }

    function submit (e: any, tempolary1 = false) {
        if(!tempolary1) {
            e.preventDefault();
        }
        let error = false
        error = charaLimit(content, 400) !== ''
        if (error) {
          window.alert('文字数がオーバーしています')
          return
        }
        const data = {
            id: applicant.self_pr.id,
            content: content,
            movie: applicant.self_pr.movie
        }
        setEdited(false);
        settempolary(tempolary1);
        dispatch(fetchApplicantsPrRequest(loginData.login.resource.id, 'self_pr', applicant.self_pr.id, data));
    }

    return (
      <main id="mypage">
        <MypageSideBar resource={resourceId} familyName = {familyName} currentList="ApplicantList" />
        {loadScreen ? <Loader /> : <></>}
        <section id="mypage_inner">
            <ProfileIndex authenticate={authenticate} navigation_book_url={applicant && applicant.navigation_book}/>
            <ProfileTab ref={refs} index={5} />
            <form className="profile wrapper">
                <div className="cont1">
                    <h3 className="ttl">自己PR</h3>
                    <p className="ex_info">※ <span className="required">必須</span>が付いている項目はエントリーに必要な情報です。 </p>
                    <div className="wrapper">
                    <table>
                        <tbody className="other">
                        <tr>
                            <th>自己PR <span className="any">任意</span>
                            </th>
                            <td className="nest">
                            <div className="textarea_cont">
                                <h4>自己PR（４００文字以内）</h4>
                                <textarea placeholder="活かせるスキルや意気込み、または「こういう合理的配慮・環境があれば○○ができる」などのPRを書いてください。" 
                                onChange={(e) => handleContent(e.target.value)}
                                onKeyDown={(e) => handleContentKey(e.target)}
                                onKeyUp={(e) => handleContentKey(e.target)}
                                defaultValue={content}></textarea>
                                <p className="text_length_alert">{over}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="btn_box">
                    <button type="button" onClick={(e) => submit(e, true)} className="btn type3">一時保存する</button>
                    <button type="submit" disabled={isValid} onClick={(e) => submit(e, false)} className="btn type3 orange"> 保存して次へ <img src="/assets/img/jobs/arrow03.svg" alt=""/>
                    </button>
                </div>
                </form>
            </section>
      </main>
    );
}