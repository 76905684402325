
import { recruitersTypes } from "../../ActionTypes/recruitersTypes";
import { RecruitersActions, RecruitersState } from "../../types/type";

const initialState: RecruitersState = {
  pending: false,
  recruiters: [],
  error: null,
};

export default (state = initialState, action: RecruitersActions,) => {
  switch (action.type) {
    case recruitersTypes.FETCH_RECRUITERS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitersTypes.FETCH_RECRUITERS_SUCCESS:
      return {
        ...state,
        pending: true,
        recruiters: action.payload.recruiters,
        error: null
      };
    case recruitersTypes.FETCH_RECRUITERS_FAILURE:
      return {
        ...state,
        pending: false,
        recruiters: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
