import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchPaymentlistFailure, fetchPaymentlistSuccess } from "../../actions/recruiterInvoiceActions/recrutierPaymentlistActions";
import { RecruiterPaymentlistTypes } from "../../ActionTypes/recruiterInvoiceTypes";

const getRecruiterInvoice = (method: any, data: any) => {

      return axios.get(API_BASE_ADDRESS + '/recruiter/invoice', {
        headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken }
      });
    }

function* fetchRecruiterInvoiceSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getRecruiterInvoice, action.method, action.data);
    yield put(
        fetchPaymentlistSuccess({
        recruiterPaymentlist: response.data
      })
    );
  } catch (e:any) {
    yield put(
        fetchPaymentlistFailure({
        error: e.message
      })
    );
  }
}

function* RecruiterInvoiceSaga() {
  yield takeLatest(RecruiterPaymentlistTypes.FETCH_RECRUITER_PAYMENTLIST_REQUEST, fetchRecruiterInvoiceSaga);
}
export default RecruiterInvoiceSaga;