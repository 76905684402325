import { companiesTypes } from "../../ActionTypes/companiesTypes";
import { invitationTypes } from "../../ActionTypes/invitationTypes";
import { FetchInvitationFailure, FetchInvitationFailurePayload, FetchInvitationRequest, FetchInvitationSuccess, FetchInvitationSuccessPayload } from "../../types/type";


export const fetchInvitationRequest = (id:any): FetchInvitationRequest => ({
  type: invitationTypes.FETCH_INVITATIONS_REQUEST,
  id,
});

export const fetchInvitationSuccess = (
  payload: FetchInvitationSuccessPayload,
): FetchInvitationSuccess => ({
  type: invitationTypes.FETCH_INVITATIONS_SUCCESS,
  payload
});

export const fetchInvitationFailure = (
  payload: FetchInvitationFailurePayload
): FetchInvitationFailure => ({
  type: invitationTypes.FETCH_INVITATIONS_FAILURE,
  payload
});
