import { adminRecommendJobsTypes } from "../../ActionTypes/adminRecommendJobsTypes";
import { FetchAdminRecommendJobsFailure, FetchAdminRecommendJobsFailurePayload, FetchAdminRecommendJobsRequest, FetchAdminRecommendJobsSuccess, FetchAdminRecommendJobsSuccessPayload } from "../../types/type";


export const fetchAdminRecommendJobsRequest = (): FetchAdminRecommendJobsRequest => ({
  type: adminRecommendJobsTypes.FETCH_ADMIN_RECOMMEND_JOBS_REQUEST,
});

export const fetchAdminRecommendJobsSuccess = (
  payload: FetchAdminRecommendJobsSuccessPayload
): FetchAdminRecommendJobsSuccess => ({
  type: adminRecommendJobsTypes.FETCH_ADMIN_RECOMMEND_JOBS_SUCCESS,
  payload
});

export const fetchAdminRecommendJobsFailure = (
  payload: FetchAdminRecommendJobsFailurePayload
): FetchAdminRecommendJobsFailure => ({
  type: adminRecommendJobsTypes.FETCH_ADMIN_RECOMMEND_JOBS_FAILURE,
  payload
});