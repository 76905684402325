import { recruiterPricingTypes } from "../../ActionTypes/recruiterPricingTypes";
import { FetchRecruiterPricingFailure, FetchRecruiterPricingFailurePayload, FetchRecruiterPricingRequest, FetchRecruiterPricingSuccess, FetchRecruiterPricingSuccessPayload } from "../../types/type";


export const fetchRecruiterPricingRequest = (): FetchRecruiterPricingRequest => ({
  type: recruiterPricingTypes.FETCH_RECRUITER_PRICING_REQUEST,
});

export const fetchRecruiterPricingSuccess = (
  payload: FetchRecruiterPricingSuccessPayload,
): FetchRecruiterPricingSuccess => ({
  type: recruiterPricingTypes.FETCH_RECRUITER_PRICING_SUCCESS,
  payload
});

export const fetchRecruiterPricingFailure = (
  payload: FetchRecruiterPricingFailurePayload
): FetchRecruiterPricingFailure => ({
  type: recruiterPricingTypes.FETCH_RECRUITER_PRICING_FAILURE,
  payload
});
