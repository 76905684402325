import { articlesDeleteTypes } from "../../ActionTypes/articlesDeleteTypes";
import { ArticlesDeleteActions, ArticlesDeleteState } from "../../types/type";

const initialState: ArticlesDeleteState = {
  pending: false,
  articles: [],
  error: null,
};

export default (state = initialState, action: ArticlesDeleteActions,) => {
  switch (action.type) {
    case articlesDeleteTypes.FETCH_ARTICLES_DELETE_REQUEST:
      return {
        ...state,
        pending: false
      };
    case articlesDeleteTypes.FETCH_ARTICLES_DELETE_SUCCESS:
      return {
        ...state,
        pending: true,
        articles: action.payload.articles,
        error: null
      };
    case articlesDeleteTypes.FETCH_ARTICLES_DELETE_FAILURE:
      return {
        ...state,
        pending: false,
        articles: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
