import React, { useEffect, useState }  from 'react'
import './adminTop.css';
import AdminNav from './AdminNav';
import { fetchLoginRequest } from '../../redax/actions/loginActions/loginActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


function AdminTop() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (!localStorage.getItem('jobParachannelAuthAdminToken')) {
          navigate('/admin/login');
        } else {
          dispatch(fetchLoginRequest('', '', ''));
        }
      }, [dispatch]);

      const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
    
      useEffect(() => {
        // Attach the event listener on component mount
        window.addEventListener('resize', handleResize);
        console.log(windowSize.width);
        
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [windowSize]);
      
  return (
    <main id='adminHome'>
        <AdminNav/>
        <section className='sectionRight'>
            <div className='naviDown adminCardLogo'>
                <a className='adminCard' href='/admin/home'>
                    <img src="/assets/img/adminHomeLogo1.svg" alt="chat" />
                    <p>データ一覧</p>
                </a>
                <a className='adminCard' href = '/admin/applicants_list'>
                    <img src="/assets/img/adminHomeLogo2.svg" alt="chat" />
                    <p>求職者一覧</p>
                </a>
                <a className='adminCard' href='/admin/companies'>
                    <img src="/assets/img/adminHomeLogo3.svg" alt="chat" />
                    <p>企業一覧</p>
                </a>
                <a className='adminCard' href='/admin/scout_matchings/jobs'>
                    <img src="/assets/img/chat.png" alt="chat" />
                    <p>スカウト代行</p>
                </a>
                <a className='adminCard' href='/admin/recommend_matchings/jobs'>
                    <img src="/assets/img/chat.png" alt="chat" />
                    <p>お知らせマッチング</p>
                </a>
                <a className='adminCard' href='/admin/invoice_check'>
                    <img src="/assets/img/adminHomeLogo5.svg" alt="chat" />
                    <p>入金管理</p>
                </a>
            </div>
            <div className='naviDown adminCardLogo'>
                <a className='adminCard' href = '/admin/openjob_list'>
                    <img src="/assets/img/adminHomeLogo6.svg" alt="chat" />
                    <p>求人一覧</p>
                </a>
                <a className='adminCard' href = '/admin/applications'>
                    <img src="/assets/img/adminHomeLogo7.svg" alt="chat" />
                    <p>応募一覧</p>
                </a>
                <a className='adminCard' href = '/admin/news'>
                    <img src="/assets/img/adminHomeLogo8.svg" alt="chat" />
                    <p>お知らせ一覧</p>
                </a>
                <a className='adminCard' href = '/admin/scout_list'>
                    <img src="/assets/img/adminHomeLogo9.svg" alt="chat" />
                    <p>スカウト一覧</p>
                </a>
                <a className='adminCard' href = '/admin/administrators'>
                    <img src="/assets/img/adminHomeLogo10.svg" alt="chat" />
                    <p>管理者一覧</p>
                </a>
            </div>
        </section>
    </main>
  )
}

export default AdminTop
