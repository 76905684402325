import { useState } from "react";


function Guide9() {

    
    return (
        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">9.求人管理・修正・削除について</h3>
          <p>この記事は約3分で読めます。</p>
    
          <p>このページでは求人管理・修正・削除について説明します。</p>
    
          <p>1.TOPページ(<a href="/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/</a>) から画面右上の「採用担当者様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/first.png" alt=""/>
    
          <p>2.「既に会員の企業様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/09/02.png" alt=""/>
    
          <p>3.登録したメールアドレス・パスワードにて、ログインしてください。<br/>
          パスワードをお忘れの方はこちらから再設定してください。<br/>
          <a href="/recruiter/password/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/recruiter/password/</a></p>
          <img src="/assets/img/guide/09/03.png" alt=""/>
    
          <p>4.マイページ画面左メニューの「求人投稿・管理」をクリックし、一覧を選びます。<br/>
          この画面では、求人の複製・編集・削除が行えます。</p>
    
          <p>求人の完成サンプルは<a href="/recruitments/6501/" style={{display: 'inline',color: '#00a0e9'}}>こちら</a></p>
          <img src="/assets/img/guide/09/04.png" alt=""/>
    
          <p>5.編集ボタンを押すと保存された求人のプレビューが表示されます。<br/>
          編集後、変更を反映するには一時保存または公開ボタンのどちらかを押してください。</p>
          <img src="/assets/img/guide/09/05.png" alt=""/>
    
          <p>6.複製ボタンを押すと、該当の求人の複製が簡単に行うことができます。<br/>
          同一職種、別勤務地の掲載等にお使いください。<br/>
          留意点：パラちゃんねるでは1アカウント3求人までの投稿になっています。 </p>
          <img src="/assets/img/guide/09/04.png" alt=""/>
    
          <p>続いて、応募者一覧・ステータス管理については<a href="/guide/10/" style={{display: 'inline',color: '#00a0e9'}}>こちら</a>をお読みください。</p>
          <a href="/guide/" className="btn type3 nuxt-link-active">一覧へ戻る</a>
        </section>
      </main>
       
    );
  }
  
  export default Guide9;