import {adminInvoiceTypes } from "../../ActionTypes/adminInvoiceTypes";
import {  AdminInvoiceActions, AdminInvoiceState } from "../../types/type";


const initialState: AdminInvoiceState = {
  pending: false,
  invoiceList: [],
  error: null,
};

export default (state = initialState, action: AdminInvoiceActions,) => {
  switch (action.type) {
    case adminInvoiceTypes.FETCH_ADMIN_INVOICE_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminInvoiceTypes.FETCH_ADMIN_INVOICE_SUCCESS:
      return {
        ...state,
        pending: true,
        invoiceList: action.payload.invoiceList,
        error: null
      };
    case adminInvoiceTypes.FETCH_ADMIN_INVOICE_FAILURE:
      return {
        ...state,
        pending: false,
        applicant: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
