import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import { RootState } from '../../../redax/reducers/rootReducer';
import Loader from '../../loader/loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { fetchAdminOpenJobRegionsRequest } from '../../../redax/actions/adminOpenJobRegionsActions/adminOpenJobRegionsActions';
import { fetchAdminCompanyAllListRequest } from '../../../redax/actions/adminComapnyAllListActions/adminComapnyAllListActions';
import { fetchPostCodeRequest } from '../../../redax/actions/postcodeActions/postcodeActions';
import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';
import { fetchAdminRecruitmentPostRequest } from '../../../redax/actions/adminRecruitmentPostActions/adminRecruitmentPostActions';
import { fetchAdminRecruitmentEditRequest } from '../../../redax/actions/adminRecruitmentEditActions/adminRecruitmentEditActions';


const AdminOpenJobEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const loginData = useSelector((state: RootState) => state.login);
  const postcodeData = useSelector((state: RootState) => state.postcode);
  const adminOpenJobRegions = useSelector((state: RootState) => state.adminOpenJobRegions);
  const adminComapnyAllList = useSelector((state: RootState) => state.adminComapnyAllList);
  const adminRecruitmentPost = useSelector((state: RootState) => state.adminRecruitmentPost);
  const adminRecruitmentEdit = useSelector((state: RootState) => state.adminRecruitmentEdit);
  const [helloworkNotice, setHelloworkNotice] = useState(false);
  const [isAnnualIncomeShow, setIsAnnualIncomeShow] = useState(true);
  const [restTime, setRestTime] = useState(null);
  const [numberOfWorkingDays, setNumberOfWorkingDays] = useState<any>(null);
  const [selectedSalaryFormId, setSelectedSalaryFormId] = useState('');
  const [lowerMonthlyBasicSalary, setLowerMonthlyBasicSalary] = useState(null);
  const [upperMonthlyBasicSalary, setUpperMonthlyBasicSalary] = useState(null);
  const [lowerEstimatedAnnualIncome, setLowerEstimatedAnnualIncome] = useState(null);
  const [retirementMoneyExists, setRetirementMoneyExists] = useState<any>(false);
  const [numberOfEmployees, setNumberOfEmployees] = useState<number>();
  const [locationDepartment, setLocationDepartment] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [companyList, setCompanyList] = useState<any>([]);
  const [params, setAllParams] = useState<any>(null);
  const [officeNumber, setOfficeNumber] = useState<any>(null);
  const [recruitments, setRecruitments] = useState<any>([]);
  const [pages, setPages] = useState<any>(null);
  const [master, setMaster] = useState<any[]>([]); 
  const [jobCategories, setjobCategories] = useState<any[]>([]); 
  const [jobCategoryTypes, setjobCategoryTypes] = useState<any[]>([]);
  const [employmentStatuses, setemploymentStatuses] = useState<any[]>([]);
  const [typeOfRecruitingCompanies, settypeOfRecruitingCompanies] = useState<any[]>([]);
  const [contractPeriods, setcontractPeriods] = useState<any[]>([]);
  const [trialPeriods, settrialPeriods] = useState<any[]>([]);
  const [holidays, setholidays] = useState<any[]>([]);
  const [salaryForms, setsalaryForms] = useState<any[]>([]);
  const [allowances, setAllowances] = useState<any[]>([]);
  const [selectedAllowanceIds, setSelectedAllowanceIds] = useState<any[]>([]);
  const [vacations, setvacations] = useState<any[]>([]);
  const [socialInsurances, setsocialInsurances] = useState<any[]>([]);
  const [selectionMethods, setselectionMethods] = useState<any[]>([]);
  const [work_from_home, setwork_from_home] = useState<any[]>([]);
  const jobTitlemaxLength = 20;
  const businessContentmaxLength = 400;
  const requiredExperiencemaxLength = 200;
  const locationWorkLocationSupplementalmaxLength = 200;
  const workTimeSupplementalmaxLength = 200;
  const restTimeSupplementalmaxLength = 200;
  const overtimeHoursSupplementalmaxLength = 200;
  const numberOfWorkingDaysSupplementalmaxLength = 200;
  const holidaysSupplementalmaxLength = 200;
  const allowancesSupplementalmaxLength = 200;
  const benefitSupplementalmaxLength = 200;
  const contractPeriodSupplementalmaxLength = 200;
  const trialPeriodSupplementalmaxLength = 200;
  const selectionMethodSupplementalmaxLength = 400;
  const locationWorkLocationSupplementPlaceholder = '「新宿支店、大宮支店でも応募可能です」など\n「原則在宅勤務、月一回のみ出社可能性あり」など';
  const [jobTitle, setJobTitle] = useState('');
  const [businessContent, setBusinessContent] = useState('');
  const [requiredExperience, setRequiredExperience] = useState('');
  const [locationWorkLocationSupplemental, setlocationWorkLocationSupplemental] = useState('');
  const [contractPeriodSupplemental, setcontractPeriodSupplemental] = useState('');
  const [trialPeriodSupplemental, settrialPeriodSupplemental] = useState('');
  const [workTimeSupplemental, setworkTimeSupplemental] = useState('');
  const [restTimeSupplemental, setrestTimeSupplemental] = useState('');
  const [numberOfWorkingDaysSupplemental, setnumberOfWorkingDaysSupplemental] = useState('');
  const [overtimeHoursSupplemental, setovertimeHoursSupplemental] = useState('');
  const [holidaysSupplemental, setholidaysSupplemental] = useState('');
  const [allowancesSupplemental, setallowancesSupplemental] = useState('');
  const [benefitSupplemental, setbenefitSupplemental] = useState('');
  const [selectionMethodSupplemental, setselectionMethodSupplemental] = useState('');
  const [workFromHomeId, setWorkFromHomeId] = useState<any>(null);
  const [HideAddressDetail, setHideAddressDetail] = useState(true);
  const [isContractPeriodShow, setIsContractPeriodShow] = useState(true);
  const [isContractPeriodExistsShow, setIsContractPeriodExistsShow] = useState<any>(false);
  const [areaText, setAreaText] = useState('');
  const [prefectureText, setPrefectureText] = useState('');
  const [cityText, setCityText] = useState('');
  const [postCode, setPostCode] = useState('');
  const [locationAddress, setLocationAddress] = useState('');
  const [locationRegionId, setLocationRegionId] = useState(null);
  const [locationPrefectureId, setLocationPrefectureId] = useState(null);
  const [locationCityId, setLocationCityId] = useState(null);
  const [btn, setBtn] = useState(false);
  const [prefecture_name, setPrefectureName] = useState(String);
  const [disabled_address, setDisabledAddress] = useState(String);
  const [city_name, setCityName] = useState(String);
  const [isEditCityCorrection, setisEditCityCorrection] = useState<any>(false);
  const [cityCorrection, setCityCorrection] = useState('');
  const [isTrialPeriodExistsShow, setisTrialPeriodExistsShow] = useState<any>(false);
  const [address, setAddress] = useState(String);
  const [locationNearestStation, setLocationNearestStation] = useState('');
  const [locationTransportation, setLocationTransportation] = useState('');
  const [nationwide, setNationwide] = useState(false);
  const [startTime1Hour, setStartTime1Hour] = useState(null);
  const [startTime1Minute, setStartTime1Minute] = useState(null);
  const [startTime2Hour, setStartTime2Hour] = useState(null);
  const [startTime2Minute, setStartTime2Minute] = useState(null);
  const [startTime3Hour, setStartTime3Hour] = useState(null);
  const [startTime3Minute, setStartTime3Minute] = useState(null);
  const [endTime1Hour, setEndTime1Hour] = useState(null);
  const [endTime1Minute, setEndTime1Minute] = useState(null);
  const [endTime2Hour, setEndTime2Hour] = useState(null);
  const [endTime2Minute, setEndTime2Minute] = useState(null);
  const [endTime3Hour, setEndTime3Hour] = useState(null);
  const [endTime3Minute, setEndTime3Minute] = useState(null);
  const [startTime1, setStartTime1] = useState<any>(null);
  const [startTime2, setStartTime2] = useState<any>(null);
  const [startTime3, setStartTime3] = useState<any>(null);
  const [endTime1, setEndTime1] = useState<any>(null);
  const [endTime2, setEndTime2] = useState<any>(null);
  const [endTime3, setEndTime3] = useState<any>(null);
  const [workingDays, setWorkingDays] = useState<any[]>([1, 2, 3, 4, 5, 6]);
  const [contractRenewalExists, setContractRenewalExists] = useState<any>(null);
  const [fullTimeEmployeeExists, setFullTimeEmployeeExists] = useState<any>(null);
  const [selectedTrialPeriodId, setSelectedTrialPeriodId] = useState(null);
  const [overtimeHoursExists, setOvertimeHoursExists] = useState<any>(null);
  const [selectedVacationIds, setSelectedVacationIds] = useState<any>([]);
  const [annualHolidayCount, setAnnualHolidayCount] = useState<number>();
  const [annualNumberOfBonusExists, setAnnualNumberOfBonusExists] = useState<any>(false);
  const [annualNumberOfBonus, setAnnualNumberOfBonus] = useState(null);
  const [annualNumberOfRaisesExists, setAnnualNumberOfRaisesExists] = useState<any>(null);
  const [annualNumberOfRaises, setAnnualNumberOfRaises] = useState(null);
  const [upperEstimatedAnnualIncome, setUpperEstimatedAnnualIncome] = useState(null);
  const [retirementAge, setRetirementAge] = useState('');
  const [selectedJobCategoryId, setSelectedJobCategoryId] = useState<any>(null);
  const [selectedJobTypeId, setSelectedJobTypeId] = useState<any>(null);
  const [typeOfRecruitingCompanyId, setTypeOfRecruitingCompanyId] = useState<any>(null);
  const [selectedEmploymentStatusId, setSelectedEmploymentStatusId] = useState('');
  const [selectedHolidayIds, setSelectedHolidayIds] = useState<any[]>([]);
  const [selectedSelectionMethodIds, setSelectedSelectionMethodIds] = useState<any[]>([]);
  const [selectedSocialInsuranceIds, setSelectedSocialInsuranceIds] = useState<any[]>([]);    
  const [selectedContractPeriodId, setSelectedContractPeriodId] = useState<any>(null);
  const [loadScreen, setLoadScreen] = useState(true);
  const [isData, setIsData] = useState(false);
  const [isStartMinute1Disable, setIsStartMinute1Disable] = useState(true);
  const [isStartMinute2Disable, setIsStartMinute2Disable] = useState(true);
  const [isStartMinute3Disable, setIsStartMinute3Disable] = useState(true);
  const [isEndMinute1Disable, setIsEndMinute1Disable] = useState(true);
  const [isEndMinute2Disable, setIsEndMinute2Disable] = useState(true);
  const [isEndMinute3Disable, setIsEndMinute3Disable] = useState(true); 
  const location = useLocation();
  const data = location.state;
  const [isReload, setIsReload] = useState<any>(false);
  const [checkCopyOnCompanyAddress, setCheckCopyOnCompanyAddress] = useState<any>(false);
  const [showSubNew1, setShowSubNew1] = useState(false);
  const [showSubNew2, setShowSubNew2] = useState(false);

  const [showSubInputNew1, setShowInputSubNew1] = useState(false);
  const [showSubInputNew2, setShowInputSubNew2] = useState(false);
  const [showSubInputNew3, setShowInputSubNew3] = useState(false);

  //step2
  const [graduateError, setGraduateError] = useState(false);
  const [new_graduate, setNew_graduate] = useState(null); 
  const [recruitmentscheduleid, setrecruitmentscheduleid] = useState(null);
  const [schedules, setschedules] = useState<any[]>([]);
  const [region, setregions] = useState<any[]>([]);
  const [MySelectRegions, setMySelectRegions] = useState<any[]>([]);
  const [MySelectRegions1, setMySelectRegions1] = useState<any[]>([]);
  const [prefectures, setprefectures] = useState<any[]>([]);
  const [mySelectedPrefectures, setMySelectedPrefectures] = useState<any[]>([]); 
  const [handicaptypes, sethandicaptypes] = useState<any[]>([]);
  const [selectHandicapTypesIds, setSelectHandicapTypesIds] = useState<number[]>([]); 
  const [appealitems, setappealitems] = useState<any[]>([]);
  const [appealitems1, setappealitems1] = useState<any>(null);
  const [appealitems2, setappealitems2] = useState<any>(null);
  const [appealitems3, setappealitems3] = useState<any>(null);
  const [postCodeDataChk, setPostCodeDataChk] = useState(false);

  let notEqualList: any[] = [];
  //step3 
  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [show, setShow] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [showImage, setShowImage] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);
  //step3
  const appeal_recruiter_items = [
    "社風:職場の雰囲気",
    "1日の業務の流れ",
    "先輩社員からのコメント",
    "キャリアパス",
    "現場での合理的配慮例",
    "採用担当メッセージ",
    "アピール動画",
  ];
  const [edited, setEdited] = useState(false);
  const [selectedAppeal, setSelectedAppeal] = useState<any>([]);
  const [appeal_recruiter_items_corporate_culture, setCorporateCulture] =
    useState("");
  const [appeal_recruiter_items_business_flow, setBusinessFlow] = useState("");
  const [appeal_recruiter_items_superior_message, setSuperiorMessage] =
    useState("");
  const [appeal_recruiter_items_career_path, setCareerPath] = useState("");
  const [
    appeal_recruiter_items_consideration_condition,
    setConsiderationCondition,
  ] = useState("");
  const [appeal_recruiter_items_hr_message, setHrMessage] = useState("");
  let txt1 =
    "【以下定型文】\n私たちの職場は〇〇な人が多く、職場の雰囲気を一言でいうと〇〇です。\n例えば、こんなイベント・制度があります。\n会社としても〇〇を大切にしています。";
  let txt2 =
    "【以下定型文】\n【出社後】\nまず、〇〇を行います。\n〈1日の主な業務〉・・・\n【休憩】\n〇〇:〇〇〜〇〇:〇〇の間が多いです。\n【退勤前】\n終業前は〇〇を行い、業務終了です。";
  let txt3 =
    "【以下定型文】\n例：\n「弊社は〇〇な会社で、〇〇なところは大変ですが、〇〇なところがやりがいです。\n私はこの業務に携わっていから〇〇のスキルを身につけることができました。\n〇〇な人とぜひ働きたいので、ご応募お待ちしています。」\n（Aさん/入社〇〇年目・〇〇職）";
  let txt4 =
    "【以下定型文】\n入社当初は主に〇〇の業務を行っていただきます。\nその後、職場の状況や適正によって〇〇の仕事を徐々にお任せしていきます。\n評価面接等は年○回です。";
  let txt5 =
    "【以下定型文】\n例：\n弊社では〇〇に障がいがある方には、「」のような配慮や\n現場でのサポートが可能です。";
  let txt6 = "【以下定型文】\n例：";
  const [isApiLoadingCorporateCulture, setIsApiLoadingCorporateCulture] =
    useState(false);
    const [movie_path, setMoviePath] = useState("");
    const [movie_path2, setMoviePath2] = useState("");
    const [isEditMoviePath, setIsEditMoviePath] = useState(false);
    const [idealCandidatesUnique, setIdealCandidatesUnique] = useState<any>([]);
    const [considerationConditionTypes, setConsiderationConditionTypes] =
    useState<any[]>([]);
    const [isOpenList, setIsOpenList] = useState<boolean[]>([]);
    const [considerationConditions, setConsiderationConditions] = useState<any[]>(
        []
      );
      const [
        selectedConsiderationConditionsId,
        setSelectedConsiderationConditionsId,
      ] = useState<any[]>([]);
      const [selectedConsiderationConditions, setSelectedConsiderationConditions] =
      useState<any[]>([]);
      const [workEnvironments, setWorkEnvironments] = useState<any[]>([]);
      const [recruitmentWorkEnvironments, setRecruitmentWorkEnvironments] =
      useState<any[]>([]);
    const [recruitmentWorkEnvironmentsText, setRecruitmentWorkEnvironmentsText] =
      useState<any[]>([]);
      const [environmentsText, setEnvironmentsText] = useState("");
      const [previewDisplayTexts, setPreviewDisplayTexts] = useState<any[]>([]);

      const [isErrorPreviewDisplayText0, setIsErrorPreviewDisplayText0] =
      useState(false);
    const [isErrorPreviewDisplayText1, setIsErrorPreviewDisplayText1] =
      useState(false);
    const [isErrorPreviewDisplayText2, setIsErrorPreviewDisplayText2] =
      useState(false);
    const [isErrorPreviewDisplayText3, setIsErrorPreviewDisplayText3] =
      useState(false);

      const [previewDisplayText0MaxLength, setPreviewDisplayText0MaxLength] =
      useState(false);
    const [previewDisplayText1MaxLength, setPreviewDisplayText1MaxLength] =
      useState(false);
    const [previewDisplayText2MaxLength, setPreviewDisplayText2MaxLength] =
      useState(false);
    const [previewDisplayText3MaxLength, setPreviewDisplayText3MaxLength] =
      useState(false);
      const [previewDisplayText0MaxLenError, setPreviewDisplayText0MaxLenError] =
      useState(null);
    const [previewDisplayText1MaxLenError, setPreviewDisplayText1MaxLenError] =
      useState(null);
    const [previewDisplayText2MaxLenError, setPreviewDisplayText2MaxLenError] =
      useState(null);
    const [previewDisplayText3MaxLenError, setPreviewDisplayText3MaxLenError] =
      useState(null);
      const [previewDisplayText0, setPreviewDisplayText0] = useState<any>(null);
      const [previewDisplayText1, setPreviewDisplayText1] = useState<any>(null);
      const [previewDisplayText2, setPreviewDisplayText2] = useState<any>(null);
      const [previewDisplayText3, setPreviewDisplayText3] = useState<any>(null);
      const [images, setImages] = useState<any[]>([]);
      const imagesAdvices = [
        "例1）採用ターゲットに近い社員の写真",
        "例2）会社や店舗の雰囲気が伝わる写真",
        "例3）自社のサービスや商品の画像",
        "例4）一緒に働く社員の顔写真",
        "例5）社員旅行・社内イベントなどの写真",
      ];
      const [recruitmentCompatibilities, setRecruitmentCompatibilities] = useState<any[]>([]);
      let previewDisplayTextsMaxLen = 20;
      const [chkPost, setChkPost] =useState(false);
      const { id } = useParams();

  useEffect(() => {
    dispatch(fetchAdminOpenJobRegionsRequest());
      if (localStorage.getItem('jobParachannelAuthAdminToken')) {
        dispatch(fetchAdminCompanyAllListRequest());
        dispatch(fetchAdminRecruitmentEditRequest(id));
        setLoadScreen(false);
      } 
  }, [dispatch]); 

  useEffect(() => {
      if(adminOpenJobRegions !== undefined && adminOpenJobRegions.pending) {
          setConsiderationConditions(adminOpenJobRegions.regions.consideration_conditions);
          setWorkEnvironments(adminOpenJobRegions.regions.work_environments);
          setConsiderationConditionTypes( adminOpenJobRegions.regions.consideration_condition_types);
          setIdealCandidatesUnique(adminOpenJobRegions.regions.ideal_candidates_unique);
          setappealitems(adminOpenJobRegions.regions.appeal_items);
          setprefectures(adminOpenJobRegions.regions.prefectures);
          setschedules(adminOpenJobRegions.regions.schedules);
          setregions(adminOpenJobRegions.regions.regions);
          sethandicaptypes(adminOpenJobRegions.regions.handicap_types);
          setMaster(adminOpenJobRegions);            
          setjobCategoryTypes(adminOpenJobRegions.regions.job_category_types);
          setemploymentStatuses(adminOpenJobRegions.regions.employment_statuses);
          settypeOfRecruitingCompanies(adminOpenJobRegions.regions.type_of_recruiting_companies);
          setcontractPeriods(adminOpenJobRegions.regions.contract_periods);
          setholidays(adminOpenJobRegions.regions.holidays);
          setsalaryForms(adminOpenJobRegions.regions.salary_forms);
          setAllowances(adminOpenJobRegions.regions.allowances);
          setvacations(adminOpenJobRegions.regions.vacations);
          setsocialInsurances(adminOpenJobRegions.regions.social_insurances);
          setselectionMethods(adminOpenJobRegions.regions.selection_methods);
          setwork_from_home(adminOpenJobRegions.regions.work_from_home);
          settrialPeriods(adminOpenJobRegions.regions.trial_periods);
        //   setLoadScreen(false);
      }  
  },[adminOpenJobRegions, master]);

  

  

  useEffect(() => {
    if(adminRecruitmentEdit.pending && adminRecruitmentEdit) {
        setSelectedCompany(adminRecruitmentEdit.recruitments.company_id);
        setOfficeNumber(adminRecruitmentEdit.recruitments.office_number);
        setJobTitle(adminRecruitmentEdit.recruitments.job_title);
        // setSelectedJobTypeId(adminRecruitmentEdit.recruitments.job_category_type_id);
        if(adminRecruitmentEdit.recruitments.job_category_id && adminOpenJobRegions.regions.job_categories){
            for(let i = 0; i< adminOpenJobRegions.regions.job_categories.length; i++) {
                if(adminOpenJobRegions.regions.job_categories[i].id === adminRecruitmentEdit.recruitments.job_category_id){
                    setSelectedJobTypeId(adminOpenJobRegions.regions.job_categories[i].job_category_type_id);
                    setjobCategories(adminOpenJobRegions.regions.job_categories.filter((category: any) => category.job_category_type_id == adminOpenJobRegions.regions.job_categories[i].job_category_type_id));
                    setSelectedJobCategoryId( adminRecruitmentEdit.recruitments.job_category_id);
                }
            }
        }
        setTypeOfRecruitingCompanyId(adminRecruitmentEdit.recruitments.type_of_recruiting_company_id);
        setSelectedEmploymentStatusId(adminRecruitmentEdit.recruitments.employment_status_id);
        setBusinessContent(adminRecruitmentEdit.recruitments.business_content);
        setRequiredExperience(adminRecruitmentEdit.recruitments.required_experience);
        setWorkFromHomeId(adminRecruitmentEdit.recruitments.work_location.work_from_home_id);
        setLocationDepartment(adminRecruitmentEdit.recruitments.work_location.department);

        if(!postCodeDataChk){
            setPostCode(adminRecruitmentEdit.recruitments.work_location.postcode);
            let textAreaKeyUp = adminRecruitmentEdit.recruitments.work_location.prefecture_name + adminRecruitmentEdit.recruitments.work_location.city_name;
            if(textAreaKeyUp) {
                setAreaText(textAreaKeyUp);
            }
            
    
            setLocationRegionId(adminRecruitmentEdit.recruitments.work_location.region_id);
            // setPrefectureText(postcodeData.postcode?.prefecture?.name);
            setLocationPrefectureId(adminRecruitmentEdit.recruitments.work_location.prefecture_id);          
            // setCityText(postcodeData.postcode?.city?.name);
            setLocationCityId(adminRecruitmentEdit.recruitments.work_location.city_id);
            setLocationAddress(adminRecruitmentEdit.recruitments.work_location.address);
            setLocationNearestStation(adminRecruitmentEdit.recruitments.work_location.nearest_station);
            setLocationTransportation(adminRecruitmentEdit.recruitments.work_location.transportation);
        }
       
        setNationwide(adminRecruitmentEdit.recruitments.work_location.nationwide);
        setlocationWorkLocationSupplemental(adminRecruitmentEdit.recruitments.work_location.work_location_supplemental);
        setIsContractPeriodExistsShow(adminRecruitmentEdit.recruitments.period.contract_period_exists);
        setSelectedContractPeriodId(adminRecruitmentEdit.recruitments.period.contract_period_id);
        setContractRenewalExists(adminRecruitmentEdit.recruitments.period.contract_renewal_exists);
        setisTrialPeriodExistsShow(adminRecruitmentEdit.recruitments.period.trial_period_exists);
        setSelectedTrialPeriodId(adminRecruitmentEdit.recruitments.period.trial_period_id);
        settrialPeriodSupplemental(adminRecruitmentEdit.recruitments.period.trial_period_supplemental);
        setFullTimeEmployeeExists(adminRecruitmentEdit.recruitments.period.full_time_employee_exists);
        setcontractPeriodSupplemental(adminRecruitmentEdit.recruitments.period.contract_period_supplemental);
        if( (adminRecruitmentEdit.recruitments.work_time.start_time1 !== '' && adminRecruitmentEdit.recruitments.work_time.start_time1 !== null) && (adminRecruitmentEdit.recruitments.work_time.end_time1 !== '' && adminRecruitmentEdit.recruitments.work_time.end_time1 !== null)){
           
           
            let arrStart = adminRecruitmentEdit.recruitments.work_time.start_time1.split(':');
            setStartTime1Hour(arrStart[0]);
            setStartTime1Minute(arrStart[1]);
            setStartTime1(adminRecruitmentEdit.recruitments.work_time.start_time1);
            setIsStartMinute1Disable(false);
            let arrEnd = adminRecruitmentEdit.recruitments.work_time.end_time1.split(':');
            setEndTime1Hour(arrEnd[0]);
            setEndTime1Minute(arrEnd[1]);
            setIsEndMinute1Disable(false);
            setEndTime1(adminRecruitmentEdit.recruitments.work_time.end_time1);
        }
        if( adminRecruitmentEdit.recruitments.work_time.start_time2 !== '' && adminRecruitmentEdit.recruitments.work_time.end_time2 !== ''){
            setShowSubNew1(true);
            let arrStart = adminRecruitmentEdit.recruitments.work_time.start_time2.split(':');
            setStartTime2Hour(arrStart[0]);
            setStartTime2Minute(arrStart[1]);
            setIsStartMinute2Disable(false);
            setStartTime2(adminRecruitmentEdit.recruitments.work_time.start_time2);
            let arrEnd = adminRecruitmentEdit.recruitments.work_time.end_time2.split(':');
            setEndTime2Hour(arrEnd[0]);
            setEndTime2Minute(arrEnd[1]);
            setIsEndMinute2Disable(false);
            setEndTime2(adminRecruitmentEdit.recruitments.work_time.end_time2);
        }
        if( adminRecruitmentEdit.recruitments.work_time.start_time3 !== '' && adminRecruitmentEdit.recruitments.work_time.end_time3 !== ''){
            setShowSubNew2(true);
            let arrStart = adminRecruitmentEdit.recruitments.work_time.start_time3.split(':');
            setStartTime3Hour(arrStart[0]);
            setStartTime3Minute(arrStart[1]);
            setIsStartMinute3Disable(false);
            setStartTime3(adminRecruitmentEdit.recruitments.work_time.start_time3);
            let arrEnd = adminRecruitmentEdit.recruitments.work_time.end_time3.split(':');
            setEndTime3Hour(arrEnd[0]);
            setEndTime3Minute(arrEnd[1]);
            setIsEndMinute3Disable(false);
            setEndTime3(adminRecruitmentEdit.recruitments.work_time.end_time3);
        }
        setworkTimeSupplemental(adminRecruitmentEdit.recruitments.work_time.work_time_supplemental);
        setRestTime(adminRecruitmentEdit.recruitments.work_time.rest_time)
        setrestTimeSupplemental(adminRecruitmentEdit.recruitments.work_time.rest_time_supplemental);
        setNumberOfWorkingDays(adminRecruitmentEdit.recruitments.work_time.number_of_working_days);
        setnumberOfWorkingDaysSupplemental(adminRecruitmentEdit.recruitments.work_time.number_of_working_days_supplemental);
        setOvertimeHoursExists(adminRecruitmentEdit.recruitments.work_time.overtime_hours_exists);
        setovertimeHoursSupplemental(adminRecruitmentEdit.recruitments.work_time.overtime_hours_supplemental);
        setSelectedHolidayIds(adminRecruitmentEdit.recruitments.holidays);
        setAnnualHolidayCount(adminRecruitmentEdit.recruitments.work_time.annual_holiday);
        setSelectedVacationIds(adminRecruitmentEdit.recruitments.vacations);
        setholidaysSupplemental(adminRecruitmentEdit.recruitments.work_time.holidays_supplemental);
        setSelectedSalaryFormId(adminRecruitmentEdit.recruitments.salary.salary_form_id);
        setLowerMonthlyBasicSalary(adminRecruitmentEdit.recruitments.salary.lower_monthly_basic_salary); 
        setUpperMonthlyBasicSalary(adminRecruitmentEdit.recruitments.salary.upper_monthly_basic_salary);
        setSelectedAllowanceIds(adminRecruitmentEdit.recruitments.allowances);
        setallowancesSupplemental(adminRecruitmentEdit.recruitments.salary.allowances_supplemental);
        setAnnualNumberOfBonusExists(adminRecruitmentEdit.recruitments.salary.annual_number_of_bonus_exists);
        setAnnualNumberOfBonus(adminRecruitmentEdit.recruitments.salary.annual_number_of_bonus);
        setAnnualNumberOfRaisesExists(adminRecruitmentEdit.recruitments.salary.annual_number_of_raises_exists);
        setAnnualNumberOfRaises(adminRecruitmentEdit.recruitments.salary.annual_number_of_raises);
        setUpperEstimatedAnnualIncome(adminRecruitmentEdit.recruitments.salary.upper_estimated_annual_income);
        setLowerEstimatedAnnualIncome(adminRecruitmentEdit.recruitments.salary.lower_estimated_annual_income);
        setSelectedSocialInsuranceIds(adminRecruitmentEdit.recruitments.social_insurances);
        setRetirementMoneyExists(adminRecruitmentEdit.recruitments.retirement_money_exists);
        setRetirementAge(adminRecruitmentEdit.recruitments.retirement_age);
        setbenefitSupplemental(adminRecruitmentEdit.recruitments.benefit_supplemental);
        setSelectedSelectionMethodIds(adminRecruitmentEdit.recruitments.selection_methods);
        setNumberOfEmployees(adminRecruitmentEdit.recruitments.target.number_of_employees);
        setselectionMethodSupplemental(adminRecruitmentEdit.recruitments.selection_method_supplemental);
        setNew_graduate(adminRecruitmentEdit.recruitments.new_graduate);
        setrecruitmentscheduleid(adminRecruitmentEdit.recruitments.target.schedule_id);
        let regionList: any = [];
        let preList: any = [];
        for (let i = 0; i < prefectures.length; i++) {
            prefectures[i].checked = false;
        }

        if (adminRecruitmentEdit.recruitments.prefectures.length !== 0 && prefectures.length !== 0) {
            for (let i = 0; i < prefectures.length; i++) {
                for (let j = 0; j < adminRecruitmentEdit.recruitments.prefectures.length; j++) {
                    if (prefectures[i].id == adminRecruitmentEdit.recruitments.prefectures[j]) {
                        prefectures[i].checked = true; 
                        if (!regionList.includes(prefectures[i].region_id)) {
                            regionList.push(prefectures[i].region_id);
                        }
                        preList.push(prefectures[i]);
                    }
                }
            }
            setMySelectRegions(regionList);
            setMySelectedPrefectures(preList);

        } else {
            setMySelectRegions([]);
            setMySelectedPrefectures([]);
        }
        setSelectHandicapTypesIds(adminRecruitmentEdit.recruitments.handicap_types);
        setappealitems1(adminRecruitmentEdit.recruitments.appeal_item.appeal_item_1.id);
        setappealitems2(adminRecruitmentEdit.recruitments.appeal_item.appeal_item_2.id);
        setappealitems3(adminRecruitmentEdit.recruitments.appeal_item.appeal_item_3.id);
        for(let i = 0; i < show.length; i++){
            if(adminRecruitmentEdit.recruitments.corporate_culture_text){
                show[0] = true;
                setCorporateCulture(adminRecruitmentEdit.recruitments.corporate_culture_text);
            }
            if(adminRecruitmentEdit.recruitments.business_flow){
                setBusinessFlow(adminRecruitmentEdit.recruitments.business_flow);
                show[1] = true;
            }
            if(adminRecruitmentEdit.recruitments.superior_message) {
                setSuperiorMessage(adminRecruitmentEdit.recruitments.superior_message)
                show[2] = true;
            }
            if(adminRecruitmentEdit.recruitments.career_path){
                setCareerPath(adminRecruitmentEdit.recruitments.career_path);
                show[3] = true;
            }
            if(adminRecruitmentEdit.recruitments.consideration_condition_text){
                setConsiderationCondition(adminRecruitmentEdit.recruitments.consideration_condition_text);
                show[4] = true;
            }
            if(adminRecruitmentEdit.recruitments.hr_message){
                setHrMessage(adminRecruitmentEdit.recruitments.hr_message);
                show[5] = true;
            }
            if(adminRecruitmentEdit.recruitments.movie_path){
                setMoviePath2(adminRecruitmentEdit.recruitments.movie_path);
                show[6] = true;
            }
        }
        setRecruitmentCompatibilities(adminRecruitmentEdit.recruitments.compatibilities);
        if(adminRecruitmentEdit.recruitments.consideration_conditions){
            setSelectedConsiderationConditionsId(adminRecruitmentEdit.recruitments.consideration_conditions);
            for(let i = 0; i < adminRecruitmentEdit.recruitments.consideration_conditions.length; i++){

                if( adminRecruitmentEdit.recruitments.consideration_conditions[i] < 11){
                    isOpenList[0] = true
                }
                 if (adminRecruitmentEdit.recruitments.consideration_conditions[i] > 11 && adminRecruitmentEdit.recruitments.consideration_conditions[i] < 34){
                    isOpenList[1] = true
                }
                if(adminRecruitmentEdit.recruitments.consideration_conditions[i] > 33) {
                    isOpenList[2] = true
                }
            }
        }
        
        if(considerationConditions && adminRecruitmentEdit.recruitments.consideration_conditions){
            for(let j = 0 ; j < adminRecruitmentEdit.recruitments.consideration_conditions.length; j++){
                for(let i = 0; i < considerationConditions.length; i++) {
                    if(considerationConditions[i].id == adminRecruitmentEdit.recruitments.consideration_conditions[i]){
                        if(considerationConditions[i].consideration_condition_type_id == 1){
                            isOpenList[0] = true;
                        }
                        if(considerationConditions[i].consideration_condition_type_id == 2){
                            isOpenList[1] = true;

                        }
                        if(considerationConditions[i].consideration_condition_type_id == 3){
                            isOpenList[2] = true;
                        }
                    }
                }
            }
        }
        setRecruitmentWorkEnvironments(adminRecruitmentEdit.recruitments.work_environments);
        setRecruitmentWorkEnvironmentsText(adminRecruitmentEdit.recruitments.work_environment_text);
        if(adminRecruitmentEdit.recruitments.preview_display_texts){
            setPreviewDisplayTexts(adminRecruitmentEdit.recruitments.preview_display_texts);
            for(let i = 0; i < adminRecruitmentEdit.recruitments.preview_display_texts.length; i++){
                if(i == 1){
                    setShowInputSubNew1(true);
                }
                if(i == 2){
                    setShowInputSubNew2(true);
                }
                if(i == 3){
                    setShowInputSubNew3(true);
                }
            }
        }
        // setImages(adminRecruitmentEdit.recruitments.images);
        let tmpimg:any =[]
        if(adminRecruitmentEdit.recruitments.images.length !== 0){
            // setImages(adminRecruitmentEdit.recruitments.images);
            for(let i = 0; i < adminRecruitmentEdit.recruitments.images.length; i++){
                tmpimg[i] =
                    {
                        url:adminRecruitmentEdit.recruitments.images[i].url,
                        image:adminRecruitmentEdit.recruitments.images[i].url,
                        comment:adminRecruitmentEdit.recruitments.images[i].comment,
                        priority:adminRecruitmentEdit.recruitments.images[i].priority,
                    }
                
                if(i == 1){
                    if(adminRecruitmentEdit.recruitments.images[i].url !== ''){
                        showImage[1] = true;
                    }
                }
                if(i == 2){
                    if(adminRecruitmentEdit.recruitments.images[i].url !== ''){
                        showImage[2] = true;
                    }

                }
                if(i == 3){
                    if(adminRecruitmentEdit.recruitments.images[i].url !== ''){
                        showImage[3] = true;
                    }
                }
                if(i == 4){
                    if(adminRecruitmentEdit.recruitments.images[i].url !== ''){
                        showImage[4] = true;
                    }
                }
                if(i == 5){
                    if(adminRecruitmentEdit.recruitments.images[i].url !== ''){
                        showImage[5] = true;
                    }
                }
            }
            setImages(tmpimg);
        } else {
            const value = [];
            for (let i = 0; i < 5; i++) {
              const image = { priority: i + 1, comment: null ,image:null};
              value.push({ ...image });
            }
            setImages(value);
    
        }
        // setLoadScreen(false);
        

    }
  }, [adminRecruitmentEdit]);


  useEffect(() => {
    if(adminRecruitmentPost.pending && chkPost) {
        // navigate('/admin/openjob_list/' + id)
        window.location.href = '/admin/openjob_list/' + id;
    }
  }, [adminRecruitmentPost,chkPost]);


  useEffect(() => {
    if(adminComapnyAllList.pending) {
      setCompanyList(adminComapnyAllList.company);
    //   setLoadScreen(false);
    }
  }, [adminComapnyAllList,companyList]);



    useEffect(() => {
        if(postCodeDataChk){
            if (postcodeData.pending && Object.keys(postcodeData).length !== 0) {
                setLocationRegionId(postcodeData.postcode?.region?.id);
                setPrefectureText(postcodeData.postcode?.prefecture?.name);
                setLocationPrefectureId(postcodeData.postcode?.prefecture?.id);          
                setCityText(postcodeData.postcode?.city?.name);
                setLocationCityId(postcodeData.postcode?.city?.id);
                let textAreaKeyUp = postcodeData.postcode?.prefecture?.name + postcodeData.postcode?.city?.name;
                if(textAreaKeyUp) {
                    setAreaText(textAreaKeyUp);
                }
            } else {
              setLocationRegionId(null);
              setLocationPrefectureId(null)
              setAreaText('');
              setPrefectureText('');
              setCityText('');
              setLocationCityId(null)
          } 
        }
        
  }, [postcodeData, postCodeDataChk]);

  function handleChangetwo(event: any) {
      setBusinessContent(event);
  }

  function handleChangethree(event: any){
      setRequiredExperience(event);
  }

  function inputLocationDepartment(event: any) {
      setLocationDepartment(event);
  }

  function handleChangeAddress(event: any) {
      setlocationWorkLocationSupplemental(event);
  }

  function handleChangeContract(event: any) {
      setcontractPeriodSupplemental(event);
  }

  function handleChangetrial(event: any) {
      settrialPeriodSupplemental(event);
  }

  const handleChangework = (event: any) => {
      setworkTimeSupplemental(event.target.value);
  }

  const handleChangerest = (event: any) => {
      setrestTimeSupplemental(event.target.value);
  }

  const handleChangenumber = (event: any) => {
      setnumberOfWorkingDaysSupplemental(event.target.value);
  }

  const handleChangeovertime = (event: any) => {
      setovertimeHoursSupplemental(event.target.value);
  }

  const handleChangeholiday = (event: any) => {
      setholidaysSupplemental(event.target.value);
  }

  const handleChangeallowances = (event: any) => {
      setallowancesSupplemental(event.target.value);
  }

  const handleChangebenefit = (event: any) => {
      setbenefitSupplemental(event.target.value);
  }

  const handleChangeselection = (event: any) => {
      setselectionMethodSupplemental(event.target.value);
  }

  function handler_nationwide(e: any) {
      setWorkFromHomeId(e);
      if(parseInt(e) === 1) {
          setHideAddressDetail(false);
          setCheckCopyOnCompanyAddress(false);
          // if(companies.company !== undefined) {
          //     company = companies.company;
          //     setAreaText(company.prefecture.name + company.city.name);
          //     setPrefectureText(company.prefecture.name);
          //     setCityText(company.city.name);
          //     setPostCode(company.postcode);
          //     setLocationAddress(company.address);
          //     setLocationRegionId(company.prefecture.region_id);
          //     setLocationPrefectureId(company.prefecture.id);
          //     setLocationCityId(company.city.id);
              
          // }            
      } else {
          setHideAddressDetail(true);
        //   setCheckCopyOnCompanyAddress(false);
        //   setAreaText('');
        //   setPostCode('');
        //   setLocationAddress('');
        //   setLocationRegionId(null);
        //   setLocationPrefectureId(null);
        //   setLocationCityId(null);
      }
  }

  function handler_contractPeriodShow(e: any) {
      if(parseInt(e) === 1) {
          setIsContractPeriodShow(false);    
      } else {
          setIsContractPeriodShow(true);     
      }
      setSelectedEmploymentStatusId(e);
  }
  
  function handler_isContractPeriodExistsShow(e: any) {
      if(e.target.value === "true") {
          setIsContractPeriodExistsShow(true);    
      } else {
          setIsContractPeriodExistsShow(false);     
      }
  }

  function handler_isTrialPeriodExistsShow(e : any) {
      if(e.target.value === "true"){
          setisTrialPeriodExistsShow(true);
      } else{
          setisTrialPeriodExistsShow(false);
      }
  };

  const hourList = Array.from(Array(24), (_, i) => ('00' + i).slice(-2));
  const minuteList = Array.from(Array(12), (_, i) => ('00' + (i * 5)).slice(-2));

  function onChangeJobType(value: any) {
      setSelectedJobTypeId(value);
      let tmp = adminOpenJobRegions.regions.job_categories.filter((category: any) => category.job_category_type_id == value);
      setjobCategories(adminOpenJobRegions.regions.job_categories.filter((category: any) => category.job_category_type_id == value));
      setSelectedJobCategoryId(tmp[0].id);
  }
  function onChangeJobCategories(value: any) {
      setSelectedJobCategoryId(value);
  } 

  function onChangeRecCompany(value: any) {
      setTypeOfRecruitingCompanyId(parseInt(value));
  }

  function validates() {
      let result = ''
      if(selectedCompany == "") {
        result += '企業\n'
      }        
      if (jobTitle == '') {
          result += '職業名\n'
      }
      if (selectedJobTypeId == null ||  selectedJobCategoryId == null) {
          result += '職種\n'
      }

      if (typeOfRecruitingCompanyId == null) {
          result += '募集する企業の区分\n'
      }

    //   if (companyName == '') {
    //       result += '会社名\n'
    //   }

    //   if (selectedEmploymentStatusId == '') {
    //       result += '雇用形態\n'
    //   }

      if (businessContent == '') {
          result += '業務内容\n'
      }

      if (workFromHomeId == null) {
        result += '働き方・テレワーク\n'
      }

      if (postCode == '' && locationAddress == '' || locationPrefectureId == null && locationCityId == null) { //住所            
          result += '住所\n'
      }
    //   console.log(selectedEmploymentStatusId);
    //   if (selectedEmploymentStatusId != '1' && selectedEmploymentStatusId === null) {
    //     result += '契約期間有無\n'
    //   }
      if(isContractPeriodShow) {
          if (isContractPeriodExistsShow === null) {
              result += '契約期間有無\n'
          }
      }        
      if (isTrialPeriodExistsShow === null) {
          result += '試用期間有無\n'
      }
      if (startTime1Hour === null && startTime1Minute === null) {
          result += '始業時間①\n'
      }
      if (endTime1Hour === null && endTime1Minute === null) {
          result += '終業時間①\n'
      }
      if (restTime === null) {
          result += '休憩時間\n'
      }
      if (!numberOfWorkingDays) {
          result += '勤務日数/週\n'
      }
      if (selectedHolidayIds === undefined) {
          result += '休日\n'
      }
      if (selectedSalaryFormId === '') {
          result += '給与方式\n'
      }
      if (lowerMonthlyBasicSalary === null || upperMonthlyBasicSalary === null) {
          result += '基本給\n'
      }
      if (annualNumberOfBonusExists === null) {
          result += '賞与\n'
          //set
      }
      if (parseInt(selectedSalaryFormId) == 1 && (lowerEstimatedAnnualIncome === null || upperMonthlyBasicSalary === null)) {
          result += '想定年収\n'
      }
      if (annualNumberOfRaisesExists === null) {
          result += '昇給\n'
      }
      if (selectedSocialInsuranceIds === undefined || selectedSocialInsuranceIds == null) {
          result += '社会保険\n'
      }
      if (retirementMoneyExists === null) {
          result += '退職金制度\n'
      }
      if (selectedSelectionMethodIds.length == 0 || selectedSelectionMethodIds == null) {
          result += '選考方法\n'
      }
      if (numberOfEmployees === null) {
          result += '想定採用人数\n'
      }    
      if (numberOfEmployees == 0) {
          result += '想定採用人数\n'
      }
      if (new_graduate === null) {
        result += '希望の採用要件 採用種別\n'
      }
    //   if(previewDisplayText1 === null){
    //     result += 'previewDisplayText'
    //   }
    //   if (prefectureIds == '') {
    //   result += '希望の採用要件 地域\n'
    //   }
    //   if (handicaptypes == '') {
    //   result += '希望の採用要件 障がい区分\n'
    //   }
      if(selectHandicapTypesIds.length === 0) {
        result += '希望の採用要件 障がい区分\n'
      }
      if(recruitmentCompatibilities.length === 0){
        result += '求める人物像\n'
      }
      if (appealitems1 === null) {
        result += 'エントリー時に聞きたい内容 アピール項目１\n'
    
        }
        if (appealitems2 === null) {
        result += 'エントリー時に聞きたい内容 アピール項目２\n'
    
        }
        if (appealitems3  === null) {
        result += 'エントリー時に聞きたい内容 アピール項目３\n'
        }
      if(images[0].url === null || images[0].url === '') {
        result += 'image'
      }
      
      return result
  }
  function onChangeLocationAddress(e: any) {
      setLocationAddress(e.target.value)
  }

  function onChangeLocationNearestStation(e: any) {
      setLocationNearestStation(e.target.value)
  }

  function onChangeLocationTransportation(e: any) {
      setLocationTransportation(e.target.value)
  }

  function onChangeNationwide(e: any) {
      if(e.target.value === 'true') {
          setNationwide(true);
      } else {
          setNationwide(false);
      }
  }

  function onKeyUpPostCode() {
    //   if ( postCode !== 7 ) { return }
      dispatch(fetchPostCodeRequest(postCode));
      setPostCodeDataChk(true);
  }

  function handler_contractPeriods(e: any) {
      setSelectedContractPeriodId(e);
  }

  function OnChangeContractRenewalExists(e: any) {
      if(e.target.value === 'true') {
          setContractRenewalExists(true);
      } else {
          setContractRenewalExists(false);
      }
  } 

  function handler_trialPeriods(e: any) {
      if(e == '') {
          setSelectedTrialPeriodId(null);
      } else {
          setSelectedTrialPeriodId(e);
      }
  }
  
  function handler_fullTimeEmployeeExists(e: any) {
      if(e.target.value === 'true') {
          setFullTimeEmployeeExists(true);
      } else {
          setFullTimeEmployeeExists(false);
      }   
      
  } 

  function handler_overtimeHoursExists(e: any) {
      if(e.target.value === "true") {
          setOvertimeHoursExists(true);
      } else {
          setOvertimeHoursExists(false);
      }
      
  }

  function updateHour (v: any) {
      if("startTime1Hour" === v.target.name) {
          if (v.target.value == null || v.target.value == "") {
              setStartTime1Hour(null);
              setStartTime1Minute(null);
              setStartTime1(null)
          } else {
              setStartTime1Hour(v.target.value);
              setIsStartMinute1Disable(false);
              if(startTime1Minute !== null && startTime1Minute !== '') {
                  setStartTime1(v.target.value + ':' + startTime1Minute)
              } else {
                  setStartTime1(v.target.value + ':00');
              }
          }            
      }  
      
      if("startTime2Hour" === v.target.name) {
          if (v.target.value == null || v.target.value == "") {
              setStartTime2Hour(null);
              setStartTime2Minute(null);
              setStartTime2(null)
          } else {
              setStartTime2Hour(v.target.value);
              setIsStartMinute2Disable(false);
              if(startTime2Minute !== null && startTime2Minute !== '') {
                  setStartTime2(v.target.value + ':' + startTime2Minute)
              } else {
                  setStartTime2(v.target.value + ':00');
              }
          }            
      }

      if("startTime3Hour" === v.target.name) {
          if (v.target.value == null || v.target.value == "") {
              setStartTime3Hour(null);
              setStartTime3Minute(null);
              setStartTime3(null);
          } else {
              setStartTime3Hour(v.target.value);
              setIsStartMinute3Disable(false);
              if(startTime3Minute !== null && startTime3Minute !== '') {
                  setStartTime3(v.target.value + ':' + startTime3Minute)
              } else {
                  setStartTime3(v.target.value + ':00');
              }
          }            
      }

      if("endTime1Hour" === v.target.name) {
          if (v.target.value == null || v.target.value == "") {
              setEndTime1Hour(null);
              setEndTime1Minute(null);
              setEndTime1(null)
          } else {
              setEndTime1Hour(v.target.value);
              setIsEndMinute1Disable(false);
              if(endTime1Minute !== null && endTime1Minute !== '') {
                  setEndTime1(v.target.value + ':' + endTime1Minute)
              } else {
                  setEndTime1(v.target.value + ':00');
              }
          }            
      }  
      
      if("endTime2Hour" === v.target.name) {
          if (v.target.value == null || v.target.value == "") {
              setEndTime2Hour(null);
              setEndTime2Minute(null);
              setEndTime2(null)
          } else {
              setEndTime2Hour(v.target.value);
              setIsEndMinute2Disable(false);
              if(endTime2Minute !== null && endTime2Minute !== '') {
                  setEndTime2(v.target.value + ':' + endTime2Minute)
              } else {
                  setEndTime2(v.target.value + ':00');
              }
          }
      }

      if("endTime3Hour" === v.target.name) {
          if (v.target.value == null || v.target.value == "") {
              setEndTime3Hour(null);
              setEndTime3Minute(null);
              setEndTime3(null)
          } else {
              setEndTime3Hour(v.target.value);
              setIsEndMinute3Disable(false);
              if(endTime3Minute !== null && endTime3Minute !== '') {
                  setEndTime3(v.target.value + ':' + endTime3Minute)
              } else {
                  setEndTime3(v.target.value + ':00');
              }
          }            
      }
  }

  function updateMinute (v: any) {
      if("startTime1Minute" === v.target.name) {
          if (v.target.value == null || v.target.value == "" || startTime1Hour == null) {
              setStartTime1Hour(null);
              setStartTime1(null)
          } else {
              setStartTime1Minute(v.target.value);
              if(startTime1Hour !== null && startTime1Hour !== '') {
                  setStartTime1(startTime1Hour + ':' + v.target.value)
              }
          }            
      }

      if("startTime2Minute" === v.target.name) {
          if (v.target.value == null || v.target.value == "" || startTime2Hour == null) {
              setStartTime2Hour(null);
              setStartTime2(null)
          } else {
              setStartTime2Minute(v.target.value);
              if(startTime2Hour !== null && startTime2Hour !== '') {
                  setStartTime2(startTime2Hour + ':' + v.target.value)
              }
          }  
      }

      if("startTime3Minute" === v.target.name) {
          if (v.target.value == null || v.target.value == "" || startTime3Hour == null) {
              setStartTime3Hour(null);
              setStartTime3(null)
          } else {
              setStartTime3Minute(v.target.value);
              if(startTime3Hour !== null && startTime3Hour !== '') {
                  setStartTime3(startTime3Hour + ':' + v.target.value)
              }
          }  
      }

      if("endTime1Minute" === v.target.name) {
          if (v.target.value == null || v.target.value == "" || endTime1Hour == null) {
              setEndTime1Hour(null);
              setEndTime1(null)
          } else {
              setEndTime1Minute(v.target.value);
              if(endTime1Hour !== null && endTime1Hour !== '') {
                  setEndTime1(endTime1Hour + ':' + v.target.value)
              }
          }  
      }

      if("endTime2Minute" === v.target.name) {
          if (v.target.value == null || v.target.value == "" || endTime2Hour == null) {
              setEndTime2Hour(null);
              setEndTime2(null)
          } else {
              setEndTime2Minute(v.target.value);
              if(endTime2Hour !== null && endTime2Hour !== '') {
                  setEndTime2(endTime2Hour + ':' + v.target.value)
              }
          }  
      }

      if("endTime3Minute" === v.target.name) {
          if (v.target.value == null || v.target.value == "" || endTime3Hour == null) {
              setEndTime3Hour(null);
              setEndTime3(null)
          } else {
              setEndTime3Minute(v.target.value);
              if(endTime3Hour !== null && endTime3Hour !== '') {
                  setEndTime3(endTime3Hour + ':' + v.target.value)
              }
          }  
      }
  }

  function onChangeRestTime(e: any) {
      setRestTime(e.target.value);
  }

  function selectedWorkingDays(e: any) {
      setNumberOfWorkingDays(e.target.value);
  }

  function onChangeHolidays (e: any, index: any) {
      let c = [];
      for(let i = 0; i < selectedHolidayIds.length; i++) {
          c.push(selectedHolidayIds[i]);
      }
      let chk = false;
      for(let i = 0; i < c.length; i++) {
          if(c[i] == e.target.value) {
              chk = true;
              index = i;
          }
      }
      if(!chk) {
          c.push(parseInt(e.target.value));
      } else {
          c.splice(index, 1);
      }
      setSelectedHolidayIds(c);
  } 

  function onChangeAnnualHolidayCount (e: any) {
    //   setAnnualHolidayCount(e.target.value);
      const value = parseInt(e.target.value, 10);
      if (value >= 0 || e.target.value === '') {
        setAnnualHolidayCount(value);
    } else {
        setAnnualHolidayCount(0); // or you could leave it unchanged
    }
  } 

  function onChangeVacations (e: any, id: any) {
      let c = [];
      for(let i = 0; i < selectedVacationIds.length; i++) {
          c.push(selectedVacationIds[i]);
      }
      if(e.target.checked) {
          c.push(id);
      } else {
          c.splice(c.indexOf(id), 1);
      }
      setSelectedVacationIds(c);
  } 

  function onChangeSalaryForms (e: any) {
      setSelectedSalaryFormId(e.target.value);
      if(e.target.value === '1') {
          setIsAnnualIncomeShow(true)
      }else{
          setIsAnnualIncomeShow(false)
      }
  } 

  function onChangeLowerMonthlyBasicSalary (e: any) {
      setLowerMonthlyBasicSalary(e.target.value); 
  }

  function onChangeUpperMonthlyBasicSalary (e: any) {
      setUpperMonthlyBasicSalary(e.target.value);
  } 

  function onChangeAllowances (e: any, index: any) {
      // setSelectedAllowanceIds(e.target.value);
      let c = [];
      for(let i = 0; i < selectedAllowanceIds.length; i++) {
          c.push(selectedAllowanceIds[i]);
      }
      let chk = false;
      for(let i = 0; i < c.length; i++) {
          if(c[i] == index+1) {
              chk = true;
              index = i;
          }
      }
      if(!chk) {
          c.push(parseInt(index+1));
      } else {
          c.splice(index, 1);
      }
      setSelectedAllowanceIds(c);
  } 

  function onChangeAnnualNumberOfBonusExists (e: any) {
    if(e.target.value == 'true') {
        setAnnualNumberOfBonusExists(true);
    } else {
        setAnnualNumberOfBonusExists(false);
    } 
} 

  function onChangeAnnualNumberOfBonus (e: any) {
      setAnnualNumberOfBonus(e.target.value)
  } 

  function onChangeLowerEstimatedAnnualIncome (e: any) {
      setLowerEstimatedAnnualIncome(e.target.value);
  }

  function onChangeUpperEstimatedAnnualIncome (e: any) {
      setUpperEstimatedAnnualIncome(e.target.value);
  } 

  function onChangeAnnualNumberOfRaisesExists (e: any) {
      if(e.target.value == 'true') {
          setAnnualNumberOfRaisesExists(true);
      } else {
          setAnnualNumberOfRaisesExists(false);
      } 
  } 

  function onChangeAnnualNumberOfRaises (e: any) {
      setAnnualNumberOfRaises(e.target.value);
  } 

  function onChangeSocialInsurances (e: any, index: any) {
      let c = [];
      for(let i = 0; i < selectedSocialInsuranceIds.length; i++) {
          c.push(selectedSocialInsuranceIds[i]);
      }
      let chk = false;
      for(let i = 0; i < c.length; i++) {
          if(c[i] == e.target.value) {
              chk = true;
              index = i;
          }
      }
      if(!chk) {
          c.push(parseInt(e.target.value));
      } else {
          c.splice(index, 1);
      }
      setSelectedSocialInsuranceIds(c);
  } 

  function onChangeRetirementMoneyExists (e: any) {
      if(e.target.value == 'true') {
          setRetirementMoneyExists(true);
      } else {
          setRetirementMoneyExists(false);
      }        
  } 

  function onChangeRetirementAge (e: any) {
      setRetirementAge(e.target.value);
  }



  function onChangeNumberOfEmployees (e: any) {
    //   setNumberOfEmployees(e.target.value);
      const value = parseInt(e.target.value, 10);
      if (value >= 0 || e.target.value === '') {
        setNumberOfEmployees(value);
    } else {
        setNumberOfEmployees(0);
    }
  }
  function onChangeOfficeNumber (e: any) {
    setOfficeNumber(e)
  } 
  
  function onChangeCompany (e: any) {
    setSelectedCompany(e);
  }


  async function createRecruiter() {   
      const validatedText = validates(); 
      if (validatedText) {
          alert('未入力項目があります\n\n' + validatedText)
          return
      }
      let result = '';
      let newGrad;
      if(new_graduate === '0'){
        newGrad =false
      } else {
        newGrad =true
      }
      let periodExistshow;
      if(isContractPeriodExistsShow) {
        periodExistshow = 'true'
      } else {
        periodExistshow = 'false'
      }
      let renewalExist;
      if(contractRenewalExists) {
        renewalExist = 'true'
      } else {
        renewalExist = 'false'
      }
      let fullNameExist;
      if(fullTimeEmployeeExists) {
        fullNameExist = 'true'
      } else {
        fullNameExist = 'false'
      }
      let isTrialPeriodExistsShow1;
      if(isTrialPeriodExistsShow){
        isTrialPeriodExistsShow1 = 'true'
      } else {
        isTrialPeriodExistsShow1 = 'false'
      }
      let retirementMoneyExists1;
      if(retirementMoneyExists) {
        retirementMoneyExists1 = 'true'
      } else {
        retirementMoneyExists1 = 'false'
      }
      let annualNumberOfBonusExists1
      if(annualNumberOfBonusExists) {
        annualNumberOfBonusExists1 = 'true'
      } else {
        annualNumberOfBonusExists1 = 'false'
      }
      let annualNumberOfRaisesExists1
      if (annualNumberOfRaisesExists) {
        annualNumberOfRaisesExists1 = 'true'
      } else {
        annualNumberOfRaisesExists1 = 'false'
      }

      let mySelectedPrefecturesIds:any = [];
      if(adminRecruitmentEdit.recruitments.prefectures.length !== 0){
        for(let i = 0; i < mySelectedPrefectures.length; i++) {
            mySelectedPrefecturesIds.push(mySelectedPrefectures[i].id)
          }
      } else {
        mySelectedPrefecturesIds = mySelectedPrefectures;
      }

    //   if (adminRecruitmentEdit.status_id == 2) {
    //     if (window.confirm('・会社概要プレビューを確認していただけましたか。\n・求人票の記入に誤りはありませんか。\n本当に公開しますか？')) {
    //     } else {
    //       adminRecruitmentEdit.status_id = undefined
    //       return false
    //     }
    //   }
    let arr:any =[];
    for(let i = 0; i < mySelectedPrefectures.length; i++){
        arr.push(mySelectedPrefectures[i].id)
    }

      const params = {
          company_id: parseInt(selectedCompany),
          office_number: officeNumber,
          job_title: jobTitle,
          job_category_id: parseInt(selectedJobCategoryId),
          job_category_type_id: parseInt(selectedJobTypeId),
          allowances: selectedAllowanceIds,
          appeal_item: {
            appeal_item_id_1: parseInt(appealitems1),
            appeal_item_id_2: parseInt(appealitems2),
            appeal_item_id_3: parseInt(appealitems3),
          },
          benefit_supplemental: benefitSupplemental,
        //   selectedCompany_name: companyName,
          employment_status_id: parseInt(selectedEmploymentStatusId),
          business_content: businessContent,
          business_flow: appeal_recruiter_items_business_flow,
          career_path:appeal_recruiter_items_career_path,
          compatibilities:recruitmentCompatibilities,
          consideration_condition_text:appeal_recruiter_items_consideration_condition,
          consideration_conditions: selectedConsiderationConditionsId,
          corporate_culture_text:appeal_recruiter_items_corporate_culture,
          handicap_types:selectHandicapTypesIds,
          holidays: selectedHolidayIds,
          hr_message:appeal_recruiter_items_hr_message,
          images: images,
          movie_path: movie_path2,
          new_graduate: newGrad,
          prefectures:arr,
          period: {                
              contract_period_id: parseInt(selectedContractPeriodId),
              contract_period_exists: periodExistshow,
              contract_renewal_exists: renewalExist,
              full_time_employee_exists: fullNameExist,
              contract_period_supplemental: contractPeriodSupplemental,
              trial_period_exists: isTrialPeriodExistsShow1,
              trial_period_id: selectedTrialPeriodId != null ? parseInt(selectedTrialPeriodId) : selectedTrialPeriodId,
              trial_period_supplemental: trialPeriodSupplemental,
          },
          preview_display_texts:previewDisplayTexts,
          required_experience: requiredExperience,
          retirement_money_exists: retirementMoneyExists1,
          retirement_age: retirementAge,
          work_environment:recruitmentWorkEnvironments,
          work_environment_text:recruitmentWorkEnvironmentsText,
          salary : {
              annual_number_of_raises_exists: annualNumberOfRaisesExists1,
              annual_number_of_raises: annualNumberOfRaises,
              allowances_supplemental: allowancesSupplemental,                
              annual_number_of_bonus_exists: annualNumberOfBonusExists1,
              annual_number_of_bonus: annualNumberOfBonus,
              lower_monthly_basic_salary: lowerMonthlyBasicSalary,
              upper_monthly_basic_salary: upperMonthlyBasicSalary,
              lower_estimated_annual_income: lowerEstimatedAnnualIncome,
              upper_estimated_annual_income: upperEstimatedAnnualIncome, 
              salary_form_id: parseInt(selectedSalaryFormId),
          },
          selection_method_supplemental: selectionMethodSupplemental,
          selection_methods: selectedSelectionMethodIds,
          social_insurances: selectedSocialInsuranceIds,
          superior_message:appeal_recruiter_items_superior_message,
          target : {
              number_of_employees: numberOfEmployees,
              schedule_id: recruitmentscheduleid != null ? parseInt(recruitmentscheduleid) : recruitmentscheduleid,
          },
          type_of_recruiting_company_id: typeOfRecruitingCompanyId,
          vacations: selectedVacationIds,        
          work_location: {
              address: locationAddress,
              city_correction: isEditCityCorrection ? cityCorrection : null,
              city_id: locationCityId,
              department: locationDepartment,
              nationWide: nationwide,
              nearest_station: locationNearestStation,
              postcode: postCode,
              prefecture_id: locationPrefectureId,
              region_id: locationRegionId,
              transportation: locationTransportation,
              work_from_home_id: Number(workFromHomeId),
              work_location_supplemental: locationWorkLocationSupplemental,
          },
          work_time: {    
              annual_holiday: annualHolidayCount,   
              start_time1: startTime1,
              start_time2: startTime2,
              start_time3: startTime3,
              end_time1: endTime1,
              end_time2: endTime2,
              end_time3: endTime3,                
              work_time_supplemental: workTimeSupplemental,
              rest_time: restTime,
              rest_time_supplemental: restTimeSupplemental,
              number_of_working_days: parseInt(numberOfWorkingDays),
              number_of_working_days_supplemental: numberOfWorkingDaysSupplemental,
              overtime_hours_exists: overtimeHoursExists,
              overtime_hours_supplemental: overtimeHoursSupplemental,
              holidays_supplemental: holidaysSupplemental,
          },
      }

      setAllParams(params);
      setLoadScreen(true);
      dispatch(fetchAdminRecruitmentPostRequest(params,"PUT",id)); 
      setChkPost(true);
  }

  //step2

  function change_graduate(e: any){
    setNew_graduate(e);
    setGraduateError(false);
}

function recruitmentschedule(e: any) {
    if(e === '') {
        // setErrorscheduleid(true);
    } else {
        // setErrorscheduleid(false);
    }
    setrecruitmentscheduleid(e);
}


function updateRegion(value:any, index:any) {
    let updatedRegions = [...MySelectRegions];
    let updatedPrefectures = [...mySelectedPrefectures];
    let c:any =[];
    if (value) {
        for(let i = 0; i< region.length; i++) {
            if(region[i].id == index+1){
                updatedRegions.push(region[i].id);
                for(let j = 0; j< prefectures.length; j++) {
                    if(prefectures[j].region_id == region[i].id){
                        prefectures[j].checked = true;
                        updatedPrefectures.push(prefectures[j]);
                    }
                }
            }
        }
 
    } else {
        updatedRegions = updatedRegions.filter((r) => r !== index + 1);
        updatedPrefectures = updatedPrefectures.filter((p) => p.region_id !== index + 1);
        for (let j = 0; j < prefectures.length; j++) {
            if (prefectures[j].region_id === index + 1) {
                prefectures[j].checked = false;
            }
        }
     
    }
    setMySelectRegions(updatedRegions);
    setMySelectedPrefectures(updatedPrefectures);
}

const updateValue = (value:any, data: any, index: any) => {
    let c = [];
    for(let i = 0; i < mySelectedPrefectures.length; i++) {
        c.push(mySelectedPrefectures[i]);
    }
    if (value) {
        c[index].checked = true;
        setMySelectedPrefectures(c);
    } else {          
      c[index].checked = false;
      setMySelectedPrefectures(c);
    } 

  };

  function updatePurpose(e: any, index: any) {
    let c = [];
    for(let i = 0; i < selectHandicapTypesIds.length; i++) {
        c.push(selectHandicapTypesIds[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == e.target.value) {
            chk = true;
            index = i;
        }
    }
    if(!chk) {
        c.push(Number(e.target.value));
    } else {
        c.splice(index, 1);
    }
    setSelectHandicapTypesIds(c);
}


function onChangeSelectionMethods (e: any, index: any) {
    let c = [];
    for(let i = 0; i < selectedSelectionMethodIds.length; i++) {
        c.push(selectedSelectionMethodIds[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == e.target.value) {
            chk = true;
            index = i;
        }
    }
    if(!chk) {
        c.push(Number(e.target.value));
    } else {
        c.splice(index, 1);
    }
    setSelectedSelectionMethodIds(c);
}

function handler_appeal_item(e: any) {  
    if("appeal_item_1" === e.target.name) {
        setappealitems1(e.target.value);
    }
    if("appeal_item_2" === e.target.name) {
        setappealitems2(e.target.value);
    }
    if("appeal_item_3" === e.target.name) {
        setappealitems3(e.target.value);
    }   
}
//step 3 
const selectAppeal = (key: number) => {
    setEdited(true);
    let c = [];
    for (let i = 0; i < selectAppeal.length; i++) {
      c.push(selectedAppeal[i]);
    }
    c.push(key);
    if (key == 0) {
      setCorporateCulture(txt1);
    } else if (key == 1) {
      setBusinessFlow(txt2);
    } else if (key == 2) {
      setSuperiorMessage(txt3);
    } else if (key == 3) {
      setCareerPath(txt4);
    } else if (key == 4) {
      setConsiderationCondition(txt5);
    } else if (key == 5) {
      setHrMessage(txt6);
    }
    setSelectedAppeal(c);
    const updatedShow = [...show];
    updatedShow[key] = !updatedShow[key];
    setShow(updatedShow);
    var sel = document.getElementById(
      "select-appeal-items"
    ) as HTMLSelectElement;
    sel.selectedIndex = 0;
  };
  
  const filter_appeal_recruiter_items = () => {
    return appeal_recruiter_items.filter((v, i) => {
      return true;
    });
  };

  const deleteAppeal = (index: any) => {
    const newShow = [...show];
    newShow[index] = false;
    if(index == 0){
        setCorporateCulture('');
    }
    if(index == 1){
        setBusinessFlow('');
    }
    if(index == 2) {
        setSuperiorMessage('');
    }
    if(index == 3){
        setCareerPath('');
    }
    if(index == 4){
        setConsiderationCondition('');
    }
    if(index == 5){
        setHrMessage('');
    }
    if(index == 6){
        setMoviePath2('')
    }
    setShow(newShow);
  };


  const EditMovie = () => {
    let errorFlag = true;
    if (movie_path2.indexOf("https://youtu.be/") !== -1) errorFlag = false;
    if (movie_path2.indexOf("https://www.youtube.com/watch?v=") !== -1)
      errorFlag = false;
    if (errorFlag) {
      window.alert("この動画のURLは対応しておりません。");
      return;
    }
    convertYoutubePath();
    setIsEditMoviePath(false);
  };

  function convertYoutubePath() {
    if (!movie_path2) {
      return;
    }
    let url = "";
    if (movie_path2.indexOf("https://www.youtube.com/watch?v=") != -1) {
      url = "https://www.youtube.com/embed/" + movie_path2.split("watch?v=")[1];
    }
    if (movie_path2.indexOf("https://youtu.be/") != -1) {
      url =
        "https://www.youtube.com/embed/" +
        movie_path2.split("https://youtu.be/")[1];
    }
    setMoviePath(url.trim());
  }

  function toggle(index: any) {
    setIsOpenList((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }

    function updateValue1(array: any, id: any) {
    setEdited(true);
    if (selectedConsiderationConditions.includes(array)) {
      setSelectedConsiderationConditions(
        selectedConsiderationConditions.filter((skill) => skill !== array)
      );
    } else {
      setSelectedConsiderationConditions([
        ...selectedConsiderationConditions,
        array,
      ]);
    }
    if (selectedConsiderationConditionsId.includes(id)) {
      setSelectedConsiderationConditionsId(
        selectedConsiderationConditionsId.filter((skill) => skill !== id)
      );
    } else {
      setSelectedConsiderationConditionsId([
        ...selectedConsiderationConditionsId,
        id,
      ]);
    }
  }

  function changeRecruitmentWorkEnvironments(id: any) {
    setEdited(true);
    
    if (id !== 0) {
      if (recruitmentWorkEnvironments.includes(id)) {
        setRecruitmentWorkEnvironments(
          recruitmentWorkEnvironments.filter((skill) => skill !== id)
        );
      } else {
        setRecruitmentWorkEnvironments([...recruitmentWorkEnvironments, id]);
      }
    }
  }

  function handleButtonClick(e: any) {
    e.preventDefault();
    changeRecruitmentWorkEnvironmentsText(environmentsText);
  }
  function changeRecruitmentWorkEnvironmentsText(text: any) {
    setEdited(true);
    if (recruitmentWorkEnvironmentsText.includes(text)) {
      setRecruitmentWorkEnvironmentsText(
        recruitmentWorkEnvironmentsText.filter((skill) => skill !== text)
      );
    } else {
      setRecruitmentWorkEnvironmentsText([
        ...recruitmentWorkEnvironmentsText,
        text,
      ]);
    }
    setEnvironmentsText("");
  }

  const deleteTag = (index: any) => {
    const updatedArray = [...recruitmentWorkEnvironmentsText];
    updatedArray.splice(index, 1);
    setRecruitmentWorkEnvironmentsText(updatedArray);
  };



function checkList1(arr : any[]) {
    let newArr: any[] = [];        
    let notEqualId1 : any[] = [];
    let notEqualId2 : any[] = [];
    let notEqualId3 : any[] = [];
    newArr = arr;
    if(appealitems1 !== null) {
        newArr = arr.filter(n => n.id !== appealitems1);
        notEqualId1 = arr.filter(n => n.id == parseInt(appealitems1));
        if(notEqualId1 !== null) {
            notEqualList.push({id: notEqualId1[0].id, name: notEqualId1[0].name, desc: 'appealitems1', appeal: 'appeal1'});
            notEqualList.push({id: notEqualId1[0].id, name: notEqualId1[0].name, desc: 'appealitems2', appeal: 'appeal1'});
            notEqualList.push({id: notEqualId1[0].id, name: notEqualId1[0].name, desc: 'appealitems3', appeal: 'appeal1'});
        }
    }
    if(appealitems2 !== null) {
        newArr = arr.filter(n => n.id !== appealitems2);
        notEqualId2 = arr.filter(n => n.id == parseInt(appealitems2));
        if(notEqualId2 !== null) {
            notEqualList.push({id: notEqualId2[0].id, name: notEqualId2[0].name, desc: 'appealitems1', appeal: 'appeal2'});
            notEqualList.push({id: notEqualId2[0].id, name: notEqualId2[0].name, desc: 'appealitems2', appeal: 'appeal2'});
            notEqualList.push({id: notEqualId2[0].id, name: notEqualId2[0].name, desc: 'appealitems3', appeal: 'appeal2'});
        }
    }
    if(appealitems3 !== null) {
        newArr = arr.filter(n => n.id !== appealitems3);
        notEqualId3 = arr.filter(n => n.id == parseInt(appealitems3));
        if(notEqualId3 !== null) {
            notEqualList.push({id: notEqualId3[0].id, name: notEqualId3[0].name, desc: 'appealitems1', appeal: 'appeal3'});
            notEqualList.push({id: notEqualId3[0].id, name: notEqualId3[0].name, desc: 'appealitems2', appeal: 'appeal3'});
            notEqualList.push({id: notEqualId3[0].id, name: notEqualId3[0].name, desc: 'appealitems3', appeal: 'appeal3'});
        }  
    }
    for(let i = 0; i < arr.length; i++) {
        for(let j = 0; j < notEqualList.length; j++) { 
            if(notEqualList[j].desc == 'appealitems1'){
                const equal = arr.filter(n => n.id == parseInt(notEqualList[j].id));
                if(equal != null) {
                    var element = document.getElementById(`appeal_item_1_`+notEqualList[j].id);
                    element?.classList.add("display_none");
                }                     
            }
            const objWithIdIndex = arr.filter(n => n.id !== notEqualList[j].id);
            for(let a = 0; a < objWithIdIndex.length; a++) { 
                if(objWithIdIndex[a].id !== parseInt(appealitems1) && objWithIdIndex[a].id !== parseInt(appealitems2) && objWithIdIndex[a].id !== parseInt(appealitems3)) {                        
                    if(notEqualList[j].appeal === 'appeal1') {
                        if(objWithIdIndex[a].id !== notEqualList[j].id) {
                            var element = document.getElementById(`appeal_item_1_`+objWithIdIndex[a].id);
                            element?.classList.remove("display_none");
                        }
                    }
                    if(notEqualList[j].appeal === 'appeal2') {
                        if(objWithIdIndex[a].id !== notEqualList[j].id) {
                            var element = document.getElementById(`appeal_item_2_`+objWithIdIndex[a].id);
                            element?.classList.remove("display_none");
                        }
                    }
                    if(notEqualList[j].appeal === 'appeal3') {
                        if(objWithIdIndex[a].id !== notEqualList[j].id) {
                            var element = document.getElementById(`appeal_item_3_`+objWithIdIndex[a].id);
                            element?.classList.remove("display_none");
                        }
                    }
                }
            }
            if(notEqualList[j].desc == 'appealitems2'){
                if(arr[i].id == parseInt(notEqualList[j].id)) {
                    var element = document.getElementById(`appeal_item_2_`+ notEqualList[j].id);
                    element?.classList.add("display_none");
                } 
            }
            if(notEqualList[j].desc == 'appealitems3'){
                if(arr[i].id == parseInt(notEqualList[j].id)) {
                    var element = document.getElementById(`appeal_item_3_`+ notEqualList[j].id);
                    element?.classList.add("display_none");
                } 
            }
        }   
    }
    return newArr;
}

function filterConsiderationConditionsHandicap(typeId: any) {
    return considerationConditions.filter(
      (v) => v.consideration_condition_type_id === typeId
    );
  }

  function changePreviewDisplayText(e: any) {
    setEdited(true);
    const { name, value } = e.target;
    if (name == "previewText0") {
        
      setPreviewDisplayText0(e.target.value);
      const updatedTexts = [...previewDisplayTexts];
      updatedTexts[0] = e.target.value;
      setPreviewDisplayTexts(updatedTexts);
      if (value !== null) {
        if (value.length > previewDisplayTextsMaxLen) {
          setIsErrorPreviewDisplayText0(true);
          setPreviewDisplayText0MaxLength(true);
          setPreviewDisplayText0MaxLenError(value.length);
        } else {
          setIsErrorPreviewDisplayText0(false);
          setPreviewDisplayText0MaxLength(false);
        }
      }
    }
    if (name == "previewText1") {
      setPreviewDisplayText1(e.target.value);
      const updatedTexts = [...previewDisplayTexts];
      updatedTexts[1] = e.target.value;
      setPreviewDisplayTexts(updatedTexts);
      if (value !== null) {
        if (value.length > previewDisplayTextsMaxLen) {
          setIsErrorPreviewDisplayText1(true);
          setPreviewDisplayText1MaxLength(true);
          setPreviewDisplayText1MaxLenError(value.length);
        } else {
          setIsErrorPreviewDisplayText1(false);
          setPreviewDisplayText1MaxLength(false);
        }
      }
    }
    if (name == "previewText2") {
      setPreviewDisplayText2(value);
      const updatedTexts = [...previewDisplayTexts];
      updatedTexts[2] = e.target.value;
      setPreviewDisplayTexts(updatedTexts);
      if (value !== null) {
        if (value.length > previewDisplayTextsMaxLen) {
          setIsErrorPreviewDisplayText2(true);
          setPreviewDisplayText2MaxLength(true);
          setPreviewDisplayText2MaxLenError(value.length);
        } else {
          setIsErrorPreviewDisplayText2(false);
          setPreviewDisplayText2MaxLength(false);
        }
      }
    }
    if (name == "previewText3") {
      setPreviewDisplayText3(value);
      const updatedTexts = [...previewDisplayTexts];
      updatedTexts[3] = e.target.value;
      setPreviewDisplayTexts(updatedTexts);
      if (value !== null) {
        if (value.length > previewDisplayTextsMaxLen) {
          setIsErrorPreviewDisplayText3(true);
          setPreviewDisplayText3MaxLength(true);
          setPreviewDisplayText3MaxLenError(value.length);
        } else {
          setIsErrorPreviewDisplayText3(false);
          setPreviewDisplayText3MaxLength(false);
        }
      }
    }
  }

  const handleCommentChange = (event: any, index: number) => {
    setEdited(true);
    const text = event.target.value;
    const updatevalue = {
      comment: text,
    };
    const updatedComment = [...images];
    updatedComment[index] = {
      ...updatedComment[index],
      ...updatevalue,
    };
    setImages(updatedComment);
  };

//   function delImage(event: any) {
//     let c = [];
//     for (let i = 0; i < images.length; i++) {
//       c.push(images[i]);
//     }
//     c[event - 1].url = null;
//     c[event - 1].comment = null;
//     delete c[event - 1].image;
//     setImages(c);
//   }

function delImage(event: any) {
    let c = [...images];
    c[event - 1].url = null;
    c[event - 1].comment = null;
    delete c[event - 1].image;
    setImages(c);

    // Null check before resetting the input value
    const fileInput = fileInputRefs.current[event - 1];
    if (fileInput) {
        fileInput.value = ""; // Reset the file input
    }
}

  const setImage = (index: number, event: any) => {
    setEdited(true);
    const fileImg = event.target.files[0];

    if (fileImg.type.startsWith("image/")) {
      const updatedImages = [...images];
      const reader = new FileReader();
      reader.readAsDataURL(fileImg);
      reader.onload = function () {
        updatedImages[index].image = reader.result as string;
        updatedImages[index].url = reader.result as string;
        setImages(updatedImages);
      };
    }
  };



  function otherImage (id: any) {
    let arr = [...showImage];
    arr[id + 1] = true;
    setShowImage(arr);
  }

  function changeRecruitmentCompatibilities(id: any) {
    setEdited(true);
    if (id !== 0) {
        if (recruitmentCompatibilities.includes(id)) {
            setRecruitmentCompatibilities(
                recruitmentCompatibilities.filter((skill) => skill !== id)
            );
        } else if (recruitmentCompatibilities.length < 5) {
            setRecruitmentCompatibilities([...recruitmentCompatibilities, id]);
        } else {
            console.warn("You can only select up to 5 items.");
        }
    }
}

function deleteTime3 () {
    // setShowSubNew1(false);
    setShowSubNew2(false)
    setStartTime3(null)
    setStartTime3Hour(null);
    setStartTime3Minute(null);
    setEndTime3(null);
    setEndTime3Hour(null);
    setEndTime3Minute(null)
}
function deleteTime2 () {
    setShowSubNew1(false);
    setStartTime2Hour(null);
    setStartTime2Minute(null);
    setEndTime2(null);
    setEndTime2Hour(null);
    setEndTime2Minute(null);
}

  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader /> : null}
      <section id='openjob'>
        <div className='adminPageWrapper'>
          <div className="header">
            <h2 className="title">求人編集</h2>
          </div>  
          <div className='body'>
            <div className="">
              <div className="textWrapper">
                <a onClick={() => navigate('/admin/openjob_list')} className='blueBtn'>＜ 一覧に戻る</a>
              </div>
            </div>
            <div className='step1'>
              <h1>STEP1 基本情報</h1>
              <h3>求人基本情報</h3>
              <div className='bordered'>
                <div className='flexBox'>
                  <div className='mr'>
                      <div>
                        <label>
                          企業 <span style={{ color: 'red' }}>※必須</span>
                        </label>
                        <select name="company"  value={selectedCompany}  onChange={(e) => onChangeCompany(e.target.value)}>
                            <option disabled selected>選択してください</option>
                            {companyList && companyList.map((data: any, index: any) => (
                                <option value={data.id}>企業ID: { data.id }  { data.name }</option>
                            ))}
                          </select>
                      </div>
                      <div>
                        <label>
                          事業所番号
                        </label>
                        <input type="text" name="officeNumber" placeholder='入力してください' value={officeNumber}  onChange={(e) => onChangeOfficeNumber(e.target.value)}/>

                      </div>
                      <div>
                        <label>
                          職種名(20文字以内) <span style={{ color: 'red' }}>※必須</span>
                        </label>
                        <input type="text" className="long" name="jobTitle" placeholder="入力してください" value={jobTitle || ''} onChange={(e) =>setJobTitle(e.target.value)}/>
                        {jobTitle.length > jobTitlemaxLength ? 
                        <div className="error_box">
                            <p className="error_text">{jobTitle.length - jobTitlemaxLength}文字オーバー</p>
                        </div>
                        : <></>}
                      </div>
                      <div>
                        <label>
                          職種 <span style={{ color: 'red' }}>※必須</span>
                        </label>
                        <select className="long" onChange={(e) => onChangeJobType(e.target.value)}>
                            <option disabled selected value={'大項目を選択してください'}>大項目を選択してください</option>
                            {jobCategoryTypes && jobCategoryTypes.map((jobCategoryType) => (
                                <option selected={selectedJobTypeId !== null && (jobCategoryType.id === selectedJobTypeId ? jobCategoryType.name === jobCategoryType.name : jobCategoryType.name === "")} key={jobCategoryType.id} value={jobCategoryType.id}>
                                    {jobCategoryType.name}
                                </option>
                            ))}
                        </select>
                      </div>
                      <div>
                        <select className="long" onChange={(e) => onChangeJobCategories(e.target.value)}>
                            <option disabled selected value={'小項目を選択してください'}>小項目を選択してください</option>
                            {jobCategories && jobCategories.map((jobCategory: any) => (
                                <option  selected={selectedJobCategoryId !== null && (jobCategory.id === selectedJobCategoryId ? jobCategory.name === jobCategory.name : jobCategory.name === "")} key={jobCategory.id} value={jobCategory.id}>
                                    {jobCategory.name}
                                </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label>
                          募集する企業区分 <span style={{ color: 'red' }}>※必須</span>
                        </label>
                          <select className="long" onChange={(e) => onChangeRecCompany(e.target.value)}>
                                  <option disabled selected value={'選択してください'}>選択してください</option>
                                  {typeOfRecruitingCompanies && typeOfRecruitingCompanies.map((i) => (
                                      <option selected={typeOfRecruitingCompanyId !== null && (i.id === typeOfRecruitingCompanyId ? i.name === i.name : i.name === '')} key={i.id} value={i.id}>
                                          {i.name}
                                      </option>
                                  ))}
                              </select>
                      </div>
                      <div>
                        <label>
                        雇用形態 <span style={{ color: 'red' }}>※必須</span>
                          
                        </label>
                        <select className="long" onChange={(e) => handler_contractPeriodShow(e.target.value)}>
                            <option disabled selected value={'選択してください'}>選択してください</option>
                            {employmentStatuses && employmentStatuses.map((employmentStatuses) => (
                                <option selected={selectedEmploymentStatusId != null && (employmentStatuses.id === selectedEmploymentStatusId ? employmentStatuses.name === employmentStatuses.name : employmentStatuses.name === "")} key={employmentStatuses.id} value={employmentStatuses.id}>
                                    {employmentStatuses.name}
                                </option>
                            ))}
                        </select>
                      </div>
                      <div>
                        <label> 現在のステータス <span style={{ color: 'red' }}>※必須</span></label>
                          <select name="currentStatus">
                            <option value="">選択してください</option>
                            <option value={1}>募集終了</option>
                            <option value={2}>休止</option>
                          </select>
                      </div>
                  </div>
                </div>
              </div>
              <div className='text-area'>
                <h3>業務内容(400文字以内)<span style={{ color: 'red' }}>※必須</span></h3>
                  <textarea  value={businessContent} onChange={(e) => handleChangetwo(e.target.value)} placeholder="入力してください">    
                  </textarea>
                  {businessContent.length > businessContentmaxLength ? 
                  <div className="error_box">
                      <p className="error_text nomargin">{businessContent.length - businessContentmaxLength}文字オーバ</p>
                  </div>
                  : <></>}
              </div>
              <div className='text-area'>
                <h3>必要な経験:資格(200文字以内)</h3>
                  <textarea value={requiredExperience} onChange={(e) => handleChangethree(e.target.value)} placeholder="入力してください">
                  </textarea>
                  {requiredExperience.length > requiredExperiencemaxLength ? 
                  <div className="error_box">
                      <p className="error_text  nomargin">{requiredExperience.length - requiredExperiencemaxLength}文字オーバ</p>
                  </div>
                  : <></>}
              </div>
              <div>
                <h3>住所(勤務地)</h3> 
                <div className='bordered'>
                    <div>
                      <label>
                      働き方:テレワーク <span style={{ color: 'red' }}>※必須</span>
                      
                      </label>
                      <select name="workFromHome" value={workFromHomeId || ''} onChange={(e) => handler_nationwide(e.target.value)}>
                          <option value="" disabled>選択してください</option>
                          {/* {work_from_home && work_from_home.map((data: any, index: any) => (
                              <option value={data.id}>{ data.name1 }</option>
                          ))} */}
                              <option value={1}>在宅勤務</option>
                              <option value={2}>オフィス:現場勤務</option>
                              <option value={3}>どちらでも:応相談</option>

                        </select>
                    </div>
                    <div>
                      <label>
                        支店:部署名
                      </label>
                      <input type="text" className='wid300' value={locationDepartment !== null ? locationDepartment : ""} placeholder="(例)本社、人事部など" onChange={(e) => inputLocationDepartment(e.target.value)}/>
                        
                        <div>
                            <label>
                                郵便番号<span style={{ color: 'red' }}>※必須</span>
                            </label>
                            <div className='dFlex'>
                            <input type="number" maxLength={7} className='wid300' placeholder={workFromHomeId == 1 ? '本社の住所を入力ください' : '(例)0000000'} name="address" value={postCode}  onChange={(e) => {if (e.target.value.length <= 7) { setPostCode(e.target.value);}}}/>
                            <button onClick={onKeyUpPostCode}>郵便番号検索</button>
                            </div>
                        </div>
                            <div>
                            <div className='dFlex'>
                            <div>
                                <label>
                                都道府県 市/区<span  style={{ color: 'red' }}>※必須</span>
                                </label>
                                <input type="text" placeholder='郵便番号で自動入力' name="prefecture" value={areaText} onChange={(e) => setAreaText(e.target.value)} />
                            </div>
                            <div style={{marginLeft:'20px'}}>
                                <label>
                                住所(それ以降)
                                </label>
                                <input  type="text" placeholder='(例)1-1-1 マンション名' value={locationAddress} onChange={onChangeLocationAddress}/>
                            </div>
                            </div>
                            <div className='dFlex'>
                                <div>
                                    <label>最寄り駅</label>
                                    <div><input type="text" placeholder="(例)東京都メトロ線半蔵門駅" value={locationNearestStation} onChange={onChangeLocationNearestStation}/></div>
                                    
                                </div>
                                <div style={{marginLeft:'20px'}}>
                                    <label>最寄り駅からの交通手段</label>
                                    <div><input type="text" placeholder="(例)徒歩5分など" value={locationTransportation} onChange={onChangeLocationTransportation}/></div>
                                </div>
                            </div>
                        </div>
                     
                     <div>
                      <label>転勤の有無<span style={{ color: 'red' }}>※必須</span></label>
                      <div className='dFlex'>
                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="nationwide" value="true" checked={nationwide ? true : false} onChange={onChangeNationwide}/>有</label>
                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="nationwide" value="false" checked={!nationwide ? true : false}  onChange={onChangeNationwide}/>無</label>
                      </div>
                    </div>
                    
                    
                    <div>
                      <h3>勤務地の補足(200文字)</h3>
                      <textarea placeholder='入力してください' value={locationWorkLocationSupplemental} onChange={(e) => handleChangeAddress(e.target.value)}></textarea>
                        {locationWorkLocationSupplemental && locationWorkLocationSupplemental.length > locationWorkLocationSupplementalmaxLength ? 
                            <div className="error_box">
                                <p className="error_text  nomargin">{locationWorkLocationSupplemental.length - locationWorkLocationSupplementalmaxLength}文字オーバ</p>
                            </div>
                        : <></>}
                    </div>
                        
                    </div>
                </div>
              </div>
              <div>
                <h3>契約期間/就業時間</h3> 
                <div className='bordered'>
                    <div>
                      <label>契約期間有無<span style={{ color: 'red' }}>※必須</span></label>
                      <div className='dFlex'>
                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="cpe" value="true" checked={isContractPeriodExistsShow ? true : false}  onChange={handler_isContractPeriodExistsShow}/>有</label>
                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="cpe" value="false"  checked={!isContractPeriodExistsShow ? true : false}   onChange={handler_isContractPeriodExistsShow}/>無</label>
                        </div>
                        {isContractPeriodExistsShow ? 
                        <>
                        <div>
                            <label>
                                契約期間 
                            </label>
                                <select onChange={(e) => handler_contractPeriods(e.target.value)}>
                                    <option selected value={'選択してください'}>選択してください</option>
                                    {contractPeriods && contractPeriods.map((contractPeriods) => (
                                        <option selected={selectedContractPeriodId !== null && (contractPeriods.id === selectedContractPeriodId ? contractPeriods.name === contractPeriods.name : contractPeriods.name === "")} key={contractPeriods.id} value={contractPeriods.id}>
                                            {contractPeriods.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <label>契約更新</label>
                            <div className='dFlex'> 
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="cre" value="true" checked={contractRenewalExists ? true : false} onChange={OnChangeContractRenewalExists}/>有</label>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="cre" value="false"  checked={!contractRenewalExists ? true : false} onChange={OnChangeContractRenewalExists}/>無</label>
                            </div>

                            <div>
                                <label>その他補足(200文字)</label>
                                    <textarea placeholder="入力してください"  value={contractPeriodSupplemental} onChange={(e) => handleChangeContract(e.target.value)}/>
                                    {contractPeriodSupplemental && contractPeriodSupplemental.length > contractPeriodSupplementalmaxLength ? 
                                    <div className="error_box">
                                        <p className="error_text  nomargin">{contractPeriodSupplemental.length - contractPeriodSupplementalmaxLength}文字オーバ</p>
                                    </div>
                                    : <></>}
                            </div>
                        </>
                        : <></>}
                    </div>
                    <div>
                      <label>試用期間の有無<span style={{ color: 'red' }}>※必須</span></label>
                      <div className='dFlex'>
                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="tpe" checked={isTrialPeriodExistsShow ? true : false} value="true" onChange={handler_isTrialPeriodExistsShow}/>有</label>
                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="tpe" checked={!isTrialPeriodExistsShow ? true : false} value="false" onChange={handler_isTrialPeriodExistsShow}/>無</label>
                      </div>
                      {isTrialPeriodExistsShow ?
                        <>
                            <label>試用期間</label>
                            <div>
                                <select onChange={(e) => handler_trialPeriods(e.target.value)}>
                                    <option selected value="">選択してください</option>
                                    {trialPeriods && trialPeriods.map((trialPeriods) => (
                                    <option key={trialPeriods.id} value={trialPeriods.id} selected={selectedTrialPeriodId != null && (trialPeriods.id === selectedTrialPeriodId ? trialPeriods.name === trialPeriods.name : trialPeriods.name === "")}>
                                        {trialPeriods.name}
                                    </option>
                                ))}
                                </select>
                            </div>
                            <label>その他補足(200文字)</label>
                            <div>
                                <textarea placeholder="入力してください" value={trialPeriodSupplemental} onChange={(e) => handleChangetrial(e.target.value)}/>
                                {trialPeriodSupplemental && trialPeriodSupplemental.length > trialPeriodSupplementalmaxLength ? 
                                <div className="error_box">
                                    <p className="error_text  nomargin">{trialPeriodSupplemental.length - trialPeriodSupplementalmaxLength}文字オーバ</p>
                                </div>
                                : <></>}
                            </div>
                        </>
                        : <></>}
                    </div>
                    <div>
                      <label>正社員登用<span style={{ color: 'red' }}>※必須</span></label>
                      <div className='dFlex'>
                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="ftee" value="true" checked={fullTimeEmployeeExists ? true : false} onChange={handler_fullTimeEmployeeExists}/>有</label>
                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="ftee" value="false" checked={!fullTimeEmployeeExists ? true : false} onChange={handler_fullTimeEmployeeExists}/>無</label>
                      </div>
                      <p>
                        就業時間 <span style={{ color: 'red' }}>※必須(最大3つまで就業時間を設定できます)</span>
                      </p>
                    </div>
                        <div>
                            <div className="time_box">
                                <label>始業時間
                                <div className="time_selector">
                                    <select onChange={(e) => updateHour(e)} name="startTime1Hour">
                                        <option selected value={''}></option>
                                        {hourList && hourList.map((h) => (
                                            <option selected={startTime1Hour ? (h === startTime1Hour) : h === ""} key={h} value={h}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                    <span>:</span>
                                    <select onChange={(e) => updateMinute(e)}  name="startTime1Minute" disabled={isStartMinute1Disable}>
                                        <option selected value={''}></option>
                                            {minuteList.map((m) => (
                                                <option selected={startTime1Minute ? (m === startTime1Minute) : m === ""} key={m} value={m}>
                                                    {m}
                                                    </option>
                                        ))}
                                    </select>
                                    </div>
                                </label>
                                <p>〜</p>
                                <label>終業時間
                                <div className="time_selector">
                                    <select onChange={(e) => updateHour(e)} name="endTime1Hour">
                                        <option selected value={''}></option>
                                        {hourList && hourList.map((h) => (
                                            <option selected={endTime1Hour ? (h === endTime1Hour) : h === ""} key={h} value={h}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                    <span>:</span>
                                    <select onChange={(e) => updateMinute(e)}  name="endTime1Minute" disabled={isEndMinute1Disable}>
                                        <option selected value={''}></option>
                                            {minuteList.map((m) => (
                                                <option selected={endTime1Minute ? (m === endTime1Minute) : m === ""} key={m} value={m}>
                                                    {m}
                                                    </option>
                                        ))}
                                    </select>
                                    </div>
                                </label>
                            </div>
                            {!showSubNew1 ? <button className='newsBtnToggle'  onClick={() => setShowSubNew1(true)}>複数を追加</button> : <></>}

                            {showSubNew1 ? 
                            <>
                            <div className="time_box">
                                <label>始業時間
                                <div className="time_selector">
                                    <select onChange={(e) => updateHour(e)} name="startTime2Hour">
                                        <option selected value={''}></option>
                                        {hourList && hourList.map((h) => (
                                            <option selected={startTime2Hour ? h === startTime2Hour : h === ""} key={h} value={h}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                    <span>:</span>
                                    <select onChange={(e) => updateMinute(e)} name="startTime2Minute" disabled={isStartMinute2Disable}>
                                        <option selected value={''}></option>
                                            {minuteList.map((m) => (
                                                <option selected={startTime2Minute ? m === startTime2Minute : m === ""} key={m} value={m}>
                                                    {m}
                                                    </option>
                                        ))}
                                    </select>
                                    </div>
                                </label>
                                <p>〜</p>
                                <label>終業時間
                                <div className="time_selector">
                                    <select onChange={(e) => updateHour(e)} name="endTime2Hour">
                                        <option selected value={''}></option>
                                        {hourList && hourList.map((h) => (
                                            <option selected={endTime2Hour ? h === endTime2Hour : h === ""} key={h} value={h}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                    <span>:</span>
                                    <select onChange={(e) => updateMinute(e)} name="endTime2Minute" disabled={isEndMinute2Disable}>
                                        <option selected value={''}></option>
                                            {minuteList.map((m) => (
                                                <option selected={endTime2Minute ? m === endTime2Minute : m === ""} key={m} value={m}>
                                                    {m}
                                                    </option>
                                        ))}
                                    </select>
                                    </div>
                                </label>
                            </div>
                            {!showSubNew2 ? <button className='newsBtnToggle'  onClick={() => setShowSubNew2(true)}>複数を追加</button> : <></>}
                            <button className='deletBtnToggle'  onClick={() =>  deleteTime2()}>削除</button>
                            </>
                            : <></>}
                            {showSubNew2 ?
                                <>
                                <div className="time_box">
                                <label>始業時間
                                <div className="time_selector">
                                    <select onChange={(e) => updateHour(e)} name="startTime3Hour">
                                        <option value={" "}></option>
                                        {hourList && hourList.map((h) => (
                                            <option selected={startTime3Hour ? h === startTime3Hour : h === ""} key={h} value={h}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                    <span>:</span>
                                    <select onChange={(e) => updateMinute(e)} name="startTime3Minute" disabled={isStartMinute3Disable}>
                                        <option value={" "}></option>
                                            {minuteList.map((m) => (
                                                <option selected={startTime3Minute ? m === startTime3Minute : m === ""} key={m} value={m}>
                                                    {m}
                                                    </option>
                                        ))}
                                    </select>
                                </div>
                                </label>
                                <p>〜</p>
                                <label>終業時間
                                <div className="time_selector">
                                    <select onChange={(e) => updateHour(e)} name="endTime3Hour">
                                        <option value={" "}></option>
                                        {hourList && hourList.map((h) => (
                                            <option selected={endTime3Hour ? h === endTime3Hour : h === ""}  key={h} value={h}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                    <span>:</span>
                                    <select onChange={(e) => updateMinute(e)} name="endTime3Minute" disabled={isEndMinute3Disable}> 
                                        <option value={" "}></option>
                                            {minuteList.map((m) => (
                                                <option selected={endTime3Minute ? m === endTime3Minute : m === ""} key={m} value={m}>
                                                    {m}
                                                    </option>
                                        ))}
                                    </select>
                                    </div>
                                </label>
                            </div>
                            <button className='deletBtnToggle'  onClick={() => deleteTime3()}>削除</button>

                                </>
                            
                            : <></>}
                        </div>
                    <div>
                        <label>勤務時間補足（200文字以内）</label>
                        <div>
                            <textarea  value={workTimeSupplemental} onChange={handleChangework} placeholder="入力してください"></textarea>
                            {workTimeSupplemental && workTimeSupplemental.length > workTimeSupplementalmaxLength ? 
                            <div className="error_box">
                                <p className="error_text">{workTimeSupplemental.length - workTimeSupplementalmaxLength}文字オーバ</p>
                            </div>
                            : <></>}
                        </div>
                    </div>
                </div>
              </div>
              <div>
                <h3>休憩時間/勤務日数 <span style={{ color: 'red' }}>※必須</span> </h3>
                <div className='bordered'>
                    <div>
                        <label>休憩時間<span style={{ color: 'red' }}>※必須</span></label>
                        <div>
                           <div className='dFlex'>
                           <input type="text" placeholder="1" className='wid300' value={restTime ? restTime : ''} v-model="restTime" onChange={onChangeRestTime}/> <p style={{padding:'5px 10px'}}>時間</p>
                           </div>
                            <div>
                                <label>休憩時間補足（200文字以内）</label>
                                <div>
                                    <textarea placeholder="入力してください" value={restTimeSupplemental} onChange={handleChangerest}></textarea>
                                    {restTimeSupplemental && restTimeSupplemental.length >restTimeSupplementalmaxLength ? 
                                    <div className="error_box">
                                        <p className="error_text">{restTimeSupplemental.length - restTimeSupplementalmaxLength}文字オーバ</p>
                                    </div>
                                    : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label>勤務日数/週 <span style={{ color: 'red' }}>※必須</span></label>
                        <select onChange={selectedWorkingDays}>
                            <option value={""}>選択</option>
                            {workingDays.map((workingDay) => (
                                <option selected={numberOfWorkingDays != null ? workingDay === numberOfWorkingDays : workingDay === " "} key={workingDay} value={workingDay}>
                                    {workingDay}
                                </option>
                            ))}
                        </select>
                        <div>
                            <label>勤務日数補足（200文字以内）</label>
                            <div>
                                <textarea placeholder="入力してください" value={numberOfWorkingDaysSupplemental || ''} onChange={handleChangenumber}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div>
                <h3>残業時間</h3>
                <div className='bordered'>
                    <div>
                        <label >残業時間有無</label>
                        <div className='dFlex'>
                            <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="ohe" value="true" checked={overtimeHoursExists ? true : false} onChange={handler_overtimeHoursExists}/>有</label>
                            <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="ohe" value="false" checked={!overtimeHoursExists ? true : false}  onChange={handler_overtimeHoursExists}/>無</label>
                        </div>
                    </div>
                    <div>
                        <label>残業時間補足（200文字以内）</label>
                        <div>
                            <textarea placeholder="" value={overtimeHoursSupplemental} onChange={handleChangeovertime}></textarea>
                            {overtimeHoursSupplemental && overtimeHoursSupplemental.length >overtimeHoursSupplementalmaxLength ? 
                                <div className="error_box">
                                    <p className="error_text">{overtimeHoursSupplemental.length - overtimeHoursSupplementalmaxLength}文字オーバ</p>
                                </div>
                            : <></>}
                        </div>
                    </div>
                </div>
              </div>
              <div>
                <h3>休日に関して</h3>
                <div className='bordered'>
                    <div>
                        <dl>
                            <label>休日</label>
                            <div className='dFlex'  style={{marginBottom:'10px'}}>
                            {holidays && holidays.map((holidays, index) => (
                                <label key={holidays.id}>
                                    <input className='m-3'  type="checkbox" checked={selectedHolidayIds.includes(holidays.id) ? true : false} value={holidays.id} onChange={(e) => onChangeHolidays(e, index)}></input>
                                    {holidays.name}
                                </label>
                            ))}
                            </div>
                            <label>年間休日</label>
                            <div className='dFlex'>
                                <input type="number" className="wid300" min={0} value={annualHolidayCount !== null ? annualHolidayCount : ''}  onChange={onChangeAnnualHolidayCount}/>
                                <p>日</p>
                            </div>
                            <div>
                                <label>休日:休暇について</label>
                                <div>
                                    <ul className="check_list">
                                    {vacations && vacations.map((vacations) => (
                                        <li key={vacations.id} className='dFlex'>
                                                
                                                <label  htmlFor={'vacation-' + vacations.id}><input  className='m-3' type="checkbox"  id={'vacation-' + vacations.id} value={vacations.id} checked={selectedVacationIds.includes(vacations.id)} onChange={(e) => onChangeVacations(e, vacations.id)}></input>{vacations.name}</label>
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                            </div>
                            
                            <div className='mt20'>
                                <label>休日補足（200文字以内）</label>
                                <div>
                                <textarea placeholder="" value={holidaysSupplemental} onChange={handleChangeholiday}></textarea>
                                {holidaysSupplemental && holidaysSupplemental.length > holidaysSupplementalmaxLength ? 
                                    <div className="error_box">
                                        <p className="error_text nomargin">{holidaysSupplemental.length - holidaysSupplementalmaxLength}文字オーバ</p>
                                    </div>
                                    : <></>}
                                </div>
                            </div>
                        </dl>
                    </div>
                </div>
              </div>
              <div>
                <h3>給与関係:各種手当</h3>
                <div className='bordered'>
                    <div>
                        <label>給与方式<span style={{color:'red'}}>※必須</span></label>
                        <div>
                            <select onChange={onChangeSalaryForms}>
                                <option>選択してください</option>
                                {salaryForms && salaryForms.map((salaryForms) => (
                                    <option selected={selectedSalaryFormId !== null ? (salaryForms.id === selectedSalaryFormId ? salaryForms.name === salaryForms.name :  salaryForms.name === "") : salaryForms.name === ""} key={salaryForms.id} value={salaryForms.id}>
                                        {salaryForms.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div>
                        <label>基本給<span style={{color:'red'}}>※必須</span></label>
                        <div className='dFlex'>
                            <input type="number" className='wid300' placeholder="例)100000" maxLength={8} pattern="[0-9]*" value={lowerMonthlyBasicSalary != null ? lowerMonthlyBasicSalary : ''} onChange={onChangeLowerMonthlyBasicSalary}/> <p style={{padding:'0 15px'}}>円〜</p>
                            <input type="number" className='wid300' placeholder="例)200000" maxLength={8} pattern="[0-9]*" value={upperMonthlyBasicSalary != null ? upperMonthlyBasicSalary : ''} onChange={onChangeUpperMonthlyBasicSalary}/> <p style={{padding:'0 15px'}}>円</p>
                        </div>
                    </div>
                    <div>
                        <label>想定年収<span style={{color:'red'}}>※必須</span></label>
                        <div className='dFlex'>
                            <input type="number" className='wid300' placeholder="2,500,000" maxLength={8} pattern="[0-9]*" value={lowerEstimatedAnnualIncome != null ? lowerEstimatedAnnualIncome : ''} onChange={onChangeLowerEstimatedAnnualIncome}/><p style={{padding:' 5px 15px'}}>〜</p>
                            <input type="number" className='wid300' placeholder="3,000,000" maxLength={8} pattern="[0-9]*" value={upperEstimatedAnnualIncome != null ? upperEstimatedAnnualIncome : ''} onChange={onChangeUpperEstimatedAnnualIncome}/>
                        </div>
                    </div>
                    <div>
                        <label>各種手当</label>
                            <div>
                                <ul className="check_list">
                                        {allowances && allowances.map((allowances, index) => (
                                            <li key={allowances.id} className='dFlex'>
                                                
                                                <label htmlFor={'allowance-' + allowances.id}><input  className='m-3' type="checkbox" checked={selectedAllowanceIds.includes(allowances.id) ? true : false}  id={'allowance-' + allowances.id} onChange={(e) =>onChangeAllowances(e, index)}></input>{allowances.name}</label>
                                            </li>
                                        ))}
                                </ul>
                                <div className='mt20'>
                                    <label>各種手当補足（200文字以内）</label>
                                    <div>
                                        <textarea placeholder="" value={allowancesSupplemental} onChange={handleChangeallowances}></textarea>
                                        {allowancesSupplemental.length > allowancesSupplementalmaxLength ? 
                                        <div className="error_box">
                                            <p className="error_text nomargin">{allowancesSupplemental.length - allowancesSupplementalmaxLength}文字オーバ</p>
                                        </div>
                                        : <></>}
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div>
                        <label>賞与<span style={{color:'red'}}>※必須</span></label>
                        <div className='dFlex'>
                            <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name='annualNumberOfBonusExists' checked={annualNumberOfBonusExists}  value="true"   onChange={onChangeAnnualNumberOfBonusExists}/>有</label>
                            <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name='annualNumberOfBonusExists' checked={!annualNumberOfBonusExists}  value="false" onChange={onChangeAnnualNumberOfBonusExists} />無</label>
                        </div>
                        {/* <input type="text" value={annualNumberOfBonus !== null ? annualNumberOfBonus : ""}  onChange={onChangeAnnualNumberOfBonus}/> */}
                    </div>
                    <div>
                        <label>昇給<span style={{color:'red'}}>※必須</span></label>
                        <div>
                            
                            <div className='dFlex'>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' id="raisesYes" name="annualNumberOfRaisesExists" value="true"  checked={annualNumberOfRaisesExists} onChange={onChangeAnnualNumberOfRaisesExists} />有</label>
                            
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}> <input type="radio" className='radio' id="raisesNo" name="annualNumberOfRaisesExists" value="false" checked={!annualNumberOfRaisesExists} onChange={onChangeAnnualNumberOfRaisesExists} />無</label>
                            </div>
                            {/* <input type="text" v-model="annualNumberOfRaises" onChange={onChangeAnnualNumberOfRaises} /> */}
                        </div>
                    </div>
                </div>
              </div>
                <div>
                    <h3>社会保険/退職金制度/定年/福利厚生</h3>
                    <div className='bordered'>
                        <div>
                            <label>社会保険</label>
                            <div className='dFlex' style={{marginBottom:'10px'}}>
                                {socialInsurances && socialInsurances.map((socialInsurances, index) => (
                                    <label key={socialInsurances.id}>
                                        <input  className='m-3' type="checkbox" value={socialInsurances.id} checked={selectedSocialInsuranceIds.includes(socialInsurances.id)} onChange={(e) =>onChangeSocialInsurances(e, index)}></input>
                                        {socialInsurances.name}
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label>退職金制度 <span style={{color:'red'}}>※必須</span></label>
                            <div className='dFlex'>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="ooh" value="true" checked={retirementMoneyExists} onChange={onChangeRetirementMoneyExists}/>有</label>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="ooh" value="false" checked={!retirementMoneyExists} onChange={onChangeRetirementMoneyExists}/>無</label>
                            </div>
                        </div>
                        <div>
                            <label>定年</label>
                            <div className='dFlex wid300'>
                                <input type="text" placeholder="(例)60" value={retirementAge} onChange={onChangeRetirementAge}/> <p>歳</p>
                            </div>
                        </div>
                        <div>
                        <label>福利厚生補足（200文字以内）</label>
                            <div>
                                <textarea placeholder="" value={benefitSupplemental} onChange={handleChangebenefit}></textarea>
                                {benefitSupplemental && benefitSupplemental.length > benefitSupplementalmaxLength ? 
                                    <div className="error_box">
                                        <p className="error_text nomargin">{benefitSupplemental.length - benefitSupplementalmaxLength}文字オーバ</p>
                                    </div>
                                : <></>}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3>選考方法/想定採用人数</h3>
                    <div className='bordered'>
                        <div>
                            <label>選考方法 <span style={{color:'red'}}>※必須</span></label>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', width:'500px' }}>
                                {selectionMethods && selectionMethods.map((selectionMethods, index) => (
                                    <label key={selectionMethods.id} style={{ width: '150px' }}>
                                        <input className='m-3' type="checkbox" checked={selectedSelectionMethodIds.includes(selectionMethods.id)} value={selectionMethods.id} onChange={(e) =>onChangeSelectionMethods(e, index)}></input>
                                        {selectionMethods.name}
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div className='mt20'>
                            <label>想定採用人数<span style={{color:'red'}}>※必須</span></label>
                            <div className='dFlex wid300'>
                                <input type="number" min={0}  name="number_of_employees" placeholder="例）1" value={numberOfEmployees != null ? numberOfEmployees : ''} onChange={onChangeNumberOfEmployees}/>  <p style={{padding:"5px 10px"}}>人</p>
                            </div>
                        </div>
                       
                    </div>
                </div> 
                <div>
                    <h3>その他特記事項（400文字以内）</h3>
                    <div>
                        <textarea placeholder="入力してください"value={selectionMethodSupplemental} onChange={handleChangeselection}></textarea>
                        {selectionMethodSupplemental.length > selectionMethodSupplementalmaxLength ? 
                            <div className="error_box">
                                <p className="error_text nomargin">{selectionMethodSupplemental.length - selectionMethodSupplementalmaxLength}文字オーバ</p>
                            </div>
                        : <></>}
                    </div>
                </div>                              
            </div>
            <div className='step2' style={{marginTop:'30px'}}>
                <h1>STEP2 採用要件・アピール項目 </h1>
                <div>
                    <h3>採用情報</h3>
                    <div className='bordered'>
                        <div>
                            <label>採用種別を教えてください</label>
                            <div>
                                <div className='dFlex'>
                                    <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="new_graduate" checked={new_graduate == "1"} value="1" onChange={(e)=>change_graduate(e.target.value)}/>新卒</label>
                                    <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name="new_graduate" checked={new_graduate == "0"} value="0" onChange={(e)=>change_graduate(e.target.value)}/>中途</label>
                                </div>
                                {graduateError ?
                                    <div className="error_box">
                                        <p className="error_msg">選択必須項目です</p>
                                    </div>
                                : <></>}
                            </div>
                        </div>
                        <div>
                            <label>採用スケジュールを教えてください</label>
                            <select name="schedule"  value={recruitmentscheduleid || ''} onChange={(e)=>recruitmentschedule(e.target.value)}>
                                <option value='' disabled selected  style={{ display:"none" }} >選択してください</option>
                                {schedules && schedules.map((data:any , index:any) => (
                                    <option  value={data.id} key={index}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>地域を教えてください</label>
                            <div>
                                <div className='dFlex' style={{marginBottom:'10px'}}>
                                    {region && region.map((data:any , index:any) => (
                                        <label key={index}>
                                            <input
                                                value={data.id}
                                                className='m-3'
                                                type="checkbox"
                                                checked={MySelectRegions.includes(data.id)}
                                                onChange={e => {updateRegion(e.target.checked, index);}}
                                            />
                                            {data.name}
                                        </label>
                                    ))}
                                </div>
                                {mySelectedPrefectures && mySelectedPrefectures.length > 0 ?
                                    <div className="checkbox_list area area_child dFlexWrap" >
                                        {mySelectedPrefectures.map((data: any, index:any) => (
                                            <label key={data.id}>
                                                <input type="checkbox" value={data.id} checked={data.checked} onChange={e => updateValue(e.target.checked, data, index)}/> {data.name}
                                            </label>
                                        ))}
                                    </div>
                                : <></>}
                            </div>
                        </div>
                        <div>
                            <label>障害区分 <span style={{color:'red'}}>※必須</span></label>
                            <div className='dFlex'>
                                {handicaptypes && handicaptypes.map((data:any , index:any ) => (
                                    <label key={index}>
                                        <input  type="checkbox" name="purpose" checked={selectHandicapTypesIds.includes(data.id)} value={data.id}   onChange={(e) => updatePurpose(e, index)}></input>
                                        {data.name}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3>エントリー時に聞きたい内容</h3>
                    <div className='bordered'>
                        <div>
                            <label>アピールしたい職歴<span style={{color:'red'}}>※必須</span></label>
                            <select name="appeal_item_1" value={appealitems1 || ''} onChange={(e) => handler_appeal_item(e)}>
                                <option value='' disabled selected  style={{ display:"none" }} >選択してください</option>
                                {appealitems && checkList1(appealitems).map((data:any) => (
                                    <option value={data.id} id={`appeal_item_1_`+data.id} key={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>アピールしたい経験職種<span style={{color:'red'}}>※必須</span></label>

                        <select name="appeal_item_2" value={appealitems2 || ''} onChange={(e) => handler_appeal_item(e)} >
                            <option value='' disabled selected  style={{ display:"none" }} >選択してください</option>
                            {appealitems && checkList1(appealitems).map((data:any) => (
                                <option value={data.id} id={`appeal_item_2_`+data.id} key={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                        </div>
                        <div>
                            <label>アピールしたい語学<span style={{color:'red'}}>※必須</span></label>
                            <select name="appeal_item_3" value={appealitems3 || ''} onChange={(e) => handler_appeal_item(e)} >
                                <option value='' disabled selected  style={{ display:"none" }} >選択してください</option>
                                {appealitems && checkList1(appealitems).map((data:any) => (
                                    <option value={data.id} id={`appeal_item_3_`+data.id} key={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='step3' style={{marginTop:'30px'}}>
                <h1>STEP3プレビュー設定項目</h1>
                <div>
                    <h3>アピール項目</h3>
                    <div className='bordered'>
                        <label >アピール項目を追加する場合はご選択ください<span style={{color:'red'}}>※複数設定できます。</span></label>
                        <div>
                            {show.includes(false) ? (
                                <select
                                id="select-appeal-items"
                                v-model="selectedAppeal"
                                onChange={(e) =>
                                    selectAppeal(
                                    filter_appeal_recruiter_items().indexOf(
                                        e.target.value
                                    )
                                    )
                                }
                                >
                                <option disabled selected>
                                    選択してください
                                </option>
                                {filter_appeal_recruiter_items().map(
                                    (i, index) =>
                                    !show[index] && (
                                        <option key={index} value={i}>
                                        {i}
                                        </option>
                                    )
                                )}
                                </select>
                            ) : (
                                <></>
                            )}
                        </div>
                        {show[0] && (
                            <div>
                                <h3>
                                社風・職場の雰囲気
                                
                                </h3>
                                {/* {!isApiLoadingCorporateCulture ? ( */}
                                <div className="load-area-warp">
                                    <p>
                                    <textarea
                                        cols={30}
                                        rows={10}
                                        value={appeal_recruiter_items_corporate_culture}
                                        onChange={(e) =>
                                        setCorporateCulture(e.target.value)
                                        }
                                        v-show={show[0]}
                                    />
                                    </p>
                                    {appeal_recruiter_items_corporate_culture.length >
                                    200 ? (
                                    <div className="error_box">
                                        <p className="error_text">
                                        {appeal_recruiter_items_corporate_culture.length -
                                            200}
                                        文字オーバー
                                        </p>
                                    </div>
                                    ) : (
                                    <></>
                                    )}
                                    {/* <p
                                    className="openai_link"
                                    v-show={show[0]}
                                    onClick={sampleTextCorporateCulture}
                                    >
                                    会社概要からサンプル文章を生成する
                                    </p> */}
                                </div>
                                {/* ) : (
                                <div className="load-area">
                                    <div className="load-text">
                                    <h2>サンプル文章の生成中です...</h2>
                                    <div className="load-spinner">
                                        <span className="spinner"></span>
                                    </div>
                                    </div>
                                </div>
                                )} */}
                                <button className='dFlex deleteBtn'onClick={() => {
                                    setCorporateCulture(
                                        appeal_recruiter_items_corporate_culture
                                    );
                                    deleteAppeal(0);
                                    }}>削除</button>
                            </div>
                            )}
                            {show[1] && (
                            <div>
                                <h3>1日の業務の流れ</h3>
                                {/* {!isApiLoadingBusinessFlow ? ( */}
                                <div className="load-area-warp">
                                    <p>
                                    <textarea
                                        cols={30}
                                        rows={10}
                                        value={appeal_recruiter_items_business_flow}
                                        onChange={(e) => setBusinessFlow(e.target.value)}
                                        v-show={show[1]}
                                    />
                                    </p>
                                    {appeal_recruiter_items_business_flow.length > 200 ? (
                                    <div className="error_box">
                                        <p className="error_text">
                                        {appeal_recruiter_items_business_flow.length -
                                            200}
                                        文字オーバー
                                        </p>
                                    </div>
                                    ) : (
                                    <></>
                                    )}
                                    {/* <p
                                    className="openai_link"
                                    v-show={show[1]}
                                    onClick={(e) => sampleTextBusinessFlow(e)}
                                    >
                                    採用情報からサンプル文章を生成する
                                    </p> */}
                                </div>
                                {/* ) : (
                                <div className="load-area">
                                    <div className="load-text">
                                    <h2>サンプル文章の生成中です...</h2>
                                    <div className="load-spinner">
                                        <span className="spinner"></span>
                                    </div>
                                    </div>
                                </div>
                                )} */}
                                <button className='dFlex deleteBtn'  onClick={() => {
                                    setBusinessFlow(appeal_recruiter_items_business_flow);
                                    deleteAppeal(1);
                                    }}>削除</button>
                            </div>
                            )}
                            {show[2] && (
                            <div>
                                <h3>先輩社員からのコメント </h3>
                                {/* {!isApiLoadingSuperiorMessage ? ( */}
                                <div className="load-area-warp">
                                    <p>
                                    <textarea
                                        cols={30}
                                        rows={10}
                                        value={appeal_recruiter_items_superior_message}
                                        onChange={(e) => setSuperiorMessage(e.target.value)}
                                        v-show={show[2]}
                                    />
                                    </p>
                                    {appeal_recruiter_items_superior_message.length >
                                    200 ? (
                                    <div className="error_box">
                                        <p className="error_text">
                                        {appeal_recruiter_items_superior_message.length -
                                            200}
                                        文字オーバー
                                        </p>
                                    </div>
                                    ) : (
                                    <></>
                                    )}
                                </div>
                                {/* ) : (
                                <div className="load-area">
                                    <div className="load-text">
                                    <h2>サンプル文章の生成中です...</h2>
                                    <div className="load-spinner">
                                        <span className="spinner"></span>
                                    </div>
                                    </div>
                                </div>
                                )} */}
                                <button className='dFlex deleteBtn' onClick={() => {
                                    setSuperiorMessage(
                                        appeal_recruiter_items_superior_message
                                    );
                                    deleteAppeal(2);
                                    }}>削除</button>
                            </div>
                            )}

                            {show[3] && (
                            <div>
                                <h3> キャリアパス</h3>
                                <div className="load-area-warp">
                                <p>
                                    <textarea
                                    cols={30}
                                    rows={10}
                                    value={appeal_recruiter_items_career_path}
                                    onChange={(e) => setCareerPath(e.target.value)}
                                    v-show={show[3]}
                                    />
                                </p>
                                {appeal_recruiter_items_career_path.length > 200 ? (
                                    <div className="error_box">
                                    <p className="error_text">
                                        {appeal_recruiter_items_career_path.length - 200}
                                        文字オーバー
                                    </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                </div>
                                <button className='dFlex deleteBtn' onClick={() => {
                                    setCareerPath(appeal_recruiter_items_career_path);
                                    deleteAppeal(3);
                                    }}>削除</button>
                            </div>
                            )}

                            {show[4] && (
                            <div>
                                <h3>
                                現場での合理的配慮例
                                </h3>
                                <p>
                                <textarea
                                    cols={30}
                                    rows={10}
                                    // onBlur={() => setShow(4)}
                                    // onKeyDown={(e) => e.key === 'Tab' && setShow(4)}
                                    value={appeal_recruiter_items_consideration_condition}
                                    onChange={(e) =>
                                    setConsiderationCondition(e.target.value)
                                    }
                                    v-show={show[4]}
                                />
                                </p>
                                {appeal_recruiter_items_consideration_condition.length >
                                200 ? (
                                <div className="error_box">
                                    <p className="error_text">
                                    {appeal_recruiter_items_consideration_condition.length -
                                        200}
                                    文字オーバー
                                    </p>
                                </div>
                                ) : (
                                <></>
                                )}
                                <button className='dFlex deleteBtn' onClick={() => {
                                    setConsiderationCondition(
                                        appeal_recruiter_items_consideration_condition
                                    );
                                    deleteAppeal(4);
                                    }}>削除</button>
                            </div>
                            )}

                            {show[5] && (
                            <div>
                                <h3>
                                採用担当メッセージ
                                
                                </h3>
                                {/* {!isApiLoadingHrMessage ? ( */}
                                <div className="load-area-warp">
                                    <p>
                                    <textarea
                                        cols={30}
                                        rows={10}
                                        value={appeal_recruiter_items_hr_message}
                                        onChange={(e) => setHrMessage(e.target.value)}
                                        v-show={show[5]}
                                    />
                                    </p>
                                    {appeal_recruiter_items_hr_message.length > 200 ? (
                                    <div className="error_box">
                                        <p className="error_text">
                                        {appeal_recruiter_items_hr_message.length - 200}
                                        文字オーバー
                                        </p>
                                    </div>
                                    ) : (
                                    <></>
                                    )}
                                    {/* <p
                                    className="openai_link"
                                    v-show={show[5]}
                                    onClick={sampleTextHrMessage}
                                    >
                                    採用情報からサンプル文章を生成する
                                    </p> */}
                                </div>
                                {/* ) : (
                                <div className="load-area">
                                    <div className="load-text">
                                    <h2>サンプル文章の生成中です...</h2>
                                    <div className="load-spinner">
                                        <span className="spinner"></span>
                                    </div>
                                    </div>
                                </div>
                                )} */}
                                <button className='deleteBtn dFlex'  onClick={() => {
                                    setHrMessage(appeal_recruiter_items_hr_message);
                                    deleteAppeal(5);
                                    }}>削除</button>
                            </div>
                            )}

                            {show[6] && (
                            <div>
                                <h3>動画</h3>
                                <div className="movie_box">
                                {!movie_path2 || isEditMoviePath ? (
                                    <>
                                    <label>
                                        Youtube動画のURLを入力してください
                                        <input
                                        type="text"
                                        value={movie_path2}
                                        placeholder="https://www.youtube.com/xxxxxx"
                                        onChange={(e) => setMoviePath2(e.target.value)}
                                        />
                                    </label>
                                    {/* <button onClick={EditMovie}>保存</button> */}
                                    </>
                                ) : (
                                    <>
                                    </>
                                )}
                                <button className='dFlex deleteBtn' onClick={() => {
                                    deleteAppeal(6);
                                    }}>削除</button>
                                </div>
                            </div>
                            )}
                    </div>
                    <div>
                        <h3>求める人物像<span style={{color:'red'}}>※最大5つまで選択可能です。</span></h3>
                            <div className='bordered'>
                                <div>
                                <div className="select_area_child">
                                    {idealCandidatesUnique &&
                                        idealCandidatesUnique.map((i: any, index: any) => (
                                        <label key={i.id}>
                                            <input
                                            type="checkbox"
                                            // checked={recruitmentCompatibilities}
                                            value={i.id}
                                            defaultChecked={recruitmentCompatibilities.includes(
                                                i.id
                                            )}
                                            checked={recruitmentCompatibilities.includes(i.id)}
                                            onChange={(e) =>
                                                changeRecruitmentCompatibilities(i.id)
                                            }
                                            disabled={
                                                recruitmentCompatibilities.length >= 5 &&
                                                !recruitmentCompatibilities.includes(i.id)
                                            }
                                            />
                                            {i.name}
                                        </label>
                                        ))}
                                    </div>
                                </div>  
                            </div>
                    </div>
                    <div>
                        <h3>合理的配慮</h3>
                        <div className='bordered'>
                            <div className="select_area _flex">
                                {considerationConditionTypes &&
                                considerationConditionTypes.map(
                                    (i: any, index: any) => (
                                    <div key={i.id}>
                                        <div className="consideration_condition_type">
                                        <a onClick={(e) => toggle(index)}>{i.name}</a>
                                        </div>
                                        {isOpenList[index] ? (
                                        <div className="select_area_child">
                                            {filterConsiderationConditionsHandicap(
                                            i.id
                                            ).map((j: any) => (
                                            <label key={j.id}>
                                                <input
                                                type="checkbox"
                                                v-model="mySelected"
                                                defaultChecked={selectedConsiderationConditionsId.includes(
                                                    j.id
                                                )}
                                                value={j.id}
                                                onChange={(e) => updateValue1(j, j.id)}
                                                />
                                                {j.name}
                                            </label>
                                            ))}
                                        </div>
                                        ) : (
                                        <></>
                                        )}
                                    </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3>職場環境</h3>
                        <div className='bordered'>
                        <div className="consideration_area">
                            <div className="edit_area">
                                <div className="select_area _flex">
                                    <div className="select_area_child">
                                    {workEnvironments.map((i: any) => (
                                        <label key={i.id}>
                                        <input
                                            type="checkbox"
                                            checked ={recruitmentWorkEnvironments.includes(
                                                i.id
                                            )}
                                            value={i.id}
                                            onChange={(e) =>
                                            changeRecruitmentWorkEnvironments(i.id)
                                            }
                                        />
                                        {i.name}
                                        </label>
                                    ))}
                                    </div>
                                </div>
                                <div className="free_tag_box">
                                    <div className="free_tag_input_area">
                                        <input
                                            type="text"
                                            placeholder="一覧にないタグを入力"
                                            value={environmentsText}
                                            onChange={(e) => setEnvironmentsText(e.target.value)}
                                            className="input-field"
                                        />
                                        <button className="add-button" onClick={handleButtonClick}>
                                            追加
                                        </button>
                                    </div>
                                    <ul className="free_tag_display_area">
                                    {recruitmentWorkEnvironmentsText.map(
                                        (data: any, idx: any) => (
                                        <li key={idx}>
                                            <a onClick={(e) => deleteTag(idx)}>
                                            <Icon
                                                path={mdiCloseCircle}
                                                size={0.5}
                                                color="#00a0e9"
                                            />
                                            </a>
                                            {data}
                                        </li>
                                        )
                                    )}
                                    </ul>
                                </div>
                                {/* <button
                                    onClick={(e) => setIsWorkEnvironment(false)}
                                    v-show="isWorkEnvironment"
                                >
                                    完了
                                </button> */}
                            </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3>一覧画面用 <span style={{color:'red'}}>※１つは必須</span> </h3>
                        <div className='bordered'>
                            <div>
                                <label htmlFor="">業務内容を1行20文字以内で記入 <span style={{color:'red'}}>※必須</span></label>
                                <div>
                                <ul>
                                    <li key={0}>
                                    <input
                                        type="text"
                                        name="previewText0"
                                        value={previewDisplayTexts[0]}
                                        placeholder="業務内容を箇条書きで記入してください"
                                        onChange={(e) => changePreviewDisplayText(e)}
                                    />
                                    {isErrorPreviewDisplayText0 ? (
                                        <div className="error_box">
                                        <p className="error_text">
                                            {previewDisplayText0MaxLenError} 文字オーバー
                                        </p>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                     {!showSubInputNew1 ? <button className='newsBtnToggle'  onClick={() => setShowInputSubNew1(true)}>複数を追加</button>: <></>}
                                    </li>
                                    {showSubInputNew1 ? 
                                    <li key={1}>
                                    <input
                                        type="text"
                                        name="previewText1"
                                        value={previewDisplayTexts[1]}
                                        placeholder="業務内容を箇条書きで記入してください"
                                        onChange={(e) => changePreviewDisplayText(e)}
                                    />
                                    {isErrorPreviewDisplayText1 ? (
                                        <div className="error_box">
                                        <p className="error_text">
                                            {previewDisplayText1MaxLenError} 文字オーバー
                                        </p>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                     {!showSubInputNew2 ?  <button className='newsBtnToggle'  onClick={() => setShowInputSubNew2(true)}>複数を追加</button>: <></>}
                                    </li>
                                    : <></>}
                                    {showSubInputNew2 ?
                                    <li key={2}>
                                    <input
                                        type="text"
                                        name="previewText2"
                                        value={previewDisplayTexts[2]}
                                        placeholder="業務内容を箇条書きで記入してください"
                                        onChange={(e) => changePreviewDisplayText(e)}
                                    />
                                    {isErrorPreviewDisplayText2 ? (
                                        <div className="error_box">
                                        <p className="error_text">
                                            {previewDisplayText2MaxLenError} 文字オーバー
                                        </p>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                     {!showSubInputNew3 ? <button className='newsBtnToggle'  onClick={() => setShowInputSubNew3(true)}>複数を追加</button>: <></>}
                                    </li>
                                    : <></>}
                                    {showSubInputNew3 ?
                                    <li key={3}>
                                    <input
                                        type="text"
                                        name="previewText3"
                                        value={previewDisplayTexts[3]}
                                        placeholder="業務内容を箇条書きで記入してください"
                                        onChange={(e) => changePreviewDisplayText(e)}
                                    />
                                    {isErrorPreviewDisplayText3 ? (
                                        <div className="error_box">
                                        <p className="error_text">
                                            {previewDisplayText3MaxLenError} 文字オーバー
                                        </p>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    </li>
                                    : <></>}
                                    
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3>画像</h3>
                        <div className='bordered'>
                            <label htmlFor="">サムネイル画像 <span style={{color:'red'}}>※必須</span></label>
                        {images &&
                            images.map((i: any, index: any) => {
                            return (
                                <div key={index} className='adminImageBox'>
                                    {showImage[index] ? 
                                    <>
                                    <div key={"main" + index} className="image_dFlex">
                                            {/* <p className="advice_txt">
                                                {imagesAdvices[index]}
                                            </p> */}
                                            <div>
                                                {(i.url == null || i.url == '') ? <img src="/assets/img/no-image.jpg"alt=""/> : <img src={i.url}></img>}
                                            </div>
                                            <div>
                                            <label>
                                                <div>
                                                <input ref={(el) => (fileInputRefs.current[index] = el)}type="file" id="fileInput" accept="image/*" onChange={(event) => setImage(index, event)}/>
                                                <button
                                                    className='custom-file-upload'
                                                    onClick={() => {
                                                        if (fileInputRefs.current[index]) {
                                                        fileInputRefs.current[index]?.click();
                                                        }
                                                    }}
                                                    >
                                                    ファイルを選択
                                                    </button>
                                                </div>
                                            </label>
                                            {i.url != "" ?
                                                <> 
                                                    <div className='dFlex'>
                                                    <input
                                                        className="swComment"
                                                        type="text"
                                                        value={i.comment || ""}
                                                        placeholder="画像説明文を入力"
                                                        onChange={(event) => {
                                                        handleCommentChange(event, index);
                                                        }}
                                                    ></input>
                                                    <button className='fileDeleteBtn' onClick={(e) => delImage(i.priority)}>削除</button>
                                                    </div>
                                                </>
                                            :<></>}
                                            </div>
                                        </div>
                                        {index != 4 ?
                                         <div className='newImage' onClick={() =>otherImage(index)}>画像を複数追加</div>
                                        :<></>}
                                    </>
                                    : <></>}
                                </div>
                            );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display:'flex',position:'relative', alignItems:'center', justifyContent:'center',marginTop:'20px'}}>
                <button className='createBtn' onClick={createRecruiter} style={{padding:'5px 100px', fontSize:'18px'}}>更新</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AdminOpenJobEdit;