import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../redax/reducers/rootReducer";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import { fetchApplicantsRequest } from "../../../../redax/actions/applicantsActions/applicantsActions";
import { fetchScoutsEntryRequest } from "../../../../redax/actions/scoutsEntryActions/scoutsEntryActions";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import Loader from "../../../loader/loader";


function ScoutEntry() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [resourceId, setResourceId] = useState(null);
    const loginData = useSelector((state: RootState) => state.login);
    const applicantsData = useSelector((state: RootState) => state.applicants);
    const entryData = useSelector((state: RootState) => state.entry);
    const [familyName, setFamylyName] = useState(null);
    const [loadScreen, setLoadScreen] = useState(true);
    const [saveTemplate, setSaveTemplate] = useState(false);
    const [textPlaceholder, setTextPlaceholder] = useState('はじめまして。株式会社○○の採用担当です。\n\n○○様のご経歴を拝見し、〇〇という可能性を感じ、ご連絡させていただきました。\n\n弊社は〇〇〇〇（企業文化や理念）を大切にしており、現在〇〇〇〇（事業内容）を展開しております。\n〇〇〇〇（職場の雰囲気）な職場となっております。\n\n今回、〇〇という業務をお願いしたいと考えております。\n\nまずは一度お会いし、ざっくばらんにお話できればと思っております。\nお会いできることを楽しみにしています。');
    const [templateName, setTemplateName] = useState('');
    const [content, setContent] = useState('');
    const [applicants, setList] = useState<any>([]);
    const [recruitments, setRecruitments] = useState<any>([]);
    const [scoutTemplates, setScoutsTemplates] = useState<any>([]);
    const [scoutTemplate2, setScoutsTemplate] = useState<any>(0);
    const [recruitmentIds, setRecruitmentIds] = useState<any>([]);
    const [load1, setLoad1] = useState(false);
    const [load2, setLoad2] = useState(false);
    const [load3, setLoad3] = useState(false);
    const location = useLocation();
    const data = location.state;

    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
          dispatch(fetchLoginRequest('', '',''))
        } else (
            navigate('/recruiter/login/')
        )
      }, [dispatch]);


    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/recruiter/init/');
            }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            if(data != null) {
                if(data.content) {
                    setContent(data.content);
                    setTemplateName(data.templateName);
                    setSaveTemplate(data.saveTemplate);
                    setRecruitmentIds(data.recruitmentIds);
                }
                //console.log(data.applicants);
                setList(data.applicants);
            }
            dispatch(fetchScoutsEntryRequest(0, 'template', 'get'));
            setLoad1(true);
        }
    }, [loginData.pending]);

    useEffect(() => {
        if (entryData.pending) {
            if (load1) {
                setLoad1(false);
                setScoutsTemplates(entryData.entry.company_scout_templates);
                dispatch(fetchScoutsEntryRequest(0, 'scout', 'get'));
                setLoad2(true);
            }              
            if (load2) {
                setLoadScreen(false);     
                // setRecruitments(entryData.entry.recruitments);
                let c = [];
                if (entryData.entry && entryData.entry.recruitments) {
                    for (let index = 0; index < applicants.length; index++) {
                        if(applicants[index].scouted_recruitments.length != 0) {
                            for (let j = 0; j < applicants[index].scouted_recruitments.length; j++) {
                                c.push(applicants[index].scouted_recruitments[j].id);
                            }
                        }
                    } 
                }                       
                if(c.length == 0) {
                    setRecruitments(entryData.entry.recruitments);
                } else {
                    let r = [];
                    let ch = false;
                    for (let index = 0; index < entryData.entry.recruitments.length; index++) {
                        ch = false;
                        for (let j = 0; j < c.length; j++) {
                            if(entryData.entry.recruitments[index].id === c[j]) {
                                ch = true;
                            }
                        }
                        if(!ch) {
                            r.push(entryData.entry.recruitments[index]);
                        }
                    }
                    setRecruitments(r);
                }
            }
            if (load3) {
                setLoadScreen(false);
                setLoad2(false);      
                setLoad3(false);            
            } else if(entryData.error != null) {
                setLoadScreen(false);
                // navigate('/error?code=500');
            }
        }
    }, [entryData.pending]);
    
    function logout() {
        if (localStorage.entryFlag) {
            localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
            localStorage.removeItem('jobParachannelAuthToken')
            navigate('/');
            window.location.reload();
        }
    }

    function confirmDeleteTemplate(id: any) {
        if (window.confirm('テンプレートを削除しますか？')) {
          // TODO
          // API待ち
          dispatch(fetchScoutsEntryRequest(id, 'template', 'delete'));
          setScoutsTemplates(scoutTemplates.filter((v: any) => v.id != id));
        }
    }

    function selectedTemplateId (value: any) {
        const selectedTemplate = scoutTemplates.find((v: any) => v.id == value);
        if(selectedTemplate != undefined) {
            setContent(selectedTemplate.content);
        }
        console.log(value);
        setScoutsTemplate(value);
    }
  
    function validates() {
        let result = ''
        if (recruitmentIds.length == 0) {
          result += 'スカウトする求人\n'
        }
        if (!content) {
          result += '本文\n'
        }
        if (saveTemplate && !templateName) {
          result += 'テンプレート名\n'
        }
  
        return result
    }

    function changeRecruitmentIds(e: any, id: any) {
        let c = [];
        for(let i = 0; i < recruitmentIds.length; i++) {
            c.push(recruitmentIds[i]);
        }
        let chk = false;
        let index = 0;
        for(let i = 0; i < c.length; i++) {
            if(c[i] == id) {
                chk = true;
                index = i;
            }
        }
        if(e.target.checked) {
            if(!chk) {
                c.push(id);
            }
        } else {
            if(chk) {
                c.splice(index, 1);
            }
        }
        setRecruitmentIds(c);
    }

    function confirm() {
        const validatedText = validates()
        if (validatedText) {
            alert('未入力項目があります\n\n' + validatedText)
            return false
        }
        const selectedRecruitmentNames = [];
        for(let i = 0; i < recruitmentIds.length; i++) {
            for(let j = 0; j < recruitments.length; j++) {
                if(recruitmentIds[i] == recruitments[j].id) {
                    selectedRecruitmentNames.push(recruitments[j].job_title);
                }
            }            
        }
        let forms = {content: content, selectedRecruitmentNamesStr: selectedRecruitmentNames.join('、'), templateName: templateName, saveTemplate: saveTemplate,applicants: applicants, recruitmentIds: recruitmentIds,queryParamString: data ? data.queryParamString : null};
        
        navigate('/recruiter/scouts/confirm/', {
            state: forms,
        });
    }

    function loadBack() {
        let l = data.queryParamString != null ? data.queryParamString : ''
        window.location.href = '/recruiter/scouts/?' + l;
    }
      

    return (
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className ="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">スカウト</h2>
                <ul>
                <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            <div id="scouts" className="entry">
                <form>
                {/* <!-- スカウト対象 --> */}
                <div className="cont1">
                    <h3>スカウト対象：{ applicants.length }名</h3>
                    <ul>
                    {applicants.map((applicant: any,index: any ) => (
                    <li key={applicant.id}>
                        <div>
                        <img src={applicant.face_photo} alt={applicant.family_name + applicant.first_name}/></div>
                        <p>ID:{ applicant.id }</p>
                    </li>
                    ))}
                    </ul>
                </div>

                <div className="cont2">
                    <h3><span>Step1</span>スカウトする求人を選択してください。</h3>
                    <div className="checkbox">
                    {recruitments && recruitments.map((recruitment: any,index: any ) => (
                    <div key={recruitment.id}>
                        <input id={'scout' + recruitment.id} type="checkbox" v-model="recruitmentIds" value={recruitment.id} checked={recruitmentIds.includes(recruitment.id)} onChange={(e) => changeRecruitmentIds(e, recruitment.id)} />
                        <label htmlFor={'scout' + recruitment.id}>{ recruitment.job_title } (求人ID: {recruitment.id})</label>
                    </div>
                    ))}
                    </div>
                    <a href="/recruiter/recruitments/">求人一覧を見る</a>
                </div>
                <div className="cont3">
                    <h3><span>Step2</span>メッセージを作成しましょう。</h3>
                    <dl>
                    <dt>送信テンプレート：</dt>
                    <dd>
                    {scoutTemplates.map((scoutTemplate: any,index: any ) => (
                        <label key={index}><input type="radio" checked={scoutTemplate2 == scoutTemplate.id} value={scoutTemplate.id} onChange={() => selectedTemplateId(scoutTemplate.id)} />{ scoutTemplate.title }
                        <span onClick={() => confirmDeleteTemplate(scoutTemplate.id)}>×削除</span></label>
                    ))}
                    </dd>
                    </dl>
                    <textarea v-model="content" placeholder={textPlaceholder} value={content} onChange={(e) => setContent(e.target.value)}></textarea>
                    <label><input v-model="saveTemplate" type="checkbox" checked={saveTemplate} onChange={() => setSaveTemplate(!saveTemplate)} value="今回のメッセージを送信テンプレートに保存する"/>今回のメッセージを送信テンプレートに保存する</label>
                    {saveTemplate ?
                    <dl>
                    <dt>テンプレート名</dt>
                    <dd><input v-model="templateName" value={templateName} type="text" onChange={(e) => setTemplateName(e.target.value)}/></dd>
                    </dl>
                    : <></>}
                </div>
                <div className="btn_box">
                    <a href="#" onClick={loadBack} className="btn back">戻る</a>
                    <a href="javascript:void(0)" onClick={() => confirm()} className="btn orange">確認する</a>
                </div>
                </form>
            </div>
        </section>
    </main>
    );
  }
  
  export default ScoutEntry;