import {adminCompanyListTypes } from "../../ActionTypes/adminCompanyListTypes";
import {  AdminCompanyListActions, AdminCompanyListState } from "../../types/type";


const initialState: AdminCompanyListState = {
  pending: false,
  companies: [],
  pagenation: {},
  error: null,
};

export default (state = initialState, action: AdminCompanyListActions,) => {
  switch (action.type) {
    case adminCompanyListTypes.FETCH_ADMIN_COMPANY_LIST_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminCompanyListTypes.FETCH_ADMIN_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        pending: true,
        companies: action.payload.companies,
        pagenation: action.payload.pagenation,
        error: null
      };
    case adminCompanyListTypes.FETCH_ADMIN_COMPANY_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        applicant: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
