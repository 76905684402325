
import { emailChangeTokenTypes } from "../../ActionTypes/emailChangeTokenTypes";
import { EmailChangeTokenActions, EmailChangeTokenState } from "../../types/type";

const initialState: EmailChangeTokenState = {
  pending: false,
  token: [],
  error: null,
};

export default (state = initialState, action: EmailChangeTokenActions,) => {
  switch (action.type) {
    case emailChangeTokenTypes.FETCH_EMAIL_CHANGE_TOKEN_REQUEST:
      return {
        ...state,
        pending: false
      };
    case emailChangeTokenTypes.FETCH_EMAIL_CHANGE_TOKEN_SUCCESS:
      return {
        ...state,
        pending: true,
        token: action.payload.token,
        error: null
      };
    case emailChangeTokenTypes.FETCH_EMAIL_CHANGE_TOKEN_FAILURE:
      return {
        ...state,
        pending: false,
        token: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
