import { forwardRef } from "react";

function ProfileTab(props: { index: any; }, refs:any) {
    let index: any = [];
    if(props) {
        index  = props.index;
    }
    const queryParams = new URLSearchParams(window.location.search);
    if(queryParams.get('tab') != null || queryParams.get('next') != null) {
        if(index == 4) {
            setTimeout(() => {
                refs.current?.scrollBy({left: 3});
                refs.current?.scrollIntoView({behavior:"smooth", block:"start"});
            }, 0);
        }    
        if(index == 5 || index == 6 || index == 7) {
            setTimeout(() => {
                refs.current?.scrollBy({left: 100});                
                refs.current?.scrollIntoView({behavior:"smooth", block:"start"});
            }, 0);
        }      
        // if(index == 6) {
        //     setTimeout(() => {
        //         refs.current?.scrollBy({left: 15});                
        //         refs.current?.scrollIntoView({behavior:"smooth", block:"start"});
        //     }, 0);
        // }   
        // if(index == 7) {
        //     setTimeout(() => {
        //         refs.current?.scrollBy({left: 25});                
        //         refs.current?.scrollIntoView({behavior:"smooth", block:"start"});
        //     }, 0);
        // }   
    }

    return (
        <div ref={refs} className="resume_tab_list" id="test">
            <ul>
            <li className={index == 1 ? "active" : ""}>
                <a href="/mypage/profile/" className={index == 1 ? "nuxt-link-exact-active nuxt-link-active" : ""}>プロフィール</a>
            </li>
            <li className={index == 2 ? "active" : ""}>
                <a href="/mypage/profile2/" className={index == 2 ? "nuxt-link-exact-active nuxt-link-active" : ""}>障がいについて</a>
            </li>
            <li className={index == 3 ? "active" : ""}>
                <a href="/mypage/profile3/" className={index == 3 ? "nuxt-link-exact-active nuxt-link-active" : ""}>職務経歴</a>
            </li>
            <li className={index == 4 ? "active" : ""}>
                <a href="/mypage/profile4/" className={index == 4 ? "nuxt-link-exact-active nuxt-link-active" : ""}>スキル</a>
            </li>
            <li className={index == 5 ? "active" : ""}>
                <a href="/mypage/profile5/" className={index == 5 ? "nuxt-link-exact-active nuxt-link-active" : ""}>自己PR</a>
            </li>
            <li className={index == 6 ? "active" : ""}>
                <a href="/mypage/profile6/" className={index == 6 ? "nuxt-link-exact-active nuxt-link-active" : ""}>希望条件</a>
            </li>
            <li className={index == 7 ? "small active" : "small"}>
                <a href="/mypage/profile7/" className={index == 7 ? "nuxt-link-exact-active nuxt-link-active" : ""}>企業に伝えたい項目</a>
            </li>
            </ul>
        </div>
    );
  }
  
  export default forwardRef(ProfileTab);