import { adminRecruitmentEditTypes } from "../../ActionTypes/adminRecruitmentEditTypes";
import {FetchAdminRecruitmentEditFailure, FetchAdminRecruitmentEditFailurePayload, FetchAdminRecruitmentEditRequest, FetchAdminRecruitmentEditSuccess, FetchAdminRecruitmentEditSuccessPayload } from "../../types/type";

export const fetchAdminRecruitmentEditRequest = (id: any): FetchAdminRecruitmentEditRequest => ({
  type: adminRecruitmentEditTypes.FETCH_ADMIN_RECRUITMENT_EDIT_REQUEST,
  id
});

export const fetchAdminRecruitmentEditSuccess = (
  payload: FetchAdminRecruitmentEditSuccessPayload
): FetchAdminRecruitmentEditSuccess => ({
  type: adminRecruitmentEditTypes.FETCH_ADMIN_RECRUITMENT_EDIT_SUCCESS,
  payload
});

export const fetchAdminRecruitmentEditFailure = (
  payload: FetchAdminRecruitmentEditFailurePayload
): FetchAdminRecruitmentEditFailure => ({
  type: adminRecruitmentEditTypes.FETCH_ADMIN_RECRUITMENT_EDIT_FAILURE,
  payload
});