
import { masterInfoTypes } from "../../ActionTypes/masterInfoTypes";
import {  MasterInfoActions, MasterInfoState } from "../../types/type";

const initialState: MasterInfoState = {
  pending: false,
  masterInfo: [],
  error: null,
};

export default (state = initialState, action: MasterInfoActions,) => {
  switch (action.type) {
    case masterInfoTypes.FETCH_MASTER_INFO_REQUEST:
      return {
        ...state,
        pending: false
      };
    case masterInfoTypes.FETCH_MASTER_INFO_SUCCESS:
      return {
        ...state,
        pending: true,
        masterInfo: action.payload.masterInfo,
        error: null
      };
    case masterInfoTypes.FETCH_MASTER_INFO_FAILURE:
      return {
        ...state,
        pending: false,
        masterInfo: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
