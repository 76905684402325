import { recruitmentsCompanyTypes } from "../../ActionTypes/recruitmentsCompanyTypes";
import { RecruitmentsCompanyActions, RecruitmentsCompanyState, } from "../../types/type";


const initialState: RecruitmentsCompanyState = {
  pending: false,
  company: [],
  error: null,
};

export default (state = initialState, action: RecruitmentsCompanyActions,) => {
  switch (action.type) {
    case recruitmentsCompanyTypes.FETCH_RECRUIMENTS_COMPANY_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitmentsCompanyTypes.FETCH_RECRUIMENTS_COMPANY_SUCCESS:
      return {
        ...state,
        pending: true,
        company: action.payload.company,
        error: null
      };
    case recruitmentsCompanyTypes.FETCH_RECRUIMENTS_COMPANY_FAILURE:
      return {
        ...state,
        pending: false,
        company: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
