import { applicantDecideTypes } from "../../ActionTypes/applicantDecideTypes";
import { FetchApplicantDecideFailure,
   FetchApplicantDecideFailurePayload,
    FetchApplicantDecideRequest, 
    FetchApplicantDecideSuccess,
     FetchApplicantDecideSuccessPayload } from "../../types/type";



export const fetchApplicantDecideRequest = (id:any, data: any, types: any): FetchApplicantDecideRequest => ({
  type: applicantDecideTypes.FETCH_APPLICANT_DECIDE_REQUEST,
  id,
  data,
  types,
});

export const fetchApplicantDecideSuccess = (
  payload: FetchApplicantDecideSuccessPayload,
): FetchApplicantDecideSuccess => ({
  type: applicantDecideTypes.FETCH_APPLICANT_DECIDE_SUCCESS,
  payload
});

export const fetchApplicantDecideFailure = (
  payload: FetchApplicantDecideFailurePayload
): FetchApplicantDecideFailure => ({
  type: applicantDecideTypes.FETCH_APPLICANT_DECIDE_FAILURE,
  payload
});
