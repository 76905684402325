import React, { useState, useRef, useEffect, Component, Fragment } from "react";
import './recruitmentsentry.scss'
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginRequest } from "../../redax/actions/loginActions/loginActions";
import { RootState } from "../../redax/reducers/rootReducer";
import { fetchApplicantsRequest } from "../../redax/actions/applicantsActions/applicantsActions";
import { fetchRegionsRequest } from "../../redax/actions/regionsActions/regionsActions";
import { useParams } from "react-router-dom";
import { fetchRecruitmentsDetailRequest } from "../../redax/actions/recruitmentsDetailActions/recruitmentsDetailActions";
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchHandicapsRequest } from "../../redax/actions/handicapsActions/handicapsActions";
import { fetchChangeUpdateApplicantsRequest } from "../../redax/actions/changeUpdateApplicantsActions/changeUpdateApplicantsActions";
import Loader from "../loader/loader";
import { fetchPostCodeRequest } from '../../redax/actions/postcodeActions/postcodeActions';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

let recAppeal: any = [];

function RecruimentsEntry() {
  let textValue = 'textValue';
  const [applicant, setApplicants] = useState<any[]>([]);  
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen3, setModalOpen3] = useState(false);
  const [wordExp, setWordExp] = useState(false);
  const [excelExp, setExcelExp] = useState(false);
  const dispatch = useDispatch();
  const loginData = useSelector((state: RootState) => state.login);
  const applicants = useSelector((state: RootState) => state.applicants);
  const regions = useSelector((state: RootState) => state.regions);
  const recruitmentsDetail = useSelector((state: RootState) => state.recruitmentsDetail);
  const { postcode } = useSelector((state: RootState) => state.postcode);
  const { id } = useParams();
  const [company_name, setCompany_name] = useState();
  const [job_title, setJob_title] = useState();
  const [family_name, setFamily_name] = useState();
  const [first_name, setFirst_name] = useState();
  const [family_name_kana, setFamily_name_kana] = useState();
  const [first_name_kana, setFirst_name_kana] = useState();
  const [birthday, setBirthday] = useState<any>();
  const [postcodes, setPostcode] = useState('');
  const [graduation_date, setGraduationDate] = useState('');
  const [isBlankgraduationDate, setisBlankgraduationDate] = useState<any>(null);
  const [new_graduate, setNewGraduate] = useState();
  const [career_count_id, setCareerCountId] = useState();
  const [company, setCompany] = useState('');
  const [industry_id, setIndustryId] = useState(null);
  const [job_category_id, setJobCategoryid] = useState(null);
  const [arrival_date, setArrivalDate] = useState('');
  const [departure_date, setDepartureDate] = useState<any>(null);
  const [business_content, setBusinessContent] = useState(null);
  const [special_content, setSpecialContent] = useState();
  const [search_policy_id, setSearchPolicyId] = useState();
  const [compatibilities, setCompatibilities] = useState<any[]>([]);
  const [working_hour_id, setWorkingHourId] = useState();
  const [work_from_home_id, setWorkFromHomeId] = useState();
  const [prefecture_id_1, setPrefectureId1] = useState();
  const [prefecture_id_2, setPrefectureId2] = useState();
  const [commuting_methods, setCommutingMethods] = useState();
  const [employment_statuses_id, setEmploymentStatusId] = useState<number[]>([]);
  const [job_category_id_1, setJobCategoryid1] = useState();
  const [job_category_id_2, setJobCategoryid2] = useState();
  const [annual_income_id, setAnnualIncomeId] = useState();
  const [disability_certificate_status_id, setDisabilityCertificateStatusId] = useState<any>(null);
  const [applicantConsiderationConditionsRequiredExists, setApplicantConsiderationConditionsRequiredExists] = useState<any>(null);
  const [considerationConditionsMasterData, setConsiderationConditionsMasterData] = useState<any[]>([]);
  const [applicantHandicapQualifications, setApplicantHandicapQualifications] = useState<any[]>([]);
  const [considerationRequiredSlide, setConsiderationRequiredSlide] = useState(0);
  const [readOnlyConsiderationRequired, setReadOnlyConsiderationRequired] = useState(false);
  const [AreaOpenConsiderationRequired, setAreaOpenConsiderationRequired] = useState(false);
  const [considerationConditionsRequiredModal, setConsiderationConditionsRequiredModal] = useState(false); 
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [process, setProcess] = useState('');
  const [characteristics_and_countermeasures, setCharacteristics] = useState('');
  const [consideration_conditions_required_exists, setConsiderationConditionsRequiredExists] = useState<any>(null);
  const navigate = useNavigate(); 
  const [face_photo1, setFacePhoto1] = useState<any>(null);
  const [face_photo, setFacePhoto] = useState<any>(null);
  const [address, setAddress] = useState(String);
  const [prefecture_name, setPrefecture_name] = useState(String);
  const [prefecture_id, setPrefecture_id] = useState<any>(null);
  const [city_name, setCity_name] = useState(String);
  const [city_id, setCity_id] = useState<any>(null);
  const [email, setEmail] = useState();
  const [mobile_number, setMobile_number] = useState('');
  const [final_education_id, setFinal_education_id] = useState<any>();
  const [oa_skills, setOaskills] = useState<any[]>([]);
  const [qualification_categories, setQualificationCategories] = useState<any[]>([]);
  const [qualifications, setQualifications] = useState<any[]>([]);
  const [job_category_types, setJobCategoryTypes] = useState<any[]>([]);
  const [job_categories, setJobCategories] = useState<any[]>([]);
  const [languages, setLanguages] = useState<any[]>([]);
  const [levels, setLevels] = useState<any[]>([]);
  const [industries, setIndustries] = useState<any[]>([]);
  const [annual_income, setAnnualIncome] = useState<any[]>([]);
  const [employment_statuses, setEmploymentstatuses] = useState<any[]>([]);
  // const [handicap_qualifications, setHandicapQualifications] = useState<any[]>([]);
  const [final_educations, setFinalEducations] = useState<any[]>([]);
  const [classEr1, setClassEr1] = useState(String);
  const [classEr2, setClassEr2] = useState(String);
  const [classEr3, setClassEr3] = useState(String);
  const [classEr4, setClassEr4] = useState(String);
  const [classEr5, setClassEr5] = useState(String);
  const [classEr6, setClassEr6] = useState(String);
  const [classEr7, setClassEr7] = useState(String);
  const [classEr8, setClassEr8] = useState('zip');
  const [classEr9, setClassEr9] = useState('address');
  const [classEr10, setClassEr10] = useState('');
  const [handicaps, setHandicaps] = useState<any[]>([]);
  const [handicap_types, setHandicapTypes] = useState<any[]>([]);
  const [disability_certificate_statuses, setDisabilityCertificateStatus] = useState<any[]>([]);
  const [disability_certificates, setDisabilityCertificates] = useState<any[]>([]);
  const [resourceId, setResourceId] = useState(null);
  const [language_id_1, setLanguageId1] = useState<any[]>([]);
  const [language_id_2, setLanguageId2] = useState<any[]>([]);
  const [language_3, setLanguage3] = useState<any[]>([]);
  const [level_id_1, setLevelId1] = useState<any[]>([]);
  const [level_id_2,setLevelId2] = useState<any[]>([]);
  const [word, setWord] = useState<any[]>([]);
  const [excel, setExcel] = useState<any[]>([]);
  const [design, setDesign] = useState<any[]>([]);
  const [program, setProgram] = useState<any[]>([]);
  const [qualification_id_1, setQualificationsId1] = useState<any[]>([]);
  const [qualification_id_2, setQualificationsId2] = useState<any[]>([]);
  const [qualification_3, setQualifications3] = useState<any[]>([]);
  const [word_experience, setWordExperience] = useState(false);
  const [excel_experience, setExcelExperience] = useState(false);
  const [job_category_1, setJobCategory1] = useState(null);
  const [job_category_2, setJobCategory2] = useState(null);
  const [job_category_3, setJobCategory3] = useState(null);
  const [job_career_appeals, setJobCareerAppeals] = useState<any[]>([]);
  const [experience_job_category, setExperienceJobCategory] = useState<any[]>([]);
  const [qualification, setQualification] = useState<any[]>([]);
  const [language_study, setLanguageStudy] = useState<any[]>([]);
  const [employment_support_agency, setEmploymentSupportAgency] = useState<any[]>([]);
  const [self_pr, setSelfPr] = useState<any[]>([]);  
  const [selfPR, setSelfPR] = useState('');
  const [job_career_recent, setJobCareerRecent] = useState<any[]>([]);
  const [recentFlg, setRecentFlg] = useState(false);
  const [firstRecentFlg, setFirstRecentFlg] = useState(false);
  const [handiFlg, setHandiFlg] = useState(false);
  const [finalEducationFlg, setFinalEducationFlg] = useState(false);
  const [employmentstatusesFlg, setEmploymentstatusesFlg] = useState(false);
  const [annualIncomeFlg, setAnnualIncomeFlg] = useState(false);
  const [graduationYearFlg, setGraduationYearFlg] = useState(false);
  const [appeal_item_1, setAppeal_item_1] = useState<number>(0);
  const [appeal_item_2, setAppeal_item_2] = useState<number>(0);
  const [appeal_item_3, setAppeal_item_3] = useState<number>(0);
  const [appeals, setAppeals] = useState<any[]>([]);
  const [consideration_conditions_required, setConsiderationConditionsRequired] = useState<any[]>([]);
  const [consideration_condition_supplemental, setConsiderationConditionSupplemental] = useState('');
  const [considerationConditionSupplementalMaxlengthError, setConsiderationConditionSupplementalMaxLengthError] = useState(false);
  const [considerationConditionSupplementalMaxlengthCount, setConsiderationConditionSupplementalMaxLengthCount] = useState(0);
  const pageTitle = `${job_title}/${company_name}への｜エントリー｜パラちゃんねる`;
  document.title = pageTitle;
  const [loadScreen, setLoadScreen] = useState(true);
  const [appealsId, setAppealsId] = useState<any[]>([]);
  const [pCode,setPCode] = useState('');
  const [selectedAppealItemId1, setSelectedAppealItemId1] = useState(false);
  const [selectedAppealItemId2, setSelectedAppealItemId2] = useState(false);
  const [selectedAppealItemId3, setSelectedAppealItemId3] = useState(false);
  const [currentAppeal, setCurrentAppeal] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [yearRange, setYearRange] = useState<any>(null);
  let appAppeal:any = [];
  const [currentSlide, setCurrentSlide] = useState(0);  
  const [years, setYears] = useState<number[]>([]);
  const [years2, setYears2] = useState<number[]>([]);
  const [year, setYear] = useState<any>(null);
  const [month, setMonth] = useState<any>(null);
  const [end_month, setEndMonth] = useState<any>(null);
  const [endYear, setEndYear] = useState<any>(null);
  const [year_1, setYear1] = useState('');
  const [year_2, setYear2] = useState('');
  const [month_1, setMonth1] = useState('');
  const [month_2, setMonth2] = useState('');
  const [jobCareerAppealsArrivalYear, setJobCareerAppealsArrivalYear] = useState('');
  const [jobCareerAppealsArrivalMonth, setJobCareerAppealsArrivalMonth] = useState('');
  const [jobCareerAppealsDepartureYear, setJobCareerAppealsDepartureYear] = useState('');
  const [jobCareerAppealsDepartureMonth, setJobCareerAppealsDepartureMonth] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [isBlankAddress, setIsBlankAddress] = useState(false);
  const [trainingContentError, setTrainingContentError] = useState(false);
  const [trainingContentErrorCount, setTrainingContentErrorCount] = useState<any>(null);
  const [over, setOver] = useState<any>(null);
  const [isValid, setValid] = useState(false);
  const [readOnlyProcess, setReadOnlyProcess] = useState(false);
  const [readOnlyCharacter, setReadOnlyCharacter] = useState(false);
  const [AreaOpenCharacter, setAreaOpenCharacter] = useState<any>([]);
  const [AreaOpenProcess, setAreaOpenProcess] = useState<any>([]);
  const [handicapTypeId, setHandicapTypeId] = useState(null);
  const [handicap_id, setHandicapId] = useState(null);
  const [selectedSkills15, setSelectedSkills15] = useState(null); 


  let dataSetNames = ['該当する障がい区分', '該当する障がい', '障がい者手帳の有無', '該当する障がい者手帳の種別'];  

  useEffect(() => {
    if (!localStorage.getItem('jobParachannelAuthToken')) {
      navigate('/login');
    } 
  }, [navigate, dispatch]);

  useEffect(() => {
    if (loginData.pending) {
      window.scrollTo(0, 0);
      dispatch(fetchRecruitmentsDetailRequest(id));      
      setResourceId(loginData.login.resource.id);
      dispatch(fetchRegionsRequest());
      dispatch(fetchHandicapsRequest());      
    }
}, [loginData.pending, dispatch]);

useEffect(() => {
  if (recruitmentsDetail.pending) {
    setCompany_name(recruitmentsDetail.recruitmentsDetail.recruitment.company);
    setJob_title(recruitmentsDetail.recruitmentsDetail.recruitment.job_title);
    recAppeal.push(recruitmentsDetail.recruitmentsDetail.recruitment.appeal_item.appeal_item_1.id,
                recruitmentsDetail.recruitmentsDetail.recruitment.appeal_item.appeal_item_2.id,
                recruitmentsDetail.recruitmentsDetail.recruitment.appeal_item.appeal_item_3.id)
    setAppeal_item_1(recruitmentsDetail.recruitmentsDetail.recruitment.appeal_item.appeal_item_1.id);
    setAppeal_item_2(recruitmentsDetail.recruitmentsDetail.recruitment.appeal_item.appeal_item_2.id);
    setAppeal_item_3(recruitmentsDetail.recruitmentsDetail.recruitment.appeal_item.appeal_item_3.id);
    dispatch(fetchApplicantsRequest(loginData.login.resource.id, 'get', 0));    
  }  
}, [dispatch, recruitmentsDetail.pending]);

useEffect(() => {  
  if (applicants.pending) {        
    const applicantData = applicants.applicants.applicant;
    setJobCareerAppeals(applicantData.job_career_appeals); 
    setFamily_name(applicantData.family_name);
    setFirst_name(applicantData.first_name);
    setFamily_name_kana(applicantData.family_name_kana);
    setFirst_name_kana(applicantData.first_name_kana);
    setApplicantHandicapQualifications(applicantData.handicap_qualifications);
    const sourceDate = applicantData.birthday;
    const formattedDate = formatDate(sourceDate);
    setBirthday(formattedDate);

    if(applicantData.appeal_item) {
      
        const ap1 = recAppeal.includes(applicantData.appeal_item.appeal_item_id_1) ? null : applicantData.appeal_item.appeal_item_id_1;
        const ap2 = recAppeal.includes(applicantData.appeal_item.appeal_item_id_2) ? null : applicantData.appeal_item.appeal_item_id_2;
        const ap3 = recAppeal.includes(applicantData.appeal_item.appeal_item_id_3) ? null : applicantData.appeal_item.appeal_item_id_3;
        if(ap1 != null || ap2 != null || ap3 != null) {
          appAppeal.push(ap1 != null ? ap1.toString() : "");
          appAppeal.push(ap2 != null ? ap2.toString() : "");
          appAppeal.push(ap3 != null ? ap3.toString() : "");
        }
        if(ap1 != null) {
          setSelectedAppealItemId1(true);
        }
        if(ap2 != null) {
          setSelectedAppealItemId2(true);
        }
        if(ap3 != null) {
          setSelectedAppealItemId3(true);
        }
        setAppealsId([...appealsId,...appAppeal]);
    }
    if (applicantData.postcode != null && applicantData.postcode != '' && applicantData.prefecture_id != null && applicantData.prefecture_id != '') {
      setPostcode(applicantData.postcode);
    } else {
      setClassEr8('error_box')
    }
    if(applicantData.address != null && applicantData.address != '') {
      setAddress(applicantData.address);
      setIsBlankAddress(false);
    } else {
      setClassEr9('error_box');
      setIsBlankAddress(true);
    }
    let p = [];
    let c = [];
    for(let i = 0; i < applicantData.handicap_qualifications.length; i++) {
      if(applicantData.handicap_qualifications[i].process !== '' && applicantData.handicap_qualifications[i].process !== null) {
          p.push(true);
      } else {
          p.push(false);
      } 
      if(applicantData.handicap_qualifications[i].characteristics_and_countermeasures !== null && applicantData.handicap_qualifications[i].characteristics_and_countermeasures !== '') {
          c.push(true);
      } else {
          c.push(false);
      }
      setAreaOpenProcess(p);
      setAreaOpenCharacter(c);
  }
  
    setCity_name(applicantData.city_name);
    setCity_id(applicantData.city_id);
    setPrefecture_name(applicantData.prefecture_name);
    setPrefecture_id(applicantData.prefecture_id);
    setEmail(applicantData.email);
    if(applicantData.mobile_number) {
      setMobile_number(applicantData.mobile_number);
      setTel(applicantData.mobile_number)
    } else {
       setClassEr1('error_box');
    }
    setFinal_education_id(applicantData.educational_background.final_education_id);
    if (applicantData.educational_background.final_education_id != null) {
      setFinalEducationFlg(true);
    } else {
      setClassEr2("error_box")
    }
    if (applicantData.educational_background.graduation_date != null) {
      setGraduationDate(applicantData.educational_background.graduation_date);
      // setisBlankgraduationDate(false);
    } else {
      setisBlankgraduationDate(true);
      setClassEr3('error_box')
    }
    if (applicantData.educational_background.graduation_date != null) {
      setGraduationYearFlg(true)
    } else {
      setClassEr7('error_box');
    }
    setSupportAgencyExists(applicantData.employment_support_agency.support_agency_exists);

    if (applicantData.employment_support_agency.support_agency_exists) {
      setSupportAgencyExists(applicantData.employment_support_agency.support_agency_exists);
    }
    setCanContact(applicantData.employment_support_agency.can_contact);
    setContactNumber(applicantData.employment_support_agency.contact_number);
    setEndDate(applicantData.employment_support_agency.end_date);
    if(applicantData.employment_support_agency.end_date != null) {
      setSelectedSkills30(applicantData.employment_support_agency.end_date.split('-')[0]);
      setSelectedSkills31(applicantData.employment_support_agency.end_date.split('-')[1]);
    }
    setOrganization(applicantData.employment_support_agency.organization);
    setStartDAte(applicantData.employment_support_agency.start_date);
    if(applicantData.employment_support_agency.start_date != null) {
      setSelectedSkills28(applicantData.employment_support_agency.start_date.split('-')[0]);
      setSelectedSkills29(applicantData.employment_support_agency.start_date.split('-')[1]);
    }
    setTrainingContent(applicantData.employment_support_agency.training_content);
    if(applicantData.employment_support_agency.training_content != null && applicantData.employment_support_agency.training_content != '') {
      if(applicantData.employment_support_agency.training_content.length > 200) {
        setTrainingContentError(true);
        setTrainingContentErrorCount(applicantData.employment_support_agency.training_content.length);
      } else {
        setTrainingContentError(false);        
      }
    }
    setUrl(applicantData.employment_support_agency.url);
    setNewGraduate(applicantData.educational_background.new_graduate);
    setCareerCountId(applicantData.job_career_count_id);
    setJobCareerRecent(applicantData.job_career_recent);
    if (((applicantData.job_career_recent.company == null || applicantData.job_career_recent.company == '') || !applicantData.job_career_recent.business_content)) {      
      setRecentFlg(true);
      if (applicantData.job_career_recent.company == null || applicantData.job_career_recent.company == '') {
        setRecentFlg(false);
      }
      if(applicantData.job_career_count_id != 1) {
        setClassEr3('error_box');
      }
    } else {
      if (applicantData.job_career_recent.company == null || applicantData.job_career_recent.company == '') {
        setRecentFlg(false);
        if(applicantData.job_career_count_id != 1) {
          setClassEr3('error_box');
        }
      } else {
        setCompany(applicantData.job_career_recent.company);
        setRecentFlg(true);
      }
      setFirstRecentFlg(true);      
    }
    if (applicantData.job_career_recent.industry_id != null) {
      setIndustryId(applicantData.job_career_recent.industry_id);
    }
    if (applicantData.job_career_recent.job_category_id != null) {
      setJobCategoryid(applicantData.job_career_recent.job_category_id);
    }
    if (applicantData.job_career_recent.business_content != "") {
      setTextareaValue(applicantData.job_career_recent.business_content);
    } else {
      setClassEr3("error_box")
      // setRecentFlg(false);
    }
    if (applicantData.job_career_recent.departure_date != null) {
      setDepartureDate(applicantData.job_career_recent.departure_date);
    }
    if (applicantData.job_career_recent.arrival_date != null) {
      setArrivalDate(applicantData.job_career_recent.arrival_date);
    }
    // if(applicantData.consideration_conditions_required_exists != null) {
    //   setConsiderationConditionsRequiredExists(applicantData.consideration_conditions_required_exists);
    // } else {
    //   setClassEr10('error_box');
    // }   
    if (applicantData.consideration_conditions_required_exists !== null && applicantData.consideration_conditions_required_exists !== '') {
      setConsiderationConditionsRequiredExists(applicantData.consideration_conditions_required_exists);
      setAreaOpenConsiderationRequired(true);
    } else {
      setClassEr10('error_box');
      setAreaOpenConsiderationRequired(false);
    }
    setConsiderationConditionsRequired(applicantData.consideration_conditions_required);
    setConsiderationConditionSupplemental(applicantData.consideration_condition_supplemental);
    if(applicantData.consideration_condition_supplemental != null && applicantData.consideration_condition_supplemental != '') {
      if(applicantData.consideration_condition_supplemental.length > 200) {
        setConsiderationConditionSupplementalMaxLengthError(true);
        setConsiderationConditionSupplementalMaxLengthCount(applicantData.consideration_condition_supplemental.length);
      } else {            
          setConsiderationConditionSupplementalMaxLengthError(false);
      }
    }    
    setSpecialContent(applicantData.special_content);
    setSearchPolicyId(applicantData.preferred.search_policy_id);
    setCompatibilities(applicantData.compatibilities);
    setWorkingHourId(applicantData.preferred.working_hour_id);
    setWorkFromHomeId(applicantData.preferred.work_from_home_id);
    setPrefectureId1(applicantData.preferred.prefecture_id_1);
    setPrefectureId2(applicantData.preferred.prefecture_id_2);
    setCommutingMethods(applicantData.preferred.commuting_methods);
    if(applicantData.employment_statuses.length != 0) {
      setEmploymentStatusId(applicantData.employment_statuses);
    } else {
      
      setClassEr4('error_box');
    }
    if (applicantData.employment_statuses != null && applicantData.employment_statuses.length != 0) {
      setEmploymentstatusesFlg(true)
    }
    setJobCategoryid1(applicantData.preferred.job_category_id_1);
    setJobCategoryid2(applicantData.preferred.job_category_id_2);
    setAnnualIncomeId(applicantData.preferred.annual_income_id);
    if (applicantData.preferred.annual_income_id != null) {
      setAnnualIncomeFlg(true)
    } else {
      setClassEr5('error_box')
    }
    // setHandicapQualifications(applicantData.handicap_qualifications);
    setHandiFlg(false);
    // if (applicantData.handicap_qualifications && applicantData.handicap_qualifications.length != 0) {
    //   setHandiFlg(true);
    // }
    setFacePhoto(applicantData.face_photo);
    if(applicantData.experience_job_category.job_category_id_1) {
      setJobCategory1(applicantData.experience_job_category.job_category_id_1);
      setJobCategory2(applicantData.experience_job_category.job_category_id_2);
      setJobCategory3(applicantData.experience_job_category.job_category_id_3);
    }
    setSelfPR(applicantData.self_pr.content);
    setSelectedSkills15(applicantData.language_study.language_id_1);
    if (applicantData.language_study.language_id_1 != null && applicantData.language_study.language_id_1.length != 0) {
      setFormattedName1(applicantData.language_study.language_id_1);
    }
    setLanguageId2(applicantData.language_study.language_id_2);
    if (applicantData.language_study.language_id_2 != null && applicantData.language_study.language_id_2.length != 0) {
      setFormattedName2(applicantData.language_study.language_id_2);
    }
    setLanguage3(applicantData.language_study.language_3);
    if (applicantData.language_study.language_3 != null && applicantData.language_study.language_3.length != 0) {
      setLanguageAppeal(applicantData.language_study.language_3);
    }
    setLevelId1(applicantData.language_study.level_id_1);
    if (applicantData.language_study.level_id_1 != null && applicantData.language_study.level_id_1.length != 0) {
      setSelectedSkills16(applicantData.language_study.level_id_1);
    }
    setLevelId2(applicantData.language_study.level_id_2);
    if (applicantData.language_study.level_id_2 != null && applicantData.language_study.level_id_2.length != 0) {
      setSelectedSkills18(applicantData.language_study.level_id_2);
    }
    setWordExperience(applicantData.oa_skills.word_experience);
    setExcelExperience(applicantData.oa_skills.excel_experience);
    setWord(applicantData.oa_skills.word);
    if (applicantData.oa_skills.word_experience) {
      setWordExp(true);
      setSelectedSkills(applicantData.oa_skills.word);
    }
    setExcel(applicantData.oa_skills.excel);
    if (applicantData.oa_skills.excel_experience) {
      setExcelExp(true);
      setSelectedSkills2(applicantData.oa_skills.excel);
    }
    setDesign(applicantData.oa_skills.design);
    if (applicantData.oa_skills.design != null && applicantData.oa_skills.design.length != 0) {
      setSelectedSkills3(applicantData.oa_skills.design);
    }
    setProgram(applicantData.oa_skills.program);
    if (applicantData.oa_skills.program != null && applicantData.oa_skills.program.length != 0) {
      setSelectedSkills4(applicantData.oa_skills.program);
    }
    setQualificationsId1(applicantData.qualification.qualification_id_1)
    if ( applicantData.qualification.qualification_id_1!= null && applicantData.qualification.qualification_id_1.length != 0) {
      setSelectedSkills6(applicantData.qualification.qualification_id_1);
    }
    setQualificationsId2(applicantData.qualification.qualification_id_2)
    if (applicantData.qualification.qualification_id_2 != null && applicantData.qualification.qualification_id_2.length != 0) {
      setSelectedSkills8(applicantData.qualification.qualification_id_2);
    }
    setQualifications3(applicantData.qualification.qualification_3)
    setExperienceJobCategory(applicantData.experience_job_category)
    //setOaskills(applicantData.oa_skills)
    setQualification(applicantData.qualification)
    setLanguageStudy(applicantData.language_study)
    if(applicantData.employment_support_agency) {
      setEmploymentSupportAgency(applicantData.employment_support_agency);
    }
    setApplicantConsiderationConditionsRequiredExists(applicantData.consideration_conditions_required_exists);
    
    setSelfPr(applicantData.self_pr)
    if(applicantData.self_pr.content) {
      if(!charaLimit(applicantData.self_pr.content, 400)) {
          setValid(false);
      }
  }
    setLoadScreen(false);
  }
  const maxYear = dayjs().year();
  const minYear = new_graduate ? dayjs().year() - 3 : maxYear - 50;
  const yearRange1 = Array.from({ length: maxYear - minYear + 1 }, (_, index) => maxYear - index);
  setYearRange(yearRange1); 
}, [applicants.pending]);



const formatDate = (dateString : any) => {
  const [year, month, day] = dateString.split('-').map((component: any) => parseInt(component));
  return `${year}年${month}月${day}日`;
};
const formatYM = (dateString : any) => {
  // if(dateString == null || dateString == '') {
  //   return '選択してください'
  // }
  if(dateString == null) {
    return ""
  }
  const [year, month] = dateString.split('-').map((component: any) => parseInt(component));
  if(year == 9999 && month == 12) {
    return '現在'
  }
  return `${year}年${month}月`;
};

const formatEmploymentYM = (dateString : any) => {
  if(dateString == null || dateString == '') {
    return '選択してください'
  }
  const [year, month] = dateString.split('-').map((component: any) => parseInt(component));
  if(year == 9999 && month == 12) {
    return '終了月なし（継続中）'
  }
  return `${year}年${month}月`;
};

// const fullAddress = `${prefecture_name||''}${city_name||''}${address||''}`;

useEffect(() => {
  if (regions.pending) {
    setOaskills(regions.regions.oa_skills);
    setQualificationCategories(regions.regions.qualification_categories);
    setQualifications(regions.regions.qualifications);
    setJobCategoryTypes(regions.regions.job_category_types);
    setJobCategories(regions.regions.job_categories);
    setLanguages(regions.regions.languages);
    setLevels(regions.regions.levels);
    setIndustries(regions.regions.industries);
    setAnnualIncome(regions.regions.annual_incomes);
    setEmploymentstatuses(regions.regions.employment_statuses);
    setFinalEducations(regions.regions.final_educations);
    setHandicaps(regions.regions.handicaps);
    setHandicapTypes(regions.regions.handicap_types);
    setDisabilityCertificateStatus(regions.regions.disability_certificate_statuses);
    setDisabilityCertificates(regions.regions.disability_certificates)
    setAppeals(regions.regions.appeal_items);
    setConsiderationConditionsMasterData(regions.regions.consideration_conditions);
  }
}, [regions.pending]);

const findValue = (value: any, masterName: string | number) => {
  const dataSet = regions.regions[masterName];
  if (dataSet == undefined) {
    return null;
  }
  return value && value != undefined ? dataSet.find((v: { id: number; }) => v.id == Number(value))?.name : '未登録';
};

const findValue2 = (value: any, masterName: string | number) => {
  const dataSet = regions.regions[masterName];
  if (dataSet == undefined) {
    return null;
  }
  return value && value != undefined ? dataSet.find((v: { id: number; }) => v.id == Number(value)).class_name : null;
};
const findValue3 = (value: any) => {
  const dataSet = regions.regions.handicaps;
  if (dataSet == undefined) {
    return null;
  }
  return value && value != undefined ? dataSet.find((v: { id: number; }) => v.id == Number(value)).handicap_type_id : '未登録';
};

const considerationRequiredModalNext = () => {
  if (considerationRequiredSlide == 2) {
      setConsiderationConditionsRequiredModal(false)
      setConsiderationRequiredSlide(0);
      if(consideration_conditions_required.length > 0) {
        setClassEr10('')
      }
  } else {
      setConsiderationRequiredSlide(considerationRequiredSlide + 1)
  }
};
const filteredData = considerationConditionsMasterData.filter(data => {
  const consideration_types = applicantHandicapQualifications.map((i) => findValue3(i.handicap_id));
  if (applicantHandicapQualifications.length == 0) {
    return data.consideration_condition_type_id == 1;
  } else {
    return (
      data.consideration_condition_type_id == 1 && data.handicap_type_ids.some((id:any) => consideration_types.includes(id))
   );
  }
});
const filteredData2 = considerationConditionsMasterData.filter(data => {
  const consideration_types = applicantHandicapQualifications.map((i) => findValue3(i.handicap_id));
  if (applicantHandicapQualifications.length == 0) {
    return data.consideration_condition_type_id == 2;
  } else {
    return (
      data.consideration_condition_type_id == 2 && data.handicap_type_ids.some((id:any) => consideration_types.includes(id))
   );
  }
});

const filteredData3 = considerationConditionsMasterData.filter(data => {
  const consideration_types = applicantHandicapQualifications.map((i) => findValue3(i.handicap_id));
  if (applicantHandicapQualifications.length == 0) {
    return data.consideration_condition_type_id == 3;
  } else {
    return (
      data.consideration_condition_type_id == 3 && data.handicap_type_ids.some((id:any) => consideration_types.includes(id))
   );
  }
});

const handleLinkClick = (id: any, pos: any) => {
  if(id != 0) {
    setCurrentAppeal(id);
    setCurrentPosition(pos);
  } else {
    if(pos == 0) {
      setCurrentAppeal(id);
      setCurrentPosition(1);
    } else {
      setCurrentAppeal(0);
      setCurrentPosition(pos);
    }
  }
  setModalOpen(true);
};

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleEntryflg = () => {
    const safeId = id ?? '';
    localStorage.setItem('entryFlag', safeId);
    // navigate('/mypage/profile/')
    window.location.href = '/mypage/profile/';
  };

  const handleLinkClick3 = () => {
    setModalOpen3(true);
  };
  const [updateflg, setUpdateflg] = useState(false);
  const [indexflg, setindexflg] = useState<number>(0);
  const handleLinkClick33 = (index: number) => {
    setindexflg(index);
    setUpdateflg(true);
    setModalOpen3(true);
  };
  const handleCloseModal3= () => {
    setModalOpen3(false);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState<any[]>([]);

  const toggleWordExperience = () => {
    setWordExp(!wordExp);
    setSelectedSkills([]);
  };

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
    setCurrentSlide(0);
  };

  const handleCheckboxChange = (event: { target: { value: any; }; }) => {
    const skillValue = event.target.value;
    if (selectedSkills.includes(parseInt(skillValue))) {
      setSelectedSkills(selectedSkills.filter(skill => skill != parseInt(skillValue)));
    } else {
      setSelectedSkills([...selectedSkills, parseInt(skillValue)]);
    }
  };

  const handleComplete = () => {
    toggleDialog();
  };

  function prev() {
    let slide = currentSlide - 1;
    setCurrentSlide(slide);
  }

  const handleRadioChange = (event: any) => {
    const appealId = event.target.value;
    if (appealId == '0') {
      if (appealsId.length > 0) {
        let c = [];
        for(let i = 0; i < appealsId.length; i++) {
          c.push(appealsId[i]);
        }
        for(let j = 0; j < c.length; j++) {
          if(c[j] == currentAppeal.toString()) {
            c[j] = "";
          }
        }
        let too = 0;
        for(var i = 0; i < c.length; i++) {
          if(c[i] == "") {
            too++;
          }
        }
        if(too == c.length) {
          setAppealsId([]);
        } else {
          setAppealsId(c);
        }        
        if(currentPosition == 1) {
          setSelectedAppealItemId1(false);
        } else if(currentPosition == 2) {
          setSelectedAppealItemId2(false);
        } else {
          setSelectedAppealItemId3(false);
        }
      }
    } else {
      if (!appealsId.includes(appealId)) {
        if (appealsId.length <= 3) {
          if(currentAppeal == 0) {
            let c = [];
            for(let i = 0; i < appealsId.length; i++) {
              c.push(appealsId[i]);
            }
            if(appealsId.length == 0) {
              for(let i = 0; i < 3; i++) {
                c.push("");
              }
            }
            for(let j = 0; j < c.length; j++) {
                c[currentPosition - 1] = appealId.toString();
            }
            setAppealsId(c);
          } else {
            let c = [];
            for(let i = 0; i < appealsId.length; i++) {
              c.push(appealsId[i]);
            }
            for(let j = 0; j < c.length; j++) {
              if(c[j] == currentAppeal.toString()) {
                c[j] = appealId.toString();
              }
            }
            setAppealsId(c);
          }
          if(currentPosition == 1) {
            setSelectedAppealItemId1(true);
          } else if(currentPosition == 2) {
            setSelectedAppealItemId2(true);
          } else {
            setSelectedAppealItemId3(true);
          }
        }
      }
    }    
    setModalOpen(false);
  }

  const handleRadioChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSupportAgencyExists(!support_agency_exists);

  }

  const [dialogOpen2, setDialogOpen2] = useState(false);
  const [selectedSkills2, setSelectedSkills2] = useState<any[]>([]);

  const toggleExcelExperience = () => {
    setExcelExp(!excelExp);
    setSelectedSkills2([]);
  };

  const toggleDialog2 = () => {
    setDialogOpen2(!dialogOpen2);
    setCurrentSlide(0);
  };
  

  const handleCheckboxChange2 = (event: { target: { value: any; }; }) => {
    const skillValue2 = event.target.value;
    if (selectedSkills2.includes(parseInt(skillValue2))) {
      setSelectedSkills2(selectedSkills2.filter(skill => skill != parseInt(skillValue2)));
    } else {
      setSelectedSkills2([...selectedSkills2, parseInt(skillValue2)]);
    }
  };

  const handleComplete2 = () => {
    toggleDialog2();
  };
  const [dialogOpen3, setDialogOpen3] = useState(false);
  const [selectedSkills3, setSelectedSkills3] = useState<any[]>([]);

  const toggleDialog3 = () => {
    setDialogOpen3(!dialogOpen3);
    setCurrentSlide(0);
  };

  const handleComplete3 = () => {
    toggleDialog3(); 
  };

  const handleCheckboxChange3 = (event: { target: { value: any; }; }) => {
    const skillValue3 = event.target.value;
    if (selectedSkills3.includes(parseInt(skillValue3))) {
      setSelectedSkills3(selectedSkills3.filter(skill => skill != parseInt(skillValue3)));
    } else {
      setSelectedSkills3([...selectedSkills3, parseInt(skillValue3)]);
    }
  };
  const [dialogOpen4, setDialogOpen4] = useState(false);
  const [selectedSkills4, setSelectedSkills4] = useState<any[]>([]); 
  const toggleDialog4 = () => {
    setDialogOpen4(!dialogOpen4);
    setCurrentSlide(0);
  };

  const handleComplete4 = () => {
    toggleDialog4();
  };

  const handleCheckboxChange4 = (event: { target: { value: any; }; }) => {
    const skillValue4 = event.target.value;
    if (selectedSkills4.includes(parseInt(skillValue4))) {
      setSelectedSkills4(selectedSkills4.filter(skill => skill != parseInt(skillValue4)));
    } else {
      setSelectedSkills4([...selectedSkills4, parseInt(skillValue4)]);
    }
  };

  const [dialogOpen5, setDialogOpen5] = useState(false);
  const [selectedSkills5, setSelectedSkills5] = useState<string[]>([]); // Specify the type as string[]
  const [kills5, setSkills5] = useState<number>(0);

  const toggleDialog5 = () => {
    setDialogOpen5(!dialogOpen5);
    setCurrentSlide(0);
  };

  const handleCheckboxChange5 = (event: { target: { value: any; }; }) => {
    const skillValue5 = event.target.value;
    const sSkills5 = parseInt(event.target.value);
    setSkills5(sSkills5);
    if (selectedSkills5.includes(skillValue5)) {
      setSelectedSkills5(selectedSkills5.filter(skill => skill != skillValue5));
    } else {
      setSelectedSkills5([...selectedSkills5, skillValue5]);
    }
    // setSelectedSkills6([]);
  };

  const [selectedSkills6, setSelectedSkills6] = useState<string[]>([]); // Specify the type as string[]

  const handleCheckboxChange6 = (event: { target: { value: any; }; }) => {
    const skillValue6 = event.target.value;
    setSelectedSkills6(skillValue6);
    toggleDialog5(); 
  };
  
  const [dialogOpen6, setDialogOpen6] = useState(false);
  const [selectedSkills7, setSelectedSkills7] = useState<string[]>([]); // Specify the type as string[]
  const [kills6, setSkills6] = useState<number>(0);

  const toggleDialog6 = () => {
    setDialogOpen6(!dialogOpen6);
    setCurrentSlide(0);
  };

  const handleCheckboxChange7 = (event: { target: { value: any; }; }) => {
    const skillValue7 = event.target.value;
    const sSkills6 = parseInt(event.target.value);
    setSkills6(sSkills6);
    if (selectedSkills7.includes(skillValue7)) {
      setSelectedSkills7(selectedSkills7.filter(skill => skill != skillValue7));
    } else {
      setSelectedSkills7([skillValue7]);
    }
    // setSelectedSkills8([]);
  };

  const [selectedSkills8, setSelectedSkills8] = useState<string[]>([]); // Specify the type as string[]

  const handleCheckboxChange8 = (event: { target: { value: any; }; }) => {
    const skillValue8 = event.target.value;
    setSelectedSkills8(skillValue8);
    toggleDialog6(); 
  };

   const [dialogOpen7, setDialogOpen7] = useState(false);
   const [selectedSkills9, setSelectedSkills9] = useState(null); // Specify the type as string[]
   const [kills7, setSkills7] = useState<number>(0);
 
   const toggleDialog7 = () => {
     setDialogOpen7(!dialogOpen7);
     setCurrentSlide(0);
   };
  
   const handleCheckboxChange9 = (event: { target: { value: any; }; }) => {
     const skillValue9 = event.target.value;
     const sSkills7 = parseInt(event.target.value);
     setSelectedSkills9(skillValue9);
     setSkills7(sSkills7);
     setSelectedSkills10(null);
   };
 
   const [selectedSkills10, setSelectedSkills10] = useState(null);
   const handleCheckboxChange10 = (event: { target: { value: any; }; }) => {
     const skillValue10 = event.target.value;
     setSelectedSkills10(skillValue10);
     setJobCategory1(skillValue10);
     toggleDialog7();
   };
    const [dialogOpen8, setDialogOpen8] = useState(false);
    const [selectedSkills11, setSelectedSkills11] = useState(null);
    const [kills8, setSkills8] = useState<number>(0);

    const toggleDialog8 = () => {
      setDialogOpen8(!dialogOpen8);
      setCurrentSlide(0);
    };

    const handleCheckboxChange11 = (event: { target: { value: any; }; }) => {
      const skillValue11 = event.target.value;
      const sSkills8 = parseInt(event.target.value);
      setSelectedSkills11(skillValue11);
      setSkills8(sSkills8);
      setSelectedSkills12(null);
    };
  
    const [selectedSkills12, setSelectedSkills12] = useState(null);
    const handleCheckboxChange12 = (event: { target: { value: any; }; }) => {
      setSelectedSkills12(event.target.value);
      setJobCategory2(event.target.value);
      toggleDialog8();  
    };
  
  const [dialogOpen9, setDialogOpen9] = useState(false);
  const [selectedSkills13, setSelectedSkills13] = useState(null); 
  const [kills9, setSkills9] = useState<number>(0);

  const toggleDialog9 = () => {
    setDialogOpen9(!dialogOpen9);
    setCurrentSlide(0);
  };


  const handleCheckboxChange13 = (event: { target: { value: any; }; }) => {
    const skillValue13 = event.target.value;
    const sSkills9 = parseInt(event.target.value);
    setSelectedSkills13(skillValue13);
    setSkills9(sSkills9);
    setSelectedSkills14(null);
  };

  const [selectedSkills14, setSelectedSkills14] = useState(null); 
  const handleCheckboxChange14 = (event: { target: { value: any; }; }) => {
    setSelectedSkills14(event.target.value);
    setJobCategory3(event.target.value);
    toggleDialog9();  
  };

   const [dialogOpen10, setDialogOpen10] = useState(false);
 
   const toggleDialog10 = () => {
     setDialogOpen10(!dialogOpen10);
     setCurrentSlide(0);
   };
   const handleComplete10 = () => {
     toggleDialog10();  
   };
   const language1Toggle = () => {
    setDialogOpen10(!dialogOpen10);
    setSelectedSkills16([])
  };
  const language2Toggle = () => {
    setDialogOpen11(!dialogOpen11);
    setSelectedSkills18([])
  };
  const qualification1Toggle = () => {
    setDialogOpen5(!dialogOpen5);
    setSelectedSkills6([])
  };
  const qualification2Toggle = () => {
    setDialogOpen6(!dialogOpen6);
    setSelectedSkills8([])
  };
  const jobCategory1Toggle = () => {
    setDialogOpen7(!dialogOpen7);
    setJobCategory1(null)
  };
  const jobCategory2Toggle = () => {
    setDialogOpen8(!dialogOpen8);
    setJobCategory2(null)
  };
  const jobCategory3Toggle = () => {
    setDialogOpen9(!dialogOpen9);
    setJobCategory3(null)
  };

  // function filterQualificationCategories () {
  //   return qualification_categories.filter((v: any) => {
  //       if (selectedSkills5 == v.id ) { return false }
  //       if (selectedSkills7 == v.id ) { return false }
  //       return true
  //   });
  // }
 
   const handleCheckboxChange15 = (event: { target: { value: any; }; }) => {
     const skillValue15 = event.target.value;
     setSelectedSkills15(skillValue15);
    //  if (selectedSkills15.includes(skillValue15)) {
    //    setSelectedSkills15(selectedSkills15.filter(skill => skill != skillValue15));
    //  } else {
    //    setSelectedSkills15([...selectedSkills15, skillValue15]);
    //  }
   };

  //  function filterLanguages () {
  //   return languages.filter((v: any) => {
  //       if ( selectedSkills15 == v.id ) { return false }
  //       if ( selectedSkills17 == v.id ) { return false }
  //       return true
  //   });
  // }

  function filterLevels () {
    return levels.filter((v: any) => {
        // if ( selectedSkills16 == v.id ) { return false }
        // if ( selectedSkills18 == v.id ) { return false }
        if (selectedSkills15 == selectedSkills17 && level_id_1 == v.id ) { return false }
        if (selectedSkills15 == selectedSkills17 && level_id_2 == v.id ) { return false }
        return true
    });
  }
 
   const [selectedSkills16, setSelectedSkills16] = useState<any[]>([]); // Specify the type as string[]
   const [formattedName2, setFormattedName2] = useState(null);
   const [formattedName1, setFormattedName1] = useState(null);

   const handleCheckboxChange16 = (event: { target: { value: any; }; }) => {
     const skillValue16 = event.target.value;
     setSelectedSkills16(skillValue16);
    //  if (selectedSkills16.includes(skillValue16)) {
    //    setSelectedSkills16(selectedSkills16.filter(skill => skill != skillValue16));
    //  } else {
    //    setSelectedSkills16([...selectedSkills16, skillValue16]);
    //  }
     setFormattedName1(selectedSkills15);
     handleComplete10();
   };
    //
    const [dialogOpen11, setDialogOpen11] = useState(false);
    const [selectedSkills17, setSelectedSkills17] = useState(null); // Specify the type as string[]
  
    const toggleDialog11 = () => {
      setDialogOpen11(!dialogOpen11);
      setCurrentSlide(0);
    };
  
    const handleCheckboxChange17 = (event: { target: { value: any; }; }) => {
      const skillValue17 = event.target.value;
      setSelectedSkills17(skillValue17);
      // if (selectedSkills17.includes(skillValue17)) {
      //   setSelectedSkills17(selectedSkills17.filter(skill => skill != skillValue17));
      // } else {
      //   setSelectedSkills17([skillValue17]);
      // }
    };
  
    const [selectedSkills18, setSelectedSkills18] = useState<any[]>([]); // Specify the type as string[]
    const handleCheckboxChange18 = (event: { target: { value: any; }; }) => {
      const skillValue18 = event.target.value;
      setSelectedSkills18(skillValue18);
      // if (selectedSkills18.includes(skillValue18)) {
      //   setSelectedSkills18(selectedSkills18.filter(skill => skill != skillValue18));
      // } else {
      //   setSelectedSkills18([...selectedSkills18, skillValue18]);
      // }
      setFormattedName2(selectedSkills17);
      toggleDialog11();
    };
     //
   
    const [dialogOpen12, setDialogOpen12] = useState(false);
    const [selectedSkills26, setSelectedSkills26] = useState<string[]>([]); // Specify the type as string[]
    const toggleDialog12 = () => {
      setDialogOpen12(!dialogOpen12);
      setCurrentSlide(0);
    };
  
    const handleComplete12 = () => {
      toggleDialog12();  
    };
  
    const handleCheckboxChange26 = (event: { target: { value: any; }; }) => {
      const skillValue26 = event.target.value;
      
      if (selectedSkills26.includes(skillValue26)) {
        setSelectedSkills26([]);
      } else {
        setSelectedSkills26([skillValue26]);
        setClassEr5('')
      }
      handleComplete12();
    };
     
    const [dialogOpen13, setDialogOpen13] = useState(false);
    const [selectedSkills27, setSelectedSkills27] = useState<string[]>([]); // Specify the type as string[]
  
    const toggleDialog13 = () => {
      setDialogOpen13(!dialogOpen13);
      setCurrentSlide(0);
    };
  
    const handleComplete13 = () => {
      toggleDialog13();  
    };
  
    const handleCheckboxChange27 = (event: { target: { value: any; }; }) => {
      const skillValue27 = event.target.value;
      
      if (selectedSkills27.includes(skillValue27)) {
        setSelectedSkills27(selectedSkills27.filter(skill => skill != skillValue27));
      } else {
        setSelectedSkills27([...selectedSkills27, skillValue27]);
        setClassEr4('')
      }
      handleComplete13();
    };
     //
    const [dialogOpen14, setDialogOpen14] = useState(false);
    const toggleDialog14 = () => {
      filteredYears();
      setDialogOpen14(!dialogOpen14);
      setCurrentSlide(0);
    };
  
    const handleComplete14 = () => {
      toggleDialog14();  
    };

    const [dialogOpen15, setDialogOpen15] = useState(false);
    const toggleDialog15 = () => {
      setDialogOpen15(!dialogOpen15);
      setCurrentSlide(0);
    };

    const [dialogOpen16, setDialogOpen16] = useState(false);
  
    const toggleDialog16 = () => {
      setDialogOpen16(!dialogOpen16);
      setCurrentSlide(0);
    };
  
    const handleComplete16 = () => {
      toggleDialog16();  
    };

    const [dialogOpen17, setDialogOpen17] = useState(false);
    const toggleDialog17 = () => {
      // setLastcompany('');
      // setLastcomp('');
      // setSkills11(0);
      // setYear1('');
      // setMonth1('');
      // setYear2('');
      // setMonth2('');      
      // setSelectedSkills38([]);
      // setSelectedSkills39([]);
      // setSelectedSkills40([]);
      // setJobCategoryid(null);
      // if (!recentFlg) {
        setDialogOpen17(!dialogOpen17);
        setCurrentSlide(0);
      // }
    };

    const [dialogOpen18, setDialogOpen18] = useState(false);
    const toggleDialog18 = () => {
      setDialogOpen18(!dialogOpen18);
      setCurrentSlide(0);
    };

    const [updateflgHandi, setUpdateflgHandi] = useState(false);
    const [indexflgHandi, setindexflgHandi] = useState<number>(0);
    const handleFlgHandi = (index: number) => {
        // setEdited(true);
        setindexflgHandi(index);
        setUpdateflgHandi(true);
        toggleDialog18();
    };

    const [dialogOpen19, setDialogOpen19] = useState(false);
  
    const toggleDialog19 = () => {
      setSelectedSkills47([]);
      setSelectedSkills48([]);
      setDialogOpen19(!dialogOpen19);
      setCurrentSlide(0);
    };
    const handleConsiderationRequiredCheckbox = (e: any, id: any) => {
      const value = id;
      if (consideration_conditions_required.includes(value)) {
          setConsiderationConditionsRequired(consideration_conditions_required.filter(skill => skill != value));
      } else {
          setConsiderationConditionsRequired([...consideration_conditions_required, value]);
      }
  };
  const [count, setCount] = useState(0);
  const [url, setUrl] = useState<string>();
  const [support_agency_exists, setSupportAgencyExists] =  useState(false);
  const [can_contact, setCanContact] = useState<boolean>();
  const [contact_number, setContactNumber] = useState<string>();
  const [end_date, setEndDate] = useState<string>('');
  const [organization, setOrganization] = useState<string>();
  const [start_date, setStartDAte] = useState<string>();
  const [training_content, setTrainingContent] = useState<string>();
  const handleTextareaChange = (event: { target: { value: React.SetStateAction<any>; }; }) => {
    if(event.target.value.length > 200) {
      setTrainingContentError(true);
      setTrainingContentErrorCount(event.target.value.length);
    } else {
      setTrainingContentError(false);      
    }
    setTrainingContent(event.target.value);
  };
  const [languageAppeal, setLanguageAppeal] = useState<any>();

  const handleInputChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setLanguageAppeal(event.target.value);
  };
  const handlePrTextareaChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    if(event.target.value == '') {
      setOver('');
    } else if(charaLimit (event.target.value, 400)) {
        setValid(true);
        setOver(charaLimit (event.target.value, 400));
    } else {
        setValid(false);
        setOver('');
    }
    setSelfPR(event.target.value);
  };


  const next = () =>  { 
    
    let hasErrors = false;
    let validMessage = ''

    if ( face_photo == null) {
      validMessage = validMessage + '顔写真\n';
      // setClassEr1('error_box');
      hasErrors = true;
    } else {
      // setClassEr1('');
    }

    if (!recentFlg && career_count_id != 1 && !textareaValue && company.length == 0) {
      validMessage = validMessage + '直近の職歴\n';
      setClassEr3('error_box');
      hasErrors = true;
    } else {
      setClassEr3('');
    }

    if (!annualIncomeFlg && selectedSkills26.length == 0) {
      validMessage = validMessage + '希望年収\n';
      setClassEr5('error_box');
      hasErrors = true;
    } else {
      setClassEr5('');
    }

    if (!employmentstatusesFlg && selectedSkills27.length == 0) {
      validMessage = validMessage + '希望する雇用形態\n';
      setClassEr4('error_box');
      hasErrors = true;
    } else {
      setClassEr4('');
    }

    if (applicantHandicapQualifications.length == 0) {
      validMessage = validMessage + '障がいの基本情報\n';
      setClassEr6('error_box');
      hasErrors = true;
    } else {
      setClassEr6('');
    }

    if (!graduationYearFlg && graduation_date == '') {
      validMessage = validMessage + '卒業・卒業見込み年度\n';
      setClassEr7('error_box');
      hasErrors = true;
    } else {
      setClassEr7('');
    }

    if(postcodes) {
      if (postcodes == undefined || postcodes.length != 7 ) {
        validMessage = validMessage + '郵便番号\n';
        setClassEr8('zip error_box');
        setClassEr9('address error_box');
        hasErrors = true;
      } else {
        setClassEr8('zip');
        setClassEr9('address');
      }
    } else {
      if (pCode == undefined || pCode.length != 7 ) {
        validMessage = validMessage + '郵便番号\n';
        setClassEr8('zip error_box');
        setClassEr9('address error_box');
        hasErrors = true;
      } else {
        setClassEr8('zip');
        setClassEr9('address');
      }
    }

    if(address.length == 0 || address == null || address == '') {
      validMessage = validMessage + '住所（番地）\n';
      setClassEr9('address error_box');
      hasErrors = true;
    } else {
      setClassEr9('');
    }

    if ( tel != null && (tel.length < 10 || tel.length > 11 || !/^[0-9]*$/.test(tel))) {
      validMessage = validMessage + '電話番号\n';
      setClassEr1('error_box');
      hasErrors = true;
    } else {
      setClassEr1('');
    }

    if (!finalEducationFlg && selectedSkills32.length == 0) {
      validMessage = validMessage + '最終学歴\n';
      setClassEr2('error_box');
      hasErrors = true;
    } else {
      setClassEr2('');
    }
  
    if (validMessage != '') {
      window.alert("未入力項目があります\n\n" + validMessage)
      return
    }

    let error = false;
    if (!hasErrors) {
      if(trainingContentError == true) {
        error = true;
      }
      // self_pr content error check
      if(isValid == true) {
        error = true;
      }
      // business_content
      for(let i = 0; i < job_career_appeals.length; i++) {
        if(job_career_appeals[i].business_content_maxLength == true) {
          error = true;
        }
      }

      if (error) {
        window.alert('文字数がオーバーしています')
        return
      }   
    }
    if(error == false) {
      applyData();
    }
  }

  const currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth() + 1;
  const goBackYears = 70;
  const startYear = currentYear - goBackYears;
  const yearArray = [];   
  let months = [
    1, 2, 3, 4,
    5, 6, 7, 8,
    9, 10, 11, 12
  ];

  let months2 = [
    1, 2, 3, 4,
    5, 6, 7, 8,
    9, 10, 11, 12
  ];


  if(year_1.length == 0) {
      for (let year = currentYear; year >= startYear; year--) {
          yearArray.push(year);
      }      
  } 
  
  if (month_1 && year_1 == year_2) {
    const startIndex = months.indexOf(Number(month_1));
    const filteredMonths = months.slice(startIndex);
    months2 = filteredMonths;
  }  
  let m = [];
  if(year_2 == currentYear.toString()) {
    for (let i = 1; i <= currentMonth; i++) {
      m.push(i);
    }
    months2 = m;
  }

  if (jobCareerAppealsArrivalMonth && jobCareerAppealsArrivalYear == jobCareerAppealsDepartureYear) {
    const startIndex = months.indexOf(Number(jobCareerAppealsArrivalMonth));
    const filteredMonths = months.slice(startIndex);
    months2 = filteredMonths;
  }  

  let y = [];
  if(jobCareerAppealsDepartureYear == currentYear.toString()) {
    for (let i = 1; i <= currentMonth; i++) {
      y.push(i);
    }
    months2 = y;
  }

  const filteredYears = () => {
    if(year_1.length == 0 && years.length == 0) {
      for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
      }
    } 
    if(jobCareerAppealsArrivalYear.length == 0 && years.length == 0) {
      for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
      }
    } 
    if(selectedSkills28.length == 0 && years.length == 0) {
      for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
      }
    } 
    setYear(years);
    filteredMonths();
  }

  function filteredEndYears () {
    const endYears = [];
    if(year_1.length > 0){
      for (let year = currentYear; year >= Number(year_1); year--) {
        endYears.push(year);
      }
      setEndYear(endYears);
    }   
    if(jobCareerAppealsArrivalYear.length > 0){
      for (let year = currentYear; year >= Number(jobCareerAppealsArrivalYear); year--) {
        endYears.push(year);
      }
      setEndYear(endYears);
    } 
    if(selectedSkills28.length > 0){
      for (let year = currentYear; year >= Number(selectedSkills28); year--) {
        endYears.push(year);
      }
      setEndYear(endYears);
    }         
  }

  function filteredMonths () {
    const months = [];
    let start = 1;
    let end = 12;
    for (let i = start; i <= end; i++) {
      months.push(i);
    }
    setMonth(months);  
    filteredEndYears();
  }

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    const goBackYears = 70;
    const goBackYears2 = 40;

    let currentYear = dayjs().year();
    let currentYear2 = dayjs().year();

    const calculatedYears: number[] = [];
    const calculatedYears2: number[] = [];

    for (let i = 0; i <= goBackYears; i++) {
      calculatedYears.push(currentYear);
      currentYear--;
    }

    setYears(calculatedYears);
    for (let i = 0; i <= goBackYears2; i++) {
      calculatedYears2.push(currentYear2);
      currentYear2--;
    }

    setYears2(calculatedYears2);
  };
  
  const [cname, setCname] = useState('');
  const [compname, setCompname] = useState('');
  const [lastcomp, setLastcomp] = useState('');
  const [lastcompany, setLastcompany] = useState('');
  const [tel, setTel] = useState('');

  const handleNameChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setCname(event.target.value);
  };

  const handleNextClick = () => {
    setCompname(cname);
  };

  const handleNameChange2 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setLastcomp(event.target.value);
    setCompany(event.target.value);
  };

  const handleNextClick2 = () => {
    setLastcompany(lastcomp);
    setCompname(lastcomp);
    // setCompany(lastcomp);
  };

  const [selectedSkills21, setSelectedSkills21] = useState<any[]>([]);
  const handleCheckboxChange21 = (event: { target: { value: any; }; }) => {
     const skillValue21 = event.target.value;
     if (selectedSkills21.includes(skillValue21)) {
       setSelectedSkills21(selectedSkills21.filter(skill => skill != skillValue21));
     } else {
       setSelectedSkills21([...selectedSkills21, skillValue21]);
     }
  };
  const [selectedSkills22, setSelectedSkills22] = useState<any>(null);
  const handleCheckboxChange22 = (event: { target: { value: any; }; }) => {
     const skillValue22 = parseInt(event.target.value);
     setSelectedSkills22(skillValue22);
    //  if (selectedSkills22.includes(skillValue22)) {
    //    setSelectedSkills22(selectedSkills22.filter(skill => skill != skillValue22));
    //  } else {
    //    setSelectedSkills22([...selectedSkills22, skillValue22]);
    //  }
  };
  const [kills10, setSkills10] = useState<number>(0);
  const [selectedSkills23, setSelectedSkills23] = useState<any[]>([]);

  const handleCheckboxChange23 = (event: { target: { value: any; }; }) => {
     const skillValue23 = event.target.value;
     const sSkills10 = parseInt(event.target.value);
     setSkills10(sSkills10);
     if (selectedSkills23.includes(skillValue23)) {
       setSelectedSkills23(selectedSkills23.filter(skill => skill != skillValue23));
     } else {
       setSelectedSkills23([...selectedSkills23, skillValue23]);
     }
  };
  const [selectedSkills24, setSelectedSkills24] = useState<any[]>([]);
  const handleCheckboxChange24 = (event: any) => {
    setCurrentSlide(0);
    const skillValue24 = event.target.value;
    if (selectedSkills24.includes(parseInt(skillValue24))) {
      setSelectedSkills24(selectedSkills24.filter(skill => skill != parseInt(skillValue24)));
    } else {
      setSelectedSkills24([...selectedSkills24, parseInt(skillValue24)]);
    }
    if (updateflg == true) {
      handleUpdateCareerAppeal(indexflg, parseInt(skillValue24));
    } else {
      handleAddCareerAppeal(parseInt(skillValue24));
    }     
    setModalOpen3(false);
    //  handleCloseModal3();
  };
  
  const handleAddCareerAppeal = (sel24: any) => {
    const newAppeal = {
      company: cname, 
      arrival_date: `${jobCareerAppealsArrivalYear}-${jobCareerAppealsArrivalMonth}`,
      departure_date:  `${jobCareerAppealsDepartureYear}-${jobCareerAppealsDepartureMonth}`,
      industry_id: selectedSkills22,
      job_category_id: sel24,
      business_content: business_content_text,
    };
    setJobCareerAppeals([...job_career_appeals, newAppeal]);
    setCompname('');
    setCname('');
    setJobCareerAppealsArrivalYear('');
    setJobCareerAppealsArrivalMonth('');
    setJobCareerAppealsDepartureYear('');
    setJobCareerAppealsDepartureMonth('');
    setSelectedSkills22([]);
    setSelectedSkills23([]);
    setSelectedSkills24([]);
  };
  const [selectedSkills25, setSelectedSkills25] = useState<any[]>([]);
  const handleCheckboxChange25 = (event: { target: { value: any; }; }) => {
     const skillValue25 = event.target.value;
     if (selectedSkills25.includes(skillValue25)) {
       setSelectedSkills25(selectedSkills25.filter(skill => skill != skillValue25));
     } else {
       setSelectedSkills25([...selectedSkills25, skillValue25]);
     }
  };
  const [selectedSkills28, setSelectedSkills28] = useState('');
  const [trigger, setTrigger] = useState<number>(0);

  const handleCheckboxChange28 = (event: { target: { value: any; }; }) => {
     const skillValue28 = event.target.value;
     setSelectedSkills28(skillValue28);
    //  if (selectedSkills28.includes(skillValue28)) {
    //   setSelectedSkills28([skillValue28]);
    // } else {
    //   setSelectedSkills28([skillValue28]);
    // }
    setTrigger(1);
  };
  
  const [selectedSkills29, setSelectedSkills29] = useState('');
  const handleCheckboxChange29 = (event: { target: { value: any; }; }) => {
    const skillValue29 = event.target.value;
    setSelectedSkills29(skillValue29);
    setStartDAte(`${selectedSkills28}-${skillValue29}`);
    // if (selectedSkills29.includes(skillValue29)) {
    //   setSelectedSkills29([skillValue29]);
    // } else {
    //   setSelectedSkills29([skillValue29]);
    // }
    setSelectedSkills30('');
    setSelectedSkills31('');
    setEndDate('');
    // if(selectedSkills30 != null && selectedSkills31 != null) {

    // }
    setTrigger(0);
    handleComplete14();
  };
  

  const [selectedSkills30, setSelectedSkills30] = useState('');
  // const [trigger2, setTrigger2] = useState<number>(0);
  const handleCheckboxChange30 = (event: { target: { value: any; }; }) => {
     const skillValue30 = event.target.value;
     setSelectedSkills30(skillValue30);
    //  if (selectedSkills30.includes(skillValue30)) {
    //   setSelectedSkills30([]);
    // } else {
    //   setSelectedSkills30([skillValue30]);
    // }
    // setTrigger2(1);    
    setSelectedSkills31('');
    if (selectedSkills29.length > 0 && parseInt(selectedSkills28) == parseInt(skillValue30)) {
      const startIndex = months.indexOf(Number(selectedSkills29));
      const filteredMonths = months.slice(startIndex);
      setEndMonth(filteredMonths);
    } else {    
      setEndMonth(months);
    } 

    // if (event.target.value.includes('9999-12')) {
    //   // setTrigger2(0);
    //   setCurrentSlide(0);
    //   handleComplete15();
    // }
  };
  
  const [selectedSkills31, setSelectedSkills31] = useState('');
  const handleCheckboxChange31 = (event: { target: { value: any; }; }) => {
    const skillValue31 = event.target.value;
    setSelectedSkills31(skillValue31);
    setEndDate(`${selectedSkills30}-${skillValue31}`);
    toggleDialog15(); 
    // if (selectedSkills31.includes(skillValue31)) {
    //   setSelectedSkills31(skillValue31);
    // } else {
    //   setSelectedSkills31([skillValue31]);
    // }
    // setTrigger2(0);
  };
  
  // useEffect(() => {
  //   // setEndDate(`${selectedSkills30.join('-')}-${selectedSkills31.join('-')}`);
  //   setEndDate(`${selectedSkills30}-${selectedSkills31}`);
  // }, [selectedSkills30, selectedSkills31]);

  const [selectedSkills32, setSelectedSkills32] = useState<any[]>([]);
  const handleCheckboxChange32 = (event: { target: { value: any; }; }) => {
     const skillValue32 = event.target.value;
     if (selectedSkills32.includes(skillValue32)) {
      setSelectedSkills32([]);
    } else {
      setSelectedSkills32([skillValue32]);
      setClassEr2('')
    }
    handleComplete16();
  };

  const handleInputDate = () => {
    setArrivalDate(`${year_1}-${month_1}`)
    setDepartureDate(`${year_2}-${month_2}`)
  }

  const handleInputEndDate = () => {
    setEndDate('9999-12');
  }
  // const [selectedSkills33, setSelectedSkills33] = useState<any[]>([]);
  // const handleCheckboxChange33 = (event: { target: { value: any; }; }) => {
  //    const skillValue33 = event.target.value;
  //    if (selectedSkills33.includes(skillValue33)) {
  //     setSelectedSkills33([]);
  //   } else {
  //     setSelectedSkills33([skillValue33]);
  //   }
  // };
  const [textareaValue, setTextareaValue] = useState('');

  const handleTextareaChange2 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setTextareaValue(event.target.value);
    if(event.target.value != "" && recentFlg) {
      setClassEr3('')
    } else {
      setClassEr3('error_box')
    }
  };
  const [selectedSkills35, setSelectedSkills35] = useState<any[]>([]);
  const handleCheckboxChange35 = (event: { target: { value: any; }; }) => {
     const skillValue35 = event.target.value;
     if (selectedSkills35.includes(skillValue35)) {
      setSelectedSkills35([]);
    } else {
      setSelectedSkills35([skillValue35]);
    }
  };
  // const [selectedSkills36, setSelectedSkills36] = useState<any[]>([]);
  // const handleCheckboxChange36 = (event: { target: { value: any; }; }) => {
  //    const skillValue36 = event.target.value;
  //    if (selectedSkills36.includes(skillValue36)) {
  //     setSelectedSkills36([]);
  //   } else {
  //     console.log(skillValue36)
  //     setYear2(skillValue36);
  //     // setSelectedSkills36([skillValue36]);
  //   }
  // };
  // const [selectedSkills37, setSelectedSkills37] = useState<any[]>([]);
  // const handleCheckboxChange37 = (event: { target: { value: any; }; }) => {
  //    const skillValue37 = event.target.value;
  //    if (selectedSkills37.includes(skillValue37)) {
  //     setSelectedSkills37([]);
  //   } else {
  //     setMonth2(skillValue37)
  //     // setSelectedSkills37([skillValue37]);
  //   }
  //   setDepartureDate(`${selectedSkills36}-${skillValue37}`);
  // };
  const [selectedSkills38, setSelectedSkills38] = useState<number[]>([]);
  const handleCheckboxChange38 = (event: { target: { value: any; }; }) => {
     const skillValue38 = event.target.value;
     if (selectedSkills38.includes(skillValue38)) {
      setSelectedSkills38([]);
    } else {
      setSelectedSkills38([skillValue38]);
    }
    setIndustryId(event.target.value);
  };

  // function handleYearTwo() {    
  //   setArrivalDate(`${year_1}-${month_1}`);
  //   setDepartureDate(`${9999}-${12}`);
  //   setRecentFlg(true);  
  //   setCurrentSlide(0); 
  //   toggleDialog17();
  // }
  const [selectedSkills40, setSelectedSkills40] = useState<any[]>([]);
  const [kills11, setSkills11] = useState<number>(0);
  const handleCheckboxChange40 = (event: { target: { value: any; }; }) => {
     const skillValue40 = event.target.value;
     setJobCategoryid(event.target.value);
     if (selectedSkills40.includes(skillValue40)) {
      setSelectedSkills40([]);
    } else {
      setSelectedSkills40([skillValue40]);
    }
    if(industry_id != null && year_1 != '' && month_1 != '' && year_2 != '' && month_2 != '') {
      setRecentFlg(true);
    }
    if(textareaValue != null && textareaValue != ""){
      setClassEr3("");
    } else {
      setClassEr3("error_box");
    }
    setLastcompany('');
    setLastcomp('');
    setSkills11(0);
    setYear1('');
    setMonth1('');
    setYear2('');
    setMonth2('');
    // setSelectedSkills36([]);
    setSelectedSkills38([]);
    setSelectedSkills39([]);
    setSelectedSkills40([]);
    toggleDialog17();
  };
  const [selectedSkills39, setSelectedSkills39] = useState<any[]>([]);
  const handleCheckboxChange39 = (event: { target: { value: any; }; }) => {
     const skillValue39 = event.target.value;
     const sSkills11 = parseInt(event.target.value);
     setSkills11(sSkills11);
     if (selectedSkills39.includes(skillValue39)) {
      setSelectedSkills39([]);
    } else {
      setSelectedSkills39([skillValue39]);
    }
  };
  const [selectedSkills44, setSelectedSkills44] = useState<any>(null);
  const handleCheckboxChange44 = (data: any, cfid: any) => {
    if (cfid == 0 ) {
      cfid = disability_certificate_status_id;
    }
    toggleDialog18();
    setSelectedSkills44(data);

    if (updateflgHandi == true) {
      const updatevalue = {
        handicap_id: handicap_id,
        disability_certificate_status_id: parseInt(cfid),
        disability_certificate_id: data,
        disability_certificate_acquisition_month: null,
      };
      
      const updatedAppeals = [...applicantHandicapQualifications];
      updatedAppeals[indexflgHandi] = {
        ...updatedAppeals[indexflgHandi], 
        ...updatevalue, 
      };
      setApplicantHandicapQualifications(updatedAppeals);
      setHandicapId(null);
      setHandicapTypeId(null);
      setDisabilityCertificateStatusId(null);
      setSelectedSkills44(null);
      setUpdateflgHandi(false);
      setindexflgHandi(0);
    } else {
        const newHandi = {
            handicap_id: handicap_id,
            disability_certificate_status_id: parseInt(cfid),
            disability_certificate_id: data,
            disability_certificate_acquisition_month: null,
            characteristics_and_countermeasures: "",
            process: ""
        };
        setApplicantHandicapQualifications([...applicantHandicapQualifications, newHandi]);
        setHandicapId(null);
        setHandicapTypeId(null);
        setDisabilityCertificateStatusId(null);
        setSelectedSkills44(null);
    }
  };
    
  const [selectedSkills45, setSelectedSkills45] = useState<any[]>([]);
  const [selectedSkills46, setSelectedSkills46] = useState<any[]>([]);
  const handleCheckboxChange45 = (event: { target: { value: any; }; }) => {
    const skillValue45 = event.target.value;
    if (selectedSkills45.includes(skillValue45)) {
     setSelectedSkills45([]);
   } else {
     setSelectedSkills45([skillValue45]);
   }
 };
 const handleCheckboxChange46 = (event: { target: { value: any; }; }) => {
  const skillValue46 = event.target.value;
  if (selectedSkills46.includes(skillValue46)) {
   setSelectedSkills46([]);
 } else {
   setSelectedSkills46([skillValue46]);
 }
};
  const handleCheckboxChange43 = (data: any) => {
    setCurrentSlide(3);
    setDisabilityCertificateStatusId(data);
    if (data !== 2) {
      const val = null;
      handleCheckboxChange44(val, data);
    }
  };
  const [selectedSkills47, setSelectedSkills47] = useState<number[]>([]);
  const [selectedSkills48, setSelectedSkills48] = useState<number[]>([]);
  const handleCheckboxChange47 = (event: { target: { value: any }; }) => {
    const skillValue47 = event.target.value;
    if (selectedSkills47.includes(skillValue47)) {
      setSelectedSkills47([]);
    } else {
      setSelectedSkills47([skillValue47]);
    }
  };
  
  const handleCheckboxChange48 = (event: { target: { value: any }; }) => {
    const skillValue48 = event.target.value;
    if (selectedSkills48.includes(skillValue48)) {
      setSelectedSkills48([]);
    } else {
      setSelectedSkills48([skillValue48]);
    }
    setGraduationDate(`${selectedSkills47}-${skillValue48}`);
    if(graduation_date != "") {
      setSelectedSkills47([]);
      setSelectedSkills48([]);
    }
    toggleDialog19();
  };

const [business_content_text, setBusinessContentText] = useState('');

const handleContentChange = (event: any, index: number) => {
  const text = (event.target.value);
  const maxLenCount = (event.target.value.length);        
  let maxlenCheck = false;
  const updatevalue = {
    business_content: text,
    business_content_maxLength: maxlenCheck,
    business_content_lengthCount: maxLenCount,
  };
        
  const updatedAppeals = [...job_career_appeals];
  updatedAppeals[index] = {
    ...updatedAppeals[index], 
    ...updatevalue, 
  };

  if(maxLenCount > 200) {
    maxlenCheck = true;
    const updatevalue2 = {
      business_content: text,
        business_content_maxLength: maxlenCheck,
        business_content_lengthCount: maxLenCount,
    }; 
    updatedAppeals[index] = {
        ...updatedAppeals[index], 
        ...updatevalue2, 
    };
  }      
  setJobCareerAppeals(updatedAppeals);
};

  const handleQualificationInputChange = (event: { target: { value: React.SetStateAction<any>; }; }) => {
    setQualifications3(event.target.value);
  };
  
  function setImage() {
    const fileInput = fileInputRef.current;
    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      const fileImg = fileInput.files[0];
      if (fileImg.type.startsWith('image/')) {
        const logoUrl = URL.createObjectURL(fileImg);
        const reader = new FileReader();
        reader.readAsDataURL(fileImg);
        reader.onload = function () {
            setFacePhoto(reader.result);
            setFacePhoto1(reader.result);
        };
      }
    }
  }

const handleDeleteCareerAppeal = (index: number) => {
  const updatedAppeals = [
    ...job_career_appeals];
  updatedAppeals.splice(index, 1);
  setJobCareerAppeals(updatedAppeals);
};
const handleUpdateCareerAppeal = (index: number, sel24: any) => {
    const updatevalue = {
      company: cname, 
      arrival_date: `${jobCareerAppealsArrivalYear}-${jobCareerAppealsArrivalMonth}`,
      departure_date: `${jobCareerAppealsDepartureYear}-${jobCareerAppealsDepartureMonth}`,
      industry_id: selectedSkills22,
      job_category_id: sel24,
    };

    const updatedAppeals = [...job_career_appeals];
    updatedAppeals[index] = {
      ...updatedAppeals[index], 
      ...updatevalue, 
    };
    setJobCareerAppeals(updatedAppeals);
    setUpdateflg(false);
    setindexflg(0);
    setCompname('');
    setCname('');
    setJobCareerAppealsArrivalYear('');
    setJobCareerAppealsArrivalMonth('');
    setJobCareerAppealsDepartureYear('');
    setJobCareerAppealsDepartureMonth('');
    setSelectedSkills22([]);
    setSelectedSkills23([]);
    setSelectedSkills24([]);  
};

// const handleDeleteCareerHandicap = (index: number) => {
//   const updatedHandicap = [
//     ...handicap_qualifications];
//     updatedHandicap.splice(index, 1);
//   setHandicapQualifications(updatedHandicap);
// };
// const handleUpdateCareerHandicap = (index: number) => {

//   const updatevalue = {
//     handicap_id: selectedSkills21, 
//     disability_certificate_status_id: `${jobCareerAppealsArrivalYear}-${jobCareerAppealsArrivalMonth}`,
//     disability_certificate_id:  `${selectedSkills21}-${selectedSkills25}`,
//     disability_certificate_acquisition_month: selectedSkills21,
//     process: selectedSkills21,
//     characteristics_and_countermeasures:selectedSkills21,
//   };

//   const updatedHandicap = [...handicap_qualifications];
//   updatedHandicap[index] = {
//     ...updatedHandicap[index], 
//     ...updatevalue, 
//   };
//   setHandicapQualifications(updatedHandicap);
//   setUpdateflg(false);
//   setindexflg(0);
// };


const handleMobileNumberChange = (event: any) => {
  setTel(event.target.value);
  if(event.target.value != '') {
    setClassEr1('');
  } else {
    setClassEr1('error_box');
  }
};

function onChangePostCode(e: any) {
  if(e.target.value.length == 0) {
    setClassEr8('error_box');
  }
  if(e.target.value.length == 7) {
    setClassEr8('');
    dispatch(fetchPostCodeRequest(e.target.value));   
    setPCode(e.target.value); 
    if(address.length == 0){
      setClassEr9('error_box');
    }
  } else {
    setPCode(''); 
    setClassEr8('error_box');
    setFullAddress('');
    setAddress('');
    if(address.length == 0){
      setClassEr9('error_box');
    }
  }
}

function searchPostCode() {
  if(postcode.region) {
    setPrefecture_name(postcode.prefecture.name);
    setCity_name(postcode.city.name);
    setFullAddress(postcode.prefecture.name + postcode.city.name);
    setPrefecture_id(postcode.prefecture.id);
    setCity_id(postcode.city.id);
    if(address.length == 0){
      setClassEr9('error_box');
    } else {
      setClassEr9('');
    }
    setClassEr8('');
  } else {
    setClassEr8('error_box');
    setClassEr9('error_box');
    alert("郵便番号が間違っているか存在しません")
  }
}

function onChangeAddress(e: any) {
  if(e.target.value == null && e.target.value == '') {
    setClassEr9('error_box');
    setAddress('');
  } else {  
    setAddress(e.target.value);
    setClassEr9('');
  }
}

const applyData = () => {

  const updatedApplicant = {
    ...applicants.applicants.applicant,
  };   

  if (face_photo != null) {
    updatedApplicant.face_photo = face_photo;
  }

  if (Number(tel) != 0) {
    updatedApplicant.mobile_number = tel;
  }
  if (!finalEducationFlg) {
    updatedApplicant.educational_background.final_education_id = Number(selectedSkills32);
  }

  if (!employmentstatusesFlg) {
    updatedApplicant.employment_statuses = selectedSkills27;
  }
  if (!annualIncomeFlg) {
    updatedApplicant.preferred.annual_income_id= Number(selectedSkills26);
  }
  if (!graduationYearFlg) {
    updatedApplicant.educational_background.graduation_date = graduation_date;
  }
  if (recentFlg) {
    updatedApplicant.job_career_recent.company = company;
    updatedApplicant.job_career_recent.arrival_date = arrival_date;
    updatedApplicant.job_career_recent.industry_id = industry_id;
    updatedApplicant.job_career_recent.job_category_id = job_category_id;
    updatedApplicant.job_career_recent.departure_date = departure_date;
    updatedApplicant.job_career_recent.business_content = textareaValue;
  }
  if (!handiFlg) {
    updatedApplicant.handicap_qualifications = applicantHandicapQualifications.map((qualification: any) => ({
    // updatedApplicant.handicap_qualifications = handicap_qualifications.map((qualification: any) => ({
      ...qualification,
      disability_certificate_acquisition_month: qualification.disability_certificate_acquisition_month,
      characteristics_and_countermeasures: qualification.characteristics_and_countermeasures,
      disability_certificate_id: qualification.disability_certificate_id,
      disability_certificate_status_id: qualification.disability_certificate_status_id,
      handicap_id: qualification.handicap_id,
      process: qualification.process,
    }));
  }
  if(consideration_conditions_required_exists != null) {
    updatedApplicant.consideration_conditions_required_exists = consideration_conditions_required_exists;

    if(consideration_conditions_required.length > 0) {
      updatedApplicant.consideration_conditions_required = consideration_conditions_required;
    }
  }
  if(consideration_condition_supplemental != null) {
    updatedApplicant.consideration_condition_supplemental = consideration_condition_supplemental;
  }
  if(pCode != "") {
    updatedApplicant.postcode = pCode;
  }
  if(address != null && address != '') {
    updatedApplicant.address = address;
  }
  if(prefecture_id != null && prefecture_id != '') {
    updatedApplicant.prefecture_id = prefecture_id;
  }
  if(city_id != null && city_id != '') {
    updatedApplicant.city_id = city_id;
  }

  const updatedApplicants = {
    ...applicants.applicants,
    applicant: updatedApplicant,
  };
  if(applicant != null) {
    dispatch(fetchChangeUpdateApplicantsRequest(resourceId, updatedApplicants.applicant));
    const experience_job_category = {
      "job_category_id_1": job_category_1,
      "job_category_id_2": job_category_2,
      "job_category_id_3": job_category_3,
    };
    const oa_skills = {
      "word_experience": wordExp,
      "word": selectedSkills,
      "excel_experience": excelExp,
      "excel": selectedSkills2,
      "design": selectedSkills3,
      "program": selectedSkills4,
    };    
    const qualification = {
      "qualification_id_1": selectedSkills6,
      "qualification_id_2": selectedSkills8,
      "qualification_3": qualification_3,
    };
    const language_study = {
      "language_id_1": formattedName1,
      "level_id_1": selectedSkills16,
      "language_id_2": formattedName2,
      "level_id_2": selectedSkills18,
      "language_3": languageAppeal
    };    
    const employment_support_agency = {
      "support_agency_exists": support_agency_exists,
      "organization": organization,
      "contact_number": contact_number,
      "url": url,
      "can_contact": can_contact,
      "start_date": start_date,
      "end_date": end_date,
      "training_content": training_content
    };
    const self_pr = {
      "id": id,
      "content":selfPR,
      "movie": null
    };    
    setTimeout( () => navigate('/recruitments/' + id + '/entry/confirm', {state: {appeal: appealsId, appealItems1: job_career_appeals, 
                                                                                                     appealItems2: experience_job_category,
                                                                                                     appealItems3: oa_skills,
                                                                                                     appealItems4: qualification,
                                                                                                     appealItems5: language_study,
                                                                                                     appealItems6: employment_support_agency,
                                                                                                     appealItems7: self_pr}}), 1500);
  }
}

const handleProcessChange = (event: any, index: number) => {  
  const text = (event.target.value);
  const maxLenCount = (event.target.value.length);        
  let maxlenCheck = false;
  const updatevalue = {
      process: text,
      processMaxLength: maxlenCheck,
      processLengthCount: maxLenCount,
  }; 
  const updatedAppeals = [...applicantHandicapQualifications];
  updatedAppeals[index] = {
      ...updatedAppeals[index], 
      ...updatevalue, 
  };
  if(maxLenCount > 200) {
      maxlenCheck = true;
      const updatevalue2 = {
          process: text,
          processMaxLength: maxlenCheck,
          processLengthCount: maxLenCount,
      }; 
      updatedAppeals[index] = {
          ...updatedAppeals[index], 
          ...updatevalue2, 
      };
  }          
  setApplicantHandicapQualifications(updatedAppeals);
};

function toggleOpenProcess (id: any) {
  if (readOnlyProcess) { return }
  let c = [];
  for(let i = 0; i < AreaOpenProcess.length; i++) {
      c.push(AreaOpenProcess[i]);
  }
  c[id] = !c[id];
  setAreaOpenProcess(c);
}

const handleCharacteristicsChange = (event: any, index: number) => {
  const maxLenCount = (event.target.value.length);        
  let maxlenCheck = false;
  const text = (event.target.value);
  const updatevalue = {
      characteristics_and_countermeasures: text,
      characterMaxLength: maxlenCheck,
      characterMaxLengthCount: maxLenCount
    };
  
  const updatedAppeals = [...applicantHandicapQualifications];
  updatedAppeals[index] = {
  ...updatedAppeals[index], 
  ...updatevalue, 
  };
  if(maxLenCount > 200) {
      maxlenCheck = true;
      const updatevalue2 = {
          characteristics_and_countermeasures: text,
          characterMaxLength: maxlenCheck,
          characterMaxLengthCount: maxLenCount,
      }; 
      updatedAppeals[index] = {
          ...updatedAppeals[index], 
          ...updatevalue2, 
      };
  } 
  setApplicantHandicapQualifications(updatedAppeals);
};

function toggleOpenCharacter (id: any) {
  if (readOnlyCharacter) { return }
  let c = [];
  for(let i = 0; i < AreaOpenCharacter.length; i++) {
      c.push(AreaOpenCharacter[i]);
  }
  c[id] = !c[id];
  setAreaOpenCharacter(c);
}

function deleteitem(e: any) {
  let c = [];
  for(let i = 0; i < applicantHandicapQualifications.length; i++) {
      c.push(applicantHandicapQualifications[i]);
  }
  if(c[e]) {
      c.splice(e, 1);
  }
  setApplicantHandicapQualifications(c);
}

const considerationRequiredModalToggle = () => {
  setConsiderationConditionsRequiredModal(!considerationConditionsRequiredModal);
};

function toggleOpenConsiderationRequired () {
  if (readOnlyConsiderationRequired) { return }
  setAreaOpenConsiderationRequired(!AreaOpenConsiderationRequired);
}

const handleInputsupplment = (event: any) => {
  setConsiderationConditionSupplemental(event.target.value);
  if(event.target.value.length > 200) {
    setConsiderationConditionSupplementalMaxLengthError(true);
    setConsiderationConditionSupplementalMaxLengthCount(event.target.value.length);
  } else {            
      setConsiderationConditionSupplementalMaxLengthError(false);
  }
};

const radioChange = (event: any) => {
  const value = event.target.value;
  if(value == 'true') {
    setConsiderationConditionsRequiredExists(true);
  } else {
    setConsiderationConditionsRequiredExists(false);
  }
  if(value == 'true') {
    setClassEr10("error_box");
  } else {
    setClassEr10("");
  }
}

function charaLimit (inputText: any, limit: any) {
  if (typeof inputText != "string") { return '' }
  return inputText.length > limit ? (inputText.length - limit) + '文字オーバー' : ''
}

    return (
      <main id="jobs">
        {loadScreen ? <Loader /> : <></>}
        <section className="entry wrapper"><h2 className="ttl type3">エントリー</h2>
        <p>
        { company_name }「{ job_title }」の求人にエントリーいたします。<br/>
        必須項目へ入力の上、確認ボタンを押してください。
        </p>
          <form className="entry_cont" style={{width: "100%"}}>
            <div className="cont1">
              <div>
                <div className="ttl_box">
                  <h3 className="ttl">エントリー時に企業に伝えたい項目</h3>
                </div>
                
                {/* case0 */}
                {appealsId.length == 0 ?(
                  <div className="not_set"><p className="red">
                    まだ設定されていません。<br/>エントリー時に企業に伝えたい項目を設定すると書類選考の確率がアップします。
                    </p> <a onClick={(e) => handleLinkClick(0, 0)} className="btn type3 yellow">設定する</a>
                  </div>
                ): 
                <dl>
                  {!selectedAppealItemId1 ?                    
                    <>
                      <dt className="style3">
                        未登録
                      </dt>
                      <dd className="not_set">
                        <div>
                          未入力
                        </div> 
                        <a onClick={(e) => handleLinkClick(0, 1)} className="btn type3 yellow">設定する</a>
                      </dd>
                    </>
                  : 
                  <>
                  {appealsId[0] == "1" &&(
                  
                  <>
                    <dt className="">
                      アピールしたい職歴
                      <a href="javascript:void(0)" onClick={(e) => handleLinkClick(1, 1)}>
                        <span>項目を変更する</span>
                      </a>
                    </dt>
                    <dd className="">
                      <div className="box">
                        {job_career_appeals.length == 0 &&
                          <div className="nest_child acc_list_cont"><p className="acc_ttl fixed">
                            <a onClick={handleLinkClick3} title="ポップアップが立ち上がります。TABキーを押して選択してください。">アピールしたい職歴を追加する</a></p>
                          </div>
                        }
                        {job_career_appeals.length != 0 &&
                          <div className="nest_child">
                            {job_career_appeals.map((item, index) => (
                              <>
                                <a
                                  key={index}
                                  tabIndex={0}
                                  title="ポップアップが立ち上がります。TABキーを押して選択してください。"
                                  className="edit_box fixed"
                                  onClick={() => handleLinkClick3()}
                                >
                                  <div className="txt_cont">
                                    <h4>アピールしたい職歴</h4>
                                    <p>
                                      {item.company || '会社名未入力'} / {findValue(item.industry_id, 'industries')} <br />
                                      {findValue(item.job_category_id, 'job_categories')} <br />
                                      {formatYM(item.arrival_date)} 〜 {formatYM(item.departure_date) || '現在' }
                                    </p>
                                  </div>
                                  <div className="icon_cont">
                                    <span className="edit">
                                      <img
                                        src="/assets/img/mypage/memo_icon.svg"
                                        onClick={() => handleLinkClick33(index)}
                                        alt="memo_icon" />
                                    </span>
                                  </div>
                                </a>
                                <div className="textarea_cont">
                                    <h4>業務内容（２００文字以内）</h4>
                                    <textarea cols={30} rows={10} value={item.business_content} onChange={(event) => {handleContentChange(event, index)}}></textarea>
                                    {/* <p className="text_length_alert"></p> */}
                                    {job_career_appeals[index].business_content_maxLength && job_career_appeals[index].business_content_maxLength == true ?
                                        <p className="text_length_alert">{job_career_appeals[index].business_content_lengthCount}文字オーバー</p>
                                    : <></>}
                                </div>
                                <p className="acc_ttl del">
                                  <a onClick={() => handleDeleteCareerAppeal(index)}>アピールしたい職歴を削除する</a>
                                </p>
                              </>
                            ))}
                            <div className="nest_child acc_list_cont">
                              <p className="acc_ttl">
                                <a onClick={() => handleLinkClick3()} title="ポップアップが立ち上がります。TABキーを押して選択してください。">
                                  アピールしたい職歴を追加する
                                </a>
                              </p>
                            </div>
                          </div>
                          }
                      </div> 
                      <a href="" className="btn type3 yellow" style={{ display: "none" }}>設定する</a>
                    </dd>
                  </>                 
                )}
                {appealsId[0] == "5" && (                
                  <>
                    <dt className="">
                      アピールしたい語学 
                      <a href="javascript:void(0)" onClick={(e) => handleLinkClick(5, 1)}>
                        <span>項目を変更する</span>
                      </a>
                    </dt>
                    <dd className="">
                      <div>
                        <div className="nest_child select_cont">
                          <h4>アピールしたい語学1</h4>
                          <p className="select" style={{ backgroundColor: selectedSkills16.length != 0 ? "white" : "#dbffff" }}>
                            <a onClick={toggleDialog10}>
                              {selectedSkills16.length != 0 ? (
                                <span>{findValue(selectedSkills15, 'languages')}, {findValue(selectedSkills16, 'levels')}レベル</span>
                              ) : (
                                <span>未登録</span>
                              )}
                            </a>
                          </p>
                        </div>

                        <div className="nest_child select_cont">
                          <h4>アピールしたい語学2</h4>
                          <p className="select" style={{ backgroundColor: selectedSkills18.length != 0 ? "white" : "#dbffff" }}>
                            <a onClick={toggleDialog11}>
                              {selectedSkills18.length != 0 ? (
                                <span>{findValue(formattedName2, 'languages')}, {findValue(selectedSkills18, 'levels')}レベル</span>
                              ) : (
                                <span>未登録</span>
                              )}
                            </a>
                          </p>
                        </div>
                           
                        <div className="textarea_cont nest_child">
                          <h4>アピールしたい語学3</h4>
                          <label>
                            <input
                              type="text"
                              placeholder="言語名、習得レベル"
                              value={languageAppeal}
                              onChange={handleInputChange} />
                          </label>
                          <p className="note">※一覧にない言語などがあればご入力ください</p>
                        </div>
                      </div>
                    </dd>
                  </>                
                )}                
                        
                {/* <div className="textarea_cont nest_child">
                  <h4>アピールしたい語学3</h4>
                  <label>
                    <input
                      type="text"
                      placeholder="言語名、習得レベル"
                      value={languageAppeal}
                      onChange={handleInputChange} />
                  </label>
                  <p className="note">※一覧にない言語などがあればご入力ください</p>
                </div> */}
                {appealsId[0] == "6" && (                
                  <>
                    <dt className="">
                      就労支援機関 
                      <a href="javascript:void(0)" onClick={(e) => handleLinkClick(6, 1)}>
                        <span>項目を変更する</span>
                      </a>
                    </dt>
                    <dd className="">
                      <div className="nest_child select_cont">
                        <h4>就労支援機関の利用有無</h4>
                        <div className="radio">
                          <label>
                            <input type="radio" name="support_agency_exists" 
                              checked={support_agency_exists} 
                              onChange={handleRadioChange2} />有
                          </label> 
                          <label>
                            <input type="radio" name="support_agency_exists" 
                            checked={!support_agency_exists} 
                            onChange={handleRadioChange2} />無
                          </label>
                        </div>
                        {support_agency_exists && (
                          <div className="">
                            <div>
                              <div className="textarea_cont nest_child">
                                <h4>機関・企業名</h4>
                                <label>
                                  <input
                                    type="text"
                                    placeholder="例）○○職業センター、○○福祉医療機構"
                                    value={organization}
                                    onChange={(e) => setOrganization(e.target.value)} />
                                </label>
                              </div>
                              <div className="textarea_cont nest_child">
                                <h4>連絡先</h4>
                                <label>
                                  <input
                                    type="text"
                                    placeholder="0300000000"
                                    value={contact_number}
                                    onChange={(e) => setContactNumber(e.target.value)} />
                                </label>
                              </div>
                              <div className="textarea_cont nest_child">
                                <h4>URL</h4>
                                <label>
                                  <input
                                    type="text"
                                    placeholder="https://www.example.com"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)} />
                                </label>
                              </div>
                              <div className="nest_child radio_cont">
                                <h4>エントリー企業との連携可否</h4>
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      value="true"
                                      checked={can_contact == true}
                                      onChange={() => setCanContact(true)} />
                                    可
                                  </label>
                                  <label>
                                    <input
                                      type="radio"
                                      value="false"
                                      checked={can_contact == false}
                                      onChange={() => setCanContact(false)} />
                                    否
                                  </label>
                                </div>
                              </div>
                              <div className="nest_child select_cont">
                                <h4>利用期間 開始月</h4>                              
                                <p className="select">
                                  <a onClick={toggleDialog14}>
                                    {start_date != null ? 
                                      <>
                                        {formatYM(start_date)}
                                      </>
                                      : 
                                        '選択してください'
                                    }
                                    {/* {start_date != null ? (
                                      <a>{formatYM(start_date)}</a>
                                    ) : (
                                      selectedSkills29.length != 0 ? (
                                        <a>{selectedSkills28}年{selectedSkills29}月</a>
                                      ) : (
                                        <span>選択してください</span>
                                      )
                                    )} */}
                                    
                                  </a></p>
                              </div>
                              <div className="nest_child select_cont">
                                <h4>利用期間 終了月</h4>                                

                                <p className="select">
                                  <a onClick={toggleDialog15}>
                                    {end_date != null ? 
                                      <>
                                        {formatEmploymentYM(end_date)}
                                      </>
                                      : 
                                        '選択してください'
                                    }
                                    {/* {end_date?.includes('9999-12') ? (
                                      '終了月なし（継続中）'
                                    ) : (
                                      end_date != null ? (
                                        <a>{formatYM(end_date)}</a>
                                      ) : (
                                        selectedSkills31.length != 0 ? (
                                          selectedSkills31.includes('9999-12') ? (
                                            <a>終了月なし（継続中）</a>
                                          ) : (
                                            <span>{selectedSkills30}年{selectedSkills31}月</span>
                                          )
                                        ) : (
                                          <span>選択してください</span>
                                        )
                                      )
                                    )} */}
                                  </a></p>
                              </div>
                              <div className="textarea_cont nest_child">
                                <h4>訓練内容（２００文字以内）</h4>
                                <textarea
                                  cols={30}
                                  rows={10}
                                  placeholder="例）研修で学んだこと、グループワークなどで取り組んだ課題"
                                  value={training_content}
                                  onChange={handleTextareaChange}
                                ></textarea>
                                {trainingContentError == true ?
                                  <p className="text_length_alert">{trainingContentErrorCount}文字オーバー</p>
                                : <></>}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </dd></>

                )}
                {appealsId[0] == "7" && (                
                  <>
                    <dt className="">
                      自己PR 
                      <a href="javascript:void(0)" onClick={(e) => handleLinkClick(7, 1)}>
                        <span>項目を変更する</span>
                      </a>
                    </dt>
                    <dd className="">
                      <div className="textarea_cont">
                        <h4>自己PR（４００文字以内）</h4>
                        <textarea
                          cols={30}
                          rows={10}
                          placeholder="活かせるスキルや意気込み、または「こういう合理的配慮・環境があれば○○ができる」などのPRを書いてください。"
                          defaultValue={selfPR}
                          onChange={handlePrTextareaChange}
                        ></textarea>
                        <p className="text_length_alert">{over}</p>
                      </div>
                    </dd>
                  </>                
                )}
                {appealsId[0] == "3" && (                
                  <>
                  <dt>アピールしたいPCスキル
                    <a onClick={(e) => handleLinkClick(3, 1)}>
                      <span>項目を変更する</span>
                    </a>
                  </dt>
                  <dd>
                    <div>
                      <div className="nest_child select_cont">
                        <h4>Word</h4>
                        <div className="radio">
                          <label><input type="radio" name="word_experience" checked={wordExp} onChange={toggleWordExperience} />あり</label>
                          <label><input type="radio" name="word_experience" checked={!wordExp} onChange={toggleWordExperience} />なし</label>
                        </div>
                        {wordExp && selectedSkills.length == 0 && (
                          <div className="nest_child acc_list_cont">
                            <p className="acc_ttl fixed"><a onClick={toggleDialog}>Wordスキルを追加する</a></p>
                          </div>
                        )}
                        {/* {dialogOpen && (
                          <div role="dialog" tabIndex={0} className="modal_box checkbox">
                            <div className="modal_inner">
                              <h4>Word</h4>
                              <form>
                                <h5 className="modal_ttl">アピールしたいWordスキルを選択してください</h5>
                                <p className="note">※複数選択可能</p>
                                <div className="slider_item">
                                  {oa_skills &&
                                    oa_skills
                                      .filter(data => data.oa_skill_category_id == 1)
                                      .map((data: any, index: any) => (
                                        <label key={index}>
                                          <input
                                            type="checkbox"
                                            name="アピールしたいPCスキル（Word）"
                                            value={data.id}
                                            onChange={handleCheckboxChange}
                                            checked={selectedSkills.includes(data.id.toString())} />
                                          <span>{data.name}</span>
                                        </label>
                                      ))}
                                </div>
                              </form>
                              <p className="complete" onClick={handleComplete}>完了</p>
                              <p className="close" onClick={toggleDialog}><span className="material-icons">close</span></p>
                            </div>
                            <div className="overlay" onClick={toggleDialog}></div>
                          </div>
                        )} */}
                        {selectedSkills.length != 0 && (
                          <div className="nest_child">
                            <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                              <div className="txt_cont" onClick={toggleDialog}>
                                <h4>Wordスキルを追加する</h4>
                                <p><span>{selectedSkills.map((id, index) => (
                                  <React.Fragment key={id}>
                                    {index > 0 && ' / '}
                                    {findValue(id, 'oa_skills')}
                                  </React.Fragment>
                                ))}
                                </span> <span style={{ display: "none" }}>/</span></p>
                              </div>
                              <div className="icon_cont">
                                <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog} alt="memo_icon" /></span>
                              </div></a>
                          </div>
                        )}
                      </div>
                      <div className="nest_child select_cont">
                        <h4>Excel</h4>
                        <div className="radio">
                          <label><input type="radio" name="excel_experience" checked={excelExp} onChange={toggleExcelExperience} />あり</label>
                          <label><input type="radio" name="excel_experience" checked={!excelExp} onChange={toggleExcelExperience} />なし</label>
                        </div>
                      </div>
                      {excelExp && selectedSkills2.length == 0 && (
                        <div className="nest_child acc_list_cont">
                          <p className="acc_ttl fixed"><a onClick={toggleDialog2}>Excelスキルを追加する</a></p>
                        </div>
                      )}
                      
                      {selectedSkills2.length != 0 && (
                        <div className="nest_child">
                          <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                            <div className="txt_cont" onClick={toggleDialog2}>
                              <h4>Excelスキルを追加する</h4>
                              <p><span>{selectedSkills2.map((id, index) => (
                                <React.Fragment key={id}>
                                  {index > 0 && ' / '}
                                  {findValue(id, 'oa_skills')}
                                </React.Fragment>
                              ))}
                              </span> <span style={{ display: "none" }}>/</span></p>
                            </div>
                            <div className="icon_cont">
                              <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog2} alt="memo_icon" /></span>
                            </div></a>
                        </div>
                      )}
                      <div className="nest_child select_cont">
                        <h4>デザイン系・その他事務系</h4>
                        {selectedSkills3.length == 0 && (
                          <div className="nest_child acc_list_cont">
                            <p className="acc_ttl fixed"><a onClick={toggleDialog3}>デザイン系・その他事務系スキルを追加する</a></p>
                          </div>
                        )}
                        {/* {dialogOpen3 && (<div role="dialog" tabIndex={0} className="modal_box checkbox">
                          <div className="modal_inner">
                            <h4>アピールしたいPCスキル（デザイン系・その他事務系）</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたいデザイン系・その他事務系スキルを選択してください</h5>
                              <p className="note">※複数選択可能</p>
                              <div tabIndex={0} className="slider_item">
                                {oa_skills &&
                                  oa_skills
                                    .filter(data => data.oa_skill_category_id == 3)
                                    .map((data: any, index: any) => (
                                      <label key={index}>
                                        <input
                                          type="checkbox"
                                          name="アピールしたいPCスキル（デザイン系・その他事務系）"
                                          value={data.id}
                                          onChange={handleCheckboxChange3}
                                          checked={selectedSkills3.includes(data.id.toString())} />
                                        <span>{data.name}</span>
                                      </label>
                                    ))}
                              </div>
                            </form>
                            <p tabIndex={0} className="complete" onClick={handleComplete3}>完了</p>
                            <p tabIndex={0} className="close" onClick={toggleDialog3}><span className="material-icons">close</span></p>
                          </div>
                          <div tabIndex={0} className="overlay" onClick={toggleDialog3}></div>
                        </div>
                        )} */}
                        {selectedSkills3.length != 0 && (
                          <div className="nest_child">
                            <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                              <div className="txt_cont" onClick={toggleDialog3}>
                                <h4>デザイン系・その他事務系スキルを追加する</h4>
                                <p><span>{selectedSkills3.map((id, index) => (
                                  <React.Fragment key={id}>
                                    {index > 0 && ' / '}
                                    {findValue(id, 'oa_skills')}
                                  </React.Fragment>
                                ))}</span> <span style={{ display: "none" }}>/</span></p>
                              </div>
                              <div className="icon_cont">
                                <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog3} alt="memo_icon" /></span>
                              </div></a>
                          </div>
                        )}
                      </div>
                      <div className="nest_child select_cont">
                        <h4>プログラミング系</h4>
                        {selectedSkills4.length == 0 && (
                          <div className="nest_child acc_list_cont">
                            <p className="acc_ttl fixed"><a onClick={toggleDialog4}>プログラミング系スキルを追加する</a></p>
                          </div>
                        )}
                        
                        {selectedSkills4.length != 0 && (
                          <div className="nest_child">
                            <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                              <div className="txt_cont" onClick={toggleDialog4}>
                                <h4>プログラミング系スキルを追加する</h4>
                                <p><span>{selectedSkills4.map((id, index) => (
                                  <React.Fragment key={id}>
                                    {index > 0 && ' / '}
                                    {findValue(id, 'oa_skills')}
                                  </React.Fragment>
                                ))}</span> <span style={{ display: "none" }}>/</span></p>
                              </div>
                              <div className="icon_cont">
                                <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog4} alt="memo_icon" /></span>
                              </div></a>
                          </div>
                        )}
                      </div>
                    </div>
                  </dd></>
                
                )}
                {appealsId[0] == "4" && (                
                  <>
                  <dt>アピールしたい資格 
                    <a href="javascript:void(0)" onClick={(e) => handleLinkClick(4, 1)}>
                      <span>項目を変更する</span>
                    </a>
                  </dt>
                  <dd>
                    <div>
                      <div className="nest_child select_cont">
                        <h4>アピールしたい資格1</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills6.length != 0 ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog5}>
                            {selectedSkills6.length != 0 ? (
                              <span>{findValue(selectedSkills6, 'qualifications')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen5 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>アピールしたい資格1</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい資格を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills5.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onChange={qualification1Toggle} /> <span>なし</span></label>
                                          }
                                          {selectedSkills5.length == 0 &&
                                            qualification_categories
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange5}
                                                    checked={selectedSkills5.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills6.length == 0 &&
                                            qualifications
                                              .filter(data => data.qualification_category_id == kills5)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange6}
                                                    checked={selectedSkills6.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog5}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog5}></div>
                        </div>
                      )} */}
                      <div className="nest_child select_cont">
                        <h4>アピールしたい資格2</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills8.length != 0 ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog6}>
                            {selectedSkills8.length != 0 ? (
                              <span>{findValue(selectedSkills8, 'qualifications')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen6 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>アピールしたい資格2</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい資格を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills17.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={qualification2Toggle}/> <span>なし</span></label>
                                          }
                                          {selectedSkills7.length == 0 &&
                                            qualification_categories
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange7}
                                                    checked={selectedSkills7.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills8.length == 0 &&
                                            qualifications
                                              .filter(data => data.qualification_category_id == kills6)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange8}
                                                    checked={selectedSkills8.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog6}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog6}></div>
                        </div>
                      )} */}
                      <div className="textarea_cont nest_child">
                        <h4>アピールしたい資格3</h4>
                        <label><input
                          type="text"
                          placeholder="資格名"
                          value={qualification_3}
                          onChange={handleQualificationInputChange} />
                          <p className="note">※一覧にない資格などがあればご入力ください</p>
                        </label>
                      </div>
                    </div>
                  </dd></>
                
                )}
                {appealsId[0] == "2" && (                
                  <>
                  <dt>アピールしたい経験職種
                    <a href="javascript:void(0)" onClick={(e) => handleLinkClick(2, 1)}>
                      <span>項目を変更する</span>
                    </a>
                  </dt>
                  <dd>
                    <div className="experience_point">
                      <div className="nest_child select_cont">
                        <h4>経験職種1</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills10 != null ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog7}>
                            {job_category_1 != null ? (
                              <span>{findValue(job_category_1, 'job_categories')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen7 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>経験職種1</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい経験職種を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills9.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={jobCategory1Toggle} /> <span>なし</span></label>
                                          }
                                          {selectedSkills9.length == 0 &&
                                            job_category_types
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange9}
                                                    checked={selectedSkills9.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills10.length == 0 &&
                                            job_categories
                                              .filter(data => data.job_category_type_id == kills7)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange10}
                                                    checked={selectedSkills10.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog7}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog7}></div>
                        </div>
                      )} */}
                      <div className="nest_child select_cont">
                        <h4>経験職種2</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills12 != null ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog8}>
                            {job_category_2 != null ? (
                              <span>{findValue(job_category_2, 'job_categories')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      
                      <div className="nest_child select_cont">
                        <h4>経験職種3</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills14 != null ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog9}>
                            {job_category_3 != undefined && job_category_3 != null ? (
                              <span>{findValue(job_category_3, 'job_categories')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen9 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>経験職種3</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい経験職種を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills13.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={jobCategory3Toggle}/> <span>なし</span></label>
                                          }
                                          {selectedSkills13.length == 0 &&
                                            job_category_types
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange13}
                                                    checked={selectedSkills13.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills14.length == 0 &&
                                            job_categories
                                              .filter(data => data.job_category_type_id == kills9)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange14}
                                                    checked={selectedSkills14.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog9}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog9}></div>
                        </div>
                      )} */}
                    </div>
                  </dd></>
                
                )}  
              </>
            }
                  {!selectedAppealItemId2 ?
                  
                      <><dt className="style3">
                      未登録
                    </dt><dd className="not_set">
                        <div>
                          未入力
                        </div> <a onClick={(e) => handleLinkClick(0, 2)} className="btn type3 yellow">設定する</a>
                      </dd></>
                  :
                  <>                  
                  {appealsId[1] == "1" &&(
                  
                  <><dt className="">
                  アピールしたい職歴
                  <a href="javascript:void(0)" onClick={(e) => handleLinkClick(1, 2)}><span>項目を変更する</span></a>
                </dt><dd className="">
                    <div className="box">
                      {job_career_appeals.length == 0 &&
                        <div className="nest_child acc_list_cont"><p className="acc_ttl fixed">
                          <a onClick={handleLinkClick3} title="ポップアップが立ち上がります。TABキーを押して選択してください。">アピールしたい職歴を追加する</a></p>
                        </div>}
                      {job_career_appeals.length != 0 &&
                        <div className="nest_child">
                          {job_career_appeals.map((item, index) => (
                            <><a
                              key={index}
                              tabIndex={0}
                              title="ポップアップが立ち上がります。TABキーを押して選択してください。"
                              className="edit_box fixed"
                              onClick={() => handleLinkClick3()}
                            >
                              <div className="txt_cont">
                                <h4>アピールしたい職歴</h4>
                                <p>
                                  {item.company || '会社名未入力' } / {findValue(item.industry_id, 'industries')} <br />
                                  {findValue(item.job_category_id, 'job_categories')} <br />
                                  {formatYM(item.arrival_date)} 〜 {formatYM(item.departure_date) || '現在' }
                                </p>
                              </div>
                              <div className="icon_cont">
                                <span className="edit">
                                  <img
                                    src="/assets/img/mypage/memo_icon.svg"
                                    onClick={() => handleLinkClick33(index)}
                                    alt="memo_icon" />
                                </span>
                              </div>
                            </a><div className="textarea_cont">
                                <h4>業務内容（２００文字以内）</h4>
                                <textarea cols={30} rows={10} value={item.business_content} onChange={(event) => {handleContentChange(event, index)}}></textarea>
                                {/* <p className="text_length_alert"></p> */}
                                {job_career_appeals[index].business_content_maxLength && job_career_appeals[index].business_content_maxLength == true ?
                                  <p className="text_length_alert">{job_career_appeals[index].business_content_lengthCount}文字オーバー</p>
                                : <></>}
                              </div>
                              <p className="acc_ttl del">
                                <a onClick={() => handleDeleteCareerAppeal(index)}>アピールしたい職歴を削除する</a>
                              </p></>

                          ))}
                          <div className="nest_child acc_list_cont">
                            <p className="acc_ttl">
                              <a onClick={() => handleLinkClick3()} title="ポップアップが立ち上がります。TABキーを押して選択してください。">
                                アピールしたい職歴を追加する
                              </a>
                            </p>

                          </div>
                        </div>}
                    </div> <a href="" className="btn type3 yellow" style={{ display: "none" }}>設定する</a>
                  </dd></> 
                
                )}
                {/* {isModalOpen3 &&  (
                <div role="dialog" tabIndex={0} className="modal_box">
                <div className="modal_inner">
                  <h4>アピールしたい職歴</h4>
                  <form>
                    <h5 className="modal_ttl">会社名を入力してください</h5>
                    <div dir="ltr" className="slider slick-slider slick-initialized">
                      <div className="slick-list">
                        <div className="slick-track" style={{opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%"}}>
                          <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                            <div>
                              <div tabIndex={-1} className="slider_item" style={{width: "100%", display: "inline-block"}}>
                                {compname.length == 0 && (
                                <><label className="input_text">
                                      <input type="text" tabIndex={0} value={cname} onChange={handleNameChange} />
                                    </label><a id="btn_next" tabIndex={0} onClick={handleNextClick}> 次へ</a></>                           
                                )}   
                                {compname.length > 0 && selectedSkills19.length == 0 &&
                                    yearArray
                                      .map((year) => (
                                        <label className="column3" key={year}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={year}
                                            onChange={handleCheckboxChange19}
                                            checked={selectedSkills19.includes(year)}
                                          />
                                          <span>{year} 年</span>
                                        </label>
                                      ))
                                }
                                {selectedSkills19.length > 0 && selectedSkills20.length == 0 &&
                                    months
                                      .map((month) => (
                                        <label className="column3" key={month}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={month}
                                            onChange={handleCheckboxChange20}
                                            checked={selectedSkills20.includes(month)}
                                          />
                                          <span>{month} 月</span>
                                        </label>
                                      ))
                                }
                                {selectedSkills20.length > 0 && selectedSkills21.length == 0 && (
                                <label className="column3" >
                                          <input
                                            type="radio"
                                            name=""
                                            value="在職中"
                                            onChange={handleCheckboxChange20}
                                            checked={selectedSkills21.includes('在職中')}
                                          />
                                          <span>在職中</span>
                                        </label>
                                )}
                                {selectedSkills20.length > 0 && selectedSkills21.length == 0 &&
                                    years
                                      .map((year) => (
                                        <label className="column3" key={year}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={year}
                                            onChange={handleCheckboxChange21}
                                            checked={selectedSkills21.includes(year)}
                                          />
                                          <span>{year} 年</span>
                                        </label>
                                      ))
                                }
                                {selectedSkills21.length > 0 && selectedSkills25.length == 0 &&
                                    months
                                      .map((month) => (
                                        <label className="column3" key={month}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={month}
                                            onChange={handleCheckboxChange25}
                                            checked={selectedSkills25.includes(month)}
                                          />
                                          <span>{month} 月</span>
                                        </label>
                                      ))
                                }
                                {selectedSkills25.length > 0 && selectedSkills22.length == 0 &&
                                    industries
                                      .map((data: any, index: any) => (
                                        <label className="column3" key={index}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={data.id}
                                            onChange={handleCheckboxChange22}
                                            checked={selectedSkills22.includes(data.id.toString())}
                                          />
                                          <span>{data.name}</span>
                                        </label>
                                      ))
                                }
                                {selectedSkills22.length > 0 && selectedSkills23.length == 0 &&
                                    job_category_types
                                      .map((data: any, index: any) => (
                                        <label className="column3" key={index}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={data.id}
                                            onChange={handleCheckboxChange23}
                                            checked={selectedSkills23.includes(data.id.toString())}
                                          />
                                          <span>{data.name}</span>
                                        </label>
                                      ))
                                }
                                {selectedSkills23.length > 0 && selectedSkills24.length == 0 &&
                                    job_categories
                                      .filter(data => data.job_category_type_id == kills10) 
                                      .map((data: any, index: any) => (
                                        <label className="column3" key={index}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={data.id}
                                            onChange={handleCheckboxChange24}
                                            checked={selectedSkills24.includes(data.id.toString())}
                                          />
                                          <span>{data.name}</span>
                                        </label>
                                      ))
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <p tabIndex={0} className="close" onClick={handleCloseModal3}><span className="material-icons">close</span></p>
                </div>
                <div className="overlay" onClick={handleCloseModal3}></div>
                </div>
                )} */}
                {appealsId[1] == "5" &&(
                
                  <><dt className="">
                    アピールしたい語学 <a href="javascript:void(0)" onClick={(e) => handleLinkClick(5, 2)}><span>項目を変更する</span></a>
                  </dt><dd className="">
                      <div>
                        <div className="nest_child select_cont">
                          <h4>アピールしたい語学1</h4>
                          <p className="select" style={{ backgroundColor: selectedSkills16.length != 0 ? "white" : "#dbffff" }}>
                            <a onClick={toggleDialog10}>
                              {selectedSkills16.length != 0 ? (
                                <span>{findValue(selectedSkills15, 'languages')}, {findValue(selectedSkills16, 'levels')}レベル</span>
                              ) : (
                                <span>未登録</span>
                              )}
                            </a>
                          </p>
                        </div>
                        {/* {dialogOpen10 && (
                          <div role="dialog" tabIndex={0} className="modal_box">
                            <div className="modal_inner">
                              <h4>アピールしたい語学1</h4>
                              <form>
                                <h5 className="modal_ttl">アピールしたい語学を選択してください</h5>
                                <div dir="ltr" className="slider slick-slider slick-initialized">
                                  <div className="slick-list">
                                    <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                      <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                        <div>
                                          <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                            {selectedSkills15.length == 0 &&
                                              <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={language1Toggle} /> <span>なし</span></label>}
                                            {selectedSkills15.length == 0 &&
                                              languages
                                                .map((data: any, index: any) => (
                                                  <label className="column3" key={index}>
                                                    <input
                                                      type="radio"
                                                      name=""
                                                      value={data.id}
                                                      onChange={handleCheckboxChange15}
                                                      checked={selectedSkills15.includes(data.id.toString())} />
                                                    <span>{data.name}</span>
                                                  </label>
                                                ))}
                                            {selectedSkills16.length == 0 &&
                                              levels
                                                .map((data: any, index: any) => (
                                                  <label className="column3" key={index}>
                                                    <input
                                                      type="radio"
                                                      name=""
                                                      value={data.id}
                                                      onChange={handleCheckboxChange16}
                                                      checked={selectedSkills16.includes(data.id.toString())} />
                                                    <span>{data.name}</span>
                                                  </label>
                                                ))}
                                          </div>
                                        </div>
                                      </div>
                                      <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                              <p className="close"><span className="material-icons" onClick={toggleDialog10}>close</span></p>
                            </div>
                            <div className="overlay" onClick={toggleDialog10}></div>
                          </div>
                        )} */}
                        <div className="nest_child select_cont">
                          <h4>アピールしたい語学2</h4>
                          <p className="select" style={{ backgroundColor: selectedSkills18.length != 0 ? "white" : "#dbffff" }}>
                            <a onClick={toggleDialog11}>
                              {selectedSkills18.length != 0 ? (
                                <span>{findValue(selectedSkills17, 'languages')}, {findValue(selectedSkills18, 'levels')}</span>
                              ) : (
                                <span>未登録</span>
                              )}
                            </a>
                          </p>
                        </div>
                        {/* {dialogOpen11 && (
                          <div role="dialog" tabIndex={0} className="modal_box">
                            <div className="modal_inner">
                              <h4>アピールしたい語学2</h4>
                              <form>
                                <h5 className="modal_ttl">アピールしたい語学を選択してください</h5>
                                <div dir="ltr" className="slider slick-slider slick-initialized">
                                  <div className="slick-list">
                                    <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                      <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                        <div>
                                          <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                            {selectedSkills17.length == 0 &&
                                              <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={language2Toggle}/> <span>なし</span></label>}
                                            {selectedSkills17.length == 0 &&
                                              languages
                                                .map((data: any, index: any) => (
                                                  <label className="column3" key={index}>
                                                    <input
                                                      type="radio"
                                                      name=""
                                                      value={data.id}
                                                      onChange={handleCheckboxChange17}
                                                      checked={selectedSkills17.includes(data.id.toString())} />
                                                    <span>{data.name}</span>
                                                  </label>
                                                ))}
                                            {selectedSkills18.length == 0 &&
                                              levels
                                                .map((data: any, index: any) => (
                                                  <label className="column3" key={index}>
                                                    <input
                                                      type="radio"
                                                      name=""
                                                      value={data.id}
                                                      onChange={handleCheckboxChange18}
                                                      checked={selectedSkills18.includes(data.id.toString())} />
                                                    <span>{data.name}</span>
                                                  </label>
                                                ))}
                                          </div>
                                        </div>
                                      </div>
                                      <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                              <p className="close"><span className="material-icons" onClick={toggleDialog11}>close</span></p>
                            </div>
                            <div className="overlay" onClick={toggleDialog11}></div>
                          </div>
                        )} */}
                        <div className="textarea_cont nest_child">
                          <h4>アピールしたい語学3</h4>
                          <label>
                            <input
                              type="text"
                              placeholder="言語名、習得レベル"
                              value={languageAppeal}
                              onChange={handleInputChange} />
                          </label>
                          <p className="note">※一覧にない言語などがあればご入力ください</p>
                        </div>
                      </div>
                    </dd></>
                
                )}
                {appealsId[1] == "6" &&(
                
                  <><dt className="">
                    就労支援機関 <a href="javascript:void(0)" onClick={(e) => handleLinkClick(6, 2)}><span>項目を変更する</span></a>
                  </dt><dd className="">
                      <div className="nest_child select_cont">
                        <h4>就労支援機関の利用有無</h4>
                        <div className="radio">
                          <label><input type="radio" name="support_agency_exists" checked={support_agency_exists} onChange={handleRadioChange2} />有</label> <label><input type="radio" name="support_agency_exists" checked={!support_agency_exists} onChange={handleRadioChange2} />無</label>
                        </div>
                        {support_agency_exists && (
                          <div className="">
                            <div>
                              <div className="textarea_cont nest_child">
                                <h4>機関・企業名</h4>
                                <label>
                                  <input
                                    type="text"
                                    placeholder="例）○○職業センター、○○福祉医療機構"
                                    value={organization}
                                    onChange={(e) => setOrganization(e.target.value)} />
                                </label>
                              </div>
                              <div className="textarea_cont nest_child">
                                <h4>連絡先</h4>
                                <label>
                                  <input
                                    type="text"
                                    placeholder="0300000000"
                                    value={contact_number}
                                    onChange={(e) => setContactNumber(e.target.value)} />
                                </label>
                              </div>
                              <div className="textarea_cont nest_child">
                                <h4>URL</h4>
                                <label>
                                  <input
                                    type="text"
                                    placeholder="https://www.example.com"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)} />
                                </label>
                              </div>
                              <div className="nest_child radio_cont">
                                <h4>エントリー企業との連携可否</h4>
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      value="true"
                                      checked={can_contact == true}
                                      onChange={() => setCanContact(true)} />
                                    可
                                  </label>
                                  <label>
                                    <input
                                      type="radio"
                                      value="false"
                                      checked={can_contact == false}
                                      onChange={() => setCanContact(false)} />
                                    否
                                  </label>
                                </div>
                              </div>
                              <div className="nest_child select_cont">
                                <h4>利用期間 開始月</h4>
                                {/* {dialogOpen14 && (
                                  <div role="dialog" tabIndex={0} className="modal_box">
                                    <div className="modal_inner">
                                      <h4>利用期間の開始月</h4>
                                      <form>
                                        <h5 className="modal_ttl">年を選択してください</h5>
                                        <div dir="ltr" className="slider slick-slider slick-initialized">
                                          <div className="slick-list">
                                            <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                              <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                                <div>
                                                  <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                                    {trigger == 0 &&
                                                      years2
                                                        .map((year) => (
                                                          <label className="column3" key={year}>
                                                            <input
                                                              type="radio"
                                                              name=""
                                                              value={year}
                                                              onChange={handleCheckboxChange28}
                                                              checked={selectedSkills28.includes(year)} />
                                                            <span>{year} 年</span>
                                                          </label>
                                                        ))}
                                                    {trigger == 1 &&
                                                      months
                                                        .map((month) => (
                                                          <label className="column3" key={month}>
                                                            <input
                                                              type="radio"
                                                              name=""
                                                              value={month}
                                                              onChange={handleCheckboxChange29}
                                                              checked={selectedSkills29.includes(month)} />
                                                            <span>{month} 月</span>
                                                          </label>
                                                        ))}
                                                  </div>
                                                </div>
                                              </div>
                                              <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                      <p className="close"><span className="material-icons" onClick={toggleDialog14}>close</span></p>
                                    </div>
                                    <div className="overlay" onClick={toggleDialog14}></div>
                                  </div>
                                )} */}

                                <p className="select">
                                  <a onClick={toggleDialog14}>
                                    {start_date != null ? 
                                      <>
                                        {formatYM(start_date)}
                                      </>
                                      : 
                                        '選択してください'
                                    }
                                    {/* {start_date != null ? (
                                      <a>{formatYM(start_date)}</a>
                                    ) : (
                                      selectedSkills29.length != 0 ? (
                                        <a>{selectedSkills28}年{selectedSkills29}月</a>
                                      ) : (
                                        <span>選択してください</span>
                                      )
                                    )} */}
                                  </a></p>
                              </div>
                              <div className="nest_child select_cont">
                                <h4>利用期間 終了月</h4>
                                {/* {dialogOpen15 && (
                                  <div role="dialog" tabIndex={0} className="modal_box">
                                    <div className="modal_inner">
                                      <h4>利用期間の終了月</h4>
                                      <form>
                                        <h5 className="modal_ttl">年を選択してください</h5>
                                        <div dir="ltr" className="slider slick-slider slick-initialized">
                                          <div className="slick-list">
                                            <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                              <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                                <div>
                                                  <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                                    {trigger2 == 0 &&
                                                      <label className="column3">
                                                        <input
                                                          type="radio"
                                                          name=""
                                                          value="9999-12"
                                                          onChange={handleCheckboxChange30}
                                                          checked={selectedSkills30.includes('終了月なし（継続中）')} />
                                                        <span>終了月なし（継続中）</span>
                                                      </label>}
                                                    {trigger2 == 0 &&
                                                      years2
                                                        .map((year) => (
                                                          <label className="column3" key={year}>
                                                            <input
                                                              type="radio"
                                                              name=""
                                                              value={year}
                                                              onChange={handleCheckboxChange30}
                                                              checked={selectedSkills30.includes(year)} />
                                                            <span>{year} 年</span>
                                                          </label>
                                                        ))}
                                                    {trigger2 == 1 && !selectedSkills30.includes('終了月なし') && (
                                                      <div>
                                                        {months.map((month) => (
                                                          <label className="column3" key={month}>
                                                            <input
                                                              type="radio"
                                                              name=""
                                                              value={month}
                                                              onChange={handleCheckboxChange31}
                                                              checked={selectedSkills31.includes(month)} />
                                                            <span>{month} 月</span>
                                                          </label>
                                                        ))}
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                      <p className="close"><span className="material-icons" onClick={toggleDialog15}>close</span></p>
                                    </div>
                                    <div className="overlay" onClick={toggleDialog15}></div>
                                  </div>
                                )} */}

                                <p className="select">
                                  <a onClick={toggleDialog15}>
                                    {end_date != null ? 
                                      <>
                                        {formatEmploymentYM(end_date)}
                                      </>
                                      : 
                                        '選択してください'
                                    }
                                    {/* {end_date?.includes('9999-12') ? (
                                      '終了月なし（継続中）'
                                    ) : (
                                      end_date != null ? (
                                        <a>{formatYM(end_date)}</a>
                                      ) : (
                                        selectedSkills31.length != 0 ? (
                                          selectedSkills31.includes('9999-12') ? (
                                            <a>終了月なし（継続中）</a>
                                          ) : (
                                            <span>{selectedSkills30}年{selectedSkills31}月</span>
                                          )
                                        ) : (
                                          <span>選択してください</span>
                                        )
                                      )
                                    )} */}
                                  </a></p>
                              </div>
                              <div className="textarea_cont nest_child">
                                <h4>訓練内容（２００文字以内）</h4>
                                <textarea
                                  cols={30}
                                  rows={10}
                                  placeholder="例）研修で学んだこと、グループワークなどで取り組んだ課題"
                                  value={training_content}
                                  onChange={handleTextareaChange}
                                ></textarea>
                                {trainingContentError == true ?
                                  <p className="text_length_alert">{trainingContentErrorCount}文字オーバー</p>
                                : <></>}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </dd></>

                )}
                {appealsId[1] == "7" &&(
                
                  <><dt className="">
                    自己PR <a href="javascript:void(0)" onClick={(e) => handleLinkClick(7, 2)}><span>項目を変更する</span></a>
                  </dt><dd className="">
                      <div className="textarea_cont">
                        <h4>自己PR（４００文字以内）</h4>
                        <textarea
                          cols={30}
                          rows={10}
                          placeholder="活かせるスキルや意気込み、または「こういう合理的配慮・環境があれば○○ができる」などのPRを書いてください。"
                          value={selfPR}
                          onChange={handlePrTextareaChange}
                        ></textarea>
                        <p className="text_length_alert">{over}</p>
                      </div>
                    </dd></>
                
                )}
                 {appealsId[1] =="3" &&(
                
                  <><dt>アピールしたいPCスキル<a href="javascript:void(0)" onClick={(e) => handleLinkClick(3, 2)}><span>項目を変更する</span></a></dt><dd>
                    <div>
                      <div className="nest_child select_cont">
                        <h4>Word</h4>
                        <div className="radio">
                          <label><input type="radio" name="word_experience" checked={wordExp} onChange={toggleWordExperience} />あり</label>
                          <label><input type="radio" name="word_experience" checked={!wordExp} onChange={toggleWordExperience} />なし</label>
                        </div>
                        {wordExp && selectedSkills.length == 0 && (
                          <div className="nest_child acc_list_cont">
                            <p className="acc_ttl fixed"><a onClick={toggleDialog}>Wordスキルを追加する</a></p>
                          </div>
                        )}
                        {/* {dialogOpen && (
                          <div role="dialog" tabIndex={0} className="modal_box checkbox">
                            <div className="modal_inner">
                              <h4>Word</h4>
                              <form>
                                <h5 className="modal_ttl">アピールしたいWordスキルを選択してください</h5>
                                <p className="note">※複数選択可能</p>
                                <div className="slider_item">
                                  {oa_skills &&
                                    oa_skills
                                      .filter(data => data.oa_skill_category_id == 1)
                                      .map((data: any, index: any) => (
                                        <label key={index}>
                                          <input
                                            type="checkbox"
                                            name="アピールしたいPCスキル（Word）"
                                            value={data.id}
                                            onChange={handleCheckboxChange}
                                            checked={selectedSkills.includes(data.id.toString())} />
                                          <span>{data.name}</span>
                                        </label>
                                      ))}
                                </div>
                              </form>
                              <p className="complete" onClick={handleComplete}>完了</p>
                              <p className="close" onClick={toggleDialog}><span className="material-icons">close</span></p>
                            </div>
                            <div className="overlay" onClick={toggleDialog}></div>
                          </div>
                        )} */}
                        {selectedSkills.length != 0 && (
                          <div className="nest_child">
                            <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                              <div className="txt_cont" onClick={toggleDialog}>
                                <h4>Wordスキルを追加する</h4>
                                <p><span>{selectedSkills.map((id, index) => (
                                  <React.Fragment key={id}>
                                    {index > 0 && ' / '}
                                    {findValue(id, 'oa_skills')}
                                  </React.Fragment>
                                ))}
                                </span> <span style={{ display: "none" }}>/</span></p>
                              </div>
                              <div className="icon_cont">
                                <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog} alt="memo_icon" /></span>
                              </div></a>
                          </div>
                        )}
                      </div>
                      <div className="nest_child select_cont">
                        <h4>Excel</h4>
                        <div className="radio">
                          <label><input type="radio" name="excel_experience" checked={excelExp} onChange={toggleExcelExperience} />あり</label>
                          <label><input type="radio" name="excel_experience" checked={!excelExp} onChange={toggleExcelExperience} />なし</label>
                        </div>
                      </div>
                      {excelExp && selectedSkills2.length == 0 && (
                        <div className="nest_child acc_list_cont">
                          <p className="acc_ttl fixed"><a onClick={toggleDialog2}>Excelスキルを追加する</a></p>
                        </div>
                      )}
                      {/* {dialogOpen2 && (
                        <div role="dialog" tabIndex={0} className="modal_box checkbox">
                          <div className="modal_inner">
                            <h4>アピールしたいPCスキル（Excel）</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたいExcelスキルを選択してください</h5>
                              <p className="note">※複数選択可能</p>
                              <div className="slider_item">
                                {oa_skills &&
                                  oa_skills
                                    .filter(data => data.oa_skill_category_id == 2)
                                    .map((data: any, index: any) => (
                                      <label key={index}>
                                        <input
                                          type="checkbox"
                                          name="アピールしたいPCスキル（Excel）"
                                          value={data.id}
                                          onChange={handleCheckboxChange2}
                                          checked={selectedSkills2.includes(data.id.toString())} />
                                        <span>{data.name}</span>
                                      </label>
                                    ))}
                              </div>
                            </form>
                            <p className="complete" onClick={handleComplete2}>完了</p>
                            <p className="close" onClick={toggleDialog2}><span className="material-icons">close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog2}></div>
                        </div>
                      )} */}
                      {selectedSkills2.length != 0 && (
                        <div className="nest_child">
                          <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                            <div className="txt_cont" onClick={toggleDialog2}>
                              <h4>Excelスキルを追加する</h4>
                              <p><span>{selectedSkills2.map((id, index) => (
                                <React.Fragment key={id}>
                                  {index > 0 && ' / '}
                                  {findValue(id, 'oa_skills')}
                                </React.Fragment>
                              ))}
                              </span> <span style={{ display: "none" }}>/</span></p>
                            </div>
                            <div className="icon_cont">
                              <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog2} alt="memo_icon" /></span>
                            </div></a>
                        </div>
                      )}
                      <div className="nest_child select_cont">
                        <h4>デザイン系・その他事務系</h4>
                        {selectedSkills3.length == 0 && (
                          <div className="nest_child acc_list_cont">
                            <p className="acc_ttl fixed"><a onClick={toggleDialog3}>デザイン系・その他事務系スキルを追加する</a></p>
                          </div>
                        )}
                        {/* {dialogOpen3 && (<div role="dialog" tabIndex={0} className="modal_box checkbox">
                          <div className="modal_inner">
                            <h4>アピールしたいPCスキル（デザイン系・その他事務系）</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたいデザイン系・その他事務系スキルを選択してください</h5>
                              <p className="note">※複数選択可能</p>
                              <div tabIndex={0} className="slider_item">
                                {oa_skills &&
                                  oa_skills
                                    .filter(data => data.oa_skill_category_id == 3)
                                    .map((data: any, index: any) => (
                                      <label key={index}>
                                        <input
                                          type="checkbox"
                                          name="アピールしたいPCスキル（デザイン系・その他事務系）"
                                          value={data.id}
                                          onChange={handleCheckboxChange3}
                                          checked={selectedSkills3.includes(data.id.toString())} />
                                        <span>{data.name}</span>
                                      </label>
                                    ))}
                              </div>
                            </form>
                            <p tabIndex={0} className="complete" onClick={handleComplete3}>完了</p>
                            <p tabIndex={0} className="close" onClick={toggleDialog3}><span className="material-icons">close</span></p>
                          </div>
                          <div tabIndex={0} className="overlay" onClick={toggleDialog3}></div>
                        </div>
                        )} */}
                        {selectedSkills3.length != 0 && (
                          <div className="nest_child">
                            <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                              <div className="txt_cont" onClick={toggleDialog3}>
                                <h4>デザイン系・その他事務系スキルを追加する</h4>
                                <p><span>{selectedSkills3.map((id, index) => (
                                  <React.Fragment key={id}>
                                    {index > 0 && ' / '}
                                    {findValue(id, 'oa_skills')}
                                  </React.Fragment>
                                ))}</span> <span style={{ display: "none" }}>/</span></p>
                              </div>
                              <div className="icon_cont">
                                <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog3} alt="memo_icon" /></span>
                              </div></a>
                          </div>
                        )}
                      </div>
                      <div className="nest_child select_cont">
                        <h4>プログラミング系</h4>
                        {selectedSkills4.length == 0 && (
                          <div className="nest_child acc_list_cont">
                            <p className="acc_ttl fixed"><a onClick={toggleDialog4}>プログラミング系スキルを追加する</a></p>
                          </div>
                        )}
                        {/* {dialogOpen4 && (
                          <div role="dialog" tabIndex={0} className="modal_box checkbox">
                            <div className="modal_inner">
                              <h4>アピールしたいPCスキル（プログラミング系）</h4>
                              <form>
                                <h5 className="modal_ttl">アピールしたいプログラミング系スキルを選択してください</h5>
                                <p className="note">※複数選択可能</p>
                                <div tabIndex={0} className="slider_item">
                                  {oa_skills &&
                                    oa_skills
                                      .filter(data => data.oa_skill_category_id == 4)
                                      .map((data: any, index: any) => (
                                        <label key={index}>
                                          <input
                                            type="checkbox"
                                            name="アピールしたいPCスキル（プログラミング系）"
                                            value={data.id}
                                            onChange={handleCheckboxChange4}
                                            checked={selectedSkills4.includes(data.id.toString())} />
                                          <span>{data.name}</span>
                                        </label>
                                      ))}
                                </div>
                              </form>
                              <p tabIndex={0} className="complete" onClick={handleComplete4}>完了</p>
                              <p tabIndex={0} className="close" onClick={toggleDialog4}><span className="material-icons">close</span></p>
                            </div>
                            <div tabIndex={0} className="overlay" onClick={toggleDialog4}></div>
                          </div>
                        )} */}
                        {selectedSkills4.length != 0 && (
                          <div className="nest_child">
                            <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                              <div className="txt_cont" onClick={toggleDialog4}>
                                <h4>プログラミング系スキルを追加する</h4>
                                <p><span>{selectedSkills4.map((id, index) => (
                                  <React.Fragment key={id}>
                                    {index > 0 && ' / '}
                                    {findValue(id, 'oa_skills')}
                                  </React.Fragment>
                                ))}</span> <span style={{ display: "none" }}>/</span></p>
                              </div>
                              <div className="icon_cont">
                                <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog4} alt="memo_icon" /></span>
                              </div></a>
                          </div>
                        )}
                      </div>
                    </div>
                  </dd></>
                
                )}
                 {appealsId[1] =="4" &&(
                
                  <><dt>アピールしたい資格 <a href="javascript:void(0)" onClick={(e) => handleLinkClick(4, 2)}><span>項目を変更する</span></a></dt><dd>
                    <div>
                      <div className="nest_child select_cont">
                        <h4>アピールしたい資格1</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills6.length != 0 ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog5}>
                            {selectedSkills6.length != 0 ? (
                              <span>{findValue(selectedSkills6, 'qualifications')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen5 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>アピールしたい資格1</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい資格を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills5.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onChange={qualification1Toggle} /> <span>なし</span></label>
                                          }
                                          {selectedSkills5.length == 0 &&
                                            qualification_categories
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange5}
                                                    checked={selectedSkills5.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills6.length == 0 &&
                                            qualifications
                                              .filter(data => data.qualification_category_id == kills5)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange6}
                                                    checked={selectedSkills6.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog5}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog5}></div>
                        </div>
                      )} */}
                      <div className="nest_child select_cont">
                        <h4>アピールしたい資格2</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills8.length != 0 ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog6}>
                            {selectedSkills8.length != 0 ? (
                              <span>{findValue(selectedSkills8, 'qualifications')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen6 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>アピールしたい資格2</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい資格を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills17.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={qualification2Toggle}/> <span>なし</span></label>
                                          }
                                          {selectedSkills7.length == 0 &&
                                            qualification_categories
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange7}
                                                    checked={selectedSkills7.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills8.length == 0 &&
                                            qualifications
                                              .filter(data => data.qualification_category_id == kills6)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange8}
                                                    checked={selectedSkills8.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog6}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog6}></div>
                        </div>
                      )} */}
                      <div className="textarea_cont nest_child">
                        <h4>アピールしたい資格3</h4>
                        <label><input
                          type="text"
                          placeholder="資格名"
                          value={qualification_3}
                          onChange={handleQualificationInputChange} />
                          <p className="note">※一覧にない資格などがあればご入力ください</p>
                        </label>
                      </div>
                    </div>
                  </dd></>
                
                )}
                 {appealsId[1] =="2" &&(
                
                  <><dt>アピールしたい経験職種<a href="javascript:void(0)" onClick={(e) => handleLinkClick(2, 2)}><span>項目を変更する</span></a></dt><dd>
                    <div className="experience_point">
                      <div className="nest_child select_cont">
                        <h4>経験職種1</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills10 != null ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog7}>
                            {job_category_1 != null ? (
                              <span>{findValue(job_category_1, 'job_categories')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen7 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>経験職種1</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい経験職種を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills9.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={jobCategory1Toggle} /> <span>なし</span></label>
                                          }
                                          {selectedSkills9.length == 0 &&
                                            job_category_types
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange9}
                                                    checked={selectedSkills9.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills10.length == 0 &&
                                            job_categories
                                              .filter(data => data.job_category_type_id == kills7)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange10}
                                                    checked={selectedSkills10.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog7}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog7}></div>
                        </div>
                      )} */}
                      <div className="nest_child select_cont">
                        <h4>経験職種2</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills12 != null ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog8}>
                            {job_category_2 != null ? (
                              <span>{findValue(job_category_2, 'job_categories')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen8 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>経験職種2</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい経験職種を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills11.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={jobCategory2Toggle}/> <span>なし</span></label>
                                          } 
                                          {selectedSkills11.length == 0 &&
                                            job_category_types
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange11}
                                                    checked={selectedSkills11.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills12.length == 0 &&
                                            job_categories
                                              .filter(data => data.job_category_type_id == kills8)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange12}
                                                    checked={selectedSkills12.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog8}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog8}></div>
                        </div>
                      )} */}
                      <div className="nest_child select_cont">
                        <h4>経験職種3</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills14 != null ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog9}>
                            {job_category_3 != undefined && job_category_3 != null ? (
                              <span>{findValue(job_category_3, 'job_categories')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen9 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>経験職種3</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい経験職種を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills13.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={jobCategory3Toggle}/> <span>なし</span></label>
                                          }
                                          {selectedSkills13.length == 0 &&
                                            job_category_types
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange13}
                                                    checked={selectedSkills13.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills14.length == 0 &&
                                            job_categories
                                              .filter(data => data.job_category_type_id == kills9)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange14}
                                                    checked={selectedSkills14.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog9}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog9}></div>
                        </div>
                      )} */}
                    </div>
                  </dd></>
                
                )}  
                  </> 
                  }
                  {!selectedAppealItemId3 ?
                    
                      <><dt className="style3">
                      未登録
                    </dt><dd className="not_set">
                        <div>
                          未入力
                        </div> <a onClick={(e) => handleLinkClick(0, 3)} className="btn type3 yellow">設定する</a>
                      </dd></>
                  :
                  <>
                  {appealsId[2] == "1" &&(
                  
                  <><dt className="">
                  アピールしたい職歴
                  <a href="javascript:void(0)" onClick={(e) => handleLinkClick(1, 3)}><span>項目を変更する</span></a>
                </dt><dd className="">
                    <div className="box">
                      {job_career_appeals.length == 0 &&
                        <div className="nest_child acc_list_cont"><p className="acc_ttl fixed">
                          <a onClick={handleLinkClick3} title="ポップアップが立ち上がります。TABキーを押して選択してください。">アピールしたい職歴を追加する</a></p>
                        </div>}
                      {job_career_appeals.length != 0 &&
                        <div className="nest_child">
                          {job_career_appeals.map((item, index) => (
                            <><a
                              key={index}
                              tabIndex={0}
                              title="ポップアップが立ち上がります。TABキーを押して選択してください。"
                              className="edit_box fixed"
                              onClick={() => handleLinkClick3()}
                            >
                              <div className="txt_cont">
                                <h4>アピールしたい職歴</h4>
                                <p>
                                  {item.company || '会社名未入力' } / {findValue(item.industry_id, 'industries')} <br />
                                  {findValue(item.job_category_id, 'job_categories')} <br />
                                  {formatYM(item.arrival_date)} 〜 {formatYM(item.departure_date)}
                                </p>
                              </div>
                              <div className="icon_cont">
                                <span className="edit">
                                  <img
                                    src="/assets/img/mypage/memo_icon.svg"
                                    onClick={() => handleLinkClick33(index)}
                                    alt="memo_icon" />
                                </span>
                              </div>
                            </a><div className="textarea_cont">
                                <h4>業務内容（２００文字以内）</h4>
                                <textarea cols={30} rows={10} value={item.business_content} onChange={(event) => {handleContentChange(event, index)}}></textarea>
                                {/* <p className="text_length_alert"></p> */}
                                {job_career_appeals[index].business_content_maxLength && job_career_appeals[index].business_content_maxLength == true ?
                                  <p className="text_length_alert">{job_career_appeals[index].business_content_lengthCount}文字オーバー</p>
                                : <></>}
                              </div>
                              <p className="acc_ttl del">
                                <a onClick={() => handleDeleteCareerAppeal(index)}>アピールしたい職歴を削除する</a>
                              </p></>

                          ))}
                          <div className="nest_child acc_list_cont">
                            <p className="acc_ttl">
                              <a onClick={() => handleLinkClick3()} title="ポップアップが立ち上がります。TABキーを押して選択してください。">
                                アピールしたい職歴を追加する
                              </a>
                            </p>

                          </div>
                        </div>}
                    </div> <a href="" className="btn type3 yellow" style={{ display: "none" }}>設定する</a>
                  </dd></> 
                
                )}
                {/* {isModalOpen3 &&  (
                <div role="dialog" tabIndex={0} className="modal_box">
                <div className="modal_inner">
                  <h4>アピールしたい職歴</h4>
                  <form>
                    <h5 className="modal_ttl">会社名を入力してください</h5>
                    <div dir="ltr" className="slider slick-slider slick-initialized">
                      <div className="slick-list">
                        <div className="slick-track" style={{opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%"}}>
                          <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                            <div>
                              <div tabIndex={-1} className="slider_item" style={{width: "100%", display: "inline-block"}}>
                                {compname.length == 0 && (
                                <><label className="input_text">
                                      <input type="text" tabIndex={0} value={cname} onChange={handleNameChange} />
                                    </label><a id="btn_next" tabIndex={0} onClick={handleNextClick}> 次へ</a></>                           
                                )}   
                                {compname.length > 0 && selectedSkills19.length == 0 &&
                                    yearArray
                                      .map((year) => (
                                        <label className="column3" key={year}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={year}
                                            onChange={handleCheckboxChange19}
                                            checked={selectedSkills19.includes(year)}
                                          />
                                          <span>{year} 年</span>
                                        </label>
                                      ))
                                }
                                {selectedSkills19.length > 0 && selectedSkills20.length == 0 &&
                                    months
                                      .map((month) => (
                                        <label className="column3" key={month}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={month}
                                            onChange={handleCheckboxChange20}
                                            checked={selectedSkills20.includes(month)}
                                          />
                                          <span>{month} 月</span>
                                        </label>
                                      ))
                                }
                                {selectedSkills20.length > 0 && selectedSkills21.length == 0 && (
                                <label className="column3" >
                                          <input
                                            type="radio"
                                            name=""
                                            value="在職中"
                                            onChange={handleCheckboxChange20}
                                            checked={selectedSkills21.includes('在職中')}
                                          />
                                          <span>在職中</span>
                                        </label>
                                )}
                                {selectedSkills20.length > 0 && selectedSkills21.length == 0 &&
                                    years
                                      .map((year) => (
                                        <label className="column3" key={year}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={year}
                                            onChange={handleCheckboxChange21}
                                            checked={selectedSkills21.includes(year)}
                                          />
                                          <span>{year} 年</span>
                                        </label>
                                      ))
                                }
                                {selectedSkills21.length > 0 && selectedSkills25.length == 0 &&
                                    months
                                      .map((month) => (
                                        <label className="column3" key={month}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={month}
                                            onChange={handleCheckboxChange25}
                                            checked={selectedSkills25.includes(month)}
                                          />
                                          <span>{month} 月</span>
                                        </label>
                                      ))
                                }
                                {selectedSkills25.length > 0 && selectedSkills22.length == 0 &&
                                    industries
                                      .map((data: any, index: any) => (
                                        <label className="column3" key={index}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={data.id}
                                            onChange={handleCheckboxChange22}
                                            checked={selectedSkills22.includes(data.id.toString())}
                                          />
                                          <span>{data.name}</span>
                                        </label>
                                      ))
                                }
                                {selectedSkills22.length > 0 && selectedSkills23.length == 0 &&
                                    job_category_types
                                      .map((data: any, index: any) => (
                                        <label className="column3" key={index}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={data.id}
                                            onChange={handleCheckboxChange23}
                                            checked={selectedSkills23.includes(data.id.toString())}
                                          />
                                          <span>{data.name}</span>
                                        </label>
                                      ))
                                }
                                {selectedSkills23.length > 0 && selectedSkills24.length == 0 &&
                                    job_categories
                                      .filter(data => data.job_category_type_id == kills10) 
                                      .map((data: any, index: any) => (
                                        <label className="column3" key={index}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={data.id}
                                            onChange={handleCheckboxChange24}
                                            checked={selectedSkills24.includes(data.id.toString())}
                                          />
                                          <span>{data.name}</span>
                                        </label>
                                      ))
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <p tabIndex={0} className="close" onClick={handleCloseModal3}><span className="material-icons">close</span></p>
                </div>
                <div className="overlay" onClick={handleCloseModal3}></div>
                </div>
                )} */}
                {appealsId[2] == "5" &&(
                
                  <><dt className="">
                    アピールしたい語学 <a href="javascript:void(0)" onClick={(e) => handleLinkClick(5, 3)}><span>項目を変更する</span></a>
                  </dt><dd className="">
                      <div>
                        <div className="nest_child select_cont">
                          <h4>アピールしたい語学1</h4>
                          <p className="select" style={{ backgroundColor: selectedSkills16.length != 0 ? "white" : "#dbffff" }}>
                            <a onClick={toggleDialog10}>
                              {selectedSkills16.length != 0 ? (
                                <span>{findValue(selectedSkills15, 'languages')}, {findValue(selectedSkills16, 'levels')}レベル</span>
                              ) : (
                                <span>未登録</span>
                              )}
                            </a>
                          </p>
                        </div>
                        {/* {dialogOpen10 && (
                          <div role="dialog" tabIndex={0} className="modal_box">
                            <div className="modal_inner">
                              <h4>アピールしたい語学1</h4>
                              <form>
                                <h5 className="modal_ttl">アピールしたい語学を選択してください</h5>
                                <div dir="ltr" className="slider slick-slider slick-initialized">
                                  <div className="slick-list">
                                    <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                      <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                        <div>
                                          <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                            {selectedSkills15.length == 0 &&
                                              <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={language1Toggle} /> <span>なし</span></label>}
                                            {selectedSkills15.length == 0 &&
                                              languages
                                                .map((data: any, index: any) => (
                                                  <label className="column3" key={index}>
                                                    <input
                                                      type="radio"
                                                      name=""
                                                      value={data.id}
                                                      onChange={handleCheckboxChange15}
                                                      checked={selectedSkills15.includes(data.id.toString())} />
                                                    <span>{data.name}</span>
                                                  </label>
                                                ))}
                                            {selectedSkills16.length == 0 &&
                                              levels
                                                .map((data: any, index: any) => (
                                                  <label className="column3" key={index}>
                                                    <input
                                                      type="radio"
                                                      name=""
                                                      value={data.id}
                                                      onChange={handleCheckboxChange16}
                                                      checked={selectedSkills16.includes(data.id.toString())} />
                                                    <span>{data.name}</span>
                                                  </label>
                                                ))}
                                          </div>
                                        </div>
                                      </div>
                                      <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                              <p className="close"><span className="material-icons" onClick={toggleDialog10}>close</span></p>
                            </div>
                            <div className="overlay" onClick={toggleDialog10}></div>
                          </div>
                        )} */}
                        <div className="nest_child select_cont">
                          <h4>アピールしたい語学2</h4>
                          <p className="select" style={{ backgroundColor: selectedSkills18.length != 0 ? "white" : "#dbffff" }}>
                            <a onClick={toggleDialog11}>
                              {selectedSkills18.length != 0 ? (
                                <span>{findValue(formattedName2, 'languages')}, {findValue(selectedSkills18, 'levels')}レベル</span>
                              ) : (
                                <span>未登録</span>
                              )}
                            </a>
                          </p>
                        </div>
                        {/* {dialogOpen11 && (
                          <div role="dialog" tabIndex={0} className="modal_box">
                            <div className="modal_inner">
                              <h4>アピールしたい語学2</h4>
                              <form>
                                <h5 className="modal_ttl">アピールしたい語学を選択してください</h5>
                                <div dir="ltr" className="slider slick-slider slick-initialized">
                                  <div className="slick-list">
                                    <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                      <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                        <div>
                                          <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                            {selectedSkills17.length == 0 &&
                                              <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={language2Toggle}/> <span>なし</span></label>}
                                            {selectedSkills17.length == 0 &&
                                              languages
                                                .map((data: any, index: any) => (
                                                  <label className="column3" key={index}>
                                                    <input
                                                      type="radio"
                                                      name=""
                                                      value={data.id}
                                                      onChange={handleCheckboxChange17}
                                                      checked={selectedSkills17.includes(data.id.toString())} />
                                                    <span>{data.name}</span>
                                                  </label>
                                                ))}
                                            {selectedSkills18.length == 0 &&
                                              levels
                                                .map((data: any, index: any) => (
                                                  <label className="column3" key={index}>
                                                    <input
                                                      type="radio"
                                                      name=""
                                                      value={data.id}
                                                      onChange={handleCheckboxChange18}
                                                      checked={selectedSkills18.includes(data.id.toString())} />
                                                    <span>{data.name}</span>
                                                  </label>
                                                ))}
                                          </div>
                                        </div>
                                      </div>
                                      <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                              <p className="close"><span className="material-icons" onClick={toggleDialog11}>close</span></p>
                            </div>
                            <div className="overlay" onClick={toggleDialog11}></div>
                          </div>
                        )} */}
                        <div className="textarea_cont nest_child">
                          <h4>アピールしたい語学3</h4>
                          <label>
                            <input
                              type="text"
                              placeholder="言語名、習得レベル"
                              value={languageAppeal}
                              onChange={handleInputChange} />
                          </label>
                          <p className="note">※一覧にない言語などがあればご入力ください</p>
                        </div>
                      </div>
                    </dd></>
                
                )}
                {appealsId[2] == "6" &&(
                
                  <><dt className="">
                    就労支援機関 <a href="javascript:void(0)" onClick={(e) => handleLinkClick(6, 3)}><span>項目を変更する</span></a>
                  </dt><dd className="">
                      <div className="nest_child select_cont">
                        <h4>就労支援機関の利用有無</h4>
                        <div className="radio">
                          <label><input type="radio" name="support_agency_exists" checked={support_agency_exists} onChange={handleRadioChange2} />有</label> <label><input type="radio" name="support_agency_exists" checked={!support_agency_exists} onChange={handleRadioChange2} />無</label>
                        </div>
                        {support_agency_exists && (
                          <div className="">
                            <div>
                              <div className="textarea_cont nest_child">
                                <h4>機関・企業名</h4>
                                <label>
                                  <input
                                    type="text"
                                    placeholder="例）○○職業センター、○○福祉医療機構"
                                    value={organization}
                                    onChange={(e) => setOrganization(e.target.value)} />
                                </label>
                              </div>
                              <div className="textarea_cont nest_child">
                                <h4>連絡先</h4>
                                <label>
                                  <input
                                    type="text"
                                    placeholder="0300000000"
                                    value={contact_number}
                                    onChange={(e) => setContactNumber(e.target.value)} />
                                </label>
                              </div>
                              <div className="textarea_cont nest_child">
                                <h4>URL</h4>
                                <label>
                                  <input
                                    type="text"
                                    placeholder="https://www.example.com"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)} />
                                </label>
                              </div>
                              <div className="nest_child radio_cont">
                                <h4>エントリー企業との連携可否</h4>
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      value="true"
                                      checked={can_contact == true}
                                      onChange={() => setCanContact(true)} />
                                    可
                                  </label>
                                  <label>
                                    <input
                                      type="radio"
                                      value="false"
                                      checked={can_contact == false}
                                      onChange={() => setCanContact(false)} />
                                    否
                                  </label>
                                </div>
                              </div>
                              <div className="nest_child select_cont">
                                <h4>利用期間 開始月</h4>
                                {/* {dialogOpen14 && (
                                  <div role="dialog" tabIndex={0} className="modal_box">
                                    <div className="modal_inner">
                                      <h4>利用期間の開始月</h4>
                                      <form>
                                        <h5 className="modal_ttl">年を選択してください</h5>
                                        <div dir="ltr" className="slider slick-slider slick-initialized">
                                          <div className="slick-list">
                                            <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                              <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                                <div>
                                                  <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                                    {trigger == 0 &&
                                                      years2
                                                        .map((year) => (
                                                          <label className="column3" key={year}>
                                                            <input
                                                              type="radio"
                                                              name=""
                                                              value={year}
                                                              onChange={handleCheckboxChange28}
                                                              checked={selectedSkills28.includes(year)} />
                                                            <span>{year} 年</span>
                                                          </label>
                                                        ))}
                                                    {trigger == 1 &&
                                                      months
                                                        .map((month) => (
                                                          <label className="column3" key={month}>
                                                            <input
                                                              type="radio"
                                                              name=""
                                                              value={month}
                                                              onChange={handleCheckboxChange29}
                                                              checked={selectedSkills29.includes(month)} />
                                                            <span>{month} 月</span>
                                                          </label>
                                                        ))}
                                                  </div>
                                                </div>
                                              </div>
                                              <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                      <p className="close"><span className="material-icons" onClick={toggleDialog14}>close</span></p>
                                    </div>
                                    <div className="overlay" onClick={toggleDialog14}></div>
                                  </div>
                                )} */}

                                <p className="select">
                                  <a onClick={toggleDialog14}>
                                    {start_date != null ? 
                                      <>
                                        {formatYM(start_date)}
                                      </>
                                      : 
                                        '選択してください'
                                    }
                                    {/* {start_date != null ? (
                                      <a>{formatYM(start_date)}</a>
                                    ) : (
                                      selectedSkills29.length != 0 ? (
                                        <a>{selectedSkills28}年{selectedSkills29}月</a>
                                      ) : (
                                        <span>選択してください</span>
                                      )
                                    )} */}
                                  </a></p>
                              </div>
                              <div className="nest_child select_cont">
                                <h4>利用期間 終了月</h4>
                                {/* {dialogOpen15 && (
                                  <div role="dialog" tabIndex={0} className="modal_box">
                                    <div className="modal_inner">
                                      <h4>利用期間の終了月</h4>
                                      <form>
                                        <h5 className="modal_ttl">年を選択してください</h5>
                                        <div dir="ltr" className="slider slick-slider slick-initialized">
                                          <div className="slick-list">
                                            <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                              <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                                <div>
                                                  <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                                    {trigger2 == 0 &&
                                                      <label className="column3">
                                                        <input
                                                          type="radio"
                                                          name=""
                                                          value="9999-12"
                                                          onChange={handleCheckboxChange30}
                                                          checked={selectedSkills30.includes('終了月なし（継続中）')} />
                                                        <span>終了月なし（継続中）</span>
                                                      </label>}
                                                    {trigger2 == 0 &&
                                                      years2
                                                        .map((year) => (
                                                          <label className="column3" key={year}>
                                                            <input
                                                              type="radio"
                                                              name=""
                                                              value={year}
                                                              onChange={handleCheckboxChange30}
                                                              checked={selectedSkills30.includes(year)} />
                                                            <span>{year} 年</span>
                                                          </label>
                                                        ))}
                                                    {trigger2 == 1 && !selectedSkills30.includes('終了月なし') && (
                                                      <div>
                                                        {months.map((month) => (
                                                          <label className="column3" key={month}>
                                                            <input
                                                              type="radio"
                                                              name=""
                                                              value={month}
                                                              onChange={handleCheckboxChange31}
                                                              checked={selectedSkills31.includes(month)} />
                                                            <span>{month} 月</span>
                                                          </label>
                                                        ))}
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                      <p className="close"><span className="material-icons" onClick={toggleDialog15}>close</span></p>
                                    </div>
                                    <div className="overlay" onClick={toggleDialog15}></div>
                                  </div>
                                )} */}

                                <p className="select">
                                  <a onClick={toggleDialog15}>
                                    {end_date != null ? 
                                      <>
                                        {formatEmploymentYM(end_date)}
                                      </>
                                      : 
                                        '選択してください'
                                    }
                                    {/* {end_date?.includes('9999-12') ? (
                                      '終了月なし（継続中）'
                                    ) : (
                                      end_date != null ? (
                                        <a>{formatYM(end_date)}</a>
                                      ) : (
                                        selectedSkills31.length != 0 ? (
                                          selectedSkills31.includes('9999-12') ? (
                                            <a>終了月なし（継続中）</a>
                                          ) : (
                                            <span>{selectedSkills30}年{selectedSkills31}月</span>
                                          )
                                        ) : (
                                          <span>選択してください</span>
                                        )
                                      )
                                    )} */}
                                  </a></p>
                              </div>
                              <div className="textarea_cont nest_child">
                                <h4>訓練内容（２００文字以内）</h4>
                                <textarea
                                  cols={30}
                                  rows={10}
                                  placeholder="例）研修で学んだこと、グループワークなどで取り組んだ課題"
                                  value={training_content}
                                  onChange={handleTextareaChange}
                                ></textarea>
                                {trainingContentError == true ?
                                  <p className="text_length_alert">{trainingContentErrorCount}文字オーバー</p>
                                : <></>}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </dd></>

                )}
                {appealsId[2] == "7" &&(
                
                  <><dt className="">
                    自己PR <a href="javascript:void(0)" onClick={(e) => handleLinkClick(7, 3)}><span>項目を変更する</span></a>
                  </dt><dd className="">
                      <div className="textarea_cont">
                        <h4>自己PR（４００文字以内）</h4>
                        <textarea
                          cols={30}
                          rows={10}
                          placeholder="活かせるスキルや意気込み、または「こういう合理的配慮・環境があれば○○ができる」などのPRを書いてください。"
                          value={selfPR}
                          onChange={handlePrTextareaChange}
                        ></textarea>
                        <p className="text_length_alert">{over}</p>
                      </div>
                    </dd></>
                
                )}
                 {appealsId[2] == "3" &&(
                
                  <><dt>アピールしたいPCスキル<a href="javascript:void(0)" onClick={(e) => handleLinkClick(3, 3)}><span>項目を変更する</span></a></dt><dd>
                    <div>
                      <div className="nest_child select_cont">
                        <h4>Word</h4>
                        <div className="radio">
                          <label><input type="radio" name="word_experience" checked={wordExp} onChange={toggleWordExperience} />あり</label>
                          <label><input type="radio" name="word_experience" checked={!wordExp} onChange={toggleWordExperience} />なし</label>
                        </div>
                        {wordExp && selectedSkills.length == 0 && (
                          <div className="nest_child acc_list_cont">
                            <p className="acc_ttl fixed"><a onClick={toggleDialog}>Wordスキルを追加する</a></p>
                          </div>
                        )}
                        {/* {dialogOpen && (
                          <div role="dialog" tabIndex={0} className="modal_box checkbox">
                            <div className="modal_inner">
                              <h4>Word</h4>
                              <form>
                                <h5 className="modal_ttl">アピールしたいWordスキルを選択してください</h5>
                                <p className="note">※複数選択可能</p>
                                <div className="slider_item">
                                  {oa_skills &&
                                    oa_skills
                                      .filter(data => data.oa_skill_category_id == 1)
                                      .map((data: any, index: any) => (
                                        <label key={index}>
                                          <input
                                            type="checkbox"
                                            name="アピールしたいPCスキル（Word）"
                                            value={data.id}
                                            onChange={handleCheckboxChange}
                                            checked={selectedSkills.includes(data.id.toString())} />
                                          <span>{data.name}</span>
                                        </label>
                                      ))}
                                </div>
                              </form>
                              <p className="complete" onClick={handleComplete}>完了</p>
                              <p className="close" onClick={toggleDialog}><span className="material-icons">close</span></p>
                            </div>
                            <div className="overlay" onClick={toggleDialog}></div>
                          </div>
                        )} */}
                        {selectedSkills.length != 0 && (
                          <div className="nest_child">
                            <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                              <div className="txt_cont" onClick={toggleDialog}>
                                <h4>Wordスキルを追加する</h4>
                                <p><span>{selectedSkills.map((id, index) => (
                                  <React.Fragment key={id}>
                                    {index > 0 && ' / '}
                                    {findValue(id, 'oa_skills')}
                                  </React.Fragment>
                                ))}
                                </span> <span style={{ display: "none" }}>/</span></p>
                              </div>
                              <div className="icon_cont">
                                <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog} alt="memo_icon" /></span>
                              </div></a>
                          </div>
                        )}
                      </div>
                      <div className="nest_child select_cont">
                        <h4>Excel</h4>
                        <div className="radio">
                          <label><input type="radio" name="excel_experience" checked={excelExp} onChange={toggleExcelExperience} />あり</label>
                          <label><input type="radio" name="excel_experience" checked={!excelExp} onChange={toggleExcelExperience} />なし</label>
                        </div>
                      </div>
                      {excelExp && selectedSkills2.length == 0 && (
                        <div className="nest_child acc_list_cont">
                          <p className="acc_ttl fixed"><a onClick={toggleDialog2}>Excelスキルを追加する</a></p>
                        </div>
                      )}
                      {/* {dialogOpen2 && (
                        <div role="dialog" tabIndex={0} className="modal_box checkbox">
                          <div className="modal_inner">
                            <h4>アピールしたいPCスキル（Excel）</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたいExcelスキルを選択してください</h5>
                              <p className="note">※複数選択可能</p>
                              <div className="slider_item">
                                {oa_skills &&
                                  oa_skills
                                    .filter(data => data.oa_skill_category_id == 2)
                                    .map((data: any, index: any) => (
                                      <label key={index}>
                                        <input
                                          type="checkbox"
                                          name="アピールしたいPCスキル（Excel）"
                                          value={data.id}
                                          onChange={handleCheckboxChange2}
                                          checked={selectedSkills2.includes(data.id.toString())} />
                                        <span>{data.name}</span>
                                      </label>
                                    ))}
                              </div>
                            </form>
                            <p className="complete" onClick={handleComplete2}>完了</p>
                            <p className="close" onClick={toggleDialog2}><span className="material-icons">close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog2}></div>
                        </div>
                      )} */}
                      {selectedSkills2.length != 0 && (
                        <div className="nest_child">
                          <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                            <div className="txt_cont" onClick={toggleDialog2}>
                              <h4>Excelスキルを追加する</h4>
                              <p><span>{selectedSkills2.map((id, index) => (
                                <React.Fragment key={id}>
                                  {index > 0 && ' / '}
                                  {findValue(id, 'oa_skills')}
                                </React.Fragment>
                              ))}
                              </span> <span style={{ display: "none" }}>/</span></p>
                            </div>
                            <div className="icon_cont">
                              <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog2} alt="memo_icon" /></span>
                            </div></a>
                        </div>
                      )}
                      <div className="nest_child select_cont">
                        <h4>デザイン系・その他事務系</h4>
                        {selectedSkills3.length == 0 && (
                          <div className="nest_child acc_list_cont">
                            <p className="acc_ttl fixed"><a onClick={toggleDialog3}>デザイン系・その他事務系スキルを追加する</a></p>
                          </div>
                        )}
                        {/* {dialogOpen3 && (<div role="dialog" tabIndex={0} className="modal_box checkbox">
                          <div className="modal_inner">
                            <h4>アピールしたいPCスキル（デザイン系・その他事務系）</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたいデザイン系・その他事務系スキルを選択してください</h5>
                              <p className="note">※複数選択可能</p>
                              <div tabIndex={0} className="slider_item">
                                {oa_skills &&
                                  oa_skills
                                    .filter(data => data.oa_skill_category_id == 3)
                                    .map((data: any, index: any) => (
                                      <label key={index}>
                                        <input
                                          type="checkbox"
                                          name="アピールしたいPCスキル（デザイン系・その他事務系）"
                                          value={data.id}
                                          onChange={handleCheckboxChange3}
                                          checked={selectedSkills3.includes(data.id)} />
                                        <span>{data.name}</span>
                                      </label>
                                    ))}
                              </div>
                            </form>
                            <p tabIndex={0} className="complete" onClick={handleComplete3}>完了</p>
                            <p tabIndex={0} className="close" onClick={toggleDialog3}><span className="material-icons">close</span></p>
                          </div>
                          <div tabIndex={0} className="overlay" onClick={toggleDialog3}></div>
                        </div>
                        )} */}
                        {selectedSkills3.length != 0 && (
                          <div className="nest_child">
                            <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                              <div className="txt_cont" onClick={toggleDialog3}>
                                <h4>デザイン系・その他事務系スキルを追加する</h4>
                                <p><span>{selectedSkills3.map((id, index) => (
                                  <React.Fragment key={id}>
                                    {index > 0 && ' / '}
                                    {findValue(id, 'oa_skills')}
                                  </React.Fragment>
                                ))}</span> <span style={{ display: "none" }}>/</span></p>
                              </div>
                              <div className="icon_cont">
                                <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog3} alt="memo_icon" /></span>
                              </div></a>
                          </div>
                        )}
                      </div>
                      <div className="nest_child select_cont">
                        <h4>プログラミング系</h4>
                        {selectedSkills4.length == 0 && (
                          <div className="nest_child acc_list_cont">
                            <p className="acc_ttl fixed"><a onClick={toggleDialog4}>プログラミング系スキルを追加する</a></p>
                          </div>
                        )}
                        {/* {dialogOpen4 && (
                          <div role="dialog" tabIndex={0} className="modal_box checkbox">
                            <div className="modal_inner">
                              <h4>アピールしたいPCスキル（プログラミング系）</h4>
                              <form>
                                <h5 className="modal_ttl">アピールしたいプログラミング系スキルを選択してください</h5>
                                <p className="note">※複数選択可能</p>
                                <div tabIndex={0} className="slider_item">
                                  {oa_skills &&
                                    oa_skills
                                      .filter(data => data.oa_skill_category_id == 4)
                                      .map((data: any, index: any) => (
                                        <label key={index}>
                                          <input
                                            type="checkbox"
                                            name="アピールしたいPCスキル（プログラミング系）"
                                            value={data.id}
                                            onChange={handleCheckboxChange4}
                                            checked={selectedSkills4.includes(data.id.toString())} />
                                          <span>{data.name}</span>
                                        </label>
                                      ))}
                                </div>
                              </form>
                              <p tabIndex={0} className="complete" onClick={handleComplete4}>完了</p>
                              <p tabIndex={0} className="close" onClick={toggleDialog4}><span className="material-icons">close</span></p>
                            </div>
                            <div tabIndex={0} className="overlay" onClick={toggleDialog4}></div>
                          </div>
                        )} */}
                        {selectedSkills4.length != 0 && (
                          <div className="nest_child">
                            <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                              <div className="txt_cont" onClick={toggleDialog4}>
                                <h4>プログラミング系スキルを追加する</h4>
                                <p><span>{selectedSkills4.map((id, index) => (
                                  <React.Fragment key={id}>
                                    {index > 0 && ' / '}
                                    {findValue(id, 'oa_skills')}
                                  </React.Fragment>
                                ))}</span> <span style={{ display: "none" }}>/</span></p>
                              </div>
                              <div className="icon_cont">
                                <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog4} alt="memo_icon" /></span>
                              </div></a>
                          </div>
                        )}
                      </div>
                    </div>
                  </dd></>
                
                )}
                 {appealsId[2] == "4" &&(
                
                  <><dt>アピールしたい資格 <a href="javascript:void(0)" onClick={(e) => handleLinkClick(4, 3)}><span>項目を変更する</span></a></dt><dd>
                    <div>
                      <div className="nest_child select_cont">
                        <h4>アピールしたい資格1</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills6.length != 0 ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog5}>
                            {selectedSkills6.length != 0 ? (
                              <span>{findValue(selectedSkills6, 'qualifications')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen5 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>アピールしたい資格1</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい資格を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills5.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onChange={qualification1Toggle} /> <span>なし</span></label>
                                          }
                                          {selectedSkills5.length == 0 &&
                                            qualification_categories
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange5}
                                                    checked={selectedSkills5.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills6.length == 0 &&
                                            qualifications
                                              .filter(data => data.qualification_category_id == kills5)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange6}
                                                    checked={selectedSkills6.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog5}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog5}></div>
                        </div>
                      )} */}
                      <div className="nest_child select_cont">
                        <h4>アピールしたい資格2</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills8.length != 0 ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog6}>
                            {selectedSkills8.length != 0 ? (
                              <span>{findValue(selectedSkills8, 'qualifications')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen6 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>アピールしたい資格2</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい資格を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills17.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={qualification2Toggle}/> <span>なし</span></label>
                                          }
                                          {selectedSkills7.length == 0 &&
                                            qualification_categories
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange7}
                                                    checked={selectedSkills7.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills8.length == 0 &&
                                            qualifications
                                              .filter(data => data.qualification_category_id == kills6)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange8}
                                                    checked={selectedSkills8.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog6}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog6}></div>
                        </div>
                      )} */}
                      <div className="textarea_cont nest_child">
                        <h4>アピールしたい資格3</h4>
                        <label><input
                          type="text"
                          placeholder="資格名"
                          value={qualification_3}
                          onChange={handleQualificationInputChange} />
                          <p className="note">※一覧にない資格などがあればご入力ください</p>
                        </label>
                      </div>
                    </div>
                  </dd></>
                
                )}
                 {appealsId[2] == "2" &&(
                
                  <><dt>アピールしたい経験職種<a href="javascript:void(0)" onClick={(e) => handleLinkClick(2, 3)}><span>項目を変更する</span></a></dt><dd>
                    <div className="experience_point">
                      <div className="nest_child select_cont">
                        <h4>経験職種1</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills10 != null ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog7}>
                            {job_category_1 != null ? (
                              <span>{findValue(job_category_1, 'job_categories')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen7 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>経験職種1</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい経験職種を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills9.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={jobCategory1Toggle} /> <span>なし</span></label>
                                          }
                                          {selectedSkills9.length == 0 &&
                                            job_category_types
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange9}
                                                    checked={selectedSkills9.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills10.length == 0 &&
                                            job_categories
                                              .filter(data => data.job_category_type_id == kills7)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange10}
                                                    checked={selectedSkills10.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog7}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog7}></div>
                        </div>
                      )} */}
                      <div className="nest_child select_cont">
                        <h4>経験職種2</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills12 != null ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog8}>
                            {job_category_2 != null ? (
                              <span>{findValue(job_category_2, 'job_categories')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      {/* {dialogOpen8 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>経験職種2</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたい経験職種を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {selectedSkills11.length == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={jobCategory2Toggle}/> <span>なし</span></label>
                                          } 
                                          {selectedSkills11.length == 0 &&
                                            job_category_types
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange11}
                                                    checked={selectedSkills11.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {selectedSkills12.length == 0 &&
                                            job_categories
                                              .filter(data => data.job_category_type_id == kills8)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange12}
                                                    checked={selectedSkills12.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog8}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog8}></div>
                        </div>
                      )} */}
                      <div className="nest_child select_cont">
                        <h4>経験職種3</h4>
                        <p className="select" style={{ backgroundColor: selectedSkills14 != null ? "white" : "#dbffff" }}>
                          <a onClick={toggleDialog9}>
                            {job_category_3 != undefined && job_category_3 != null ? (
                              <span>{findValue(job_category_3, 'job_categories')}</span>
                            ) : (
                              <span>未登録</span>
                            )}
                          </a>
                        </p>
                      </div>
                      
                    </div>
                  </dd></>
                
                )}  
                  </>  
                  }
                                  
                  </dl>
                  }
                  </div>
                  <div>
                    <div className="ttl_box">
                      <h3 className="ttl">採用担当者が知りたい項目</h3><br className="sp_only"/>
                      <small className="style1">※記入すると選考通過率UP</small>
                    </div>
                    <dl>
                    {(appeal_item_1 == 1 || appeal_item_2 == 1 || appeal_item_3 == 1) && 
                      <><dt>アピールしたい職歴</dt><dd className="">
                          <div className="box">
                            {job_career_appeals.length == 0 &&
                              <div className="nest_child acc_list_cont"><p className="acc_ttl fixed">
                                <a onClick={handleLinkClick3} title="ポップアップが立ち上がります。TABキーを押して選択してください。">アピールしたい職歴を追加する</a></p>
                              </div>}                           
                              <div className="nest_child">   
                                {job_career_appeals.length != 0 && 
                                  <>
                                  {job_career_appeals.map((item, index) => (
                                    <>                       
                                      <a
                                        key={'job_career_appeals'+index}
                                        tabIndex={0}
                                        title="ポップアップが立ち上がります。TABキーを押して選択してください。"
                                        className="edit_box fixed"
                                        onClick={() => handleLinkClick3()}
                                      >
                                        <div className="txt_cont">
                                          <h4>アピールしたい職歴</h4>
                                          <p>
                                            {item.company || '会社名未入力'} / {findValue(item.industry_id, 'industries')} <br />
                                            {findValue(item.job_category_id, 'job_categories')} <br />
                                            {formatYM(item.arrival_date)} 〜 {formatYM(item.departure_date)}
                                          </p>
                                        </div>
                                        <div className="icon_cont">
                                          <span className="edit">
                                            <img
                                              src="/assets/img/mypage/memo_icon.svg"
                                              onClick={() => handleLinkClick33(index)}
                                              alt="memo_icon" />
                                          </span>
                                        </div>
                                      </a>
                                      <div className="textarea_cont">
                                        <h4>業務内容（２００文字以内）</h4>
                                        <textarea cols={30} rows={10} value={item.business_content} onChange={(event) => {handleContentChange(event, index)}}></textarea>
                                        {/* <p className="text_length_alert"></p> */}
                                        {job_career_appeals[index].business_content_maxLength && job_career_appeals[index].business_content_maxLength == true ?
                                          <p className="text_length_alert">{job_career_appeals[index].business_content_lengthCount}文字オーバー</p>
                                        : <></>}
                                      </div>
                                      <p className="acc_ttl del">
                                        <a onClick={() => handleDeleteCareerAppeal(index)}>アピールしたい職歴を削除する</a>
                                      </p>
                                      <div className="nest_child acc_list_cont">
                                        <p className="acc_ttl">
                                          <a onClick={() => handleLinkClick3()} title="ポップアップが立ち上がります。TABキーを押して選択してください。">
                                            アピールしたい職歴を追加する
                                          </a>
                                        </p>
                                      </div>                               
                                      </>  
                                    ))}         
                                  </>  
                                }                        
                            </div>
                          </div> <a href="" className="btn type3 yellow" style={{ display: "none" }}>設定する</a>
                        </dd></>
                      }            
                       
                      
                      {(appeal_item_1 == 2 || appeal_item_2 == 2 || appeal_item_3 == 2) &&
                        <>
                          <dt>アピールしたい経験職種</dt>
                            <dd>
                              <div className="experience_point">
                                <div className="nest_child select_cont">
                                  <h4>経験職種1</h4>
                                  <p className="select" style={{ backgroundColor: job_category_1 != null ? "white" : "#dbffff" }}>
                                    <a onClick={toggleDialog7}>
                                      {job_category_1 != null ? (
                                        <span>{findValue(job_category_1, 'job_categories')}</span>
                                      ) : (
                                        <span>未登録</span>
                                      )}
                                    </a>
                                  </p>
                                </div>
                                
                                <div className="nest_child select_cont">
                                  <h4>経験職種2</h4>
                                  <p className="select" style={{ backgroundColor: job_category_2 != null ? "white" : "#dbffff" }}>
                                    <a onClick={toggleDialog8}>
                                      {job_category_2 != null ? (
                                        <span>{findValue(job_category_2, 'job_categories')}</span>
                                      ) : (
                                        <span>未登録</span>
                                      )}
                                    </a>
                                  </p>
                                </div>
                                {/* {dialogOpen8 && (
                                  <div role="dialog" tabIndex={0} className="modal_box">
                                    <div className="modal_inner">
                                      <h4>経験職種2</h4>
                                      <form>
                                        <h5 className="modal_ttl">アピールしたい経験職種を選択してください</h5>
                                        <div dir="ltr" className="slider slick-slider slick-initialized">
                                          <div className="slick-list">
                                            <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                              <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                                <div>
                                                  <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                                    <label className="column3"><input type="radio" name="categiries" onClick={() => {setJobCategory2(null); toggleDialog8()}} /> <span>なし</span></label>
                                                    {selectedSkills11.length == 0 &&
                                                      job_category_types
                                                        .map((data: any, index: any) => (
                                                          <label className="column3" key={index}>
                                                            <input
                                                              type="radio"
                                                              name=""
                                                              value={data.id}
                                                              onChange={handleCheckboxChange11}
                                                              checked={selectedSkills11.includes(data.id.toString())} />
                                                            <span>{data.name}</span>
                                                          </label>
                                                        ))}
                                                    {selectedSkills12.length == 0 &&
                                                      job_categories
                                                        .filter(data => data.job_category_type_id == kills8)
                                                        .map((data: any, index: any) => (
                                                          <label className="column3" key={index}>
                                                            <input
                                                              type="radio"
                                                              name=""
                                                              value={data.id}
                                                              onChange={handleCheckboxChange12}
                                                              checked={selectedSkills12.includes(data.id.toString())} />
                                                            <span>{data.name}</span>
                                                          </label>
                                                        ))}
                                                  </div>
                                                </div>
                                              </div>
                                              <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                      <p className="close"><span className="material-icons" onClick={toggleDialog8}>close</span></p>
                                    </div>
                                    <div className="overlay" onClick={toggleDialog8}></div>
                                  </div>
                                )} */}
                                <div className="nest_child select_cont">
                                  <h4>経験職種3</h4>
                                  <p className="select" style={{ backgroundColor: job_category_3 != null ? "white" : "#dbffff" }}>
                                    <a onClick={toggleDialog9}>
                                      {job_category_3 != null ? (
                                        <span>{findValue(job_category_3, 'job_categories')}</span>
                                      ) : (
                                        <span>未登録</span>
                                      )}
                                    </a>
                                  </p>
                                </div>
                                {/* {dialogOpen9 && (
                                  <div role="dialog" tabIndex={0} className="modal_box">
                                    <div className="modal_inner">
                                      <h4>経験職種3</h4>
                                      <form>
                                        <h5 className="modal_ttl">アピールしたい経験職種を選択してください</h5>
                                        <div dir="ltr" className="slider slick-slider slick-initialized">
                                          <div className="slick-list">
                                            <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                              <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                                <div>
                                                  <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                                    <label className="column3"><input type="radio" name="categiries" onClick={() => {setJobCategory3(null); toggleDialog9()}} /> <span>なし</span></label>
                                                    {selectedSkills13.length == 0 &&
                                                      job_category_types
                                                        .map((data: any, index: any) => (
                                                          <label className="column3" key={index}>
                                                            <input
                                                              type="radio"
                                                              name=""
                                                              value={data.id}
                                                              onChange={handleCheckboxChange13}
                                                              checked={selectedSkills13.includes(data.id.toString())} />
                                                            <span>{data.name}</span>
                                                          </label>
                                                        ))}
                                                    {selectedSkills14.length == 0 &&
                                                      job_categories
                                                        .filter(data => data.job_category_type_id == kills9)
                                                        .map((data: any, index: any) => (
                                                          <label className="column3" key={index}>
                                                            <input
                                                              type="radio"
                                                              name=""
                                                              value={data.id}
                                                              onChange={handleCheckboxChange14}
                                                              checked={selectedSkills14.includes(data.id.toString())} />
                                                            <span>{data.name}</span>
                                                          </label>
                                                        ))}
                                                  </div>
                                                </div>
                                              </div>
                                              <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                      <p className="close"><span className="material-icons" onClick={toggleDialog9}>close</span></p>
                                    </div>
                                    <div className="overlay" onClick={toggleDialog9}></div>
                                  </div>
                                )} */}
                            </div>
                          </dd>
                        </>
                      }      
                      {(appeal_item_1 == 3 || appeal_item_2 == 3 || appeal_item_3 == 3) &&
                      <><dt>アピールしたいPCスキル</dt><dd>
                          <div>
                            <div className="nest_child select_cont">
                              <h4>Word</h4>
                              <div className="radio">
                                <label><input type="radio" name="word_experience" checked={wordExp} onChange={toggleWordExperience} />あり</label>
                                <label><input type="radio" name="word_experience" checked={!wordExp} onChange={toggleWordExperience} />なし</label>
                              </div>
                              {wordExp && selectedSkills.length == 0 && (
                                <div className="nest_child acc_list_cont">
                                  <p className="acc_ttl fixed"><a onClick={toggleDialog}>Wordスキルを追加する</a></p>
                                </div>
                              )}
                              
                              {selectedSkills.length != 0 && (
                                <div className="nest_child">
                                  <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                                    <div className="txt_cont" onClick={toggleDialog}>
                                      <h4>Wordスキルを追加する</h4>
                                      <p><span>{selectedSkills.map((id, index) => (
                                        <React.Fragment key={id}>
                                          {index > 0 && ' / '}
                                          {findValue(id, 'oa_skills')}
                                        </React.Fragment>
                                      ))}
                                      </span> <span style={{ display: "none" }}>/</span></p>
                                    </div>
                                    <div className="icon_cont">
                                      <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog} alt="memo_icon" /></span>
                                    </div></a>
                                </div>
                              )}
                            </div>
                            <div className="nest_child select_cont">
                              <h4>Excel</h4>
                              <div className="radio">
                                <label><input type="radio" name="excel_experience" checked={excelExp} onChange={toggleExcelExperience} />あり</label>
                                <label><input type="radio" name="excel_experience" checked={!excelExp} onChange={toggleExcelExperience} />なし</label>
                              </div>
                            </div>
                            {excelExp && selectedSkills2.length == 0 && (
                              <div className="nest_child acc_list_cont">
                                <p className="acc_ttl fixed"><a onClick={toggleDialog2}>Excelスキルを追加する</a></p>
                              </div>
                            )}
                            {/* {dialogOpen2 && (
                              <div role="dialog" tabIndex={0} className="modal_box checkbox">
                                <div className="modal_inner">
                                  <h4>アピールしたいPCスキル（Excel）</h4>
                                  <form>
                                    <h5 className="modal_ttl">アピールしたいExcelスキルを選択してください</h5>
                                    <p className="note">※複数選択可能</p>
                                    <div className="slider_item">
                                      {oa_skills &&
                                        oa_skills
                                          .filter(data => data.oa_skill_category_id == 2)
                                          .map((data: any, index: any) => (
                                            <label key={index}>
                                              <input
                                                type="checkbox"
                                                name="アピールしたいPCスキル（Excel）"
                                                value={data.id}
                                                onChange={handleCheckboxChange2}
                                                checked={selectedSkills2.includes(data.id.toString())} />
                                              <span>{data.name}</span>
                                            </label>
                                          ))}
                                    </div>
                                  </form>
                                  <p className="complete" onClick={handleComplete2}>完了</p>
                                  <p className="close" onClick={toggleDialog2}><span className="material-icons">close</span></p>
                                </div>
                                <div className="overlay" onClick={toggleDialog2}></div>
                              </div>
                            )} */}
                            {selectedSkills2.length != 0 && (
                              <div className="nest_child">
                                <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                                  <div className="txt_cont" onClick={toggleDialog2}>
                                    <h4>Excelスキルを追加する</h4>
                                    <p><span>{selectedSkills2.map((id, index) => (
                                      <React.Fragment key={id}>
                                        {index > 0 && ' / '}
                                        {findValue(id, 'oa_skills')}
                                      </React.Fragment>
                                    ))}
                                    </span> <span style={{ display: "none" }}>/</span></p>
                                  </div>
                                  <div className="icon_cont">
                                    <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog2} alt="memo_icon" /></span>
                                  </div></a>
                              </div>
                            )}
                            <div className="nest_child select_cont">
                              <h4>デザイン系・その他事務系</h4>
                              {selectedSkills3.length == 0 && (
                                <div className="nest_child acc_list_cont">
                                  <p className="acc_ttl fixed"><a onClick={toggleDialog3}>デザイン系・その他事務系スキルを追加する</a></p>
                                </div>
                              )}                              
                              {selectedSkills3.length != 0 && (
                                <div className="nest_child">
                                  <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                                    <div className="txt_cont" onClick={toggleDialog3}>
                                      <h4>デザイン系・その他事務系スキルを追加する</h4>
                                      <p><span>{selectedSkills3.map((id, index) => (
                                        <React.Fragment key={id}>
                                          {index > 0 && ' / '}
                                          {findValue(id, 'oa_skills')}
                                        </React.Fragment>
                                      ))}</span> <span style={{ display: "none" }}>/</span></p>
                                    </div>
                                    <div className="icon_cont">
                                      <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog3} alt="memo_icon" /></span>
                                    </div></a>
                                </div>
                              )}
                            </div>
                            <div className="nest_child select_cont">
                              <h4>プログラミング系</h4>
                              {selectedSkills4.length == 0 && (
                                <div className="nest_child acc_list_cont">
                                  <p className="acc_ttl fixed"><a onClick={toggleDialog4}>プログラミング系スキルを追加する</a></p>
                                </div>
                              )}
                              {/* {dialogOpen4 && (
                                <div role="dialog" tabIndex={0} className="modal_box checkbox">
                                  <div className="modal_inner">
                                    <h4>アピールしたいPCスキル（プログラミング系）</h4>
                                    <form>
                                      <h5 className="modal_ttl">アピールしたいプログラミング系スキルを選択してください</h5>
                                      <p className="note">※複数選択可能</p>
                                      <div tabIndex={0} className="slider_item">
                                        {oa_skills &&
                                          oa_skills
                                            .filter(data => data.oa_skill_category_id == 4)
                                            .map((data: any, index: any) => (
                                              <label key={index}>
                                                <input
                                                  type="checkbox"
                                                  name="アピールしたいPCスキル（プログラミング系）"
                                                  value={data.id}
                                                  onChange={handleCheckboxChange4}
                                                  checked={selectedSkills4.includes(data.id.toString())} />
                                                <span>{data.name}</span>
                                              </label>
                                            ))}
                                      </div>
                                    </form>
                                    <p tabIndex={0} className="complete" onClick={handleComplete4}>完了</p>
                                    <p tabIndex={0} className="close" onClick={toggleDialog4}><span className="material-icons">close</span></p>
                                  </div>
                                  <div tabIndex={0} className="overlay" onClick={toggleDialog4}></div>
                                </div>
                              )} */}
                              {selectedSkills4.length != 0 && (
                                <div className="nest_child">
                                  <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                                    <div className="txt_cont" onClick={toggleDialog4}>
                                      <h4>プログラミング系スキルを追加する</h4>
                                      <p><span>{selectedSkills4.map((id, index) => (
                                        <React.Fragment key={id}>
                                          {index > 0 && ' / '}
                                          {findValue(id, 'oa_skills')}
                                        </React.Fragment>
                                      ))}</span> <span style={{ display: "none" }}>/</span></p>
                                    </div>
                                    <div className="icon_cont">
                                      <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={toggleDialog4} alt="memo_icon" /></span>
                                    </div></a>
                                </div>
                              )}
                            </div>
                          </div>
                        </dd></>
                      }
                      {(appeal_item_1 == 4 || appeal_item_2 == 4 || appeal_item_3 == 4) &&
                      <><dt>アピールしたい資格</dt><dd>
                          <div>
                            <div className="nest_child select_cont">
                              <h4>アピールしたい資格1</h4>
                              <p className="select" style={{ backgroundColor: selectedSkills6.length != 0 ? "white" : "#dbffff" }}>
                                <a onClick={toggleDialog5}>
                                  {selectedSkills6.length != 0 ? (
                                    <span>{findValue(selectedSkills6, 'qualifications')}</span>
                                  ) : (
                                    <span>未登録</span>
                                  )}
                                </a>
                              </p>
                            </div>                            
                            <div className="nest_child select_cont">
                              <h4>アピールしたい資格2</h4>
                              <p className="select" style={{ backgroundColor: selectedSkills8.length != 0 ? "white" : "#dbffff" }}>
                                <a onClick={toggleDialog6}>
                                  {selectedSkills8.length != 0 ? (
                                    <span>{findValue(selectedSkills8, 'qualifications')}</span>
                                  ) : (
                                    <span>未登録</span>
                                  )}
                                </a>
                              </p>
                            </div>
                            <div className="textarea_cont nest_child">
                              <h4>アピールしたい資格3</h4>
                              <label><input
                                type="text"
                                placeholder="資格名"
                                value={qualification_3}
                                onChange={handleQualificationInputChange} />
                                <p className="note">※一覧にない資格などがあればご入力ください</p>
                              </label>
                            </div>
                          </div>
                        </dd></>
                      }                                      
                      {(appeal_item_1 == 5 || appeal_item_2 == 5 || appeal_item_3 == 5) && 
                      <><dt>アピールしたい語学</dt><dd className="">
                          <div>
                            <div className="nest_child select_cont">
                              <h4>アピールしたい語学1</h4>
                              <p className="select" style={{ backgroundColor: selectedSkills16.length != 0 ? "white" : "#dbffff" }}>
                                <a onClick={toggleDialog10}>
                                  {selectedSkills16.length != 0 ? (
                                    <span>{findValue(selectedSkills15, 'languages')}, {findValue(selectedSkills16, 'levels')}レベル</span>
                                  ) : (
                                    <span>未登録</span>
                                  )}
                                </a>
                              </p>
                            </div>
                            {/* {dialogOpen10 && (
                              <div role="dialog" tabIndex={0} className="modal_box">
                                <div className="modal_inner">
                                  <h4>アピールしたい語学1</h4>                                  
                                  { currentSlideLanguageStudy > 0 ?
                                    <p id="btn_prev" onClick={prevLanguageStudy}>戻る</p>  
                                  :<></>}
                                  <form>
                                    {currentSlideLanguageStudy == 1 ? 
                                      <h5 className="modal_ttl">語学を選択してください</h5>
                                    :<></>}
                                    {currentSlideLanguageStudy == 2 ? 
                                      <h5 className="modal_ttl">習得レベルを選択してください</h5>
                                    :<></>}
                                    <div dir="ltr" className="slider slick-slider slick-initialized">
                                      <div className="slick-list">
                                        <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                          <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                            <div>
                                              <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                                {currentSlideLanguageStudy == 0 &&
                                                  <label className="column3">
                                                    <input type="radio" name="categiries" onClick={language1Toggle} tabIndex={0} /> 
                                                    <span>なし</span>
                                                  </label>
                                                }
                                                {currentSlideLanguageStudy == 0 &&
                                                  languages
                                                    .map((data: any, index: any) => (
                                                      <label className="column3" key={index}>
                                                        <input
                                                          type="radio"
                                                          name=""
                                                          value={data.id}
                                                          onChange={handleCheckboxChange15}
                                                          onClick={() => {setCurrentSlideLanguageStudy(1);}} 
                                                          checked={selectedSkills15.includes(data.id.toString())} />
                                                        <span>{data.name}</span>
                                                      </label>
                                                    ))}
                                                {currentSlideLanguageStudy == 1 &&
                                                  levels
                                                    .map((data: any, index: any) => (
                                                      <label className="column3" key={index}>
                                                        <input
                                                          type="radio"
                                                          name=""
                                                          value={data.id}
                                                          onChange={handleCheckboxChange16}
                                                          onClick={() => {setCurrentSlideLanguageStudy(0);}} 
                                                          checked={selectedSkills16.includes(data.id.toString())} />
                                                        <span>{data.name}</span>
                                                      </label>
                                                    ))}
                                              </div>
                                            </div>
                                          </div>
                                          <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                  <p className="close"><span className="material-icons" onClick={toggleDialog10}>close</span></p>
                                </div>
                                <div className="overlay" onClick={toggleDialog10}></div>
                              </div>
                            )} */}
                            <div className="nest_child select_cont">
                              <h4>アピールしたい語学2</h4>
                              <p className="select" style={{ backgroundColor: selectedSkills18.length != 0 ? "white" : "#dbffff" }}>
                                <a onClick={toggleDialog11}>
                                  {selectedSkills18.length != 0 ? (
                                    <span>{findValue(formattedName2, 'languages')}, {findValue(selectedSkills18, 'levels')}レベル</span>
                                  ) : (
                                    <span>未登録</span>
                                  )}
                                </a>
                              </p>
                            </div>
                           
                            <div className="textarea_cont nest_child">
                              <h4>アピールしたい語学3</h4>
                              <label>
                                <input
                                  type="text"
                                  placeholder="言語名、習得レベル"
                                  value={languageAppeal}
                                  onChange={handleInputChange} />
                              </label>
                              <p className="note">※一覧にない言語などがあればご入力ください</p>
                            </div>
                          </div>

                        </dd></>
                      }

                      
                      {(appeal_item_1 == 6 || appeal_item_2 == 6 || appeal_item_3 == 6) && 
                      <><dt>就労支援機関</dt><dd className="">
                          <div className="nest_child select_cont">
                            <h4>就労支援機関の利用有無</h4>
                            <div className="radio">
                              <label><input type="radio" name="support_agency_exists" checked={support_agency_exists} onChange={handleRadioChange2} />有</label> <label><input type="radio" name="support_agency_exists" checked={!support_agency_exists} onChange={handleRadioChange2} />無</label>
                            </div>
                            {support_agency_exists && (
                              <div className="">
                                <div>
                                  <div className="textarea_cont nest_child">
                                    <h4>機関・企業名</h4>
                                    <label>
                                      <input
                                        type="text"
                                        placeholder="例）○○職業センター、○○福祉医療機構"
                                        value={organization}
                                        onChange={(e) => setOrganization(e.target.value)} />
                                    </label>
                                  </div>
                                  <div className="textarea_cont nest_child">
                                    <h4>連絡先</h4>
                                    <label>
                                      <input
                                        type="text"
                                        placeholder="0300000000"
                                        value={contact_number}
                                        onChange={(e) => setContactNumber(e.target.value)} />
                                    </label>
                                  </div>
                                  <div className="textarea_cont nest_child">
                                    <h4>URL</h4>
                                    <label>
                                      <input
                                        type="text"
                                        placeholder="https://www.example.com"
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)} />
                                    </label>
                                  </div>
                                  <div className="nest_child radio_cont">
                                    <h4>エントリー企業との連携可否</h4>
                                    <div className="radio">
                                      <label>
                                        <input
                                          type="radio"
                                          value="true"
                                          checked={can_contact == true}
                                          onChange={() => setCanContact(true)} />
                                        可
                                      </label>
                                      <label>
                                        <input
                                          type="radio"
                                          value="false"
                                          checked={can_contact == false}
                                          onChange={() => setCanContact(false)} />
                                        否
                                      </label>
                                    </div>
                                  </div>
                                  <div className="nest_child select_cont">
                                    <h4>利用期間 開始月</h4>
                                    {/* {dialogOpen14 && (
                                      <div role="dialog" tabIndex={0} className="modal_box">
                                        <div className="modal_inner">
                                          <h4>利用期間の開始月</h4>
                                          <form>
                                            <h5 className="modal_ttl">年を選択してください</h5>
                                            <div dir="ltr" className="slider slick-slider slick-initialized">
                                              <div className="slick-list">
                                                <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                                  <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                                    <div>
                                                      <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                                        {trigger == 0 &&
                                                          years2
                                                            .map((year) => (
                                                              <label className="column3" key={year}>
                                                                <input
                                                                  type="radio"
                                                                  name=""
                                                                  value={year}
                                                                  onChange={handleCheckboxChange28}
                                                                  checked={selectedSkills28.includes(year)} />
                                                                <span>{year} 年</span>
                                                              </label>
                                                            ))}
                                                        {trigger == 1 &&
                                                          months
                                                            .map((month) => (
                                                              <label className="column3" key={month}>
                                                                <input
                                                                  type="radio"
                                                                  name=""
                                                                  value={month}
                                                                  onChange={handleCheckboxChange29}
                                                                  checked={selectedSkills29.includes(month)} />
                                                                <span>{month} 月</span>
                                                              </label>
                                                            ))}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                          <p className="close"><span className="material-icons" onClick={toggleDialog14}>close</span></p>
                                        </div>
                                        <div className="overlay" onClick={toggleDialog14}></div>
                                      </div>
                                    )} */}

                                    <p className="select">
                                      <a onClick={toggleDialog14}>
                                        {start_date != null ? 
                                          <>
                                            {formatYM(start_date)}
                                          </>
                                          : 
                                            '選択してください'
                                        }
                                        {/* {start_date != null ? (
                                          <a>{formatYM(start_date)}</a>
                                        ) : (
                                          selectedSkills29.length != 0 ? (
                                            <a>{selectedSkills28}年{selectedSkills29}月</a>
                                          ) : (
                                            <span>選択してください</span>
                                          )
                                        )} */}
                                      </a></p>
                                  </div>
                                  <div className="nest_child select_cont">
                                    <h4>利用期間 終了月</h4>
                                    

                                    <p className="select">
                                      <a onClick={toggleDialog15}>
                                        {end_date != null ? 
                                          <>
                                            {formatEmploymentYM(end_date)}
                                          </>
                                          : 
                                            '選択してください'
                                        }
                                        {/* {end_date?.includes('9999-12') ? (
                                          '終了月なし（継続中）'
                                        ) : (
                                          end_date != null ? (
                                            <a>{formatYM(end_date)}</a>
                                          ) : (
                                            selectedSkills31.length != 0 ? (
                                              selectedSkills31.includes('9999-12') ? (
                                                <a>終了月なし（継続中）</a>
                                              ) : (
                                                <span>{selectedSkills30}年{selectedSkills31}月</span>
                                              )
                                            ) : (
                                              <span>選択してください</span>
                                            )
                                          )
                                        )} */}
                                      </a></p>
                                  </div>
                                  <div className="textarea_cont nest_child">
                                    <h4>訓練内容（２００文字以内）</h4>
                                    <textarea
                                      cols={30}
                                      rows={10}
                                      placeholder="例）研修で学んだこと、グループワークなどで取り組んだ課題"
                                      value={training_content}
                                      onChange={handleTextareaChange}
                                    ></textarea>
                                    {trainingContentError == true ?
                                      <p className="text_length_alert">{trainingContentErrorCount}文字オーバー</p>
                                    : <></>}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </dd></>
                      }
                      {(appeal_item_1 == 7 || appeal_item_2 == 7 || appeal_item_3 == 7) && 
                      <><dt>自己PR</dt><dd className="">
                          <div className="nest_child select_cont">
                            <div className="textarea_cont">
                              <h4>自己PR（４００文字以内）</h4>
                              <textarea
                                cols={30}
                                rows={10}
                                placeholder="活かせるスキルや意気込み、または「こういう合理的配慮・環境があれば○○ができる」などのPRを書いてください。"
                                value={selfPR}
                                onChange={handlePrTextareaChange}
                              ></textarea>
                              <p className="text_length_alert">{over}</p>
                            </div>
                          </div>
                        </dd></>
                      }
                    </dl>
                  </div>
                {/* Second Code Block */}
                
            </div>

            <div className="cont2">
              <div className="ttl_box">
                <h3 className="ttl">基本情報</h3><a href="#" onClick={handleEntryflg}><span>＞</span>マイページへ戻り編集する</a>
              </div>
              <dl className="type2">
                <dt>顔写真<span className="required">必須</span></dt>
                  <dd className="photo">
                    <div className="img_box">
                      <img style={{width: "160px"}} src={face_photo1 == null ? face_photo : face_photo1} alt=""/>
                    </div>
                    <p className="btn type3">
                    <label>
                      <input type="file" className="btn type3" ref={fileInputRef} onChange={setImage}/>アップロード </label>
                    </p>
                  </dd>
                <dt>氏名<span className="required">必須</span></dt>
                  <dd>{ family_name } { first_name }</dd>
                <dt>氏名（フリガナ）<span className="required">必須</span></dt>
                  <dd>{ family_name_kana } { first_name_kana }</dd>
                <dt>生年月日<span className="required">必須</span></dt>
                  <dd>{ birthday }</dd>
                <dt>障がいの基本情報<span className="required">必須</span></dt>
                  {applicantHandicapQualifications != null ? (
                    <dd>
                      {applicantHandicapQualifications.map((handicap, index) => (
                        <><><a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed" style={{width: isMobile ? 'calc(100% - 30px)' : '100%'}}>
                          <div className="txt_cont" key={index} onClick={() => handleFlgHandi(index)}>
                            <p>
                              {findValue(findValue3(handicap.handicap_id), 'handicap_types')}&nbsp; 
                              {findValue(handicap.handicap_id, 'handicaps')} <br />障がい者手帳&nbsp;
                              {/* {handicap.disability_certificate_status_id == 2 &&  '障がい手帳あり'}
                              {handicap.disability_certificate_status_id == 1 &&  '障がい手帳なし'}
                              {handicap.disability_certificate_status_id == 3 && '障がい手帳申請中'}   */}
                              {findValue(handicap.disability_certificate_status_id, 'disability_certificate_statuses')}<br />
                              {handicap.disability_certificate_status_id == 2 && 
                                <span style={{color: "unset"}}>
                                  <br/>{ findValue(handicap.disability_certificate_id, 'disability_certificates') }
                                </span>}
                              {/* {handicap.disability_certificate_status_id == 2 && 
                              findValue(handicap.disability_certificate_id, 'disability_certificates')}　
                              
                              {handicap.disability_certificate_status_id == 1 && 
                              findValue2(handicap.disability_certificate_id, 'disability_certificates')} */}
                            </p>
                            {!handiFlg && 
                              <div className="icon_cont">
                                <span className="edit"><img onClick={() => handleFlgHandi(index)} src="/assets/img/mypage/memo_icon.svg" alt="memo_icon"/></span>
                              </div>}
                          </div>
                        </a>
                        { !handiFlg &&
                        <><div className="textarea_cont" key={`process` + index}>
                            <h4 className={AreaOpenProcess[index] ? "open acc_h4" : "acc_h4" && readOnlyProcess ? "" : "acc_h4"}>
                              <a onClick={() => toggleOpenProcess(index)}>経緯と経過（２００文字以内）
                                <span className="any">任意</span>
                              </a>
                            </h4>
                            {readOnlyProcess ?
                              <div>
                                  <p>{ textValue }</p>
                              </div>
                            :<></>} 
                            {AreaOpenProcess[index] ?
                              <div>
                                <textarea
                                  cols={30}
                                  rows={10}
                                  placeholder="発症時期:〇〇年〇〇月ごろ〜 経緯: 通院:月○回 服薬:朝・昼・夕各○回"
                                  value={handicap.process}
                                  onChange={(event) => {handleProcessChange(event, index)}}
                                ></textarea>
                              </div>
                            : <></> }
                            {applicantHandicapQualifications[index].processMaxLength && applicantHandicapQualifications[index].processMaxLength == true ?
                              <p className="text_length_alert">{applicantHandicapQualifications[index].processLengthCount}文字オーバー</p>
                            : <></>}
                          </div>

                          <div className="textarea_cont" key={`characteristics` + index}>
                            <h4 className={AreaOpenCharacter[index] ? "open acc_h4" : "acc_h4" && readOnlyCharacter ? "" : "acc_h4"}>
                              <a className={readOnlyCharacter ? "fixed" : ""} title='特性と対策（２００文字以内）' onClick={() => toggleOpenCharacter(index)}>  
                                特性と対策（２００文字以内） <span className="any">任意</span>
                              </a>
                            </h4>  
                            {readOnlyCharacter ?
                              <div>
                                  <p>{ textValue }</p>
                              </div>
                            :<></>}  
                            {AreaOpenCharacter[index] ?
                              <div>
                                <textarea
                                  cols={30}
                                  rows={10}
                                  placeholder="私には以下のような特性がございますが、それぞれの特性に対し自助努力を継続する事で業務遂行をしております。 特性（1） 特性（2） 特性（3） 自己対応（1） 自己対応（2） 自己対応（3）"
                                  value={handicap.characteristics_and_countermeasures}
                                  onChange={(event) => {handleCharacteristicsChange(event, index)}}
                                ></textarea>
                              </div>
                            : <></> }
                            {applicantHandicapQualifications[index].characterMaxLength && applicantHandicapQualifications[index].characterMaxLength == true ?
                              <p className="text_length_alert">{applicantHandicapQualifications[index].characterMaxLengthCount}文字オーバー</p>
                            : <></>}
                          </div>
                          <p className="acc_ttl del">
                            <a onClick={(e)=>deleteitem(index)}>種別を削除する</a>
                          </p>
                          </>
                        }
                        {handiFlg && handicap.process != null &&
                          <div className="textarea_cont">
                            <h4 className="acc_h4 fixed">
                              <a className="not_active">発症経緯と経過
                                <span className="any">任意</span>
                              </a>
                            </h4> 
                            <p>{handicap.process}</p>
                          </div>
                        }
                        </>
                        {handiFlg && handicap.characteristics_and_countermeasures != null &&
                          <div className="textarea_cont">
                            <h4 className="acc_h4 fixed">
                              <a className="not_active">障がい特 性と対策
                                <span className="any">任意</span>
                              </a>
                            </h4> 
                            <p>{handicap.characteristics_and_countermeasures}</p>
                          </div>
                          }
                          </>
                      ))}
                      {!handiFlg && 
                        <p className="acc_ttl"><a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={toggleDialog18}>種別を追加する</a></p>
                      }
                    </dd>
                    )
                    :
                    (
                      <dd className={classEr6}>
                        <div className="box">
                          <div className="nest_child acc_list_cont">
                            <p className="acc_ttl fixed"><a onClick={toggleDialog18}>種別を追加する</a>
                            </p>
                          </div>
                        </div>
                      </dd>                      
                    )
                  }
                <dt>就労上必須の配慮事項<span className="required">必須</span></dt>
                <dd className={`${classEr10}`}>
                    <div>
                    { (applicantConsiderationConditionsRequiredExists == null || (consideration_conditions_required_exists && consideration_conditions_required.length == 0)) ?
                      <>
                      <div className="nest_child radio_cont" style={{borderBottom: "1px solid #9e9f9f", width: "100%"}}>
                          <ul className="radio_list">
                              <li>
                                  <label>
                                      <input type="radio" name="consideration_conditions_required_exists" value="true" onChange={radioChange} checked={consideration_conditions_required_exists == true}/>
                                      <span className="m-form-radio-name">
                                          <span className="m-form-radio-text">有</span>
                                      </span>
                                  </label>
                              </li>
                              <li>
                                  <label>
                                      <input type="radio" name="consideration_conditions_required_exists" value="false" onChange={radioChange} checked={consideration_conditions_required_exists == false}/>
                                      <span className="m-form-radio-name">
                                          <span className="m-form-radio-text">無</span>
                                      </span>
                                  </label>
                              </li>
                          </ul>
                      </div>      
                      { consideration_conditions_required_exists ?
                        <>
                          {consideration_conditions_required.length == 0 ? 
                                <div className="nest_child acc_list_cont">
                                    <p className="acc_ttl fixed" onClick={considerationRequiredModalToggle}>
                                        <a title="ポップアップが立ち上がります。TABキーを押して選択してください。">就労上必須の配慮事項を追加する</a>
                                    </p>
                                </div>
                            :
                            <div className="nest_child" style={{borderBottom: "1px solid #9e9f9f"}}>
                                <a href="javascript:void(0)" onClick={considerationRequiredModalToggle} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed"
                                 style={{width: isMobile ? 'calc(100% - 30px)' : '100%'}}>
                                    <div  className="txt_cont"  onClick={considerationRequiredModalToggle}>
                                        <p><span>{consideration_conditions_required.map((id, index) => (
                                            <React.Fragment key={id}>
                                            {index > 0 && ' / '}
                                            {findValue(id, 'consideration_conditions')}
                                            </React.Fragment>
                                        ))}</span></p>
                                    </div>
                                    <div className="icon_cont">
                                      <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" alt=""/></span>
                                    </div>
                                </a>
                            </div>
                          }
                          { consideration_conditions_required.length != 0 &&
                              <div className="nest_child acc_list_cont">
                                  <p className="acc_ttl del"><a onClick={() => {setConsiderationConditionsRequired([]);setClassEr10("error_box");}}>就労上必須の配慮事項を削除する</a></p>
                              </div>
                          }
                        </>
                      :<></>}
                      <div className="textarea_cont">
                          <h4 className={readOnlyConsiderationRequired ? "open acc_h4" : "acc_h4" && readOnlyConsiderationRequired ? "" : "acc_h4"}>
                              <a className={readOnlyConsiderationRequired ? "" : "fixed"} onClick={toggleOpenConsiderationRequired}> 配慮事項（その他)
                                <span className="any">任意</span>
                              </a>
                          </h4>
                          {readOnlyConsiderationRequired ?
                              <div>
                                  <p></p>
                              </div>
                          :<></>}  
                          {AreaOpenConsiderationRequired ?
                            <div>
                              <textarea aria-colspan={30} aria-rowspan={10} 
                                placeholder="選択肢にない配慮事項がございましたら、ご自由にお書きください。" 
                                value={consideration_condition_supplemental} 
                                onChange={handleInputsupplment}></textarea>
                            </div>
                          :<></>} 
                          {considerationConditionSupplementalMaxlengthError == true ?
                            <p className="text_length_alert">{considerationConditionSupplementalMaxlengthCount}文字オーバー</p>
                          : <></>}
                      </div>
                    </>
                    :
                      <>
                    {!consideration_conditions_required_exists ? <span style={{color: 'unset'}}>無</span> : <></>}
                      <div className="preview">
                        <div className="txt_cont" style={{borderBottom: "1px #adadad solid", marginBottom:"15px", paddingBottom: "15px"}}>
                          <p>
                            <span>{consideration_conditions_required.map((id, index) => (
                              <React.Fragment key={id}>
                                {index > 0 && ' / '}
                                {findValue(id, 'consideration_conditions')}
                              </React.Fragment>
                            ))}</span>
                            </p>
                        </div>
                      </div> 
                      {consideration_conditions_required_exists ?
                      <div className="textarea_cont">
                          <h4 className="acc_h4 fixed">
                              <p className="not_active"> 配慮事項（その他)<span className="any">任意</span>
                              </p>
                          </h4>
                          <p>{ consideration_condition_supplemental }</p>
                      </div>
                      :<></>} 
                      </>         
                    }
                    
                  </div>
                </dd>
                {considerationConditionsRequiredModal &&
                      <div role="dialog"  className="modal_box checkbox" >
                          <div className="modal_inner">
                              <h4 data-v-7074e5f8="">配慮事項</h4>
                              <form data-v-7074e5f8="">
                              <h5 className="modal_ttl">就業に関する配慮を選択してください</h5>
                              <p className="note">※複数選択可能</p>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                  <div className="slick-list">
                                  <div className="slick-track" style={{opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%"}}>
                                      <div data-index="0" aria-hidden="false" className="slick-slide slick-active slick-current" style={{outline: "none", width: "100%"}}>
                                          <div className="slider_item" style={{width: "100%", display: "inline-block"}}>
                                          {considerationRequiredSlide == 0 &&
                                          filteredData
                                              .map((d, index) => (
                                                  <label className="column3" key={index}>
                                                  <input type="checkbox" name="" value={d.id} onClick={(e) => { handleConsiderationRequiredCheckbox(e, d.id)}} defaultChecked={consideration_conditions_required.includes(d.id)}/>
                                                  <span>{d.name}</span>
                                                  </label>
                                              ))
                                          }
                                          {considerationRequiredSlide == 1 &&
                                          filteredData2
                                              .map((d, index) => (
                                                  <label className="column3" key={index}>
                                                  <input type="checkbox" name="" value={d.id} onClick={(e) => {handleConsiderationRequiredCheckbox(e,d.id) }} defaultChecked={consideration_conditions_required.includes(d.id)}/>
                                                  <span>{d.name}</span>
                                                  </label>
                                              ))
                                          }
                                          {considerationRequiredSlide == 2 &&
                                          filteredData3
                                              .map((d, index) => (
                                                  <label className="column3" key={index}>
                                                  <input type="checkbox" name="" value={d.id} onClick={(e) => {handleConsiderationRequiredCheckbox(e,d.id)}} defaultChecked={consideration_conditions_required.includes(d.id)}/>
                                                  <span>{d.name}</span>
                                                  </label>
                                              ))
                                          }
                                          </div>
                                      </div>
                                  </div>
                                  </div>
                              </div>
                              </form>
                              <p className="close"><span className="material-icons" onClick={considerationRequiredModalToggle}>close</span></p>
                              <p id="btn_next" onClick={considerationRequiredModalNext} >{considerationRequiredSlide == 2 ? '完了':'次へ'}</p>
                          </div>
                          <div className="overlay" onClick={considerationRequiredModalToggle}></div>
                          </div>
                  }
                <dt>郵便番号<small>（半角数字）</small><span className="required">必須</span></dt>
                <dd className={`zip ${classEr8}`}>
                  {
                    postcodes == "" ? (
                    <div>
                      <label style={{display:"flex"}}>
                        <input onChange={onChangePostCode} type="tel" name="zip" placeholder=""/>
                        <button onClick={searchPostCode} type="button" className="btn type3">住所検索</button>
                      </label>
                      <span className="ex">
                        ※ハイフン不要、半角数字
                      </span>
                    </div>) : <div>〒 { postcodes }</div>
                  }
                </dd>
                <dt>住所<span className="required">必須</span></dt>
                <dd className={`address ${classEr9}`}> 
                {isBlankAddress ? 
                  <>
                    <div>
                      <h4>都道府県 市・区</h4>
                      <p>{ fullAddress || '郵便番号を入力後自動で入力されます' }</p>
                    </div>
                    <div>
                      <h4>番地</h4>
                      <input type="text" name="address" value={address} onChange={onChangeAddress} placeholder=""/>
                    </div>
                  </>
                :
                  <div>
                    { prefecture_name }{ city_name }{ address }
                  </div>
                }
                  <span className="ex">※番地は面接が確定次第、企業へ公開されます</span>
                </dd>
                <dt>メールアドレス<span className="required">必須</span></dt>
                  <dd> { email } <br/>
                  <span className="ex">※面接が確定次第、企業へ公開されます</span></dd>
                  <dt>電話番号<span className="required">必須</span></dt>
                    {mobile_number != '' ? (
                      <dd className="tel">
                        {mobile_number} <br />
                        <span className="ex">※面接が確定次第、企業へ公開されます</span>
                      </dd>
                    ) : (
                      <dd className={`tel ${classEr1}`}>
                        <div>
                          <label className="mb5">
                          <input type="tel" tabIndex={0} value={tel} onChange={(event) => {{handleMobileNumberChange(event)}}} />
                          </label>
                          <span className="ex">
                            ※ハイフン不要、半角数字<br />
                            ※面接が確定次第、企業へ公開されます
                          </span>
                        </div>
                      </dd>
                    )}
                <dt>最終学歴<span className="required">必須</span></dt>
                  {final_education_id != null ? (
                    <dd>{findValue(final_education_id, 'final_educations')}</dd>
                  ) : (
                    <dd className={!final_education_id != null ? classEr2 : ''}>
                      <p className="select" onClick={toggleDialog16}>
                      {selectedSkills32.length == 0 ? (
                        <a>未登録</a>
                      ):(
                        <a>{findValue(selectedSkills32, 'final_educations')}</a>
                      )
                      }
                      </p>
                      
                    </dd>
                  )}

                <dt>卒業・卒業見込み年度<span className="required">必須</span></dt>
                
                <dd className={!graduation_date ? "error_box" : ''} >
                  {!isBlankgraduationDate ? 
                    <div>{formatYM(graduation_date)}</div>
                  :
                    <div>
                      <p className={graduation_date ? "comp select" : "select"}>
                        {graduation_date.length == 0 ? 
                          <a onClick={toggleDialog19}>未登録</a>
                        :
                          <a onClick={toggleDialog19}>{formatYM(graduation_date)}</a>
                        }
                      </p>
                        
                    </div>
                  }
                </dd>
                <dt>就労支援機関の有無<span className="any">任意</span></dt>
                  <dd>{support_agency_exists ? '有' : '無'}</dd>
                <dt>就職カテゴリー<span className="any">任意</span></dt>
                  <dd>{new_graduate ? '新卒' : '中途'}</dd>
                <dt>直近の職歴<span className="required">必須</span></dt>
                  {/* <dd className=""> */}
                  {career_count_id == 1 ? 
                    <dd className="">
                    職歴なし
                    </dd>
                  : 
                    <dd className={classEr3}>
                      <div>
                        {recentFlg ? (
                          !firstRecentFlg ?
                          <a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                            <div className="txt_cont" onClick={toggleDialog17}>
                              <p>{company} / {findValue(industry_id, 'industries')}<br/>
                                {findValue(job_category_id, 'job_categories')} <br/>
                                {formatYM(arrival_date)}&nbsp;〜&nbsp;{formatYM(departure_date) || '現在' }</p>
                            </div>
                              
                            <div className="icon_cont">
                              <span className="edit"><img onClick={toggleDialog17} src="/assets/img/mypage/memo_icon.svg" alt="memo_icon"/></span>
                            </div>
                          </a>
                          :
                          <div className="preview" role="dialog">
                            <div className="txt_cont">
                              <p>
                                {company || '会社名未入力' } / { findValue(industry_id, 'industries') }<br/>
                                {findValue(job_category_id, 'job_categories')} <br/>
                                {formatYM(arrival_date)}&nbsp;〜&nbsp;{formatYM(departure_date) || '現在' }</p>
                            </div>
                          </div>
                        ):(
                          <>
                            {!recentFlg && 
                              <p className="acc_ttl"><a onClick={toggleDialog17}>直近の職歴を追加する</a></p>
                            }
                          </>
                        )}
                          </div>
                           <div>
                            <h4>業務内容</h4>
                            {!firstRecentFlg ?
                            <textarea
                              cols={30}
                              rows={10}
                              value={textareaValue}
                              onChange={handleTextareaChange2}
                            ></textarea>
                            : <p>{textareaValue}</p> 
                            }
                          </div>
                        </dd>
                        }
                        

                        <dt>特記事項<span className="any">任意</span></dt>
                        <dd>{special_content}</dd>
                      </dl><br />
                      <div className="ttl_box">
                        <h3 className="ttl">希望条件</h3><a href="#" onClick={handleEntryflg}><span>＞</span>マイページへ戻り編集する</a>
                      </div>
                      <dl className="type2">
                        <dt>仕事の探し方<span className="any">任意</span></dt>
                        <dd>{findValue(search_policy_id, 'search_policies')}</dd>
                        <dt>働き方<span className="any">任意</span></dt>
                        <dd>{compatibilities.map((id, index) => (
                                    <React.Fragment key={id}>
                                      {index > 0 && ' / '}
                                      {findValue(id, 'work_styles')}
                                    </React.Fragment>
                                  ))}</dd>
                        <dt>就労時間<span className="any">任意</span></dt>
                        <dd>{findValue(working_hour_id, 'working_hours')}</dd>
                        <dt>在宅・リモートワーク<br className="pc_only" />
                          希望<span className="any">任意</span></dt>
                        <dd>{findValue(work_from_home_id, 'work_from_home')}</dd>
                        <dt>希望勤務地<span className="any">任意</span></dt>
                        <dd>
                          <ul>
                            <li>{findValue(prefecture_id_1, 'prefectures')}</li>
                            <li>{findValue(prefecture_id_2, 'prefectures')}</li>
                          </ul>
                        </dd>
                        <dt>通勤手段<span className="any">任意</span></dt>
                        <dd>
                          <div role="dialog" className="preview">
                            <div className="txt_cont">
                              <a>{commuting_methods != null ?? findValue(commuting_methods, 'commuting_methods')}</a>
                            </div>
                          </div>
                        </dd>
                        <dt>希望する雇用形態<span className="required">必須</span></dt>
                        {employment_statuses_id.length != 0 ? (
                          <dd>
                            {employment_statuses_id.map((id, index) => (
                              <React.Fragment key={id}>
                                {index > 0 && ' / '}
                                {findValue(id, 'employment_statuses')}
                              </React.Fragment>
                            ))}
                          </dd>
                        ) : (
                          <dd className={classEr4}>
                            <p className="select" onClick={toggleDialog13}>
                              {selectedSkills27.length == 0 ? (
                                <a>未登録</a>
                              ) : (
                                <a>{selectedSkills27.map((id, index) => (
                                  <React.Fragment key={id}>
                                    {index > 0 && ' / '}
                                    {findValue(id, 'employment_statuses')}
                                  </React.Fragment>
                                ))}</a>
                              )}
                            </p>
                            
                          </dd>
                        )}
                        <dt>希望年収<span className="required">必須</span></dt>
                        {annual_income_id != undefined && annual_income_id != null ? (
                          <dd>{findValue(annual_income_id, 'annual_incomes')}</dd>
                        ) : (
                          <dd className={classEr5}>
                            <p className="select" onClick={toggleDialog12}>
                              {selectedSkills26.length == 0 ? (
                                <a>未登録</a>
                              ) : (
                                <a>{findValue(selectedSkills26, 'annual_incomes')}</a>
                              )}
                            </p>
                            
                          </dd>
                        )}
                        <dt>希望職種<span className="any">任意</span></dt>
                        <dd>
                          <ul>
                              <li>{findValue(job_category_id_1, 'job_categories')}</li>
                              <li>{findValue(job_category_id_2, 'job_categories')}</li>
                          </ul>
                        </dd>
                      </dl>
                    </div><div className="btn_box cont2"><a type="submit" className="btn type3" onClick={next}>確認する<span>＞</span></a></div>
          </form>
        </section>
        {/* all dialogs list */}
        {dialogOpen && (
                        <div role="dialog" tabIndex={0} className="modal_box checkbox">
                          <div className="modal_inner">
                            <h4>アピールしたいPCスキル（Word）</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたいWordスキルを選択してください</h5>
                              <p className="note">※複数選択可能</p>
                              <div className="slider_item">
                                {oa_skills && oa_skills
                                    .filter(data => data.oa_skill_category_id == 1)
                                    .map((data: any, index: any) => (
                                      <label key={index}>
                                        <input
                                          type="checkbox"
                                          name="アピールしたいPCスキル（Word）"
                                          value={data.id}
                                          onChange={handleCheckboxChange}
                                          checked={selectedSkills.includes(data.id)} />
                                        <span>{data.name}</span>
                                      </label>
                                    ))}
                              </div>
                            </form>
                            <p className="complete" onClick={handleComplete}>完了</p>
                            <p className="close" onClick={toggleDialog}><span className="material-icons">close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog}></div>
                        </div>
                      )}
                    
                      {dialogOpen2 && (
                        <div role="dialog" tabIndex={0} className="modal_box checkbox">
                          <div className="modal_inner">
                            <h4>アピールしたいPCスキル（Excel）</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたいExcelスキルを選択してください</h5>
                              <p className="note">※複数選択可能</p>
                              <div className="slider_item">
                                {oa_skills && oa_skills
                                    .filter(data => data.oa_skill_category_id == 2)
                                    .map((data: any, index: any) => (
                                      <label key={index}>
                                        <input
                                          type="checkbox"
                                          name="アピールしたいPCスキル（Excel）"
                                          value={data.id}
                                          onChange={handleCheckboxChange2}
                                          checked={selectedSkills2.includes(data.id)} />
                                        <span>{data.name}</span>
                                      </label>
                                    ))}
                              </div>
                            </form>
                            <p className="complete" onClick={handleComplete2}>完了</p>
                            <p className="close" onClick={toggleDialog2}><span className="material-icons">close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog2}></div>
                        </div>
                      )}

                      {dialogOpen3 && (<div role="dialog" tabIndex={0} className="modal_box checkbox">
                        <div className="modal_inner">
                          <h4>アピールしたいPCスキル（デザイン系・その他事務系）</h4>
                          <form>
                            <h5 className="modal_ttl">アピールしたいデザイン系・その他事務系スキルを選択してください</h5>
                            <p className="note">※複数選択可能</p>
                            <div tabIndex={0} className="slider_item">
                              {oa_skills && oa_skills
                                  .filter(data => data.oa_skill_category_id == 3)
                                  .map((data: any, index: any) => (
                                    <label key={index}>
                                      <input
                                        type="checkbox"
                                        name="アピールしたいPCスキル（デザイン系・その他事務系）"
                                        value={data.id}
                                        onChange={handleCheckboxChange3}
                                        checked={selectedSkills3.includes(data.id)} />
                                      <span>{data.name}</span>
                                    </label>
                                  ))}
                            </div>
                          </form>
                          <p tabIndex={0} className="complete" onClick={handleComplete3}>完了</p>
                          <p tabIndex={0} className="close" onClick={toggleDialog3}><span className="material-icons">close</span></p>
                        </div>
                        <div tabIndex={0} className="overlay" onClick={toggleDialog3}></div>
                      </div>
                      )}

                      {dialogOpen4 && (
                        <div role="dialog" tabIndex={0} className="modal_box checkbox">
                          <div className="modal_inner">
                            <h4>アピールしたいPCスキル（プログラミング系）</h4>
                            <form>
                              <h5 className="modal_ttl">アピールしたいプログラミング系スキルを選択してください</h5>
                              <p className="note">※複数選択可能</p>
                              <div tabIndex={0} className="slider_item">
                                {oa_skills &&
                                  oa_skills
                                    .filter(data => data.oa_skill_category_id == 4)
                                    .map((data: any, index: any) => (
                                      <label key={index}>
                                        <input
                                          type="checkbox"
                                          name="アピールしたいPCスキル（プログラミング系）"
                                          value={data.id}
                                          onChange={handleCheckboxChange4}
                                          checked={selectedSkills4.includes(data.id)} />
                                        <span>{data.name}</span>
                                      </label>
                                    ))}
                              </div>
                            </form>
                            <p tabIndex={0} className="complete" onClick={handleComplete4}>完了</p>
                            <p tabIndex={0} className="close" onClick={toggleDialog4}><span className="material-icons">close</span></p>
                          </div>
                          <div tabIndex={0} className="overlay" onClick={toggleDialog4}></div>
                        </div>
                      )}

                      {dialogOpen5 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>アピールしたい資格1</h4>
                            { currentSlide > 0 ?
                              <p id="btn_prev" onClick={prev}>戻る</p>  
                            :<></>}
                            <form>
                              <h5 className="modal_ttl">アピールしたい資格を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          <label className="column3"><input type="radio" name="categiries" onClick={qualification1Toggle}/> <span>なし</span></label>
                                          {currentSlide == 0 &&
                                            qualification_categories
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange5}
                                                    onClick={() => {setCurrentSlide(1);}} 
                                                    checked={selectedSkills5.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                          ))}
                                          {currentSlide == 1 &&
                                            qualifications
                                              .filter(data => data.qualification_category_id == kills5 && data.id != selectedSkills6 && data.id != selectedSkills8)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange6}
                                                    onClick={() => {setCurrentSlide(0);}} 
                                                    checked={selectedSkills6 == data.id} />
                                                  <span>{data.name}</span>
                                                </label>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={() =>{toggleDialog5(); setCurrentSlide(0);}}>close</span></p>
                          </div>
                          <div className="overlay" onClick={() =>{toggleDialog5(); setCurrentSlide(0);}}></div>
                        </div>
                      )}
                      {dialogOpen6 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>アピールしたい資格2</h4>
                            { currentSlide > 0 ?
                              <p id="btn_prev" onClick={prev}>戻る</p>  
                            :<></>}
                            <form>
                              <h5 className="modal_ttl">アピールしたい資格を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      {/* <div> */}
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          <label className="column3"><input type="radio" name="categiries" tabIndex={0} onClick={qualification2Toggle} /> <span>なし</span></label>
                                          {currentSlide == 0 &&
                                            qualification_categories
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange7}
                                                    onClick={() => {setCurrentSlide(1);}} 
                                                    checked={selectedSkills7.includes(data.id.toString())} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {currentSlide == 1 &&
                                            qualifications
                                              .filter(data => data.qualification_category_id == kills6 && data.id != selectedSkills6 && data.id != selectedSkills8)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange8}
                                                    onClick={() => {setCurrentSlide(0);}} 
                                                    checked={selectedSkills8 == data.id} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      {/* </div> */}
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={() => {toggleDialog6(); setCurrentSlide(0);}}>close</span></p>
                          </div>
                          <div className="overlay" onClick={() => {toggleDialog6(); setCurrentSlide(0);}}></div>
                        </div>
                      )}
                      {dialogOpen7 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>経験職種1</h4>
                            { currentSlide > 0 ?
                              <p id="btn_prev" onClick={prev}>戻る</p>  
                            :<></>}
                            <form>
                              <h5 className="modal_ttl">アピールしたい経験職種を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      {/* <div> */}
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          <label className="column3">
                                            <input type="radio" 
                                              name="categiries" 
                                              onClick={() => {
                                                setJobCategory1(null); 
                                                toggleDialog7();
                                                }} /> 
                                              <span>なし</span>
                                          </label>
                                          {currentSlide == 0 &&
                                            job_category_types
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange9}
                                                    onClick={() => {setCurrentSlide(1);}} 
                                                    checked={selectedSkills9 == data.id} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {currentSlide == 1 &&
                                            job_categories
                                              .filter(data => data.job_category_type_id == kills7 && selectedSkills10 != data.id && selectedSkills12 != data.id && selectedSkills14 != data.id)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange10}
                                                    onClick={() => {setCurrentSlide(0);}} 
                                                    checked={selectedSkills10 == data.id} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      {/* </div> */}
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={() => {toggleDialog7(); setCurrentSlide(0);}}>close</span></p>
                          </div>
                          <div className="overlay" onClick={() => {toggleDialog7(); setCurrentSlide(0);}}></div>
                        </div>
                      )}
                      {dialogOpen8 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>経験職種2</h4>
                            { currentSlide > 0 ?
                              <p id="btn_prev" onClick={prev}>戻る</p>  
                            :<></>}
                            <form>
                              <h5 className="modal_ttl">アピールしたい経験職種を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {currentSlide == 0 &&
                                            <label className="column3">
                                              <input 
                                                type="radio" 
                                                name="categiries"                                                 
                                                onClick={() => {
                                                  jobCategory2Toggle(); }} /> 
                                              <span>なし</span>
                                            </label>
                                          } 
                                          {currentSlide == 0 &&
                                            job_category_types
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange11}
                                                    onClick={() => {setCurrentSlide(1);}}
                                                    checked={selectedSkills11 == data.id} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {currentSlide == 1 &&
                                            job_categories
                                              .filter(data => data.job_category_type_id == kills8 && selectedSkills10 != data.id && selectedSkills12 != data.id && selectedSkills14 != data.id)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange12}
                                                    onClick={() => {setCurrentSlide(0);}}
                                                    checked={selectedSkills12 == data.id} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={() => {toggleDialog8(); setCurrentSlide(0);}}>close</span></p>
                          </div>
                          <div className="overlay" onClick={() => {toggleDialog8(); setCurrentSlide(0);}}></div>
                        </div>
                      )}
                      {dialogOpen9 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>経験職種3</h4>
                            { currentSlide > 0 ?
                              <p id="btn_prev" onClick={prev}>戻る</p>  
                            :<></>}
                            <form>
                              <h5 className="modal_ttl">アピールしたい経験職種を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {currentSlide == 0 &&
                                            <label className="column3">
                                              <input type="radio" name="categiries" tabIndex={0} onClick={jobCategory3Toggle}/> <span>なし</span></label>
                                          }
                                          {currentSlide == 0 &&
                                            job_category_types
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange13}
                                                    onClick={() => {setCurrentSlide(1);}}
                                                    checked={selectedSkills13 == data.id} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {currentSlide == 1 &&
                                            job_categories
                                              .filter(data => data.job_category_type_id == kills9 && selectedSkills10 != data.id && selectedSkills12 != data.id && selectedSkills14 != data.id)
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange14}
                                                    onClick={() => {setCurrentSlide(0);}}
                                                    checked={selectedSkills14 == data.id} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={() => {toggleDialog9(); setCurrentSlide(0);}}>close</span></p>
                          </div>
                          <div className="overlay" onClick={() => {toggleDialog9(); setCurrentSlide(0);}}></div>
                        </div>
                      )}
                      {/* Language 1 modal */}
                      {dialogOpen10 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>語学</h4>                                  
                            { currentSlide > 0 ?
                              <p id="btn_prev" onClick={prev}>戻る</p>  
                            :<></>}
                            <form>
                              {currentSlide == 0 ? 
                                <h5 className="modal_ttl">語学を選択してください</h5>
                              :<></>}
                              {currentSlide == 1 ? 
                                <h5 className="modal_ttl">習得レベルを選択してください</h5>
                              :<></>}
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{  transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {currentSlide == 0 &&
                                            <label className="column3">
                                              <input type="radio" name="categiries" onClick={language1Toggle} tabIndex={0} /> 
                                              <span>なし</span>
                                            </label>
                                          }
                                          {currentSlide == 0 &&
                                            languages
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange15}
                                                    onClick={() => {setCurrentSlide(1);}} 
                                                    checked={selectedSkills15 == data.id} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {currentSlide == 1 &&
                                            filterLevels()
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange16}
                                                    onClick={() => {toggleDialog10(); setCurrentSlide(0);}} 
                                                    checked={selectedSkills16 == data.id} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={() => {toggleDialog10(); setCurrentSlide(0);}}>close</span></p>
                          </div>
                          <div className="overlay" onClick={() => {toggleDialog10(); setCurrentSlide(0);}}></div>
                        </div>
                      )}
                      {/* Language 2 modal */}
                       {dialogOpen11 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>語学</h4> 
                            { currentSlide > 0 ?
                              <p id="btn_prev" onClick={prev}>戻る</p>  
                            :<></>}
                            <form>                                    
                              {currentSlide == 0 ? 
                                <h5 className="modal_ttl">語学を選択してください</h5>
                              :<></>}
                              {currentSlide == 1 ? 
                                <h5 className="modal_ttl">習得レベルを選択してください</h5>
                              :<></>}
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                     <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {currentSlide == 0 &&
                                            <label className="column3"><input type="radio" name="categiries" onClick={language2Toggle} tabIndex={0} /> <span>なし</span></label>}
                                          {currentSlide == 0 &&
                                            languages
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onClick={() => {setCurrentSlide(1);}} 
                                                    onChange={handleCheckboxChange17}
                                                    checked={selectedSkills17 == data.id} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                          {currentSlide == 1 &&
                                            filterLevels()
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onClick={() => {setCurrentSlide(0);}} 
                                                    onChange={handleCheckboxChange18}
                                                    checked={selectedSkills18 == data.id} />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={() => {toggleDialog11(); setCurrentSlide(0);}}>close</span></p>
                          </div>
                          <div className="overlay" onClick={() => {toggleDialog11(); setCurrentSlide(0);}}></div>
                        </div>
                      )}
                      {dialogOpen12 && (
                        <div role="dialog" className="modal_box">
                          <div className="modal_inner">
                            <h4>年収</h4>
                            <form>
                              <h5 className="modal_ttl">希望年収を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current" style={{ outline: "none", width: "100%" }}>
                                      <div>
                                        <div className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          <label>
                                            <input type="radio" name="handicap_type" value="1"></input>
                                            <span>なし</span>
                                          </label>
                                          {annual_income
                                            .map((data: any, index: any) => (
                                              <label className="column3" key={index}>
                                                <input
                                                  type="radio"
                                                  name=""
                                                  value={data.id}
                                                  onChange={handleCheckboxChange26}
                                                  checked={selectedSkills26.includes(data.id.toString())} />
                                                <span>{data.name}</span>
                                              </label>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog12}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog12}></div>
                        </div>
                      )}
                      {dialogOpen13 && (
                        <div role="dialog" className="modal_box">
                          <div className="modal_inner">
                            <h4>雇用形態</h4>
                            <form>
                              <h5 className="modal_ttl">希望する雇用形態を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current" style={{ outline: "none", width: "100%" }}>
                                      <div>
                                        <div className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          <label>
                                            <input type="radio" name="handicap_type" value="1"></input>
                                            <span>なし</span>
                                          </label>
                                          {employment_statuses
                                            .map((data: any, index: any) => (
                                              <label className="column3" key={index}>
                                                <input
                                                  type="radio"
                                                  name=""
                                                  value={data.id}
                                                  onChange={handleCheckboxChange27}
                                                  checked={selectedSkills27.includes(data.id.toString())} />
                                                <span>{data.name}</span>
                                              </label>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog13}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog13}></div>
                        </div>
                      )}
                      {dialogOpen14 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>利用期間の開始月</h4>
                            { currentSlide > 0 ?
                              <p id="btn_prev" onClick={prev}>戻る</p>  
                            :<></>}
                            <form>
                              <h5 className="modal_ttl">年を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {currentSlide == 0 &&
                                            year
                                              .map((startYearModal14: any) => (
                                                <label className="column3" key={startYearModal14}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={startYearModal14}
                                                    onChange={handleCheckboxChange28}
                                                    onClick={() => {setCurrentSlide(1);}} 
                                                    checked={selectedSkills28.indexOf(startYearModal14.toString()) > -1} />
                                                  <span>{startYearModal14} 年</span>
                                                </label>
                                              ))}
                                          {currentSlide == 1 &&
                                            months
                                              .map((start_month: any) => (
                                                <label className="column3" key={start_month}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={start_month}
                                                    onChange={handleCheckboxChange29}
                                                    onClick={() => {setCurrentSlide(0); 
                                                      filteredEndYears();}} 
                                                    checked={selectedSkills29.includes(start_month.toString())} />
                                                  <span>{start_month} 月</span>
                                                </label>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={() => {toggleDialog14(); setCurrentSlide(0);}}>close</span></p>
                          </div>
                          <div className="overlay" onClick={() => {toggleDialog14(); setCurrentSlide(0);}}></div>
                        </div>
                      )}
                      {dialogOpen15 && (
                        <div role="dialog" tabIndex={0} className="modal_box">
                          <div className="modal_inner">
                            <h4>利用期間の終了月</h4>
                            { currentSlide > 0 ?
                              <p id="btn_prev" onClick={prev}>戻る</p>  
                            :<></>}
                            <form>
                              <h5 className="modal_ttl">年を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%" }}>
                                    <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                      <div>
                                        <div tabIndex={-1} className="slider_item" style={{ width: "100%", display: "inline-block" }}>
                                          {currentSlide == 0 && (
                                            <label className="column3">
                                              <input
                                                type="radio"
                                                name=""
                                                onClick={() => { 
                                                  setCurrentSlide(0);
                                                  toggleDialog15();
                                                  handleInputEndDate();
                                                 }} 
                                                defaultChecked={end_date?.includes('終了月なし（継続中）')} 
                                                />
                                              <span>終了月なし（継続中）</span>
                                            </label>
                                            )}                                          
                                          {currentSlide == 0 &&
                                            endYear && endYear
                                              .map((endYearModal15: any) => (
                                                <label className="column3" key={endYearModal15}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={endYearModal15}
                                                    onChange={handleCheckboxChange30}                                                    
                                                    onClick={() => {setCurrentSlide(1);}} 
                                                    checked={selectedSkills30 == endYearModal15} />
                                                  <span>{endYearModal15} 年</span>
                                                </label>
                                              ))}
                                          {currentSlide == 1 && !selectedSkills30.includes('終了月なし') && (
                                            <div>
                                              {end_month.map((month: any) => (
                                                <label className="column3" key={month}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={month}
                                                    onChange={handleCheckboxChange31}                                                    
                                                    onClick={() => {setCurrentSlide(0);}} 
                                                    checked={selectedSkills31 == month} />
                                                  <span>{month} 月</span>
                                                </label>
                                              ))}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div tabIndex={-1} data-index="1" aria-hidden="true" className="slick-slide style5">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={() => {toggleDialog15(); setCurrentSlide(0);}}>close</span></p>
                          </div>
                          <div className="overlay" onClick={() => {toggleDialog15(); setCurrentSlide(0);}}></div>
                        </div>
                      )}
                      {dialogOpen16 && (
                      <div role="dialog"  className="modal_box selectRadio1" >
                        <div className="modal_inner">
                          <h4>最終学歴</h4>
                          <form>
                            <h5 className="modal_ttl">最終学歴を選択してください</h5>
                            <div dir="ltr" className="slider slick-slider slick-initialized">
                              <div className="slick-list">
                                <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%"}}>
                                  <div data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current" style={{outline: "none", width: "100%"}}>
                                    <div>
                                      <div  className="slider_item" style={{width: "100%", display: "inline-block"}}>
                                        {
                                          final_educations
                                          .map((data: any, index: any) => (
                                            <label className="column3" key={index}>
                                              <input
                                                type="radio"
                                                name=""
                                                value={data.id}
                                                onChange={handleCheckboxChange32}
                                                checked={selectedSkills32.includes(data.id.toString())}
                                              />
                                              <span>{data.name}</span>
                                            </label>
                                          ))
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                          <p className="close"><span className="material-icons" onClick={toggleDialog16}>close</span></p>
                        </div>
                        <div className="overlay" onClick={toggleDialog16}></div>
                      </div>
                      )}
                      {dialogOpen17 &&  (
                        <div role="dialog" tabIndex={0} className="modal_box">
                        <div className="modal_inner">
                          <h4>直近の職歴</h4>
                          { currentSlide > 0 ?
                            <p id="btn_prev" onClick={prev}>戻る</p>  
                          :<></>}
                          <form>
                            { currentSlide == 0 &&
                              <h5 className="modal_ttl">会社名を入力してください</h5>
                            }
                            { currentSlide == 1 &&
                              <h5 className="modal_ttl">就業を開始した年を選択してください</h5>
                            }
                            { currentSlide == 2 &&
                              <h5 className="modal_ttl">就業を開始した月を選択してください</h5>
                            }
                            { currentSlide == 3 &&
                              <h5 className="modal_ttl">就業を終了した年を選択してください</h5>
                            }
                            { currentSlide == 4 &&
                              <h5 className="modal_ttl">就業を終了した月を選択してください</h5>
                            }
                            { currentSlide == 5 &&
                              <h5 className="modal_ttl">業界を選択してください</h5>
                            }
                            { currentSlide == 6 &&
                              <h5 className="modal_ttl">職種のカテゴリを選択してください</h5>
                            }
                            { currentSlide == 7 &&
                              <h5 className="modal_ttl">職種を選択してください</h5>
                            }
                            <div dir="ltr" className="slider slick-slider slick-initialized">
                              <div className="slick-list">
                                <div className="slick-track" style={{opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%"}}>
                                  <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                    <div>
                                      <div tabIndex={-1} className="slider_item" style={{width: "100%", display: "inline-block"}}>
                                        {currentSlide == 0 && (
                                        <><label className="input_text">
                                              <input type="text" tabIndex={0} value={lastcomp} onChange={handleNameChange2} />
                                            </label><a id="btn_next" tabIndex={0} onClick={() =>{handleNextClick2(); setCurrentSlide(1); filteredYears();}}> 次へ</a></>                           
                                        )}   
                                        {currentSlide == 1 &&
                                            year && year
                                              .map((year1: any) => (
                                                <label className="column3">
                                                  <input
                                                    type="radio"
                                                    name="year_1"
                                                    value={year1}
                                                    onClick={() => { setYear1(year1.toString()); setCurrentSlide(2);}} 
                                                    defaultChecked={Number(year_1) == Number(year1) ? true : false}
                                                  />
                                                  <span>{year1} 年</span>
                                                </label>
                                              ))
                                        }
                                        {currentSlide == 2 &&
                                            month && month
                                              .map((month1: any) => (
                                                <label className="column3" key={'arrival_month'+month1}>
                                                  <input
                                                    type="radio"
                                                    name="month_1"
                                                    value={month1}
                                                    onClick={() => { setMonth1(month1.toString()); setCurrentSlide(3);filteredEndYears();}} 
                                                    defaultChecked={Number(month_1) == month1 ? true : false}
                                                  />
                                                  <span>{month1} 月</span>
                                                </label>
                                              ))
                                        }
                                        {currentSlide == 3 && (
                                          <label className="column3">
                                            <input type="radio" name=""                                             
                                              // onChange={handleYearTwo}
                                              onClick={() => { setYear2('9999'); setMonth2('12'); setCurrentSlide(5);}} 
                                              defaultChecked={year_2 == '在職中'} />
                                            <span>在職中</span>
                                          </label>
                                        )}
                                        {currentSlide == 3 &&
                                            endYear && endYear
                                              .map((year2: any) => (
                                                <label className="column3" key={'@departure_'+year2}>
                                                  <input
                                                    type="radio"
                                                    name="year_2"
                                                    value={year2}
                                                    onClick={() => { setYear2(year2.toString()); setCurrentSlide(4)}} 
                                                    defaultChecked={Number(year_2) == year2 ? true : false}
                                                  />
                                                  <span>{year2} 年</span>
                                                </label>
                                              ))
                                        }
                                        {currentSlide == 4 &&
                                            months2 && months2
                                              .map((month2: any) => (
                                                <label className="column3" key={'departure_'+month2}>
                                                  <input
                                                    type="radio"
                                                    name="month_2"
                                                    value={month2}
                                                    onClick={() => { setMonth2(month2.toString()); setCurrentSlide(5)}} 
                                                    defaultChecked={Number(month_2) == month2 ? true : false}
                                                  />
                                                  <span>{month2} 月</span>
                                                </label>
                                              ))
                                        }
                                        {currentSlide == 5 &&
                                            industries
                                              .map((data: any, index: any) => (
                                                <label className="column3">
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange38}
                                                    onClick={() => {handleInputDate(); setCurrentSlide(6)}}
                                                    checked={selectedSkills38.includes(data.id.toString())}
                                                  />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))
                                        }
                                        {currentSlide == 6 &&
                                            job_category_types
                                              .map((data: any, index: any) => (
                                                <label className="column3">
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange39}
                                                    onClick={() => { setCurrentSlide(7)}}
                                                    checked={selectedSkills39.includes(data.id.toString())}
                                                  />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))
                                        }
                                        {currentSlide == 7 &&
                                            job_categories
                                              .filter(data => data.job_category_type_id == kills11) 
                                              .map((data: any, index: any) => (
                                                <label className="column3">
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    onChange={handleCheckboxChange40}
                                                    onClick={() => { setCurrentSlide(0);}}
                                                    checked={selectedSkills40.includes(data.id.toString())}
                                                  />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                          <p tabIndex={0} className="close" onClick={toggleDialog17}><span className="material-icons">close</span></p>
                        </div>
                        <div className="overlay" onClick={toggleDialog17}></div>
                      </div>
                      )}
                      {dialogOpen18 &&  (
                        <div role="dialog" tabIndex={0} className="modal_box">
                        <div className="modal_inner">
                          <h4>障がいについて</h4>
                          { currentSlide > 0 ?
                            <p id="btn_prev" onClick={prev}>戻る</p>  
                          :<></>}
                          <form>
                            {/* <h5 className="modal_ttl">該当する障がい区分を選択してください</h5> */}
                            <h5 className="modal_ttl">{ dataSetNames[currentSlide] }を選択してください<span className="required">必須</span></h5>
                            <div dir="ltr" className="slider slick-slider slick-initialized">
                              <div className="slick-list">
                                <div className="slick-track" style={{opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%"}}>
                                  <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                    <div>
                                      <div tabIndex={-1} className="slider_item" style={{width: "100%", display: "inline-block"}}>
                                        {currentSlide == 0 &&
                                            handicap_types
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name="handicapTypeId"
                                                    value={data.id}
                                                    // onChange={handleCheckboxChange41}
                                                    onClick={() => {setHandicapTypeId(data.id); setCurrentSlide(1); }}
                                                    defaultChecked={data.id == handicapTypeId}
                                                  />
                                                  <span>{data.name} </span>
                                                </label>
                                              ))
                                        }

                                        {currentSlide == 1 &&
                                            handicaps
                                            .filter(data => data.handicap_type_id == handicapTypeId) 
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name="handicapId"
                                                    value={data.id}
                                                    // onChange={handleCheckboxChange42}
                                                    onClick={() => {setHandicapId(data.id); setCurrentSlide(2);}}
                                                    defaultChecked={data.id == handicap_id}
                                                  />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))
                                        }
                                        {currentSlide == 2 &&
                                            disability_certificate_statuses
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name=""
                                                    value={data.id}
                                                    // onChange={handleCheckboxChange43}
                                                    onClick={() => { handleCheckboxChange43(data.id)}}
                                                    defaultChecked={disability_certificate_status_id == data.id}
                                                  />
                                                  <span>{data.name}</span>
                                                </label> 
                                            ))
                                        }
                                        {currentSlide == 3 &&
                                            disability_certificates
                                            .filter(data => data.handicap_type_id == handicapTypeId) 
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name="disability_certificate"
                                                    value={data.id}
                                                    onClick={() => { setCurrentSlide(0); handleCheckboxChange44(data.id, 0); toggleDialog18();}}
                                                    checked={selectedSkills44 == data.id}
                                                  />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                          <p tabIndex={0} className="close" onClick={() => {toggleDialog18(); setCurrentSlide(0)}}><span className="material-icons">close</span></p>
                        </div>
                        <div className="overlay" onClick={() => {toggleDialog18(); setCurrentSlide(0)}}></div>
                        </div>
                        )}
                        {dialogOpen19 && (
                        <div role="dialog" className="modal_box">
                          <div className="modal_inner">
                            <h4>卒業・卒業見込み年度</h4>
                            { currentSlide > 0 ?
                              <p id="btn_prev" onClick={prev}>戻る</p>  
                            :<></>}
                            <form>
                              <h5 className="modal_ttl">年を選択してください</h5>
                              <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                  <div className="slick-track" style={{ opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%"}}>
                                    <div data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current" style={{outline: "none", width: "100%"}}>
                                      <div>
                                        <div  className="slider_item" style={{width: "100%", display: "inline-block"}}>
                                        { currentSlide == 0 &&
                                              yearRange
                                                .map((year: any) => (
                                                  <label className="column3" key={year}>
                                                    <input
                                                      type="radio"
                                                      name="year"
                                                      value={year}
                                                      onChange={handleCheckboxChange47}
                                                      onClick={() => { setCurrentSlide(1)}}
                                                      checked={graduation_date.substring(0,4) == year ? true : false}
                                                    />
                                                    <span>{year} 年</span>
                                                  </label>
                                                ))
                                          }
                                          {currentSlide == 1 &&
                                              months
                                                .map((m) => (
                                                  <label className="column3" key={m}>
                                                    <input
                                                      type="radio"
                                                      name="month"
                                                      value={m}
                                                      onChange={handleCheckboxChange48}
                                                      onClick={() => { setCurrentSlide(0)}}
                                                      checked={graduation_date.substring(5,6) == m.toString() ? true : false}
                                                    />
                                                    <span>{m} 月</span>
                                                  </label>
                                                ))
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="close"><span className="material-icons" onClick={toggleDialog19}>close</span></p>
                          </div>
                          <div className="overlay" onClick={toggleDialog19}></div>
                          </div>
                      )}
                      {isModalOpen &&  (
                        <div                   
                          role="dialog"
                          className="modal_box selectRadio1"
                        >
                          <div className="modal_inner">
                            <h4>企業に伝えたい項目1</h4>
                            <form>
                              <h5 className="modal_ttl">
                                Web履歴書項目の中で企業担当者にアピールしたい項目を選択してください
                              </h5>
                              <div className="slider_item">
                                <label className="column3">
                                  <input
                                    type="radio"
                                    name=""
                                    value="0"
                                    onChange={(e) => handleRadioChange(e)}
                                    // checked={selectedValue.includes(data.id.toString())}
                                  />
                                  <span>なし</span>
                                </label>  
                                {appealsId.length <= 3 &&
                                  appeals
                                    .filter((data: any) => data.id != appeal_item_1 && data.id != appeal_item_2 && data.id != appeal_item_3 && !appealsId.includes(data.id.toString()))
                                      .map((data: any, index: any) => (
                                        <label className="column3" key={index}>
                                          <input
                                            type="radio"
                                            name=""
                                            value={data.id}
                                            onChange={(e) => handleRadioChange(e)}
                                            // checked={selectedValue.includes(data.id.toString())}
                                          />
                                          <span>{data.name}</span>
                                        </label>
                                    ))
                                }
                              </div>
                            </form>
                            <p className="close">
                              <span className="material-icons" onClick={handleCloseModal}>
                                close
                              </span>
                            </p>
                          </div>
                          <div className="overlay" onClick={handleCloseModal}></div>
                        </div>
                      )}
                      {isModalOpen3 &&  (
                      <div role="dialog" tabIndex={0} className="modal_box">
                        <div className="modal_inner">
                          <h4>アピールしたい職歴</h4>
                          { currentSlide > 0 ?
                            <p id="btn_prev" onClick={prev}>戻る</p>  
                          :<></>}
                          <form>
                            {currentSlide == 0 ?
                              <h5 className="modal_ttl">会社名を入力してください</h5>
                            :<></>}
                            {currentSlide == 1 ?
                              <h5 className="modal_ttl">就業を開始した年を選択してください</h5>
                            :<></>}
                            {currentSlide == 2 ?
                              <h5 className="modal_ttl">就業を開始した月を選択してください</h5>
                            :<></>}
                            {currentSlide == 3 ?
                              <h5 className="modal_ttl">就業を終了した年を選択してください</h5>
                            :<></>}
                            {currentSlide == 4 ?
                              <h5 className="modal_ttl">就業を終了した月を選択してください</h5>
                            :<></>}
                            {currentSlide == 5 ?
                              <h5 className="modal_ttl">業界を選択してください</h5>
                            :<></>}
                            {currentSlide == 6 ?
                              <h5 className="modal_ttl">職種のカテゴリを選択してください</h5>
                            :<></>}
                            {currentSlide == 7 ?
                              <h5 className="modal_ttl">職種を選択してください</h5>
                            :<></>}
                            <div dir="ltr" className="slider slick-slider slick-initialized">
                              <div className="slick-list">
                                <div className="slick-track" style={{opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%"}}>
                                  <div tabIndex={-1} data-index={0} aria-hidden="false" className="slick-slide slick-active slick-current style5">
                                    {/* <> */}
                                      <div tabIndex={-1} className="slider_item" style={{width: "100%", display: "inline-block"}}>
                                        {currentSlide == 0 && (
                                            <>
                                              <label className="input_text">
                                                <input type="text" tabIndex={0} value={cname} onChange={handleNameChange} />
                                              </label>
                                              <a id="btn_next" tabIndex={0} 
                                                onClick={() => {
                                                  handleNextClick(); 
                                                  setCurrentSlide(1); 
                                                  filteredYears();
                                                }}> 次へ
                                              </a>
                                            </>                           
                                        )}   
                                        {currentSlide == 1 && 
                                            year && year
                                              .map((y: any) => (
                                                <label className="column3" key={'startY-' + y}>
                                                  <input
                                                    type="radio"
                                                    name="year1"
                                                    value={y}
                                                    onClick={() => { 
                                                      setJobCareerAppealsArrivalYear(y.toString()); 
                                                      setCurrentSlide(2);
                                                    }}                                     
                                                    defaultChecked={jobCareerAppealsArrivalYear == y.toString()}
                                                  />
                                                  <span>{y} 年</span>
                                                </label>
                                              ))
                                        }
                                        {currentSlide == 2 && (
                                            month && month
                                              .map((month: any) => (
                                                <label className="column3" key={month}>
                                                  <input
                                                    type="radio"
                                                    name="month1"
                                                    value={month}
                                                    onClick={() => {
                                                      setJobCareerAppealsArrivalMonth(month.toString()); 
                                                      setCurrentSlide(3);
                                                      filteredEndYears();
                                                    }}
                                                    defaultChecked={jobCareerAppealsArrivalMonth == month.toString()}
                                                  />
                                                  <span>{month} 月</span>
                                                </label>
                                              ))
                                        )}
                                        {currentSlide == 3 && (
                                          <label className="column3">
                                            <input type="radio" 
                                              name="year2"  
                                              onClick={() => {
                                                setJobCareerAppealsDepartureYear('9999'); 
                                                setJobCareerAppealsDepartureMonth('12');  
                                                setCurrentSlide(5);
                                              }}
                                                defaultChecked={jobCareerAppealsDepartureYear == '在職中'} />
                                            <span>在職中</span>
                                          </label>
                                        )}
                                        {currentSlide == 3 && (
                                            endYear && endYear
                                              .map((year2: any) => (
                                                <label className="column3" key={'@endYear_'+year2}>
                                                  <input
                                                    type="radio"
                                                    name="year2"
                                                    value={year2}
                                                    onClick={() => { 
                                                      setJobCareerAppealsDepartureYear(year2.toString()); 
                                                      setCurrentSlide(4);
                                                    }} 
                                                    defaultChecked={Number(jobCareerAppealsDepartureYear) == year2}
                                                  />
                                                  <span>{year2} 年</span>
                                                </label>
                                              ))
                                        )}
                                      
                                        {currentSlide == 4 && (
                                            months2 && months2
                                              .map((month) => (
                                                <label className="column3" key={month}>
                                                  <input
                                                    type="radio"
                                                    name="month2"
                                                    value={month}
                                                    onClick={() => {
                                                      setJobCareerAppealsDepartureMonth(month.toString()); 
                                                      setCurrentSlide(5);
                                                    }}
                                                    defaultChecked={Number(jobCareerAppealsDepartureMonth) == month}
                                                  />
                                                  <span>{month} 月</span>
                                                </label>
                                              ))
                                        )}
                                        {currentSlide == 5 &&
                                            industries
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name="industry"
                                                    value={data.id}
                                                    onChange={handleCheckboxChange22}
                                                    onClick={() => {setCurrentSlide(6)}}
                                                    checked={selectedSkills22 == data.id}
                                                  />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))
                                        }
                                        {currentSlide == 6 &&
                                            job_category_types
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name="job_categiry_type"
                                                    value={data.id}
                                                    onClick={() => {setCurrentSlide(7)}}
                                                    onChange={handleCheckboxChange23}
                                                    checked={selectedSkills23.includes(data.id.toString())}
                                                  />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))
                                        }
                                        {currentSlide == 7 &&
                                            job_categories
                                              .filter(data => data.job_category_type_id == kills10) 
                                              .map((data: any, index: any) => (
                                                <label className="column3" key={index}>
                                                  <input
                                                    type="radio"
                                                    name="job_categiry"
                                                    value={data.id}
                                                    onChange={handleCheckboxChange24}
                                                    checked={selectedSkills24.includes(data.id.toString())}                     
                                                  />
                                                  <span>{data.name}</span>
                                                </label>
                                              ))
                                        }
                                      </div>
                                    {/* </> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                          <p tabIndex={0} className="close" onClick={handleCloseModal3}><span className="material-icons">close</span></p>
                        </div>
                      <div className="overlay" onClick={handleCloseModal3}></div>
                      </div>
                      )}
      </main>
    );
  }
  
  export default RecruimentsEntry;
