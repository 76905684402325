import { applicantDecideTypes } from "../../ActionTypes/applicantDecideTypes";
import { ApplicantDecideActions, ApplicantDecideState } from "../../types/type";


const initialState: ApplicantDecideState = {
  pending: false,
  decide: [],
  error: null,
};

export default (state = initialState, action: ApplicantDecideActions,) => {
  switch (action.type) {
    case applicantDecideTypes.FETCH_APPLICANT_DECIDE_REQUEST:
      return {
        ...state,
        pending: false
      };
    case applicantDecideTypes.FETCH_APPLICANT_DECIDE_SUCCESS:
      return {
        ...state,
        pending: true,
        decide: action.payload.decide,
        error: null
      };
    case applicantDecideTypes.FETCH_APPLICANT_DECIDE_FAILURE:
      return {
        ...state,
        pending: false,
        applicant: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
