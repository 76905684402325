import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminApplicantsListTypes } from "../../ActionTypes/adminApplicantsListTypes";
import { fetchAdminApplicantsListFailure, fetchAdminApplicantsListSuccess } from "../../actions/adminAplicantsListActions/adminAplicantsListActions";

const getAdminApplicantsList = (data: any) => { 
  const config = {
    headers: { 
      Prefer: 'example=example-1', Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/applicants?page=' + data.page + '&keyword=' + data.keyword + '&deleted=' + data.deleted　+ '&per=' + 50, config); 
}


function* fetchAdminApplicantsListSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminApplicantsList, action.data);
    yield put(
      fetchAdminApplicantsListSuccess({
        applicants: response.data.applicants,
        pagenation: response.data.pagenation,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminApplicantsListFailure({
        error: e.message
      })
    );
  }
}

function* AdminApplicantsListSaga() {
  yield takeLatest(adminApplicantsListTypes.FETCH_ADMIN_APPLICANTS_LIST_REQUEST, fetchAdminApplicantsListSaga);
}
export default AdminApplicantsListSaga;