import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { scoutsCandidateTypes } from "../../ActionTypes/scoutsCandidateTypes";
import { fetchScoutsCandidateFailure, fetchScoutsCandidateSuccess } from "../../actions/scoutsCandidateActions/scoutsCandidateActions";

var getScoutsCandidate = (id: any, name:any, data: any) => {
  if(name == 'get') {
    return axios.get(API_BASE_ADDRESS + '/scout_candidates?' + data, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 
  } else {
    return axios.post(API_BASE_ADDRESS + '/scout_candidates/', {applicant_ids: data}, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 
  }
}

function* fetchScoutsCandidateSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getScoutsCandidate, action.id, action.name, action.data);
    yield put(
      fetchScoutsCandidateSuccess({
        candidates: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchScoutsCandidateFailure({
        error: e.message
      })
    );
  }
}

function* ScoutsCandidateSaga() {
  yield takeLatest(scoutsCandidateTypes.FETCH_SCOUTS_CANDIDATE_REQUEST, fetchScoutsCandidateSaga);
}

export default ScoutsCandidateSaga;
