import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { scoutAlertTypes } from "../../ActionTypes/scoutsAlertTypes";
import { fetchScoutAlertFailure, fetchScoutAlertSuccess } from "../../actions/scoutsAlertActions/scoutsAlertActions";

var getScoutAlert = (id: any) => {
  return axios.get(API_BASE_ADDRESS + '/scouts/alert?company_id=' + id, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 
}

function* fetchScoutAlertSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getScoutAlert, action.id);
    yield put(
      fetchScoutAlertSuccess({
        alert: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchScoutAlertFailure({
        error: e.message
      })
    );
  }
}

function* ScoutAlertSaga() {
  yield takeLatest(scoutAlertTypes.FETCH_SCOUT_ALERT_REQUEST, fetchScoutAlertSaga);
}

export default ScoutAlertSaga;
