import { savedConditionsTypes } from "../../ActionTypes/savedConditionsTypes";
import { SavedConditionsActions, SavedConditionsState } from "../../types/type";



const initialState: SavedConditionsState = {
  pending: false,
  savedConditions: [],
  error: null,
};

export default (state = initialState, action: SavedConditionsActions) => {
  switch (action.type) {
    case savedConditionsTypes.FETCH_SAVED_CONDITIONS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case savedConditionsTypes.FETCH_SAVED_CONDITIONS_SUCCESS:
      return {
        ...state,
        pending: true,
        savedConditions: action.payload.savedConditions,
        error: null
      };
    case savedConditionsTypes.FETCH_SAVED_CONDITIONS_FAILURE:
      return {
        ...state,
        pending: false,
        savedConditions: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
