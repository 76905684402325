import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../redax/reducers/rootReducer";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import { fetchRegionsRequest } from "../../../../redax/actions/regionsActions/regionsActions";
import { fetchApplicantsRequest } from "../../../../redax/actions/applicantsActions/applicantsActions";
import { fetchScoutsCandidateRequest } from "../../../../redax/actions/scoutsCandidateActions/scoutsCandidateActions";
import Loader from "../../../loader/loader";
import './applicant.scss';
import { fetchRecruiterPlanRequest } from '../../../../redax/actions/recruiterPlanActions/recruiterPlanActions';

function ScoutsApplicant() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [resourceId, setResourceId] = useState(null);
    const loginData = useSelector((state: RootState) => state.login);
    const [familyName, setFamylyName] = useState(null);
    const { regions  } = useSelector( (state: RootState) => state.regions);
    const applicants = useSelector((state: RootState) => state.applicants);
    const candidates = useSelector((state: RootState) => state.candidates);
    const recruiterPlan = useSelector((state: RootState) => state.recruiterPlan);
    const [loadScreen, setLoadScreen] = useState(true);
    const [applicant, setApplicant] = useState<any>(null);
    const [profile, setProfile] = useState<any>(null);
    const [support_agency_exists, setSupport_agency_exists] = useState('');
    const [consideration_conditions_required_exists, setConsideration_conditions_required_exists] = useState('');
    const [employmentSupportAgency, setEmploymentSupportAgency] = useState<any>(null);
    const [limitCount, setLimitCount] = useState(0);
    const {id} = useParams();
    const [currentPlanId, setCurrentPlanId] = useState<any>(null);
    const [isMark, setIsMark] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
        //   dispatch(fetchLoginRequest('', '',''))
        } else (
            navigate('/')
        )
      }, [dispatch]);


    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/recruiter/init/');
            }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            setLimitCount(loginData.login.scout_today_limit);
            dispatch(fetchRegionsRequest());                     
            dispatch(fetchApplicantsRequest(id, 'company',''));  
        }
    }, [loginData.pending]);

    useEffect(() => {
        if (regions !== undefined) {         
            dispatch(fetchRecruiterPlanRequest());
        }        
        if (recruiterPlan.pending) {
            if(recruiterPlan.recruiterPlan.current_plan_id == 1) {
                setIsMark(true);
            } else {
                setIsMark(false);
            }
            setCurrentPlanId(recruiterPlan.recruiterPlan.current_plan_id);   
        }
    }, [regions]);

    useEffect(() => {
        if (applicants.pending) {
            setLoadScreen(false);
            setApplicant(applicants.applicants.applicant);
            setProfile(applicants.applicants.applicant_profile);
            if(applicants.applicants.applicant_profile.consideration_conditions_required_exists != null) {
                if(applicants.applicants.applicant_profile.consideration_conditions_required_exists.toString().includes('有') || applicants.applicants.applicant_profile.consideration_conditions_required_exists.toString().includes('無')){
                    setConsideration_conditions_required_exists(applicants.applicants.applicant_profile.consideration_conditions_required_exists);
                } else if(applicants.applicants.applicant_profile.consideration_conditions_required_exists == true) {
                    setConsideration_conditions_required_exists('有');
                } else {
                    setConsideration_conditions_required_exists('無');
                } 
            }
            let ESA = applicants.applicants.applicant_profile.employment_support_agency;
            let SAE = ESA.support_agency_exists;
            if(applicants.applicants.applicant_profile.employment_support_agency != null) {
                if(SAE.toString().includes('有') || SAE.toString().includes('無')){
                    setSupport_agency_exists(SAE);
                } else if(SAE == true) {
                    setSupport_agency_exists('有');
                } else {
                    setSupport_agency_exists('無');
                } 
                setEmploymentSupportAgency(applicants.applicants.applicant_profile.employment_support_agency);
            }
        } else if(applicants.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [applicants, applicant, profile]);
    function logout() {
        if (localStorage.entryFlag) {
            localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
            localStorage.removeItem('jobParachannelAuthToken')
            navigate('/');
            window.location.reload();
        }
    }

    function isCandidatePage() {
        return window.location.pathname.indexOf('candidate') != -1
    }

    /**
     * 求職者詳細ページの時true、そうでない時false
     **/
    function checkApplicantDetailsPage() {
        return window.location.pathname.indexOf('scouts/applicant') != -1
    }

    function replacedContent(value: any) {
        if (value == null) {
          return null
        }
        return value.replaceAll("\n", '<br>');
    }

    function saveCandidates(selectedApplicantIds: any) {
        // スカウト候補に追加処理が求職者一覧と詳細で異なる
        // 一覧: 追加後画面遷移なし、ボタンを押せなくする
        // 詳細: 追加後画面遷移
        // 一覧でボタンを押せなくするため求職者一覧オブジェクトをstoreで持ちたい
        // store/scouts/index.jsで持って、URLを元に同モジュールのスカウト候補に追加処理で分岐させても良いが
        // 求職者一覧ページでしか使わない処理や求職者一覧オブジェクトをそこに書くのは混乱を招きそうなので
        // ページ単位でstoreのファイルを分けて、そこから共通の処理であるスカウト候補に追加処理が書かれてるscouts/index.jsのsaveCandidates()を呼ぶことにした
        if (checkApplicantDetailsPage()) {
            // 求職者詳細ページの時
            dispatch(fetchScoutsCandidateRequest('', 'post', [applicant.id]));
            setTimeout( () => navigate('/recruiter/scouts/candidate'), 1500);
        }
    }

    function loadEntry(app: any) {
        if (1 > limitCount) {
            window.alert('本日のスカウト可能残数を超えています')
            return
        }
        navigate('/recruiter/scouts/entry/', {state: {applicants: [app], id: app.id}});
    }

    const formatYM = (dateString : any) => {
        if (dateString === '9999-12') {
            return `現在`;
        } else if (dateString === null) {
            return ''
        } else if(dateString.includes('-')) {
            const [year, month] = dateString.split('-').map((component: any) => parseInt(component));
            return `${year}年${month}月`;
        } else {
            return dateString;
        }
    };

    return (
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className ="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">スカウト</h2>
                <ul>
                <li><a href="" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            <div id="scouts" className="applicant">
                {applicant ?
                <><div className={applicant.active ? 'item_box online' : 'item_box'}>
                    <div>
                    <div>
                        {!checkApplicantDetailsPage() ?
                        (<div className="tag_box">
                            <a href={'/recruiter/scouts/applicant/' + applicant.id} target="_blank">
                            <div className="img_box">
                                <img src={applicant.face_photo} alt={applicant.family_name + applicant.first_name}/>
                            </div>
                            <h4>ID:{ applicant.id }</h4>
                            <p>&#x2665; 相性{ applicant.compatibilities }%</p>
                            <div className="tooltip_box">
                                <div className="tooltip">
                                <p className="icon"><img src="/assets/img/company/icon32.svg" alt=""/></p>
                                <p className="text">※相性とは<br/>スキルや経験だけでなく、求職者の求める働き方と採用担当<br/>者の求める人物像との相性を加味して算出しています。</p>
                                </div>
                            </div>
                            </a>
                        </div>)
                        : (<>
                        <div className="img_box">
                            <img src={applicant.face_photo} alt={applicant.family_name + applicant.first_name}/>
                        </div>
                        <h4>ID:{ applicant.id }</h4>
                        <p>&#x2665; 相性{ applicant.compatibilities }%</p>
                            <div className="tooltip_box">
                                <div className="tooltip">
                                    <p className="icon"><img src="/assets/img/company/icon32.svg" alt=""/></p>
                                    <p className="text">※相性とは<br/>スキルや経験だけでなく、求職者の求める働き方と採用担当<br/>者の求める人物像との相性を加味して算出しています。</p>
                                </div>
                            </div>
                        </>)}
                    </div>
                    <ul>
                        <li>
                        <span>障害種別<br className="sp_only"/>(手帳有無)</span>
                        {applicant.handicap_qualifications.length > 0 ?
                            applicant.handicap_qualifications && applicant.handicap_qualifications.map((handicap_qualification: any ,i: any) => (
                                <>{ handicap_qualification.handicap_type }(手帳{ handicap_qualification.disability_certificate_status })
                                {i != Object.keys(applicant.handicap_qualifications).length - 1 ? <>/<br className="sp_only"/></> : <></>}
                                </>
                            ))
                        : <></>}
                        </li>
                        <li><span>就労支援有無</span>{ applicant.employment_support_agency }</li>
                        <li><span>勤務地希望</span>
                        {applicant.preferred_prefectures.length > 0 ?
                            applicant.preferred_prefectures && applicant.preferred_prefectures.map((preferred_prefecture: any ,i: any) => (
                                <>{preferred_prefecture}
                                {(i != Object.keys(applicant.preferred_prefectures).length - 1) ? <>/<br className="sp_only"/></> : <></>}
                                </>
                            ))
                        : <>無し</>}
                        </li>
                    </ul>
                    </div>
                    <div className="btn_box">
                    {!isCandidatePage() ?
                    <a href="javascript:void(0)" onClick={(e) => saveCandidates(e)} className={applicant.include_scout_candidate ? 'btn green none-applicant' : 'btn green'}>スカウト候補に追加</a>
                    : <></>}
                    <a onClick={() => loadEntry(applicant)} href="#" className="btn orange">スカウト</a>
                    </div>
                    {applicant.scouted_recruitments.length > 0 ?
                        !checkApplicantDetailsPage ?
                            <div className="tag_box">
                                <h5>スカウト済みの案件</h5>
                                <ul>
                                {applicant.scouted_recruitments && applicant.scouted_recruitments.map((scouted_recruitment: any ) => (
                                    <li key={'scouted_recruitment' + scouted_recruitment.id}>
                                        <a href={'/recruitments/' + scouted_recruitment.id} target="_blank">{scouted_recruitment.title}</a>
                                    </li>
                                ))}
                                </ul>
                            </div>
                        : <></>
                    : <></>}
                </div>
                {profile ?
                <div className="cont">  
                    <div>
                        {/* profile1 */}
                        <h3>基本情報</h3>
                        <dl>
                            {/* <dt>障がいの<br className="sp_only"/>基本情報</dt> */}
                            {/* <dd>
                                {profile.handicap_qualifications && profile.handicap_qualifications.map((data: any,index: any ) => (
                                    <>
                                    <p key={index + 100}>
                                    { data.handicap_type } { data.handicap }<br/>
                                    障がい者手帳 { data.disability_certificate_status }<br/>
                                    {data.disability_certificate_status == 'あり' ?
                                        <>{ data.disability_certificate }</>
                                    : <></>}
                                    </p>
                                    {data.process ?
                                    <p key={index + 200}>発症経緯と経過 <br /> { data.process }</p>
                                    : <></>}
                                    {data.characteristics_and_countermeasures ?
                                    <p key={index + 300}>障がい特性と対策 <br /> { data.characteristics_and_countermeasures }</p>
                                    : <></>}
                                    </>
                                ))}
                            </dd> */}
                            <dt>住所</dt>
                            <dd>{ profile.address }</dd>
                            <dt>年齢</dt>
                            <dd>{ profile.age }</dd>
                            <dt>最終学歴</dt>
                            <dd>{ profile.educational_background.final_education }</dd>
                        </dl>
                    </div>
                        
                    { currentPlanId == 1 ?
                        <div style={{color: "#00A0E9", width: "100%", display: "flex"}}>
                            <p style={{color: "#00A0E9", width: "60%", marginTop: "18px"}}>情報はスタンダードプラン以上で全て閲覧可能です</p> 
                            <button onClick={()=>window.location.href = ('/recruiter/plan')} className='scoutsBtn' style={{float: "right", width: "40%"}} >プラン変更へ</button>
                        </div>
                    : 
                        <></> 
                    }

                    <br />

                    <div>
                        <h3>希望条件</h3>
                        <dl>
                            <dt>仕事の探し方</dt>
                            <dd>{ profile.preferred.search_policy }</dd>
                            <dt>就労時間</dt>
                            <dd>{ profile.preferred.working_hour }</dd>
                            <dt>在宅・リモートワーク希望</dt>
                            <dd>{ profile.preferred.work_from_home }</dd>
                            <dt>働き方</dt>
                            <dd>{ profile.preferred_hasmany.applicant_compatibilities }</dd>
                            <dt>勤務地</dt>
                            <dd>{ profile.preferred.prefectures }</dd>
                            <dt>職種</dt>
                            <dd>{ profile.preferred.job_categories }</dd>
                            <dt>雇用形態</dt>
                            <dd>{ profile.preferred_hasmany.employment_statuses }</dd>
                            <dt>年収</dt>
                            <dd>{ profile.preferred.annual_income }</dd>
                        </dl>
                    </div>

                    <br />

                    <div>
                        <h3>障害について</h3>
                        <dl>
                            <dt>障害の基本情報</dt>
                            <dd>
                                {profile.handicap_qualifications && profile.handicap_qualifications.map((data: any,index: any ) => (
                                    <>
                                        <p key={index + 100}>
                                        { data.handicap_type } { data.handicap }<br/>
                                        障がい者手帳 { data.disability_certificate_status }<br/>
                                        {data.disability_certificate_status == 'あり' ?
                                            <>{ data.disability_certificate }</>
                                        : <></>}
                                        </p>
                                    </>
                                ))}
                            </dd>
                            <dt>経緯と経過</dt>
                            <dd>
                                {profile.handicap_qualifications && profile.handicap_qualifications.map((data: any,index: any ) => (
                                    <>
                                        {data.process ?
                                        <p key={index + 200}>発症経緯と経過 <br /> { data.process }</p>
                                        : <></>}
                                    </>
                                ))}
                            </dd>
                            <dt>特性と対策</dt>
                            <dd>
                                {
                                    isMark ?
                                    <div className="isMark"></div>
                                : 
                                    <>
                                        {profile.handicap_qualifications && profile.handicap_qualifications.map((data: any,index: any ) => (
                                            <>
                                                {data.characteristics_and_countermeasures ?
                                                <p key={index + 300}>障がい特性と対策 <br /> { data.characteristics_and_countermeasures }</p>
                                                : <></>}
                                            </>
                                        ))} 
                                    </>
                                }
                            </dd>
                            <dt>就労上必須の配慮事項</dt>
                            <dd>
                                {
                                    isMark ?
                                    <div className="isMark"></div>
                                :  
                                    <>
                                        { consideration_conditions_required_exists }
                                    </>
                                }
                            </dd>
                            <dt>配慮事項(その他)</dt>
                            <dd>
                                {
                                    isMark ?
                                    <div className="isMark"></div>
                                :  
                                    <>
                                        { profile.consideration_conditions_required }
                                    </>
                                }
                            </dd>
                            <dt>あると嬉しい配慮事項</dt>
                            <dd>
                                {
                                    isMark ?
                                    <div className="isMark"></div>
                                :
                                    <>
                                        { profile.consideration_conditions_preferred }
                                    </>
                                }
                            </dd>
                            <dt>あると嬉しい配慮事項(その他)</dt>
                            <dd> 
                                {
                                    isMark ?
                                    <div className="isMarkHeigth"></div>
                                :
                                    <>
                                        { profile.consideration_conditions_preferred_supplemental }
                                    </>
                                }
                            </dd>
                        </dl>
                    </div>

                    <br />

                    <div>
                        <h3>就労支援機関</h3>
                        <dl>
                            <dt>機関:企業名</dt>
                            <dd>
                                {
                                    isMark ?
                                    <div className="isMark"></div>
                                :
                                    <>
                                        { support_agency_exists }
                                    </>
                                }
                            </dd>
                            <dt>利用期間</dt>
                            <dd>
                                {
                                    isMark ?
                                    <div className="isMark"></div>
                                :                                            
                                    <>
                                        { employmentSupportAgency.start_date ? formatYM(employmentSupportAgency.start_date)  : ''} { employmentSupportAgency.start_date ? '〜' : ''}
                                        { employmentSupportAgency.start_date ? formatYM(employmentSupportAgency.start_date) : ''}
                                    </>                                            
                                }
                               </dd>
                            <dt>訓練内容</dt>
                            <dd>
                                {
                                    isMark ?
                                    <div className="isMark"></div>
                                :
                                    <>
                                        { employmentSupportAgency.training_content } 
                                    </>
                                }
                            </dd>
                        </dl>
                    </div>

                    <br />

                    <div>
                        <h3>スキル情報</h3>
                        <dl>
                            <dt>アピールしたい職歴</dt>
                            <dd>
                                {
                                    isMark ?
                                    <div className="isMark"></div>
                                :
                                    <>
                                        {profile.job_career_appeals.length > 0 ?
                                        profile.job_career_appeals && profile.job_career_appeals.map((data: any,index: any ) => (                            
                                            <>{ data.company } / { data.industry }<br/>
                                            { data.job_category }<br/>
                                            { formatYM(data.arrival_date) } 〜 { formatYM(data.departure_date) }<br/>                            
                                            <div dangerouslySetInnerHTML={{__html: replacedContent(data.business_content) || ''}}></div></>
                                        ))
                                        : <>なし</>}
                                    </>
                                }
                                
                            </dd>
                            <dt>スキル-PC</dt>
                            <dd>
                                {
                                    isMark ?
                                    <div className="isMark"></div>
                                :
                                    <div dangerouslySetInnerHTML={{__html: replacedContent(profile.oa_skill) || ''}}></div>
                                }
                            </dd>
                            <dt>スキル-語学</dt>
                            <dd>
                                {
                                    isMark ?
                                    <div className="isMark"></div>
                                :
                                    <div dangerouslySetInnerHTML={{__html: replacedContent(profile.language_study) || ''}}></div>
                                }
                            </dd>
                            <dt>スキル-資格</dt>
                            <dd>
                                {
                                    isMark ?
                                    <div className="isMark"></div>
                                :
                                    <div dangerouslySetInnerHTML={{__html: replacedContent(profile.qualification) || ''}}></div>
                                }
                            </dd>
                            <dt>直近の職歴</dt>
                                <dd>
                                    {profile.job_career_recent_exists ?
                                        <>
                                            {
                                                isMark ?
                                                <div className="isMark"></div>
                                            :
                                                <>
                                                    { profile.job_career_recent.company } / { profile.job_career_recent.industry }<br/>
                                                    { profile.job_career_recent.job_category }<br/>
                                                    { formatYM(profile.job_career_recent.arrival_date) } 〜 { formatYM(profile.job_career_recent.departure_date) }<br/>
                                                    <div dangerouslySetInnerHTML={{__html: replacedContent(profile.job_career_recent.business_content) || ''}}></div>
                                                </>
                                            }
                                        </>
                                    : <></>}

                                    {!profile.job_career_recent_exists ?
                                        <>
                                            {
                                                isMark ?
                                                <div className="isMark"></div>
                                            :
                                                <>{ profile.job_career_recent }</>
                                            }
                                        </>
                                    : <></>}
                                </dd>
                            <dt>自己PR</dt>
                            <dd>
                                {
                                    isMark ?
                                    <div className="isMark"></div>
                                :
                                    <div dangerouslySetInnerHTML={{__html: replacedContent(profile.self_pr.content) || ''}}></div>
                                }
                            </dd>
                        </dl>
                    </div>

                    <div className="btn_box">
                    <a href="javascript:void(0)" onClick={(e) => saveCandidates(e)} className={applicant.include_scout_candidate ? 'btn green none-applicant' : 'btn green'}>スカウト候補に追加</a>
                    <a onClick={() => loadEntry(applicant)} href="#" className="btn orange">スカウト</a>
                    </div>
                    <a href="/recruiter/scouts/">一覧へ戻る</a>
                    <a href="/recruiter/scouts/">一覧へ戻る</a>
                </div>
                : <></>}
                </>
                : <></>}
            </div>
        </section>
    </main>
    );
  }
  export default ScoutsApplicant;