import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompaniesRequest } from "../../redax/actions/companiesActions/companiesActions";
import { useParams } from "react-router-dom";
import { RootState } from "../../redax/reducers/rootReducer";
import RecruitmentCardList from "../../components/RecruitmentCardList/RecruitmentCardList";
import { fetchRecruitmentsPublicListRequest } from "../../redax/actions/recruitmentsPublicListActions/recruitmentsPublicListActions";
import OtherColumnsOfCompany from "../../components/OtherColumnsOfCompany/OtherColumnsOfCompany";

export default function Companies() {
  let company = [];
  let company_prefecture = [];
  let company_city = [];
  let company_industry = [];
  let company_founded = [];
  let company_stock_listing = [];
  let recruitment = [];
  const { id } = useParams();
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompaniesRequest(id, 'get', null));
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchRecruitmentsPublicListRequest(id));
  }, [dispatch]);

  const { companies } = useSelector(
    (state: RootState) => state.companies
  );
  const { recruitments_public_list } = useSelector(
    (state: RootState) => state.recruitmentsPublicList
  );

  if(companies.company !== undefined){
    company = companies.company;
  }
  if(company.prefecture !== undefined){
    company_prefecture = company.prefecture;
  }
  if(company.city !== undefined){
    company_city = company.city;
  }
  if(company.industry !== undefined){
    company_industry = company.industry;
  }
  if(company.founded !== undefined){
    company_founded = company.founded;
  }
  if(company.stock_listing !== undefined){
    company_stock_listing = company.stock_listing;
  }
  if(recruitments_public_list !== undefined) {
    recruitment = recruitments_public_list
  }
  return (
    <main id="jobs" className="column_preview">
    <section className="detail wrapper type2">
      <div className="cont_box">
        <OtherColumnsOfCompany company={company} showMoreBtn = {false} />
        <RecruitmentCardList recruitment={recruitment.recruitments} />
      </div>
      <div className="sidebar">
        <div className="tab_box">
          <div className="logo_box">
            <img src={company.logo} alt="{company.name}"/>
            <h2>{company.name}</h2>
          </div>
          <div className="follow_box">
            <p>FOLLOW 1</p>
          </div>
          <dl>
            <dt>URL</dt>
            <dd>
              <a href="https://www.google.co.jp" target="_blank">https://www.google.co.jp <span className="material-icons">launch</span>
              </a>
            </dd>
            <dt>住所<br/>（本社）</dt>
            <dd>〒{company.postcode}<br/>{company_prefecture.name}{company_city.name}{company.address}</dd>
            <dt>業種分類</dt>
            <dd>{company_industry.name}</dd>
            <dt>事業内容</dt>
            <dd>
              <span className="white_space">{company.business_content}</span>
            </dd>
            <dt> 設立年月 </dt>
            <dd>{company_founded.year}年{company_founded.month}月</dd>
            <dt>従業員数</dt>
            <dd>{company.number_of_employees}人 </dd>
            <dt>資本金</dt>
            <dd>{company.capital}</dd>
            <dt>売上高</dt>
            <dd>{company.sales}</dd>
            <dt>株式</dt>
            <dd>{company_stock_listing.name}</dd>
            <dt>障がい者<br/>雇用実績</dt>
            <dd>無</dd>
          </dl>
        </div>
      </div>
    </section>
    </main>
  );
}
