import { adminCompanyAllListTypes } from "../../ActionTypes/adminCompanyAllListTypes";
import { AdminCompanyAllListActions, AdminCompanyAllListState } from "../../types/type";



const initialState: AdminCompanyAllListState = {
  pending: false,
  company: [],
  error: null,
};

export default (state = initialState, action: AdminCompanyAllListActions,) => {
  switch (action.type) {
    case adminCompanyAllListTypes.FETCH_ADMIN_COMPANY_ALL_LIST_REQUEST:
      return {
        ...state,
        pending: false
      };
    case adminCompanyAllListTypes.FETCH_ADMIN_COMPANY_ALL_LIST_SUCCESS:
      return {
        ...state,
        pending: true,
        company: action.payload.company,
        error: null
      };
    case adminCompanyAllListTypes.FETCH_ADMIN_COMPANY_ALL_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        company: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
