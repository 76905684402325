import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { columnFetchItemTypes } from "../../ActionTypes/columnFetchItemTypes";
import { columnFetchItemFailure, columnFetchItemSuccess } from "../../actions/columnFetchItemActions/columnFetchItemActions";
let per = 3;

var getColumnFetchItem = (paramsId:any) => axios.get(API_BASE_ADDRESS + '/articles/' + paramsId , {headers: { Accept: 'application/json'}}); 

function* fetchColumnFetchItemSaga(paramsId: any) {
  try {
    const response: AxiosResponse = yield call(getColumnFetchItem, paramsId.paramsId);

    yield put(
      columnFetchItemSuccess({
        columnItem: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      columnFetchItemFailure({
        error: e.message
      })
    );
  }
}

function* ColumnFetchItemSaga() {
  yield takeLatest(columnFetchItemTypes.FETCH_COLUMN_FETCH_ITEM_REQUEST, fetchColumnFetchItemSaga);
}

export default ColumnFetchItemSaga;
