import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminCompanyNewTypes } from "../../ActionTypes/adminCompanyNewTypes";
import { fetchAdminCompanyNewSuccess, fetchAdminCompanyNewFailure } from "../../actions/adminCompanyNewActions/adminCompanyNewActions";


const getAdminCompanyNew = (body: any, method: any, id:any) => { 
  console.log(method);
  const config = {
    headers: { 
      Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  if(method == "POST"){
    return axios.post(API_BASE_ADDRESS + '/admin/companies/' , body, config); 
  } else if (method =="PUT") {
    return axios.put(API_BASE_ADDRESS + '/admin/companies/'+ id , body, config); 
  } else {
    return axios.delete(API_BASE_ADDRESS + '/admin/companies/'+ id , config);
  }
 
}


function* fetchAdminCompanyNewSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminCompanyNew, action.body, action.method, action.id);
    yield put(
      fetchAdminCompanyNewSuccess({
        company: response.data,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminCompanyNewFailure({
        error: e.message
      })
    );
  }
}

function* AdminCompanyNewSaga() {
  yield takeLatest(adminCompanyNewTypes.FETCH_ADMIN_COMPANY_NEW_REQUEST, fetchAdminCompanyNewSaga);
}
export default AdminCompanyNewSaga;