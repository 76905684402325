
import { RecruiterPaymentDetailTypes } from "../../ActionTypes/recruiterInvoiceDetailTypes";
import {  FetchRecruiterPaymentDetailFailure, FetchRecruiterPaymentDetailRequest, FetchRecruiterPaymentDetailSuccess, FetchRecruiterPaymentDetailSuccessPayload, FetchRecruiterPaymentlistFailure, FetchRecruiterPaymentlistFailurePayload, FetchRecruiterPaymentlistRequest, FetchRecruiterPaymentlistSuccess, FetchRecruiterPaymentlistSuccessPayload } from "../../types/type";


export const fetchPaymentDetailRequest = (id: any): FetchRecruiterPaymentDetailRequest => ({
  type: RecruiterPaymentDetailTypes.FETCH_RECRUITER_PAYMENT_DETAIL_REQUEST,
  id
});

export const fetchPaymesntDetailSuccess = (
  payload: FetchRecruiterPaymentDetailSuccessPayload,
): FetchRecruiterPaymentDetailSuccess => ({
  type: RecruiterPaymentDetailTypes.FETCH_RECRUITER_PAYMENT_DETAIL_SUCCESS,
  payload
});

export const fetchPaymentDetailFailure = (
  payload: FetchRecruiterPaymentlistFailurePayload
): FetchRecruiterPaymentDetailFailure => ({
  type: RecruiterPaymentDetailTypes.FETCH_RECRUITER_PAYMENT_DETAIL_FAILURE,
  payload
});
