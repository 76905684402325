import { useState, useEffect, useRef } from 'react'; 
import { fetchInvitationRequest } from '../../../redax/actions/invitationActions/invitationActions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redax/reducers/rootReducer';
import './invitation.scss';
function Invitation() {
    const [screen, setScreen] = useState(0);
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const [error3, setError3] = useState(false);

    const [familyname, setFamilyName] = useState<any>(null);
    const [familynamekana, setfamilynameKana] = useState<any>(null);
    const [password, setpassword] = useState<any>(null);
    const [passwordConfirm, setpasswordConfirm] = useState<any>(null);
    const [errornameMaxLenght, setErrorNameMaxLenght] = useState(false);
    const [errorkanaValidate, setErrorkanaValidate] = useState(false);
    const [tokenVal, setToken] = useState<any>('');

    const [errorpasswordMaxLenght, setErrorPasswordMaxLenght] = useState(false);
    const [errorpasswordMinLenght, setErrorPasswordMinLenght] = useState(false);
    const [errorPasswordValidate, setErrorPasswordValidate] = useState(false);
    const [errorPasswordConfirm, setErrorPasswordConfirm] = useState(false);
    const navigate = useNavigate();  
    const dispatch = useDispatch();
    const invitation = useSelector((state: RootState) => state.invitation);
    const [success, setSuccess] = useState(false);
    const queryParameters = new URLSearchParams(window.location.search);
    let token = queryParameters.get("token");



    useEffect(()=> {
      if(invitation.pending) {
        if(invitation !== undefined && invitation.pending) {
          setScreen(1);
          navigate('/recruiter/login');
        } else if(invitation.error !== null) {
          setScreen(2);
        }
      }
      
    },[invitation])


    useEffect(()=> {
     setToken(token);
      
    },[invitation])
    


    const inputChange = (event: any) => {
        const {name, value} = event.target;
        if(name == 'name1') {
            setFamilyName(value);
        }
        if(name == 'nameKana') {
            setfamilynameKana(value);
        }
        if(name == 'password') {
            setpassword(value);
        }
        if(name == 'password_confirm') {
            setpasswordConfirm(value);
        }
        dataValidation(name, value);
      };
      function dataValidation (name: any, value: any) {
        const kanaValidate = new RegExp('^([ァ-ン]|ー)+$');
        // const passwordValidate = new RegExp('/^(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,32}$/');
        const passwordValidate = /^(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,32}$/;
          // const passwordValidate = /^(?=.*[A-Z])(?=.*[a-z])[A-Za-z0-9]{8,32}$/;
          // const passwordValidate = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,32}$/;
        if(name == 'name1') {
            if(value === '') {
              setError1(true);
            } else {
              setError1(false);
              if(value.length > 50) {
                setErrorNameMaxLenght(true);
              } else {
                setErrorNameMaxLenght(false);
              }
            }
          }
          if(name == 'nameKana') {
            if(value === '') {
              setError2(true);
            } else {
                setError2(false);
                if(value.length > 50) {
                  setErrorNameMaxLenght(true);
                } else {
                  setErrorNameMaxLenght(false);
                }
                if(kanaValidate.test(value)) {
                  setErrorkanaValidate(false)
                } else {
                  setErrorkanaValidate(true)
                }
            }
          }
          if(name == 'password') {
            if(value === '') {
              setError3(true);
            } else {
              setError3(false);
              if(value.length >33) {
                setErrorPasswordMaxLenght(true);
              } else {
                setErrorPasswordMaxLenght(false);
              }
              if(value.length < 8) {
                setErrorPasswordMinLenght(true);
              } else {
                setErrorPasswordMinLenght(false);
              }
              if(passwordValidate.test(value)) {
                setErrorPasswordValidate(false)
              } else {
                setErrorPasswordValidate(true)
              }
            }
          }
          if(name == 'password_confirm') {
            if(password != value) {
              setErrorPasswordConfirm(true);
            } else {
              setErrorPasswordConfirm(false);
            }
          }
      }


      function submit(){
        if((familyname !== null || familyname !== undefined) &&  (familynamekana !== null ||  familynamekana !== undefined) && password !== null && passwordConfirm !== null && (token !== '' || token !== undefined)) {
          const data = {
            family_name: familyname,
            family_name_kana: familynamekana,
            password: password,
            password_confirm: passwordConfirm,
            token: token
          };
          if(data !== null) {
            dispatch(fetchInvitationRequest(data));
          }
        }
      }
  
  return (
    <main id="login">
      <section className={`wrapper ${screen !== 0 ? 'password' : ''}`} >
        <h2 className='ttl type3'>ログイン</h2>
        <div className='input_box'>
            <h3 className="ttl">新規採用担当者情報</h3>
            <template>
                {screen ===1 ? 
                <p>採用担当者設定が完了しました。<br/>自動でマイページにリダイレクトします。</p>
                : <></>}
            </template>
            {screen ===2 ? 
            <p>エラーが発生しました。<br/>恐れ入りますがもう一度やり直してください。</p>
            : <></>}
            {screen ===0 ? 
            <div className='manager main'>
                <dl>
                    <dt>ご担当者名</dt>
                    <dd>
                        <label>
                            <input type="text" name="name1" placeholder="ご担当者名"value={familyname} onChange={inputChange} className={error1 || errornameMaxLenght ? 'error': ''}/>
                        </label>
                        {error1 || errornameMaxLenght ? 
                        <div>
                            {error1   ?
                                <span>必須項目です</span>
                            : <></>}
                            {errornameMaxLenght   ?
                                <span>50文字以下で入力してください</span>
                            : <></>}
                         </div>
                        : <></>}
                    </dd>
                    <dt>ご担当者名<span>(カナ)</span></dt>
                    <dd>
                    <label><input type="text" name="nameKana" placeholder="タントウシャメイ" value={familynamekana} onChange={inputChange}/></label>
                    {error2 || errornameMaxLenght ||errorkanaValidate ? 
                        <div>
                            {error2   ?
                                <span>必須項目です</span>
                            : <></>}
                            {errornameMaxLenght   ?
                                <span>50文字以下で入力してください</span>
                            : <></>}
                            {errorkanaValidate   ?
                                <span>ご担当者名（カナ）は全角カタカナで入力してください</span>
                            : <></>}
                         </div>
                        : <></>}
                    </dd>
                    <dt>パスワード</dt>
                    <dd>
                    <input type="password" name="password" value={password} onChange={inputChange}/>
                    {error3 || errorpasswordMaxLenght ||errorpasswordMinLenght|| errorPasswordValidate ? 
                        <div>
                            {error3   ?
                                <span>必須項目です</span>
                            : <></>}
                            {errorpasswordMaxLenght ||errorpasswordMinLenght  ?
                                <span>8文字以上32文字以下で入力してください</span>
                            : <></>}
                            {errorPasswordValidate   ?
                                <span>大文字と小文字を1文字以上含めた半角英数字で入力してください</span>
                            : <></>}
                         </div>
                        : <></>}
                    </dd>
                    <dt>パスワード<span>(確認)</span></dt>
                    <dd>
                    <input type="password" name="password_confirm" value={passwordConfirm} onChange={inputChange}/>
                    {errorPasswordConfirm ? 
                    <div>
                        <span>確認用パスワードが一致していません</span>
                    </div>
                : <></>} 
                    </dd>
                </dl>
                <button onClick={submit} className="btn type3" >設定</button>
                {/* <button  onClick={onSubmit}type="submit" className="btn type3" >設定</button> */}
            </div>
            : <></>}
        </div>
      </section>
    </main>
  );
}

export default Invitation;
