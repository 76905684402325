import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruiterRecruitmentsDetailTypes } from "../../ActionTypes/recruiterRecruitmentsDetailTypes";
import { fetchRecruiterRecruitmentsDetailFailure, fetchRecruiterRecruitmentsDetailSuccess } from "../../actions/recruiterRecruitmentsDetailActions/recruiterRecruitmentsDetailActions";

var getRecruiterRecruitmentsDetail = (id:any) => axios.get(API_BASE_ADDRESS + '/recruitments/' + id , {headers: {Authorization: localStorage.getItem('jobParachannelAuthToken'), Accept: 'application/json'}}); 

function* fetchRecruiterRecruitmentsDetailSaga(id: any) {
  try {
    const response: AxiosResponse = yield call(getRecruiterRecruitmentsDetail, id.id);
    yield put(
      fetchRecruiterRecruitmentsDetailSuccess({
        recruiterRecruitmentsDetail: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruiterRecruitmentsDetailFailure({
        error: e.message
      })
    );
  }
}

function* RecruiterRecruitmentsDetailSaga() {
  yield takeLatest(recruiterRecruitmentsDetailTypes.FETCH_RECRUITER_RECRUIMENTS_DETAIL_REQUEST, fetchRecruiterRecruitmentsDetailSaga);
}

export default RecruiterRecruitmentsDetailSaga;
