import React from 'react';
import './loader.scss';

const Loader = () => {

  return <div id="overlay" className="load-overlay">
  <div className="load-spinner">
    <span className="spinner"></span>
  </div>
</div>;
};

export default Loader;