import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSignupRequest } from '../../redax/actions/signupAction/signupAction';
import { RootState } from '../../redax/reducers/rootReducer';


const Registration = () => {
    const [stepList] = useState(['仮登録情報入力', '仮登録メール送信', '本登録完了']);
    const [screen, setScreen] = useState(1);

        
  return (
    <main id="register">
      <section className="wrapper">
        <div className="ttl_box">
          <h2 className="ttl">新規会員登録</h2>
          <p>登録は<span>1分</span>で完了</p>
        </div>
        <ul className="tab_box">
          {stepList.map((data: any, index: any) => (
            <li key= {index} className={screen === index ? 'active' : ''}>
                <span>STEP{index + 1}</span>{data}
            </li>
            ))}
          </ul>

        <p>
        ご入力いただいたメールアドレス宛に仮登録メールをお送りいたしました。<br/>
        お送りしたメールをご確認いただき、そちらから登録の手順を実行してください。<br/>
        <small>
        ※メールが届かない場合は、間違ったメールアドレスを登録しているか、<br className="pc_only"/>
          迷惑メールフォルダに振り分けられている可能性があります。<br className="pc_only"/>
          ご確認いただき再度ご登録をお願いします。
        </small>
      </p>

      </section>
    </main>
  )
}

export default Registration
