import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { savedConditionsTypes } from "../../ActionTypes/savedConditionsTypes";
import { fetchSavedConditionsFailure, fetchSavedConditionsSuccess } from "../../actions/savedConditionsActions/savedConditionsActions.tsx";


var getsavedConditions = (queryParams:any) => axios.get(API_BASE_ADDRESS + queryParams, {headers: { Accept: 'application/json'}}); 

function* fetchSavedConditionsSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getsavedConditions, queryParams.queryParams);
    // console.log(response.data)
    yield put(
      fetchSavedConditionsSuccess({
        savedConditions: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchSavedConditionsFailure({
        error: e.message
      })
    );
  }
}

function* SavedConditionsSaga() {
  yield takeLatest(savedConditionsTypes.FETCH_SAVED_CONDITIONS_REQUEST, fetchSavedConditionsSaga);
}

export default SavedConditionsSaga;
