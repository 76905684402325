import { savedConditionsCreateTypes } from "../../ActionTypes/savedConditionsCreateTypes";
import { SavedConditionsCreateActions, SavedConditionsCreateState } from "../../types/type";



const initialState: SavedConditionsCreateState = {
  pending: false,
  savedConditionsCreate: [],
  error: null,
};

export default (state = initialState, action: SavedConditionsCreateActions,) => {
  switch (action.type) {
    case savedConditionsCreateTypes.FETCH_SAVED_CONDITIONS_CREATE_REQUEST:
      return {
        ...state,
        pending: false
      };
    case savedConditionsCreateTypes.FETCH_SAVED_CONDITIONS_CREATE_SUCCESS:
      return {
        ...state,
        pending: true,
        savedConditionsCreate: action.payload.savedConditionsCreate,
        error: null
      };
    case savedConditionsCreateTypes.FETCH_SAVED_CONDITIONS_CREATE_FAILURE:
      return {
        ...state,
        pending: false,
        savedConditionsCreate: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
