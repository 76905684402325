import { DownloadCareerTypes } from "../../ActionTypes/downloadCareerTypes ";
import { DownloadCareerActions, DownloadCareerState } from "../../types/type";



const initialState: DownloadCareerState = {
  pending: false,
  applicantId: [],
  error: null,
};

export default (state = initialState, action: DownloadCareerActions,) => {
  switch (action.type) {
    case DownloadCareerTypes.FETCH_DOWNLOAD_CAREER_REQUEST:
      return {
        ...state,
        pending: false
      };
    case DownloadCareerTypes.FETCH_DOWNLOAD_CAREER_SUCCESS:
      return {
        ...state,
        pending: true,
        applicantId: action.payload.applicantId,
        error: null
      };
    case DownloadCareerTypes.FETCH_DOWNLOAD_CAREER_FAILURE:
      return {
        ...state,
        pending: false,
        applicantId: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
