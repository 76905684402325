import { recruitmentsStepTwoTypes } from "../../ActionTypes/recruitmentsStepTwoTypes";
import { RecruitmentsStepTwoActions, RecruitmentsStepTwoState } from "../../types/type";


const initialState: RecruitmentsStepTwoState = {
  pending: false,
  recruitmentsStepTwo: [],
  error: null,
  status: []
};

export default (state = initialState, action: RecruitmentsStepTwoActions,) => {
  switch (action.type) {
    case recruitmentsStepTwoTypes.FETCH_RECRUIMENTS_STEP_TWO_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitmentsStepTwoTypes.FETCH_RECRUIMENTS_STEP_TWO_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitmentsStepTwo: action.payload.recruitmentsStepTwo,
        status: action.payload.status,
        error: null,
      };
    case recruitmentsStepTwoTypes.FETCH_RECRUIMENTS_STEP_TWO_FAILURE:
      return {
        ...state,
        pending: false,
        recruitmentsStepTwo: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
