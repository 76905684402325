import { useState } from "react";


function GuideIndex() {
    const [isActive, setIsActive] = useState(1);

    const changeTab = (tabIndex: any) => {
      setIsActive(tabIndex);
    };
    const pageTitle = '使い方ガイド一覧｜パラちゃんねる';
    document.title = pageTitle;


    
    return (
        <main id="guide">
                <section className="wrapper">
      <h2 className="ttl type3">使い方ガイド</h2>
      <ul className="tab_btn">
        <li onClick={() => changeTab(1)} className={isActive === 1 ? 'active' : ''}>
          ALL
        </li>
        <li onClick={() => changeTab(2)} className={isActive === 2 ? 'active' : ''}>
          求職者向け
        </li>
        <li onClick={() => changeTab(3)} className={isActive === 3 ? 'active' : ''}>
          採用企業向け
        </li>
      </ul>
      <ul className="tab_box">

      {isActive === 1 && 
        <li className={isActive === 1 ? 'active' : ''}>
            <ul>
            <li><a href="/guide/1/"><p>1.アカウント登録について</p></a></li>
            <li><a href="/guide/2/"><p>2.Web履歴書の入力について</p></a></li>
            <li><a href="/guide/3/"><p>3.求人検索・応募について</p></a></li>
            <li><a href="/guide/4/"><p>4.応募管理・企業とのやり取りについて</p></a></li>
            <li><a href="/guide/5/"><p>5.選考中求人のスケジュール確認</p></a></li>
            <li><a href="/guide/6/"><p>6.各種設定</p></a></li>
            <li><a href="/guide/7/"><p>7.企業アカウント登録について</p></a></li>
            <li><a href="/guide/8/"><p>8.求人投稿について</p></a></li>
            <li><a href="/guide/9/"><p>9.求人管理・修正・削除について</p></a></li>
            <li><a href="/guide/10/"><p>10.応募者一覧・ステータス管理について</p></a></li>
            <li><a href="/guide/11/"><p>11.求人の効果確認について</p></a></li>
            <li><a href="/guide/12/"><p>12.各種設定について</p></a></li>
            <li><a href="/guide/13/"><p>13.応募時のメッセージのやり取りについて(企業・求職者両方)</p></a></li>
            <li><a href="/guide/14/"><p>14.【新機能】スカウトについて</p></a></li>
            </ul>
        </li>
      }
      {isActive === 2 && 
      <li className={isActive === 2 ? 'active' : ''}>
            <ul>
            <li><a href="/guide/1/"><p>1.アカウント登録について</p></a></li>
            <li><a href="/guide/2/"><p>2.Web履歴書の入力について</p></a></li>
            <li><a href="/guide/3/"><p>3.求人検索・応募について</p></a></li>
            <li><a href="/guide/4/"><p>4.応募管理・企業とのやり取りについて</p></a></li>
            <li><a href="/guide/5/"><p>5.選考中求人のスケジュール確認</p></a></li>
            <li><a href="/guide/6/"><p>6.各種設定</p></a></li>
            <li><a href="/guide/13/"><p>13.応募時のメッセージのやり取りについて(企業・求職者両方)</p></a></li>
          </ul>
      </li>
      
      }
      {isActive === 3 && 
      <li className={isActive === 3 ? 'active' : ''}>
           <ul>
            <li><a href="/guide/7/"><p>7.企業アカウント登録について</p></a></li>
            <li><a href="/guide/8/"><p>8.求人投稿について</p></a></li>
            <li><a href="/guide/9/"><p>9.求人管理・修正・削除について</p></a></li>
            <li><a href="/guide/10/"><p>10.応募者一覧・ステータス管理について</p></a></li>
            <li><a href="/guide/11/"><p>11.求人の効果確認について</p></a></li>
            <li><a href="/guide/12/"><p>12.各種設定について</p></a></li>
            <li><a href="/guide/13/"><p>13.応募時のメッセージのやり取りについて(企業・求職者両方)</p></a></li>
            <li><a href="/guide/14/"><p>14.【新機能】スカウトについて</p></a></li>
          </ul>

      </li>
      }

      </ul>
    </section>
        </main>
    );
  }
  
  export default GuideIndex;