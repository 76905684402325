import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const AdminNav = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    let currentList = ''
    const isInclude = (value: any) => {
        return routePath.match(new RegExp(value));
    };
    const isMatchDynamic = (value: any) => {
        const reg = new RegExp(value + '[0-9]')
        return routePath.match(reg)
    };
    let routePath = window.location.pathname;
    let disabled = false;
    const isMatch = (value: any) => {
        if(routePath === value) {
          return true
        } else {
          return false  
        }
    };

    // useEffect(() => {
    //     if(isMobile) {
    //       // document.getElementById('nav-menu')!.style.height = '0'
    //       document.getElementById('nav-menu')!.style.padding = '0 30px'
    //       document.getElementById('nav-menu')!.style.transition = 'all 0.3s';
    //     }   
    // }, []);

    const handleClick = () => {      
        if (isMenuOpen) {
          document.body.className = ''
          document.getElementById('nav-menu')!.style.height = '0'
          document.getElementById('nav-menu')!.style.padding = '0 30px'
        } else {
          document.body.className = 'overflow'
          document.getElementById('nav-menu')!.style.height = window.innerHeight + 'px'
          document.getElementById('nav-menu')!.style.padding = ''
        }
        document.getElementById('nav-menu')!.style.transition = 'all 0.3s';
        setIsMenuOpen(!isMenuOpen);
    };

    function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthAdminToken) {
          localStorage.removeItem('jobParachannelAuthAdminToken')
          // navigate('/');
          window.location.href = '/admin/login';
        }
      }

  return (
      <section className='adminNavWrapper'>
        <div>
            <a href="/admin/" style={{width:"80px", height:"48px",}}><img src="/assets/img/logo.svg" alt="パラちゃんねる" style={{width:"80px", height:"48px",}} /></a>
        </div>
        <input className="btn-logout" type="button" value="ログアウト" onClick={logout}/>
        {/* <div className='adminNavBar' style={{overflow:"hidden" , padding:"0",width:"100%"}}>
            <h1 id='linkH1'>
                <a href="/" style={{width:"92px", height:"92px",}}><img src="/assets/img/logo.svg" alt="パラちゃんねる" style={{width:"92px", height:"92px",}} /></a>
            </h1>
            <nav id="nav-menu" style={{textAlign:"center", height:"100%" }}>
                <ul className={disabled ? 'disabled' : ''}>
                    {}
                    <li className={window.location.href.includes('scouts') ? 'adminSpan' : 'notActive'}>
                        <Link className='navLink' onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/admin/home');window.location.reload();}} to="#">
                            <span className={window.location.href.includes('scouts') ? 'adminSpan' : 'adminActive'}>Home</span>
                        </Link>
                    </li>
                    <li className={` ${window.location.href.includes('scouts') ? 'adminActive' : 'notActive' }`}>
                        <Link className='navLink' onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/admin/scout_matchings/jobs');window.location.reload();}} to="#">
                            <span  className={window.location.href.includes('scouts') ? 'adminActive' : 'adminSpan'}>スカウト代行</span>
                        </Link>
                    </li>
                </ul>
                <input type="button" value="Log Out" id='sectionLeftBtn' onClick={logout}/>
            </nav>
            <div className={`menu_btn ${isMenuOpen ? 'active' : ''}`} onClick={handleClick}>
            <span></span>
            <span></span>
            <span></span>
            </div>
        </div> */}
      </section>
  )
}

export default AdminNav
