import { scoutsEntryTypes } from "../../ActionTypes/scoutsEntryTypes";
import { ScoutsEntryActions, ScoutsEntryState } from "../../types/type";


const initialState: ScoutsEntryState = {
  pending: false,
  entry: [],
  error: null,
};

export default (state = initialState, action: ScoutsEntryActions,) => {
  switch (action.type) {
    case scoutsEntryTypes.FETCH_SCOUTS_ENTRY_REQUEST:
      return {
        ...state,
        pending: false
      };
    case scoutsEntryTypes.FETCH_SCOUTS_ENTRY_SUCCESS:
      return {
        ...state,
        pending: true,
        entry: action.payload.entry,
        error: null
      };
    case scoutsEntryTypes.FETCH_SCOUTS_ENTRY_FAILURE:
      return {
        ...state,
        pending: false,
        entry: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
