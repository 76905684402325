import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { useNavigate } from "react-router-dom";
import { fetchSignupFailure, fetchSignupSuccess } from "../../actions/signupAction/signupAction";
import { signupTypes } from "../../ActionTypes/signupTypes";
import { fetchStepFailure, fetchStepSuccess } from "../../actions/stepAction/stepAction";
import { stepTypes } from "../../ActionTypes/stepTypes";


const getStep = (query: any) =>
  axios.post(API_BASE_ADDRESS + '/authenticate/production?token=' + query, {
    query: query,
  }, {
    headers: { Accept: 'application/json'  }
  }) 

function* fetchStepSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getStep, action.query);
    const authToken = response.headers['authorization'];
    if (authToken) {
      localStorage.jobParachannelAuthToken = authToken;
      localStorage.load = '1';
      yield put(
        fetchStepSuccess({
          signup: response.data        
        })
      );
    } else {
      throw new Error('Authorization header not found');
    }
  } catch (e:any) {
    yield put(
      fetchStepFailure({
        error: e.message
      })
    );
  }
}

function* StepSaga() {
  yield takeLatest(stepTypes.FETCH_STEP_REQUEST, fetchStepSaga);
}

export default StepSaga;