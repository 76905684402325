import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { scoutsCompanyTypes } from "../../ActionTypes/scoutsCompayTypes";
import { fetchScoutsCompanyFailure, fetchScoutsCompanySuccess } from "../../actions/scoutsCompanyActions/scoutsCompanyActions.";

var getScoutsCompany = (queryParams:any) => axios.get(API_BASE_ADDRESS  + queryParams , {
  headers: { 
    Accept: 'application/json',
    Authorization: localStorage.jobParachannelAuthToken
  }}); 
function* fetchScoutsCompanySaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getScoutsCompany, queryParams.queryParams);
    yield put(
      fetchScoutsCompanySuccess({
        scoutsCompany: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchScoutsCompanyFailure({
        error: e.message
      })
    );
  }
}

function* ScoutsCompanySaga() {
  yield takeLatest(scoutsCompanyTypes.FETCH_SCOUTS_COMPANY_REQUEST, fetchScoutsCompanySaga);
}

export default ScoutsCompanySaga;
