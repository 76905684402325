import { scoutAlertTypes } from "../../ActionTypes/scoutsAlertTypes";
import { ScoutAlertActions, ScoutAlertState } from "../../types/type";


const initialState: ScoutAlertState = {
  pending: false,
  alert: [],
  error: null,
};

export default (state = initialState, action: ScoutAlertActions,) => {
  switch (action.type) {
    case scoutAlertTypes.FETCH_SCOUT_ALERT_REQUEST:
      return {
        ...state,
        pending: false
      };
    case scoutAlertTypes.FETCH_SCOUT_ALERT_SUCCESS:
      return {
        ...state,
        pending: true,
        alert: action.payload.alert,
        error: null
      };
    case scoutAlertTypes.FETCH_SCOUT_ALERT_FAILURE:
      return {
        ...state,
        pending: false,
        alert: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
