import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../loader/loader";
import { RootState } from "../../../redax/reducers/rootReducer";
import { fetchEmailChangeTokenRequest } from "../../../redax/actions/emailChangeTokenActions/emailChangeTokenActions";

function EmailChange() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loadScreen, setLoadScreen] = useState(false);
    const [screen, setScreen] = useState(0);
    const emailChangeToken = useSelector((state: RootState) => state.emailChangeToken);
    const location = useLocation();
  
    useEffect(() => {
      const query = new URLSearchParams(location.search).get('token');
      if(query){
        dispatch(fetchEmailChangeTokenRequest(query))
      } else {
        navigate('/')
      }
    }, [location,dispatch]);
    useEffect(() => {
        if(emailChangeToken.pending){
            setScreen(1);
            navigate('/mypage')
        } else if (emailChangeToken.error !== null) {
            setScreen(2);
        }
        
      }, [emailChangeToken]);



return (
 <main  id="mypage">
    {loadScreen ? <Loader /> : <></>}
    {/* <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" /> */}
    <section id="mypage_inner" className="settings_cont">
    <div className="input_box">
          <h3 className="ttl">メールアドレス変更</h3>
          {screen === 1 && (
            <>
              <p>メールアドレス変更が完了しました。<br />自動でマイページにリダイレクトします。</p>
              <a href="/login/">ログインページ</a>
            </>
          )}
          {screen === 2 && (
            <>
              <p>エラーが発生しました。<br />恐れ入りますがもう一度やり直してください。</p>
              <a href="/" style={{textAlign:'center'}}>トップページ</a>
            </>
          )}
        </div>
    </section>
 </main>
);

}

export default EmailChange;