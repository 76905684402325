import { savedConditionsDeleteTypes } from "../../ActionTypes/savedConditionsDeleteTypes";
import { FetchSavedConditionsDeleteFailure, FetchSavedConditionsDeleteFailurePayload, FetchSavedConditionsDeleteRequest, FetchSavedConditionsDeleteSuccess, FetchSavedConditionsDeleteSuccessPayload } from "../../types/type";

export const fetchSavedConditionsDeleteRequest = (queryParams:any): FetchSavedConditionsDeleteRequest => ({
  type: savedConditionsDeleteTypes.FETCH_SAVED_CONDITIONS_DELETE_REQUEST,
  queryParams,
});

export const fetchSavedConditionsDeleteSuccess = (
  payload: FetchSavedConditionsDeleteSuccessPayload,
): FetchSavedConditionsDeleteSuccess => ({
  type: savedConditionsDeleteTypes.FETCH_SAVED_CONDITIONS_DELETE_SUCCESS,
  payload
});

export const fetchSavedConditionsDeleteFailure = (
  payload: FetchSavedConditionsDeleteFailurePayload
): FetchSavedConditionsDeleteFailure => ({
  type: savedConditionsDeleteTypes.FETCH_SAVED_CONDITIONS_DELETE_FAILURE,
  payload
});
