import { useState } from "react";


function Guide5() {

    
    return (
        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">5.選考中求人のスケジュール確認</h3>
          <p>この記事は約3分で読めます。</p>
    
          <p>このページでは応募管理・企業とのやり取りについて説明します。</p>
    
          <p>
            1.パラちゃんねる求人サイトTOP画面より
            <a href="/login/" style={{ display: 'inline', color: '#00a0e9' }}>ログイン／登録</a>
            をクリックします。
          </p>
          <img src="/assets/img/guide/first.png" alt=""/>
    
          <p>2.メールアドレス・パスワードを入力し、ログインをクリックします。<br/>
          （パスワードを忘れた場合）パスワードをお忘れの方をクリックしてください。</p>
          <img src="/assets/img/guide/05/02.png" alt=""/>
    
          <p>メールアドレスを入力して、再設定メール送信をクリックして下さい。</p>
          <img src="/assets/img/guide/05/03.png" alt=""/>
    
          <p>3.ログイン後、メニューのスケジュールをクリックすると、<br/>
          選考が確定した求人を一覧で確認することができます。<br/>
          また、マイページ上でも直近のスケジュールが確認することができます。<br/>
          企業から選考日程の連絡が来た場合は、お知らせ画面で通知されます。</p>
          <img src="/assets/img/guide/05/04.png" alt=""/>
    
          <p>4.スケジュール画面では、確定した選考スケジュールが月別に確認できます。<br/>
          求人をクリックすると、メッセージ画面へ遷移します。</p>
          <img src="/assets/img/guide/05/05.png" alt=""/>
    
          <p>5.メッセージ画面で、当日の持ち物や交通手段、同席者の可否など選考についてなどを企業へ確認したい場合はメッセージ内容を入力し送信ボタンをクリックしてください。</p>
          <img src="/assets/img/guide/05/06.png" alt=""/>
    
          <p>以上が選考中のスケジュール確認についてとなります。</p>
          <a href="/guide/" className="btn type3">一覧へ戻る</a>
        </section>
      </main>
    );
  }
  
  export default Guide5;