import { adminScoutApplicantsTypes } from "../../ActionTypes/adminScoutMatchingApplicantsTypes";
import { FetchAdminScoutApplicantsRequest, FetchAdminScoutApplicantsSuccessPayload, FetchAdminScoutApplicantsSuccess, FetchAdminScoutApplicantsFailure,
  FetchAdminScoutApplicantsFailurePayload } from "../../types/type";


export const fetchAdminScoutApplicantsRequest = (method: any, data: any): FetchAdminScoutApplicantsRequest => ({
  type: adminScoutApplicantsTypes.FETCH_ADMIN_SCOUT_APPLICANTS_REQUEST,
  method,
  data,
});

export const fetchAdminScoutApplicantsSuccess = (
  payload: FetchAdminScoutApplicantsSuccessPayload
): FetchAdminScoutApplicantsSuccess => ({
  type: adminScoutApplicantsTypes.FETCH_ADMIN_SCOUT_APPLICANTS_SUCCESS,
  payload
});

export const fetchAdminScoutApplicantsFailure = (
  payload: FetchAdminScoutApplicantsFailurePayload
): FetchAdminScoutApplicantsFailure => ({
  type: adminScoutApplicantsTypes.FETCH_ADMIN_SCOUT_APPLICANTS_FAILURE,
  payload
});