import { useState, useEffect, useRef } from 'react'; 
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from '../../../redax/reducers/rootReducer';
import { useDispatch,  } from 'react-redux';
import { fetchPreviewRequest } from '../../../redax/actions/previewActions/previewActions';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';

function Preview() {
  let company: any = [];
  const dispatch = useDispatch();
  const { preview } = useSelector((state: RootState) => state.preview);
  const loginData = useSelector((state: RootState) => state.login);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [tab, setTab] = useState(1);
  const [companyName, setCompanyName] = useState<any>(null);
  const [companyUrl, setCompanyUrl] = useState<any>(null);
  const [companyPostCode, setCompanyPostCode] = useState<any>(null);
  const [companyPrefecture, setPrefecture] = useState<any>(null);
  const [companyCity, setCity] = useState<any>(null);
  const [companyAddress, setAddress] = useState<any>(null);
  const [companyIndustry, setCompanyIndustry] = useState<any>(null);
  const [companyBusinessContent, setBusinessContent] = useState<any>(null);
  const [companyFoundedyear, setCompanyFoundedyear] = useState<any>(null);
  const [companyFoundedmonth, setFoundedmonth] = useState<any>(null);
  const [companyNumber_of_employees, setNumber_of_employees] = useState<any>(null);
  const [companyCapital, setCompanyCapital] = useState<any>(null);
  const [companySales, setcompanySales] = useState<any>(null);
  const [companyStock_listing, setcompanyStock_listing] = useState<any>(null);
  const [dataName, setdataName] = useState<any>(null);
  const [load, setLoad] = useState<any>(false);
  const navigate = useNavigate();
  const [corporatecultures, setcorporate_cultures] = useState([]);
  const [peopleWithDisabilitiesDisplay, setPeopleWithDisabilitiesDisplay] = useState<any[]>([]);
  const [peopleWithDisabilitiesExists, setPeopleWithDisabilitiesExists] = useState(null);
  let nextUrlCompany = true;
  useEffect(() => {
    if (localStorage.getItem('jobParachannelAuthToken')) {
      dispatch(fetchLoginRequest('', '',''))
    } else (
        navigate('/recruiter/login/')
    )
  }, [dispatch]);

  useEffect(() => {
    if (loginData.pending && !load) {
      dispatch(fetchPreviewRequest(loginData.login.company.id));
      setLoad(true);
    }
  }, [dispatch, loginData, load]);

  useEffect(() => {
    if (preview !== undefined && preview.company ) {
      setCompanyLogo(preview.company.logo);
      setCompanyName(preview.company.name);
      setCompanyUrl(preview.company.url);
      setCompanyPostCode(preview.company.postcode);
      setPrefecture(preview.company.prefecture);
      setCity(preview.company.city);
      setAddress(preview.company.address);
      setCompanyIndustry(preview.company.industry);
      setBusinessContent(preview.company.business_content);
      setNumber_of_employees(preview.company.number_of_employees);
      setCompanyCapital(preview.company.capital);
      setcompanySales(preview.company.sales);
      setcompanyStock_listing(preview.company.stock_listing);
      setCompanyFoundedyear(preview.company.founded.year);
      setFoundedmonth(preview.company.founded.month);
      setcorporate_cultures(preview.company.corporate_cultures);
      let c: any[] = [];
      if(preview.company.people_with_disabilities_display !== undefined) {
        c = Object.entries(preview.company.people_with_disabilities_display);
      }
      setPeopleWithDisabilitiesDisplay(c);
      setPeopleWithDisabilitiesExists(preview.company.people_with_disabilities_exists);      
    }
    
  }, [preview]);  

  return (
    <main id="jobs">
      <section  className={`detail wrapper ${tab === 0 ? 'type1' : 'type2'}`}>
        <div className='cont_box'>
          <h2 className='ttl'>
            {tab === 0 ? <></> : <>どんな会社？</>}
          </h2>
          {tab === 0 ? 
          <div className="no_image">
            <a href="/recruiter/company/">
              <span>画像がまだ登録されていません</span>
              <img src="/assets/img/company/icon03.svg" className="add_icon" alt="" />
          </a>
        </div>
          : <img src={companyLogo || ''} alt="{name}" />}
        <h3>社風</h3>
        <ul className="tag">
              {corporatecultures && corporatecultures.map((data: any, index: any) => (
                <li key={index}>{data.name}</li>
              ))}
        </ul>
        </div>
        <div className='sidebar'>
        <p className="back_btn"> <Link onClick={nextUrlCompany ? (e) => {window.location.reload(); window.location.href='/recruiter/company/'} : () => {window.location.reload();}} to={''}>会社概要を編集する</Link></p>
        <p className="back_btn"> <Link to={'/recruiter/recruitments/'}>求人一覧に戻る</Link></p>
        <ul className="tab_btn top">
          <li className='active'><a href="">会社概要</a></li>
        </ul>

        <div className='tab_box'>
        <div className="logo_box">
            <img src={companyLogo || ''} alt="{name}" />
            <h2>{ companyName }</h2>
        </div>
        <dl>
          <dt>会社名</dt>
          <dd>{ companyName }</dd>
          <dt>URL</dt>
          <dd>  
          <a href="companyUrl" target="_blank">{companyUrl }<span className="material-icons">launch</span></a>
          </dd>
          <dt>住所<br/>（本社）</dt>
          <dd>〒{ companyPostCode }<br/>
          {companyPrefecture}{companyCity}{companyAddress}
           </dd>
          <dt >業種分類</dt>
          <dd>{ companyIndustry }</dd>
          <dt >事業内容</dt>
          <dd>{ companyBusinessContent}</dd>
          <dt>設立年月</dt>
          <dd>{ companyFoundedyear}年{companyFoundedmonth}月</dd>
          <dt>従業員数</dt>
          <dd>{ companyNumber_of_employees}人</dd>
          <dt>資本金</dt>
          <dd>{ companyCapital}</dd>
          <dt>売上高</dt>  
          <dd>{ companySales}</dd>
          <dt>株式</dt>
          <dd>{ companyStock_listing}</dd>
          <dt>障がい者<br/>雇用実績</dt>
          {peopleWithDisabilitiesExists == '無' ? (
              <dd>
              {peopleWithDisabilitiesExists }
            </dd>
            ) :
            <dd>
              {peopleWithDisabilitiesDisplay && peopleWithDisabilitiesDisplay.map((item: any, index : any) => (
                <>
                 {item[0]} : {item[1]}<br key={item[0]}></br>
                </>
              ))}
            </dd>
            }
        </dl>
        </div>
        <ul className="tab_btn bottom">
          <li className='active'><a href="#">会社概要</a></li>
        </ul>

        </div>
      </section>
    </main>
  );
}

export default Preview;

