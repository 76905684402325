import { recruitmentsSearchTypes } from "../../ActionTypes/recruitmentsSearchTypes";
import { RecruitmentsSearchActions, RecruitmentsSearchState } from "../../types/type";

const initialState: RecruitmentsSearchState = {
  pending: false,
  recruitmentsSearch: [],
  error: null,
};

export default (state = initialState, action: RecruitmentsSearchActions,) => {
  switch (action.type) {
    case recruitmentsSearchTypes.FETCH_RECRUIMENTS_SEARCH_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitmentsSearchTypes.FETCH_RECRUIMENTS_SEARCH_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitmentsSearch: action.payload.recruitmentsSearch,
        error: null
      };
    case recruitmentsSearchTypes.FETCH_RECRUIMENTS_SEARCH_FAILURE:
      return {
        ...state,
        pending: false,
        recruitmentsSearch: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
