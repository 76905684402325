import { adminApplicantsCsvTypes } from "../../ActionTypes/adminApplicantsCsvTypes";
import { FetchAdminApplicantsCsvFailure, FetchAdminApplicantsCsvFailurePayload, FetchAdminApplicantsCsvRequest, FetchAdminApplicantsCsvSuccess, FetchAdminApplicantsCsvSuccessPayload } from "../../types/type";


export const fetchAdminApplicantsCsvRequest = (): FetchAdminApplicantsCsvRequest => ({
  type: adminApplicantsCsvTypes.FETCH_ADMIN_APPLICANTS_CSV_REQUEST,
});

export const fetchAdminApplicantsCsvSuccess = (
  payload: FetchAdminApplicantsCsvSuccessPayload
): FetchAdminApplicantsCsvSuccess => ({
  type: adminApplicantsCsvTypes.FETCH_ADMIN_APPLICANTS_CSV_SUCCESS,
  payload
});

export const fetchAdminApplicantsCsvFailure = (
  payload: FetchAdminApplicantsCsvFailurePayload
): FetchAdminApplicantsCsvFailure => ({
  type: adminApplicantsCsvTypes.FETCH_ADMIN_APPLICANTS_CSV_FAILURE,
  payload
});