import { adminRecruitmentDetailTypes } from "../../ActionTypes/adminRecruitmentDetailTypes";
import { FetchAdminRecruitmentDetailFailure, FetchAdminRecruitmentDetailFailurePayload, FetchAdminRecruitmentDetailRequest, FetchAdminRecruitmentDetailSuccess, FetchAdminRecruitmentDetailSuccessPayload } from "../../types/type";

export const fetchAdminRecruitmentDetailRequest = (id: any): FetchAdminRecruitmentDetailRequest => ({
  type: adminRecruitmentDetailTypes.FETCH_ADMIN_RECRUITMENT_DETAIL_REQUEST,
  id
});

export const fetchAdminRecruitmentDetailSuccess = (
  payload: FetchAdminRecruitmentDetailSuccessPayload
): FetchAdminRecruitmentDetailSuccess => ({
  type: adminRecruitmentDetailTypes.FETCH_ADMIN_RECRUITMENT_DETAIL_SUCCESS,
  payload
});

export const fetchAdminRecruitmentDetailFailure = (
  payload: FetchAdminRecruitmentDetailFailurePayload
): FetchAdminRecruitmentDetailFailure => ({
  type: adminRecruitmentDetailTypes.FETCH_ADMIN_RECRUITMENT_DETAIL_FAILURE,
  payload
});