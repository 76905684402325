import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { applicantsPrTypes } from "../../ActionTypes/applicantsPrTypes";
import { fetchApplicantsPrFailure, fetchApplicantsPrSuccess } from "../../actions/applicantsPrActions/applicantsPrActions";


var getApplicantsPr = (id:any, types: any, pr_id: any, a: any) => {
  if(pr_id != null && pr_id != '') {
    return axios.patch(API_BASE_ADDRESS + '/applicants/' + id + '/self_prs/' + pr_id, a, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken')}});
  } else {
    return axios.post(API_BASE_ADDRESS + '/applicants/' + id + '/self_prs', a, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken')}});
  }
}

function* fetchApplicantsPrSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getApplicantsPr, action.id, action.name, action.pr_id, action.a);
    yield put(
      fetchApplicantsPrSuccess({
        pr: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchApplicantsPrFailure({
        error: e.message
      })
    );
  }
}

function* ApplicantsPrSaga() {
  yield takeLatest(applicantsPrTypes.FETCH_APPLICANTS_PR_REQUEST, fetchApplicantsPrSaga);
}

export default ApplicantsPrSaga;
