import { passwordResetTypes } from "../../ActionTypes/passwordResetTypes";
import { FetchPasswordResetFailure, FetchPasswordResetFailurePayload, FetchPasswordResetRequest, FetchPasswordResetSuccess, FetchPasswordResetSuccessPayload } from "../../types/type";




export const fetchPasswordResetRequest = (email:any, resource_type: any): FetchPasswordResetRequest => ({
  type: passwordResetTypes.FETCH_PASSWORD_RESET_REQUEST,
  email,
  resource_type,
});

export const fetchPasswordResetSuccess = (
  payload: FetchPasswordResetSuccessPayload,
): FetchPasswordResetSuccess => ({
  type: passwordResetTypes.FETCH_PASSWORD_RESET_SUCCESS,
  payload
});

export const fetchPasswordResetFailure = (
  payload: FetchPasswordResetFailurePayload
): FetchPasswordResetFailure => ({
  type: passwordResetTypes.FETCH_PASSWORD_RESET_FAILURE,
  payload
});
