import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminCompanyRecruiterTypes } from "../../ActionTypes/adminCompanyRecruiterTypes";
import { fetchAdminCompanyRecruiterSuccess, fetchAdminCompanyRecruiterFailure } from "../../actions/adminComanyRecruiterActions/adminComanyRecruiterActions";


const getAdminCompanyRecruiter = (body: any, method: any, id:any, recruiterId:any) => { 
  const config = {
    headers: { 
      Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  if(method == "POST"){
    return axios.post(API_BASE_ADDRESS + '/admin/companies/' + id + '/recruiters/'+ recruiterId , body, config); 

  } else if (method =="PUT") {
    return axios.put(API_BASE_ADDRESS + '/admin/companies/' + id + '/recruiters/' + recruiterId , body, config); 
  } else {
    return axios.delete(API_BASE_ADDRESS + '/admin/companies/'+ id + '/recruiters/'+ recruiterId , config);
  }
 
}


function* fetchAdminCompanyRecruiterSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminCompanyRecruiter, action.body, action.method, action.id, action.recruiterId);
    yield put(
      fetchAdminCompanyRecruiterSuccess({
        recruiter: response.data,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminCompanyRecruiterFailure({
        error: e.message
      })
    );
  }
}

function* AdminCompanyRecruiterSaga() {
  yield takeLatest(adminCompanyRecruiterTypes.FETCH_ADMIN_COMPANY_RECRUITER_REQUEST, fetchAdminCompanyRecruiterSaga);
}
export default AdminCompanyRecruiterSaga;