import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { articlesImagesFailure, articlesImagesSuccess } from "../../actions/articlesImagesActions/articlesImagesActions";
import { articlesImagesTypes } from "../../ActionTypes/articlesImagesTypes";

var getArticlesImages = (page:any) => axios.get(API_BASE_ADDRESS + '/articles/images?page=' + page , {headers: { Accept: 'application/json',  Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 

function* fetchArticlesImagesSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getArticlesImages, action.page);

    yield put(
      articlesImagesSuccess({
        images: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      articlesImagesFailure({
        error: e.message
      })
    );
  }
}

function* ArticlesImagesSaga() {
  yield takeLatest(articlesImagesTypes.FETCH_ARTICLES_IMAGES_REQUEST, fetchArticlesImagesSaga);
}

export default ArticlesImagesSaga;
