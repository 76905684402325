import { adminInvoiceTypes } from "../../ActionTypes/adminInvoiceTypes"
import { FetchAdminInvoiceFailure, FetchAdminInvoiceRequest, FetchAdminInvoiceSuccess, FetchAdminInvoiceSuccessPayload,
  FetchAdminInvoiceFailurePayload } from "../../types/type";

export const fetchAdminInvoiceRequest = (data: any): FetchAdminInvoiceRequest => ({
  type: adminInvoiceTypes.FETCH_ADMIN_INVOICE_REQUEST,
  data
});

export const fetchAdminInvoiceSuccess = (
  payload: FetchAdminInvoiceSuccessPayload
): FetchAdminInvoiceSuccess => ({
  type: adminInvoiceTypes.FETCH_ADMIN_INVOICE_SUCCESS,
  payload
});

export const fetchAdminInvoiceFailure = (
  payload: FetchAdminInvoiceFailurePayload
): FetchAdminInvoiceFailure => ({
  type: adminInvoiceTypes.FETCH_ADMIN_INVOICE_FAILURE,
  payload
});