import { articlesRecruiterTypes } from "../../ActionTypes/articlesRecruiterTypes";
import { FetchArticlesRecruiterFailure, FetchArticlesRecruiterFailurePayload, FetchArticlesRecruiterRequest, FetchArticlesRecruiterSuccess, FetchArticlesRecruiterSuccessPayload } from "../../types/type";


export const fetchArticlesRecruiterRequest = (data: any): FetchArticlesRecruiterRequest => ({
  type: articlesRecruiterTypes.FETCH_ARTICLES_RECRUITER_REQUEST,
  data,
});

export const fetchArticlesRecruiterSuccess = (
  payload: FetchArticlesRecruiterSuccessPayload,
): FetchArticlesRecruiterSuccess => ({
  type: articlesRecruiterTypes.FETCH_ARTICLES_RECRUITER_SUCCESS,
  payload
});

export const fetchArticlesRecruiterFailure = (
  payload: FetchArticlesRecruiterFailurePayload
): FetchArticlesRecruiterFailure => ({
  type: articlesRecruiterTypes.FETCH_ARTICLES_RECRUITER_FAILURE,
  payload
});
