import { applicantTypes } from "../../ActionTypes/applicantTypes";
import { ApplicantActions, ApplicantState } from "../../types/type";


const initialState: ApplicantState = {
  pending: false,
  applicant: [],
  error: null,
};

export default (state = initialState, action: ApplicantActions,) => {
  switch (action.type) {
    case applicantTypes.FETCH_APPLICANT_REQUEST:
      return {
        ...state,
        pending: false
      };
    case applicantTypes.FETCH_APPLICANT_SUCCESS:
      return {
        ...state,
        pending: true,
        applicant: action.payload.applicant,
        error: null
      };
    case applicantTypes.FETCH_APPLICANT_FAILURE:
      return {
        ...state,
        pending: false,
        applicant: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
