import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { useNavigate } from "react-router-dom";
import { fetchSignupFailure, fetchSignupSuccess } from "../../actions/signupAction/signupAction";
import { signupTypes } from "../../ActionTypes/signupTypes";


const getSignup = (email: any, password: any, resource_type: any) =>
  axios.post(API_BASE_ADDRESS + '/authenticate/pre', {
    email: email,
    password: password,
    resource_type: resource_type
  }, {
    headers: { Accept: 'application/json' }
  }) 

function* fetchSignupSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getSignup, action.email, action.password, action.resource_type);
    yield put(
      fetchSignupSuccess({
        signup: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchSignupFailure({
        error: e.message
      })
    );
  }
}

function* SignupSaga() {
  yield takeLatest(signupTypes.FETCH_SIGNUP_REQUEST, fetchSignupSaga);
}

export default SignupSaga;