

import { favCountTypes } from "../../ActionTypes/favCountTypes";
import { FavCountActions, CountState } from "../../types/type";


const initialState: CountState = {
  pending: false,
  count: [],
  error: null,
};

export default (state = initialState, action: FavCountActions,) => {
  switch (action.type) {
    case favCountTypes.FETCH_FAV_COUNT_REQUEST:
      return {
        ...state,
        pending: false
      };
    case favCountTypes.FETCH_FAV_COUNT_SUCCESS:
      return {
        ...state,
        pending: true,
        count: action.payload.count,
        error: null
      };
    case favCountTypes.FETCH_FAV_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        count: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
