import axios, { AxiosResponse } from "axios";
import { API_BASE_ADDRESS } from "../../API";
import { call, put, takeLatest } from "redux-saga/effects";
import { ChangeUpdateStatusTypes } from "../../ActionTypes/changeUpdateStatusTypes";
import { fetchChangeUpdateStatusFailure, fetchChangeUpdateStatusSuccess } from "../../actions/changeUpdateStatusActions/changeUpdateStatusActions ";

var getMessages = (application_id: any, status_id: any) => axios.put(API_BASE_ADDRESS + '/applications/' + application_id, {
  status_id: status_id }, {
  headers: {
  Accept: '*/*',
  }
}); 
function* fetchChangeUpdateStatusSaga(queryParams: any) {
  try {
    console.log(queryParams);
    const response: AxiosResponse = yield call(getMessages, queryParams.application_id, queryParams.status_id);
    yield put(
      fetchChangeUpdateStatusSuccess({
        status_id: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchChangeUpdateStatusFailure({
        error: e.status_id
      })
    );
  }
}

function* ChangeUpdateStatusSaga() {
  yield takeLatest(ChangeUpdateStatusTypes.FETCH_CHANGE_UPDATE_STATUS_REQUEST, fetchChangeUpdateStatusSaga);
}

export default ChangeUpdateStatusSaga;
