import { adminApplicantsPostTypes } from "../../ActionTypes/adminApplicantsPostTypes";
import { AdminApplicantsPostActions, AdminApplicantsPostState } from "../../types/type";



const initialState: AdminApplicantsPostState = {
  pending: false,
  applicants: [],
  error: null,
};

export default (state = initialState, action: AdminApplicantsPostActions,) => {
  switch (action.type) {
    case adminApplicantsPostTypes.FETCH_ADMIN_APPLICANTS_POST_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminApplicantsPostTypes.FETCH_ADMIN_APPLICANTS_POST_SUCCESS:
      return {
        ...state,
        pending: true,
        applicants: action.payload.applicants,
        error: null
      };
    case adminApplicantsPostTypes.FETCH_ADMIN_APPLICANTS_POST_FAILURE:
      return {
        ...state,
        pending: false,
        applicants: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
