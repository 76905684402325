import { useState } from "react";


function Guide10() {

    
    return (
        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">10.応募者一覧・ステータス管理について</h3>
          <p>この記事は約3分で読めます。</p>
    
          <p>このページでは応募者一覧・ステータス管理について説明します。</p>
    
          <p>1.TOPページ(<a href="/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/</a>) から画面右上の「採用担当者様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/first.png" alt=""/>
    
          <p>2.「既に会員の企業様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/10/02.png" alt="/"/>
    
          <p>3.登録したメールアドレス・パスワードにて、ログインしてください。<br/>
          パスワードをお忘れの方はこちらから再設定してください。<br/>
          <a href="/recruiter/password/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/recruiter/password/</a></p>
          <img src="/assets/img/guide/10/03.png" alt=""/>
    
          <p>4.マイページ画面左メニューの「応募管理」をクリックし、一覧を選びます。<br/>
          この画面では、各求人への応募状況を一覧で見ることができます。<br/>
          主な機能として、３つあります。<br/>
          ・各求職者の概要・選考状況の把握<br/>
          ・選考ステータスの変更<br/>
          ・CSVで一括ダウンロード<br/>
          （求人・ステータス・障がい区分ごとに絞り込みも可能）</p>
    
          <p>なお、パラちゃんねるの仕様としてステータスが「10日間」変更がない場合、自動で「不採用」に変更されます。対応漏れのないようご留意ください。</p>
          <img src="/assets/img/guide/10/04.png" alt=""/>
    
          <p >※注意※<br/>ステータス変更すると、求職者側のステータスも変更されてしまうためご注意ください。</p>
          <p>・誤ってステータス変更してしまった場合、以下対応をお願い致します。<br/>
          ①求職者へメッセージ機能で連絡<br/>
          ②以下より事務局までご連絡ください。<br/>
          <a href="/contact/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/contact/</a></p>
    
          <p>5.選考ステータスを「面接・職場見学の日程調整に進む」を押すと、メッセージにて日程調整が可能になります。</p>
    
          <img src="/assets/img/guide/10/05_1.png" alt=""/>
          <p>以下の日程調整画面へ遷移します。</p>
          <img src="/assets/img/guide/10/05_2.png" alt=""/>
    
          <p>6.日程候補を入力します</p>
          <p>※面接・職場見学の日程はこちらの「面接・職場見学の日程を調整する」の機能を使って確定することでステータスが変更となります。<br/>
          したがって、テキストメッセージは使わずこちらの機能をお使いください。</p>
          <img src="/assets/img/guide/10/06.png" alt=""/>
    
          <p>7.日程を送信すると、求職者へ通知されます。  </p>
          <img src="/assets/img/guide/10/07_1.png" alt=""/>
          <p>※当日に向けて連絡先や詳細を伝えたい場合はメッセージタブから送信ができます。</p>
          <img src="/assets/img/guide/10/07_2.png" alt=""/>
    
          <p>8.（日程調整前に応募者の詳細情報を見たい場合）応募者詳細タブで確認できます。</p>
          <img src="/assets/img/guide/10/09.png" alt=""/>
    
          <p>9.（求職者画面）求職者へはこのように届きます。</p>
          <img src="/assets/img/guide/10/10.png" alt=""/>
    
          <p>10.求職者が日程を確定すると、日程確定のメールが返信されます。</p>
          <img src="/assets/img/guide/10/11.png" alt=""/>
    
          <p>11.直近の選考予定を確認したい場合、左メニュー画面のスケジュールにも予定が反映されます。</p>
          <img src="/assets/img/guide/10/12.png" alt=""/>
    
          <p>続いて、求人の効果確認については<a href="/guide/11/" style={{display: 'inline',color: '#00a0e9'}}>こちら</a>をお読みください。</p>
          <a href="/guide/" className="btn type3 nuxt-link-active">一覧へ戻る</a>
        </section>
      </main>
       
    );
  }
  
  export default Guide10;