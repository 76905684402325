import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { emailChangeTokenTypes } from "../../ActionTypes/emailChangeTokenTypes";
import { fetchEmailChangeTokenFailure, fetchEmailChangeTokenSuccess } from "../../actions/emailChangeTokenActions/emailChangeTokenActions";


const getEmailChange = (token: any) =>
  axios.post(API_BASE_ADDRESS + '/email_change?token=' + token,{
    headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'), }
  });

function* fetchEmailChangeTokenSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getEmailChange, action.token);
    yield put(
      fetchEmailChangeTokenSuccess({
        token: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchEmailChangeTokenFailure({
        error: e.message
      })
    );
  }
}

function* EmailChangeTokenSaga() {
  yield takeLatest(emailChangeTokenTypes.FETCH_EMAIL_CHANGE_TOKEN_REQUEST, fetchEmailChangeTokenSaga);
}
export default EmailChangeTokenSaga;