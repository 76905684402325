import { adminRecruitmentListTypes } from "../../ActionTypes/adminRecruitmentListTypes";
import { AdminRecruitmentListActions, AdminRecruitmentListState } from "../../types/type";

const initialState: AdminRecruitmentListState = {
  pending: false,
  recruitments: [],
  pagenation: {},
  error: null,
};

export default (state = initialState, action: AdminRecruitmentListActions,) => {
  switch (action.type) {
    case adminRecruitmentListTypes.FETCH_ADMIN_RECRUITMENT_LIST_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminRecruitmentListTypes.FETCH_ADMIN_RECRUITMENT_LIST_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitments: action.payload.recruitments,
        pagenation: action.payload.pagenation,
        error: null
      };
    case adminRecruitmentListTypes.FETCH_ADMIN_RECRUITMENT_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        applicant: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
