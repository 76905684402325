import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchRecruiterPlanFailure, fetchRecruiterPlanSuccess } from "../../actions/recruiterPlanActions/recruiterPlanActions";
import { RecruiterPlanTypes } from "../../ActionTypes/recruiterPlan";

const getRecruiterPlan = (method: any, data: any) => {

      return axios.get(API_BASE_ADDRESS + '/recruiter/plan', {
        headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken }
      });
    }

function* fetchRecruiterPlanSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getRecruiterPlan, action.method, action.data);
    yield put(
      fetchRecruiterPlanSuccess({
        recruiterPlan: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruiterPlanFailure({
        error: e.message
      })
    );
  }
}

function* RecruiterPlanSaga() {
  yield takeLatest(RecruiterPlanTypes.FETCH_RECRUITER_PLAN_REQUEST, fetchRecruiterPlanSaga);
}
export default RecruiterPlanSaga;