import { adminInvoicePostTypes } from "../../ActionTypes/adminInvoicePostTypes";
import { FetchAdminInvoicePostFailure, FetchAdminInvoicePostRequest, FetchAdminInvoicePostSuccess, FetchAdminInvoicePostSuccessPayload, FetchAdminInvoicePostFailurePayload } from "../../types/type";

export const fetchAdminInvoicePostRequest = (data: any): FetchAdminInvoicePostRequest => ({
  type: adminInvoicePostTypes.FETCH_ADMIN_INVOICE_POST_REQUEST,
  data,
});

export const fetchAdminInvoicePostSuccess = (
  payload: FetchAdminInvoicePostSuccessPayload
): FetchAdminInvoicePostSuccess => ({
  type: adminInvoicePostTypes.FETCH_ADMIN_INVOICE_POST_SUCCESS,
  payload
});

export const fetchAdminInvoicePostFailure = (
  payload: FetchAdminInvoicePostFailurePayload
): FetchAdminInvoicePostFailure => ({
  type: adminInvoicePostTypes.FETCH_ADMIN_INVOICE_POST_FAILURE,
  payload
});