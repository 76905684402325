import { useLocation } from "react-router-dom";

function RecruitmentCardList(props: { recruitment: any; }) {
    let recruitment: any = [];
    if(props) {
        recruitment  = props.recruitment;
    }
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('consideration_conditions', 'true');

    return (
        <div className="link_box link_box_cont_box">
        {recruitment ?
        <div className="etc_box">
          {recruitment.length > 0 ?
          <h3>この企業の求人</h3>
          : <></>}
          <ul>
            {recruitment.map((data: any) => (
                <li key={data.id}>
                    <a href={`/recruitments/${data.id}`} >
                        <div className="img_box"><img src={data.thumbnail} alt=""/></div>
                        <div className="text_box">
                            <p className="company">{data.company_name}</p>
                            <p>{data.job_title}</p>
                        </div>
                    </a>
                </li>               
            ))}
          </ul>
        </div>
        : <></>}
      </div>
    );
  }
  
  export default RecruitmentCardList;