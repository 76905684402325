import { adminRecruitmentEditTypes } from "../../ActionTypes/adminRecruitmentEditTypes";
import { AdminRecruitmentEditActions, AdminRecruitmentEditState } from "../../types/type";


const initialState: AdminRecruitmentEditState = {
  pending: false,
  recruitments: [],
  error: null,
};

export default (state = initialState, action: AdminRecruitmentEditActions,) => {
  switch (action.type) {
    case adminRecruitmentEditTypes.FETCH_ADMIN_RECRUITMENT_EDIT_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminRecruitmentEditTypes.FETCH_ADMIN_RECRUITMENT_EDIT_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitments: action.payload.recruitments,
        error: null
      };
    case adminRecruitmentEditTypes.FETCH_ADMIN_RECRUITMENT_EDIT_FAILURE:
      return {
        ...state,
        pending: false,
        recruitments: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
