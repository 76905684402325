import { applicationCreateTypes } from "../../ActionTypes/applicationCreateTypes";
import { FetchApplicationCreateFailure, FetchApplicationCreateFailurePayload, FetchApplicationCreateRequest, FetchApplicationCreateSuccess, FetchApplicationCreateSuccessPayload } from "../../types/type";




export const fetchApplicationCreateRequest = (body:any): FetchApplicationCreateRequest => ({
  type: applicationCreateTypes.FETCH_APPLICATION_CREATE_REQUEST,
  body,
});

export const fetchApplicationCreateSuccess = (
  payload: FetchApplicationCreateSuccessPayload,
): FetchApplicationCreateSuccess => ({
  type: applicationCreateTypes.FETCH_APPLICATION_CREATE_SUCCESS,
  payload
});

export const fetchApplicationCreateFailure = (
  payload: FetchApplicationCreateFailurePayload
): FetchApplicationCreateFailure => ({
  type: applicationCreateTypes.FETCH_APPLICATION_CREATE_FAILURE,
  payload
});
