

function Terms1() {


    return (

        <main id="terms">
        <section className="wrapper">
          <h2 className="ttl type3">パラちゃんねる 利用規約</h2>
    
    <p className="wrap" style={{marginTop: '59px' }}>
    株式会社キャリアート（以下「当社」といいます。）が運営する「パラちゃんねる」（これに関連するウェブサイトなどを含みます。以下同じ。）は、本サービス（第２条第１項で定義します。）の提供に関し、求職者会員（第２条第７項で定義します。）となる皆様の大切な個人情報をお預かりいたします。これらの情報は、多様な社会を受入れ皆様の生活を豊かにするため、または就職を希望する方および企業の双方にとって有意義な就職活動のために利用されるものです。パラちゃんねるは、皆様から得た情報を細心の注意を払って管理し、プライバシーを尊重しながら適正に取り扱うよう努めます。利用目的を越えて使用することはありません。利用目的や個人情報の取り扱い等について説明します。
    <br /><br />
    <strong>第1条（規約の適用）</strong><br />
    パラちゃんねる利用規約（求職者会員）（以下「本規約」といいます。）は、当社がパラちゃんねるにおいて提供する本サービスに利用登録申請し、当社がこれを承認した求職者会員について、本サービスを利用する際の権利義務を定めるものです。
    <br />
    <br />
    2. 当社は、本規約に基づき求職者会員に本サービスを提供するものとし、本サービスのご利用を希望される場合には、以下の全ての本規約に同意していただく必要があり、求職者会員は本規約に従い利用する義務を負います。
    <br /><br />
    <strong>第2条（定義）</strong><br />
    1. 「本サービス」<br />
    「本サービス」とは、当社が、パラちゃんねるにおいて、共生社会の実現のために多様な価値観の浸透と多様な障害者雇用の促進と就業の定着を目的に提供する「メディアサービス」（コラム・Youtubeサービス。次項で定義します。）および「就職・転職情報サービス」（求人情報提供サービスおよびスカウトサービス）、その他当社が提供する就職・転職支援サービス（第３項で定義します。）の総称をいいます。
    <br /><br />
    2. 「メディアサービス」<br />
    「メディアサービス」とは、当社が提供する会員（本条第６項で定義します。）・非会員向けのコラム・Youtubeサービスの総称をいいます。「コラム」とは、本サービスを利用する求人企業や当社の選定する専門の障害などの特性のあるライターにより私生活や仕事など多岐に渡る情報配信をするサービスです。「Youtube」とは、キャリアコンサルタントなどの資格を有する就活やキャリア支援の専門家によるアドバイスを配信する動画サービスです。コラム・YoutubeサービスはSNSによる配信サービスなどを含みます。
    <br /><br />
    3. 「就職・転職情報サービス」<br />
    「就職・転職情報サービス」とは、当社が会員を対象として運営する求人情報提供サービスおよびスカウトサービスの総称をいいます。「求人情報提供サービス」とは、就職・転職を希望する求職者会員が希望条件を登録することで、求人情報の検索・閲覧・応募ができるサービスです。「スカウトサービス」とは、求職者会員が登録した個人情報等を含むレジュメ情報（以下、「レジュメ」といいます。）を企業会員（本条第８項で定義します。）が閲覧し、企業会員から直接、求人情報を記載したメールを受けられるサービス、およびそれらに関連するサービスです。スカウトサービスは以下のサービスを含みます。
    <br /><br />
    ①	    スカウトサービス<br />
    企業会員担当者が、レジュメのうち、都道府県・市区、経験職種、希望条件、障害情報等を閲覧し、当該求職者会員に紹介できると判断した求人情報を、当該会員にメールにより送付（以下かかる求人情報の送付を「スカウトメール」といいます。）するサービスをいいます。
    <br />②自動配信メールサービス<br />
    求職者会員が本サービスの利用登録時に登録したレジュメのうち都道府県・市区、経験職種、希望条件、障害情報等をもとに自動配信される求人情報提供メールサービスをいいます。
    <br /><br />
    4. 「メッセージ機能」<br />
    「メッセージ機能」とは、求職者会員と企業会員が本サービスを通じて直接メッセージのやり取りをすることができるサービスの総称をいいます。メッセージ機能の利用に当たっては、コミュニケーションの円滑化を目的に、求職者会員も企業会員も双方に送付したメッセージについて、開封状況に関する情報を取得します。
    <br /><br />
    5. 「アピールボード」<br />
    「アピールボード」とは、就職・転職情報サービスを希望する求職者会員が、企業会員へのアピールとして自らの経験・スキル・障害特性等を登録する機能をいいます。
    <br /><br />
    6. 「会員」<br />
    「会員」とは、当社の承認を得て本サービスに利用登録した者であって、第７項および第８項の定める「求職者会員」と「企業会員」の総称です。
    <br /><br />
    7. 「求職者会員」<br />
    「求職者会員」とは、会員のうち、当社との間で本サービスを利用してレジュメを登録し、就職活動を行う求職者をいいます。
    <br /><br />
    8. 「企業会員」<br />
    「企業会員」とは、会員のうち、当社との間で本サービスを利用して求人の掲載と人材採用を行う事業者をいいます。
    <br /><br /><br />
    
    <strong>第3条（本サービスの利用）</strong><br />
    1. 求職者会員は、本サービスを利用する場合、本規約および別途定める「パラちゃんねる プライバシーポリシー」（以下「プライバシーポリシー」といいます。）の内容をすべて承諾したものとみなされます。不承諾の意思表示は、本サービスを利用しないことをもってのみ認められるものとします。不承諾の場合は本サービスを利用できません。求職者会員は、自らの意思および責任をもって本サービスを利用するものとします。
    <br />2. 本サービスは、求職者会員の就職時期または企業会員の募集条件などによって、利用できる機能に制限がある場合があります。<br /><br />
    
    <strong>第4条（求職者会員）</strong>
    <br />1.求職者会員とは、本サービスの利用を希望する者で、本規約に同意の上、「メディアサービス」においては、個人情報（プライバシーポリシーで定める定義に従います。以下同じ。）の登録を、「就職・転職情報サービス」においては、併せて資格・経験・障害特性等の就職・転職活動のために必要な情報を登録して本サービスの利用を申し込み、当社がこれを承認した者をいいます（以下、当社が本サービスの利用申し込みを承認することを「会員登録」といいます。）。また、求職者会員が本サービスに登録した情報を、以下「登録情報」といいます。
    <br />2. 本サービスの利用を希望する者は、プライバシーポリシーを確認の上、会員登録を申し込むものとします。プライバシーポリシーは本規約の一部とし、会員登録を申し込んだ時点で、プライバシーポリシーを含む本規約の内容をすべて承諾したものとみなされます。不承諾の意思表示は、会員登録を申し込まないことをもってのみ認められるものとします。
    <br />3. 本サービスの利用を希望する者は、自らの意思及び責任を持って本サービスに会員登録を申し込むものとします。求職者会員は登録情報に対し、自らその内容に関する責任を負い、本サービスの会員専用ページ（以下「マイページ」といいます。）で登録情報を確認・修正・削除することができます。
    <br />4. 第2項に基づき会員登録を希望する者が次の各号のいずれかに該当する場合、当社は会員登録を拒否することができるものとします。この場合、当社は理由を説明する義務を負わないものとします。
    <br />(1) 会員登録の申込みに関連して当社に提供された情報の全部または一部につき虚偽、誤記、不足または公序良俗に違反しもしくは第三者に不快感を与える内容があった場合
    <br />(2) 過去に本規約に違反したことがある場合
    <br />(3) その他当社が不適切と判断した場合
    <br />5. 求職者会員が本サービスの利用に当たり、本規約に違反したと当社が判断した場合、当社は、当該会員に対し、当該会員の登録情報の修正・削除などを求めることができます。この場合、当該会員は、直ちに、当社の求めに応じて、登録情報の修正・削除などをしなければならないものとします。
    <br />6. 求職者会員は、本サービスの開始に当たって設定または付与された会員ID、パスワードおよびマイページURLを自らの責任において使用・管理し、いかなる場合もそれらを第三者に使用させ、または譲渡してはならないものとします。
    <br />7. 求職者会員は登録情報その他の情報を自らの責任において保存するものとし、当社は保存義務を負わないものとします。
    <br />8. 本サービスの提供を受けるために必要な、コンピューター、スマートフォンその他の機器、ソフトウェア、通信回線その他の通信環境等は、求職者会員の費用と責任において準備し維持するものとします。当社は、本サービスがあらゆる機器等に適合することを保証するものではありません。
    <br /><br />
    <strong>第5条（会員登録および求職者会員の責任等）</strong><br />
    1. 求職者会員は、自らの意思により会員登録するものとし、その際に当社が当該求職者会員にお知らせする方法に従って本サービスを利用するものとします。
    <br />2. 求職者会員は、自らの意思および責任をもって本サービスを利用するものとします。
    <br />3. 求職者会員は、本サービスの利用にあたり正確な情報を登録するものとし、自らの登録情報および活動情報の内容について、自らがその責任を負うものとします。求職者会員の登録情報または活動情報について、不備や誤りがあったことにより当該求職者会員が損害を被った場合であっても、当社は一切その責任を負いません。
    <br />4. 求職者会員は１人につき１つの会員IDのみを保有するものとします。１人が複数の会員IDを保有すること、複数人が１つの会員IDを共同して保有することはできません。求職者会員はいかなる場合においても、会員IDを第三者に譲渡または貸与することはできません。
    <br />5. 求職者会員は、本サービスを利用するために必要なパソコンその他のあらゆる機器、ソフトウェア、通信手段を自己の責任と費用において、適切に整備するものとします。求職者会員は自己の利用環境に応じて、不正アクセスおよび情報漏洩の防止等のセキュリティ対策を講じるものとします。当社は求職者会員の利用環境について一切関与せず、また一切の責任を負いません。
    <br />6. 求職者会員はマイページにおいて、会員登録の際に入力した登録情報を変更、追加または削除することができます。また求職者会員は、マイページにおいて退会手続きを行うことにより、いつでも退会することができます。
    <br />7. 当社は、求職者会員の登録情報の全部または一部に誤りがある等の理由により、第三者から登録情報の削除等の要求があった場合には、当該求職者会員に対し、当該登録情報の全部または一部の削除等を請求できるものとし、当該求職者会員はこれに従って直ちに削除等をしなければならないものとします。
    <br />8. 求職者会員が、アピールボードに情報を登録し保存した場合、当社は、本サービス上で指定する個人情報を、企業に提供します。求職者会員は、アピールボード（求職者会員が自らの資格・スキル・自己PR等の情報を登録する機能）のスキル・自己PR欄等に、その情報のみで個人が特定できる個人情報を入力しないものとします。これらの入力内容等により、企業会員に当該求職者会員が特定された場合であっても、当社が一切の責任を負わないことにつき、予めこれを承諾するものとします。
    <br />9.  求職者会員は、当社が本サービスに関して当該会員にこれを付与しもしくは設定させたパスワード、会員ＩＤ、登録メールアドレス等（以下、あわせて「パスワード等」 といいます。）の管理責任をいかなる場合においても自己の責任として負うものとします。求職者会員は、その管理するパスワード等を第三者に利用させること、貸与、譲渡、売買、質入等することはできないものとします。パスワード等の管理不十分、使用上の過誤、第三者の使用等による損害の責任は求職者会員がすべて負うものとし、当社はいかなる場合においても責任を負いません。
    <br />10. 会員登録後、求職者会員が最後にマイページにログインした日から起算して1年の間、1度もログインしなかった場合には、当該求職者会員の意向に関わらず、会員登録が取り消される場合があります。
    <br />11. 求職者会員は、該当する求人企業に対して10日間、不採用通知、合格通知、面接調整、実習調整、合格通知などの行動を受け取らない場合、機械的に不採用通知を受信すること、およびそのダッシュボード（求職者の応募管理画面のこと）上で不採用として設定されることに同意します。
    <br />13. 当社は企業会員および求職者会員の間のコミュニケーションについて責任を負わず、そのコミュニケーションについては求人企業および会員がそれぞれ責任を負うものとします。
    <br />14. 求職者会員は、企業会員により設定された面接、実習（オンラインを含む）の間に企業会員と共有または送信したデータまたは情報を安全に保護する責任を当社が負わないことを承諾します。当社は、求職者会員が面接、実習（オンラインを含む）の利用を通じて共有または送信したデータの悪用に対する責任を負いません。
    <br />19. 当社は、面接、実習などの当事者ではなく、求職者会員と企業会員との間で行われる合意や取り決めの参加者でもありません。企業会員が会員からの配慮の依頼に対する責任を負います。
    <br />20. 求職者会員へのメール配信に際して、本サービスのメールサーバへ、受信エラー等のエラー通知が一定回数以上届いた場合には、会員の意向に関わらず、当社は、本サービスのメールを当該求職者会員に配信しないことができるものとします。
    <br />21. 当社と求職者会員との送受信メッセージのうち、過去1年分を超えるものは、本サービスのデータベース・メンテナンスなどの際に抹消されることがあります。求職者会員は、必要な情報は自己の責任において本サービスのサーバー以外の場所に保存するものとします。
    <br />22. スカウトサービスの利用について、一定期間以上継続意思確認が取れない場合には、事前の通知なく、紹介の対象から外れることがあります。
    <br />23. 求職者会員は、本サービスの利用の開始により、当社より当社が運営するその他サービスの案内通知を受け取ることがあることを予め承諾します。ただし、当該個別サービスの利用を開始するためには、別途当社が定める手続きに従ってお申し込み手続きを行って頂く必要があります。
    <br />24. 求職者会員が退会した後、再度本サービスを利用することを希望する場合であっても、退会前の登録情報は利用できません。
    <br /><br />
    <strong>第6条（報告）</strong><br />
    1. 求職者会員は、当社から採用状況についての照会が行われることを承諾し、当社の照会に対し速やかに回答するものとします。<br /><br />
    
    <strong>第7条（会員の禁止事項）</strong><br />
    求職者会員は、次の各号の行為をしないものとします。かかる行為により当社に生じた一切の損害について、求職者会員は賠償する責任を負担し、当社は民事および刑事の一切の法的手段をとることができます。
    <br />(1) 意図的に虚偽の情報を登録・表示する行為
    <br />(2) 登録情報の全部または一部について、本人以外の情報の登録を行う行為
    <br />(3) 著作権、商標権、プライバシー権、氏名権、肖像権、名誉等の他人の権利を侵害する行為
    <br />(4) 個人や団体を差別、批判、攻撃、誹謗中傷する行為
    <br />(5) 政治活動、選挙活動、宗教活動またはそれらに類する行為
    <br />(6) 法令違反、犯罪行為、または公序良俗に反する行為、またはそのおそれのある行為
    <br />(7) 当社または第三者に不利益を与える行為
    <br />(8) 営業活動等営利を目的とした情報提供等の行為およびその行為を意図して行う登録
    <br />(9) 本サービスの運営を妨げる行為、または当社の信頼を毀損する行為
    <br />(10) 当社または企業会員から配信されたメールを第三者に転送する行為
    <br />(11) 本サービスにおいて提供される企業情報その他本サービスによりまたはこれに関連して取得した情報および本サービスのコンテンツ等の全部または一部を無断で複製、編集、公表、開示等する行為
    <br />(12) 前各号に類する行為のほか、当社が不適切と判断する行為
    <br /><br />
    <strong>第8条（提供情報等に関する非保証）</strong>
    <br />1.本サービスにおいて提供される情報（企業会員にかかる会社情報、コラム・Youtube、広告等その他第三者により提供される情報を含みます。）、および求職者会員が企業会員から提供される情報は、当該企業会員または第三者の責任で提供されるものであり、求職者会員は、提供情報の最新性、真実性、合法性、安全性、適切性、有用性、および採用の確実性について、当社が何ら保証しないことを承諾のうえ、自らの責任において利用するものとします。
    <br />2.当社は、①本サービスの提供に不具合やエラーや障害が生じないこと、②本サービスから得られる情報等が正確なものであること、③本サービスおよび本サービスを通じて入手できる商品、役務、情報などが求職者会員の期待を満たすものであること、のいずれについても保証するものではありません。
    <br />3. 求職者会員は、自己の電子メール、当該求職者会員が作成した登録情報やエントリーその他を通じて送信したメッセージの内容について、自ら責任を負い、当社がそれらの内容（内容が適法であるか否かも含みます。）について、責任を負わず、すべての責任を負わないことに同意します。
    <br />4. 当社は、企業会員のメールまたはメッセージ機能による送信または求職者会員による受信を保証するものではなく、また、データの送信または保存において誤りがないことも保証しません。メッセージ送信先のアカウントが停止されている場合、そのメッセージは配信できません。
    <br />5.当社は、第三者が本サービス以外の方法または態様で、本サービス上で提供される情報の転載を行った場合、その転載が本サービスからの転載であることが会員に識別できるか否かを問わず、当該転載情報についての、最新性、真実性、合法性、安全性、適切性、有用性について何ら保証するものではありません。
    <br />6. 当社は、本サービスについて、採用の確実性、求人企業からオファーまたは求人情報の紹介があること、企業会員に対する推薦を受けられること、企業会員に採用されること、その他本サービスの効果一切について、その確実性を保証するものではありません。
    <br />7. 本サービスに関して求職者会員と第三者との間でなんらかの紛争が発生した場合には、当該求職者会員の費用と責任において問題を解決するとともに、当社になんらの迷惑または損害を与えないものとします。
    <br /><br />
    <strong>第9条（不可抗力に関する非保証）</strong><br />
    当社は通常講ずるべきウィルス対策では防止できないウィルス被害、天変地異による被害、予防措置を講じたにも関わらず発生したシステム障害による被害、その他当社の責によらない事由（以下「不可抗力」といいます。）に起因して求職者会員に生じたすべての損害につき、一切責任を負わないものとします。当社はこれらの不可抗力によって、本サービスにおけるデータが消去・変更されないことを保証致しません。求職者会員は、本サービスにおけるデータを自己の責任において保存するものとします。
    <br /><br />
    <strong>第10条（当社の責任）</strong><br />
    1. 会員登録および求職者会員による本サービスの利用（これらに付随する当社または企業会員・第三者等の情報提供行為等を含みます。）、その他本サービスを通じてアクセスできる企業等が提供するサイトおよびサービスにより会員に生じる一切の損害（精神的苦痛、傷害、金銭的損失を含む一切の不利益をいいます。）につき、当社は、当社に過失がない限り責任を負わないものとします。また、当社が責任を負う場合であっても、当社に故意または重過失がない限り当社の責任は直接かつ通常の損害に限られるものとします。
    <br />2. 求職者会員が、本サービスにおける当社または求人企業とのメッセージ送受信機能を利用する際に、電子ファイルが添付され、当該添付ファイルによって当該求職者会員に損害が発生した場合においても、当社は一切責任を負わないものとします。
    <br /><br />
    <strong>第11条（本サービスの一次的な中止、変更、中断または終了）</strong><br />
    1. 当社は、次の各号に掲げる場合、本サービスの全部または一部を、求職者会員への予告なく一時的に中止または変更することができ、これに起因して当該求職者会員に損害が発生した場合でも、当社は、当社に故意または重過失がない限り責任を負わないものとします
    <br />(1) 定期的または緊急に、本サービスの提供のためのシステムの保守または点検を行う場合
    <br />(2) 火災、停電、天災地変等の非常事態により、本サービスの提供が困難または不能となった場合
    <br />(3) 戦争、内乱、暴動、騒擾、労働争議等により、本サービスの提供が困難または不能となった場合
    <br />(4) 本サービスの提供のためのシステムの不良および第三者からの不正アクセス、コンピューターウイルスの感染等により本サービスの提供が困難または不能となった場合
    <br />(5) 法令等に基づく措置により、本サービスの提供が困難または不能となった場合
    <br />(6) その他当社がやむを得ないと判断した場合
    <br />2. 当社は2週間の予告期間をもって求職者会員に通知の上、本サービスの提供を長期的に中断または終了することができます。
    <br />3. 当社は、本サービスの一時的な中止、変更、中断または終了により、本サービスにおいて提供される情報が消去・変更されないことを保証するものではなく、求職者会員は、かかる情報を自らの責任において保存するものとします。
    <br /><br />
    <strong>第12条（知的財産権）</strong><br />
    1.     本サービスに関する全てのコンテンツの著作権、商標権、意匠権その他の知的財産に関する権利（以下「知的財産権」といいます。）は、当社に帰属するものとし、著作権法、商標法、意匠法等により保護されます。ただし、会員に権利が帰属するものについてはこの限りではなく、会員は、当社が、本サービスにおいて利用すること、当該利用にあたり当社が当該権利にかかる著作物を自由に複製・改変・削除等できることについて予め承諾するものとし、著作者人格権を有する場合でも、これを行使しないものとします。会員は、かかる著作物に含まれる権利（知的財産権、肖像権等を含みますが、これらに限られません。）を有する第三者から、予め本サービスでの利用にかかる許諾を得るとともに、当該利用に必要な権利処理の一切を行うものとします。なお、かかる著作物に関連して第三者から要求等がなされた場合、会員の責任において対応するものとし、当社は一切の責任を負わないものとします
    <br />2. 当社は、本サービスに関して会員がパラちゃんねるその他の関連ウェブサイト上に自ら投稿した意見や情報等の内容を当社著作物等に転載することができるものとし、会員はこれを予め承諾するものとし、当社が加工・作成した情報等に関する著作権その他一切の権利は、当社に帰属します。
    <br /><br />
    <strong>第13条（会員へのアンケート含む提供情報等）</strong><br />
    1.求職者会員は、当社が求職者会員に対して実施するアンケート等（以下「アンケート」といいます）に対する意見や情報等の提供について、自らの意思および責任をもってこれに対応するものとします。
    <br />2.当社は、アンケート結果の内容につき、当社が編集発行する情報媒体に求職者会員の事前の承諾なくして編集および転載することができます。この場合、転載したアンケート結果の内容の著作権はすべて当社に帰属するものとし、求職者会員はこれらのアンケートの結果内容について著作者人格権を行使しないものとします。
    <br />3.当社は、求職者会員に対して、本サービスの品質向上、会員の転職活動のサポート、本サービスによる効果の捕捉（以下「効果捕捉」といいます。）を確認する等の目的で、レジュメを基にアンケートを送付すること、および電話またはメールを送付してヒアリングを行うことができるものとします。
    <br />4.当社は、前項によって求職者会員から取得した情報を、本サービスの品質向上、当該求職者会員の転職活動のサポート、効果捕捉、企業会員への事実の照会等を目的として利用できるものとします。
    <br />5. 本サービスの一部として提供されるメッセージ機能を利用する場合には、求職者会員は自らの責任で使用することとし、その利用により当該求職者会員が損害を被った場合であっても、当社は一切その責任を負いません。
    <br /><br />
    <strong>第14条（個人情報・登録情報・活動情報等の取り扱い）</strong><br />
    1. 当社は、本サービスを利用する者（会員以外の者を含みます。以下「ユーザー」といいます。）のプライバシーを尊重し、個人情報の管理に細心の注意を払い、別途定めるプライバシーポリシーに従ってユーザーの個人情報を取り扱うものとします。
    <br />2. 当社は、登録情報および活動情報に加えて、求職者会員の就職活動に関して企業会員が保有する情報（インターンシップや会社説明会への参加、面接といった会員の就職活動のプロセスにおいて当該企業が取得した情報の他、当該企業が採用した後に人事管理のために取得した会員に関する情報を含み、以下「企業保有情報」といいます。この企業保有情報には、例えば、会員の内定や内定辞退に関する情報、入社後の勤務等に関する情報が含まれますが、これらに限られません。）について、当該企業会員から提供を受けることがあります。当社は、登録情報、活動情報および企業保有情報を、秘密として厳重かつ適正に取り扱うものとし、当該求職者会員および当該企業会員の同意を得た場合を除き、第三者に開示または漏洩しないものとします。
    <br />3.当社は、会員登録・抹消の前後を問わず、本サービスにおける求職者会員の登録情報等、本サービスの利用履歴、およびこれら個人を特定し、または特定しないで解析した後、個人を識別・特定できないように加工、集計および分析した統計データ、属性情報等を作成し、当該解析結果、利用履歴、統計データ、属性情報等を、何らの制限なく利用（求人企業へ閲覧・提案、市場の調査、本サービスの機能改善、新サービスの開発を含みますが、これらに限られません。）することができるものとし、求職者会員はこれをあらかじめ承諾します。
    <br />4.当社は、求職者会員が企業会員に自らの個人情報を開示した時点以降、当該企業会員から、当該求職者会員の転職活動の進捗状況について報告（採否、および採用条件を含みます。）を受けることができ、求職者会員はこれをあらかじめ承諾します。
    <br />5. 前項にかかわらず、特定の企業等の委託を受けまたは当社の裁量で、当社は、求職者会員の登録情報、活動情報および企業保有情報を閲覧または使用して、求職者会員に対し、当該特定の企業等または当社が企画する各種イベント、会社説明会、求人広告または各種情報の提供をすることがあります。
    <br />6. 当社は、登録情報、活動情報、企業保有情報等を集計・分析し、個人を特定できないように加工した属性情報等の統計データとして、何らの制限なく利用することができるものとします。なお、当該利用には、企業等への各種提案（採用方針の決定または人材要件の定義等の各種サポートを目的とする提案を含みますが、これらに限られません。）および各種報告、ならびに市場調査、統計処理および機械学習、ならびに本サービスおよび当社の新サービス・他サービスの検討、改善、拡張および開発等のために行われる利用を含みます。
    <br />7. 当社は、本サービスの全部または一部を第三者に委託できるものとし、求職者会員は、かかる委託を承諾するものとします。
    <br /><br />
    <strong>第15条（クッキーの使用）</strong><br />
    1. 当社のホームページにおいて、ユーザーへのサービスの提供、トラフィック情報の検証等のため、ユーザーのコンピューターにクッキー（Cookie）と呼ぶ情報を送る場合があります。この情報はユーザーのコンピューターのハードディスクまたはメモリーに保存されます。クッキーの情報は当社HPのマーケティング分析や各種サービス提供に限定して使用いたしますので、ユーザーのプライバシーを侵害することはありません。
    <br /><br />
    <strong>第16条（反社会的勢力の排除）</strong><br />
    1.	    求職者会員は、現在、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下これらを「暴力団員等」といいます。）に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。
    <br />(1) 暴力団員等が経営を支配していると認められる関係を有すること
    <br />(2) 暴力団員等が経営に実質的に関与していると認められる関係を有すること
    <br />(3) 自己もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
    <br />(4) 暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること
    <br />(5) 役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
    <br />2. 求職者会員は、自らまたは第三者を利用して次の各号の一にでも該当する行為を行わないことを確約するものとします。
    <br />(1) 暴力的な要求行為
    <br />(2) 法的な責任を超えた不当な要求行為
    <br />(3) 取引に関して、脅迫的な言動をし、または暴力を用いる行為
    <br />(4) 風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為
    <br />(5) その他前各号に準ずる行為
    <br /><br />
    <strong>第17条（会員の退会・除名）</strong><br />
    1. 求職者会員は、本サービスより自由に退会できるものとします。退会手続きは、求職者会員自らがマイページ上で行うものとします。
    <br />2. 当社は、求職者会員が本規約に違反したと判断した場合、当該求職者会員に対し事前に通知することなく、当該求職者会員の本サービスの全部または一部の利用を一時中止し、または、会員登録を抹消して本サービスから除名することができます。また、本規約違反の態様によっては、当社はその裁量で、求職者会員に対し事前に通知することなく、当該求職者会員から企業会員に対する応募またはお問い合わせを無効とし、当該会員がスカウトメールを受ける設定をしているときは、これを将来に向けて無効とする、企業会員から求職者会員へのメッセージ、求職者会員から企業会員に対する返信または当社に対する問い合わせを無効とする等、その他当社が必要と認める一切の措置を講ずることができることとします。
    <br />3. 当社が前項に定める措置をとったことにより求職者会員に損失が生じたとしても、当社は、当該求職者会員に対し何らの責任も負わないものとします。
    <br /><br />
    <strong>第18条（紛争の解決）</strong><br />
    求職者会員は、本規約に違反することにより当社が第三者との間の紛争に巻きこまれた場合、自らの責任と費用をもって当該第三者との紛争解決を図るものとします。また、求職者会員は、本サービスの利用に際し、本規約に違反することにより当社に対して損害を与えた場合、直接・間接を問わず一切の損害（直接または間接を問わず、また、合理的な弁護士費用を含むものとします。）の賠償を行うものとします。
    <br /><br />
    <strong>第19条（免責）</strong><br />
    1. 求職者会員は、自己責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為およびその結果について当社は一切の責任を負いません。
    <br />2. 当社は、求職者会員が本サービスを通じて知ったまたは知り得た求人情報等の情報および企業会員の言動、行動について、合法性、道徳性、信頼性、正確性を一切保証しません。求職者会員はこれを了承し、自己の責任において本サービスを利用するものとします。
    <br />3. 当社は、求人情報に関する個別の雇用契約等の仲介や、当該契約上生じる争議に関して、一切関与しないものとします。
    <br />4. 当社は、本サービスの内容についての技術的不正確さや誤字、誤植がないこと、不具合や障害が生じない事を一切保証しません。
    <br />5. 本サービスの提供情報からリンクしている外部サイトは、当社が管理運営するものではなく、その内容の合法性、道徳性、信頼性、正確性やサイトの変更、更新などに関して、当社は一切責任を負わないものとします。 また、外部サイトが本システムの提供情報からリンクしていることは、当社と当該外部サイトとの間になんらかの契約関係または商業的関係があることを含意するものではありません。
    <br />6. 当社は、本サービスを利用することによって生じた、求職者会員その他第三者との間の紛争等について一切責任を負わないものとします。
    <br />7. 当社は、求職者会員が本サービスを利用したことまたは利用できなかったこと、会員が本サービスを通じて取引を行い、情報を入手しもしくは役務を代替させるために費用を要したこと、会員の送信（発信）、データへの不正アクセスや不正な改変がなされたこと、本サービス内における発言、送信（発信）や行為、その他本サービスに関連する事項に起因または関連して会員に生じた一切の損害について、一切の責任を負いません。
    <br />8. レジュメ等の情報に関する責任は、求職者会員自身にあります。当社は求職者会員が投稿したレジュメ等の情報の内容について、その合法性、道徳性、信頼性、正確性その他について一切責任を負いません。
    <br />9. 求職者会員が第三者の名誉を毀損した場合、プライバシー権を侵害した場合、許諾なく第三者の個人情報を開示した場合、著作権法に違反する行為を行った場合その他、第三者の権利を侵害した場合には、当該求職者会員は自身の責任と費用において解決しなければならず、当社は一切の責任を負いません。
    <br />10. 当社は、求職者会員の投稿したレジュメ等の情報、履歴等の情報の内容を閲覧する権限を有します。ただし、当社はその義務を負うものではありません。また当社は、それによって生じたいかなる損害についても、一切責任を負いません。
    <br />11. 求職者会員は、本サービスを利用して投稿したレジュメ等の情報、履歴等の情報について当社に保存義務がないことを認識し、必要な情報については適宜バックアップをとるものとします。
    <br />12. 当社は、本サービスの運営上必要と判断した場合は、求職者会員がアップロードしたファイルを自由に閲覧、削除する権限を有します。ただし、当社はその義務を負うものではありません。また当社は、それによって生じたいかなる損害についても、一切責任を負いません。
    <br />13. 求職者会員は、本サービスまたは本サービスにより知った情報を利用する場合、利用される国、地域における法令を遵守しなければならず、当社は会員の法令違反につき一切の責任を負いません。
    <br />14. 当社は、求職者会員の通信や活動、企業会員とのやりとり、取引にともない発生した紛争その他の問題には一切の責任を負いません。万一、会員間の紛争があった場合でも、当該会員間で解決するものとし、当社は一切関与しません。
    <br />15. 当社は、理由の如何を問わず、本サービスの追加、変更、中断、終了、不具合等につき一切の責任を免除され、求職者会員になんらかの不利益および損害（本サービスの利用機会の喪失その他の不利益および損害、その他一切の不利益および損害）が生じたとしても、当社は一切責任を負わず、本サービスのデータに関する補償等も行いません。
    <br />16. 当社は、本規約またはその他の利用規約等に違反する行為またはそのおそれのある行為が行われたと判断した場合には、当該行為を行った求職者会員の除名、レジュメ等の情報の全部もしくは一部の削除、および公開範囲の変更等を行う場合がありますが、それによって生じたいかなる損害についても、一切責任を負いません。
    <br />17. 当社は、求職者会員が本サービス外で会員同士が集まるための会合の告知を行い、広く参加者を集めるなどした場合につき、一切責任を負いません。
    <br />18. 当社が求職者会員からのメール、口頭等の方法による依頼により、当社がレジュメ等の情報入力の代行業務をおこなった場合であっても、当社の責任は、当社が故意又は重大な過失によりメール、口頭などによる依頼の内容と入力内容が異なることにより生じた損害（直接的損害に限り、間接的損害などは含みません。）に限られるものとします。
    <br />19. 本利用規約又はその他の利用規約等が消費者契約法（平成12年法律第61号）第2条第3項の消費者契約に該当し、かつ、当社が債務不履行又は不法行為に基づき損害賠償責任を負う場合については、当社に故意又は重大な過失がある場合を除いて、当社は、ユーザーが直接かつ現実に被った損害を上限として損害賠償責任を負うものとし、特別な事情から生じた損害等（損害発生につき予見し、または予見し得た場合を含む。）については責任を負わないものとします。
    <br /><br />
    <strong>第20条（会員規約・利用規約の変更）</strong><br />
    1. 当社は、次の各号のいずれかに該当する場合、会員規約を随時変更することができるものとします。
    <br />(1) 会員の一般の利益に適合する場合
    <br />(2) 本サービスの提供の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更にかかる事情に照らして合理的なものである場合
    <br />2. 変更の内容は、本サービス上に1ヵ月表示した時点で効力が発生し、効力発生日時点において会員登録を維持するすべての会員は、規約変更を承諾したものとみなします。
    <br /><br />
    <strong>第21条（分離条項）</strong><br />
    本規約の一部の効力が、法令や確定判決により無効とされた場合であっても、その他の条項は引き続き効力を有するものとします。
    <br /><br />
    <strong>第22条（準拠および管轄）</strong><br />
    本サービスおよび本規約の準拠法は日本法とし、本サービスまたは本規約に関して生じる一切の紛争については、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
    </p>
    <p className="left">
    付則<br/>
    2021年12月1日 適用<br/>
    2022年1月10日 改訂
    </p>
    
        </section>
      </main>
       
    );
  }
  
  export default Terms1;