import { DownloadResumeTypes } from "../../ActionTypes/downloadResumeTypes";
import { FetchDownloadResumeFailure, FetchDownloadResumeFailurePayload, FetchDownloadResumeRequest, FetchDownloadResumeSuccess, FetchDownloadResumeSuccessPayload } from "../../types/type";

export const fetchDownloadResumeRequest = (applicantId: any): FetchDownloadResumeRequest => ({
  type: DownloadResumeTypes.FETCH_DOWNLOAD_RESUME_REQUEST,
  applicantId
});

export const fetchDownloadResumeSuccess = (
  payload: FetchDownloadResumeSuccessPayload,
): FetchDownloadResumeSuccess => ({
  type: DownloadResumeTypes.FETCH_DOWNLOAD_RESUME_SUCCESS,
  payload
});

export const fetchDownloadResumeFailure = (
  payload: FetchDownloadResumeFailurePayload
): FetchDownloadResumeFailure => ({
  type: DownloadResumeTypes.FETCH_DOWNLOAD_RESUME_FAILURE,
  payload
});
