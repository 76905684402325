import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import { RootState } from '../../../redax/reducers/rootReducer';
import Loader from '../../loader/loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { fetchAdminOpenJobRegionsRequest } from '../../../redax/actions/adminOpenJobRegionsActions/adminOpenJobRegionsActions';
import { fetchAdminCompanyAllListRequest } from '../../../redax/actions/adminComapnyAllListActions/adminComapnyAllListActions';
import { fetchPostCodeRequest } from '../../../redax/actions/postcodeActions/postcodeActions';
import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';
import { fetchAdminRecruitmentPostRequest } from '../../../redax/actions/adminRecruitmentPostActions/adminRecruitmentPostActions';
import { fetchAdminRecruitmentJobOfferRequest } from '../../../redax/actions/adminRecruitmentJobOfferActions/adminRecruitmentJobOfferActions';
import { fetchRegionsRequest } from '../../../redax/actions/regionsActions/regionsActions';
import { fetchAdminApplicantsRegionsRequest } from '../../../redax/actions/adminApplicantsRegionsActions/adminApplicantsRegionsActions';
import { fetchAdminApplicantsPostRequest } from '../../../redax/actions/adminApplicantsPostActions/adminApplicantsPostActions';
import { fetchAdminApplicantsDetailRequest } from '../../../redax/actions/adminApplicantsDetailActions/adminApplicantsDetailActions';


const AdminApplicantsEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const loginData = useSelector((state: RootState) => state.login);
  const postcodeData = useSelector((state: RootState) => state.postcode);
  const adminApplicantsPost = useSelector((state: RootState) => state.adminApplicantsPost);
  const adminApplicantsDetail = useSelector((state: RootState) => state.adminApplicantsDetail);

  const [loadScreen, setLoadScreen] = useState(false);
  const [tempolary, settempolary] = useState(false);
  const [family_name, setFamilyName] = useState<string | null>(null);
  const [first_name, setFirstName] = useState<string | null>(null);
  const [family_name_kana, setFamilyNameKana] = useState<string | null>(null);
  const [first_name_kana, setFirstNameKana] = useState<string | null>(null);
  const [postCode, setPostCode] = useState<string | null>(null);
  const [address, setAddress] = useState('');
  const [mobile_number, setMobileNumber] = useState('');
  const [prefectureName, setPrefectureName] = useState(String);
  const [applicantPrefectureName, setApplicantPrefectureName] = useState(String);
  const [cityName, setCityName] = useState(String);
  const [applicantCityName, setApplicantCityName] = useState(String);
  const [face_photo, setFacePhoto] = useState<any>(null);
  const [face_photo1, setFacePhoto1] = useState<any>(null);
  const [isBirthdayOpen, setBirthdayOpen] = useState(false);
  const [isNewGraduate, setNewGraduate] = useState(false);
  const [email, setEmail] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  const [authenticate, setAuthenticate] = useState(null);
  const [btn, setBtn] = useState(false);
  const [lostPost, setLoadPost] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [errorPostCode, setErrorPostCode] = useState(false);  
  const [errorFamilyName, setErrorFamilyName] = useState(false);
  const [errorFamilyNameKana, setErrorFamilyNameKana] = useState(false);
  const [errorFamilyNameMaxlength, setErrorFamilyNameMaxLength] = useState(false);
  const [errorFamilyNameKanaMaxlength, setErrorFamilyNameKanaMaxLength] = useState(false);
  const [errorFamilyNameKanaCheck, setErrorFamilyNameKanaCheck] = useState(false);
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorFirstNameKana, setErrorFirstNameKana] = useState(false);
  const [errorFirstNameMaxlength, setErrorFirstNameMaxLength] = useState(false);
  const [errorFirstNameKanaMaxlength, setErrorFirstNameKanaMaxLength] = useState(false);
  const [errorFirstNameKanaCheck, setErrorFirstNameKanaCheck] = useState(false);
  const [errorPostcodeMinMaxLength, setErrorPostcodeMinMaxLength] = useState(false);
  const [errorMobileNumber, setErrorMobileNumber] = useState(false);
  const [mobileNumericError, setMobileNumericError] = useState(false);
  const [mobileMinLengthError, setMobileMinLengthError] = useState(false);
  const [mobileMaxLengthError, setMobileMaxLengthError] = useState(false);
  const [birthday, setBirthday] = useState('');
  const [finalEducationData, setFinalEducationData] = useState<any[]>([]); 
  const [selectedFinalEducationId, setSelectedFinalEducationId] = useState(null);
  const [jobCareerCountId, setJobCareerCountId] = useState<any>(null);
  const [selectedGraduationDate, setSelectedGraduationDate] = useState<any>(null);
  const [minYear, setMinYear] = useState<any>(null);
  const [maxYear, setMaxYear] = useState<any>(null);
  const [baseMonth, setBaseMonth] = useState<any>(null);
  const [baseMonthThanAgo, setBaseMonthThanAgo] = useState(false);
  const [onlyPast, setOnlyPast] = useState(false);
  const [enableNone, setEnableNone] = useState(false);
  const [gender, setGender] = useState(null); 
  const [selectedFinalEducationName, setSelectedFinalEducationName] = useState<any>(null);
  const [navigation_book, setNavigationBook] = useState<any>(null); 
  const [edited, setEdited] = useState(false); 
  let mobileMinLen = 10;
  let mobileMaxLen = 11;
  // let BirthDay: any;
  const [years, setYears] = useState<any[]>([]);
  const [months, setMonths] = useState<any[]>([]); 
  const [days, setDays] = useState<any[]>([]); 
  const [filterYears, setFilterYears] = useState<any[]>([]);
  const [filterMonths, setFilterMonths] = useState<any[]>([]);
  const [year, setYear] = useState<any>(null);
  const [month, setMonth] = useState(null);
  const [graduationYear, setGraduationYear] = useState<any>();
  const [graduationMonth, setGraduationMonth] = useState<any>();
  const [day, setDay] = useState(null);
  const adminApplicantsRegions = useSelector((state: RootState) => state.adminApplicantsRegions);
  const { postcode } = useSelector((state: RootState) => state.postcode);
  const [firstLoad, setFirstLoad] = useState(false);
  const [nowage, setNowage] = useState('');
  const [areaText, setAreaText] = useState('');
  const [prefectureText, setPrefectureText] = useState('');
  const [cityText, setCityText] = useState('');
  const [locationAddress, setLocationAddress] = useState('');
  const [locationRegionId, setLocationRegionId] = useState(null);
  const [locationPrefectureId, setLocationPrefectureId] = useState(null);
  const [locationCityId, setLocationCityId] = useState(null);
  const [locationNearestStation, setLocationNearestStation] = useState('');
  const [emailPassword, setEmailPassword] = useState('');
  const [finalEducations, setFinalEducations] = useState<any[]>([]);
  const [handicaptypes, setHandicaptypes] = useState<any[]>([]);
  const [disabilityCertificate, setDisabilityCertificate] = useState<any[]>([]);
  const [handicaps, setHandicaps] = useState<any[]>([]);
  const [considerationConditionsRequiredExists, setConsiderationConditionsRequiredExists] = useState(false);
  const [considerationConditions, setConsiderationConditions] = useState<any[]>([]);
  const [selectedConsiderationConditions, setSelectedConsiderationConditions] = useState<any[]>([]);
  const [selectedConsiderationConditionsPrefer, setSelectedConsiderationConditionsPrefer] = useState<any[]>([]);
  const [considerationConditionsSupplemental, setConsiderationConditionsSupplemental] = useState('');
  const [considerationConditionsPreferSupplemental, setConsiderationConditionsPreferSupplemental] = useState('');
  const [employmentSupportAgencyStartYear, setEmploymentSupportAgencyStartYear] = useState('');
  const [employmentSupportAgencyStartMonth, setEmploymentSupportAgencyStartMonth] = useState('');
  const [navigationBookURL, setNavigationBookURL] = useState<string | null>(null);
  const [navigationBookName, setNavigationBookName] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const fileInputRefImage = useRef<HTMLInputElement | null>(null);
  const [jobCareerCounts, setJonCareerCounts] = useState<any[]>([]);
  const [jobCareerRecentArrivalYear, setJobCareerRecentArrivalYear] = useState<string | null>(null);
  const [jobCareerRecentArrivalMonth, setJobCareerRecentArrivalMonth] = useState<string | null>(null);
  const [jobCareerRecentDepartureYear, setJobCareerRecentDepartureYear] = useState<string | null>(null);
  const [jobCareerRecentDepartureMonth, setJobCareerRecentDepartureMonth] = useState<string | null>(null);
  const [industries, setIndustries] = useState<any[]>([]);
  const [jobCareerAppeals, setJobCareerAppeals] = useState<any[]>([]);
  const [jobCategory, setJobCategory] = useState<any[]>([]);
  const [oaSkillsData, setOaSkillsData] = useState<any[]>([]);
  const [qualifications, setQualifications] = useState<any[]>([]);
  const [languages, setLanguages] = useState<any[]>([]);
  const [levels, setLevels] = useState<any[]>([]);
  const [isLanguageFirst, setIsLanguageFirst] = useState(false);
  const [isLanguageSecond, setIsLanguageSecond] = useState(false);
  const [isLanguageThird, setIsLanguageThird] = useState(false);
  const [searchPolicies, setSearchPolicies] = useState<any[]>([]);
  const [workStyles, setWorkStyles] = useState<any[]>([]);
  const [selectedWorkStyles, setSelectedWorkStyles] = useState<any[]>([]);
  const [prefectures, setPrefectures] = useState<any[]>([]);
  const [commutingMethods, setCommutingMethods] = useState<any[]>([]);
  const [employmentStatuses, setEmploymentStatuses] = useState<any[]>([]);
  const [annualIncomes, setAnnualIncomes] = useState<any[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [joinMonths, setJoinMonths] = useState<any[]>([]);
  const [appealItems, setAppealItems] = useState<any[]>([]);
  const [specialContent, setSpecialContent] = useState('');
  const [postCodeDataChk, setPostCodeDataChk] = useState(false);
  const { id } = useParams();
  const [putBtn, setPutBtn] = useState(false);
  const [jobCareerDate, setJobCareerDate] = useState<any[]>([{
    arrival_year:null,
    arrival_month:null,
    departure_year:null,
    departure_month:null,
  }]);
  const [handicapQualifications, setHandicapQualifications] = useState<any>([{
    handicap_id: null,
    handicap_type: null,
    disability_certificate_status_id: null,
    disability_certificate: null,
    process: null,
    characteristics_and_countermeasures: null,
  }]);

  const [employmentSupportAgency, setEmploymentSupportAgency] = useState<any>([{
    supportAgencyExists: null,
    organization: null,
    contactNumber: null,
    url: null,
    canContact: null,
    startDate: null,
    endDate: null,
    trainingContent: null,
  }]);

  const [jobCareerRecent, setJobCareerRecent] = useState<any>([{
    company: null,
    arrivalDate: null,
    departureDate: null,
    industryId: null,
    jobCategoryId: null,
    businessContent: null
  }]);

  const [experienceJobCategory, setExperienceJobCategory] = useState<any>([{
    jobCategoryId_1: null,
    jobCategoryId_2: null,
    jobCategoryId_3: null
  }]);

  const [oaSkills, setOaSkills] = useState<any>([{
    wordExperience: true,
    word: [],
    excelExperience: true,
    excel: [],
    design: [],
    program: []
  }]);

  const [qualification, setQualification] = useState<any>([{
    qualificationId_1: null,
    qualificationId_2: null,
    qualification3: null,
  }]);

  const [selfPr, setSelfPr] = useState<any>([{
    content: null,
  }]);

  const [languageStudy, setLanguageStudy] = useState<any>([{
    languageId_1: null,
    levelId_1: null,
    languageId_2: null,
    levelId_2: null,
    language3: null
  }]);

  const [preferred, setPreferred] = useState<any>([{
    prefectureId_1: null,
    prefectureId_2: null,
    industryId_1: null,
    industryId_2: null,
    jobCategoryId_1: null,
    jobCategoryId_2: null,
    employmentStatusId: [],
    annualIncomeId: null,
    joinMonthId: null,
    searchPolicyId: null,
    commutingMethods: [],
    workingHourId: null,
    workFromHomeId: null,
    nationwide: false
  }]);

  const [appealItem, setAppealItem] = useState<any>([{
    appealItemId_1: null,
    appealItemId_2: null,
    appealItemId_3: null
  }]);


useEffect(() => {
    if(adminApplicantsRegions.pending) {
        setFinalEducations(adminApplicantsRegions.regions.final_educations);
        setHandicaptypes(adminApplicantsRegions.regions.handicap_types);
        setConsiderationConditions(adminApplicantsRegions.regions.consideration_conditions);
        setJonCareerCounts(adminApplicantsRegions.regions.job_career_counts);
        setIndustries(adminApplicantsRegions.regions.industries);
        setJobCategory(adminApplicantsRegions.regions.job_categories);
        setOaSkillsData(adminApplicantsRegions.regions.oa_skills);
        setQualifications(adminApplicantsRegions.regions.qualifications);
        setLanguages(adminApplicantsRegions.regions.languages);
        setLevels(adminApplicantsRegions.regions.levels);
        setSearchPolicies(adminApplicantsRegions.regions.search_policies)
        setWorkStyles(adminApplicantsRegions.regions.work_styles);
        setPrefectures(adminApplicantsRegions.regions.prefectures);
        setCommutingMethods(adminApplicantsRegions.regions.commuting_methods);
        setEmploymentStatuses(adminApplicantsRegions.regions.employment_statuses);
        setAnnualIncomes(adminApplicantsRegions.regions.annual_incomes);
        setJoinMonths(adminApplicantsRegions.regions.join_months);
        setAppealItems(adminApplicantsRegions.regions.appeal_items);
    }
}, [adminApplicantsRegions]);


useEffect(() => {
    if(adminApplicantsDetail.pending) {
        setFacePhoto(adminApplicantsDetail.applicant.face_photo);
        setFamilyName(adminApplicantsDetail.applicant.family_name);
        setFirstName(adminApplicantsDetail.applicant.first_name);
        setFamilyNameKana(adminApplicantsDetail.applicant.family_name_kana);
        setFirstNameKana(adminApplicantsDetail.applicant.first_name_kana);
        if(adminApplicantsDetail.applicant.birthday && adminApplicantsDetail.applicant.birthday !== ''){
           const arr = adminApplicantsDetail.applicant.birthday.split('-');
            setYear(arr[0]);
            setMonth(arr[1]);
            setDay(arr[2]);
        }
        setGender(adminApplicantsDetail.applicant.gender_id);
        if(!postCodeDataChk) {
            setPostCode(adminApplicantsDetail.applicant.postcode);
            setLocationAddress(adminApplicantsDetail.applicant.address);
            setLocationCityId(adminApplicantsDetail.applicant.city_id);
            setLocationPrefectureId(adminApplicantsDetail.applicant.prefecture_id);

            let textAreaKeyUp = adminApplicantsDetail.applicant.prefecture_name + adminApplicantsDetail.applicant.city_name;
            if(textAreaKeyUp) {
                setAreaText(textAreaKeyUp);
            }
        }
        
        setMobileNumber(adminApplicantsDetail.applicant.mobile_number);
        setEmail(adminApplicantsDetail.applicant.email);
        setNewGraduate(adminApplicantsDetail.applicant.educational_background.new_graduate);
        setSelectedFinalEducationName(adminApplicantsDetail.applicant.educational_background.final_education_id);
        if(adminApplicantsDetail.applicant.educational_background.graduation_date){
            let date = adminApplicantsDetail.applicant.educational_background.graduation_date.split('-');
            setGraduationYear(date[0]);
            setGraduationMonth(date[1]);
        }
        
        // setHandicapQualifications(adminApplicantsDetail.applicant.handicap_qualifications);
        
        if(adminApplicantsDetail.applicant.handicap_qualifications.length > 0){
            let tmpArr:any = [];
            let tmpArr1:any = [...handicaps];
            let handicapQualificationsData = []

            for(let i = 0; i < adminApplicantsDetail.applicant.handicap_qualifications.length; i++){
                handicapQualificationsData[i] = {
                    handicap_id: null,
                    handicap_type: null,
                    disability_certificate_status_id: null,
                    disability_certificate: null,
                    process: null,
                    characteristics_and_countermeasures: null,
                }
                if(adminApplicantsRegions.regions.handicaps && adminApplicantsRegions.regions.handicaps.length > 0){
                    for(let j = 0; j < adminApplicantsRegions.regions.handicaps.length; j++) {
                        if(adminApplicantsDetail.applicant.handicap_qualifications[i].handicap_id == adminApplicantsRegions.regions.handicaps[j].id){
                            for(let k = 0; k < handicaptypes.length; k++){
                                if(handicaptypes[k].id == adminApplicantsRegions.regions.handicaps[j].handicap_type_id){
                                    handicapQualificationsData[i].handicap_type = handicaptypes[k].id;
                                    tmpArr.push(handicaptypes[k].id);
                                }
                            }
                        }
                    }
                }
                let testarr:any= []
                for(let l = 0; l < tmpArr.length; l++){
                    testarr.push(adminApplicantsRegions.regions.handicaps.filter((handicaps: { handicap_type_id: number; }) => handicaps.handicap_type_id === tmpArr[l]))
                }
                let testarr1: any=[];

                for(let l = 0; l < tmpArr.length; l++){
                    testarr1.push(adminApplicantsRegions.regions.disability_certificates.filter((handicaps: { handicap_type_id: number; }) => handicaps.handicap_type_id === tmpArr[l]))
                }

               setHandicaps(testarr);
               setDisabilityCertificate(testarr1)
               handicapQualificationsData[i].handicap_id =  adminApplicantsDetail.applicant.handicap_qualifications[i].handicap_id;
               handicapQualificationsData[i].disability_certificate_status_id =  adminApplicantsDetail.applicant.handicap_qualifications[i].disability_certificate_status_id;
               handicapQualificationsData[i].disability_certificate =  adminApplicantsDetail.applicant.handicap_qualifications[i].disability_certificate;
               handicapQualificationsData[i].characteristics_and_countermeasures = adminApplicantsDetail.applicant.handicap_qualifications[i].characteristics_and_countermeasures;
               handicapQualificationsData[i].process = adminApplicantsDetail.applicant.handicap_qualifications[i].process;
            }
            setHandicapQualifications(handicapQualificationsData);
            setConsiderationConditionsRequiredExists(adminApplicantsDetail.applicant.consideration_conditions_required_exists);
            setSelectedConsiderationConditions(adminApplicantsDetail.applicant.consideration_conditions_required_ids);
            setSelectedConsiderationConditionsPrefer(adminApplicantsDetail.applicant.consideration_conditions_preferred_ids);
            setConsiderationConditionsSupplemental(adminApplicantsDetail.applicant.consideration_condition_supplemental);
            employmentSupportAgency[0].supportAgencyExists = adminApplicantsDetail.applicant.employment_support_agency.support_agency_exists;
            employmentSupportAgency[0].organization =  adminApplicantsDetail.applicant.employment_support_agency.organization;
            employmentSupportAgency[0].contactNumber =  adminApplicantsDetail.applicant.employment_support_agency.contact_number;
            employmentSupportAgency[0].url = adminApplicantsDetail.applicant.employment_support_agency.url;
            employmentSupportAgency[0].canContact = adminApplicantsDetail.applicant.employment_support_agency.can_contact;
            if( adminApplicantsDetail.applicant.employment_support_agency.start_date){
                const date = adminApplicantsDetail.applicant.employment_support_agency.start_date.split('-')
                // employmentSupportAgencyStartYear
                setEmploymentSupportAgencyStartYear(date[0]);
                setEmploymentSupportAgencyStartMonth(date[1]);
            }
            
            setNavigationBookURL(adminApplicantsDetail.applicant.navigation_book)
            setJobCareerCountId(adminApplicantsDetail.applicant.job_career_count_id);
            jobCareerRecent[0].company = adminApplicantsDetail.applicant.job_career_recent.company
            if(adminApplicantsDetail.applicant.job_career_recent.arrival_date){
                let arrivalDateData = adminApplicantsDetail.applicant.job_career_recent.arrival_date.split('-')
                setJobCareerRecentArrivalYear(arrivalDateData[0]);
                setJobCareerRecentArrivalMonth(arrivalDateData[1]);

            }
            if(adminApplicantsDetail.applicant.job_career_recent.departure_date){
                let departureDateData = adminApplicantsDetail.applicant.job_career_recent.departure_date.split('-')
                setJobCareerRecentDepartureYear(departureDateData[0]);
                setJobCareerRecentDepartureMonth(departureDateData[1])
            }
            jobCareerRecent[0].industryId = adminApplicantsDetail.applicant.job_career_recent.industry_id;
            jobCareerRecent[0].jobCategoryId =  adminApplicantsDetail.applicant.job_career_recent.job_category_id;
            jobCareerRecent[0].businessContent = adminApplicantsDetail.applicant.job_career_recent.business_content;
            //
            let tstArr =[];
            let tstArrDate =[];
            

            for(let i = 0; i < adminApplicantsDetail.applicant.job_career_appeals.length; i++){
                tstArr[i] = {
                    company: null,
                    arrival_date: null,
                    departure_date: null,
                    industry_id: null,
                    job_category_id: null,
                    business_content: null,
                  };
                  tstArrDate[i] = {
                    arrival_year:null,
                    arrival_month:null,
                    departure_year:null,
                    departure_month:null,
                  }
                  tstArr[i].company = adminApplicantsDetail.applicant.job_career_appeals[i].company;
                  tstArr[i].business_content = adminApplicantsDetail.applicant.job_career_appeals[i].business_content;
                  tstArr[i].industry_id = adminApplicantsDetail.applicant.job_career_appeals[i].industry_id;
                  tstArr[i].job_category_id = adminApplicantsDetail.applicant.job_career_appeals[i].job_category_id;
                  if(adminApplicantsDetail.applicant.job_career_appeals[i].arrival_date){
                    const arrivalData = adminApplicantsDetail.applicant.job_career_appeals[i].arrival_date.split('-')
                    tstArrDate[i].arrival_year = arrivalData[0];
                    tstArrDate[i].arrival_month = arrivalData[1];
                  }
                  if(adminApplicantsDetail.applicant.job_career_appeals[i].departure_date){
                    const departureData = adminApplicantsDetail.applicant.job_career_appeals[i].departure_date.split('-')
                    tstArrDate[i].departure_year = departureData[0];
                    tstArrDate[i].departure_month = departureData[1];
                  }
            }
             setJobCareerAppeals(tstArr);
             setJobCareerDate(tstArrDate);

            qualification[0].qualificationId_1 = adminApplicantsDetail.applicant.qualification.qualification_id_1;
            qualification[0].qualificationId_2 = adminApplicantsDetail.applicant.qualification.qualification_id_2;
            qualification[0].qualification3 = adminApplicantsDetail.applicant.qualification.qualification_3;
            selfPr[0].content =  adminApplicantsDetail.applicant.self_pr.content;
            languageStudy[0].languageId_1 = adminApplicantsDetail.applicant.language_study.language_id_1;
            languageStudy[0].languageId_2 = adminApplicantsDetail.applicant.language_study.language_id_2;
            languageStudy[0].language3 = adminApplicantsDetail.applicant.language_study.language_3;
            languageStudy[0].levelId_1 = adminApplicantsDetail.applicant.language_study.level_id_1;
            languageStudy[0].levelId_2 = adminApplicantsDetail.applicant.language_study.level_id_2;
            if(adminApplicantsDetail.applicant.language_study.language_id_2){
                setIsLanguageFirst(true);
            }
            if(adminApplicantsDetail.applicant.language_study.language_id_3){
                setIsLanguageSecond(true);
            }
            experienceJobCategory[0].jobCategoryId_1 = adminApplicantsDetail.applicant.experience_job_category.job_category_id_1;
            experienceJobCategory[0].jobCategoryId_2 = adminApplicantsDetail.applicant.experience_job_category.job_category_id_2;
            experienceJobCategory[0].jobCategoryId_3 = adminApplicantsDetail.applicant.experience_job_category.job_category_id_3;
            oaSkills[0].wordExperience =  adminApplicantsDetail.applicant.oa_skills.word_experience;
            oaSkills[0].word = adminApplicantsDetail.applicant.oa_skills.word_ids;
            oaSkills[0].excelExperience =  adminApplicantsDetail.applicant.oa_skills.excel_experience;
            oaSkills[0].excel =  adminApplicantsDetail.applicant.oa_skills.excel_ids;
            oaSkills[0].design = adminApplicantsDetail.applicant.oa_skills.design_ids
            oaSkills[0].program = adminApplicantsDetail.applicant.oa_skills.program_ids
            preferred[0].searchPolicyId =  adminApplicantsDetail.applicant.preferred.search_policy_id;
            setSelectedWorkStyles(adminApplicantsDetail.applicant.work_style_ids);
            preferred[0].workingHourId = adminApplicantsDetail.applicant.preferred.working_hour_id;
            preferred[0].workFromHomeId = adminApplicantsDetail.applicant.preferred.work_from_home_id;
            preferred[0].prefectureId_1 = adminApplicantsDetail.applicant.preferred.prefecture_id_1;
            preferred[0].prefectureId_2 = adminApplicantsDetail.applicant.preferred.prefecture_id_2;
            preferred[0].nationwide = adminApplicantsDetail.applicant.preferred.nationwide;
            preferred[0].commutingMethods =  adminApplicantsDetail.applicant.preferred.commuting_method_ids
            preferred[0].industryId_1 = adminApplicantsDetail.applicant.preferred.industry_id_1;
            preferred[0].industryId_2 = adminApplicantsDetail.applicant.preferred.industry_id_2;
            preferred[0].jobCategoryId_1 = adminApplicantsDetail.applicant.preferred.job_category_id_1;
            preferred[0].jobCategoryId_2 = adminApplicantsDetail.applicant.preferred.job_category_id_2;
            preferred[0].annualIncomeId = adminApplicantsDetail.applicant.preferred.annual_income_id;
            preferred[0].employmentStatusId = adminApplicantsDetail.applicant.employment_status_ids;
            setIsActive( adminApplicantsDetail.applicant.is_active);
            preferred[0].joinMonthId = adminApplicantsDetail.applicant.preferred.join_month_id;
            appealItem[0].appealItemId_1 = adminApplicantsDetail.applicant.appeal_item.appeal_item_id_1;
            appealItem[0].appealItemId_2 = adminApplicantsDetail.applicant.appeal_item.appeal_item_id_2;
            appealItem[0].appealItemId_3 = adminApplicantsDetail.applicant.appeal_item.appeal_item_id_3;
            setSpecialContent(adminApplicantsDetail.applicant.special_content);
            // setConsiderationConditionsRequiredExists(adminApplicantsDetail.applicant.consideration_conditions_required_exists)
            // setConsiderationConditionsPreferSupplemental()
        }
        
        
    }
}, [adminApplicantsDetail]);



function setImage() {
    const fileInput = fileInputRefImage.current;
    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      const fileImg = fileInput.files[0];
      if (fileImg.type.startsWith('image/')) {
        const logoUrl = URL.createObjectURL(fileImg);
        const reader = new FileReader();
        reader.readAsDataURL(fileImg);
        reader.onload = function () {
            setFacePhoto(reader.result);
        };
      }
    }
  }

  useEffect(() => {
    if (localStorage.getItem('jobParachannelAuthAdminToken')) {
        dispatch(fetchAdminApplicantsDetailRequest(id));
        dispatch(fetchAdminApplicantsRegionsRequest());
        window.scrollTo(0,0);
      } 
}, [dispatch]);
  

  const handleFamilyName = (event: any ) => {
    setEdited(true);
    setFamilyName(event.target.value);
  };

  const handleFirstName = (event: any) => {
    setEdited(true);
    setFirstName(event.target.value);
  };

  function onChangeFamilyNameKana(e: any) { 
    const kanaValidate = new RegExp('^([ァ-ン]|ー)+$');  
    setFamilyNameKana(e.target.value);
    if(e.target.value === '') {
        setErrorFamilyNameKana(true);
    } else {
        if(e.target.value.length > 100) {
            setErrorFamilyNameKanaMaxLength(true);
            setErrorFamilyNameKana(true);
        } else {
            setErrorFamilyNameKanaMaxLength(false);
            setErrorFamilyNameKana(false);
        }
        if(kanaValidate.test(e.target.value)) {
            setErrorFamilyNameKanaCheck(false);
            setErrorFamilyNameKana(false);
        } else {
            setErrorFamilyNameKanaCheck(true);
            setErrorFamilyNameKana(true);
        }
    }        
  }

  function onChangeFirstNameKana(e: any) {  
    setEdited(true);
    const kanaValidate = new RegExp('^([ァ-ン]|ー)+$'); 
    setFirstNameKana(e.target.value);     
    if(e.target.value === '') {
        setErrorFirstNameKana(true);
    } else {
        if(e.target.value.length > 100) {
            setErrorFirstNameKanaMaxLength(true);
            setErrorFirstNameKana(true);
        } else {
            setErrorFirstNameKanaMaxLength(false);
            setErrorFirstNameKana(false);
        }
        if(kanaValidate.test(e.target.value)) {
            setErrorFirstNameKanaCheck(false);
            setErrorFirstNameKana(false);
        } else {
            setErrorFirstNameKanaCheck(true);
            setErrorFirstNameKana(true);
        }
    }        
  }
  
  function onChangeBirthdayYear(e:any) {
    setYear(e.target.value);
  }
  function onChangeBirthdayMonth(e:any) {
    setMonth(e.target.value);
  }
  function onChangeBirthdayDay(e:any) {
    setDay(e.target.value);
  }
  useEffect(() => {
    if(year !== null && month !== undefined && day !== undefined){
        setBirthday(`${year}-${month}-${day}`)
        calcAge();
        setNowage( calcAge())
    } else {
        setNowage('')
    }
}, [year,month,day,nowage,birthday]);


function calcAge () {
    if (birthday) {
        const today = new Date();
        const b = birthday.split('-')
        const baseAge = today.getFullYear() - parseInt(b[0]);
        return baseAge+'歳';
    } else {
      return ''
    }
}

function chooseGender(e: any) {
    setGender(e.target.value);
  }

  useEffect(() => {
    if(postCodeDataChk){
        if (postcodeData.pending && Object.keys(postcodeData).length !== 0) {
            setLocationRegionId(postcodeData.postcode?.region?.id);
            setPrefectureText(postcodeData.postcode?.prefecture?.name);
            setLocationPrefectureId(postcodeData.postcode?.prefecture?.id);          
            setCityText(postcodeData.postcode?.city?.name);
            setLocationCityId(postcodeData.postcode?.city?.id);
            let textAreaKeyUp = postcodeData.postcode?.prefecture?.name + postcodeData.postcode?.city?.name;
            if(textAreaKeyUp) {
                setAreaText(textAreaKeyUp);
            }
        } else {
          setLocationRegionId(null);
          setLocationPrefectureId(null)
          setAreaText('');
          setPrefectureText('');
          setCityText('');
          setLocationCityId(null)
      } 
    }

}, [postcodeData, postCodeDataChk]);


function onKeyUpPostCode() {
    //   if ( postCode !== 7 ) { return }
      dispatch(fetchPostCodeRequest(postCode));
      setPostCodeDataChk(true);
}


function onChangeLocationAddress(e: any) {
    setLocationAddress(e.target.value)
}


function onChangeMobileNumber(e: any) {
    setEdited(true);
    setMobileNumber(e.target.value);
    if(e.target.value === '') {
        setErrorMobileNumber(true);
    } else {
        if(e.target.value.length > 11) {
            setMobileMaxLengthError(true);
            setErrorMobileNumber(true);
        }
        if(e.target.value.length < 10) {
            setMobileMinLengthError(true);
            setErrorMobileNumber(true);
        } else {                
            setMobileMinLengthError(false);
            setErrorMobileNumber(false);
        }
        if(!/[0-9]/.test(e.target.value)){
            setMobileNumericError(true);
            setErrorMobileNumber(true);
        }else{
            setMobileNumericError(false);
            setErrorMobileNumber(false);
        }
    }
  }

  function emailChange(e: any) {
    setEmail(e.target.value);
  }
  function onEmailPasswordChange(e: any) {
    setEmailPassword(e.target.value);
  }
  function finalEducationChange (e: any){
    setSelectedFinalEducationName(e.target.value);
  }

  function onChangeGraduationYear (e: any) {
    setGraduationYear(e.target.value);
  }
  function graduationMonthChange( e:any) {
    setGraduationMonth(e.target.value);
  }

  function handicaptypesChange(e:any,index:any) {
    const handicapQualification = [...handicapQualifications];
    let arr:any = [];
    let arr1:any = [];
    let arrFull= [...handicaps];
    let arrFull1= [...disabilityCertificate];

    for(let i = 0; i < handicapQualification.length; i++) {

        if(adminApplicantsRegions.regions.handicaps.length !== 0 && adminApplicantsRegions.regions.handicaps) {
            for(let i = 0; i < adminApplicantsRegions.regions.handicaps.length; i++) {
                if( adminApplicantsRegions.regions.handicaps[i].handicap_type_id == e.target.value){
                    arr.push(adminApplicantsRegions.regions.handicaps[i]);                    
                }
            }
            if(arrFull.length > 0 && arrFull[index] !== undefined) {
                arrFull[index]= arr;
            } else {
                arrFull.push(arr);
            }
        }
        if(adminApplicantsRegions.regions.disability_certificates && adminApplicantsRegions.regions.disability_certificates.length !== 0){
            for(let i = 0; i < adminApplicantsRegions.regions.disability_certificates.length; i++){
                if(adminApplicantsRegions.regions.disability_certificates[i].handicap_type_id == e.target.value){
                    arr1.push(adminApplicantsRegions.regions.disability_certificates[i]);
                }
            }
            if(arrFull1.length > 0 && arrFull1[index] !== undefined) {
                arrFull1[index]= arr;
            } else {
                arrFull1.push(arr);
            }
        }
    
    
        if(i == index){
            handicapQualification[i].handicap_type = parseInt(e.target.value);
        }
    }
    setDisabilityCertificate(arrFull1)
    setHandicaps(arrFull);
    setHandicapQualifications(handicapQualification);
  }
  function handicapChange (e:any,index:any) {
    const handicapQualification = [...handicapQualifications];
    for(let i = 0; i < handicapQualification.length; i++) {
        if(i == index){
            handicapQualification[i].handicap_id = parseInt(e.target.value);
        }
    }
    setHandicapQualifications(handicapQualification);
  }
  function disabilityCertificateStatusIdChange (e:any,index:any) {
    const handicapQualification = [...handicapQualifications];

    for(let i = 0; i < handicapQualification.length; i++) {
        if(i == index){
            handicapQualification[i].disability_certificate_status_id = parseInt(e.target.value);
        }
    }
    setHandicapQualifications(handicapQualification);
  }

  function disabilityCertificateChange (e: any,index:any) {
    const handicapQualification = [...handicapQualifications];
    for(let i = 0; i < handicapQualification.length; i++) {
        if(i == index){
            handicapQualification[i].disability_certificate = parseInt(e.target.value);
        }
    }
    // setSelectedDisabilityCertificate(e.target.value);
    setHandicapQualifications(handicapQualification);
  }

  function handleChangeProcess(e: any, index:any) {
    const handicapQualification = [...handicapQualifications];
    for(let i = 0; i < handicapQualification.length; i++) {
        if(i == index){
            handicapQualification[i].process = e;
        }
    }
    setHandicapQualifications(handicapQualification);
  }
  function handleChangeCharacteristicsAndCountermeasures( e:any,index: any) {
    const handicapQualification = [...handicapQualifications];
    for(let i = 0; i < handicapQualification.length; i++) {
        if(i == index){
            handicapQualification[i].characteristics_and_countermeasures = e;
        }
    }
    setHandicapQualifications(handicapQualification);
  }

  function handicapQualificationsAdd() {
    const newQualification = {
        handicap_id: null,
        handicap_type: null,
        disability_certificate_status_id: null,
        disability_certificate: null,
        process: null,
        characteristics_and_countermeasures: null,
      };
      setHandicapQualifications([...handicapQualifications, newQualification]);
  }

  function handicapQualificationsDelete(index: any) {
    const updatedQualifications = handicapQualifications.filter((_: any, i: any) => i !== index);
    setHandicapQualifications(updatedQualifications);
  }
  function onChangeConsiderationConditionsRequiredExists (e:any) {
    if(e.target.value === 'true'){
        setConsiderationConditionsRequiredExists(true);
    } else {
        setConsiderationConditionsRequiredExists(false);
    }
  }
  function onChangeConsiderationConditions (e:any, index:any){
    let c = [];
    for(let i = 0; i < selectedConsiderationConditions.length; i++) {
        c.push(selectedConsiderationConditions[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == e.target.value) {
            chk = true;
            index = i;
        }
    }
    if(!chk) {
        c.push(parseInt(e.target.value));
    } else {
        c.splice(index, 1);
    }
    setSelectedConsiderationConditions(c);
  }

  function onChangeConsiderationConditionsPrefer (e:any, index:any){
    let c = [];
    for(let i = 0; i < selectedConsiderationConditionsPrefer.length; i++) {
        c.push(selectedConsiderationConditionsPrefer[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == e.target.value) {
            chk = true;
            index = i;
        }
    }
    if(!chk) {
        c.push(parseInt(e.target.value));
    } else {
        c.splice(index, 1);
    }
    setSelectedConsiderationConditionsPrefer(c);
  }


  function handleChangeConsiderationConditionsSupplemental (e:any) {
    setConsiderationConditionsSupplemental(e);
  }

  function handleChangeConsiderationConditionsPreferSupplemental (e:any) {
    setConsiderationConditionsPreferSupplemental(e);
  }
  
  function onChangeSupportAgencyExists(e:any) {
    const employmentSupportAgencyChk = [...employmentSupportAgency];
    if(e.target.value === 'true'){
        employmentSupportAgencyChk[0].supportAgencyExists = true
    } else {
        employmentSupportAgencyChk[0].supportAgencyExists = false
    }
    setEmploymentSupportAgency(employmentSupportAgencyChk);
  }

  function onChangeOrganization(e:any) {
    const employmentSupportAgencyChk = [...employmentSupportAgency];
    employmentSupportAgencyChk[0].organization = e.target.value;
    setEmploymentSupportAgency(employmentSupportAgencyChk);
  }

  function onChangeContactNumber (e:any) {
    const employmentSupportAgencyChk = [...employmentSupportAgency];
    employmentSupportAgencyChk[0].contactNumber = e.target.value;
    setEmploymentSupportAgency(employmentSupportAgencyChk);
  }

  function onChangeUrl (e:any) {
    const employmentSupportAgencyChk = [...employmentSupportAgency];
    employmentSupportAgencyChk[0].url = e.target.value;
    setEmploymentSupportAgency(employmentSupportAgencyChk);
  }

  function onChangeCanContact(e:any) {
    const employmentSupportAgencyChk = [...employmentSupportAgency];
    if(e.target.value === 'true'){
        employmentSupportAgencyChk[0].canContact = true
    } else {
        employmentSupportAgencyChk[0].canContact = false
    }
    setEmploymentSupportAgency(employmentSupportAgencyChk);
  }
  
  

  function onChangeEmploymentSupportAgencyStartYear(e:any){
    setEmploymentSupportAgencyStartYear(e.target.value);
  }

  function onChangeEmploymentSupportAgencyStartMonth (e:any){
    setEmploymentSupportAgencyStartMonth(e.target.value);
  }

  const setPdf = () => {
    const file = fileInputRef.current?.files?.[0];
    if (file) {
      setNavigationBookURL(URL.createObjectURL(file));
      setNavigationBookName(file.name);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const navigationBookBase64 = reader.result as string;
        setNavigationBook(navigationBookBase64);
      };
    }
  };
  function onCHangeJobCareerCounts (e:any) {
    setJobCareerCountId(e.target.value);
  }

  function jobCareerRecentCompanyChange(e:any) {
    const jobCareerRecentArr = [...jobCareerRecent];
    jobCareerRecentArr[0].company = e.target.value;
    setJobCareerRecent(jobCareerRecentArr);
  }
  function onChangeJobCareerRecentArrivalYear (e:any) {
    setJobCareerRecentArrivalYear(e.target.value);
  }
  function onChangeJobCareerRecentArrivalMonth(e:any) {
    setJobCareerRecentArrivalMonth(e.target.value);
  }

  function onChangeJobCareerRecentDepartureYear (e:any) {
    setJobCareerRecentDepartureYear(e.target.value);
  }
  function onChangeJobCareerRecentDepartureMonth(e:any) {
    setJobCareerRecentDepartureMonth(e.target.value);
  }

  function industriesChange (e:any) {
    const jobCareerRecentArr = [...jobCareerRecent];
    jobCareerRecentArr[0].industryId = e.target.value;
    setJobCareerRecent(jobCareerRecentArr);
  }

  function jobCategoryIdChange (e:any) {
    const jobCareerRecentArr = [...jobCareerRecent];
    jobCareerRecentArr[0].jobCategoryId = e.target.value;
    setJobCareerRecent(jobCareerRecentArr);
  }
  function businessContentChange (e:any) {
    const jobCareerRecentArr = [...jobCareerRecent];
    jobCareerRecentArr[0].businessContent = e.target.value;
    setJobCareerRecent(jobCareerRecentArr);

  }

  function AddJobCareerAppeal() {
    const newJobCareerAppeal = {
        company: null,
        arrival_date: null,
        departure_date: null,
        industry_id: null,
        job_category_id: null,
        business_content: null,
      };

      const newJobCareerDate = {
        arrival_year:null,
        arrival_month:null,
        departure_year:null,
        departure_month:null,
    }
      setJobCareerDate([...jobCareerDate, newJobCareerDate]);
      setJobCareerAppeals([...jobCareerAppeals,newJobCareerAppeal]);
  }

  function jobCareerAppealDelete(index: any) {
    const newJobCareerAppeal = jobCareerAppeals.filter((_: any, i: any) => i !== index);
    setJobCareerAppeals(newJobCareerAppeal);
  }

  function jobCareerAppealsCompanyNameChange (e:any, index:any) {
    const jobCareerAppeal = [...jobCareerAppeals];
    for(let i = 0; i < jobCareerAppeal.length; i++) {
        if(i == index){
            jobCareerAppeal[i].company = e.target.value;
        }
    }
    setJobCareerAppeals(jobCareerAppeal);
  }

  function jobCareerAppealsArrivalMonth(e:any, index:any) {

    const jobCareerAppeal = [...jobCareerDate];
    for(let i = 0; i < jobCareerAppeal.length; i++) {
        if(i == index){
            jobCareerAppeal[i].arrival_month = e.target.value;
        }
    }
    setJobCareerDate(jobCareerAppeal);

  }

  function jobCareerAppealsArrivalYear(e:any, index:any) {
    const jobCareerAppeal = [...jobCareerDate];
    for(let i = 0; i < jobCareerAppeal.length; i++) {
        if(i == index){
            jobCareerAppeal[i].arrival_year = e.target.value;
        }
    }
    setJobCareerDate(jobCareerAppeal);
  }

  function jobCareerAppealsIndustriesChange (e:any, index:any){
    const jobCareerAppeal = [...jobCareerAppeals];
    for(let i = 0; i < jobCareerAppeal.length; i++) {
        if(i == index){
            jobCareerAppeal[i].industry_id = e.target.value;
        }
    }
    setJobCareerAppeals(jobCareerAppeal);
  }
  function jobCareerAppealsJobCategoryChange (e:any, index:any) {
    const jobCareerAppeal = [...jobCareerAppeals];
    for(let i = 0; i < jobCareerAppeal.length; i++) {
        if(i == index){
            jobCareerAppeal[i].job_category_id = e.target.value;
        }
    }
    setJobCareerAppeals(jobCareerAppeal);
  }

  function jobCareerAppealsDepartureYear (e:any, index:any){
    const jobCareerAppeal = [...jobCareerDate];
    for(let i = 0; i < jobCareerAppeal.length; i++) {
        if(i == index){
            jobCareerAppeal[i].departure_year = e.target.value;
        }
    }
    setJobCareerDate(jobCareerAppeal);
  }

  function jobCareerAppealsDepartureMonth (e:any, index:any){
    const jobCareerAppeal = [...jobCareerDate];
    for(let i = 0; i < jobCareerAppeal.length; i++) {
        if(i == index){
            jobCareerAppeal[i].departure_month = e.target.value;
        }
    }
    setJobCareerDate(jobCareerAppeal);
  }
  function jobCareerAppealsBusinessContentChange (e:any,index:any) {
    const jobCareerAppeal = [...jobCareerAppeals];
    for(let i = 0; i < jobCareerAppeal.length; i++) {
        if(i == index){
            jobCareerAppeal[i].business_content = e.target.value;
        }
    }
    setJobCareerAppeals(jobCareerAppeal);
  }
  

  function onChangeNewGraduate(e:any){
    if(e.target.value == 'true'){
        setNewGraduate(true);
    }else {
        setNewGraduate(false);
    }
  }
  function jobCategoryChange3(e:any) {
    const experienceJobCategorys = [...experienceJobCategory];
    experienceJobCategorys[0].jobCategoryId_3 = e.target.value;
    setExperienceJobCategory(experienceJobCategorys);
  }
  function jobCategoryChange2(e:any) {
    const experienceJobCategorys = [...experienceJobCategory];
    experienceJobCategorys[0].jobCategoryId_2 = e.target.value;
    setExperienceJobCategory(experienceJobCategorys)
  }
  function jobCategoryChange1(e:any) {
    const experienceJobCategorys = [...experienceJobCategory];
    experienceJobCategorys[0].jobCategoryId_1 = e.target.value;
    setExperienceJobCategory(experienceJobCategorys)
  }
  function onChangeWordExperience(e:any) {
    const oaSkill = [...oaSkills];
    if(e.target.value == 'true'){
        oaSkill[0].wordExperience = true;
    } else {
        oaSkill[0].wordExperience = false;
    }
    setOaSkills(oaSkill);
  }

  const handleCheckboxWord = (event: any, id: any) => {
    let c = [];
    const oaSkill = [...oaSkills]
    for(let i = 0; i < oaSkill[0].word.length; i++) {
        c.push(oaSkill[0].word[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == event.target.value) {
            chk = true;
            id = i;
        }
    }
    if(!chk) {
        c.push(parseInt(event.target.value));
    } else {
        c.splice(id, 1);
    }
    oaSkill[0].word = c;
    setOaSkills(oaSkill);
  };



  function onChangeExcelExperience(e:any) {
    const oaSkill = [...oaSkills];
    if(e.target.value == 'true'){
        oaSkill[0].excelExperience = true;
    } else {
        oaSkill[0].excelExperience = false;
    }
    setOaSkills(oaSkill);
  }

  const handleCheckboxExcel = (event: any, id: any) => {
    let c = [];
    const oaSkill = [...oaSkills]
    for(let i = 0; i < oaSkill[0].excel.length; i++) {
        c.push(oaSkill[0].excel[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == event.target.value) {
            chk = true;
            id = i;
        }
    }
    if(!chk) {
        c.push(parseInt(event.target.value));
    } else {
        c.splice(id, 1);
    }
    oaSkill[0].excel = c;
    setOaSkills(oaSkill);
  };

  const handleCheckboxDesign = (event: any, id: any) => {
    let c = [];
    const oaSkill = [...oaSkills]
    for(let i = 0; i < oaSkill[0].design.length; i++) {
        c.push(oaSkill[0].design[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == event.target.value) {
            chk = true;
            id = i;
        }
    }
    if(!chk) {
        c.push(parseInt(event.target.value));
    } else {
        c.splice(id, 1);
    }
    oaSkill[0].design = c;
    setOaSkills(oaSkill);
  }

  const handleCheckboxProgram = (event: any, id: any) => {
    let c = [];
    const oaSkill = [...oaSkills]
    for(let i = 0; i < oaSkill[0].program.length; i++) {
        c.push(oaSkill[0].program[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == event.target.value) {
            chk = true;
            id = i;
        }
    }
    if(!chk) {
        c.push(parseInt(event.target.value));
    } else {
        c.splice(id, 1);
    }
    oaSkill[0].program = c;
    setOaSkills(oaSkill);
  }
  function handleChangeQualifications1 (e:any) {
    const qualificationData = [...qualification];
    qualificationData[0].qualificationId_1 = e.target.value;
    setQualification(qualificationData);
  }

  function handleChangeQualifications2 (e:any) {
    const qualificationData = [...qualification];
    qualificationData[0].qualificationId_2 = e.target.value;
    setQualification(qualificationData);
  }

  function handleChangeQualifications3 (e:any) {
    const qualificationData = [...qualification];
    qualificationData[0].qualification3 = e.target.value;
    setQualification(qualificationData);
  }
  function setSelfPrChange(e:any){
    const selfPrData = [...selfPr];
    selfPrData[0].content = e.target.value;
    setSelfPr(selfPrData);
  }
  function handleChangeLanguages1(e:any){
    const languageStudyData = [...languageStudy];
    languageStudyData[0].languageId_1 = e.target.value;
    setLanguageStudy(languageStudyData);
  }
  function handleChangeLevels1(e:any){
    const languageStudyData = [...languageStudy];
    languageStudyData[0].levelId_1 = e.target.value;
    setLanguageStudy(languageStudyData);
  }
  function handleChangeLanguages2(e:any){
    const languageStudyData = [...languageStudy];
    languageStudyData[0].languageId_2 = e.target.value;
    setLanguageStudy(languageStudyData);
  }
  function handleChangeLevels2(e:any){
    const languageStudyData = [...languageStudy];
    languageStudyData[0].levelId_2 = e.target.value;
    setLanguageStudy(languageStudyData);
  }

  function handleChangeLanguages3(e:any){
    const languageStudyData = [...languageStudy];
    languageStudyData[0].language3 = e.target.value;
    setLanguageStudy(languageStudyData);
  }
  function handleChangeSearchPolicies(e:any) {
    const preferredData = [...preferred];
    preferredData[0].searchPolicyId = e.target.value;
    setPreferred(preferredData);
  }

  function onChangeWorkStyles (e:any, index:any){
    let c = [];
    for(let i = 0; i < selectedWorkStyles.length; i++) {
        c.push(selectedWorkStyles[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == e.target.value) {
            chk = true;
            index = i;
        }
    }
    if(!chk) {
        c.push(parseInt(e.target.value));
    } else {
        c.splice(index, 1);
    }
    setSelectedWorkStyles(c);
  }

  function workingHourIdChange (e:any){
    const preferredData = [...preferred];
    preferredData[0].workingHourId = e.target.value;
    setPreferred(preferredData);
  }

  function workFromHomeIdChange (e:any){
    const preferredData = [...preferred];
    preferredData[0].workFromHomeId = e.target.value;
    setPreferred(preferredData);
  }
  function handleChangePrefectures (e: any) {
    const preferredData = [...preferred];
    preferredData[0].prefectureId_1 = e.target.value;
    setPreferred(preferredData);
  }

  function handleChangePrefectures2 (e: any) {
    const preferredData = [...preferred];
    preferredData[0].prefectureId_2 = e.target.value;
    setPreferred(preferredData);
  }

  function onChangeNationwide(e:any) {
    const preferredData = [...preferred];

    if(e.target.value =='true'){
        preferredData[0].nationwide = true;
    } else {
        preferredData[0].nationwide = false;
    }
    setPreferred(preferredData);
  }

  const onChangeCommutingMethods = (event: any, id: any) => {
    let c = [];
    const preferredData = [...preferred]
    for(let i = 0; i < preferredData[0].commutingMethods.length; i++) {
        c.push(preferredData[0].commutingMethods[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == event.target.value) {
            chk = true;
            id = i;
        }
    }
    if(!chk) {
        c.push(parseInt(event.target.value));
    } else {
        c.splice(id, 1);
    }
    preferredData[0].commutingMethods = c;
    setPreferred(preferredData);
  }

  function preferredindustriesChange1 (e:any) {
    const preferredData = [...preferred]
    preferredData[0].industryId_1 = e.target.value;
    setPreferred(preferredData);
  }

  function preferredindustriesChange2 (e:any) {
    const preferredData = [...preferred];
    preferredData[0].industryId_2 = e.target.value;
    setPreferred(preferredData);
  }

  function preferredJobCategoryChange1(e:any) {
    const preferredData = [...preferred];
    preferredData[0].jobCategoryId_1 = e.target.value;
    setPreferred(preferredData);
  }
  
  function preferredJobCategoryChange2(e:any) {
    const preferredData = [...preferred];
    preferredData[0].jobCategoryId_2 = e.target.value;
    setPreferred(preferredData);
  }
  function handleEmploymentStatusId(event: any, id: any){
    let c = [];
    const preferredData = [...preferred]
    for(let i = 0; i < preferredData[0].employmentStatusId.length; i++) {
        c.push(preferredData[0].employmentStatusId[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == event.target.value) {
            chk = true;
            id = i;
        }
    }
    if(!chk) {
        c.push(parseInt(event.target.value));
    } else {
        c.splice(id, 1);
    }
    preferredData[0].employmentStatusId = c;
    setPreferred(preferredData);
  }

  function annualIncomesChange(e:any) {
    const preferredData = [...preferred];
    preferredData[0].annualIncomeId = e.target.value;
    setPreferred(preferredData);
  }
  function onChangeIsActive(e:any) {
    if(e.target.value == 'true'){
        setIsActive(true);
    } else {
        setIsActive(false);
    }
  }
  function joinMonthsChange(e:any) {
    const preferredData = [...preferred];
    preferredData[0].joinMonthId = e.target.value;
    setPreferred(preferredData);
  }
  function appealItemsChange1(e:any){
    const appealItemData = [...appealItem];
    appealItemData[0].appealItemId_1 = e.target.value;
    setAppealItem(appealItemData);

  }

 function appealItemsChange2(e:any){
    const appealItemData = [...appealItem];
    appealItemData[0].appealItemId_2 = e.target.value;
    setAppealItem(appealItemData);

  }
  
  function appealItemsChange3(e:any){
    const appealItemData = [...appealItem];
    appealItemData[0].appealItemId_3 = e.target.value;
    setAppealItem(appealItemData);
  }

  function onChangeSpecialContent(e:any){
    setSpecialContent(e.target.value);
  }

  function validates() {
    let result = '';
    const kanaValidate = new RegExp('^([ァ-ン]|ー)+$');
    if(family_name == null || family_name == ''){
        result += "名前が入力されていません\n"
    }
    if(first_name == null || first_name == ''){
        result += "名前が入力されていません\n"
    }
    if(family_name_kana == null || family_name_kana == ''){
        result +='名前（フリガナ）が入力されていません'
    }  else {
        if(!kanaValidate.test(family_name_kana)){
            result += "名前（フリガナ）はカタカナで入力してください\n"
        }
    }

    if(first_name_kana == null || first_name_kana == ''){
        result +='名前（フリガナ）が入力されていません'
    }else {
        if(!kanaValidate.test(first_name_kana)){
            result += "名前（フリガナ）はカタカナで入力してください\n"
        }
    }
    if (postCode == '') {         
        result += '郵便番号の形式が正しくありません\n'
    }
    if(locationPrefectureId == null || locationCityId == null  || areaText == '' ) {
        result += '都道府県入力されていません\n'
    }
    if( locationAddress == ''){
        result += '住所(それ以降)入力されていません\n'
    }

    if (year == null || month == null  || day == null ) {
        result += "生年月日が正しくありません\n"
    }

    if(email == null){
        result += 'メールアドレスが正しくありません\n'
    }
    if(preferred[0].searchPolicyId == null){
        result += '希望条件 勤務地が入力されていません\n'
    }
    if(preferred[0].prefectureId_1 == null){
        result += '希望条件 仕事の探し方が入力されていません\n'
    }
    if(handicapQualifications[0].handicap_id  == null || handicapQualifications[0].disability_certificate_status_id == null){
        result += '障がいについてが入力されていません\n'
    }
    return result
}
  
  function applicantEdit () {
        const validatedText = validates(); 
        // console.log(postCode);
        // console.log(validatedText);
        if (validatedText) {
            alert('未入力項目があります\n\n' + validatedText)
            return
        }

        let graduationDate;
        if(graduationYear && graduationMonth){
            graduationDate = graduationYear + '-' + graduationMonth
        }

        if (employmentSupportAgencyStartYear && employmentSupportAgencyStartMonth ) {
            employmentSupportAgency[0].startDate = `${employmentSupportAgencyStartYear}-${employmentSupportAgencyStartMonth}`
          } else {
           employmentSupportAgency[0].startDate = null
          }

        if(jobCareerAppeals && jobCareerAppeals.length > 0){
            for(let i = 0; i< jobCareerAppeals.length; i++) {
                if(jobCareerDate && jobCareerDate.length > 0){
                    jobCareerAppeals[i].arrival_date =  jobCareerDate[i].arrival_year + '-' + jobCareerDate[i].arrival_month;
                    jobCareerAppeals[i].departure_date =  jobCareerDate[i].departure_year + '-' + jobCareerDate[i].departure_month;
                }
            }
        }
        let applicant = {
            face_photo: face_photo,
            family_name: family_name,
            first_name: first_name,
            family_name_kana: family_name_kana,
            first_name_kana: first_name_kana,
            birthday: birthday,
            gender_id: gender !== null ? parseInt(gender): '',
            postcode: postCode,
            prefecture_id: locationPrefectureId,
            city_id: locationCityId,
            region_id: null,
            address: locationAddress,
            mobile_number: mobile_number,
            special_content: specialContent,
            email: email,
            password: emailPassword,
            navigation_book: navigation_book,
            educational_background: {
                final_education_id: selectedFinalEducationName ? parseInt(selectedFinalEducationName) : null,
                graduation_date: graduationDate,
                new_graduate: isNewGraduate
            },
            handicap_qualifications: handicapQualifications,
            consideration_conditions_required_exists: considerationConditionsRequiredExists,
            consideration_conditions_required: selectedConsiderationConditions,
            consideration_conditions_preferred: selectedConsiderationConditionsPrefer,
            consideration_conditions_supplemental: considerationConditionsSupplemental,
            consideration_conditions_preferred_supplemental: considerationConditionsPreferSupplemental,
            employment_support_agency: {
                support_agency_exists: employmentSupportAgency[0].supportAgencyExists,
                organization:  employmentSupportAgency[0].organization,
                contact_number:  employmentSupportAgency[0].contactNumber,
                url:  employmentSupportAgency[0].url,
                can_contact:  employmentSupportAgency[0].canContact,
                start_date:  employmentSupportAgency[0].startDate,
                end_date:  employmentSupportAgency[0].endDate,
                training_content:  employmentSupportAgency[0].trainingContent,
            } ,
            job_career_count_id: parseInt(jobCareerCountId),
            job_career_recent: {
                company: jobCareerRecent[0].company,
                arrival_date: jobCareerRecentArrivalYear + '-' + jobCareerRecentArrivalMonth,
                departure_date: jobCareerRecentDepartureYear + '-' + jobCareerRecentDepartureMonth,
                industry_id: jobCareerRecent[0].industryId,
                job_category_id: jobCareerRecent[0].jobCategoryId,
                business_content: jobCareerRecent[0].businessContent
            },
            job_career_appeals:jobCareerAppeals,
            experience_job_category: {
                job_category_id_1: experienceJobCategory[0].jobCategoryId_1 ? parseInt(experienceJobCategory[0].jobCategoryId_1) : null,
                job_category_id_2: experienceJobCategory[0].jobCategoryId_2 ? parseInt(experienceJobCategory[0].jobCategoryId_2) : null,
                job_category_id_3: experienceJobCategory[0].jobCategoryId_3 ? parseInt( experienceJobCategory[0].jobCategoryId_3) : null
            },
            oa_skills: {
                word_experience: oaSkills[0].wordExperience,
                word: oaSkills[0].word,
                excel_experience: oaSkills[0].excelExperience,
                excel: oaSkills[0].excel,
                design: oaSkills[0].design,
                program: oaSkills[0].program
            },
            qualification: {
                qualification_id_1: qualification[0].qualificationId_1 ? parseInt(qualification[0].qualificationId_1) : null,
                qualification_id_2: qualification[0].qualificationId_2 ? parseInt(qualification[0].qualificationId_2) : null,
                qualification3: qualification[0].qualification3,
            },
            language_study: {
                language_id_1: languageStudy[0].languageId_1 ?  parseInt(languageStudy[0].languageId_1) : null,  
                level_id_1:languageStudy[0].levelId_1 ? parseInt(languageStudy[0].levelId_1) : null,
                language_id_2: languageStudy[0].languageId_2 ? parseInt(languageStudy[0].languageId_2): null,
                level_id_2: languageStudy[0].levelId_2 ? parseInt(languageStudy[0].levelId_2): null,
                language3: languageStudy[0].language3,
            },
            self_pr: {
                content: selfPr[0].content
            },
            preferred: {
                prefecture_id_1: preferred[0].prefectureId_1 ? parseInt(preferred[0].prefectureId_1) : null,
                prefecture_id_2: preferred[0].prefectureId_2 ? parseInt(preferred[0].prefectureId_2) : null,
                industry_id_1: preferred[0].industryId_1 ? parseInt(preferred[0].industryId_1) : null,
                industry_id_2: preferred[0].industryId_2 ? parseInt(preferred[0].industryId_2) : null,
                job_category_id_1: preferred[0].jobCategoryId_1 ? parseInt(preferred[0].jobCategoryId_1) : null,
                job_category_id_2: preferred[0].jobCategoryId_2 ? parseInt(preferred[0].jobCategoryId_2) : null,
                // employment_status_id: preferred[0].employmentStatusId,
                annual_income_id: preferred[0].annualIncomeId ? parseInt(preferred[0].annualIncomeId) : null,
                join_month_id: preferred[0].joinMonthId ? parseInt(preferred[0].joinMonthId): null,
                search_policy_id: preferred[0].searchPolicyId ? parseInt(preferred[0].searchPolicyId) : null,
                commuting_methods: preferred[0].commutingMethods,
                working_hour_id: preferred[0].workingHourId ? parseInt(preferred[0].workingHourId): null,
                work_from_home_id: preferred[0].workFromHomeId ? parseInt(preferred[0].workFromHomeId ) : null,
                nationwide: preferred[0].nationwide
            },
            employment_status_ids:preferred[0].employmentStatusId,
            appeal_item: {
                appeal_item_id_1: parseInt(appealItem[0].appealItemId_1),
                appeal_item_id_2: parseInt(appealItem[0].appealItemId_2),
                appeal_item_id_3: parseInt(appealItem[0].appealItemId_3)
            },
            work_style: selectedWorkStyles,
            is_active: isActive
        }


        if (window.confirm('更新しました')) {
            dispatch(fetchAdminApplicantsPostRequest(applicant,'PUT',id));
            setPutBtn(true);
          } else {
            return
          }
        
  }
  useEffect(() => {
    if (adminApplicantsPost.pending && putBtn) {
        navigate('/admin/applicants_list');
        // window.location.href = '/admin/applicants_list';
    }
  }, [adminApplicantsPost]);

  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader /> : null}
      <section id='openjob'>
        <div className='adminPageWrapper'>
          <div className="header">
            <h2 className="title">求職者編集</h2>
          </div>  
          <div className='body'>
            <div className="">
              <div className="textWrapper">
                <a onClick={() => navigate('/admin/applicants_list')} className='blueBtn'>＜ 一つ前に戻る</a>
              </div>
            </div>
            <div className='step1'>
                <div>
                    <h1>顔写真</h1>
                    <div className='bordered mb30'>
                        <div className='dFlex' style={{justifyContent:'center'}}>
                            <div className="photo">
                                <div className="img_box">
                                    <img src={face_photo == null ? '/assets/img/company/img02.jpg': face_photo} alt=""/>
                                </div>
                                <p className="btn">
                                    <label  style={{background:'#00699f', color:'#fff', padding:'10px 40px ', borderRadius:'5px' , fontSize:'16px'}} ><input style={{display:'none'}} type="file" className="btn" ref={fileInputRefImage} onChange={setImage}/>ファイルを選択 </label>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h1>プロフィール</h1>
                    <div className='bordered'>
                        <div className='dFlex'>
                            <div style={{marginRight:'20px'}}>
                                <label>姓 <span style={{ color: 'red' }}>※必須</span></label>
                                <input placeholder='例)山田' type="text" name="name1" value={family_name || ''} onChange={(event) => handleFamilyName(event)}/>
                            </div>
                            <div>
                                <label>名 <span style={{ color: 'red' }}>※必須</span> </label>
                                <input placeholder='例)太郎' type="text" name="name2" value={first_name || ''} onChange={(event) => handleFirstName(event)}/>
                            </div>
                        </div>

                        <div className='dFlex'>
                            <div style={{marginRight:'20px'}}>
                                <label>性(フリガナ) <span style={{ color: 'red' }}>※必須</span></label>
                                <input placeholder='例)ヤマダ' type="text" name="kana1" className={errorFamilyNameKana ? "error" : ""} value={family_name_kana || ''} onChange={(e) => onChangeFamilyNameKana(e)}/>
                            </div>
                            <div>
                                <label>名(フリガナ) <span style={{ color: 'red' }}>※必須</span> </label>
                                <input placeholder='例)タロウ' type="text" name="kana2" className={errorFirstNameKana ? "error" : ""} value={first_name_kana || ''} onChange={(e) => onChangeFirstNameKana(e)}/>
                            </div>
                        </div>
                        <div className='dFlex'>
                            <div style={{marginRight: '5px'}}>
                                <label>生年月日<span style={{color: 'red'}}>※必須</span></label>
                                <input placeholder='入力してください' type="text" name="year" value={year || ''} onChange={(e) => onChangeBirthdayYear(e)}/>
                            </div>
                            <p style={{margin: '10px 5px'}}>年</p>
                            <div style={{marginRight: '5px', marginTop:'20px'}}>
                                <select value={month || ''} onChange={(e) => onChangeBirthdayMonth(e)}>
                                    <option value="" disabled>選択してください</option>
                                    {[...Array(12)].map((_, index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <p style={{margin: '10px 5px'}}>月</p>
                            <div style={{marginRight: '5px', marginTop:'20px'}}>
                                <select value={day || ''} onChange={(e) => onChangeBirthdayDay(e)}>
                                    <option value="" disabled>選択してください</option>
                                    {[...Array(31)].map((_, index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <p style={{margin: '10px 5px'}}>日</p>
                        </div>
                        <div>
                            <label>年齢</label>
                            <input className='wid300' type="text" name="nowage" disabled value={nowage || ''}/>
                        </div>
                        <div>
                            <label>性別</label>
                            <select value={gender || ''} onChange={chooseGender}>
                                <option value='' disabled selected>選択してください</option>
                                <option value={1}>男</option>
                                <option value={2}>女</option>
                                <option value={3}>回答しない</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>住所</h1>
                    <div className='bordered'>
                        <div>
                            <label>
                                郵便番号<span style={{ color: 'red' }}>※必須</span>
                            </label>
                            <div className='dFlex'>
                            <input type="number" maxLength={7} className='wid300' placeholder='(例)0000000' name="address" value={postCode || ''}  onChange={(e) => {if (e.target.value.length <= 7) { setPostCode(e.target.value);}}}/>
                            <button onClick={onKeyUpPostCode}>郵便番号検索</button>
                            </div>
                        </div>
                        <div>
                      <div className='dFlex'>
                        <div>
                          <label>
                          都道府県 市/区<span  style={{ color: 'red' }}>※必須</span>
                          </label>
                          <input type="text" placeholder='郵便番号で自動入力' name="prefecture" value={areaText} onChange={(e) => setAreaText(e.target.value)} />
                        </div>
                        <div style={{marginLeft:'20px'}}>
                          <label>
                          住所(それ以降)<span style={{ color: 'red' }}>※必須</span>
                          </label>
                          <input  type="text" placeholder='(例)1-1-1 マンション名' value={locationAddress} onChange={onChangeLocationAddress}/>
                        </div>
                      </div>
                      <div>
                      <div className='tel'>
                            <div className='wid300'>
                                <label className="mb5 ">
                                    電話番号
                                </label>
                                <input placeholder='(例)000-1234-56789' type="tel" name="tel" maxLength={11} minLength={10} style={errorMobileNumber ? {background : "#ffd9d9"} : {}} className={errorMobileNumber ? "error" : ""} value={mobile_number || ''} onChange={(e) => onChangeMobileNumber(e)}/>
                            </div>
                            {/* mobile_number error */}
                            {mobile_number ?
                                <div className="error_box">
                                    {mobileMaxLengthError || mobileMinLengthError ?
                                        <p className="error_msg">電話番号は{ mobileMinLen }桁以上{ mobileMaxLen }桁以下で入力してください</p> 
                                    : <></>}
                                    {mobileNumericError ?
                                        <p className="error_msg">電話番号は数字のみで入力してください</p>
                                    : <></>}
                                </div>
                            : <></>}
                        </div>
                      </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>認証情報 <span style={{color: 'red', fontWeight:'400'}}>※必須</span></h1>
                    <div className='bordered'>
                        <div>
                            <label>メールアドレス</label>
                            <input type="text" placeholder='TEST@gmai.com'  className='wid300' value={email || ''} onChange={(e) => emailChange(e)}/>
                        </div>
                        <div>
                            <label>パスワード</label>
                            <input type="password" className='wid300' placeholder='入力してください' value={emailPassword}  onChange={(e) => onEmailPasswordChange(e)}/>
                        </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>学歴または学校に関する情報</h1>
                    <div className='bordered'>
                        <div>
                            <label>就職カテゴリー</label>
                            <div className='dFlex'>
                                    <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}>
                                        <input
                                            className='radio'
                                            type="radio"
                                            name="school7"
                                            value='true'
                                            checked={isNewGraduate ? true:false}
                                            onChange={(e) => { onChangeNewGraduate(e)}}
                                        />
                                        新卒
                                    </label>
                                    <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}>
                                        <input
                                            className='radio'
                                            type="radio"
                                            name="school7"
                                            value='false'
                                            checked={!isNewGraduate ? true:false}
                                            onChange={(e) => { onChangeNewGraduate(e)}}
                                        />
                                        中途
                                    </label>
                            </div>
                        </div>
                        <div>
                            <label>最終学歴</label>
                            <select onChange={finalEducationChange} value={selectedFinalEducationName}>
                                <option value={""} disabled selected>選択してください</option>
                                {finalEducations && finalEducations.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>卒業・卒業見込み年度</label>
                            <div className='dFlex'>
                                <input type="number" className='wid300 m-3' value={graduationYear} placeholder='入力してください' onChange={(e) => onChangeGraduationYear(e)}/> <p style={{margin:'5px 10px'}}>年</p>
                                <select onChange={graduationMonthChange} value={graduationMonth} className='m-3'>
                                    <option value={""} selected disabled>選択してください</option>
                                    {[...Array(12)].map((_, index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </option>
                                    ))}
                                </select> <p style={{margin:'5px 10px'}}>月</p>

                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className='mt20'>
                    <h1>障害について<span style={{ color: 'red', fontWeight:'400' }}>※必須</span></h1>
                    <div className='bordered'>
                        {handicapQualifications && handicapQualifications.map((data:any, index:any)=> (
                            <div key={index}>
                                <div className='dFlex'>
                                    <div style={{marginRight:'20px'}}>
                                        <label>障害区分</label>
                                        <select onChange={(e)=>handicaptypesChange(e,index)} value={data.handicap_type || ''}>
                                            <option value={""} selected disabled>選択してください</option>
                                            {handicaptypes && handicaptypes.map((data) => (
                                                <option  key={`handicaptypes${data.id}-${index}`} value={data.id}>
                                                    {data.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label>障害名</label>
                                        <select onChange={(e)=>handicapChange(e,index)} value={data.handicap_id ||'' }  disabled= { handicapQualifications[index].handicap_type == null}>
                                            <option value={""} selected disabled>選択してください</option>
                                            {handicaps[index] && handicaps[index].map((data:any,key:any) => (
                                                <option key={`handicaps${data.id}-${index}-${key}`}  value={data.id}>
                                                    {data.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <label>障害者ステータスの有無</label>
                                    <div className='dFlex'>
                                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}> <input className='radio' key={`handicapQualifications-1${index}`} type="radio" name={'disabilityCertificateStatusId' +index} checked={handicapQualifications[index].disability_certificate_status_id == 2 ? true : false} value={2}  onChange={(e)=>disabilityCertificateStatusIdChange(e,index) } />あり</label>
                                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}> <input className='radio' key={`handicapQualifications-1${index}`} type="radio" name={'disabilityCertificateStatusId' +index} checked={handicapQualifications[index].disability_certificate_status_id == 1 ? true : false}  value={1}  onChange={(e)=>disabilityCertificateStatusIdChange(e,index) } />なし</label>
                                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}> <input className='radio' key={`handicapQualifications-1${index}`} type="radio" name={'disabilityCertificateStatusId' +index} checked={handicapQualifications[index].disability_certificate_status_id == 3 ? true : false}  value={3}   onChange={(e)=>disabilityCertificateStatusIdChange(e,index) }/>申請中</label>
                                    </div>
                                </div>
                                <div>
                                    <label>障害者手帳</label>
                                    <select onChange={(e)=>disabilityCertificateChange(e,index)} value={handicapQualifications[index].disability_certificate_status_id!= 2 ? '' :data.disability_certificate} disabled= { handicapQualifications[index].handicap_type == null || handicapQualifications[index].disability_certificate_status_id != 2}>
                                            <option value={""} selected disabled>選択してください</option>
                                            {disabilityCertificate[index] && disabilityCertificate[index].map((data:any,key:any) => (
                                                <option key={`disabilityCertificate${data.id}-1${index}-${key}`} value={data.id}>
                                                    {data.name} {data.class_name}
                                                </option>
                                            ))}
                                        </select>
                                </div>
                                <div>
                                    <h3>経緯と経過(200文字以内)</h3>
                                    <textarea key={`textarea-1${index}`} value={data.process} placeholder={'入力してください'}  onChange={(e) => handleChangeProcess(e.target.value,index)}></textarea>
                                </div>
                                <div>
                                    <h3>特性と対策(200文字以内)</h3>
                                    <textarea key={`textarea-2${index}`} value={data.characteristics_and_countermeasures} placeholder={'入力してください'}  onChange={(e) => handleChangeCharacteristicsAndCountermeasures(e.target.value, index)}></textarea>
                                </div>
                                <button className='newsBtnToggle' style={{marginRight:'20px', marginBottom:'20px'}} onClick={handicapQualificationsAdd}>複数追加</button>
                                {index !==0 ? <button className='deleteBtn' onClick={()=>handicapQualificationsDelete(index)}>削除</button> : <></>}   
                            </div>
                        ))}
                    </div>
                </div>
                <div className='mt20'>
                    <h1>配慮事項</h1>
                    <div className='bordered'>
                        <div>
                            <label>就労上必須の配慮事項</label>
                            <div className='dFlex'>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input className='radio' type="radio" name='considerationConditionsRequiredExists' value='true' checked={considerationConditionsRequiredExists? true : false} onChange={(e)=>onChangeConsiderationConditionsRequiredExists(e)} />有</label>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name='considerationConditionsRequiredExists' value='false' checked={!considerationConditionsRequiredExists? true: false}  onChange={(e)=>onChangeConsiderationConditionsRequiredExists(e)} />無</label>
                            </div>
                            {considerationConditionsRequiredExists ?
                            <>
                                <div style={{display:'ruby'}}>
                                    {considerationConditions && considerationConditions.map((considerationConditions, index) => (
                                        <label key={considerationConditions.id} style={{width:'33%'}}>
                                            <input  type="checkbox" checked={selectedConsiderationConditions.includes(considerationConditions.id) ? true : false} value={considerationConditions.id} onChange={(e) => onChangeConsiderationConditions(e, index)}></input>
                                            {considerationConditions.name}
                                        </label>
                                    ))}
                                </div>
                                <label>配慮事項</label>
                                <textarea placeholder={'入力してください'}  value={considerationConditionsSupplemental || ''} onChange={(e) => handleChangeConsiderationConditionsSupplemental(e.target.value)}></textarea>
                            </>
                            : <></>}
                            <label>あると嬉しい配慮事項</label>
                            <div style={{display:'ruby'}}>
                                {considerationConditions && considerationConditions.map((considerationConditions, index) => (
                                    <label key={considerationConditions.id} style={{width:'33%'}}>
                                        <input  type="checkbox" checked={selectedConsiderationConditionsPrefer.includes(considerationConditions.id) ? true : false} value={considerationConditions.id} onChange={(e) => onChangeConsiderationConditionsPrefer(e, index)}></input>
                                        {considerationConditions.name}
                                    </label>
                                ))}
                            </div>
                            <label>配慮事項</label>
                                <textarea placeholder={'入力してください'}  name='considerationConditionsPreferSupplemental' onChange={(e) => handleChangeConsiderationConditionsPreferSupplemental(e.target.value)}></textarea>
                        </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>就労支援機関/職務経歴</h1>
                    <div className='bordered'>
                        <div>
                            <label>就労上必須の配慮事項</label>
                            <div className='dFlex'>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input className='radio' type="radio" name='supportAgencyExists' value='true' checked={employmentSupportAgency[0].supportAgencyExists? true : false} onChange={(e)=>onChangeSupportAgencyExists(e)} />有</label>
                                <label  style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name='supportAgencyExists' value='false' checked={!employmentSupportAgency[0].supportAgencyExists? true: false}  onChange={(e)=>onChangeSupportAgencyExists(e)} />無</label>
                            </div>
                        </div>
                        {employmentSupportAgency[0].supportAgencyExists ?
                            <>
                                <div className='wid300'>
                                    <label>機関・企業名</label>
                                    <input type="text" value={employmentSupportAgency[0].organization || ''} onChange={(e)=>onChangeOrganization(e)} />
                                </div>
                                <div className='wid300'>
                                    <label>連絡先</label>
                                    <input type="text"  value={employmentSupportAgency[0].contactNumber || ''}  onChange={(e)=> onChangeContactNumber(e)} />
                                </div>
                                <div className='wid300'>
                                    <label>URL</label>
                                    <input type="text" value={employmentSupportAgency[0].url} onChange={(e)=> onChangeUrl(e)} />
                                </div>
                                <div>
                                    <label>エントリー企業との連携可否</label>
                                    <div className='dFlex'>
                                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input className='radio' type="radio" name='canContact' value='true' checked={employmentSupportAgency[0].canContact? true : false} onChange={(e)=>onChangeCanContact(e)} />有</label>
                                        <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input className='radio' type="radio" name='canContact' value='false' checked={!employmentSupportAgency[0].canContact? true: false}  onChange={(e)=>onChangeCanContact(e)} />無</label>
                                    </div>
                                </div>
                                <div className='dFlex'>
                                    <div style={{marginRight: '5px'}}>
                                        <label>利用期間 開始月</label>
                                        <input placeholder='入力してください' type="text" name="year" value={employmentSupportAgencyStartYear || ''} onChange={(e) => onChangeEmploymentSupportAgencyStartYear(e)}/>
                                    </div>
                                    <p style={{margin: '10px 5px'}}>年</p>
                                    <div style={{marginRight: '5px',marginTop:'20px'}}>
                                        <select onChange={(e) => onChangeEmploymentSupportAgencyStartMonth(e)}>
                                            <option value="" disabled>--</option>
                                            {[...Array(12)].map((_, index) => (
                                                <option key={index + 1} value={index + 1}>
                                                    {index + 1}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <p style={{margin: '10px 5px'}}>月</p>
                                </div>
                                <div>
                                    <label>訓練内容（２００文字以内）</label>
                                    <textarea placeholder={'入力してください'} value={considerationConditionsPreferSupplemental || ''}  onChange={(e) => handleChangeConsiderationConditionsPreferSupplemental(e.target.value)}></textarea>
                                </div>
                            </>
                        : <></>}
                        <div>
                            <label>ナビゲーションブック</label>
                            <dd>
                                {navigationBookURL && (
                                    <a
                                    href={navigationBookURL}
                                    target="_blank"
                                    className="btn link orange"
                                    style={{fontSize:'14px', background:'#ee7743', color:'#fff', display:'inline', borderRadius:'5px', padding:'5px 10px', }}
                                    rel="noopener noreferrer"
                                    >
                                    ナビゲーションブック
                                    <i className="mdi mdi-download" />
                                    </a>
                                )}
                                <div className="flex_box" style={{marginTop:'50px', marginBottom:'20px'}}>
                                    <label style={{color:'#00699f', border:'1px solid #00699f', padding:'5px 10px', borderRadius:'5px', display:'inline'}}>
                                        <input
                                        type="file"
                                        ref={fileInputRef}
                                        accept=".pdf"
                                        onChange={setPdf}
                                         style={{display:'none'}} />ファイルを選択
                                    </label>
                                    
                                </div>
                            </dd>
                        </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>職務経歴</h1>
                    <div className='bordered'>
                        <div>
                            <label>職歴数</label>
                            <select value={jobCareerCountId || ''} onChange={(e)=> onCHangeJobCareerCounts(e)}>
                                {jobCareerCounts && jobCareerCounts.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {jobCareerCountId && jobCareerCountId != 1 && jobCareerCountId !== '' ?
                         <>
                            <div>
                                <label>会社名</label>
                                <input type="text" placeholder='選択してください' value={jobCareerRecent[0].company || ''} onChange={(e)=> jobCareerRecentCompanyChange(e)} className='wid300' />
                            </div>
                            <div className='dFlex'>
                                <div style={{marginRight: '5px'}}>
                                    <label>就業開始年月</label>
                                    <input placeholder='入力してください' type="text" name="year" value={jobCareerRecentArrivalYear || ''} onChange={(e) => onChangeJobCareerRecentArrivalYear(e)}/>
                                </div>
                                <p style={{margin: '10px 5px'}}>年</p>
                                <div style={{marginRight: '5px',marginTop:'20px'}}>
                                    <select value={jobCareerRecentArrivalMonth || ''} onChange={(e) => onChangeJobCareerRecentArrivalMonth(e)}>
                                        <option value="" disabled selected>選択してください</option>
                                        {[...Array(12)].map((_, index) => (
                                            <option key={index + 1} value={index + 1}>
                                                {index + 1}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <p style={{margin: '10px 5px'}}>月</p>
                            </div>
                            <div className='dFlex'>
                                <div style={{marginRight: '5px'}}>
                                    <label>就業終了年月</label>
                                    <input placeholder='入力してください' type="text" name="year" value={jobCareerRecentDepartureYear || ''} onChange={(e) => onChangeJobCareerRecentDepartureYear(e)}/>
                                </div>
                                <p style={{margin: '10px 5px'}}>年</p>
                                <div style={{marginRight: '5px',marginTop:'20px'}}>
                                    <select value={jobCareerRecentDepartureMonth || ''} onChange={(e) => onChangeJobCareerRecentDepartureMonth(e)}>
                                        <option value="" disabled selected>選択してください</option>
                                        {[...Array(12)].map((_, index) => (
                                            <option key={index + 1} value={index + 1}>
                                                {index + 1}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <p style={{margin: '10px 5px'}}>月</p>
                            </div>
                            <div>
                                <label>業界</label>
                                <select value={jobCareerRecent[0].industryId || ''}  onChange={(e)=>industriesChange(e)}>
                                    <option value={""} selected disabled>選択してください</option>
                                    {industries && industries.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>職種</label>
                                <select value={jobCareerRecent[0].jobCategoryId} onChange={(e)=>jobCategoryIdChange(e)}>
                                    <option value={""} selected disabled>選択してください</option>
                                    {industries && industries.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>業務内容</label>
                                <textarea name="businessContent" id="" value={jobCareerRecent[0].businessContent} onChange={(e)=> businessContentChange(e)}></textarea>
                            </div>
                            {jobCareerAppeals.length == 0 ? <button className="newsBtnToggle" onClick={()=> AddJobCareerAppeal()} >アピールしたい職歴を追加<i className="mdi mdi-plus" /></button>: <></>}
                            <div>
                                {jobCareerAppeals && jobCareerAppeals.map((data,index)=> (
                                    <div key={index}>
                                        <div>
                                            <label>会社名</label>
                                            <input type="text" className='wid300' placeholder='選択してください'  value={jobCareerAppeals[index].company || ''} onChange={(e)=> jobCareerAppealsCompanyNameChange(e,index)} />
                                        </div>
                                        <div className='dFlex'>
                                            <div style={{marginRight: '5px'}}>
                                                <label>就業開始年月</label>
                                                <input placeholder='入力してください' type="text" name="year" value={jobCareerDate[index].arrival_year || ''}  onChange={(e) => jobCareerAppealsArrivalYear(e,index)}/>
                                            </div>
                                            <p style={{margin: '10px 5px'}}>年</p>
                                            <div style={{marginRight: '5px',marginTop:'20px'}}>
                                                <select value={jobCareerDate[index].arrival_month || ''} onChange={(e) => jobCareerAppealsArrivalMonth(e,index)}>
                                                    <option value="" disabled selected>選択してください</option>
                                                    {[...Array(12)].map((_, index) => (
                                                        <option key={index + 1} value={index + 1}>
                                                            {index + 1}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <p style={{margin: '10px 5px'}}>月</p>
                                        </div>
                                        <div className='dFlex'>
                                            <div style={{marginRight: '5px'}}>
                                                <label>就業終了年月</label>
                                                <input placeholder='入力してください' type="text" name="year" value={jobCareerDate[index].departure_year || ''} onChange={(e) => jobCareerAppealsDepartureYear(e,index)}/>
                                            </div>
                                            <p style={{margin: '10px 5px'}}>年</p>
                                            <div style={{marginRight: '5px',marginTop:'20px'}}>
                                                <select value={jobCareerDate[index].departure_month || ''} onChange={(e) => jobCareerAppealsDepartureMonth(e,index)}>
                                                    <option value="" disabled selected>選択してください</option>
                                                    {[...Array(12)].map((_, index) => (
                                                        <option key={index + 1} value={index + 1}>
                                                            {index + 1}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <p style={{margin: '10px 5px'}}>月</p>
                                        </div>
                                        <div>
                                            <label>業界</label>
                                            <select  value={jobCareerAppeals[index].industry_id || ''} onChange={(e)=>jobCareerAppealsIndustriesChange(e,index)}>
                                                <option value={""} selected disabled>選択してください</option>
                                                {industries && industries.map((data) => (
                                                    <option key={data.id} value={data.id}>
                                                        {data.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label>職種</label>
                                            <select value={jobCareerAppeals[index].job_category_id || ''} onChange={(e)=>jobCareerAppealsJobCategoryChange(e,index)}>
                                                <option value={""} selected disabled>選択してください</option>
                                                {industries && industries.map((data) => (
                                                    <option key={data.id} value={data.id}>
                                                        {data.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label>業務内容</label>
                                            <textarea name="businessContent" id=""   value={jobCareerAppeals[index].business_content || ''} onChange={(e)=> jobCareerAppealsBusinessContentChange(e,index)}></textarea>
                                        </div>
                                        <div className="btn_box">
                                            <button style={{marginRight:'20px'}} className="newsBtnToggle" onClick={()=> AddJobCareerAppeal()} >アピールしたい職歴を追加<i className="mdi mdi-plus" /></button>
                                            <button className='deleteBtn' onClick={()=>jobCareerAppealDelete(index)}>削除</button> 
                                        </div>
                                    </div>
                                ))}
                            </div>
                         </>
                        : <></>}
                        <div>
                            <h3 style={{marginBottom:'20px'}}>アピールしたい経験職種</h3>
                            <div>
                                <label>経験職種1</label>
                                <select  name='jobCategory1'  value={experienceJobCategory[0].jobCategoryId_1}  onChange={(e)=>jobCategoryChange1(e)} >
                                    <option value={""} selected disabled>選択してください</option>
                                    {jobCategory && jobCategory.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>経験職種2</label>
                                <select  name='jobCategory2'  value={experienceJobCategory[0].jobCategoryId_2}  onChange={(e)=>jobCategoryChange2(e)} >
                                    <option value={""} selected disabled>選択してください</option>
                                    {jobCategory && jobCategory.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>経験職種3</label>
                                <select name='jobCategory3' value={experienceJobCategory[0].jobCategoryId_3} onChange={(e)=>jobCategoryChange3(e)}>
                                    <option value={""} selected disabled>選択してください</option>
                                    {jobCategory && jobCategory.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>スキル</h1>
                    <div className='bordered'>
                        <div>
                            <label>Word</label>
                            <div className='dFlex'>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input className='radio' type="radio" name='word_experience' value='true' checked={oaSkills[0].wordExperience ? true : false} onChange={(e)=>onChangeWordExperience(e)} />あり</label>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input className='radio' type="radio" name='word_experience' value='false' checked={!oaSkills[0].wordExperience ? true : false} onChange={(e)=>onChangeWordExperience(e)} />なし</label>
                            </div>
                        </div>
                        <div>
                            <label>アピールしたいWordスキル</label>
                            <div style={{display:"ruby"}}>
                                {oaSkillsData && oaSkillsData
                                    .filter(data => data.oa_skill_category_id === 1)
                                    .map((d,index) => (
                                        <label key={d.id} style={{width:"50%", float:'left'}}>
                                            <input type="checkbox" disabled={!oaSkills[0].wordExperience} checked={oaSkills[0].word.includes(d.id) ? true : false} name={d.name} value={d.id}  onChange={(e)=>handleCheckboxWord(e,index)} />
                                            <span>{ d.name }</span>
                                        </label>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label>Excel</label>
                            <div className='dFlex'>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input className='radio' type="radio" name='excel_experience' value='true' checked={oaSkills[0].excelExperience ? true : false} onChange={(e)=>onChangeExcelExperience(e)} />あり</label>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input className='radio' type="radio" name='excel_experience' value='false' checked={!oaSkills[0].excelExperience ? true : false} onChange={(e)=>onChangeExcelExperience(e)} />なし</label>
                            </div>
                        </div>
                        <div>
                            <label>アピールしたいExcelスキル</label>
                            <div style={{display:"ruby"}}>
                                {oaSkillsData && oaSkillsData
                                    .filter(data => data.oa_skill_category_id === 2)
                                    .map((d,index) => (
                                        <label key={d.id} style={{width:"50%", float:'left'}}>
                                            <input type="checkbox" disabled={!oaSkills[0].excelExperience} checked={oaSkills[0].excel.includes(d.id) ? true : false} name={d.name} value={d.id}  onChange={(e)=>handleCheckboxExcel(e,index)} />
                                            <span>{ d.name }</span>
                                        </label>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label>アピールしたいデザイン系・その他事務系スキル</label>
                            <div style={{display:"ruby"}}>
                                {oaSkillsData && oaSkillsData
                                    .filter(data => data.oa_skill_category_id === 3)
                                    .map((d,index) => (
                                        <label key={d.id} style={{width:"50%", float:'left'}}>
                                            <input type="checkbox"  checked={oaSkills[0].design.includes(d.id) ? true : false} name={d.name} value={d.id}  onChange={(e)=>handleCheckboxDesign(e,index)} />
                                            <span>{ d.name }</span>
                                        </label>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label>アピールしたいプログラミング系スキル</label>
                            <div style={{display:"ruby"}}>
                                {oaSkillsData && oaSkillsData
                                    .filter(data => data.oa_skill_category_id === 4)
                                    .map((d,index) => (
                                        <label key={d.id} style={{width:"50%", float:'left'}}>
                                            <input type="checkbox"  checked={oaSkills[0].program.includes(d.id) ? true : false} name={d.name} value={d.id}  onChange={(e)=>handleCheckboxProgram(e,index)} />
                                            <span>{ d.name }</span>
                                        </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>資格</h1>
                    <div className='bordered'>
                        <div>
                            <label >アピールしたい資格1</label>
                            <select name='qualifications1' value={qualification[0].qualificationId_1 || ''} onChange={(e)=> handleChangeQualifications1(e)}>
                                <option value={""} selected disabled>選択してください</option>
                                {qualifications && qualifications.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label >アピールしたい資格2</label>
                            <select name='qualifications2' value={qualification[0].qualificationId_2 || ''}  onChange={(e)=> handleChangeQualifications2(e)}>
                                <option value={""} selected disabled>選択してください</option>
                                {qualifications && qualifications.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label >アピールしたい資格3</label>
                            <input type="text"  className='wid300' value={qualification[0].qualification3 || ''}  name='qualifications3'  onChange={(e)=> handleChangeQualifications3(e)}/>
                        </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>自己PR(200文字以内)</h1>
                    <textarea placeholder='入力してください' value={selfPr[0].content || ''} onChange={(e)=> setSelfPrChange(e)}></textarea>
                </div>
                <div className='mt20'>
                    <h1>アピールしたい語学</h1>
                    <div className='bordered'>
                        <div className='dFlex'>
                            <div style={{marginRight:'20px'}}>
                                <label>語学1</label>
                                <select name='languages1' value={languageStudy[0].languageId_1 } onChange={(e)=> handleChangeLanguages1(e)}>
                                    <option value={""} selected disabled>選択してください</option>
                                    {languages && languages.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>語学1 レベル</label>
                                <select name='levels1'  value={languageStudy[0].levelId_1|| ''}  onChange={(e)=> handleChangeLevels1(e)} disabled={languageStudy[0].languageId_1 == null}>
                                    <option value={""} selected disabled>選択してください</option>
                                    {levels && levels.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        
                       {!isLanguageFirst ? <div><button className='newsBtnToggle' onClick={()=>setIsLanguageFirst(true)}>複数を追加</button></div> : <></>} 
                        {isLanguageFirst ?
                            <>
                             <div className='dFlex'>
                                <div style={{marginRight:'20px'}}>
                                    <label>語学2</label>
                                    <select name='languages2' value={languageStudy[0].languageId_2 }   onChange={(e)=> handleChangeLanguages2(e)}>
                                        <option value={""} selected disabled>選択してください</option>
                                        {languages && languages.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label>語学2 レベル</label>
                                    <select name='levels2' value={languageStudy[0].levelId_2|| ''}  onChange={(e)=> handleChangeLevels2(e)} disabled={languageStudy[0].languageId_2 == null}>
                                        <option value={""} selected disabled>選択してください</option>
                                        {levels && levels.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {!isLanguageSecond ? <div ><button className='newsBtnToggle' onClick={()=>setIsLanguageSecond(true)} style={{marginRight:'10px'}}>複数を追加</button>
                            <button className='deleteBtn' onClick={()=>setIsLanguageFirst(false)}>削除</button></div> : <></>}
                            </>
                            : <></>}
                            {isLanguageSecond ? 
                                <>
                                    <div style={{marginRight:'20px'}}>
                                        <label>語学3</label>
                                        <input type="text" value={languageStudy[0].language3 }   name='languages3' className='wid300'  onChange={(e)=> handleChangeLanguages3(e)} />
                                    </div>
                                    <button className='deleteBtn' onClick={()=>setIsLanguageSecond(false)}>削除</button>
                                </>
                            : <></>}
                        
                    </div>
                </div>
                <div className='mt20'>
                    <h1>希望条件</h1>
                    <div className='bordered'>
                        <div>
                            <label>仕事の探し方 <span style={{ color: 'red' }}>※必須</span></label>
                            <select name='levels2' value={preferred[0].searchPolicyId} onChange={(e)=> handleChangeSearchPolicies(e)}>
                                <option value={""} selected disabled>選択してください</option>
                                {searchPolicies && searchPolicies.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>働き方</label>
                            <div style={{display:'ruby'}}>
                                {workStyles && workStyles.map((workStyles, index) => (
                                    <label key={workStyles.id} style={{width:'33%', float:'left'}}>
                                        <input  type="checkbox" checked={selectedWorkStyles.includes(workStyles.id) ? true : false} value={workStyles.id} onChange={(e) => onChangeWorkStyles(e, index)}></input>
                                        {workStyles.name}
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label>就労時間</label>
                            <div className='dFlex'>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}> <input type="radio" className='radio' name={'working_gour'} checked={preferred[0].workingHourId == 2 ? true : false} value={2}  onChange={(e)=>workingHourIdChange(e) } />フルタイム</label>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}> <input type="radio" className='radio' name={'working_gour' } checked={preferred[0].workingHourId == 1 ? true : false}  value={1}  onChange={(e)=>workingHourIdChange(e) } />フルタイム未満 </label>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}> <input type="radio" className='radio' name={'working_gour'} checked={preferred[0].workingHourId == 3 ? true : false}  value={3}   onChange={(e)=>workingHourIdChange(e) }/>どちらでも</label>
                            </div>
                        </div>
                        <div>
                            <label>在宅リモートワーク</label>
                            <div className='dFlex'>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}> <input type="radio" className='radio' name={'work_from_home'} checked={preferred[0].workFromHomeId == 2 ? true : false} value={2}  onChange={(e)=>workFromHomeIdChange(e) } />希望する</label>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}> <input type="radio" className='radio' name={'work_from_home' } checked={preferred[0].workFromHomeId == 1 ? true : false}  value={1}  onChange={(e)=>workFromHomeIdChange(e) } />希望しない</label>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}> <input type="radio" className='radio' name={'work_from_home'} checked={preferred[0].workFromHomeId == 3 ? true : false}  value={3}   onChange={(e)=>workFromHomeIdChange(e) }/>どちらでも</label>
                            </div>
                        </div>
                        <div>
                            <label>勤務地1 <span style={{ color: 'red' }}>※必須</span></label>
                            <select name='prefectures1' value={preferred[0].prefectureId_1 ||""} onChange={(e)=> handleChangePrefectures(e)}>
                                <option value={""} selected disabled>選択してください</option>
                                {prefectures && prefectures.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>勤務地2</label>
                            <select name='prefectures2' value={preferred[0].prefectureId_2 ||""}  onChange={(e)=> handleChangePrefectures2(e)}>
                                <option value={""} selected disabled>選択してください</option>
                                {prefectures && prefectures.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>転勤の可否</label>
                            <div className='dFlex'>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name='nationwide' value='true' checked={preferred[0].nationwide ? true : false} onChange={(e)=>onChangeNationwide(e)} />可</label>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name='nationwide' value='false' checked={!preferred[0].nationwide ? true : false} onChange={(e)=>onChangeNationwide(e)} />否</label>
                            </div>
                        </div>
                        <div>
                            <label>通勤手段</label>
                            <div>
                                {commutingMethods && commutingMethods.map((commutingMethod, index) => (
                                        <label key={commutingMethod.id}>
                                            <input  type="checkbox" checked={preferred[0].commutingMethods.includes(commutingMethod.id) ? true : false} value={commutingMethod.id} onChange={(e) => onChangeCommutingMethods(e, index)} ></input>
                                            {commutingMethod.name}
                                        </label>
                                    ))}
                            </div>
                            
                        </div>
                        <div className='dFlex'>
                            <div style={{marginRight:'20px'}}>
                                <label>業界1</label>
                                <select value={preferred[0].industryId_1 ||""} onChange={(e)=>preferredindustriesChange1(e)}>
                                    <option value={""} selected disabled>選択してください</option>
                                    {industries && industries.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>業界2</label>
                                <select value={preferred[0].industryId_2 ||""} onChange={(e)=>preferredindustriesChange2(e)}>
                                    <option value={""} selected disabled>選択してください</option>
                                    {industries && industries.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='dFlex'>
                            <div style={{marginRight:'20px'}}>
                                <label>職種1</label>
                                <select  name='preferredjobCategory1'  value={ preferred[0].jobCategoryId_1 || ""} onChange={(e)=>preferredJobCategoryChange1(e)} >
                                    <option value={""} selected disabled>選択してください</option>
                                    {jobCategory && jobCategory.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>職種2</label>
                                <select  name='preferredjobCategory2' value={preferred[0].jobCategoryId_2 || ''}  onChange={(e)=>preferredJobCategoryChange2(e)} >
                                    <option value={""} selected disabled>選択してください</option>
                                    {jobCategory && jobCategory.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <label>雇用形態</label>
                            <div style={{display:"ruby"}}>
                                {employmentStatuses && employmentStatuses.map((data,index) => (
                                        <label key={data.id} style={{width:"50%", float:'left'}}>
                                            <input type="checkbox"  checked={preferred[0].employmentStatusId.includes(data.id) ? true : false} name={data.name} value={data.id}  onChange={(e)=>handleEmploymentStatusId(e,index)} />
                                            <span>{ data.name }</span>
                                        </label>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label>年収</label>
                            <select  name='annualIncomes' value={preferred[0].annualIncomeId || ''}  onChange={(e)=>annualIncomesChange(e)} >
                                <option value={""} selected disabled>選択してください</option>
                                {annualIncomes && annualIncomes.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>求職ステータス</label>
                            <div className='dFlex'>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name='is_active' value='true' checked={isActive ? true : false} onChange={(e)=>onChangeIsActive(e)} />仕事を探している</label>
                                <label style={{marginRight:'15px', fontSize:'16px', fontWeight:'700'}}><input type="radio" className='radio' name='is_active' value='false' checked={!isActive ? true : false} onChange={(e)=>onChangeIsActive(e)} />仕事を探していない</label>
                            </div>
                        </div>
                        <div>
                            <label> 入社希望月</label>
                            <select  name='joinMonths' value={preferred[0].joinMonthId || ''} onChange={(e)=>joinMonthsChange(e)} >
                                <option value={""} selected disabled>選択してください</option>
                                {joinMonths && joinMonths.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>エントリー時に企業に伝えたい項目</h1>
                    <div className='bordered'>
                        <div>
                            <label>企業に伝えたい項目1</label>
                            <select  name='appealItem1' value={appealItem[0].appealItemId_1 || ''}  onChange={(e)=>appealItemsChange1(e)} >
                                <option value={""} selected disabled>選択してください</option>
                                {appealItems && appealItems.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>企業に伝えたい項目2</label>
                            <select  name='appealItem2' value={appealItem[0].appealItemId_2 || ''}   onChange={(e)=>appealItemsChange2(e)} >
                                <option value={""} selected disabled>選択してください</option>
                                {appealItems && appealItems.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>企業に伝えたい項目3</label>
                            <select  name='appealItem3' value={appealItem[0].appealItemId_3 || ''}   onChange={(e)=>appealItemsChange3(e)} >
                                <option value={""} selected disabled>選択してください</option>
                                {appealItems && appealItems.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>特記事項</h1>
                    <textarea  value= {specialContent || ''} onChange={(e)=> onChangeSpecialContent(e)}>

                    </textarea>
                </div>
            </div>
        </div>
           
            <div style={{display:'flex',position:'relative', alignItems:'center', justifyContent:'center',marginTop:'20px'}}>
                <button className='createBtn' style={{padding:'5px 100px', fontSize:'18px'}} onClick={()=>applicantEdit()}>更新</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AdminApplicantsEdit;