import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../redax/reducers/rootReducer";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import { fetchApplicantsRequest } from "../../../../redax/actions/applicantsActions/applicantsActions";
import { fetchScoutsEntryRequest } from "../../../../redax/actions/scoutsEntryActions/scoutsEntryActions";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import Loader from "../../../loader/loader";


function ScoutConfirm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [resourceId, setResourceId] = useState(null);
    const loginData = useSelector((state: RootState) => state.login);
    const applicantsData = useSelector((state: RootState) => state.applicants);
    const entryData = useSelector((state: RootState) => state.entry);
    const [familyName, setFamylyName] = useState(null);
    const [loadScreen, setLoadScreen] = useState(true);
    const [recruitments, setRecruitments] = useState<any>([]);
    const [load1, setLoad1] = useState(false);
    const [load2, setLoad2] = useState(false);
    const [load3, setLoad3] = useState(false);
    const [agree, setAgree] = useState(false);
    
    const location = useLocation();
    const data = location.state;
    let selectedRecruitmentNamesStr = data.selectedRecruitmentNamesStr;
    let applicants = data.applicants;
    let content = data.content;
    let templateName = data.templateName;

    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
          dispatch(fetchLoginRequest('', '',''))
        } else (
            navigate('/recruiter/login/')
        )
      }, [dispatch]);


    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/recruiter/init/');
            }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            const queryParams = new URLSearchParams()
            data.recruitmentIds.forEach(function(value: any) {
                queryParams.append('recruitment_ids[]', value);
            });
            dispatch(fetchScoutsEntryRequest(queryParams, 'scout', 'select'));
            setLoad1(true);
        }
    }, [loginData.pending]);

    useEffect(() => {
        if (entryData.pending && load1) {
            setLoad1(false);
            setLoadScreen(false);
            setRecruitments(entryData.entry.recruitments);
            dispatch(fetchScoutsEntryRequest(0, 'scout', 'get'));
            // setLoad2(true);
        }
        if (entryData.pending && load2) {
            setLoad2(false);
            setLoad3(true);
            // スカウトメッセージの送信
            dispatch(fetchScoutsEntryRequest({
                applicant_ids: applicants.map((obj: any) => obj.id),
                content: data.content,
                recruitment_ids: data.recruitmentIds
                }, 'scout', 'post'));            
        }
        if (entryData.pending && load3) {
            setLoadScreen(false);
            setLoad3(false);
            navigate('/recruiter/scouts/complete/', {state : {queryParamsString : data.queryParamString}});
            window.location.reload();
        } else if(entryData.error != null) {
            setLoadScreen(false);
            // navigate('/error?code=500');
        }
    }, [entryData, load1, load2, load3]);
      
    function logout() {
        if (localStorage.entryFlag) {
            localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
            localStorage.removeItem('jobParachannelAuthToken')
            navigate('/');
            window.location.reload();
        }
    }

    function replacedContent(value: any) {
        if (value == null) {
          return null
        }
        return value.replaceAll("\n", '<br>')
    }

    function back() {
        let forms = {content: content, templateName: templateName, saveTemplate: data.saveTemplate
            , applicants: applicants, recruitmentIds: data.recruitmentIds,queryParamsString : data.queryParamsString};
        navigate('/recruiter/scouts/entry/', {
            state: forms
        });
    }

    function submit() {
        if (!agree) {
            alert('未入力項目があります\n\n' + '注意事項')
            return false
        }
        setLoadScreen(true);
        
        // テンプレートの保存      
        if (data.saveTemplate) {
            setLoad2(true);
            dispatch(fetchScoutsEntryRequest({
                title: data.templateName,
                content: data.content
            }, 'template', 'post'));            
        } else {
            setLoad2(false);
            setLoad3(true);
            // スカウトメッセージの送信
            dispatch(fetchScoutsEntryRequest({
                applicant_ids: applicants.map((obj: any) => obj.id),
                content: data.content,
                recruitment_ids: data.recruitmentIds
                }, 'scout', 'post'));
            // setTimeout( () => navigate('/recruiter/scouts/complete/',{state : {queryParamsString : data.queryParamString}}), 1500);
        }
    }
      

    return (
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className ="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">スカウト</h2>
                <ul>
                <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            <div id="scouts" className="entry confirm">
                <form>
                {/* <!-- スカウト対象 --> */}
                <div className="cont1">
                    <h3>スカウト対象：{ applicants.length }名</h3>
                    <ul>
                    {applicants.map((applicant: any,index: any ) => (
                    <li key={applicant.id}>
                        <div>
                        <img src={applicant.face_photo} alt={applicant.family_name + applicant.first_name}/></div>
                        <p>ID:{ applicant.id }</p>
                    </li>
                    ))}
                    </ul>
                </div>

                <div className="cont2">
                    <h3><span>Step1</span>スカウトする求人：{ selectedRecruitmentNamesStr }</h3>
                </div>
                <div className="cont3">
                    <h3><span>Step2</span>メッセージ</h3>
                    <p>●求職者様名●</p><br/>
                    <p className="cont3__content">{ content }</p>
                    {recruitments.map((recruitment: any,index: any ) => (
                    <><h4>■ 求人情報</h4>
                    <div className="indent">
                        <img src={recruitment.thumbnail} alt=""/>
                        <p>
                        職種名：{ recruitment.job_title }<br/>
                        職種：{ recruitment.job_category_name }<br/>
                        給与：{ recruitment.salary_form } 【{ Number(recruitment.lower_monthly_basic_salary).toLocaleString() } 〜 { Number(recruitment.upper_monthly_basic_salary).toLocaleString() } 円】<br/>
                        業務内容：<span v-html="replacedContent(recruitment.business_content) || ''"></span><br/>
                        勤務地：{ recruitment.prefecture } { recruitment.city }<br/>
                        雇用形態：{ recruitment.employment_status }<br/>
                        <a href={'/recruitments/' + recruitment.id} target="_blank">求人の詳細はこちら</a>
                        </p>
                    </div></>
                    ))}
                    <dl>
                    <dt>今回のメッセージを送信テンプレートに保存する：</dt>
                    {data.saveTemplate ?
                    <dd>する(テンプレ名：{ templateName })</dd>
                    :
                    <dd>しない</dd>}
                    </dl>
                    <div>
                    <p>注意事項<br/>
                    ・同一人物に同じ求人票を連続してメールしていませんか？<br/>
                    ・選考中または不合格通知済みの求職者ではありませんか？<br/>
                    ・企業やその求人の魅力を本文に書いていますか？<br/><br/>
                    不適切なスカウトメールは求職活動の妨げとなります。<br/>
                    健全なサイト運営にご協力ください。</p>
                    <label><input type="checkbox" value="注意事項を確認しました" onChange={() => setAgree(!agree)} v-model="agree"/>注意事項を確認しました</label>
                    </div>
                </div>
                <div className="btn_box">
                    <a href="javascript:void(0)" onClick={back} className="btn back">戻る</a>
                    <a href="javascript:void(0)" onClick={submit} className="btn orange">送信する</a>
                </div>
                </form>
            </div>
        </section>
    </main>
    );
  }
  
  export default ScoutConfirm;