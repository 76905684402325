function Privacy() {
    return (
        <main id="privacy">
        <section className="wrapper">
          <h2 className="ttl type3">プライバシーポリシー</h2>
    
    <p className="wrap" style={{marginTop: '59px' }}>
    株式会社キャリアート（以下「当社」という。）では、企業と求職者をマッチングする障がい者雇用特化型メディア「パラちゃんねる」の運営を行っています。<br />
    その中で、皆様から任意で登録いただく皆様の個人情報に関し、以下のとおり個人情報保護方針を定め、個人情報の適切な保護に務めます。
    <br /><br />
    <strong>1. 個人情報の管理</strong><br />
    当社は、取得した個人情報を正確な状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩等を防止することに務めます。そのために、セキュリティシステムの維持・管理体制の整備・スタッフ教育の徹底等によって安全対策を実施し、個人情報の厳重な管理を行います。
    <br />個人情報とは、個人に関する情報であり、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの(他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む。)を指します。
    <br /><br />
    <strong>2. 個人情報の利用目的</strong><br />
    当社の活動・運営に伴って取得した個人情報は、あらかじめご本人（当該個人情報によって識別される特定の個人。以下同じ。）の同意を得た場合および個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令等により開示・提供が要求されている場合を除き、以下の目的のためにのみ利用します。
    <br />当社が共生社会の実現のために多様な価値観の浸透と多様な障害者雇用の促進と就業の定着を目的に無償で提供する「メディアサービス」（コラム・Youtubeサービス）および「就職・転職情報サービス」（求人情報提供サービスおよびスカウトサービス）、その他当社が提供する就職・転職支援サービス（以下、総称して「本サービス」といいます。）への登録者に対する、適切な情報提供およびマッチング（条件に合う仕事の検索・紹介）
    <br />応募者の承諾/同意に基づく企業への応募・開示
    <br />当社が運営する「パラちゃんねるカフェ」その他のサービス等に関するメルマガ等での情報提供
    <br />サービス改善のためのアンケートの実施、キャンペーン、モニター等への応募者に対する連絡
    <br />個人を特定できない集計・統計データとして利用
    <br />お問い合わせ、苦情及び相談への対応
    <br />当社の採用活動
    <br />ただし、ご本人の意思で、企業に個人情報を送信し応募された場合、当該応募先企業等に個人情報が提供されることになります。
    <br />その際には、応募先企業等が自ら定める個人情報管理規程に従い個人情報を管理することになります。
    <br /><br />
    <strong>3. 個人情報の第三者提供について</strong><br />
    当社が取得した個人情報は、次の場合を除いて第三者に提供いたしません。
    <br />・ご本人の同意がある場合(※)
    <br />・法令に基づき、提供に応じなければならない場合
    <br />・人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難な場合
    <br />・公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難な場合
    <br />・国の機関または地方公共団体またはその委託を受けた者が法令に定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼす恐れがある場合
    <br />・合併その他の事由による事業の承継に伴って提供する場合
    <br /><br />
    ※当社は個人情報を下記の目的で第三者提供することがあります。<br />
    目的：職業紹介等のため、お客様自身が本サービスを経由して応募した企業がお客様に連絡するため
    <br />項目：履歴書、職務経歴書等、氏名、生年月日、性別、メールアドレス、電話番号、その他お客様自身が応募フォームに入力された情報
    <br />手段：本サービスを経由したデータ通信、メール送信、電話、郵送等
    <br />相手：お客様自身が応募先として選択された企業・団体等、その他本サービスに関係する当社提携企業・団体等
    <br /> <br />
    <strong>4. 個人情報の開示・訂正・利用停止等</strong><br />
    当社は、個人情報の開示・訂正・利用停止等の請求がご本人により書面（メール含む）をもってなされた場合、適切な方法によって本人であることを確認した上で、かかる請求に対応いたします。ただし、当社の活動の適切な遂行を著しく妨げるおそれがあると認められる等の場合にはこの限りではありません。
    <br /><br />
    <strong>5．委託先の監督</strong><br />
    当サイトは、お客様へサービスを提供する等の業務遂行上、個人情報の一部を外部の委託先へ提供する場合があります。その場合、業務委託先が適切に個人情報を取り扱うように管理いたします。
    <br /><br />
    <strong>6. 法令等の遵守と見直し</strong><br />
    当社は、保有する個人情報に関して適用される日本の法令その他の規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
    <br /><br />
    <strong>7. 個人情報の取り扱いに関するお問い合わせについて</strong><br />
    個人情報に関するご確認、ご要望および問い合わせなどにつきましては、お問い合わせページよりご連絡をお願いいたします。
    </p>
    <p className="left">
    付則<br/>
    2021年12月1日　適用<br/>
    2022年1月10日　改訂
    </p>
        </section>
      </main>
       
    );
  }
  
  export default Privacy;