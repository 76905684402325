import { DownloadCareerTypes } from "../../ActionTypes/downloadCareerTypes ";
import { FetchDownloadCareerFailure, FetchDownloadCareerFailurePayload, FetchDownloadCareerRequest, FetchDownloadCareerSuccess, FetchDownloadCareerSuccessPayload } from "../../types/type";

export const fetchDownloadCareerRequest = (applicantId: any): FetchDownloadCareerRequest => ({
  type: DownloadCareerTypes.FETCH_DOWNLOAD_CAREER_REQUEST,
  applicantId
});

export const fetchDownloadCareerSuccess = (
  payload: FetchDownloadCareerSuccessPayload,
): FetchDownloadCareerSuccess => ({
  type: DownloadCareerTypes.FETCH_DOWNLOAD_CAREER_SUCCESS,
  payload
});

export const fetchDownloadCareerFailure = (
  payload: FetchDownloadCareerFailurePayload
): FetchDownloadCareerFailure => ({
  type: DownloadCareerTypes.FETCH_DOWNLOAD_CAREER_FAILURE,
  payload
});
