import { useState } from "react";


function Guide3() {

    
    return (
        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">3.求人検索・応募について</h3>
          <p>この記事は約3分で読めます。</p>
    
          <p>このページでは求人検索の入力について説明します。</p>
    
          <p>
            1.パラちゃんねる求人サイトTOP画面より
            <a href="/login/" style={{ display: 'inline', color: '#00a0e9' }}>ログイン／登録</a>
            をクリックします。
          </p>
          <img src="/assets/img/guide/first.png" alt=""/>
    
          <p>2.メールアドレス・パスワードを入力し、ログインをクリックします。<br/>
          （パスワードを忘れた場合）パスワードをお忘れの方をクリックしてください。</p>
          <img src="/assets/img/guide/03/02.png" alt=""/>
    
          <p>メールアドレスを入力して、再設定メール送信をクリックして下さい。</p>
          <img src="/assets/img/guide/03/03.png" alt=""/>
    
          <p>3.マイページのメニューより、お仕事を探すをクリックします。</p>
          <img src="/assets/img/guide/03/04.png" alt=""/>
    
          <p>4.求人一覧では、会員登録時に入力した職種や希望勤務地等に該当する求人を検索することができます。</p>
          <img src="/assets/img/guide/03/05.png" alt=""/>
    
          <p>4.気になる求人をクリックすると、求人詳細画面に遷移します。<br/>
          採用情報タブをクリックすると求人の詳細、会社概要タブをクリックするとその求人企業の情報を確認することができます。<br/>
          気になる企業はお気に入りに登録をクリックすると保存することができます。</p>
          <img src="/assets/img/guide/03/06.png" alt=""/>
          <img src="/assets/img/guide/03/07.png" alt=""/>
    
          <p>5.求人にエントリーする場合は、エントリーするをクリックします。<br/>
          エントリーするをクリックすると、WEB履歴書で入力した情報が表示され、<br/>
          各社に合わせて、編集することが可能です。 </p>
          <img src="/assets/img/guide/03/08.png" alt=""/>
    
          <p>以上が求人検索・応募になります。</p>
          <a href="/guide/" className="btn type3">一覧へ戻る</a>
        </section>
      </main>
    );
  }
  
  export default Guide3;