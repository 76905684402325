import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruitmentsDeleteTypes } from "../../ActionTypes/recruitmentsDeleteTypes";
import { fetchRecruitmentsDeleteFailure, fetchRecruitmentsDeleteSuccess } from "../../actions/recruitmentsDeleteActions/recruitmentsDeleteActions";


var postRecruitmentsDelete = (id:any) => axios.delete(API_BASE_ADDRESS + '/recruitments/' + id,  {
headers: {
  Authorization: localStorage.getItem('jobParachannelAuthToken'),
  'Content-Type': 'application/json',
  Accept: '*/*',
}}); 

function* fetchRecruitmentsDeleteSaga(id: any) {
  try {
    const response: AxiosResponse = yield call(postRecruitmentsDelete , id.id);
    yield put(
      fetchRecruitmentsDeleteSuccess({
        recruitmentsDelete: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitmentsDeleteFailure({
        error: e.message
      })
    );
  }
}

function* RecruitmentsDeleteSaga() {
  yield takeLatest(recruitmentsDeleteTypes.FETCH_RECRUIMENTS_DELETE_REQUEST, fetchRecruitmentsDeleteSaga);
}

export default RecruitmentsDeleteSaga;
