import { NavLink } from "react-router-dom";
import { RootState } from "../../redax/reducers/rootReducer";
import { fetchFavCountRequest } from "../../redax/actions/favCountActions/favCountActions";

import "./Recruiment.scss";
import { useDispatch, useSelector } from "react-redux";

function Recruitment(props: { recruitment: any; isLogin: any, temporary_is_favorite: any }) {
    let recruitment: any = [];
    let isLogin = '';
    let temporary_is_favorite = 0;
    if(props) {
        recruitment = props.recruitment;
        isLogin = props.isLogin;
        temporary_is_favorite = props.temporary_is_favorite;
    }
    const dispatch = useDispatch();
    const favCount = useSelector((state: RootState) => state.favCount);

    function replacedContent(value: any) {
        if (value === null) {
          return null;
        }
        return value.replaceAll("\n", '<br>');
    }

    function favoriteSwitch (num: any) {
      let message = (num === 1) ? 'お気に入りに登録しますか？' : 'お気に入り登録を解除しますか？'
      if (window.confirm(message)) {
        temporary_is_favorite = num
        // set favorite
        dispatch(fetchFavCountRequest(recruitment.id, 'post', 'recruitment'));
      }
    }
    
    return (
        <>
          <NavLink className = "data-v-7f9abc22" to={'/recruitments/' + recruitment.id}>
            <div className="img_box">
              {recruitment.company_plan.attributes.id && recruitment.company_plan.attributes.id >= 2 ? 
              <div className="ribbon19-content">
                <span className="ribbon19">PREMIUM</span>
              </div>
              : <></>}
              <img src={recruitment.thumbnail} alt="" />
              {isLogin == 'Applicant' ?
                <div className="heart">
                  {temporary_is_favorite == 0 ?
                  <a onClick={() => favoriteSwitch(1)}><img src="/assets/img/jobs/icon02.svg" alt="" /></a>
                  : <></>}
                  {temporary_is_favorite == 1 ?
                  <a onClick={() => favoriteSwitch(0)}><img src="/assets/img/jobs/icon03.svg" alt="" /></a>
                  : <></>}
                </div>
                : <></>}
            </div>
            <div className="text_box">
                {recruitment.is_new_publish ? <p className="publish">新着求人</p>: <></>}
                {recruitment.is_new_update ? <p  className="update">最終更新：24時間以内</p>: <></>}
              <h3>{ recruitment.job_title }／{ recruitment.company_name }</h3>
              <p>{ recruitment.salary_form } 【{ Number(recruitment.lower_monthly_basic_salary).toLocaleString()} 〜 { Number(recruitment.upper_monthly_basic_salary).toLocaleString() } 円】</p>
              <p className="tag">
              {recruitment.consideration_condition && recruitment.consideration_condition.map((i: any) => (
                    <span>{i.name}</span>
                ))}
              </p>
              <p className="category">{recruitment.employment_status }</p>
              <p className="new_graduate">{ recruitment.new_graduate }</p>
                {recruitment.type_of_recruiting_company ?
                <p className="type" v-show="recruitment.type_of_recruiting_company" >
                { recruitment.type_of_recruiting_company }
                </p>: <></>
                }
                <p className="address" dangerouslySetInnerHTML={{__html: replacedContent(recruitment.work_location) || ''}}></p>
              <p>
                <strong>業務内容</strong>
                <ul>
                {recruitment.preview_display_texts.map((text:any, index: any) => (
                    <li key={index}>{text}</li>
                ))}
                </ul>
              </p>
              {recruitment.already_applied ? <p className="done">エントリー済み</p>: <></>}
            </div>
          </NavLink>
        </>
    );
  }
  
  export default Recruitment;