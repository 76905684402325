import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import { RootState } from '../../../redax/reducers/rootReducer';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { useNavigate } from 'react-router-dom';
import { fetchTopRecruiterRequest } from '../../../redax/actions/topRecruiterActions/topRecruiterActions';
import Loader from '../../loader/loader';
import { fetchApplicantDecideRequest } from '../../../redax/actions/applicantDecideActions/applicantDecideActions';

function RecruiterIndex() {
    const dispatch = useDispatch();
    const [jsonObject, setJsonObject] = useState<any>(null);
    const [news, setNews] = useState<any[]>([]);
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [authenticate, setAuthenticate] = useState(null);
    const [url, setUrl] = useState('');
    const [filteredNews, setFilteredNews] = useState<any[]>([]);
    const [applications, setApplications] = useState<any[]>([]);
    // const [activities_range, setActivitiesRange] = useState<any[]>([]);
    const [schedules, setSchedules] = useState<any[]>([]);
    const [show, setShow] = useState<{ [key: number]: boolean }>({});
    const [slide, setSlide] = useState(0);
    const [recommend_modals, setRecommendModals] = useState<any[]>([]);
    const [totalPage, setTotalPage] = useState<any>(10);
    const [isRecommendModalOpen, setModalOpen] = useState(false);
    const [selectedRange, setSelectedRange] = useState<any[]>([]);
    const [totalActivities, setTotalActivities] = useState<any[]>([]);
    const [activities, setActivities] = useState<any[]>([]);
    const [relected_activities_range, setRelectedRange] = useState('');
    const [rangeStart, setStartRange] = useState('');
    const [rangeEnd, setEndRange] = useState('');
    const [id, setId] = useState(false);
    const [count, setCount] = useState<any>(null);
    let perPage = 5;
    const [page, setPage] = useState(1);
    const [loadScreen, setLoadScreen] = useState(true);
    const navigate = useNavigate();
    const pageTitle = '企業様マイページ｜パラちゃんねる';
    document.title = pageTitle;

    useEffect(() => {
      if (!localStorage.getItem('jobParachannelAuthToken')) {
        navigate('/');
      } else {
        if(localStorage.getItem('load') == '1') {
          localStorage.setItem('load', '2');
          window.location.reload();
        }
        // dispatch(fetchLoginRequest('', '',''));
      }
    }, [navigate, dispatch]);
  


    const loginData = useSelector((state: RootState) => state.login);
    const topRecruiter = useSelector((state: RootState) => state.topRecruiter);
    const decides = useSelector((state: RootState) => state.decide);
  
    useEffect(() => {
      if (loginData.pending) {
        if(!loginData.login.is_init_complete) {
          navigate('/recruiter/init/');
        }
        dispatch(fetchTopRecruiterRequest(loginData.login.resource.id, ''));
        dispatch(fetchApplicantDecideRequest(id, null, 'get'));
        setResourceId(loginData.login.resource.id);
        setFamylyName(loginData.login.resource.family_name);
        // setAuthenticate(loginData.login.authenticate.resource_type);
      }
    }, [loginData.pending]);

    useEffect(() => {
      let tmp: any = [];
      let tmp1: any = [];
      let tmp2: any = [];
      let tmp3: any = [];
      let tmp4: any = [];

      if (topRecruiter.pending) {
        setNews(topRecruiter.recruiter.news);
        if(topRecruiter.recruiter.applications !== undefined){
          tmp1 = topRecruiter.recruiter.applications;
        }
        if(topRecruiter.recruiter.schedules !== undefined){
          tmp2 = topRecruiter.recruiter.schedules;
        }
        if(topRecruiter.recruiter.activities_range !== undefined) {
          tmp = topRecruiter.recruiter.activities_range;
        }
        if(topRecruiter.recruiter.total_activities !== undefined) {
          tmp3 = topRecruiter.recruiter.total_activities;
        }
        if(topRecruiter.recruiter.activities !== undefined) {
          tmp4 = topRecruiter.recruiter.activities;
        }
        if (topRecruiter.recruiter.recommend_modal !== undefined && topRecruiter.recruiter.recommend_modal.length > 0 ) {
          setRecommendModals(topRecruiter.recruiter.recommend_modal)
          setModalOpen(true)
        }
        setApplications(tmp1);
        setSchedules(tmp2);
        setSelectedRange(tmp)
        setTotalActivities(tmp3)
        setActivities(tmp4)
        setLoadScreen(false);
      } else if(topRecruiter.error != null) {
        setLoadScreen(false);
        navigate('/error?code=500');
      }
    }, [topRecruiter, recommend_modals]);

    useEffect(() => {
      if (decides.pending) {
        if(decides.decide.last_decide == null) {
          return;
        }
        if (localStorage.jobParachannelLastDecide) {          
          const date1 = new Date(localStorage.jobParachannelLastDecide)
          const date2 = new Date(decides.decide.last_decide)
          if (date1 < date2) {
            localStorage.jobParachannelLastDecide = decides.decide.last_decide
          } else {
            return
          }
        } else {
          localStorage.jobParachannelLastDecide = decides.decide.last_decide
        }
      } else if(decides.error != null) {
        setLoadScreen(false);
        navigate('/error?code=500');
      }
  }, [decides]);

    const handleRangeChange = (event: any) => {
      const { value } = event.target;
      const selectedData = selectedRange.find(data => data.name === value);
      setSelectedRange(selectedData);
    };

    function contClass(num: any) {
      return num + 5;
    }


    useEffect(() => {
      const startIndex = (page - 1) * perPage;
      const endIndex = page * perPage;
      if (news) {
        const tmp = news.slice(startIndex, endIndex);
        setFilteredNews(tmp);
        const totalPage =  Math.ceil(news.length / 5)
        setTotalPage(totalPage);
        const count = news.length
        setCount(count);

      }
    }, [news, page]);

    function logout() {
      if (localStorage.entryFlag) {
        localStorage.removeItem('entryFlag')
      }
      if (localStorage.jobParachannelAuthToken) {
        localStorage.removeItem('jobParachannelAuthToken')
        navigate('/');
        window.location.reload();
      }
    }
    const newsToggle = (index: any) => {
      setShow((prevShow) => ({
        ...prevShow,
        [index]: !prevShow[index],
      }));
    };

    async function newsLink(news: any, url: any) {
      if (news.read_url) {
        console.log(news.read_url);
        // try {
        //   await axios.post(news.read_url, {}, {
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Authorization: localStorage.jobParachannelAuthToken,
        //     },
        //   });
        // } catch (error) {
        //   console.error('Failed to mark news as read:', error);
        // }
      }
    
      const sameUrlLink = url.startsWith(process.env.REACT_APP_BASE_URL);
      if (sameUrlLink) {
        window.location.href = url.replace(process.env.REACT_APP_BASE_URL, '');
      } else {
        window.open(url, '_blank');
      }
    }

    const onPrev = () => {
      if (page > 1) {
        setPage(page - 1);
      }
    };
  
    const onNext = () => {
      if (page < totalPage) {
        setPage(page + 1);
      }
    };

    const nextSlide = () => {
      if (slide + 1 >= recommend_modals.length) {
        setSlide(0);
        return;
      }
      setSlide(slide + 1);
    };
  
    const prevSlide = () => {
      if (slide <= 0) {
        setSlide(recommend_modals.length - 1);
        return;
      }
      setSlide(slide - 1);
    };
  
    const replacedContent = (value: any) => {
      if (value == null) {
        return null;
      }
      return value.replaceAll('\n', '<br>');
    };
    useEffect(() => {
      if(id) {
        dispatch(fetchTopRecruiterRequest(loginData.login.resource.id, id));
      }
    }, [id]);
    
    const fetchActivitiesRange = (value: any) => {
      let dt = JSON.parse(value.target.value);
      setId(dt.id);
      setRelectedRange(dt);
      setStartRange(dt.start);
      setEndRange(dt.end);
      console.log(relected_activities_range);
    };

    return (
      
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <p></p>
                <ul>
                <li><a href="javascript:void(0)" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            <div className="top mypage">
                <div>
                  <h2 className="ttl">お知らせ</h2>
                  {news && news.length == 0 ? 
                  <ul className="cont1">
                      <li>
                          <div><p>お知らせはありません</p></div>
                      </li>
                  </ul>: 
                  <ul className="cont1 news">
                          {filteredNews.map((news:any, index:any) => (
                            <li key={`news-${index}`} className={` ${show[index] ? 'active' : ''} ${news.is_scout && !news.is_read ? 'scout' : ''}`}>
                              <a href="#" onClick={(e) => { e.preventDefault(); newsToggle(index); }}>
                                <p className="date">{news.date}</p>
                                { !news.is_read && <p className="new">NEW</p> }
                                <p>{news.title}</p>
                              </a>
                              { show[index] && (
                                <div>
                                  { news.message && <p style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{news.message}</p> }
                                  { news.url && (
                                    <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.url); }}>{news.content}</a>
                                  )}
                                  { !news.url && news.content && <p style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{news.content}</p> }
                                  { news.sub_url1 && (
                                    <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.sub_url1); }}>{news.sub_content1}</a>
                                  )}
                                  { !news.sub_url1 && news.sub_content1 && <p style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{news.sub_content1}</p> }
                                  { news.sub_url2 && (
                                    <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.sub_url2); }}>{news.sub_content2}</a>
                                  )}
                                  { !news.sub_url2 && news.sub_content2 && <p style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{news.sub_content2}</p> }
                                </div>
                              )}
                            </li>
                          ))}
                    </ul>
                  }
                   <div className="btn_box">
                      <a href="#" onClick={onPrev} className={page <= 1 ? 'disabled' : ''}>
                        <img src="/assets/img/company/icon19.svg" alt="" />
                      </a>
                      <a href="#" onClick={onNext} className={page >= totalPage ? 'disabled' : ''}>
                        <img src="/assets/img/company/icon20.svg" alt="" />
                      </a>
                    </div>
                </div>
                <div>
                  <h2 className="ttl">選考待ちの応募者</h2>
                  {applications.length === 0 ?
                  <ul>
                    <li>
                      <div><p>応募者はいません</p></div>
                    </li>
                  </ul>
                  :
                  <ul className="cont2">
                     {applications.map((applications:any, index:any) => (
                      <li key={'applications'+{index}}>
                        <a href={'/recruiter/applications/' + applications.id}>
                          <p className="name">{ applications.date }</p>
                          <p className="name">{ applications.applicant_name }</p>
                          <p>{ applications.job_title }</p>
                        </a>
                      </li>
                     ))}
                  </ul>
                  }
                  {applications.length  ? <a href="/recruiter/applications/" className="btn type3" v-if="applications_list.length">もっと見る</a> : <></>} 
                </div>

                <div>
                  <h2 className="ttl">直近のスケジュール</h2>
                  { schedules.length == 0 ?
                    <ul className="cont1" v-if="schedules_list.length == 0">
                      <li>
                        <div><p>スケジュールはありません</p></div>
                      </li>
                    </ul>
                  : 
                  <ul className="cont3 schedule">
                     {schedules.map((schedule:any, index:any) => (
                      <li key={'schedules'+{index}}>
                        <div>
                          <p className="name">{ schedule.date }</p>
                          <p className="category traning">{ schedule.status }</p>
                          <p>{ schedule.job_title }</p>
                        </div>
                      </li>
                     ))}
                  </ul>
                  }
                  {schedules.length  ? <a href="/mypage/schedules/" className="btn type3">もっと見る</a> : <></>} 
                </div>
                <div>
                  <h2 className="ttl">求人状況</h2>
                  <div className='select_box'>
                    <select name="schedule" id="" value={JSON.stringify(relected_activities_range || '期間を選択してください')} onChange={(event) => fetchActivitiesRange(event)} >
                      <option disabled value=''>期間を選択してください</option>
                      {selectedRange.map((data: any, index: any) => (
                        <option value={JSON.stringify(data)} key={'range-' + index}>{data.name}</option>
                      ))}
                    </select>
                    {relected_activities_range !== ''&& rangeStart && rangeEnd ?
                    <p>{rangeStart}〜{rangeEnd}</p>
                    : <></>}
                  </div>
                  {activities.length == 0 ?
                  <div>
                    <p>求人はありません</p>
                  </div>
                  : 
                  <>
                    <div>
                      <h3 className="ttl">合計</h3>
                      <ul className="cont4">
                          <li>
                            <p>審査待ち</p>
                            <p><span>{totalActivities[1]}</span>名</p>
                          </li>
                          <li>
                            <p>面接設定済み</p>
                            <p><span>{totalActivities[2]}</span>名</p>
                          </li>
                          <li>
                            <p>面接済</p>
                            <p><span>{totalActivities[3]}</span>名</p>
                          </li>
                          <li>
                            <p>体験・実習設定済</p>
                            <p><span>{totalActivities[4]}</span>名</p>
                          </li>
                          <li>
                            <p>体験・実習済</p>
                            <p><span>{totalActivities[5]}</span>名</p>
                          </li>
                          <li>
                            <p>内定通知</p>
                            <p><span>{totalActivities[6]}</span>名</p>
                          </li>
                          <li>
                            <p>採用決定</p>
                            <p><span>{totalActivities[7]}</span>名</p>
                          </li>
                          <li>
                            <p>不採用</p>
                            <p><span>{totalActivities[8]}</span>名</p>
                          </li>
                          <li>
                            <p>内定辞退</p>
                            <p><span>{totalActivities[9]}</span>名</p>
                          </li>
                          <li>
                            <p>選考辞退</p>
                            <p><span>{totalActivities[10]}</span>名</p>
                          </li>
                        </ul>
                    </div>

                    {activities.map((activitie: any, index: any) => (
                      <div key={'ctivitie' +{index}}>
                        <h3 className="ttl">{activitie.job_title}</h3>
                        <ul className={'cont' + (index + 5)}>
                        <li>
                          <p>審査待ち</p>
                          <p><span>{activitie.activities[1]}</span>名</p>
                        </li>
                        <li>
                          <p>面接設定済み</p>
                          <p><span>{activitie.activities[2]}</span>名</p>
                        </li>
                        <li>
                          <p>面接済</p>
                          <p><span>{activitie.activities[3]}</span>名</p>
                        </li>
                        <li>
                          <p>体験・実習設定済</p>
                          <p><span>{activitie.activities[4]}</span>名</p>
                        </li>
                        <li>
                          <p>体験・実習済</p>
                          <p><span>{activitie.activities[5]}</span>名</p>
                        </li>
                        <li>
                          <p>内定通知</p>
                          <p><span>{activitie.activities[6]}</span>名</p>
                        </li>
                        <li>
                          <p>採用決定</p>
                          <p><span>{activitie.activities[7]}</span>名</p>
                        </li>
                        <li>
                          <p>不採用</p>
                          <p><span>{activitie.activities[8]}</span>名</p>
                        </li>
                        <li>
                          <p>内定辞退</p>
                          <p><span>{activitie.activities[9]}</span>名</p>
                        </li>
                        <li>
                          <p>選考辞退</p>
                          <p><span>{activitie.activities[10]}</span>名</p>
                        </li>
                        </ul>
                      </div>
                      ))}
                      { isRecommendModalOpen &&
                       <div className="modal_box">
                       <div className="modal_inner">
                         <h4>貴社にピッタリな人材が見つかるかも？</h4>
                         <p style={{ textAlign: 'center' }}>ランダム人材は毎日3人レコメンドされます。</p>
                         <p style={{ textAlign: 'center', marginTop: '24px' }}>- {slide + 1}/{recommend_modals.length} -</p>
                         <div className="recruitment_inner">
                           <div className="slider">
                             {recommend_modals.map((recommend_modal, index) => (
                               <div className={`item_box ${index === slide ? 'visible' : 'hide'}`} key={index}>
                                 <div className="slider_main_content" style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                                     <a href={`/recruiter/scouts/applicant/${recommend_modal.id}`} target="_blank">
                                       <img src={recommend_modal.face_photo} alt="" />
                                     </a>
                                   <ul>
                                     <li><span>ID</span>{recommend_modal.id}</li>
                                     <li><span>年齢/性別</span>{`${recommend_modal.age}歳/${recommend_modal.gender}`}</li>
                                     <li><span>住所</span>{recommend_modal.address}</li>
                                     <li>
                                       <span>障害種別</span>
                                       {recommend_modal.handicap_qualifications.length > 0 ? (
                                         recommend_modal.handicap_qualifications.map((handicap_qualification : any, index: any) => (
                                           <span key={index}>
                                             {handicap_qualification.handicap_type}
                                             {index !== recommend_modal.handicap_qualifications.length - 1 && <br className="sp_only" />}
                                           </span>
                                         ))
                                       ) : null}
                                     </li>
                                     <li><span>就労支援有無</span>{recommend_modal.employment_support_agency}</li>
                                     <li><span>職歴数</span>{recommend_modal.job_career_count}</li>
                                     <li><span>職種希望</span>{recommend_modal.job_categories}</li>
                                   </ul>
                                 </div>
                                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                   <div className="btn_box">
                                     <a
                                       style={{ cursor: 'pointer' }}
                                       href={`/recruiter/scouts/applicant/${recommend_modal.id}`}
                                       target="_blank"
                                       className="btn orange"
                                     >
                                       <span>詳細を確認する</span>
                                     </a>
                                   </div>
                                 </div>
                               </div>
                             ))}
                             {slide + 1 !== recommend_modals.length && (
                               <div className="right-btn" onClick={nextSlide}>＞</div>
                             )}
                             {slide + 1 !== 1 && (
                               <div className="left-btn" onClick={prevSlide}>＜</div>
                             )}
                           </div>
                           <p className="close" onClick={() => setModalOpen(false)} style={{ color: '#00a0e9 !important' }}>
                             <span className="material-icons">close</span>
                           </p>
                         </div>
                       </div>
                       <div className="overlay" onClick={() => setModalOpen(false)}></div>
                     </div>
                      }
                  </>
                  }
                </div>
            </div>
        </section>
      </main>
    );
  }
  
  export default RecruiterIndex;