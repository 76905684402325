import { recruiterRecruitmentsDetailTypes } from "../../ActionTypes/recruiterRecruitmentsDetailTypes";
import { FetchRecruiterRecruitmentsDetailFailure, FetchRecruiterRecruitmentsDetailFailurePayload, FetchRecruiterRecruitmentsDetailRequest, FetchRecruiterRecruitmentsDetailSuccess, FetchRecruiterRecruitmentsDetailSuccessPayload } from "../../types/type";


export const fetchRecruiterRecruitmentsDetailRequest = (id:any): FetchRecruiterRecruitmentsDetailRequest => ({
  type: recruiterRecruitmentsDetailTypes.FETCH_RECRUITER_RECRUIMENTS_DETAIL_REQUEST,
  id,
});

export const fetchRecruiterRecruitmentsDetailSuccess = (
  payload: FetchRecruiterRecruitmentsDetailSuccessPayload,
): FetchRecruiterRecruitmentsDetailSuccess => ({
  type: recruiterRecruitmentsDetailTypes.FETCH_RECRUITER_RECRUIMENTS_DETAIL_SUCCESS,
  payload
});

export const fetchRecruiterRecruitmentsDetailFailure = (
  payload: FetchRecruiterRecruitmentsDetailFailurePayload
): FetchRecruiterRecruitmentsDetailFailure => ({
  type: recruiterRecruitmentsDetailTypes.FETCH_RECRUITER_RECRUIMENTS_DETAIL_FAILURE,
  payload
});
