import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchPaymentlistRequest } from '../../../redax/actions/recruiterInvoiceActions/recrutierPaymentlistActions';

const PaymentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recruiterInvoice = useSelector((state: RootState) => state.recruiterInvoice);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('jobParachannelAuthToken')) {
      dispatch(fetchPaymentlistRequest());
    } else {
      navigate('/recruiter/login/');
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (recruiterInvoice && recruiterInvoice.pending) {
      setData(recruiterInvoice.recruiterPaymentlist?.invoices || []);
    }
  }, [recruiterInvoice]);
  function logout() {
    if (localStorage.entryFlag) {
      localStorage.removeItem("entryFlag");
    }
    if (localStorage.jobParachannelAuthToken) {
      localStorage.removeItem("jobParachannelAuthToken");
      navigate("/");
      window.location.reload();
    }
  }

  return (
    <main id='company'>
      <MypageSideBar resource={undefined} familyName={undefined} currentList={undefined} />
      <section className='sectionScouts' style={{ width: '100%' }}>
      <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
            <ul className="bread_crumb">
              <li>
                <a href="/">トップ</a>
              </li>
              <li>
                <a href="/recruiter/">企業様マイページ</a>
              </li>
              <li>
                <a href="#">プラン管理</a>
              </li>
            </ul>
            <div className="ttl_box">
              <ul>
                <li>
                  <a href="#" onClick={logout}>
                    <img src="/assets/img/company/icon02.svg" alt="" />
                    ログアウト
                  </a>
                </li>
              </ul>
            </div>
          </div>
        <div style={{ width: '100%', padding: '0px 4%' }}>
          <ul className="bread_crumb" style={{ marginTop: '30px' }}>
            <li style={{ display: 'flex', alignItems: 'center' }}>
              <img src="/assets/img/icon.png" style={{ width: '20px', marginRight: '10px' }} alt="" />
              <a href="/recruiter/plan" style={{ textDecoration: 'none', color: 'black' }}>プラン内容</a>
            </li>
            <li style={{ display: 'flex', alignItems: 'center' }}>
              <img src="/assets/img/yun.png" style={{ width: '25px', marginRight: '10px' }} alt="" />
              <a href="/recruiter/PaymentList" style={{ color: '#00A0E9' }}>請求一覧</a>
            </li>
          </ul>
          <hr style={{ height: '2px', width: '100%', background: '#00A0E9' }} />
          <div style={{ borderRadius: '5px', border: '#C4C4C4 solid 1px', padding: '2%' }}>
            {data.length === 0 ? (
              <p>請求書がありません。</p>
            ) : (
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th className='thNew'>日付</th>
                    <th className='thNew'>プラン</th>
                    <th className='thNew'>入金状況</th>
                    <th className='thNew' style={{ textAlign: 'end' }}>請求書</th>
                  </tr>
                </thead>
                <br />
                <tbody>
                  {data.map((invoice: any, index: number) => (
                    <tr key={index}>
                      <td className='tdNew'>{invoice.created_at.split('T')[0].split('-')[0] + '年' + invoice.created_at.split('T')[0].split('-')[1] + '月'  + invoice.created_at.split('T')[0].split('-')[2] + '日'}</td>
                      <td className='tdNew'>{invoice.plan_name}</td>
                      <td className='tdNew' style={invoice.payment_status == true ? { color: '#00A0E9', fontWeight: 700 } : {color: '#FF4D4D', fontWeight: 700}}>
                        {invoice.payment_status == true ? "入金済" : "未入金"}
                      </td>
                      <td className='tdNew' ><a href={`/recruiter/invoice/${invoice.invoice_id}`} style={{ textAlign: 'end', color: '#00A0E9' }}>{"請求書を確認"}</a></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default PaymentList;
