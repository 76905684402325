import { articlesRecruiterTypes } from "../../ActionTypes/articlesRecruiterTypes";
import { ArticlesRecruiterActions, ArticlesRecruiterState } from "../../types/type";



const initialState: ArticlesRecruiterState = {
  pending: false,
  articles: [],
  error: null,
};

export default (state = initialState, action: ArticlesRecruiterActions,) => {
  switch (action.type) {
    case articlesRecruiterTypes.FETCH_ARTICLES_RECRUITER_REQUEST:
      return {
        ...state,
        pending: false
      };
    case articlesRecruiterTypes.FETCH_ARTICLES_RECRUITER_SUCCESS:
      return {
        ...state,
        pending: true,
        articles: action.payload.articles,
        error: null
      };
    case articlesRecruiterTypes.FETCH_ARTICLES_RECRUITER_FAILURE:
      return {
        ...state,
        pending: false,
        articles: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
