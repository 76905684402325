import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminRecommendApplicantsTypes } from "../../ActionTypes/adminRecommendApplicantsTypes";
import { fetchAdminRecommendApplicantsFailure, fetchAdminRecommendApplicantsSuccess } from "../../actions/adminRecommendApplicantsActions/adminRecommendApplicantsActions";

const getAdminRecommendApplicants = (method: any, data: any) => {
    if(method == 'get') {
      return axios.post(API_BASE_ADDRESS + '/admin/recommend/recruitments/applicants?', data , {
        headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }
      });
    } else {
      return axios.post(API_BASE_ADDRESS + '/admin/recommend/applicants', data, {
        headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }
      });
    }  
}

function* fetchRecommendApplicantsSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminRecommendApplicants, action.method, action.data);
    yield put(
      fetchAdminRecommendApplicantsSuccess({
        adminApplicants: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminRecommendApplicantsFailure({
        error: e.message
      })
    );
  }
}

function* AdminRecommendApplicantsSaga() {
  yield takeLatest(adminRecommendApplicantsTypes.FETCH_ADMIN_RECOMMEND_APPLICANTS_REQUEST, fetchRecommendApplicantsSaga);
}
export default AdminRecommendApplicantsSaga;