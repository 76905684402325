import { adminRecruitmentPostTypes } from "../../ActionTypes/adminRecruitmentPostTypes";
import { FetchAdminRecruitmentPostFailure, FetchAdminRecruitmentPostFailurePayload, FetchAdminRecruitmentPostRequest, FetchAdminRecruitmentPostSuccess, FetchAdminRecruitmentPostSuccessPayload } from "../../types/type";


export const fetchAdminRecruitmentPostRequest = (body: any, method:any, id:any): FetchAdminRecruitmentPostRequest => ({
  type: adminRecruitmentPostTypes.FETCH_ADMIN_RECRUITMENT_POST_REQUEST,
  body,
  method,
  id,
});

export const fetchAdminRecruitmentPostSuccess = (
  payload: FetchAdminRecruitmentPostSuccessPayload
): FetchAdminRecruitmentPostSuccess => ({
  type: adminRecruitmentPostTypes.FETCH_ADMIN_RECRUITMENT_POST_SUCCESS,
  payload
});

export const fetchAdminRecruitmentPostFailure = (
  payload: FetchAdminRecruitmentPostFailurePayload
): FetchAdminRecruitmentPostFailure => ({
  type: adminRecruitmentPostTypes.FETCH_ADMIN_RECRUITMENT_POST_FAILURE,
  payload
});