import { adminApplicantsRegionsTypes } from "../../ActionTypes/adminApplicantsRegionsTypes";
import { FetchAdminApplicantsRegionsFailure, FetchAdminApplicantsRegionsFailurePayload, FetchAdminApplicantsRegionsRequest, FetchAdminApplicantsRegionsSuccess, FetchAdminApplicantsRegionsSuccessPayload } from "../../types/type";




export const fetchAdminApplicantsRegionsRequest = (): FetchAdminApplicantsRegionsRequest => ({
  type: adminApplicantsRegionsTypes.FETCH_ADMIN_APPLICANTS_REGIONS_REQUEST,
});

export const fetchAdminApplicantsRegionsSuccess = (
  payload: FetchAdminApplicantsRegionsSuccessPayload,
): FetchAdminApplicantsRegionsSuccess => ({
  type: adminApplicantsRegionsTypes.FETCH_ADMIN_APPLICANTS_REGIONS_SUCCESS,
  payload
});

export const fetchAdminApplicantsRegionsFailure = (
  payload: FetchAdminApplicantsRegionsFailurePayload
): FetchAdminApplicantsRegionsFailure => ({
  type: adminApplicantsRegionsTypes.FETCH_ADMIN_APPLICANTS_REGIONS_FAILURE,
  payload
});
