import { articlesRecruiterDetailTypes } from "../../ActionTypes/articlesRecruiterDetailTypes";
import { FetchArticlesRecruiterDetailFailure, FetchArticlesRecruiterDetailFailurePayload, FetchArticlesRecruiterDetailRequest, FetchArticlesRecruiterDetailSuccess, FetchArticlesRecruiterDetailSuccessPayload } from "../../types/type";

export const fetchArticlesRecruiterDetailRequest = (id: any): FetchArticlesRecruiterDetailRequest => ({
  type: articlesRecruiterDetailTypes.FETCH_ARTICLES_RECRUITER_DETAIL_REQUEST,
  id,
});

export const fetchArticlesRecruiterDetailSuccess = (
  payload: FetchArticlesRecruiterDetailSuccessPayload,
): FetchArticlesRecruiterDetailSuccess => ({
  type: articlesRecruiterDetailTypes.FETCH_ARTICLES_RECRUITER_DETAIL_SUCCESS,
  payload
});

export const fetchArticlesRecruiterDetailFailure = (
  payload: FetchArticlesRecruiterDetailFailurePayload
): FetchArticlesRecruiterDetailFailure => ({
  type: articlesRecruiterDetailTypes.FETCH_ARTICLES_RECRUITER_DETAIL_FAILURE,
  payload
});
