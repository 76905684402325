
import { scoutsSavedConditionsTypes } from "../../ActionTypes/scoutsSavedConditionsTypes";
import {  ScoutsSavedConditionsActions, ScoutsSavedConditionsState } from "../../types/type";

const initialState: ScoutsSavedConditionsState = {
  pending: false,
  saved_conditions: [],
  error: null,
};

export default (state = initialState, action: ScoutsSavedConditionsActions,) => {
  switch (action.type) {
    case scoutsSavedConditionsTypes.FETCH_SCOUTS_SAVED_CONDITIONS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case scoutsSavedConditionsTypes.FETCH_SCOUTS_SAVED_CONDITIONS_SUCCESS:
      return {
        ...state,
        pending: true,
        saved_conditions: action.payload.saved_conditions,
        error: null
      };
    case scoutsSavedConditionsTypes.FETCH_SCOUTS_SAVED_CONDITIONS_FAILURE:
      return {
        ...state,
        pending: false,
        articles: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
