import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { columnFetchListTypes } from "../../ActionTypes/columnFetchListTypes";
import { columnFetchListFailure, columnFetchListSuccess } from "../../actions/columnFetchList/columnFetchListActions";
import { countTypes } from "../../ActionTypes/countTypes";
let per = 3;

var getCount = (companyId:any) => axios.get(API_BASE_ADDRESS + '/count' , {headers: { Accept: 'application/json'}}); 

function* fetchCountSaga(companyId: any) {
  try {
    const response: AxiosResponse = yield call(getCount, companyId.companyId);

    yield put(
      countSuccess({
        count: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      CountFailure({
        error: e.message
      })
    );
  }
}

function* CountSaga() {
  yield takeLatest(countTypes.FETCH_COUNT_REQUEST, fetchCountSaga);
}

export default CountSaga;


function CountFailure(arg0: { error: any; }): any {
  throw new Error("Function not implemented.");
}

function countSuccess(arg0: { count: any; }): any {
  throw new Error("Function not implemented.");
}

