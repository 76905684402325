

import { matomeDetailTypes } from "../../ActionTypes/matomeDetailTypes";
import { FetchMatomeDetailFailure, FetchMatomeDetailFailurePayload, FetchMatomeDetailRequest, FetchMatomeDetailSuccess, FetchMatomeDetailSuccessPayload } from "../../types/type";

export const fetchMatomeDetailRequest = (data:any): FetchMatomeDetailRequest => ({
  type: matomeDetailTypes.FETCH_MATOME_DETAIL_REQUEST,
  data,
});

export const fetchMatomeDetailSuccess = (
  payload: FetchMatomeDetailSuccessPayload,
): FetchMatomeDetailSuccess => ({
  type: matomeDetailTypes.FETCH_MATOME_DETAIL_SUCCESS,
  payload
});

export const fetchMatomeDetailFailure = (
  payload: FetchMatomeDetailFailurePayload
): FetchMatomeDetailFailure => ({
  type: matomeDetailTypes.FETCH_MATOME_DETAIL_FAILURE,
  payload
});
