import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { articlesCreateTypes } from "../../ActionTypes/articlesCreateTypes";
import { fetchArticlesCreateFailure, fetchArticlesCreateSuccess } from "../../actions/articlesCreateActions/articlesCreateActions";

var getArticlesCreate = (body: any) => axios.post( API_BASE_ADDRESS + '/articles/', body, {headers: { Accept: '*/*', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 

function* fetchArticlesCreateSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getArticlesCreate, queryParams.body);
    yield put(
      fetchArticlesCreateSuccess({
        articles: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchArticlesCreateFailure({
        error: e.message
      })
    );
  }
}

function* ArticlesCreateSaga() {
  yield takeLatest(articlesCreateTypes.FETCH_ARTICLES_CREATE_REQUEST, fetchArticlesCreateSaga);
}

export default ArticlesCreateSaga;
