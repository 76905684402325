import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminRecruitmentCopyTypes } from "../../ActionTypes/adminRecruitmentCopyTypes";
import { fetchAdminRecruitmentCopyFailure, fetchAdminRecruitmentCopySuccess } from "../../actions/adminRecruitmentCopyActions/adminRecruitmentCopyActions";


const getAdminRecruitmentCopy = (id: any) => {
  const config = {
    headers: { 
      Authorization: localStorage.jobParachannelAuthAdminToken
    }
  };
  return axios.post(API_BASE_ADDRESS + '/admin/recruitments/' + id + '/copy', null, config);
}


function* fetchAdminRecruitmentCopySaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminRecruitmentCopy, action.id);
    yield put(
      fetchAdminRecruitmentCopySuccess({
        recruitments: response.data.recruitment,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminRecruitmentCopyFailure({
        error: e.message
      })
    );
  }
}

function* AdminRecruitmentCopySaga() {
  yield takeLatest(adminRecruitmentCopyTypes.FETCH_ADMIN_RECRUITMENT_COPY_REQUEST, fetchAdminRecruitmentCopySaga);
}
export default AdminRecruitmentCopySaga;