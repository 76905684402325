import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { scoutsApplicantsTypes } from "../../ActionTypes/scoutsApplicantsTypes";
import { fetchScoutsApplicantsFailure, fetchScoutsApplicantsSuccess } from "../../actions/scoutsApplicantActions/scoutsApplicantActions";



var getScoutsApplicants = (query:any) => axios.get(API_BASE_ADDRESS + '/applicants?' + query, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 

function* fetchScoutsApplicantsSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getScoutsApplicants, action.query);
    yield put(
      fetchScoutsApplicantsSuccess({
        scoutsApplicants: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchScoutsApplicantsFailure({
        error: e.message
      })
    );
  }
}

function* ScoutsApplicantsSaga() {
  yield takeLatest(scoutsApplicantsTypes.FETCH_SCOUTS_APPLICANTS_REQUEST, fetchScoutsApplicantsSaga);
}

export default ScoutsApplicantsSaga;
