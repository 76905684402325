import { articlesCreateTypes } from "../../ActionTypes/articlesCreateTypes";
import { ArticlesCreateActions, ArticlesCreateState } from "../../types/type";



const initialState: ArticlesCreateState = {
  pending: false,
  articles: [],
  error: null,
};

export default (state = initialState, action: ArticlesCreateActions,) => {
  switch (action.type) {
    case articlesCreateTypes.FETCH_ARTICLES_CREATE_REQUEST:
      return {
        ...state,
        pending: false
      };
    case articlesCreateTypes.FETCH_ARTICLES_CREATE_SUCCESS:
      return {
        ...state,
        pending: true,
        articles: action.payload.articles,
        error: null
      };
    case articlesCreateTypes.FETCH_ARTICLES_CREATE_FAILURE:
      return {
        ...state,
        pending: false,
        articles: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
