import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruitmentsApplicationTypes } from "../../ActionTypes/recruitmentsApplicationTypes";
import { fetchRecruitmentsApplicationFailure, fetchRecruitmentsApplicationSuccess } from "../../actions/recruitmentsApplicationActions/recruitmentsApplicationActions";

// var numb  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

var getRecruitmentsApplication = (company_id:any) => axios.get(API_BASE_ADDRESS + '/recruitments?applicarions=true&company_id=' + company_id, {headers: { Accept: 'application/json'}}); 

function* fetchRecruitmentsApplicationSaga(actions: any) {
  try {
    const response: AxiosResponse = yield call(getRecruitmentsApplication, actions.company_id);
    yield put(
      fetchRecruitmentsApplicationSuccess({
        recruitmentsApplication: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitmentsApplicationFailure({
        error: e.message
      })
    );
  }
}

function* RecruitmentsApplicationSaga() {
  yield takeLatest(recruitmentsApplicationTypes.FETCH_RECRUIMENTS_APPLICATION_REQUEST, fetchRecruitmentsApplicationSaga);
}

export default RecruitmentsApplicationSaga;
