import axios, { AxiosResponse } from "axios";
import { API_BASE_ADDRESS } from "../../API";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchChangeUpdateApplicantsFailure, fetchChangeUpdateApplicantsSuccess , } from "../../actions/changeUpdateApplicantsActions/changeUpdateApplicantsActions";
import { ChangeUpdateApplicantsTypes } from "../../ActionTypes/changeUpdateApplicantsTypes ";

var getMessages = (authenticate_resource_id: any, applicant: any) => axios.put(API_BASE_ADDRESS + '/applicants/' + authenticate_resource_id, {
  applicant: applicant }, {
  headers: {
  Accept: '*/*',
  Authorization: localStorage.jobParachannelAuthToken,
  }
}); 
function* fetchChangeUpdateApplicantsSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getMessages, queryParams.authenticate_resource_id, queryParams.applicant);
    yield put(
      fetchChangeUpdateApplicantsSuccess({
        applicant: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchChangeUpdateApplicantsFailure({
        error: e.message
      })
    );
  }
}

function* ChangeUpdateApplicantsSaga() {
  yield takeLatest(ChangeUpdateApplicantsTypes.FETCH_CHANGE_UPDATE_APPLICANTS_REQUEST, fetchChangeUpdateApplicantsSaga);
}

export default ChangeUpdateApplicantsSaga;

