import React, { useEffect, useRef, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchLoginRequest } from '../../../../redax/actions/loginActions/loginActions';
import MypageSideBar from '../../../../components/MypageSideBar/MypageSideBar';
import { RootState } from '../../../../redax/reducers/rootReducer';
import { CKEditor } from 'ckeditor4-react';
import { fetchArticlesRecruiterDetailRequest } from '../../../../redax/actions/articlesRecruiterDerailActions/articlesRecruiterDerailActions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { articlesImagesRequest } from '../../../../redax/actions/articlesImagesActions/articlesImagesActions';
import { fetchArticlesCreateRequest } from '../../../../redax/actions/articlesCreateActions/articlesCreateActions';
import { articlesImageFailure, articlesImageRequest } from '../../../../redax/actions/articlesImageActions/articlesImageActions';
import Loader from '../../../loader/loader';
import { API_BASE_ADDRESS } from "../../../../redax/API";

function ColumnCreate() {
    const dispatch = useDispatch();
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [authenticate, setAuthenticate] = useState(null);
    const [title, setTitle] = useState('');
    const navigate = useNavigate();
    const [editorData, setEditorData] = useState('');
    const { id } = useParams();
    const [ resData, setResData] = useState(0);
    const [column_status, setColumnStatus] = useState('draft');
    const [releaseTime, setReleaseTime] = useState('');
    const [releaseDate, setReleaseDate] = useState<any>('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [images, setImages] = useState<any>([]);
    const [totalPage, setTotalPage] =  useState<any>(null);
    const [eyeCatch, setEyeCatch] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const articlesImages = useSelector((state: RootState) => state.articlesImages);
    const articlesCreate = useSelector((state: RootState) => state.articlesCreate);
    const articlesImage = useSelector((state: RootState) => state.articlesImage);
    const [page, setPage] = useState(1);
    const [drag, setDrag] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [selectedImagePath, setSelectedImagePath] = useState('');
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [isPreview, setIsPreview] = useState(true);
    const [articleView, setArticleView] = useState('');
    const [formattedRelease, setFormattedRelease] = useState('');
    const [edited,setEdited] = useState(false);
    const pageTitle = 'コラム投稿・管理　一覧｜パラちゃんねる';
    document.title = pageTitle;
    const [loadScreen, setLoadScreen] = useState(true);
    const [firstLoad, setFirstLoad] = useState(false);
  
    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDrag(false);
  
      const files = e.dataTransfer?.files;
      if (!files || files.length === 0) return;
  
      setFile(files[0]);
      var reader = new FileReader();
      reader.onload = function read(event: any) {
        setLoadScreen(true);
        setFirstLoad(true);
        dispatch(articlesImageRequest(event.target.result, 'post', 0));
      };
      reader.readAsDataURL(files[0]);
    };
  
    const onSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      
      const files = e.target.files;
      if (!files || files.length === 0) return;
  
      // await setFile(files[0]);
      // fileInputRef.current!.value = '';
      var reader = new FileReader();
      reader.onload = function read(event: any) {
        setLoadScreen(true);
        setFirstLoad(true);
        dispatch(articlesImageRequest(event.target.result, 'post', 0));
      };
      reader.readAsDataURL(files[0]);
    };
    const breadCrumbs = [
        {
            label: 'トップ',
            path: '/',
          },
          {
            label: '企業様マイページ',
            path: '/recruiter/',
          },
          {
            label: 'コラム',
            path: '#',
          },
      ];
      const editorConfig = {
        contentsCss: ["/css/ckeditor.css"],
        editorplaceholder: "本文を入力…",
        extraPlugins: "uploadimage",
        height: 500,
        versionCheck: false,
        language: "ja",
        filebrowserBrowseUrl: "/recruiter/columns/media?type=Files",
        filebrowserUploadUrl: API_BASE_ADDRESS + "/articles/image",
        filebrowserUploadMethod: "xhr",
        fileTools_requestHeaders: {
          "X-Requested-With": "XMLHttpRequest",
          // 'Content-Type': 'application/json',
          Authorization: localStorage.jobParachannelAuthToken,
        },
        image_previewText: "プレビュー",
        clipboard_handleImages: false,
        removeButtons:
          "Save,NewPage,Preview,Print,Templates,Find,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Button,Select,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,CreateDiv,Language,Flash,Smiley,PageBreak,Iframe,Styles,Font,ShowBlocks",
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "clipboard", groups: ["clipboard", "undo"] },
          {
            name: "editing",
            groups: ["find", "selection", "spellchecker", "editing"],
          },
          { name: "forms", groups: ["forms"] },
          { name: "styles", groups: ["styles"] },
          { name: "colors", groups: ["colors"] },
          { name: "tools", groups: ["tools"] },
          { name: "others", groups: ["others"] },
          { name: "about", groups: ["about"] },
          "/",
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
          },
          { name: "links", groups: ["links"] },
          { name: "insert", groups: ["insert"] },
        ],
      };

    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
          // dispatch(fetchLoginRequest('', '',''))
          if(!edited) return 
            window.addEventListener("beforeunload", confirmSave);
        } else (
            navigate('/recruiter/login/')
        )
        return () => window.removeEventListener("beforeunload", confirmSave);
    }, [edited]);

    function confirmSave (event: any) {
      event.preventDefault();
      if (edited) {
        event.returnValue = "編集中のデータは保存されていませんが、移動しますか？"
      }
    }

    const loginData = useSelector((state: RootState) => state.login);


    useEffect(() => {
      dispatch(articlesImagesRequest(page))
    }, [dispatch, page]);


    useEffect(() => {
      if (loginData.pending) {
        if(!loginData.login.is_init_complete) {
          navigate('/recruiter/init/');
        }
        setResourceId(loginData.login.resource.id);
        setFamylyName(loginData.login.resource.family_name);
        setAuthenticate(loginData.login.authenticate.resource_type);
      }
    }, [loginData.pending]);


    useEffect(() => {
      if (articlesImages.pending) {
        setLoadScreen(false);
        setImages(articlesImages.images.images);
        setTotalPage(articlesImages.images.pagination.total_pages);
      } else if(articlesImages.error != null) {
        setLoadScreen(false);
        navigate('/error?code=500');
      }
    }, [articlesImages]);

    useEffect(() => {
      if(articlesImage.pending && firstLoad) {
        setFirstLoad(false);
        dispatch(articlesImagesRequest(page));
      } else if(articlesImage.error != null) {
        setLoadScreen(false);
        navigate('/error?code=500');
      }
    }, [dispatch, articlesImage, firstLoad]);

    const setDataCK = (event: any) => {
      console.log(event);
      const data = event;      
      setEditorData(data);
    };

    // console.log('editorData:'+editorData);

    function logout() {
      if (localStorage.entryFlag) {
        localStorage.removeItem('entryFlag')
      }
      if (localStorage.jobParachannelAuthToken) {
        localStorage.removeItem('jobParachannelAuthToken')
        navigate('/');
        window.location.reload();
      }
    }

    function dispFormattedReleaseDate() {
      if (typeof releaseDate !== 'object') {
        return releaseDate;
      }
  
      const year = releaseDate.getFullYear();
      const month = releaseDate.getMonth() + 1;
      const day = releaseDate.getDate();
      
      return `${year}年${month}月${day}日`;
    }
    function handleDateChange (date: any) {
      setReleaseDate(date);
      setIsDatePickerOpen(false);
    }


    function openDatePicker() {
      setEdited(true);
      setIsDatePickerOpen(true);
    }

    function modalOpen () {
      setEdited(true);
      setIsModalOpen(true);
    }

    function closeModal () {
      setIsModalOpen(false);
    }



    function handleChange (evt: any) {
      setEdited(true);
      // const data = event.editor.getData();

      // data.on("fileUploadRequest", async function (evt:any) {
        /*
        const formData = new FormData()
        const fileLoader = evt.data.fileLoader
        const { xhr, uploadUrl, file, fileName } = fileLoader
        xhr.open('post', uploadUrl)
        xhr.setRequestHeader('token', getToken())
        formData.append('file', file, fileName)
        xhr.send(formData)
        evt.stop()
        */

        // Copy contents of 'upload' image field to a new field with 'new_name'
        const requestDataObject = evt.data.requestData;
        // 現在はrequestDataObject['upload']に画像が入っているが
        // このまま送るとバイナリになってしまう
        requestDataObject["image"] = evt.data.fileLoader.data;

        // Delete old 'upload' image field from the request data object
        delete requestDataObject["upload"];

        const xhr = evt.data.fileLoader.xhr;
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader(
          "Authorization",
          localStorage.jobParachannelAuthToken
        );

        evt.data.fileLoader.xhr.send(
          JSON.stringify({
            image: evt.data.fileLoader.data,
          })
        );

        // Prevented the default behavior.
        evt.stop();
      // });

      
    };

    function handleChange2(evt : any) {
        evt.stop();
        const xhr = evt.data.fileLoader.xhr;
        const res = JSON.parse(xhr.responseText);
        // 自己处理返回逻辑
        if (!res.image_path) {
          evt.data.message = "アップロードに失敗しました";
          evt.cancel();
        } else {
          evt.data.url = res.image_path;
        }
    }

    function handleChange3(evt : any) {
        document
          .getElementsByClassName("cke_dialog_contents")[0]
          .getElementsByTagName("span")[1].innerText = "画像一覧";
    }


    const pageIncrement = () => {
      if (page >= totalPage) {
        setPage(totalPage);
        return;
      }
      setPage((prevPage) => prevPage + 1);
      dispatch(articlesImagesRequest(page))

    }
  
    const pageDecrement = () => {
      if (page <= 1) {
        setPage(1);
        return;
      }
      setPage((prevPage) => prevPage - 1);
      dispatch(articlesImagesRequest(page))
    };
    const updatePage = (p: any) => {
      setPage(p);
      dispatch(articlesImagesRequest(page))
    };

    function modalPrevOpen () {
      setIsPreviewModalOpen(true)
    }

    function closePrevModal () {
      setIsPreviewModalOpen(false)
    }

    function draftSubmit () {

    }
    function publishSubmit () {
  
    }

    
    function selectImage (i: any)  {
    const updatedImages = images.map((image:any, index: any) => {
      if (index === i) {
        setSelectedImagePath(image.path);
        return { ...image, selected: true };
      } else {
        return { ...image, selected: false };
      }
    });
    setImages(updatedImages);
    // this.setState({
    //   images: updatedImages,
      // selectedImagePath: updatedImages[i].path
    // });
    };

  function changeImage (img: any) {
    setEyeCatch(img);
    setIsModalOpen(false);
  }
  function formatDate (dt: any) {
    return (
      dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate()
    );
  }

  function replacedContent(value: any) {
    if (value == null) {
      return null
    }
    return value.replaceAll("\n", '<br>');
  }

  function submit (val:any) {
    if (title == null || title == "") {
      window.alert("タイトルを入力してください");
      return;
    }
    const newData = {
      content: editorData,
      eye_catch: eyeCatch,
      release_date: formattedReleaseDate() + ' ' + releaseTime,
      status: val,
      title: title
    };
    //console.log(newData);
    setEdited(false);
    dispatch(fetchArticlesCreateRequest(newData));
    setTimeout(() => {
      navigate('/recruiter/columns');
      window.location.reload();
    }, 500);
  }
  function formattedReleaseDate () {
    let releaseDate = "";
    if (releaseDate) {
      releaseDate = formatDate(releaseDate);
    } else {
      releaseDate = formatDate(new Date());
    }
    return releaseDate;
  }
  function releaseTimeChange(event: any) {
    const val = event.target.value;
    setReleaseTime(val);
  }
  function titleChange (val: any) {
    setEdited(true);
    setTitle(val.target.value);
  }
    return (
      
      <main id="company" className="column column_editor">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
        <ul className="bread_crumb">
            {breadCrumbs.map((breadCrumb, i) => (
               <li key={i}>
                <a href={breadCrumb.path}>{ breadCrumb.label }</a>
               </li>
            ))}
        </ul>
        <div className="ttl_box">
            <h2 className="ttl">コラム ＞ 新規</h2>
            <ul>
                <li>
                    <a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt="" />ログアウト</a>
                </li>
            </ul>
        </div>
        <div className="columns">
            <div className="editor">
                <div className="editor_box">
                    <form>
                        <input value={title} onChange={titleChange}  type="text" placeholder="タイトルを入力…"/>
                    </form>
                    <CKEditor
                      name="ckeditname"
                      config={editorConfig}
                      // onChange={handleChange}
                      onFileUploadRequest={handleChange}
                      onFileUploadResponse={handleChange2}
                      onDialogShow={handleChange3}
                      data={editorData} // Set the initial content using the 'data' prop
                    />
                </div>
                <div className="setting_box">
                      <div className="publish">
                        <p>公開</p>
                        <div className="cont">
                          {column_status === "draft" ?
                          <ul className="btn">
                          <li className="draft" onClick={(e) => submit(0)}><a>一時保存する</a></li>
                          <li className="preview" onClick={modalPrevOpen}>
                            <a>プレビュー</a>
                          </li>
                          <li><a  onClick={(e) => submit(1)}>公開する</a></li>
                        </ul>
                          :
                          <ul className="btn">
                            <li className="draft"  onClick={(e) => submit(0)}><a>非公開にする</a></li>
                            <li className="preview" onClick={modalPrevOpen}>
                              <a>プレビュー</a>
                            </li>
                            <li><a  onClick={(e) => submit(1)}>更新する</a></li>
                          </ul>
                          }
                          <ul className="status">
                            <li>
                              <dl>
                                <dt>
                                  <img src="/assets/img/column/edit_calender.svg" alt="" />
                                </dt>
                                <dd>
                                  {releaseDate ? 
                                     <div>
                                     <DatePicker
                                       selected={releaseDate}
                                       onChange={handleDateChange}
                                       dateFormat="yyyy年MM月dd日"
                                       onClickOutside={() => setIsDatePickerOpen(false)}
                                     />
 
                                     <input type="time" value={releaseTime}  onChange={releaseTimeChange}/>
                                   </div>
                                  : <span>すぐ</span>}
                               
                                  に公開する
                                  <a  onClick={openDatePicker}>配信日設定</a>
                                  {isDatePickerOpen ?
                                  <DatePicker
                                      selected={releaseDate}
                                      className='none'
                                      onChange={handleDateChange}
                                      dateFormat="yyyy年MM月dd日"
                                      onClickOutside={() => setIsDatePickerOpen(false)}
                                      open={isDatePickerOpen}
                                     />
                                     : <></>}
                                </dd>
                              </dl>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="icatch">
                        <p>アイキャッチ画像</p>
                        <div className="cont">
                          <img src={eyeCatch || ''} alt="" />
                          <a onClick={modalOpen}>アイキャッチ画像を設定</a>
                        </div>
                      </div>
                    </div>
            </div>
        </div>
        </section>
        {isModalOpen ?
          <div className="modal_box" v-show="isModalOpen" role="dialog" >
          <div className="modal_inner">
            <slot name="mainCts">
              <div className="icatch_modal">
                <h4>画像リスト</h4>
                <div className="media_uploader">
                  <form action="./upload.php" method="post">
                  <div
                    id="drop-zone"
                    style={{ border: '1px solid', padding: '30px' }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      setDrag(true);
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      setDrag(false);
                    }}
                    onDrop={onDrop}
                  >
                    {drag ? <p>ドラッグ中</p> : <p>ファイルをドラッグ＆ドロップ<br /><span>または</span></p>}
                    <input
                      type="file"
                      name="file"
                      id="file-input"
                      ref={fileInputRef}
                      onChange={onSelect}
                    />
                  </div>
                  </form>
                </div>
                <div className="medias">
                {images.length > 0 ?
                <ul className="list_box" style={{maxHeight: '350px', overflow: 'auto'}}>
                  {images.map((image: any, i: any) => (
                    <li key={i} onClick={(e) =>selectImage(i)} className={image.selected ? 'active' : ''}>
                      <img src={image.path} alt="" className="thumb" />
                    </li>
                  ))}
                </ul>
                : <></>}
                     <ul className="pagination_circle">
                      <li className={`prev ${page <= 1 ? 'disabled' : ''}`}>
                        <a href="#" onClick={pageDecrement}>前へ</a>
                      </li>
                      {Array.from({ length: totalPage }, (_, i) => i + 1).map((p) => (
                        <li key={p} className={`page ${page === p ? 'active' : ''}`}>
                          <a href="#" onClick={() => updatePage(p)}>
                            <span>{p}</span>
                          </a>
                        </li>
                      ))}
                      <li className={`next ${page >= totalPage ? 'disabled' : ''}`}>
                        <a href="#" onClick={pageIncrement}>次へ</a>
                      </li>
                    </ul>
                    {images.length > 0 ?  <button onClick={(e) =>changeImage(selectedImagePath)}> 決定</button> : <></>}
                   
              </div>
              
              </div>
            </slot>
            <p className="close" onClick={closeModal}><span className="material-icons">close</span></p>
          </div>
          <div className="overlay" onClick={closeModal}></div>
        </div>
         : <></>}

        {isPreviewModalOpen ?
          <div className="modal_box" >
            <div className="modal_inner">
              <slot name="mainCts">
                <div className="column_preview">
                  <h4>プレビュー</h4>
                  <article  className="column_article">
                    {!isPreview ?
                    <ul className="sns">
                      <li>
                        <a href="'https://twitter.com/share?url=' + currentUrlEncode" target="_blank"><img src="/assets/img/column/col_tw.svg" alt=""/></a>
                      </li>
                      <li>
                        <a href="'http://www.facebook.com/share.php?u=' + currentUrl"target="_blank"><img src="/assets/img/column/col_fb.svg" alt=""/></a>
                      </li>
                    </ul>
                    : <></>}
                    <h2>{ title }</h2>
                    <div className='info'>
                      <div className="view">
                        <img src="/assets/img/column/view.svg" alt="" /><span>{ articleView }</span>view
                      </div>
                      <p className="date">{ formattedRelease }</p>
                    </div>
                    <div className="article" dangerouslySetInnerHTML={{__html: replacedContent(editorData) || ''}}></div>
                  </article>
                  <div className="submitBtn">
                    {column_status == 'draft' ?
                    <ul>
                      <li className="draft"><a onClick={() => submit(0)}>一時保存する</a></li>
                      <li><a onClick={() => submit(1)}>公開する</a></li>
                    </ul>
                    :
                    <ul >
                      <li className="draft"><a onClick={() => submit(0)}>非公開にする</a></li>
                      <li><a onClick={() => submit(1)}>更新する</a></li>
                    </ul>
                    }
                  </div>
                </div>
              </slot>
              <p className="close" onClick={closePrevModal}><span className="material-icons">close</span></p>
            </div>
            <div className="overlay" onClick={closePrevModal}></div>
          </div>
         : <></>}

      </main>
    );
  }
  
  export default ColumnCreate;