import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruitmentsPublicListTypes } from "../../ActionTypes/recruitmentsPublicListTypes";
import { fetchRecruitmentsPublicListFailure, fetchRecruitmentsPublicListSuccess } from "../../actions/recruitmentsPublicListActions/recruitmentsPublicListActions";

var getRecruitmentsPublicList = (id:any) => axios.get(API_BASE_ADDRESS + '/recruitments/public_list?company_id=' + id, {headers: { Accept: 'application/json'}}); 

function* fetchRecruitmentsPublicListSaga(id: any) {
  try {
    const response: AxiosResponse = yield call(getRecruitmentsPublicList, id.id);
    yield put(
      fetchRecruitmentsPublicListSuccess({
        recruitments_public_list : response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitmentsPublicListFailure({
        error: e.message
      })
    );
  }
}

function* RecruitmentsPublicListSaga() {
  yield takeLatest(recruitmentsPublicListTypes.FETCH_RECRUITMENTS_PUBLIC_LIST_REQUEST, fetchRecruitmentsPublicListSaga);
}

export default RecruitmentsPublicListSaga;
