import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruiterPricingTypes } from "../../ActionTypes/recruiterPricingTypes";
import { fetchRecruiterPricingFailure, fetchRecruiterPricingSuccess } from "../../actions/recruiterPricingActions/recruiterPricingActions";

var getRecruiterPricing = () => axios.get(API_BASE_ADDRESS + '/recruiter/pricing', {headers: {Authorization: localStorage.getItem('jobParachannelAuthToken'), Accept: 'application/json'}}); 

function* fetchRecruiterPricingSaga() {
  try {
    const response: AxiosResponse = yield call(getRecruiterPricing);
    yield put(
      fetchRecruiterPricingSuccess({
        recruiterPricing: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruiterPricingFailure({
        error: e.message
      })
    );
  }
}

function* RecruiterPricingSaga() {
  yield takeLatest(recruiterPricingTypes.FETCH_RECRUITER_PRICING_REQUEST, fetchRecruiterPricingSaga);
}

export default RecruiterPricingSaga;
