import { adminApplicantsDetailTypes } from "../../ActionTypes/adminApplicantsDetailTypes";
import { FetchAdminApplicantsDetailFailure, FetchAdminApplicantsDetailFailurePayload, FetchAdminApplicantsDetailRequest, FetchAdminApplicantsDetailSuccess, FetchAdminApplicantsDetailSuccessPayload } from "../../types/type";

export const fetchAdminApplicantsDetailRequest = (id: any): FetchAdminApplicantsDetailRequest => ({
  type: adminApplicantsDetailTypes.FETCH_ADMIN_APPLICANTS_DETAIL_REQUEST,
  id
});

export const fetchAdminApplicantsDetailSuccess = (
  payload: FetchAdminApplicantsDetailSuccessPayload
): FetchAdminApplicantsDetailSuccess => ({
  type: adminApplicantsDetailTypes.FETCH_ADMIN_APPLICANTS_DETAIL_SUCCESS,
  payload
});

export const fetchAdminApplicantsDetailFailure = (
  payload: FetchAdminApplicantsDetailFailurePayload
): FetchAdminApplicantsDetailFailure => ({
  type: adminApplicantsDetailTypes.FETCH_ADMIN_APPLICANTS_DETAIL_FAILURE,
  payload
});