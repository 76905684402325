import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchAdminCompanyDetailFailure, fetchAdminCompanyDetailSuccess } from "../../actions/adminCompanyDetailActions/adminCompanyDetailActions";
import { adminCompanyDetailTypes } from "../../ActionTypes/adminCompanyDetailTypes";
import { adminAdministratorsDeleteTypes } from "../../ActionTypes/adminAdministratorsDeleteTypes";
import { fetchAdminAdministratorsDeleteFailure, fetchAdminAdministratorsDeleteSuccess } from "../../actions/adminAdministratorsDeleteActions/adminAdministratorsDeleteActions";

const deleteAdminAdministrator = (data: any) => { 
  const config = {
    headers: { 
      Prefer: 'example=example-1', Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.delete(API_BASE_ADDRESS + '/admin/administrators/' + data, config); 
}


function* fetchAdminAdministratorsDeleteSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(deleteAdminAdministrator, action.data);
    yield put(
      fetchAdminAdministratorsDeleteSuccess({
        administratorsDelete: response.data.administratorsDelete,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminAdministratorsDeleteFailure({
        error: e.message
      })
    );
  }
}

function* AdminAdministratorsDeleteSaga() {
  yield takeLatest(adminAdministratorsDeleteTypes.FETCH_ADMIN_ADMINISTRATORS_DELETE_REQUEST, fetchAdminAdministratorsDeleteSaga);
}
export default AdminAdministratorsDeleteSaga;