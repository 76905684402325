import { adminRecruitmentListTypes } from "../../ActionTypes/adminRecruitmentListTypes";
import {
  FetchAdminRecruitmentListRequest,
  FetchAdminRecruitmentListSuccessPayload,
  FetchAdminRecruitmentListSuccess,
  FetchAdminRecruitmentListFailurePayload,
  FetchAdminRecruitmentListFailure
} from "../../types/type";

export const fetchAdminRecruitmentListRequest = (data: any): FetchAdminRecruitmentListRequest => ({
  type: adminRecruitmentListTypes.FETCH_ADMIN_RECRUITMENT_LIST_REQUEST,
  data
});

export const fetchAdminRecruitmentListSuccess = (
  payload: FetchAdminRecruitmentListSuccessPayload
): FetchAdminRecruitmentListSuccess => ({
  type: adminRecruitmentListTypes.FETCH_ADMIN_RECRUITMENT_LIST_SUCCESS,
  payload
});

export const fetchAdminRecruitmentListFailure = (
  payload: FetchAdminRecruitmentListFailurePayload
): FetchAdminRecruitmentListFailure => ({
  type: adminRecruitmentListTypes.FETCH_ADMIN_RECRUITMENT_LIST_FAILURE,
  payload
});