import { Link, useNavigate, useParams } from 'react-router-dom';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { fetchRegionsRequest } from '../../../redax/actions/regionsActions/regionsActions';
import { fetchCompaniesRequest } from '../../../redax/actions/companiesActions/companiesActions';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchPostCodeRequest } from '../../../redax/actions/postcodeActions/postcodeActions';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { fetchInitRequest } from '../../../redax/actions/initActions/initActions';
import Loader from '../../loader/loader';

function RecruiterInit() {
    let totalStep = [1, 2, 3];
    let stepAry = ['','会社情報','採用担当者情報','利用目的'];
    let terms = false;
    let MaxFifty = 50; 
    let MaxThirty = 30; 
    let MaxEleven = 11; 
    let MinTen = 10; 
    let MaxSeven = 7;
    let MaxHundred = 100; 
    let MaxTwoHundred = 200;
    let company: any = []
    const dispatch = useDispatch();
    const [CompanyNumber, setCompanyNumber] = useState<any>(null);
    const [CompanyName, setCompanyName] = useState<any>(null);
    const [CompanyNamekana, setCompanyNamekana] = useState<any>(null);
    const [address, setaddress] = useState<any>(null);
    const [postcode, setpostcode] = useState<any>(null);
    const [familyname, setfamilyname] = useState<any>(null);
    const [familynameKana, setfamilynamekana] = useState<any>(null);
    const [phoneNumber, setphoneNumber] = useState<any>(null);
    const [department, setdepartment] = useState<any>(null);
    const [prefectureCity, setprefectureCity] = useState<any>(null);
    const [PurposeOfPosting, setPurposeOfPosting] = useState<any>([]);
    const [error, setError] = useState(false);  
    const [resourceId, setResourceId] = useState(null);
    const [familyName, setFamylyName] = useState(null);
    const [Data, setData] = useState(null);
    const [step, setStep] = useState(1);
    const [errorCompanyNumber, setErrorCompanyNumber] = useState(false);
    const [errorCompanyNumberNumeric, setErrorCompanyNumberNumeric] = useState(false);
    const [errorCompanyNumberMaxLength, setErrorCompanyNumberMaxLength] = useState(false);
    const [errorCompanyName, setErrorCompanyName] = useState(false);
    const [errorCompanyNameMaxLength, setErrorCompanyNameMaxLength] = useState(false);
    const [errorCompanyNamekana, setErrorNamekana] = useState(false);
    const [errorCompanyNamekanaMaxLength, setErrorNamekanaMaxLength] = useState(false);
    const [errorCompanyNamekanaValidate, setErrorNamekanaValidate] = useState(false);
    const [errorPostcode, setErrorPostcode] = useState(false);
    const [erroraddress, setErroraddress] = useState(false);
    const [errorPostcodeNumeric, setErrorPostcodeNumeric] = useState(false);
    const [errorPostcodeMaxLength, setErrorPostcodMaxLength] = useState(false);
    const [erroraddressMaxLength, setErroraddressLength] = useState(false);
    const [errorDisabledAddress, seterrorDisabledAddress] = useState(false);
    const [nextStep, setNextStep] = useState(false);
    const [nextStepTwo, setNextStepTwo] = useState(false);
    const [nextStepThree, setNextStepThree] = useState(false);
    const [errorfamilyname, setErrorfamilyname] = useState(false);
    const [errorfamilyNamekanaKanaValidate, setErrorfamilyNamekanaKanaValidate] = useState(false);
    const [errorfamilyNameMaxLength, setErrorfamilyNameMaxLength] = useState(false);
    const [errorfamilyNamekanaMaxLength, setErrorfamilyNamekanaMaxLength] = useState(false);
    const [errorphoneNumber, setErrorphoneNumber] = useState(false);
    const [errorphoneNumberNumeric, setErrorphoneNumberNumeric] = useState(false);
    const [errorphoneNumberminLength, setErrorphoneNumberminLength] = useState(false);
    const [errorphoneNumbermaxLength, setErrorphoneNumbermaxLength] = useState(false);
    const [errordepartment, setErrordepartment] = useState(false);
    const [errordepartmentmaxLength, setErrordepartmentmaxLength] = useState(false);
    const [errorTerms, setErrorTerms] = useState(false);
    const [errorPurposeOfPosting, setErrorPurposeOfPosting] = useState(true);
    const [termsOfCondition, setTermsOfCondition] = useState<any>(false);
    const { id } = useParams();
    const [master, setMaster] = useState<any[]>([]); 
    const [PurposeOfPostings, setPurpose_of_postings] = useState<any[]>([]); 
    const [Corporatecultures, setCorporatecultures] = useState<any[]>([]); 
    const [corporateforms, setcorporateforms] = useState<any[]>([]); 
    const [errorisEditCity, setErrorisEditCity] = useState(false);
    const [btn, setBtn] = useState(false);
    const [companyPostCode, setCompanyPostCode] = useState<any>(null);
    const [disabled, setDisabled] = useState(false);
    const [disabled_address, setDisabledAddress] = useState(String);
    const [isEditCity, setIsEditCity] = useState<any>(false);
    const [city_name, setCityName] = useState('');
    const [city_name2, setCityName2] = useState('');
    const [prefecture_name, setPrefectureName] = useState('');
    const [prefecture_id, setPrefectureId] = useState(null);
    const [city_id, setCityId] = useState(null);
    const [region_id, setRegionId] = useState(null);
    const [invalid, setinvalid] = useState(false);
    const [Companies, setCompanies] = useState<any[]>([]); 
    const companiesData = useSelector((state: RootState) => state.companies);
    const loginData = useSelector((state: RootState) => state.login);
    const postcodeData = useSelector((state: RootState) => state.postcode);
    const init = useSelector((state: RootState) => state.init);
    const navigate = useNavigate();
    const [loadScreen, setLoadScreen] = useState(true);
    const [edited,setEdited] = useState(false);

    useEffect(() => {
        // dispatch(fetchLoginRequest('', '', ''));
        if(!edited) return 
            window.addEventListener("beforeunload", confirmSave);
        return () => window.removeEventListener("beforeunload", confirmSave);
    }, [edited]);

    function confirmSave (event: any) {
        event.preventDefault();
        if (edited) {
            event.returnValue = "編集中のデータは保存されていませんが、移動しますか？"
        }
    }

    useEffect(() => {
        if (loginData !== undefined && loginData.pending) {
          setResourceId(loginData.login.resource.id);
          setData(loginData.login.resource.data); 
          setCompanyNumber(loginData.login.company.corporate_number);
          setCompanyName(loginData.login.company.name);
          setCompanyNamekana(loginData.login.company.name_kana);
          setpostcode(loginData.login.company.postcode);
          setaddress(loginData.login.company.address);
          setfamilyname(loginData.login.resource.family_name);
          setfamilynamekana(loginData.login.resource.family_name_kana);
          setdepartment(loginData.login.resource.department);
          setphoneNumber(loginData.login.resource.phone_number);
          if(loginData.login.company.postcode != null && loginData.login.company.postcode != '') {
            dispatch(fetchPostCodeRequest(loginData.login.company.postcode));
          }
        }
    }, [loginData.pending]);

    useEffect(() => {
        dispatch(fetchInitRequest());
    }, [dispatch]);
      
    useEffect(() => {
        if(init.pending) {
            setPurpose_of_postings(init.init.purpose_of_postings);
            let c = [];
            for(let i = 0; i < init.init.purpose_of_postings.length; i++) {
                c.push(false);
            }
            setPurposeOfPosting(c);
            setLoadScreen(false);
        } else if(init.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    },[init]);

    useEffect(() => {
        if(companiesData.pending) {
            // navigate('/recruiter/company/');
            // window.location.reload();
            window.location.href = '/recruiter/company/';
        } else if(companiesData.error != null) {
            navigate('/error/');
        }
    },[companiesData.pending]);

    const onChangeInput = (event: any) => {
        setEdited(true);
        const {name, value, number} = event.target;
        if(name === 'corporate_number') {
            setCompanyNumber(value);
        }
        if(name === 'name') {
            setCompanyName(value);
        }
        if(name === 'name_kana') {
            setCompanyNamekana(value);
        }
        if(name === 'postcode'){
            setpostcode(value);
        }
        if(name === 'region'){
            setaddress(value);
        }
        if(name === 'prefecture_city'){
            setprefectureCity(value);
        }
        dataValidation(name, value, number);
    }
    function dataValidation (name: any, value: any, number:any){
        let errCorporateNumber, errName, errNameKana, errPostcode, errRegion = false;
        const kanaValidate = new RegExp('^([ァ-ン]|ー)+$');
        if(name === 'corporate_number') {
            if(value === '') {
                setErrorCompanyNumber(true);
                errCorporateNumber = true;
            } else {
                setErrorCompanyNumber(false);
                if(value.length !== 13) {
                    setErrorCompanyNumberMaxLength(true);
                    errCorporateNumber = true;
                } else {
                    setErrorCompanyNumberMaxLength(false);
                }
                if(!/[0-9]/.test(value)) {
                    setErrorCompanyNumberNumeric(true);
                } else{
                    setErrorCompanyNumberNumeric(false);
                }
            }
        }
        if(name === 'name' ) {
            if(value === '') {
                setErrorCompanyName(true);
                errName = true;
            } else {
                setErrorCompanyName(false);
                if(value.length > 100) {
                    setErrorCompanyNameMaxLength(true);
                    errName = true;
                } else {
                    setErrorCompanyNameMaxLength(false);
                }
            }
        }
        if(name === 'name_kana'){
            if(value === ''){
                setErrorNamekana(true);
                errNameKana = true;
            } else {
                setErrorNamekana(false);
                if(value.length > 100) {
                    setErrorNamekanaMaxLength(true);
                } else {
                    setErrorNamekanaMaxLength(false);
                }
                if(kanaValidate.test(value)) {
                    setErrorNamekanaValidate(false)
                  } else {
                    errNameKana = true;
                    setErrorNamekanaValidate(true)
                  }

            }
        }
        if(name === 'postcode'){
            if(value === '') {
                setErrorPostcode(true);
              errPostcode = true;
            } else {
                setErrorPostcode(false);
                if(!/[0-9]/.test(value)){
                    setErrorPostcodeNumeric(true);
                } else {
                    setErrorPostcodeNumeric(false);
                }
                if(value.length !==7) {
                    setErrorPostcodMaxLength(true);
                    errPostcode = true;
                } else {
                    setErrorPostcodMaxLength(false);
                }
            }
        }
        if(name === 'region'){
            if(value === '') {
                setErroraddress(true);
                errRegion = true;
            } else {
                setErroraddress(false);
                if(value.length > 200) {
                    setErroraddressLength(true);
                    errRegion = true;
                } else {
                    setErroraddressLength(false);
                }
            }
        }
        if(name=== 'prefecture_city'){
            if(value === ''){
                seterrorDisabledAddress(true);
            } else {
                seterrorDisabledAddress(false);
            }
        }
        if(!errNameKana && !errName && !errCorporateNumber && !errPostcode && !errRegion) {
            setNextStep(false);
        } else {
            setNextStep(true);
        }
    }
    const onChangeStepTwo = (event: any) => {
        setEdited(true);
        const {name, value} = event.target;
        if(name === 'family_name') {
            setfamilyname(value);
        }
        if(name === 'family_name_kana') {
            setfamilynamekana(value);
        }
        if(name === 'phone_number') {
            setphoneNumber(value);
        }
        if(name === 'department') {
            setdepartment(value);
        }
        SelectValidation(name, value);
    }

    function SelectValidation (name: any, value: any) {
        let errFamilyName, errFamilyNameKana, errPhoneNumber, errDepart = false;
        const kanaValidate = new RegExp('^([ァ-ン]|ー)+$');
        if(name === 'family_name') {
            if(value === '') {
                setErrorfamilyname(true);
                errFamilyName = true;
            } else {
                setErrorfamilyname(false);
                if(value.length >30){
                    setErrorfamilyNameMaxLength(true)
                }else {
                    setErrorfamilyNameMaxLength(false)
                }
            }
        }
        if(name === 'family_name_kana') {
                if(value === '') {
                    setErrorfamilyname(true);
                    errFamilyNameKana = true;
                }else{
                    setErrorfamilyname(false);
                    if(kanaValidate.test(value)){
                        setErrorfamilyNamekanaKanaValidate(false)
                    }else {
                        setErrorfamilyNamekanaKanaValidate(true)
                    }
                    if(value.length >30){
                        setErrorfamilyNamekanaMaxLength(true)
                    }else {
                        setErrorfamilyNamekanaMaxLength(false)
                    }
                }
        }
        if(name === 'phone_number') {
            if(value == null) {
                setErrorphoneNumber(true);
                errPhoneNumber = true;
            }else{
                setErrorphoneNumber(false);
                if(!/[0-9]/.test(value)){
                    setErrorphoneNumberNumeric(true)
                }else {
                    setErrorphoneNumberNumeric(false)
                }
                if(value.length < 10){
                    setErrorphoneNumberminLength(true)
                }else {
                    setErrorphoneNumberminLength(false)
                }
                if(value.length > 11){
                    setErrorphoneNumbermaxLength(true)
                }else {
                    setErrorphoneNumbermaxLength(false)
                }
            }
        }
        if(name === 'department') {
            if(value === '') {
                setErrordepartment(true);
                errDepart = true;
            }else{
                setErrordepartment(false);
                if(value.length >50){
                    setErrordepartmentmaxLength(true)
                }else {
                    setErrordepartmentmaxLength(false)
                }
            }
        }
        if(!errFamilyName && !errFamilyNameKana && !errDepart && !errPhoneNumber) {
            setNextStepTwo(false);
        } else {
            setNextStepTwo(true);
        }
    }
    const onChangeStepThree = (event: any, index: any) => {
        setEdited(true);
        let err = false;
        const {name, value} = event.target;
        if(name === 'agree') {
            if(event.target.checked) {
                setTermsOfCondition(true);
            } else {
                setTermsOfCondition(false);
            }
        }
        if(name === 'purpose') {
            let c = [];
            for(let i = 0; i < PurposeOfPosting.length; i++) {
                c.push(PurposeOfPosting[i]);
            }
            c[index] = event.target.checked;
            setPurposeOfPosting(c);
            let chk = false;            
            for(let i = 0; i < c.length; i++) {
                if(c[i]) {
                    chk = true;
                }
            }
            if(!chk) {
                setErrorPurposeOfPosting(true);
                err = true;
            } else{
                setErrorPurposeOfPosting(false);
            }
        }
        ValidationThree(name, event.target.checked, err);
    }
    function ValidationThree (name: any, value: any, error: any) {
        let err, errPurpose, errAgree = false;
        if(name === 'purpose') {
            if(error) {
                errPurpose = true;
            }
        }
        if (name === 'agree') {
            if(!value) {
                setErrorTerms(true);
                errAgree = true;
            } else {
                setErrorTerms(false);
            }
        }

        if(!errPurpose && !errAgree) {
            err = false;
        } else {
            err = true;
        }        

        if(err) {
            setNextStepThree(false);
        } else {
            if(!termsOfCondition) {
                setNextStepThree(false);
            } else {
                if(PurposeOfPosting.includes(true)) {
                    setNextStepThree(true);
                } else {
                    setNextStepThree(false);
                }
            }
        }
        return err;
    }

    const moveStep = (value: any) => {
        setEdited(false);
        let tmp = step;
        if(errorCompanyNumber || errorCompanyName || errorCompanyNameMaxLength || errorCompanyNamekana || errorCompanyNamekanaMaxLength
            || errorCompanyNamekanaValidate || errorCompanyNumberMaxLength || errorCompanyNumberNumeric || errorPostcode
            || errorPostcodeMaxLength || errorPostcodeNumeric || erroraddress || erroraddressMaxLength) {
            return;
        }
        if(step === 2 && value === 1 && (errordepartment || errorfamilyname || errordepartmentmaxLength || errorfamilyNameMaxLength
            || errorfamilyNamekanaKanaValidate || errorfamilyNamekanaMaxLength || errorphoneNumber || errorphoneNumberNumeric
            || errorphoneNumbermaxLength || errorphoneNumberminLength)) {
            return;
        }
        // tmp += value;
        // setStep(tmp);
        // window.scrollTo(0, 0);
        if(value === 1){
            tmp = tmp + 1;
            setStep(tmp);
            window.scrollTo(0, 0);
        } else {
            tmp = tmp - 1;
            setStep(tmp);
            window.scrollTo(0, 0);
        }
    }
    function getPostCode () {
        if(postcode !== null && !errorPostcode && !errorPostcodeNumeric && !errorPostcodeMaxLength){
            dispatch(fetchPostCodeRequest(postcode));
        }
    }

    useEffect(() => {
        if (postcodeData.pending) {
            if(postcodeData.postcode.prefecture !== undefined) {
                setPrefectureName(postcodeData.postcode.prefecture.name);
                setDisabledAddress(postcodeData.postcode.prefecture.name + postcodeData.postcode.city.name);
                setPrefectureId(postcodeData.postcode.prefecture.id);
                setRegionId(postcodeData.postcode.region.id);
            }
            if(postcodeData.postcode.city !== undefined){
                setCityName(postcodeData.postcode.city.name);
                setCityId(postcodeData.postcode.city.id);
            }
        }
    }, [postcodeData]);

      const handleCheckboxChange = (e: any) => {
        setIsEditCity(e.target.checked);
        if(e.target.checked) {
            setCityName2(city_name);
        } else {
            setCityName2('');
        }        
      };

    useEffect(() => {
        if(CompanyNumber !== null && CompanyNumber != '' &&  CompanyName !== null && CompanyName != '' && CompanyNamekana !== null 
            && CompanyNamekana != '' && address != '' && address !== null && postcode !== null && postcode != '') {
            setNextStep(true);
        }
    }, [CompanyNumber, CompanyName, CompanyNamekana, address, postcode]);

    useEffect(() => {
        if(familyname !== null && familyname != '' &&  familynameKana !== null && familynameKana != '' &&  phoneNumber !== null && phoneNumber != '' && department !== null
            && department != '') {
            setNextStepTwo(true);
        }
    }, [familyname, familynameKana, phoneNumber, department]);

    useEffect(() => {
        if(PurposeOfPosting !== undefined && PurposeOfPosting.includes(true) && termsOfCondition) {
            setNextStepThree(true);
            applyData();
        }
    }, [PurposeOfPosting,  termsOfCondition]);

    const applyData = () => {
        let array_purpose_of_postings = [];
        for(let i = 0; i < PurposeOfPosting.length; i++) {
            if(PurposeOfPosting[i]) {
                array_purpose_of_postings.push({purpose_of_posting_id: PurposeOfPostings[i].id});
            }
        }
        const company = [];
        if (!isEditCity) {
            // company.push({corporate_number:CompanyNumber, name:CompanyName, name_kana:CompanyNamekana, address:address, postcode:postcode,
            //     consideration_conditions: [],corporate_cultures: [],people_with_disabilities: [],prefecture_id: prefecture_id, region_id: region_id,
            //     city_id: city_id, city: {name: city_name},prefecture: {name: prefecture_name},city_correction: null,
            //     main_recruiter: {family_name:familyname, family_name_kana:familynameKana, phone_number:phoneNumber, department:department,
            //     purpose_of_postings:array_purpose_of_postings}});
            
            if(prefecture_id != null) {
                company.push({corporate_number:CompanyNumber, name:CompanyName, name_kana:CompanyNamekana, address:address, postcode:postcode,
                    consideration_conditions: [],corporate_cultures: [],people_with_disabilities: [], 
                    prefecture_id: prefecture_id, region_id: region_id,
                    city_id: city_id, city: {name: city_name},
                    prefecture: {name: prefecture_name}, city_correction: null,
                    main_recruiter: {family_name:familyname, family_name_kana:familynameKana, phone_number:phoneNumber, department:department,
                    purpose_of_postings:array_purpose_of_postings}});
            } else {
                company.push({corporate_number:CompanyNumber, name:CompanyName, name_kana:CompanyNamekana, address:address, postcode:postcode,
                    consideration_conditions: [],corporate_cultures: [],people_with_disabilities: [], 
                    main_recruiter: {family_name:familyname, family_name_kana:familynameKana, phone_number:phoneNumber, department:department,
                    purpose_of_postings:array_purpose_of_postings}});
            }
        } else {
            // company.push({corporate_number:CompanyNumber, name:CompanyName, name_kana:CompanyNamekana, address:address, postcode:postcode,
            //     consideration_conditions: [],corporate_cultures: [],people_with_disabilities: [],prefecture_id: prefecture_id, region_id: region_id,
            //     city_id: city_id, city: {name: city_name},prefecture: {name: prefecture_name}, city_correction: city_name2,
            //     main_recruiter: {family_name:familyname, family_name_kana:familynameKana, phone_number:phoneNumber, department:department,
            //     purpose_of_postings:array_purpose_of_postings}});
            if(prefecture_id != null) {
                company.push({corporate_number:CompanyNumber, name:CompanyName, name_kana:CompanyNamekana, address:address, postcode:postcode,
                    consideration_conditions: [],corporate_cultures: [],people_with_disabilities: [],prefecture_id: prefecture_id, region_id: region_id,
                    city_id: city_id, city: {name: city_name},prefecture: {name: prefecture_name}, city_correction: city_name2,
                    main_recruiter: {family_name:familyname, family_name_kana:familynameKana, phone_number:phoneNumber, department:department,
                    purpose_of_postings:array_purpose_of_postings}});
            } else {
                company.push({corporate_number:CompanyNumber, name:CompanyName, name_kana:CompanyNamekana, address:address, postcode:postcode,
                    consideration_conditions: [],corporate_cultures: [],people_with_disabilities: [],
                    main_recruiter: {family_name:familyname, family_name_kana:familynameKana, phone_number:phoneNumber, department:department,
                    purpose_of_postings:array_purpose_of_postings}});
            }
        }
        setCompanies(company);
    }

    const submit = () => {
        setEdited(false);
        if(Companies !== null) {
            setLoadScreen(true);
            // console.log(Companies[0]);
            dispatch(fetchCompaniesRequest(loginData.login.company.id, 'put', Companies[0]));            
        }
    }
    

  return (
    <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName} currentList="RecruiterList"/>
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className='bread_crumb'>
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様 初回登録ページ</a></li>
            </ul>
            <div className='ttl_box'>
                <h2 className='ttl'>企業様 初回登録ページ</h2>
            </div>
            <div className='initial_registration'>
                <div className='step_box'>
                    <ul>
                        {totalStep.map((num: any) => (
                            <li className={step === num ? 'active' : ''}>
                                <span>{ stepAry[num] }</span>
                             </li>
                        ))} 
                    </ul>
                </div>
                <div className='cont_box'>
                {step == 1 ?
                    <>
                    <form>
                    <h3>法人番号を教えてください</h3>
                    <p>※法人番号が分からない方は<a href="https://www.houjin-bangou.nta.go.jp/" target="_blank" rel="noopener noreferrer">こちらのサイト</a>でお調べいただけます。</p>
                    <input type="text" name="corporate_number" placeholder="例）12345678901234（13桁）" onChange={onChangeInput} value={CompanyNumber} className={errorCompanyNumber || errorCompanyNumberMaxLength || errorCompanyNumberNumeric? 'error': ''}/>
                    {errorCompanyNumber || errorCompanyNumberMaxLength || errorCompanyNumberNumeric ? 
                    <div className='error_box'>
                        {errorCompanyNumber   ?
                            <p className='error_msg'>入力必須項目です</p>
                        : <></>}
                        {errorCompanyNumberMaxLength   ?
                            <p className='error_msg'>法人番号は13桁で入力してください</p>
                        : <></>}
                        {errorCompanyNumberNumeric   ?
                            <p className='error_msg'>法人番号は数字で入力してください</p>
                        : <></>}
                    </div>
                    : <></>}
 
                    <h3>会社名を教えてください</h3>
                    <input type="text" name="name" placeholder="例）株式会社〇〇〇" onChange={onChangeInput}  value={CompanyName} className={errorCompanyName || errorCompanyNameMaxLength ? 'error': ''}/>
                    {errorCompanyName || errorCompanyNameMaxLength ? 
                    <div className='error_box'>
                        {errorCompanyName   ?
                            <p className='error_msg'>入力必須項目です</p>
                        : <></>}
                        {errorCompanyNameMaxLength   ?
                            <p className='error_msg'>会社名は{MaxHundred}文字以下で入力してください</p>
                        : <></>}
                    </div>
                    : <></>}

                    <h3>会社名（フリガナ）を教えてください</h3>
                    <input type="text" name="name_kana" placeholder="例）カブシキガイシャ〇〇〇" onChange={onChangeInput}  value={CompanyNamekana} className={errorCompanyNamekana|| errorCompanyNamekanaMaxLength || errorCompanyNamekanaValidate ? 'error': ''} />
                    {errorCompanyNamekana || errorCompanyNamekanaMaxLength || errorCompanyNamekanaValidate ? 
                    <div className='error_box'>
                        {errorCompanyNamekana   ?
                            <p className='error_msg'>入力必須項目です</p>
                        : <></>}
                        {errorCompanyNamekanaMaxLength   ?
                            <p className='error_msg'>会社名は {MaxHundred}文字以下で入力してください</p>
                        : <></>}
                        {errorCompanyNamekanaValidate   ?
                            <p className='error_msg'>会社名（フリガナ）は全角カタカナで入力してください</p>
                        : <></>}

                    </div>
                    : <></>}

                    <h3>本社住所を教えてください</h3>
                    <dl className='address'>
                        <dt>郵便番号</dt>
                        <dd>
                            <label>
                                <input type="text" name="postcode" placeholder="例）1020083" onChange={onChangeInput}  value={postcode} className={errorPostcode|| errorPostcodeNumeric || errorPostcodeMaxLength ? 'error': ''}/>
                                <button  type="button" className={`btn type3 ${disabled ? 'disabled' : ''}`} onClick={getPostCode}  >郵便番号から探す</button>
                            </label>
                        </dd>
                        <dt></dt>
                        <div className="error_box">
                            {errorPostcode || errorPostcodeNumeric || errorPostcodeMaxLength ||erroraddress || erroraddressMaxLength || errorDisabledAddress? 
                                <div className='error_box' style={{marginLeft: '130px', marginTop: '-35px'}}>
                                    {errorPostcode || erroraddress || errorDisabledAddress  ?
                                        <p className='error_msg'>入力必須項目です</p>
                                    : <></>}
                                    {errorPostcodeNumeric   ?
                                        <p className='error_msg'>郵便番号は数字のみで入力してください</p>
                                    : <></>}
                                    {errorPostcodeMaxLength   ?
                                        <p className='error_msg'>郵便番号は{MaxSeven}桁で入力してください</p>
                                    : <></>}
                                    {erroraddressMaxLength  ?
                                        <p className='error_msg'>住所(それ以降)は{MaxTwoHundred}文字以下で入力してください</p>
                                    : <></>}
                                </div>
                            : <></>}
                        </div>
                        <dt>都道府県<br/>市区町村<br/>字名</dt>
                        <dd className="city">
                            {!isEditCity ? <label className="city"><input type="text" name="prefecture_city" value={disabled_address} disabled/></label> : <></>}
                            {isEditCity ? <label className="prefecture"><input type="text" name="prefecture" value={prefecture_name} disabled/></label> : <></>}
                            {isEditCity ? <label><input type="text" name="prefecture_city" value={city_name2} onChange={(e) => {setCityName2(e.target.value)}}/></label> : <></>}
                            <label>
                                <input type="checkbox" value={isEditCity} onChange={handleCheckboxChange}/>市区町村を修正する
                            </label>
                        </dd>
                        <dt>住所(それ以降)</dt>
                        <dd>
                        <label><input type="text" name="region" placeholder="例）1-2-3" onChange={onChangeInput}  value={address} className={erroraddress|| erroraddressMaxLength ? 'error': ''}/></label>                        
                        </dd>
                    </dl>
                    {nextStep ?
                    <button type="button" className={nextStep ? 'btn type3 orange' : "disabled btn type3 orange"} onClick={() => moveStep(1)}>次へ
                        <img src="/assets/img/jobs/arrow03.svg" alt=""/>
                    </button>
                    :
                    <button type="button" className={nextStep ? 'btn type3 orange' : "disabled btn type3 orange"}>次へ
                        <img src="/assets/img/jobs/arrow03.svg" alt=""/>
                    </button>}
                    </form>
                    </>
                : <></>}
                
                {step == 2 ?
                <div  className='cont_box'>
                    <p className="read">※採用担当者情報は求人票には掲載されません。</p>
                </div>
                : <></>}
                {step == 2 ?
                <form>
                    <h3>ご担当者名を教えてください</h3>
                    <div className='name type2'>
                    <label>
                        <span>ご担当者名</span>
                        <input type="text" name="family_name" placeholder="例）パラ太郎" onChange={onChangeStepTwo}  value={familyname} className={errorfamilyname|| errorfamilyNameMaxLength  ? 'error': ''} />
                    </label>
                    <label>
                        <span>ご担当者名カナ</span>
                        <input type="text" name="family_name_kana" placeholder="例）パラタロウ" onChange={onChangeStepTwo}  value={familynameKana} className={errorfamilyNamekanaKanaValidate|| errorfamilyNamekanaMaxLength ? 'error': ''} />
                    </label>
                    </div>
                    {errorfamilyname || errorfamilyNameMaxLength || errorfamilyNamekanaKanaValidate || errorfamilyNamekanaMaxLength? 
                        <div className='error_box'>
                            {errorfamilyname  ?
                                <p className='error_msg'>入力必須項目です</p>
                            : <></>}
                            {errorfamilyNameMaxLength   ?
                                <p className='error_msg'>ご担当者名は{MaxThirty}文字以下で入力してください</p>
                            : <></>}
                            {errorfamilyNamekanaKanaValidate   ?
                                <p className='error_msg'>ご担当者名カナはカタカナで入力してください</p>
                            : <></>}
                            {errorfamilyNamekanaMaxLength  ?
                                <p className='error_msg'>ご担当者名は{MaxThirty}文字以下で入力してください</p>
                            : <></>}
                        </div>
                    : <></>}
                    <h3>支店・部署を教えてください</h3>
                    <p>
                        ※1アカウントで3求人まで掲載可能です。<br/>
                        ※アカウントは、企業名＋支店名or部署名で1アカウントと考えます。
                    </p>
                    <input type="text" name="department" placeholder="例）本社、人事部など" v-model="company.main_recruiter.department" onChange={onChangeStepTwo} value={department} className={errordepartment|| errordepartmentmaxLength ? 'error': ''} />
                    {errordepartment || errordepartmentmaxLength? 
                        <div className='error_box'>
                            {errordepartment  ?
                                <p className='error_msg'>入力必須項目です</p>
                            : <></>}
                            {errordepartmentmaxLength   ?
                                <p className='error_msg'>部署名は{MaxFifty}文字以下で入力してください</p>
                            : <></>}
                        </div>
                    : <></>}
                    <h3>電話番号を教えてください</h3>
                    <label>電話番号<input type="text" name="phone_number" placeholder="例）0300000000"  value={phoneNumber} onChange={onChangeStepTwo} className={errorphoneNumber|| errorphoneNumberNumeric || errorphoneNumbermaxLength ? 'error': ''} />                    
                    </label>
                    {errorphoneNumber || errorphoneNumberNumeric || errorphoneNumberminLength || errorphoneNumbermaxLength? 
                        <div className='error_box'>
                            {errorphoneNumber  ?
                                <p className='error_msg'>入力必須項目です</p>
                            : <></>}
                            {errorphoneNumberNumeric   ?
                                <p className='error_msg'>電話番号は数字のみで入力してください</p>
                            : <></>}
                            {errorphoneNumberminLength || errorphoneNumbermaxLength   ?
                                <p className='error_msg'>電話番号は{MinTen}桁以上{MaxEleven}桁以下で入力してください</p>
                            : <></>}
                        </div>
                    : <></>}
                    <button onClick={() => moveStep(-1)} type="button" className="btn type3">戻る
                        <img src="/assets//img/jobs/arrow03.svg" alt=""/>
                    </button>
                    {nextStepTwo ?
                    <button onClick={() => moveStep(1)} type="button" className={nextStepTwo ? 'btn type3 orange' : "disabled btn type3 orange"}>次へ
                        <img src="/assets//img/jobs/arrow03.svg" alt=""/>
                    </button>
                    :
                    <button type="button" className={nextStepTwo ? 'btn type3 orange' : "disabled btn type3 orange"}>次へ
                        <img src="/assets//img/jobs/arrow03.svg" alt=""/>
                    </button>}
                </form>
                : <></>}

                {step ===3 ?
                <form>
                    <h3>登録・求人掲載目的を教えてください</h3>
                    <div className='checkbox_list'>
                    {PurposeOfPostings && PurposeOfPostings.map((data: any, index: any) => ( 
                        // <option value={data.id}>{data.name}</option>
                        <label key={data}>
                            <input type="checkbox" name="purpose" onChange={(e) => onChangeStepThree(e, index)}  value={PurposeOfPosting} className={errorPurposeOfPosting ? 'error': ''}></input>
                            {data.name}
                        </label>
                    ))}
                    {errorPurposeOfPosting ? 
                    <div className='error_box'>
                        {errorPurposeOfPosting   ?
                            <p className='error_msg'>選択必須項目です</p>
                        : <></>}
                    </div>
                    : <></>}
                    </div>
                    <div className="privacy_policy">
                        <p><a href="/terms1/" target="_blank">利用規約</a>と<a href="/privacy/" target="_blank">プライバシーポリシー</a>をご確認いただき、<br className="sp_only"/>同意の上送信してください</p>
                        <label>
                            <input name='agree' type="checkbox" title="利用規約とプライバシーポリシーの内容 同意する" value={termsOfCondition} onChange={(e) => onChangeStepThree(e, 0)}/><span>利用規約とプライバシーポリシーの<br className="sp_only"/>内容を確認し、同意する。</span>
                        </label>
                        {errorTerms ?
                            <div className="error_box">                                        
                                {errorTerms ?
                                    <p className="error_msg">選択必須項目です</p>                              
                                : <></>} 
                            </div>                                
                         : <></>} 
                    </div>
                    <button onClick={() => moveStep(-1)} type="button" className="btn type3">戻る<img src="/assets//img/jobs/arrow03.svg" alt=""/></button>
                    {nextStepThree ?
                    <button onClick={() => submit()}  type="button" className={nextStepThree ? 'btn type3 orange' : "disabled btn type3 orange"}
                    >完了<img src="/assets//img/jobs/arrow03.svg" alt=""/></button>
                    :
                    <button type="button" className={nextStepThree ? 'btn type3 orange' : "disabled btn type3 orange"}
                    >完了<img src="/assets//img/jobs/arrow03.svg" alt=""/></button>}
                </form>
                : <></>}
                </div>
            </div>
        </section>
    </main>
  );
}

export default RecruiterInit;

