import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { regionsTypes } from "../../ActionTypes/regionsTypes";
import { fetchMessagesFailure, fetchMessagesSuccess } from "../../actions/messagesActions/messagesActions";
import { MessagesTypes } from "../../ActionTypes/messagesTypes";

var getMessages = (queryParams:any) => axios.get(API_BASE_ADDRESS  + queryParams , {headers: {
  Authorization: localStorage.getItem('jobParachannelAuthToken'),
  Accept: '*/*',
}}); 
function* fetchMessagesSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getMessages, queryParams.message);
    yield put(
      fetchMessagesSuccess({
        messages: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchMessagesFailure({
        error: e.message
      })
    );
  }
}

function* MessagesSaga() {
  yield takeLatest(MessagesTypes.FETCH_MESSAGES_REQUEST, fetchMessagesSaga);
}

export default MessagesSaga;
