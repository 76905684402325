import { recruiterReceiptPostTypes } from "../../ActionTypes/recruiterReceiptPostTypes";
import { FetchRecruiterReceiptPostFailure, FetchRecruiterReceiptPostFailurePayload, FetchRecruiterReceiptPostRequest, FetchRecruiterReceiptPostSuccess, FetchRecruiterReceiptPostSuccessPayload } from "../../types/type";


export const fetchRecruiterReceiptPostRequest = (data:any): FetchRecruiterReceiptPostRequest => ({
  type: recruiterReceiptPostTypes.FETCH_RECRUITER_RECEIPT_POST_REQUEST,
  data,
});

export const fetchRecruiterReceiptPostSuccess = (
  payload: FetchRecruiterReceiptPostSuccessPayload,
): FetchRecruiterReceiptPostSuccess => ({
  type: recruiterReceiptPostTypes.FETCH_RECRUITER_RECEIPT_POST_SUCCESS,
  payload
});

export const fetchRecruiterReceiptPostFailure = (
  payload: FetchRecruiterReceiptPostFailurePayload
): FetchRecruiterReceiptPostFailure => ({
  type: recruiterReceiptPostTypes.FETCH_RECRUITER_RECEIPT_POST_FAILURE,
  payload
});
