import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { regionsTypes } from "../../ActionTypes/regionsTypes";
import { fetchRegionsFailure, fetchRegionsSuccess } from "../../actions/regionsActions/regionsActions";
import { fetchInitFailure, fetchInitSuccess } from "../../actions/initActions/initActions";
import { initTypes } from "../../ActionTypes/initTypes";

// var numb  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

  var getInit = () => axios.get(API_BASE_ADDRESS + '/master?companies=true&industries=true', {headers: { Accept: 'application/json', Prefer: 'example=example-1'}});

function* fetchInitSaga() {
  try {
    const response: AxiosResponse = yield call(getInit);
    yield put(
      fetchInitSuccess({
        init: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchInitFailure({
        error: e.message
      })
    );
  }
}

function* InitSaga() {
  yield takeLatest(initTypes.FETCH_INIT_REQUEST, fetchInitSaga);
}

export default InitSaga;
