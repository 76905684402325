import { summaryTypes } from "../../ActionTypes/summaryTypes";
import { SummaryState, SummaryActions } from "../../types/type";



const initialState: SummaryState = {
  pending: false,
  summary: [],
  error: null,
};

export default (state = initialState, action: SummaryActions,) => {
  switch (action.type) {
    case summaryTypes.FETCH_SUMMARY_REQUEST:
      return {
        ...state,
        pending: false
      };
    case summaryTypes.FETCH_SUMMARY_SUCCESS:
      return {
        ...state,
        pending: true,
        summary: action.payload.summary,
        error: null
      };
    case summaryTypes.FETCH_SUMMARY_FAILURE:
      return {
        ...state,
        pending: false,
        summary: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
