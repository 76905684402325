import { recruitmentsApplicationTypes } from "../../ActionTypes/recruitmentsApplicationTypes";
import { RecruitmentsApplicationActions, RecruitmentsApplicationState, } from "../../types/type";


const initialState: RecruitmentsApplicationState = {
  pending: false,
  recruitmentsApplication: [],
  error: null,
};

export default (state = initialState, action: RecruitmentsApplicationActions,) => {
  switch (action.type) {
    case recruitmentsApplicationTypes.FETCH_RECRUIMENTS_APPLICATION_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitmentsApplicationTypes.FETCH_RECRUIMENTS_APPLICATION_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitmentsApplication: action.payload.recruitmentsApplication,
        error: null
      };
    case recruitmentsApplicationTypes.FETCH_RECRUIMENTS_APPLICATION_FAILURE:
      return {
        ...state,
        pending: false,
        recruitmentsApplication: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
