import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import { RootState } from '../../../redax/reducers/rootReducer';
import Loader from '../../loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAdminAdministratorsRequest } from '../../../redax/actions/adminAdministratorsActions/adminAdministratorsActions';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { fetchAdminAdministratorsDeleteRequest } from '../../../redax/actions/adminAdministratorsDeleteActions/adminAdministratorsDeleteActions';

const AdminAdministratorList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const administrators = useSelector((state: RootState) => state.adminAdministrators);
  const loginData = useSelector((state: RootState) => state.login);
  const administratorsDelete = useSelector((state: RootState) => state.adminAdministratorsDelete);
  const [loadScreen, setLoadScreen] = useState(true);
  
  const [current_page, setCurrentPage] = useState(1);
  const [total_pages, setTotalPages] = useState(1);
  const [total_count, setTotalCount] = useState<any>(1);
  const [adminAdministratorsList, setAdminAdministratorsList] = useState<any>([]);
  const [keyword, setKeyword] = useState(''); 
  const [resourceId, setResourceId] = useState(''); 
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  // const reloadPage = () => {
  //   history.push({
  //     pathname: location.pathname,
  //     search: location.search,
  //     state: {
  //       force: true
  //     }
  //   });
  // };
  
  useEffect(() => {
    dispatch(fetchAdminAdministratorsRequest('get','',''));
    dispatch(fetchLoginRequest('', '', 'Administrator'));
}, [dispatch]); 




  useEffect(() => {
    if (administrators.pending) {
      setLoadScreen(false);
      setAdminAdministratorsList(administrators.administrators);
      setTotalCount(administrators?.total_count || 1);
    }
  }, [administrators]);


  useEffect(() => {
    if (administratorsDelete.pending) {
      window.location.reload();
    }
  }, [administratorsDelete]);


  useEffect(() => {
    if (loginData.pending) {
      if(loginData.login.resource.id) {
        setResourceId(loginData.login.resource.id)
      }
    }
  }, [loginData]);

  function fetchDelete(administrattor: any) {
    if (!window.confirm('この管理者を削除してよろしいですか？')) { return }
    dispatch(fetchAdminAdministratorsDeleteRequest(administrattor));
    // setTimeout(() => {
    //   window.location.reload();
    // }, 3000);
  }


  function handleClick () {
    // navigate('/admin/administrator_new')
    window.location.href = '/admin/administrator_new';
  }
  function moveEditScreen (administratorsData: any) {
    navigate(`/admin/administrators/${administratorsData.id}`, {
      state: { administratorsData }
    });
  }



  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader></Loader> : <></>}
      <section>
        <div className="adminPageWrapper">
          <div className="header">
            <h2 className="title">管理者一覧 </h2>
          </div>   
          
          <div className="body">
            <div className="adminTable1Wrapper">
              <div className="header">
                <div className="textWrapper">
                  <span className="text"> 全{total_count}件</span>
                </div>
                <div>
                  <label className="label1">
                    <button className="createBtn" onClick={handleClick}>新規作成</button>
                  </label>
                </div>
              </div>
              <div className="body">
              
                <table className="table openTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>管理区分</th>
                      <th>名前</th>
                      <th>メールアドレス</th>
                      <th>権限変更</th>
                      <th>削除 </th>
                    </tr>
                  </thead>
                  <tbody>
                  {adminAdministratorsList.length != 0 && adminAdministratorsList ?
                  <>
                  {adminAdministratorsList.map((administrators: any, index: any) => (
                    <tr className='openTable'>
                      <td>{index +1}</td>
                      <td> <div className='bordered'>{administrators.role}</div> </td>
                      <td>{administrators.name}</td>
                      <td>{administrators.email}</td>
                      {resourceId && resourceId !== administrators.id ?
                        <>
                          <td><a className="detailBtnAdmin"onClick={() => moveEditScreen(administrators)}>権限変更</a></td>
                          <td><a className="red" onClick={()=>fetchDelete(administrators.id)}>削除する</a></td>
                        </>
                        : 
                        <> 
                          <td></td>
                          <td></td>
                        </>
                      }
                    </tr>    
                  ))}
                  </>
                  : <></>}
                  </tbody>
                </table>
                {/* <div className="paginationWrapper">
                    <div className="icon"></div>
                    <ul className="pagination">
                        {current_page && current_page > 1 && (
                        <li className="arrow prev">
                            <a onClick={() => pageDecrement()}>
                            <img src="/assets/img/company/icon06.svg" alt="前へ" />
                            </a>
                        </li>
                        )}

                        {shaping_pagenation().map((n: any) => (
                        <li key={n} className={n === current_page ? 'active' : ''}>
                            <a onClick={() => updatePage(n)}>{n}</a>
                        </li>
                        ))}

                        {current_page && current_page < total_pages && (
                        <li className="arrow next">
                            <a onClick={() => pageIncrement()}>
                            <img src="/assets/img/company/icon07.svg" alt="次へ" />
                            </a>
                        </li>
                        )}
                    </ul>
                </div> */}
              </div>
            </div>
          </div> 
        </div>      
      </section>
    </main>
  );
};

export default AdminAdministratorList;
