import { adminApplicantsRegionsTypes } from "../../ActionTypes/adminApplicantsRegionsTypes";
import { AdminApplicantsRegionsActions, AdminApplicantsRegionsState } from "../../types/type";




const initialState: AdminApplicantsRegionsState = {
  pending: false,
  regions: [],
  error: null,
};

export default (state = initialState, action: AdminApplicantsRegionsActions,) => {
  switch (action.type) {
    case adminApplicantsRegionsTypes.FETCH_ADMIN_APPLICANTS_REGIONS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case adminApplicantsRegionsTypes.FETCH_ADMIN_APPLICANTS_REGIONS_SUCCESS:
      return {
        ...state,
        pending: true,
        regions: action.payload.regions,
        error: null
      };
    case adminApplicantsRegionsTypes.FETCH_ADMIN_APPLICANTS_REGIONS_FAILURE:
      return {
        ...state,
        pending: false,
        regions: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
