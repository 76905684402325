import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchAdminScoutApplicantsFailure, fetchAdminScoutApplicantsSuccess } from "../../actions/adminScoutApplicantsActions/adminScoutApplicantsActions";
import { adminScoutApplicantsTypes } from "../../ActionTypes/adminScoutMatchingApplicantsTypes";

const getAdminScoutJobs = (method: any, data: any) => {
    if(method == 'get') {
      return axios.post(API_BASE_ADDRESS + '/admin/scout_matching/recruitments/applicants?', data , {
        headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }
      });
    } else {
      return axios.post(API_BASE_ADDRESS + '/admin/scout_matching/applicants', data, {
        headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }
      });
    }  
}

function* fetchApplicantDecideSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminScoutJobs, action.method, action.data);
    yield put(
      fetchAdminScoutApplicantsSuccess({
        adminApplicants: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminScoutApplicantsFailure({
        error: e.message
      })
    );
  }
}

function* AdminScoutApplicantsSaga() {
  yield takeLatest(adminScoutApplicantsTypes.FETCH_ADMIN_SCOUT_APPLICANTS_REQUEST, fetchApplicantDecideSaga);
}
export default AdminScoutApplicantsSaga;