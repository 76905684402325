import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchCompaniesFailure, fetchCompaniesSuccess } from "../../actions/companiesActions/companiesActions";
import { companiesTypes } from "../../ActionTypes/companiesTypes";


var getCompanies = (id:any, types: any, data: any) => {
  if(types == 'get') {
    return axios.get(API_BASE_ADDRESS + '/companies/' + id, {headers: { Accept: 'application/json'}});
  } else {
    return axios.put(API_BASE_ADDRESS + '/companies/' + id, data, {headers: { Accept: 'application/json'}});
  }
}

function* fetchCompaniesSaga(id: any) {
  try {
    const response: AxiosResponse = yield call(getCompanies, id.id, id.types, id.data);
    yield put(
      fetchCompaniesSuccess({
        companies: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchCompaniesFailure({
        error: e.message
      })
    );
  }
}

function* CompaniesSaga() {
  yield takeLatest(companiesTypes.FETCH_COMPANIES_REQUEST, fetchCompaniesSaga);
}

export default CompaniesSaga;
