import { postcodeTypes } from "../../ActionTypes/postcodeTypes";
import { PostCodeActions, PostCodeState } from "../../types/type";


const initialState: PostCodeState = {
  pending: false,
  postcode: [],
  error: null,
};

export default (state = initialState, action: PostCodeActions,) => {
  switch (action.type) {
    case postcodeTypes.FETCH_POSTCODE_REQUEST:
      return {
        ...state,
        pending: false
      };
    case postcodeTypes.FETCH_POSTCODE_SUCCESS:
      return {
        ...state,
        pending: true,
        postcode: action.payload.postcode,
        error: null
      };
    case postcodeTypes.FETCH_POSTCODE_FAILURE:
      return {
        ...state,
        pending: false,
        postcode: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
