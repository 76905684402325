import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_OPENAI_ADDRESS, OPENAI_API_KEY } from "../../API";
import { openAiTypes } from "../../ActionTypes/openAiTypes";
import { fetchOpenAiActionsFailure, fetchOpenAiRequest, fetchOpenAiSuccess } from "../../actions/openAiActions/openAiActions";


var getOpenAI = (body:any) => axios.post(API_OPENAI_ADDRESS, body, {
  headers: {
  Authorization: `Bearer ${OPENAI_API_KEY}`,
  'Content-Type': 'application/json',
  }
}); 
function* fetchOpenAiSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getOpenAI, action.body);
    yield put(
      fetchOpenAiSuccess({
        body: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchOpenAiActionsFailure({
        error: e.message
      })
    );
  }
}

function* OpenAiSaga() {
  yield takeLatest(openAiTypes.FETCH_OPENAI_REQUEST, fetchOpenAiSaga);
}
export default OpenAiSaga;


