import { useState } from "react";


function Guide11() {

    
    return (
        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">11.求人の効果確認について</h3>
          <p>この記事は約3分で読めます。</p>
    
          <p>このページでは求人の効果確認について説明します。</p>
    
          <p>1.TOPページ(<a href="/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/</a>) から画面右上の「採用担当者様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/first.png" alt=""/>
    
          <p>2.「既に会員の企業様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/11/02.png" alt=""/>
    
          <p>3.登録したメールアドレス・パスワードにて、ログインしてください。<br/>
          パスワードをお忘れの方はこちらから再設定してください。<br/>
          <a href="/recruiter/password/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/recruiter/password/</a></p>
          <img src="/assets/img/guide/11/03.png" alt=""/>
    
          <p>4.マイページ画面をスクロールすると各求人の効果状況を一覧で見ることができます。
          パラちゃんねる求人ってどれくらいの効果が出ているか、社内で共有する際にご活用ください。（今後、CSV等で出力できるように開発中）</p>
          <img src="/assets/img/guide/11/04.png" alt=""/>
          <img src="/assets/img/guide/11/05.png" alt=""/>
          <p>続いて、各種設定については<a href="/guide/12/" style={{display: 'inline',color: '#00a0e9'}}>こちら</a>をお読みください。</p>
          <a href="/guide/" className="btn type3 nuxt-link-active">一覧へ戻る</a>
        </section>
      </main>
    );
  }
  
  export default Guide11;