import { applicantsPrTypes } from "../../ActionTypes/applicantsPrTypes";
import { FetchApplicantsPrFailure, FetchApplicantsPrFailurePayload, FetchApplicantsPrRequest, FetchApplicantsPrSuccess, FetchApplicantsPrSuccessPayload } from "../../types/type";

export const fetchApplicantsPrRequest = (id:any, name: any, pr_id: any, a: any): FetchApplicantsPrRequest => ({
  type: applicantsPrTypes.FETCH_APPLICANTS_PR_REQUEST,
  id,
  name,
  pr_id,
  a
});

export const fetchApplicantsPrSuccess = (
  payload: FetchApplicantsPrSuccessPayload,
): FetchApplicantsPrSuccess => ({
  type: applicantsPrTypes.FETCH_APPLICANTS_PR_SUCCESS,
  payload
});

export const fetchApplicantsPrFailure = (
  payload: FetchApplicantsPrFailurePayload
): FetchApplicantsPrFailure => ({
  type: applicantsPrTypes.FETCH_APPLICANTS_PR_FAILURE,
  payload
});
