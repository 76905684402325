import { adminAdministratorsTypes } from "../../ActionTypes/adminAdministratorsTypes";
import { FetchAdminAdministratorsFailure, FetchAdminAdministratorsFailurePayload, FetchAdminAdministratorsRequest, FetchAdminAdministratorsSuccess, FetchAdminAdministratorsSuccessPayload } from "../../types/type";

export const fetchAdminAdministratorsRequest = (name:any, body:any, id:any): FetchAdminAdministratorsRequest => ({
  type: adminAdministratorsTypes.FETCH_ADMIN_ADMINISTRATORS_REQUEST,
  name,
  body,
  id,
});

export const fetchAdminAdministratorsSuccess = (
  payload: FetchAdminAdministratorsSuccessPayload
): FetchAdminAdministratorsSuccess => ({
  type: adminAdministratorsTypes.FETCH_ADMIN_ADMINISTRATORS_SUCCESS,
  payload
});

export const fetchAdminAdministratorsFailure = (
  payload: FetchAdminAdministratorsFailurePayload
): FetchAdminAdministratorsFailure => ({
  type: adminAdministratorsTypes.FETCH_ADMIN_ADMINISTRATORS_FAILURE,
  payload
});