import { scoutsCandidateTypes } from "../../ActionTypes/scoutsCandidateTypes";
import { FetchScoutsCandidateFailure, FetchScoutsCandidateFailurePayload, FetchScoutsCandidateRequest, FetchScoutsCandidateSuccess, 
  FetchScoutsCandidateSuccessPayload } from "../../types/type";


export const fetchScoutsCandidateRequest = (id: any, name:any, data: any): FetchScoutsCandidateRequest => ({
  type: scoutsCandidateTypes.FETCH_SCOUTS_CANDIDATE_REQUEST,
  id,
  name,
  data,
});

export const fetchScoutsCandidateSuccess = (
  payload: FetchScoutsCandidateSuccessPayload,
): FetchScoutsCandidateSuccess => ({
  type: scoutsCandidateTypes.FETCH_SCOUTS_CANDIDATE_SUCCESS,
  payload
});

export const fetchScoutsCandidateFailure = (
  payload: FetchScoutsCandidateFailurePayload
): FetchScoutsCandidateFailure => ({
  type: scoutsCandidateTypes.FETCH_SCOUTS_CANDIDATE_FAILURE,
  payload
});
