import { recruiterPricingTypes } from "../../ActionTypes/recruiterPricingTypes";
import { RecruiterPricingActions, RecruiterPricingState } from "../../types/type";



const initialState: RecruiterPricingState = {
  pending: false,
  recruiterPricing: [],
  error: null,
};

export default (state = initialState, action: RecruiterPricingActions,) => {
  switch (action.type) {
    case recruiterPricingTypes.FETCH_RECRUITER_PRICING_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruiterPricingTypes.FETCH_RECRUITER_PRICING_SUCCESS:
      return {
        ...state,
        pending: true,
        recruiterPricing: action.payload.recruiterPricing,
        error: null
      };
    case recruiterPricingTypes.FETCH_RECRUITER_PRICING_FAILURE:
      return {
        ...state,
        pending: false,
        recruiterPricing: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
