import { articlesImageTypes } from "../../ActionTypes/articlesImageTypes";
import { ArticlesImageFailure, ArticlesImageFailurePayload, ArticlesImageRequest, ArticlesImageSuccess, ArticlesImageSuccessPayload } from "../../types/type";

export const articlesImageRequest = (image:any, types: any, id: any): ArticlesImageRequest => ({
  type: articlesImageTypes.FETCH_ARTICLES_IMAGE_REQUEST,
  image,
  types,
  id
});

export const articlesImageSuccess = (
  payload: ArticlesImageSuccessPayload,
): ArticlesImageSuccess => ({
  type: articlesImageTypes.FETCH_ARTICLES_IMAGE_SUCCESS,
  payload
});

export const articlesImageFailure = (
  payload: ArticlesImageFailurePayload
): ArticlesImageFailure => ({
  type: articlesImageTypes.FETCH_ARTICLES_IMAGE_FAILURE,
  payload
});
