import {adminScoutMatchingJobsTypes } from "../../ActionTypes/adminScoutMatchingTypes";
import {  AdminScoutMatchingJobsActionss, AdminScoutMatchingJobsState } from "../../types/type";


const initialState: AdminScoutMatchingJobsState = {
  pending: false,
  jobs: [],
  error: null,
};

export default (state = initialState, action: AdminScoutMatchingJobsActionss,) => {
  switch (action.type) {
    case adminScoutMatchingJobsTypes.FETCH_ADMIN_SCOUT_MATCHING_JOBS_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminScoutMatchingJobsTypes.FETCH_ADMIN_SCOUT_MATCHING_JOBS_SUCCESS:
      return {
        ...state,
        pending: true,
        jobs: action.payload.jobs,
        error: null
      };
    case adminScoutMatchingJobsTypes.FETCH_ADMIN_SCOUT_MATCHING_JOBS_FAILURE:
      return {
        ...state,
        pending: false,
        applicant: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
