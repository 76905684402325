import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchRecruitmentsListRequest } from '../../../redax/actions/recruitmentsListActions/recruitmentsListActions';
import Loader from '../../loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAdminRecruitmentListRequest } from '../../../redax/actions/adminRecruitmentListActions/adminRecruitmentListActions';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { fetchAdminRecruitmentCsvRequest } from '../../../redax/actions/adminRecruitmentCsvActions/adminRecruitmentCsvActions';


const AdminOpenJobList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);   
  const params = location.state;
  const recruitmentsList = useSelector((state: RootState) => state.adminRecruitmentList);
  const adminRecruitmentCsv = useSelector((state: RootState) => state.adminRecruitmentCsv);

  const [loadScreen, setLoadScreen] = useState(true);
  const [isDisolayDeleted, setIsDisplayDeleted] = useState(false);

  const [current_page, setCurrentPage] = useState(1);
  const [total_pages, setTotalPages] = useState(1);
  const [total_count, setTotalCount] = useState<any>(1);
  const [recruitmentList, setRecruitmentListList] = useState<any>([]);
  const [keyword, setKeyword] = useState(''); 
  const [page, setPage] = useState(1);
  const [downCsvPath, setDownCsvPath] = useState('');
  const navigate = useNavigate();
  const [csvChk, setCsvChk] = useState(false);


  useEffect(() => {
    getRecruiter(1,'','')
    if(queryParams.size == 0){
      setKeyword('');
    }
}, [dispatch]); 



const getRecruiter = (page: any, keyword: any, deleted: any) => {
  setLoadScreen(true);
  const data = {
      "page": page,
      "keyword": keyword ? keyword : '',
      "deleted": deleted ? deleted : '',
  }
      dispatch(fetchAdminRecruitmentListRequest(data));
      if(recruitmentsList.pagenation !== undefined) {
          setCurrentPage(recruitmentsList.pagenation.current_page);
          setTotalPages(recruitmentsList.pagenation.total_pages);
        }
}



  useEffect(() => {
    if (recruitmentsList.pending) {
        setRecruitmentListList(recruitmentsList.recruitments);
        setCurrentPage(recruitmentsList.pagenation.current_page);
        setTotalPages(recruitmentsList.pagenation.total_pages);
        setTotalCount(recruitmentsList.pagenation?.total_count || 1);
        setLoadScreen(false)
    }
  }, [recruitmentsList]);



 useEffect(() => {
    if (adminRecruitmentCsv.pending && csvChk) {
        setDownCsvPath(adminRecruitmentCsv.csv.file);
        if(downCsvPath !== '') {
            window.open(downCsvPath, '_blank', 'noreferrer');
            setCsvChk(false);
        }
    }
  }, [adminRecruitmentCsv,downCsvPath]);

  const shaping_pagenation = () => {
    if (total_pages === null) return [1]; // Add a check for total_pages being null
    if (total_pages === 1) return [1]; // Add a check for total_pages being null
    
    if (total_pages <= 11) {
      return Array.from({ length: total_pages }, (_, index) => index + 1);
    } else if (current_page < 7) {
      return Array.from({ length: 11 }, (_, index) => index + 1);
    } else if (total_pages - current_page <= 5) {
      const diff_current_total = total_pages - current_page;
      const pagenation_start = current_page - (11 - (diff_current_total + 1));
      const pagenation_end = current_page + diff_current_total;
      return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
    } else {
      const pagenation_start = current_page - 5;
      const pagenation_end = current_page + 5;
      return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
    }
};

function formatDate (value: any) {
    return dayjs(value).locale(ja).format('YYYY.MM.DD (ddd) HH:mm');
}






const pageDecrement = () => {
  if (page <= 1) {
      setPage(1);
      return;
  }
  setPage((prevPage) => prevPage - 1);
  setLoadScreen(true);
  getRecruiter(page-1,keyword,'')
}


const updatePage = (p: any) => {
  setPage(p);
  setLoadScreen(true);
  getRecruiter(p,keyword,'')
}


const pageIncrement = () => {
  if (page >= total_pages) {
    setPage(total_pages);
    return;
  }
  setPage((prevPage) => prevPage + 1);
  setLoadScreen(true);
  getRecruiter(page+1,keyword,'')
}


function handleClick() {
  getRecruiter(1,keyword,'')
}


  function displayDeteled() {
    setLoadScreen(true);
    if(isDisolayDeleted){
        setIsDisplayDeleted(false);
        queryParams.delete('deleted');
        getRecruiter(page,keyword,'')
    } else {
        setIsDisplayDeleted(true);
        getRecruiter(1,'','true')

    }
  }
  function downloadCSV() {
    dispatch(fetchAdminRecruitmentCsvRequest());
    setCsvChk(true);
  }

  function handleInput(e: any) {  
    if(e.target.name == 'keyword') {
        setKeyword(e.target.value);
  }
  
}
  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader></Loader> : <></>}
      <section>
        <div className="adminPageWrapper">
          <div className="header">
            <h2 className="title">求人一覧 </h2>
            <div className="inputBox">
              <label className="label1">
                <span>求人名: </span>
                <input name="keyword" type="text" className="inputText" placeholder='企業名で検索...' onChange={handleInput}/>
                <button className="inputBtn" onClick={handleClick}>検索</button>
                {/* <button onClick={reset}>Reset</button> */}
              </label>
            </div>
          </div>   
          <div className="body">
            <div className="adminTable1Wrapper">
              <div className="header">
                <div className="textWrapper">
                  <span className="text">{current_page}/{total_pages} ページ &nbsp;  全{total_count}件</span>
                </div>
                <div>
                <div className='d-flex'>
                    <button  className='csvButton' onClick={downloadCSV}>CSVダウンロード</button>
                    {isDisolayDeleted ? <button  className='deleteBtn' onClick={displayDeteled}>戻る</button> : <button className='deleteBtn' onClick={displayDeteled} v-else><span className='d-flex'><img className='icomImg' src="/assets/img/deleteWhite.svg" alt="" /> 削除リスト</span> </button>}
                    <button className='createBtn' onClick={(e)=>{navigate("/admin/openjob_list/new")}}>新規作成</button>
                </div>
                </div>
              </div>
              <div className="body">
              
                <table className="table openTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>TOP画像</th>
                      <th>求人名</th>
                      <th>企業名</th>
                      <th>職種</th>
                      <th>雇用形態 </th>
                      <th>本社</th>
                      <th>登録日/更新日</th>
                      <th className="text-center">-</th>
                    </tr>
                  </thead>
                  <tbody>
                  {recruitmentList.length != 0 && recruitmentList ?
                  <>
                  {recruitmentList.map((recruitments: any, index: any) => (
                    <tr className='openTable' key={"openTable"+index}>
                      <td>{index +1}</td>
                      <td className='recruitmentImg'><img src={recruitments.thumbnail} alt={recruitments.company.name}></img></td>
                      <td>{recruitments.job_title}</td>
                      <td>{recruitments.company.name}</td>
                      <td>{recruitments.job_category}</td>
                      <td>{recruitments.employment_status}</td>
                      <td>{recruitments.work_location}</td>
                      <td>{formatDate(recruitments.created_at)}/ {formatDate(recruitments.updated_at)}</td>
                      <td> <button className='btn openBtn' onClick={(e) => {navigate('/admin/openjob_list/' + recruitments.id + '/')}}>詳細を見る</button></td>
                    </tr>    
                  ))}
                  </>
                  : <></>}
                  </tbody>
                </table>
                <div className="paginationWrapper">
                    <div className="icon"></div>
                    <ul className="pagination">
                        {current_page && current_page > 1 && (
                        <li className="arrow prev">
                            <a onClick={() => pageDecrement()}>
                            <img src="/assets/img/company/icon06.svg" alt="前へ" />
                            </a>
                        </li>
                        )}

                        {shaping_pagenation().map((n: any) => (
                        <li key={n} className={n === current_page ? 'active' : ''}>
                            <a onClick={() => updatePage(n)}>{n}</a>
                        </li>
                        ))}

                        {current_page && current_page < total_pages && (
                        <li className="arrow next">
                            <a onClick={() => pageIncrement()}>
                            <img src="/assets/img/company/icon07.svg" alt="次へ" />
                            </a>
                        </li>
                        )}
                    </ul>
                </div>
              </div>
            </div>
          </div> 
        </div>      
      </section>
    </main>
  );
};

export default AdminOpenJobList;
