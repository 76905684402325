import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchAdminInvoiceFailure, fetchAdminInvoiceSuccess } from "../../actions/adminInvoiceActions/adminInvoiceActions";
import { adminInvoiceTypes } from "../../ActionTypes/adminInvoiceTypes";

const getAdminInvoice = (data: any) => { 
  const config = {
    headers: { 
      Prefer: 'example=example-1', Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/invoices/?year=' + data.year + '&month=' + data.month, config); 
}


function* fetchAdminInvoiceSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminInvoice, action.data);
    yield put(
      fetchAdminInvoiceSuccess({
        invoiceList: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminInvoiceFailure({
        error: e.message
      })
    );
  }
}

function* AdminInvoiceSaga() {
  yield takeLatest(adminInvoiceTypes.FETCH_ADMIN_INVOICE_REQUEST, fetchAdminInvoiceSaga);
}
export default AdminInvoiceSaga;