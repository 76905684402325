import { adminCompanyRecruiterTypes } from "../../ActionTypes/adminCompanyRecruiterTypes";
import { FetchAdminCompanyRecruiterRequest, FetchAdminCompanyRecruiterSuccessPayload, FetchAdminCompanyRecruiterSuccess, FetchAdminCompanyRecruiterFailurePayload, FetchAdminCompanyRecruiterFailure } from "../../types/type";


export const fetchAdminCompanyRecruiterRequest = (body: any, method:any, id:any, recruiterId:any): FetchAdminCompanyRecruiterRequest => ({
  type: adminCompanyRecruiterTypes.FETCH_ADMIN_COMPANY_RECRUITER_REQUEST,
  body,
  method,
  id,
  recruiterId,
});

export const fetchAdminCompanyRecruiterSuccess = (
  payload: FetchAdminCompanyRecruiterSuccessPayload
): FetchAdminCompanyRecruiterSuccess => ({
  type: adminCompanyRecruiterTypes.FETCH_ADMIN_COMPANY_RECRUITER_SUCCESS,
  payload
});

export const fetchAdminCompanyRecruiterFailure = (
  payload: FetchAdminCompanyRecruiterFailurePayload
): FetchAdminCompanyRecruiterFailure => ({
  type: adminCompanyRecruiterTypes.FETCH_ADMIN_COMPANY_RECRUITER_FAILURE,
  payload
});