import { applicantTypes } from "../../ActionTypes/applicantTypes";
import { FetchApplicantFailure,
   FetchApplicantFailurePayload,
    FetchApplicantRequest, 
    FetchApplicantSuccess,
     FetchApplicantSuccessPayload } from "../../types/type";



export const fetchApplicantRequest = (id:any,): FetchApplicantRequest => ({
  type: applicantTypes.FETCH_APPLICANT_REQUEST,
  id,
});

export const fetchApplicantSuccess = (
  payload: FetchApplicantSuccessPayload,
): FetchApplicantSuccess => ({
  type: applicantTypes.FETCH_APPLICANT_SUCCESS,
  payload
});

export const fetchApplicantFailure = (
  payload: FetchApplicantFailurePayload
): FetchApplicantFailure => ({
  type: applicantTypes.FETCH_APPLICANT_FAILURE,
  payload
});
