
import { recruitmentsListTypes } from "../../ActionTypes/recruitmentsListTypes";
import {
  FetchRecruitmentsListFailure,
  FetchRecruitmentsListFailurePayload,
  FetchRecruitmentsListRequest,
  FetchRecruitmentsListSuccess,
  FetchRecruitmentsListSuccessPayload,
} from "../../types/type";

export const fetchRecruitmentsListRequest = (id:any, types: any, queryParams:any): FetchRecruitmentsListRequest => ({
  type: recruitmentsListTypes.FETCH_RECRUIMENTS_LIST_REQUEST,
  id,
  types,
  queryParams,
});

export const fetchRecruitmentsListSuccess = (
  payload: FetchRecruitmentsListSuccessPayload,
): FetchRecruitmentsListSuccess => ({
  type: recruitmentsListTypes.FETCH_RECRUIMENTS_LIST_SUCCESS,
  payload
});

export const fetchRecruitmentsListFailure = (
  payload: FetchRecruitmentsListFailurePayload
): FetchRecruitmentsListFailure => ({
  type: recruitmentsListTypes.FETCH_RECRUIMENTS_LIST_FAILURE,
  payload
});
