
function ErrorPage() {

  const queryParams = new URLSearchParams(window.location.search);

    return (
      <main id="login">
        <section className="wrapper">
          {queryParams.get('code') != null && queryParams.get('code') == '404' ?
            <><h3 className="ttl">ページが見つかりません。</h3>
            <p>恐れ入りますが、お探しのページは移動または削除された可能性があります。</p></>
            : <></>}
          {queryParams.get('code') != null && queryParams.get('code') == '500' ?
            <><h3 className="ttl">エラーが発生しました。</h3>
            <p>恐れ入りますが、時間をおいて再度アクセスしてください。</p></>
            : <></>}
          {queryParams.get('code') != null && queryParams.get('code') == '401' ?
            <><h3 className="ttl">エラーが発生しました。</h3>
            <p>恐れ入りますが、入力内容にお間違いがないかお確かめの上、再度お試しください。</p></>
            : <></>}
          {queryParams.get('code') == null ? 
            <><h3 className="ttl">エラーが発生しました。</h3></>
            : <></>}
        </section>
      </main>       
    );
  }
  
  export default ErrorPage;