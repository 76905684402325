import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { settingsTypes } from "../../ActionTypes/settingsTypes";
import { fetchSettingsFailure, fetchSettingsSuccess } from "../../actions/settingsActions/settingsActions";


var getSettings = (email:any, name: any) => {
  if(name == 'applicant') {
    return axios.post(API_BASE_ADDRESS + '/email_change/send', {email: email, resource_type: 'Applicant'}, {headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken}}); 
  } else {
    return axios.post(API_BASE_ADDRESS + '/add_recruiter/send', {email: email}, {headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken}}); 
  }
}

function* fetchSettingsSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getSettings, action.email, action.name);
    yield put(
      fetchSettingsSuccess({
        settings: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchSettingsFailure({
        error: e.message
      })
    );
  }
}

function* SettingsSaga() {
  yield takeLatest(settingsTypes.FETCH_SETTINGS_REQUEST, fetchSettingsSaga);
}

export default SettingsSaga;
