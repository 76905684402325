function Guide6() {
    return (
        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">6.各種設定</h3>
          <p>この記事は約3分で読めます。</p>
    
          <p>このページでは応募管理・企業とのやり取りについて説明します。</p>
    
          <p>
            1.パラちゃんねる求人サイトTOP画面より
            <a href="/login/" style={{display: 'inline' ,color: '#00a0e9'}}>ログイン／登録</a>
            をクリックします。
          </p>
          <img src="~/assets/img/guide/first.png" alt=""/>
    
          <p>2.メールアドレス・パスワードを入力し、ログインをクリックします。<br/>
          （パスワードを忘れた場合）パスワードをお忘れの方をクリックしてください。</p>
          <img src="~/assets/img/guide/06/02.png" alt=""/>
    
          <p>メールアドレスを入力して、再設定メール送信をクリックして下さい。</p>
          <img src="~/assets/img/guide/06/03.png" alt=""/>
    
          <p>3.ログイン後、メニューの設定をクリックすると、各種設定の変更ができます。<br/>
          アドレス変更を行う場合は変更するをクリックしてください。<br/>
          新しいメールアドレスを入力するとアドレスの変更通知が自動送信されます。<br/>
          また内定が出た、就職活動を一時休止したい場合、ステータスを変更すると選考中の求人をすべて選考辞退することができます。これは企業側の応募情報にも反映されます。</p>
          <img src="~/assets/img/guide/06/04.png" alt=""/>
    
          <p>以上が各種設定についてとなります。</p>
          <a href="/guide/" className="btn type3">一覧へ戻る</a>
        </section>
      </main>
       
    );
  }
  
  export default Guide6;