import { recruitmentsDetailTypes } from "../../ActionTypes/recruitmentsDetailTypes";
import { FetchRecruitmentsDetailFailure, FetchRecruitmentsDetailFailurePayload, FetchRecruitmentsDetailRequest, FetchRecruitmentsDetailSuccess, FetchRecruitmentsDetailSuccessPayload } from "../../types/type";



export const fetchRecruitmentsDetailRequest = (id:any): FetchRecruitmentsDetailRequest => ({
  type: recruitmentsDetailTypes.FETCH_RECRUIMENTS_DETAIL_REQUEST,
  id,
});

export const fetchRecruitmentsDetailSuccess = (
  payload: FetchRecruitmentsDetailSuccessPayload,
): FetchRecruitmentsDetailSuccess => ({
  type: recruitmentsDetailTypes.FETCH_RECRUIMENTS_DETAIL_SUCCESS,
  payload
});

export const fetchRecruitmentsDetailFailure = (
  payload: FetchRecruitmentsDetailFailurePayload
): FetchRecruitmentsDetailFailure => ({
  type: recruitmentsDetailTypes.FETCH_RECRUIMENTS_DETAIL_FAILURE,
  payload
});
