import { RecruiterPlanPostTypes } from "../../ActionTypes/recruiterPlanPostTypes";
import { MessagesPostActions, MessagesPostState, RecruiterPlanPostActions, RecrutierPlanPostState } from "../../types/type";



const initialState: RecrutierPlanPostState = {
  pending: false,
  recruiterPlan: [],
  error: null,
};

export default (state = initialState, action: RecruiterPlanPostActions,) => {
  switch (action.type) {
    case RecruiterPlanPostTypes.FETCH_RECRUITER_PLAN_POST_REQUEST:
      return {
        ...state,
        pending: false
      };
    case RecruiterPlanPostTypes.FETCH_RECRUITER_PLAN_POST_SUCCESS:
      return {
        ...state,
        pending: true,
        recruiterPlan: action.payload,
        error: null
      };
    case RecruiterPlanPostTypes.FETCH_RECRUITER_PLAN_POST_FAILURE:
      return {
        ...state,
        pending: false,
        recruiterPlan: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
