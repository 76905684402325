import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminNewsNewTypes } from "../../ActionTypes/adminNewsNewTypes";
import { fetchAdminNewsNewFailure, fetchAdminNewsNewSuccess } from "../../actions/adminNewsNewActions/adminNewsNewActions";


const getAdminNewsNew = (data: any) => { 
  const config = {
    headers: { 
      Prefer: 'example=example-1', Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.post(API_BASE_ADDRESS + '/admin/news/'  , data, config); 
}


function* fetchAdminNewsNewSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminNewsNew, action.data);
    yield put(
      fetchAdminNewsNewSuccess({
        news: response.data.news,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminNewsNewFailure({
        error: e.message
      })
    );
  }
}

function* AdminNewsNewSaga() {
  yield takeLatest(adminNewsNewTypes.FETCH_ADMIN_NEWS_NEW_REQUEST, fetchAdminNewsNewSaga);
}
export default AdminNewsNewSaga;