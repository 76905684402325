import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminScoutsDetailTypes } from "../../ActionTypes/adminScoutsDetailTypes";
import { fetchAdminScoutsDetailFailure, fetchAdminScoutsDetailSuccess } from "../../actions/adminScoutsDetailActions/adminScoutsDetailActions";
const getAdminScoutsDetail = (id: any) => { 
  console.log(id);
  const config = {
    headers: { 
      Prefer: 'example=example-1', Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/scouts/' + id, config); 
}


function* fetchAdminScoutsDetailSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminScoutsDetail, action.id);
    yield put(
      fetchAdminScoutsDetailSuccess({
        scout: response.data.scout,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminScoutsDetailFailure({
        error: e.message
      })
    );
  }
}

function* AdminScoutsDetailSaga() {
  yield takeLatest(adminScoutsDetailTypes.FETCH_ADMIN_SCOUTS_DETAIL_REQUEST, fetchAdminScoutsDetailSaga);
}
export default AdminScoutsDetailSaga;