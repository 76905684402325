import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AdminNav from '../../adminTop/AdminNav';
import './../../adminTop/adminTop.css';
import Loader from '../../loader/loader';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchAdminCompanyRecruiterRequest } from '../../../redax/actions/adminComanyRecruiterActions/adminComanyRecruiterActions';

const AdminCompanyRecuiter = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loadScreen, setLoadScreen] = useState(false);
    const [familyName, setFamilyName] = useState('');
    const [familyNameKana, setFamilyNameKana] = useState('');
    const [department, setDepartment] = useState('');
    const [phoneNumber, setPhoneNumber] = useState<string>(''); 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const adminCompanyRecruiter = useSelector((state: RootState) => state.adminCompanyRecruiter);
    const { id } = useParams();
    const [chkRecruiter, setChkRecruiter] = useState(false);



    function validates() {
        let result = '';
        const kanaValidate = new RegExp('^([ァ-ン]|ー)+$');
        const passwordValidate = new RegExp('^(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,32}$');
        let mailformat = /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(familyName == "") {
            result += '採用担当者 担当者名が入力されていません\n'
        } else {
            if(familyName.length >30){
                result += '採用担当者 担当者名が入力されていません\n'
            }
        }
        if(familyNameKana == "") {
            result += '採用担当者 担当者名（カナ）はカタカナで入力してください\n'
        } else {
            if(familyNameKana.length >30){
                result += '採用担当者 担当者名（カナ）が入力されていません\n'
            }
            if(!kanaValidate.test(familyNameKana)){
                result += '採用担当者 担当者名（カナ）が入力されていません\n'
            }

        }
        if(department == ''){
            result += '採用担当者 部署が入力されていません\n'
        } else {
            if(department.length >50){
                result += '採用担当者 部署が入力されていません\n'
            }
        }
        if(phoneNumber == ''){
            result += '採用担当者 電話番号が入力されていません\n'
        } else {
            if(phoneNumber.length < 10 ||  phoneNumber.length > 11 ){
                result += '採用担当者 電話番号が入力されていません\n'
            }
        }
        if(email == ''){
            result += '採用担当者 メールアドレスが正しくありません\n'
        }else {
            if(!mailformat.test(email)){
                result += '採用担当者 メールアドレスが正しくありません\n'
            }
        }
        if(password == ''){
            result += '採用担当者 パスワードは8文字以上32文字以下で大小英数字を含めてください\n'
        } else {
            if(password.length > 32 ||  password.length <= 8){
                result += '採用担当者 パスワードは8文字以上32文字以下で大小英数字を含めてください\n'
            }
            if(!passwordValidate.test(password)){
                result += '採用担当者 パスワードは8文字以上32文字以下で大小英数字を含めてください\n'
            }
        }
        return result
    }
    

    useEffect(() => {
        if(adminCompanyRecruiter.pending && chkRecruiter){
            navigate('/admin/companies/' +id);
        }
    }, [adminCompanyRecruiter,chkRecruiter]); 

    

    function recSubmit (){
        const validatedText = validates(); 
        if (validatedText) {
            alert('未入力項目があります\n\n' + validatedText)
            return
        }
        let recruiterData = {
            family_name: familyName,
            family_name_kana: familyNameKana,
            department: department,
            phone_number: phoneNumber,
            email: email,
            password: password,
          }
          dispatch(fetchAdminCompanyRecruiterRequest(recruiterData,'POST', id,''))
          setChkRecruiter(true)
    }

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setPhoneNumber(value)
        }
    };

  return (
    <main id='adminHome'>
      <AdminNav/>
      {loadScreen ? <Loader></Loader> : <></>}
      <section id='openjob'>
        <div className='adminPageWrapper'>
            <div className="header">
                <h2 className="title">採用担当者 新規作成</h2>
            </div>  
            <div className='body openJobDetail'>
                <div>
                    <div className="textWrapper">
                        <a onClick={() => navigate('/admin/companies/' + id)} className='blueBtn'>＜ -企業詳細に戻る</a>
                    </div>
                </div>
                <div className='step1'>
                   <div>
                    <h1>採用担当者情報</h1>
                    <div>
                        <label htmlFor="">担当者名</label>
                        <input type="text" className='wid300' value={familyName || ''} onChange={(e)=> setFamilyName(e.target.value)} placeholder='(例)山田 太郎' />

                        <label htmlFor="">担当者名(カナ)</label>
                        <input type="text" className='wid300' value={familyNameKana || ''} onChange={(e)=> setFamilyNameKana(e.target.value)} placeholder='(例)ヤマダ タロウ' />

                        <label htmlFor="">部署</label>
                        <input type="text" className='wid300'  value={department || ''} onChange={(e)=> setDepartment(e.target.value)} placeholder='入力してください' />

                        <label htmlFor="">電話番号</label>
                        <input type="tel" className='wid300' maxLength={11} placeholder='入力してください'  value={phoneNumber || ''} onChange={handlePhoneNumberChange} />

                        <label htmlFor="">メールアドレス</label>
                        <input type="text" className='wid300' value={email || ''} onChange={(e)=> setEmail(e.target.value)}  placeholder='入力してください' />

                        <label htmlFor="">パスワード</label>
                        <input type="password" className='wid300' value={password || ''} onChange={(e)=> setPassword(e.target.value)}  placeholder='入力してください' />
                    </div>
                   </div>
                   <button className='createBtn' style={{padding:'10px 40px'}} onClick={()=> recSubmit()}>追加する</button>
                </div>
            </div>
        </div>
        
      </section>
    </main>
  )
}

export default AdminCompanyRecuiter
