import { scoutsRecruitmentTypes } from "../../ActionTypes/scoutsRecruitmentTypes";
import { ScoutsRecruitmentActions, ScoutsRecruitmentState } from "../../types/type";


const initialState: ScoutsRecruitmentState = {
  pending: false,
  scoutsRecruitment: [],
  error: null,
};

export default (state = initialState, action: ScoutsRecruitmentActions,) => {
  switch (action.type) {
    case scoutsRecruitmentTypes.FETCH_SCOUTS_RECRUITMENT_REQUEST:
      return {
        ...state,
        pending: false
      };
    case scoutsRecruitmentTypes.FETCH_SCOUTS_RECRUITMENT_SUCCESS:
      return {
        ...state,
        pending: true,
        scoutsRecruitment: action.payload.scoutsRecruitment,
        error: null
      };
    case scoutsRecruitmentTypes.FETCH_SCOUTS_RECRUITMENT_FAILURE:
      return {
        ...state,
        pending: false,
        scoutsRecruitment: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
