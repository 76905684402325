import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { applicationsTypes } from "../../ActionTypes/applicationsTypes";
import { fetchApplicationsFailure, fetchApplicationsSuccess } from "../../actions/applicationsActions/applicationsActions";

var getApplications = (queryParams:any, types: any, data: any) => {
  if(types == 'get') {
    return axios.get(API_BASE_ADDRESS  + queryParams , {
    headers: { 
      Accept: 'application/json',
      Authorization: localStorage.jobParachannelAuthToken
    }});
  } else {
    return axios.put(API_BASE_ADDRESS  + queryParams , {status_id: data}, {
      headers: { 
        Accept: 'application/json',
        Authorization: localStorage.jobParachannelAuthToken
      }});
  }
}
function* fetchApplicationsSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getApplications, queryParams.queryParams, queryParams.types, queryParams.data);
    yield put(
      fetchApplicationsSuccess({
        applications: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchApplicationsFailure({
        error: e.message
      })
    );
  }
}

function* ApplicationsSaga() {
  yield takeLatest(applicationsTypes.FETCH_APPLICATIONS_REQUEST, fetchApplicationsSaga);
}

export default ApplicationsSaga;
