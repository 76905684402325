import { postcodeTypes } from "../../ActionTypes/postcodeTypes";
import { FetchPostCodeFailure, FetchPostCodeFailurePayload, FetchPostCodeRequest, FetchPostCodeSuccess, FetchPostCodeSuccessPayload } from "../../types/type";

export const fetchPostCodeRequest = (id:any): FetchPostCodeRequest => ({
  type: postcodeTypes.FETCH_POSTCODE_REQUEST,
  id,
});

export const fetchPostCodeSuccess = (
  payload: FetchPostCodeSuccessPayload,
): FetchPostCodeSuccess => ({
  type: postcodeTypes.FETCH_POSTCODE_SUCCESS,
  payload
});

export const fetchPostCodeFailure = (
  payload: FetchPostCodeFailurePayload
): FetchPostCodeFailure => ({
  type: postcodeTypes.FETCH_POSTCODE_FAILURE,
  payload
});
