import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminNewsCompanyDetailTypes } from "../../ActionTypes/adminNewsCompanyDetailTypes";
import { fetchAdminNewsCompanyDetailFailure, fetchAdminNewsCompanyDetailSuccess } from "../../actions/adminNewsCompanyDetailActions/adminNewsCompanyDetailActions";


const getAdminNewsCompanyDetail = (data: any) => { 
  const config = {
    headers: { 
      Prefer: 'example=example-1', Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/news/?company_id=' + data, config); 
}


function* fetchAdminNewsCompanyDetailSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminNewsCompanyDetail, action.data);
    yield put(
      fetchAdminNewsCompanyDetailSuccess({
        newsArray: response.data.newsArray,
        pagenation: response.data.pagenation,
      }) 
    );
  } catch (e:any) {
    yield put(
      fetchAdminNewsCompanyDetailFailure({
        error: e.message
      })
    );
  }
}

function* AdminNewsCompanyDetailSaga() {
  yield takeLatest(adminNewsCompanyDetailTypes.FETCH_ADMIN_NEWS_COMPANY_DETAIL_REQUEST, fetchAdminNewsCompanyDetailSaga);
}
export default AdminNewsCompanyDetailSaga;