import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../../redax/reducers/rootReducer";
import { SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import { fetchCompaniesRequest } from "../../../../redax/actions/companiesActions/companiesActions";
import { fetchMasterInfoRequest } from '../../../../redax/actions/masterInfoActions/masterInfoActions';
import './step.scss';
import { fetchRecruitmentsStepTwoRequest } from "../../../../redax/actions/recruitmentsStepTwoActions/recruitmentsStepTwoActions";
import Loader from "../../../loader/loader";



export default function RecruiterRecruitmentsCreateStep_2(props: any) {
    const { masterInfo  } = useSelector((state: RootState) => state.masterInfo);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginData = useSelector((state: RootState) => state.login);
    const { companies } = useSelector((state: RootState) => state.companies);
    const recruitmentsStepTwoData = useSelector((state: RootState) => state.recruitmentsStepTwo);
    const [resourceId, setResourceId] = useState(null);
    const [familyName, setFamylyName] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [schedules, setschedules] = useState<any[]>([]);
    const [region, setregions] = useState<any[]>([]);
    const [prefectures, setprefectures] = useState<any[]>([]);
    const [handicaptypes, sethandicaptypes] = useState<any[]>([]);
    const [appealitems, setappealitems] = useState<any[]>([]);
    const [appealitems1, setappealitems1] = useState<any>(null);
    const [appealitems2, setappealitems2] = useState<any>(null);
    const [appealitems3, setappealitems3] = useState<any>(null);
    const [error, setError] = useState(false);
    const [recruitmentscheduleid, setrecruitmentscheduleid] = useState(null);
    const [appeal, setappeal] = useState('');
    const [errorscheduleid, setErrorscheduleid] = useState(false);
    const [graduateError, setGraduateError] = useState(false);
    const [regionError, setRegionError] = useState(false);
    const [handicapTypesError, setHandicapTypesError] = useState(false);
    const [appealitems1Error, setAppealitems1Error] = useState(false);
    const [appealitems2Error, setAppealitems2Error] = useState(false);
    const [appealitems3Error, setAppealitems3Error] = useState(false);
    const [MySelectRegions, setMySelectRegions] = useState<any[]>([]);
    // const [state, setState] = useState<any[]>([]);
    const [mySelectedPrefectures, setMySelectedPrefectures] = useState<any[]>([]); 
    const [selectHandicapTypes, setSelectHandicapTypes] = useState([]); 
    const [selectHandicapTypesIds, setSelectHandicapTypesIds] = useState<any[]>([]); 
    const [new_graduate, setNew_graduate] = useState(null); 
    const [business_flow, setBusiness_flow] = useState(null);
    const [loadScreen, setLoadScreen] = useState(true);
    const [isSelected, setIsSelected] = useState(false);
    const [isReloadStepOne, setIsReloadStepOne] = useState<any>(false);
    const location = useLocation();
    const data = location.state;
    let recruitmentId: any = null;
    if(data != null || data != undefined) {
        recruitmentId = data.params.recruitment_id;
    }
    const dto = data;
    const apiData = data;
    let notEqualList: any[] = [];
    
    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
        //   dispatch(fetchLoginRequest('', '',''))
          window.scrollTo(0, 0);
        } else (
            navigate('/recruiter/login/')
        )
      }, [dispatch]);

    useEffect(() => {        
        if (loginData.pending) {
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            setCompanyId(loginData.login.company.id);
            dispatch(fetchCompaniesRequest(loginData.login.company.id, 'get', null));
            let param = 'recruitments=true&regions=true&handicap_types=true&ideal_candidates_unique=true&consideration_conditions=true&appeal_items=true';
            dispatch(fetchMasterInfoRequest(param));
        }
      }, [loginData.pending]);

    function logout() {
        if (localStorage.entryFlag) {
            localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
            localStorage.removeItem('jobParachannelAuthToken')
            navigate('/');
            window.location.reload();
        }
    }
    
    useEffect(() => {
        if(masterInfo !== undefined) {
            setschedules(masterInfo.schedules);
            setregions(masterInfo.regions);
            setprefectures(masterInfo.prefectures);
            sethandicaptypes(masterInfo.handicap_types);
            setappealitems(masterInfo.appeal_items);
            setLoadScreen(false);
        }  
    },[masterInfo]);

    function recruitmentschedule(e: any) {
        if(e === '') {
            setErrorscheduleid(true);
        } else {
            setErrorscheduleid(false);
        }
        setrecruitmentscheduleid(e);
    }

    function updateRegion(value:any, index:any ) {
        let tmp: any[] = [];
        if(MySelectRegions.length > 0) {
            for(let i = 0; i < MySelectRegions.length; i++) {
                tmp.push(MySelectRegions[i]);
            }
        }
        if(value) {
            for(let i = 0; i < prefectures.length; i++) {
                if(prefectures[i].region_id-1 === index) {
                    prefectures[i].checked = true;
                    tmp.push(prefectures[i]);
                }
            }
            let newArr = tmp.map(n => n.id);
            setMySelectedPrefectures(newArr);
            setMySelectRegions(tmp);
        } else {
            for(let i = 0; i < prefectures.length; i++) {
                if(prefectures[i].region_id-1 === index) {
                    for(let j = 0; j < tmp.length; j++) {
                        if(tmp[j] == prefectures[i]) {
                            tmp.splice(j, 1);
                        }
                    }
                }
            }
            let newArr = tmp.map(n => n.id);
            setMySelectedPrefectures(newArr);
            setMySelectRegions(tmp);
        }
        setRegionError(false);
    };

      const updateValue = (value:any, data: any, index: any) => {
        let c = [];
        for(let i = 0; i < MySelectRegions.length; i++) {
            c.push(MySelectRegions[i]);
        }
        if (value) {
            c[index].checked = true;
            setMySelectRegions(c);
            setMySelectedPrefectures(prevSelected => [...prevSelected, data.id]);
            //setMySelectedPrefectures(data);
        } else {          
          c[index].checked = false;
          setMySelectRegions(c);
          setMySelectedPrefectures(prevSelected =>
            prevSelected.filter(n =>n !== data.id)
          );
        } 
      };

    function handler_appeal_item(e: any) {  
        if("appeal_item_1" === e.target.name) {
            setappealitems1(e.target.value);
            setAppealitems1Error(false);
        }
        if("appeal_item_2" === e.target.name) {
            setappealitems2(e.target.value);
            setAppealitems2Error(false);
        }
        if("appeal_item_3" === e.target.name) {
            setappealitems3(e.target.value);
            setAppealitems3Error(false);
        }   
    }

    function checkList1(arr : any[]) {
        let newArr: any[] = [];        
        let notEqualId1 : any[] = [];
        let notEqualId2 : any[] = [];
        let notEqualId3 : any[] = [];
        newArr = arr;
        if(appealitems1 !== null) {
            newArr = arr.filter(n => n.id !== appealitems1);
            notEqualId1 = arr.filter(n => n.id == parseInt(appealitems1));
            if(notEqualId1 !== null) {
                notEqualList.push({id: notEqualId1[0].id, name: notEqualId1[0].name, desc: 'appealitems1', appeal: 'appeal1'});
                notEqualList.push({id: notEqualId1[0].id, name: notEqualId1[0].name, desc: 'appealitems2', appeal: 'appeal1'});
                notEqualList.push({id: notEqualId1[0].id, name: notEqualId1[0].name, desc: 'appealitems3', appeal: 'appeal1'});
            }
        }
        if(appealitems2 !== null) {
            newArr = arr.filter(n => n.id !== appealitems2);
            notEqualId2 = arr.filter(n => n.id == parseInt(appealitems2));
            if(notEqualId2 !== null) {
                notEqualList.push({id: notEqualId2[0].id, name: notEqualId2[0].name, desc: 'appealitems1', appeal: 'appeal2'});
                notEqualList.push({id: notEqualId2[0].id, name: notEqualId2[0].name, desc: 'appealitems2', appeal: 'appeal2'});
                notEqualList.push({id: notEqualId2[0].id, name: notEqualId2[0].name, desc: 'appealitems3', appeal: 'appeal2'});
            }
        }
        if(appealitems3 !== null) {
            newArr = arr.filter(n => n.id !== appealitems3);
            notEqualId3 = arr.filter(n => n.id == parseInt(appealitems3));
            if(notEqualId3 !== null) {
                notEqualList.push({id: notEqualId3[0].id, name: notEqualId3[0].name, desc: 'appealitems1', appeal: 'appeal3'});
                notEqualList.push({id: notEqualId3[0].id, name: notEqualId3[0].name, desc: 'appealitems2', appeal: 'appeal3'});
                notEqualList.push({id: notEqualId3[0].id, name: notEqualId3[0].name, desc: 'appealitems3', appeal: 'appeal3'});
            }  
        }
        for(let i = 0; i < arr.length; i++) {
            for(let j = 0; j < notEqualList.length; j++) { 
                if(notEqualList[j].desc == 'appealitems1'){
                    const equal = arr.filter(n => n.id == parseInt(notEqualList[j].id));
                    if(equal != null) {
                        var element = document.getElementById(`appeal_item_1_`+notEqualList[j].id);
                        element?.classList.add("display_none");
                    }                     
                }
                const objWithIdIndex = arr.filter(n => n.id !== notEqualList[j].id);
                for(let a = 0; a < objWithIdIndex.length; a++) { 
                    if(objWithIdIndex[a].id !== parseInt(appealitems1) && objWithIdIndex[a].id !== parseInt(appealitems2) && objWithIdIndex[a].id !== parseInt(appealitems3)) {                        
                        if(notEqualList[j].appeal === 'appeal1') {
                            if(objWithIdIndex[a].id !== notEqualList[j].id) {
                                var element = document.getElementById(`appeal_item_1_`+objWithIdIndex[a].id);
                                element?.classList.remove("display_none");
                            }
                        }
                        if(notEqualList[j].appeal === 'appeal2') {
                            if(objWithIdIndex[a].id !== notEqualList[j].id) {
                                var element = document.getElementById(`appeal_item_2_`+objWithIdIndex[a].id);
                                element?.classList.remove("display_none");
                            }
                        }
                        if(notEqualList[j].appeal === 'appeal3') {
                            if(objWithIdIndex[a].id !== notEqualList[j].id) {
                                var element = document.getElementById(`appeal_item_3_`+objWithIdIndex[a].id);
                                element?.classList.remove("display_none");
                            }
                        }
                    }
                }
                if(notEqualList[j].desc == 'appealitems2'){
                    if(arr[i].id == parseInt(notEqualList[j].id)) {
                        var element = document.getElementById(`appeal_item_2_`+ notEqualList[j].id);
                        element?.classList.add("display_none");
                    } 
                }
                if(notEqualList[j].desc == 'appealitems3'){
                    if(arr[i].id == parseInt(notEqualList[j].id)) {
                        var element = document.getElementById(`appeal_item_3_`+ notEqualList[j].id);
                        element?.classList.add("display_none");
                    } 
                }
            }   
        }
        return newArr;
    }

    function updatePurpose(e: any, index: any) {
        let c = [];
        for(let i = 0; i < selectHandicapTypesIds.length; i++) {
            c.push(selectHandicapTypesIds[i]);
        }
        let chk = false;
        for(let i = 0; i < c.length; i++) {
            if(c[i] == e.target.value) {
                chk = true;
                index = i;
            }
        }
        if(!chk) {
            c.push(e.target.value);
        } else {
            c.splice(index, 1);
        }
        
        setSelectHandicapTypesIds(c);
        setHandicapTypesError(false);
        if(c.length === 0) {
            setHandicapTypesError(true);
        }
    }

    function change_graduate(e: any){
        setNew_graduate(e);
        setGraduateError(false);
    }

    function validate() {
        let result = false;
        if(new_graduate == null) {
            setGraduateError(true);
            result = true; 
        }
        if(recruitmentscheduleid == null) {
            setErrorscheduleid(true); 
            result = true; 
        }
        if(MySelectRegions.length == 0) {
            setRegionError(true); 
            result = true; 
        }
        if(selectHandicapTypesIds.length == 0) {
            setHandicapTypesError(true); 
            result = true; 
        }
        if(appealitems1 == null) {
            setAppealitems1Error(true); 
            result = true; 
        }
        if(appealitems2 == null) {
            setAppealitems2Error(true); 
            result = true;  
        }
        if(appealitems3 == null) {
            setAppealitems3Error(true); 
            result = true;  
        }
        return result
    }

    function onClickMove() {
        if(!validate()) {
            apiData.params.company_id = companyId
            apiData.params.company_recruiter_id = resourceId
            apiData.params.new_graduate = new_graduate
            apiData.params.target.schedule_id = recruitmentscheduleid
            apiData.params.prefectures = mySelectedPrefectures
            apiData.params.handicap_types = selectHandicapTypesIds
            apiData.params.business_flow = business_flow
            apiData.params.consideration_conditions = []
            apiData.params.appeal_item = {
                appeal_item_id_1: Number(appealitems1),
                appeal_item_id_2: Number(appealitems2),
                appeal_item_id_3: Number(appealitems3)
            }
            setLoadScreen(true);
            dispatch(fetchRecruitmentsStepTwoRequest( recruitmentId, apiData.params));
        }
    }

    useEffect(() => {
        if(recruitmentsStepTwoData.pending) {
            setLoadScreen(false);
            if(recruitmentsStepTwoData !== null) {
                // navigate('/recruiter/recruitments/' + recruitmentId);
                // window.location.reload();
                window.location.href = '/recruiter/recruitments/' + recruitmentId;
            } else {
                window.alert('サーバーエラー');
            }
        }
    }, [recruitmentsStepTwoData]);
      
    useEffect(() => {    
        window.onpopstate = function() {
          window.location.href = '/recruiter/recruitments/create/step_1';
        }
    }, []);

    return (
        <main id="company">
          <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
          {loadScreen ? <Loader /> : <></>}
          <section>
            <ul className="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
                <li><a href="#">求人管理</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">求人管理 ＞ 新規</h2>
                <ul>
                    <li><a href="#" onClick={logout} ><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
              </ul>
            </div>
            <div className="initial_registration">
                <div className="step_box">
                <ul>
                    <li>基本情報</li>
                    <li className="active">採用要件・アピール項目</li>
                </ul>
                
                </div>
                <div className="cont_box">
                    <p className="read">以下項目は、<br className="sp_only"/>求職者とのマッチングに使用するデータとなります。<br className="sp_only"/>求職者には表示されません。</p>
                    <form >
                        <h3>採用種別を教えてください</h3>
                        <div className={graduateError ? 'error radio' : 'radio'}>
                            <label><input type="radio" name="new_graduate" checked={new_graduate == "1"} value="1" onChange={(e)=>change_graduate(e.target.value)}/>新卒</label>
                            <label><input type="radio" name="new_graduate" checked={new_graduate == "0"} value="0" onChange={(e)=>change_graduate(e.target.value)}/>中途</label>
                            {graduateError ?
                                <div className="error_box">
                                    <p className="error_msg">選択必須項目です</p>
                                </div>
                            : <></>}
                        </div>
                        <h3>採用スケジュールを教えてください</h3>
                        <select name="schedule"  value={recruitmentscheduleid || ''} onChange={(e)=>recruitmentschedule(e.target.value)} className={errorscheduleid ? 'error': ''}>
                            <option value='' disabled selected  style={{ display:"none" }} >選択してください</option>
                            {schedules && schedules.map((data:any , index:any) => (
                                <option  value={data.id} key={index}>
                                    {data.name}
                                </option>
                            ))}
                            <div className="error_box">
                            {errorscheduleid ?
                                <p className='error_msg'>選択必須項目です</p>
                            : <></>}
                            </div>
                        </select>
                        <h3>地域を教えてください</h3>
                        <div className={regionError ? 'error checkbox_list area' : 'checkbox_list area'}>
                            {region && region.map((data:any , index:any) => (
                                <label key={index}>
                                    <input
                                        value={data.id}
                                        type="checkbox"
                                        // checked={MySelectRegions[index]}
                                        onChange={e => {updateRegion(e.target.checked, index);}}
                                    />
                                    {data.name}
                                </label>
                            ))}
                            {MySelectRegions && MySelectRegions.length > 0 ?
                                <div className="checkbox_list area area_child _flex" >
                                    {MySelectRegions.map((data: any, index:any) => (
                                        <label key={data.id}>
                                            <input type="checkbox" value={data.id} checked={data.checked} onChange={e => updateValue(e.target.checked, data, index)}/> {data.name}
                                        </label>
                                    ))}
                                </div>
                            : <></>}
                        </div>
                        
                        {regionError ?
                            <div className="error_box">
                                <p className="error_msg">選択必須項目です</p>
                            </div>
                        : <></>}

                        <h3>障がい区分を教えてください</h3>
                        <div className={handicapTypesError ? 'error checkbox_list short' : 'checkbox_list short'}>
                            {handicaptypes && handicaptypes.map((data:any , index:any ) => (
                                <label key={index}>
                                    <input  type="checkbox" name="purpose" onChange={(e) => updatePurpose(e, index)} value={data.id}></input>
                                    {data.name}
                                </label>
                            ))}
                            {handicapTypesError ?
                                <div className="error_box">
                                    <p className="error_msg">選択必須項目です</p>
                                </div>
                            : <></>}
                        </div>

                        <h3>エントリー時に聞きたい内容を３つ教えてください</h3>
                        <select name="appeal_item_1" value={appealitems1 || ''} onChange={(e) => handler_appeal_item(e)} className={appealitems1Error ? 'error' : ''}>
                            <option value='' disabled selected  style={{ display:"none" }} >選択してください</option>
                            {appealitems && checkList1(appealitems).map((data:any) => (
                                  <option value={data.id} id={`appeal_item_1_`+data.id} key={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                        {appealitems1Error ?
                                <div className="error_box">
                                    <p className="error_msg">選択必須項目です</p>
                                </div>
                        : <></>}
                        <select name="appeal_item_2" value={appealitems2 || ''} onChange={(e) => handler_appeal_item(e)} className={appealitems2Error ? 'error' : ''}>
                            <option value='' disabled selected  style={{ display:"none" }} >選択してください</option>
                            {appealitems && checkList1(appealitems).map((data:any) => (
                                <option value={data.id} id={`appeal_item_2_`+data.id} key={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                        {appealitems2Error ?
                                <div className="error_box">
                                    <p className="error_msg">選択必須項目です</p>
                                </div>
                            : <></>}
                        <select name="appeal_item_3" value={appealitems3 || ''} onChange={(e) => handler_appeal_item(e)} className={appealitems3Error ? 'error' : ''}>
                            <option value='' disabled selected  style={{ display:"none" }} >選択してください</option>
                            {appealitems && checkList1(appealitems).map((data:any) => (
                                <option value={data.id} id={`appeal_item_3_`+data.id} key={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                        {appealitems3Error ?
                                <div className="error_box">
                                    <p className="error_msg">選択必須項目です</p>
                                </div>
                            : <></>}
                        <button type="button" onClick={onClickMove} className="btn type3 orange">完了</button>
                    </form>
                </div>
            </div>
          </section>
        </main>
    )
}
