import { useState, useEffect } from 'react';
import { API_BASE_ADDRESS } from '../../../redax/API';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { RootState } from '../../../redax/reducers/rootReducer';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import dayjs from 'dayjs';
import './init.scss';
import { fetchRegionsRequest } from '../../../redax/actions/regionsActions/regionsActions';
import { fetchChangeUpdateApplicantsRequest } from '../../../redax/actions/changeUpdateApplicantsActions/changeUpdateApplicantsActions';
import { useNavigate } from 'react-router-dom';
import Loader from '../../loader/loader';

function Init() {
    const [familyName, setFamylyName] = useState('');
    const [familyNameForm, setFamylyNameForm] = useState<any>('');    
    const [firstName, setFirstName] = useState<any>('');
    const [familyNameKana, setFamilyNameKana] = useState<any>('');
    const [firstNameKana, setFirstNameKana] = useState<any>('');
    const [appBirth, setappBirth] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [years, setYears] = useState<any[]>([]);
    const [months, setMonths] = useState<any[]>([]); 
    const [days, setDays] = useState<any[]>([]);    
    const [handicaps, setHandicaps] = useState<any[]>([]); 
    const [master, setMaster] = useState<any[]>([]); 
    const [handicap_types, setHandicap_types] = useState<any[]>([]); 
    const [handicapType, setHandicapType] = useState();
    const [disability_certificates, setDisability_certificates] = useState<any[]>([]);     
    const [disability_certificate_statuses, setDisability_certificate_statuses] = useState<any[]>([]); 
    const [prefectures, setPrefectures] = useState<any[]>([]); 
    const [search_policies, setSearch_policies] = useState<any[]>([]);      
    const [filterCategoriesOne, setFilterCategoriesOne] = useState<any[]>([]);     
    const [filterCategoriesTwo, setFilterCategoriesTwo] = useState<any[]>([]);     
    const [applicants, setApplicants] = useState<any[]>([]);  
    const [year, setYear] = useState();
    const [month, setMonth] = useState();
    const [day, setDay] = useState();
    const [typeId, setTypeId] = useState(0);
    const [step, setStep] = useState(1);
    const [error, setError] = useState(false);      
    const [new_graduate, setNew_graduate] = useState(false);
    const [termsOfCondition, setTermsOfCondition] = useState<any>(false);
    const [radioOne, setRadioOne] = useState(0);    
    const [radioTwo, setRadioTwo] = useState(0);    
    const [radioThree, setRadioThree] = useState(0);
    const [errorFamilyName, setErrorFamilyName] = useState(false);
    const [errorFamilyNameKana, setErrorFamilyNameKana] = useState(false);
    const [errorFamilyNameMaxlength, setErrorFamilyNameMaxLength] = useState(false);
    const [errorFamilyNameKanaMaxlength, setErrorFamilyNameKanaMaxLength] = useState(false);
    const [errorFamilyNameKanaCheck, setErrorFamilyNameKanaCheck] = useState(false);
    const [errorFirstName, setErrorFirstName] = useState(false);
    const [errorFirstNameKana, setErrorFirstNameKana] = useState(false);
    const [errorFirstNameMaxlength, setErrorFirstNameMaxLength] = useState(false);
    const [errorFirstNameKanaMaxlength, setErrorFirstNameKanaMaxLength] = useState(false);
    const [errorFirstNameKanaCheck, setErrorFirstNameKanaCheck] = useState(false);
    const [errorBirthYear, setBirthYear] = useState(false);
    const [errorBirthMonth, setBirthMonth] = useState(false);
    const [errorBirthDay, setBirthDay] = useState(false);
    const [nextStep, setNextStep] = useState(false);
    const [nextStepTwo, setNextStepTwo] = useState(false);
    const [nextStepThree, setNextStepThree] = useState(false);
    const [selectHandiType, setSelectHandiType] = useState(null);
    const [selectHand, setselectHand] = useState('');    
    const [disabilityCertificateId, setdisabilityCertificateId] = useState(null);
    const [disability_certificate_status_id, setDisabilityCertificateStatusId] = useState<any>(null);
    const [errorHandiType, setErrorHandiType] = useState(false);
    const [errorHand, setErrorHand] = useState(false);
    const [selectWork, setSelectWork] = useState(null);
    const [selectRegion, setSelectRegion] = useState(null);
    const [errorWork, setErrorWork] = useState(false);
    const [errorRegion, setErrorRegion] = useState(false);    
    const [errorTerms, setErrorTerms] = useState(false);
    const dispatch = useDispatch();
    let totalStep = [1, 2, 3];
    let stepAry = ['','プロフィール','障がいについて','希望条件'];
    let monthly = 13;        
    let monthEndDay: any; 
    let MaxFifty = 50; 
    let MaxHundred = 100; 
    const [isOpenDisabilityCertificate, setisOpenDisabilityCertificate] = useState(true);
    let terms = false;
    const loginData = useSelector((state: RootState) => state.login);
    const { regions  } = useSelector((state: RootState) => state.regions);
    const changeupdateapplicants = useSelector((state: RootState) => state.changeupdateapplicants);
    const navigate = useNavigate();
    const [loadScreen, setLoadScreen] = useState(true);
    const [edited, setEdited] = useState(true);
    useEffect(() => {
        if (!localStorage.getItem('jobParachannelAuthToken')) {
          navigate('/login');
        } 
      }, [navigate, dispatch]);
    useEffect(() => {
        dispatch(fetchRegionsRequest());
    }, [dispatch]);
    
    useEffect(() => {
        if(changeupdateapplicants.pending) {
            // navigate('/mypage/profile/');
            // window.location.reload();
            window.location.href = '/mypage/profile/';
        } else if(changeupdateapplicants.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [changeupdateapplicants]);

    useEffect(() => {
        if(regions !== undefined) {
            setMaster(regions);            
            setHandicaps(regions.handicaps);
            setHandicap_types(regions.handicap_types);
            setDisability_certificates(regions.disability_certificates);   
            setDisability_certificate_statuses(regions.disability_certificate_statuses);           
            setPrefectures(regions.prefectures);
            setSearch_policies(regions.search_policies);
        }  
    },[regions]);

    useEffect(() => {
        // dispatch(fetchLoginRequest('', '', ''));
        if(!edited) return 
        
        window.addEventListener("beforeunload", confirmSave);

        return () => window.removeEventListener("beforeunload", confirmSave);
    }, [edited]);

    function confirmSave (event: any) {
        event.preventDefault();
        if (edited) {
            event.returnValue = "編集中のデータは保存されていませんが、移動しますか？"
        }
    }
  
    useEffect(() => {
        if (loginData.pending) {
          setResourceId(loginData.login.resource.id);
          setFamylyName(loginData.login.resource.family_name);
          setFamylyNameForm(loginData.login.resource.family_name);
          setFirstName(loginData.login.resource.first_name);
          setFamilyNameKana(loginData.login.resource.family_name_kana);
          setFirstNameKana(loginData.login.resource.first_name_kana);
          setappBirth(loginData.login.resource.birthday);
          if(loginData.login.resource.birthday != null) {
            setYear(loginData.login.resource.birthday.split('-')[0]);
            setMonth(loginData.login.resource.birthday.split('-')[1]);
            setDay(loginData.login.resource.birthday.split('-')[2]);
          }
          getYears();
          setLoadScreen(false);
        }
    }, [dispatch, loginData.pending]);

    const getYears = () => {
        const goBackYears = 62;
        const currentYear = dayjs().year() - 17;
        let startYear = currentYear - goBackYears;
        const yearsCal = [];
        yearsCal.push('--');
        for (let i = 1; i <= goBackYears; i++) {
            yearsCal.push(startYear);
            startYear++;
        }
        setYears(yearsCal);
        const monthAll = [];
        monthAll.push('--');
        for (let i = 1; i < monthly; i++) {
            monthAll.push(i);
        }
        setMonths(monthAll);
    };
    
    if(year !== undefined && month !== undefined) { 
        let chooseDay = new Date(); 
        chooseDay.setFullYear(year, month, 0);
        let d = chooseDay.toISOString().substring(8, 10);
        monthEndDay = d;
    }
    
    useEffect(() => {
        if(monthEndDay !== null) {
            monthEndDay = parseInt(monthEndDay) + 1;  
            const monthDays = [];
            monthDays.push('--');     
            for (let i = 1; i < parseInt(monthEndDay); i++) {
                monthDays.push(i);
            }
            setDays(monthDays);
        }
    }, [monthEndDay]);

    const onChangeInput = (event: any) => {
        setEdited(true);
        const {name, value} = event.target;
        if(name === 'familyname') {
            setFamylyNameForm(value);
        }
        if(name === 'firstname') {
            setFirstName(value);
        }
        if(name === 'familynamekana') {
            setFamilyNameKana(value);
        }
        if(name === 'firstnamekana') {
            setFirstNameKana(value);
        }
        if(name === 'birthday_year') {
            if(value == '' || value == '--') {                
              setDay(value);
            } else {
                setYear(value);
            }
        }
        if(name === 'birthday_month') {
            if(value === '' || value === '--') {                
                setDay(value);
            } else {
                setMonth(value);
            }
        }
        if(name === 'birthday_day') {
            if(value === '' || value === '--') {                
                setDay(value);
            } else {
                setDay(value);
            }
        }
        dataValidation(name, value);
    }

    function dataValidation (name: any, value: any) {
        let err = false;
        const kanaValidate = new RegExp('^([ァ-ン]|ー)+$');
        if(name === 'familyname') {
          if(value === '') {
            setError(true);
            setErrorFamilyName(true);
            err = true;
          } else {
            setError(false);
            setErrorFamilyName(false);
            if(value.length > 50) {
                setErrorFamilyNameMaxLength(true);
                err = true;
            } else {
                setErrorFamilyNameMaxLength(false);
            }
          }
        }
        if (name === 'familynamekana') {
          if(value === '') {
            setErrorFamilyNameKana(true);
            setErrorFamilyNameKanaCheck(false)
            setErrorFamilyNameKanaMaxLength(false)
            err = true;
          } else {
            setErrorFamilyNameKana(false);
            if(value.length > 100){
              setErrorFamilyNameKanaMaxLength(true)
              err = true;
            } else {
                setErrorFamilyNameKanaMaxLength(false)
            }
            if(kanaValidate.test(value)) {
                setErrorFamilyNameKanaCheck(false)
            } else {
                err = true;
                setErrorFamilyNameKanaCheck(true)
            }
          }
        }
        if(name === 'firstname') {
            if(value === '') {
                setError(true);
                setErrorFirstName(true);
                err = true;
            } else {
                setError(false);
                setErrorFirstName(false);
                if(value.length > 50) {
                    setErrorFirstNameMaxLength(true);
                    err = true;
                } else {
                setErrorFirstNameMaxLength(false);
                }
            }
        }

        if (name === 'firstnamekana') {
            if(value === '') {
                setErrorFirstNameKana(true);
                setErrorFirstNameKanaCheck(false)
                setErrorFirstNameKanaMaxLength(false)
                err = true;
            } else {
                setErrorFirstNameKana(false);
                if(value.length > 100){
                    setErrorFirstNameKanaMaxLength(true)
                    err = true;
                } else {
                    setErrorFirstNameKanaMaxLength(false)
                }
                if(kanaValidate.test(value)) {
                    setErrorFirstNameKanaCheck(false)
                } else {
                    err = true;
                    setErrorFirstNameKanaCheck(true)
                }
            }
        }

        if(name === 'birthday_year') {
            if(value === '' || value === '--') {
                setBirthYear(true);
                err = true;
            } else {                
                setBirthYear(false);
            }
        }

        if(name === 'birthday_month') {
            if(value === '' || value === '--') {
                setBirthMonth(true);
                err = true;
            } else {                
                setBirthMonth(false);
            }
            setBirthDay(true);
        }

        if(name === 'birthday_day') {
            if(value === '' || value === '--') {
                setBirthDay(true);
                err = true;
            } else {                
                setBirthDay(false);
            }
        }
        // if(!err) {
        //     setNextStep(false);
        // } else {
        //     setNextStep(true);
        // }
        return err;
    }

    const onChangeStepTwo = (event: any) => {
        setEdited(true);
        const {name, value} = event.target;
        if(name === 'handicap_type') {
            if(value !== 0) {                
                setErrorHandiType(false);
                setErrorHand(true);
                setSelectHandiType(value);
                setselectHand('');
                setHandicapType(value);
                const filterHandCaps = [];
                const filterDisCertificates = [];
                for (let i = 0; i < handicaps.length; i++) {
                    if(value == handicaps[i].handicap_type_id){
                        filterHandCaps.push({id: handicaps[i].id, handicap_type_id: handicaps[i].handicap_type_id, name: handicaps[i].name,});
                    }
                }
                for(let j = 0; j < disability_certificates.length; j++){
                    if(value == disability_certificates[j].handicap_type_id){
                        filterDisCertificates.push({id: disability_certificates[j].id, handicap_type_id: disability_certificates[j].handicap_type_id, name: disability_certificates[j].name, class_name: disability_certificates[j].class_name});
                    }
                }
                setFilterCategoriesOne(filterHandCaps);
                setFilterCategoriesTwo(filterDisCertificates);
            } else {
                setErrorHandiType(true);
            }
            if (value == 5) {
                setDisabilityCertificateStatusId(1);
                setisOpenDisabilityCertificate(false);
            } else {
                setisOpenDisabilityCertificate(true);
            }
        }
        if(name === 'disability_certificate') {
            setDisabilityCertificateStatusId(value);
        }
        if(name === 'handicap') {
            setselectHand(value);
            setErrorHand(false);
        }
        if(name === 'disability_certificate_id') {
            setdisabilityCertificateId(value);
        }
        SelectValidation(name, value);
    }

    function SelectValidation (name: any, value: any) {
        let err = false;
        if(name === 'handicap_type') {
          if(parseInt(value) <= 0) {
            setErrorHandiType(true);
            err = true;
          } 
        }
        if (name === 'handicap') {
          if(parseInt(value) <= 0) {            
            setErrorHand(true);
            err = true;
          } 
        }
        if(name === 'disability_certificate') {
            if(parseInt(value) <= 0) {
              err = true;
            } 
          }
        // if(err) {
        //     setNextStepTwo(false);
        // } else {
        //     setNextStepTwo(true);
        // }
        return err;
    }

    const onChangeStepThree = (event: any) => {
        setEdited(true);
        const {name, value} = event.target;
        if(name === 'new_graduate') {
            if(value == 'true') {
                setNew_graduate(true);
            } else {
                setNew_graduate(false);
            }
        }
        if(name === 'work_styles') {
            setSelectWork(value);
            if(selectRegion == null) {
                setErrorRegion(true);
            } else {
                setErrorRegion(false);
            }
        }
        if(name === 'regions') {
            setSelectRegion(value);
            if(selectWork == null) {
                setErrorWork(true);
            } else {
                setErrorWork(false);
            }
        }
        if(name === 'agree') {
            if(event.target.checked) {
                setTermsOfCondition(true);
            } else {
                setTermsOfCondition(false);
            }            
        }
        if(name === 'agree') {
            ValidationThree(name, event.target.checked);
        } else {
            ValidationThree(name, value);
        }
    }

    function ValidationThree (name: any, value: any) {
        let err = false;
        if(name === 'work_styles') {
          if(parseInt(value) <= 0) {
            setErrorWork(true);
            err = true;
          } else {
            setErrorWork(false);
          }
        }
        if (name === 'regions') {
          if(parseInt(value) <= 0) {            
            setErrorRegion(true);
            err = true;
          } else {
            setErrorRegion(false);
          }
        }
        if (name === 'agree') {
            if(!value) {
                setErrorTerms(true);
                err = true;
            } else {
                setErrorTerms(false);
            }
        }
        if(err) {
            setNextStepThree(false);
        } else {
            if(selectRegion !== null && selectWork !== null && value) {
                setNextStepThree(true);
            } else {
                setNextStepThree(false);
            }            
        }
        return err;
    }

    useEffect(() => {
        if(familyName !== '' &&  firstName !== '' &&  familyNameKana !== '' &&  firstNameKana !== '' && year !== undefined && month !== undefined && day !== undefined
         && day !== '--') {
            setNextStep(true);
        } else {
            setNextStep(false);
        }
    }, [familyName, firstName, familyNameKana, firstNameKana, year, month, day]);

    useEffect(() => {
        if(selectHandiType !== null &&  selectHand !== '' &&  disability_certificate_status_id !== null) {
            if(disability_certificate_status_id != 2) {
                setNextStepTwo(true);
            } else {
                if(disabilityCertificateId != null) {
                    setNextStepTwo(true);
                } else {
                    setNextStepTwo(false);
                }
            }
        } else {
            setNextStepTwo(false);
        }
    }, [selectHandiType, selectHand, disability_certificate_status_id, disabilityCertificateId]);

    useEffect(() => {
        if(new_graduate !== undefined &&  selectWork !== null &&  selectRegion !== null && termsOfCondition) {
            setNextStepThree(true);
            applyData();
        }
    }, [new_graduate, selectWork, selectRegion, termsOfCondition]);
    

    const moveStep = (e: any, value: any) => {
        setEdited(false);
        let tmp = step;
        if(errorBirthDay || errorBirthMonth || errorBirthYear || errorFamilyName || errorFamilyNameKana
            || errorFamilyNameKanaCheck || errorFamilyNameKanaMaxlength || errorFamilyNameMaxlength || errorFirstName
            || errorFirstNameKana || errorFirstNameKanaCheck || errorFirstNameKanaMaxlength || errorFirstNameMaxlength || day == '--') {
            return;
        }
        if(step === 2 && value === 1 && (errorHandiType || errorHand || disability_certificate_status_id == null)) {
            return;
        }
        if(value === 1){
            tmp = tmp + 1;
            setStep(tmp);
            window.scrollTo(0, 0);
        } else {
            tmp = tmp - 1;
            setStep(tmp);
            window.scrollTo(0, 0);
        }
        e.preventDefault();
    }
    
    const applyData = () => {
        const applicant = [];
        const education = [];
        const hnadicap_qual = [];
        const preferred = [];
        let job_career_count_id: any;
        let myBirthday = ''
        myBirthday += year + '-'
        myBirthday += month + '-'
        myBirthday += day;
        if (new_graduate) {
            job_career_count_id = 1;
        }
        education.push({ new_graduate: new_graduate });
        hnadicap_qual.push({ disability_certificate_status_id: disability_certificate_status_id, handicap_id: selectHand, disability_certificate_id: disabilityCertificateId,});
        preferred.push({ search_policy_id: selectWork, prefecture_id_1: selectRegion});
        applicant.push({ family_name: familyNameForm, first_name: firstName, family_name_kana: familyNameKana, first_name_kana: firstNameKana, birthday : myBirthday,
        educational_background: education[0], handicap_qualifications: hnadicap_qual, preferred: preferred[0]});
        setApplicants(applicant);
    }

    const submit = (e: any) => {
        setEdited(false);
        if(applicants !== null) {
            setLoadScreen(true);
            dispatch(fetchChangeUpdateApplicantsRequest(resourceId, applicants[0]));
            e.preventDefault();
        }
    }
    return(
        <main id="company">
            <MypageSideBar resource={resourceId} familyName = {familyName} currentList="ApplicantList"/>
            {loadScreen ? <Loader /> : <></>}
            <section>
                <ul className="bread_crumb">
                    <li>
                        <a href="/" className="nuxt-link-active">トップ</a>
                    </li> 
                    <li>
                        <a>マイページ</a>
                    </li>
                </ul> 
                <div className="ttl_box">
                    <h2 className="ttl">マイページ</h2>
                </div> 
                <div className="initial_registration">
                    <div className="step_box">
                        <ul>
                            {totalStep.map((num: any) => (
                                <li className={step === num ? 'active' : ''}>
                                    <span>{ stepAry[num] }</span>
                                </li>
                            ))} 
                        </ul>
                    </div> 
                    <div className="cont_box">
                        {step == 1 ?
                            <>
                            <form>
                            <h3>お名前を教えてください</h3> 
                            <div className="name">
                                <label>姓
                                    <input type="text" name="familyname" title="お名前 姓" placeholder="パラ" onChange={onChangeInput}  value={familyNameForm} className={errorFamilyName || errorFamilyNameMaxlength  ? 'error': ''} />
                                </label>
                                <label>名
                                    <input type="text" name="firstname"  title="お名前 名"  placeholder="太郎" onChange={onChangeInput}  value={firstName} className={errorFirstName || errorFirstNameMaxlength  ? 'error': ''}/>
                                </label>
                            </div>
                            {errorFamilyName  || errorFirstName || errorFamilyNameMaxlength  || errorFirstNameMaxlength ?
                                <div className="error_box">
                                    {errorFamilyName  || errorFirstName ?
                                        <p className="error_msg">入力必須項目です</p> 
                                    : <></>}
                                    {errorFamilyNameMaxlength  || errorFirstNameMaxlength ?
                                        <p className="error_msg">姓・名は{MaxFifty}文字以下で入力してください</p>
                                    : <></>}
                                </div>
                            : <></>}
                            <h3>お名前（フリガナ）を教えてください</h3> 
                            <div className="name">
                                <label>セイ
                                    <input type="text" name="familynamekana"  title="姓フリガナ（片仮名）"  placeholder="パラ" onChange={onChangeInput} value={familyNameKana} className={errorFamilyNameKana || errorFamilyNameKanaCheck || errorFamilyNameKanaMaxlength  ? 'error': ''}/>
                                </label> 
                                <label>メイ
                                    <input type="text" title="名フリガナ（片仮名）" name="firstnamekana" placeholder="タロウ" onChange={onChangeInput} value={firstNameKana} className={errorFirstNameKana || errorFirstNameKanaCheck || errorFirstNameKanaMaxlength  ? 'error': ''}/>
                                </label>
                            </div> 
                            {errorFamilyNameKana  || errorFirstNameKana || errorFamilyNameKanaMaxlength || errorFamilyNameKanaCheck || errorFirstNameKanaCheck || errorFirstNameKanaMaxlength  ?
                                <div className="error_box">
                                    {errorFamilyNameKana  || errorFirstNameKana ?
                                        <p className="error_msg">入力必須項目です</p>
                                    : <></>}
                                    {errorFamilyNameKanaMaxlength  || errorFirstNameKanaMaxlength ?
                                        <p className="error_msg">姓・名は{MaxHundred}文字以下で入力してください</p>
                                    : <></>}
                                    {errorFamilyNameKanaCheck  || errorFirstNameKanaCheck ?
                                        <p className="error_msg">お名前（フリガナ）は全角カタカナで入力してください</p>
                                    : <></>}
                                </div> 
                            : <></>}
                            <h3>生年月日を教えてください</h3> 
                            <div className="birthday">
                                <select name="birthday_year" title="生年月日 年 選択してください" className={errorBirthYear ? 'error' : ''} onChange={onChangeInput}>
                                    {years.map((y: any) => (
                                        <option value={year} selected={year == y}>{ y }</option>
                                    ))} 
                                </select> 
                                <p>年</p> 
                                <select name="birthday_month" title="生年月日 月 選択してください" className={errorBirthMonth  ? 'error' : ''} onChange={onChangeInput}>
                                    {months.map((m: any) => (
                                        <option value={m} selected={m == month}>{ m }</option>
                                    ))} 
                                </select> 
                                <p>月</p> 
                                <select name="birthday_day" title="生年月日 日 選択してください" className={errorBirthDay  ? 'error' : ''} onChange={onChangeInput}>
                                    {days.map((d: any) => (
                                        <option value={d} selected={d == day}>{ d }</option>
                                    ))} 
                                </select> 
                                <p>日</p>
                            </div>                             
                            {errorBirthDay || errorBirthMonth || errorBirthYear ?
                                <div className="error_box">
                                    <p className="error_msg">選択必須項目です</p>
                                </div>
                            : <></>}
                            { nextStep ?
                            <button type="button" className={nextStep ? 'btn type3 orange' : "disabled btn type3 orange"} onClick={(e) => moveStep(e, 1)}>次へ
                                <img src="/assets/img/jobs/arrow03.svg" alt=""/>
                            </button>
                            :
                            <button type="button" className={nextStep ? 'btn type3 orange' : "disabled btn type3 orange"}>次へ
                                <img src="/assets/img/jobs/arrow03.svg" alt=""/>
                            </button>}
                        </form>
                            </>
                        : <></>} 

                        {step == 2 ?
                            <>
                            <form>
                                <h3>手帳の障がい種別または主な種別・その他特性を教えてください。</h3>
                                <p>※web履歴書にて障害種別は複数追加いただけます。</p>
                                <select name="handicap_type" title="手帳の障がい種別または主な種別・その他特性 選択してください" className={errorHandiType  ? 'error' : ''}  onChange={onChangeStepTwo}>
                                    <option selected disabled className="displayNone" value="0">選択してください</option>                                    
                                    {handicap_types != undefined && handicap_types.map((hand: any, index: any) => ( 
                                        <option value={hand.id} selected={selectHandiType == hand.id}>{hand.name}</option>
                                    ))} 
                                </select>
                                <select name="handicap" title="障がいに関する詳しい病名・症状名 選択してください" onChange={onChangeStepTwo} className={errorHand  ? 'error' : ''} disabled={handicapType == null} >
                                    <option selected={selectHand == ''} disabled className="displayNone" value="0">選択してください</option>                                    
                                    {filterCategoriesOne.map((list: any, index: any) => (
                                        <option value={list.id} selected={selectHand == list.id}>{list.name}</option>
                                    ))} 
                                </select>
                                {errorHandiType  || errorHand ?
                                    <div className="error_box">                                        
                                        {errorHandiType  || errorHand ?
                                            <p className="error_msg">選択必須項目です</p>                                            
                                        : <></>}
                                    </div>
                                : <></>}

                                {isOpenDisabilityCertificate ? <h3>上で選択した選択肢の障がい者手帳の有無と種別を教えてください</h3> : <></>}
                                {isOpenDisabilityCertificate ?
                                <div className="radio">
                                    <label>
                                        <input type="radio" title="障がい手帳あり" name="disability_certificate" value="2" checked={disability_certificate_status_id == 2} onChange={onChangeStepTwo}/>あり
                                    </label>
                                    <label>
                                        <input type="radio" title="障がい手帳なし" name="disability_certificate" value="1" checked={disability_certificate_status_id == 1} onChange={onChangeStepTwo}/>なし
                                    </label>
                                    <label>
                                        <input type="radio" title="障がい手帳申請中" name="disability_certificate" value="3" checked={disability_certificate_status_id == 3} onChange={onChangeStepTwo}/>申請中
                                    </label>
                                </div> : <></>}
                                
                                {isOpenDisabilityCertificate && disability_certificate_status_id == 2 ?
                                    <label className="step3">
                                        <p>障がい者手帳の種別</p>
                                        <div>
                                        <select
                                            name="disability_certificate_id"
                                            title="障がい者手帳の種別 選択してください"
                                            className={nextStepTwo ? '' : "disabled"}
                                            onChange={onChangeStepTwo}>
                                            <option value="null" className="displayNone">選択してください</option>
                                            {filterCategoriesTwo.map((list: any, index: any) => (
                                                <option value={list.id} selected={list.id == disabilityCertificateId}>{list.name + list.class_name}</option>
                                            ))} 
                                        </select>
                                        </div>
                                    </label>
                                : <></>}

                                <button onClick={(e) => moveStep(e, -1)} type="button" className="btn type3">戻る
                                    <img src="/assets//img/jobs/arrow03.svg" alt=""/>
                                </button>{
                                    nextStepTwo ?
                                <button onClick={(e) => moveStep(e, 1)} type="button" className={nextStepTwo ? 'btn type3 orange' : "disabled btn type3 orange"}>次へ
                                    <img src="/assets//img/jobs/arrow03.svg" alt=""/>
                                </button> :
                                <button type="button" className={nextStepTwo ? 'btn type3 orange' : "disabled btn type3 orange"}>次へ
                                    <img src="/assets//img/jobs/arrow03.svg" alt=""/>
                                </button>
                                }
                            </form>
                            </>
                        : <></>} 

                        {step === 3 ?
                            <>
                            <form v-if="step == 3">
                                <h3>新卒採用でお探しですか？</h3>
                                <div className="radio">
                                    <label><input type="radio" title="新卒採用" name="new_graduate" value="true" checked={new_graduate} onChange={onChangeStepThree}/>はい</label>
                                    <label><input type="radio" title="中途採用" name="new_graduate" value="false" checked={!new_graduate} onChange={onChangeStepThree}/>いいえ</label>
                                </div>

                                <h3>希望する勤務地を教えてください</h3>
                                <select name="regions" title="希望する勤務地 選択してください"  onChange={onChangeStepThree} className={errorRegion  ? 'error' : ''}>
                                    <option value='' disabled selected className='displayNone'></option>
                                    {prefectures.map((list: any, index: any) => (
                                        <option value={list.id} selected={list.id == selectRegion}>{list.name}</option>
                                    ))} 
                                </select>
                                {errorRegion ?
                                    <div className="error_box"> 
                                        <p className="error_msg">選択必須項目です</p>
                                    </div>                                
                                : <></>}                            

                                <h3>希望する仕事の探し方を教えてください</h3>
                                <select name="work_styles" title="最も希望する働き方 選択してください" onChange={onChangeStepThree} className={errorWork  ? 'error' : ''}>
                                    <option value="null" disabled selected className='displayNone'></option>
                                    {search_policies.map((list: any, index: any) => (
                                        <option value={list.id} selected={list.id == selectWork}>{list.name}</option>
                                    ))} 
                                </select>
                                {errorWork ?
                                    <div className="error_box"> 
                                        <p className="error_msg">選択必須項目です</p>
                                    </div>                                
                                : <></>} 
                                <p>登録後、web履歴書から変更することができます</p>
                               
                                <div className="privacy_policy">
                                <p><a href="/terms1/" target="_blank">利用規約</a>と<a href="/privacy/" target="_blank">プライバシーポリシー</a>をご確認いただき、<br className="sp_only"/>同意の上送信してください</p>
                                <label>
                                    <input name='agree' type="checkbox" title="利用規約とプライバシーポリシーの内容 同意する" value={termsOfCondition} onChange={onChangeStepThree}/><span>利用規約とプライバシーポリシーの<br className="sp_only"/>内容を確認し、同意する。</span>
                                </label>
                                {errorTerms ?
                                    <div className="error_box"> 
                                        <p className="error_msg">選択必須項目です</p>
                                    </div>                                
                                : <></>} 
                                </div>

                                <button onClick={(e) => moveStep(e, -1)} type="button" className="btn type3">戻る<img src="/assets//img/jobs/arrow03.svg" alt=""/></button>
                                { nextStepThree ? <button  onClick={(e) => submit(e)}  type="button" className={nextStepThree ? 'btn type3 orange' : "disabled btn type3 orange"}
                                >完了<img src="/assets//img/jobs/arrow03.svg" alt=""/></button>
                                :<button  type="button" className={nextStepThree ? 'btn type3 orange' : "disabled btn type3 orange"}
                                >完了<img src="/assets//img/jobs/arrow03.svg" alt=""/></button>}
                            </form>
                            </>
                        : <></>} 
                        
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Init;
