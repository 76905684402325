
import { articlesTypes } from "../../ActionTypes/articlesTypes";
import {  ArticlesActions, ArticlesState } from "../../types/type";

const initialState: ArticlesState = {
  pending: false,
  articles: [],
  error: null,
};

export default (state = initialState, action: ArticlesActions,) => {
  switch (action.type) {
    case articlesTypes.FETCH_ARTICLES_REQUEST:
      return {
        ...state,
        pending: false
      };
    case articlesTypes.FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        pending: true,
        articles: action.payload.articles,
        error: null
      };
    case articlesTypes.FETCH_ARTICLES_FAILURE:
      return {
        ...state,
        pending: false,
        articles: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
