import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminRecommendJobsTypes } from "../../ActionTypes/adminRecommendJobsTypes";
import { fetchAdminRecommendJobsFailure, fetchAdminRecommendJobsSuccess } from "../../actions/adminRecommendJobsActions/adminRecommendJobsActions";


const getAdminRecommendJobs = () => {
 
    return axios.get(API_BASE_ADDRESS + '/admin/recommend/jobs', {
      headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }
    });
  
}

function* fetchRecommendJobsSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminRecommendJobs);
    yield put(
      fetchAdminRecommendJobsSuccess({
        jobs: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminRecommendJobsFailure({
        error: e.message
      })
    );
  }
}

function* AdminRecommendJobsSaga() {
  yield takeLatest(adminRecommendJobsTypes.FETCH_ADMIN_RECOMMEND_JOBS_REQUEST, fetchRecommendJobsSaga);
}
export default AdminRecommendJobsSaga;