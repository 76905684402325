import axios from 'axios'; // axiosをインポート
import React, { useEffect, useState } from 'react'
import { API_BASE_ADDRESS } from '../../redax/API';
import AdminNav from './AdminNav'
import "./adminTop.css"
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redax/reducers/rootReducer';
import { useNavigate } from 'react-router';
import { fetchLoginRequest } from '../../redax/actions/loginActions/loginActions';
import { useLocation } from 'react-router-dom';
import Loader from '../loader/loader';

let countPosts = 0;

function AdminRecommendApplicants(props:any) {
  
  const [openPopup, setOpenPopup] = useState(false);
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [checkPopup, setCheckPopup] = useState(false);
  const [adminApplicantsId, setadminApplicantsId] = useState<any>([]);
  const recommendApplicants = useSelector((state: RootState) => state.recommendApplicants);
  const postcode = useSelector((state: RootState) => state.postcode);
  const [address, setAddress] = useState<any>([]);
  const [postcodes, setPostcodes] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [msg, setMsg] = useState('');
  const location = useLocation();
  const [companyId, setCompanyId] = useState<any>([]);
  const [recruitmentId, setRecruitmentId] = useState<any>([]);
  const [allData, setAllData] = useState<any>([]);
  const [load, setLoad] = useState(true);
  const [loadFirst, setLoadFirst] = useState(false);
  const [loadScreen, setLoadScreen] = useState(true);
  const [frstChk, setFrstChk] = useState(false);
  const [mailId, setMailId] = useState(1);
  const data = location.state;
  const today = new Date();
  const year = today.getFullYear();

  const [progress, setProgress] = useState(0);
  const [progress1, setProgress1] = useState<number>(0);

  useEffect(()=> {    
    // if(load) {
      let c :any = [], r = [], a = [], d:any = [];
      setAllData(data.data.recruitments);
      console.log(allData);
      for (let i = 0; i < allData.length; i++) {
        c.push(allData[i].company_id);
        r.push(allData[i].id)
        for (let j = 0; j < allData[i].applicant.length; j++) {   
          a.push(allData[i].applicant[j].id) 
        }
      }
      setCompanyId(c.filter((value:any, index:any, self:any) => self.indexOf(value) === index));
      setadminApplicantsId(a);
      setRecruitmentId(r);
      setPostcodes(d);
      setLoad(false);
      countPosts = d.length;   
  }, [load, dispatch]);
  
    
  useEffect(() => {
    if (!localStorage.getItem('jobParachannelAuthAdminToken')) {
      navigate('/admin/login');
    } else {
      dispatch(fetchLoginRequest('', '', ''));
    }
  }, [dispatch]);
      
  useEffect(()=> {
    if(recommendApplicants.pending) {
      setMailId(adminApplicantsId.length);
      if(frstChk){
        setEmailPopUp(true);
      }
      if(progress == mailId) {
        setEmailPopUp(false);
      }
      // alert(`送信が完了しました。
      // 送信企業者数${companyId.length}社 
      // スカウト送信数${adminApplicantsId.length}件 
      // `)
      // navigate("/admin/scout_matchings/jobs");
    } else if(recommendApplicants.error != null) {
      alert("Can't send a message")
    }       
    setLoadScreen(false);
  }, [recommendApplicants,progress]);


  useEffect(()=> {
    if(progress == mailId && !emailPopUp){
      setTimeout(() => {
        alert(`送信が完了しました。
        送信企業者数${companyId.length}社 
        スカウト送信数${adminApplicantsId.length}件 
        `)
        navigate("/admin/recommend_matchings/jobs");
      }, 1000);
    }

  }, [emailPopUp,progress])



  useEffect(() => {
    if(emailPopUp) {
      const interval = setInterval(() => {
        setProgress(prev => {
          if (prev < mailId) {
            return prev + 1;
          } else {
            clearInterval(interval);
            return prev;
          }
        });
      }, 250);
      return () => clearInterval(interval);
    }
  }, [emailPopUp]);

  useEffect(() => {
    if(emailPopUp) {
      const interval1 = setInterval(() => {
        setProgress1((mId) => {
          if (mId > 0) {
            return mId - 1;
          } else {
            clearInterval(interval1);
            return mId;
          }
        });
      }, 250);
      return () => clearInterval(interval1);
    }
  }, [emailPopUp]);


   

  // function CheckAdminPopup() {
  //   if (checkPopup === false) {
  //     if (window.confirm('本当に送信してもよろしいでしょうか?')) {
  //       setOpenPopup(false);
  //       let c = [];
  //       for (let i = 0; i < allData.length; i++) {
  //         for (let j = 0; j < allData[i].applicant.length; j++) {
  //           c.push({
  //             company_id: allData[i].company_id,
  //             recruitment_id: allData[i].id,
  //             applicant_id: allData[i].applicant[j].id,
  //           });
  //         }
  //       }
  //       const data = {
  //         recruitment_applicant: c,
  //         email_message: msg,
  //       };
  //       setLoadFirst(true);
  //       setFrstChk(true);
  //       dispatch(fetchAdminScoutApplicantsRequest('post', data));
  //     }
  //   }   
  // };


async function CheckAdminPopup() {
    if (checkPopup === false) {
        if (window.confirm('本当に送信してもよろしいでしょうか?')) {
            setOpenPopup(false);            
            const recruitmentApplicant: { applicant_id: number; recruitment_ids: number[] }[] = [];
            allData.forEach((recruitment: any) => {
            recruitment.applicant.forEach((applicant: any) => {
             const existingApplicant = recruitmentApplicant.find((a) => a.applicant_id === applicant.id);
              if (existingApplicant) {
                existingApplicant.recruitment_ids.push(recruitment.id);
              } else {
                  recruitmentApplicant.push({
                  applicant_id: applicant.id,
                  recruitment_ids: [recruitment.id]
                 });
              }
        });
     });

            setLoadFirst(true);
            setFrstChk(true);
            setEmailPopUp(true);

            let sentCnt = 0
            const processCnt = 10
            // データを10件ずつに分割
            for (let i = 0; i < recruitmentApplicant.length; i += processCnt) {
                const chunk = recruitmentApplicant.slice(i, i + processCnt);

                const data = {
                    recruitment_applicant: chunk,
                    message: msg,
                };
                sentCnt += chunk.length
                console.log(data);
                try {
                    const response = await axios.post(API_BASE_ADDRESS + '/admin/recommend/applicants', data, {
                      headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }
                    });
                    
                    if (response.status !== 200) {
                        console.error('エラーが発生しました:', response);
                        break; // エラーが発生した場合、処理を中断
                    }

                    // 進捗状況を更新（オプション）
                    // const progress = Math.min(((i + 10) / c.length) * 100, 100);
                    console.log(`進捗: ${sentCnt} / ${recruitmentApplicant.length}`);

                } catch (error) {
                    console.error('エラーが発生しました:', error);
                    break; // エラーが発生した場合、処理を中断
                }
            }
            
          //   const countObj = scoutedCompanyList.reduce((acc, num) => {
          //     acc[num] = (acc[num] || 0) + 1;
          //     return acc;
          //   }, {});
          //   const sendingCompanyData = Object.entries(countObj).map(([company_id, scout_count]) => ({
          //     company_id: parseInt(company_id),
          //     scout_count: scout_count
          //   }));
          //   try {
          //     const response = await axios.post(API_BASE_ADDRESS + '/admin/scout_matching/applicants/companies', sendingCompanyData, {
          //       headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }
          //     });
              
          //     if (response.status !== 200) {
          //         alert('エラーが発生しました: ' + response);
          //     }
          // } catch (error) {
          //   alert('エラーが発生しました: ' + error);
          // }

            // 全ての処理が完了したら何かしらの処理を行う（オプション）
            console.log('全ての処理が完了しました');
        }
    }
}

  

  function handleChange (e:any){
    if(e.target.value == "") {
      setCheckPopup(true)
    } else {
      setCheckPopup(false)
    }    
    setMsg(e.target.value)
  }

  function CloseModel () {
        setOpenPopup(false);
    };

  function closeEmailModel () {
      setEmailPopUp(false)
  };
    
  function OpenModel () {
    if (mailId == 0) {
      alert(`スカウト送信可能なデータがありません`)
      navigate("/admin/scout_matchings/jobs");
    } else {
      setOpenPopup(true);
    }
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    // console.log(windowSize.width);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  return (
    <main id='adminHome'>
      <AdminNav/>
      {loadScreen ? <Loader /> : <></>}
      <section className='sectionScouts' style={windowSize.width >= 1900 ? {padding:"16px"} : {padding:"16px"} }>
        <div style={{width:"100%"}}>
          <h1 className='adminH1 text-center'>お知らせマッチング STEP2: 候補者の確認</h1>
          <hr style={{ height:"1px", backgroundColor:"#00A0E9", marginBottom:"16px"}}/>
          <p style={{color:"#FF4D4D", marginBottom: "4px"}}><b>※表サンプル↓</b></p>  
          {/* <table className='adminTable mb-4 border-red'>
            <thead className='adminThead' style={{ width: "100%", position: "sticky", top: "0" }}>
              <tr>
                <th className='adminTheadTh'>♯</th>
                <th className='adminTheadTh'>会社名/求人名</th>
                <th className='adminTheadTh'>勤務地</th>
                <th className='adminTheadTh'>職種</th>
                <th className='adminTheadTh'>雇用形態</th>
                <th className='adminTheadTh'>給与条件</th>
                <th className='adminTheadTh'> </th>
                <th className='adminTheadTh'> </th>
                <th className='adminTheadTh'>DR残</th>
              </tr>
            </thead>
            <tbody className='adminTbody'>
            <tr>
                  <td>#</td>
                  <td>ID</td>
                  <td>性名</td>
                  <td>年齢</td>
                  <td>住所</td>
                  <td>障害種別1</td>
                  <td>障害種別2</td>
                  <td>希望勤務地</td>
                  <td>希望職種</td>
              </tr>
            </tbody>
            </table> */}
        </div>
        <div className='scroll'>
            <table className='adminTable'>
            <thead className='adminThead' style={{ width: "100%", position: "sticky", top: "0" }}>
              <tr>
                <th className='adminTheadTh'>♯</th>
                <th className='adminTheadTh'>会社名/求人名</th>
                <th className='adminTheadTh'>勤務地</th>
                <th className='adminTheadTh'>職種</th>
                <th className='adminTheadTh'>雇用形態</th>
                <th className='adminTheadTh'>給与条件</th>
                <th className='adminTheadTh'> </th>
                <th className='adminTheadTh'> </th>
                <th className='adminTheadTh'>DR残</th>
              </tr>
            </thead>
            <tbody className='adminTbody'>
            <tr>
                  <td>#</td>
                  <td>ID</td>
                  <td>性名</td>
                  <td>年齢</td>
                  <td>住所</td>
                  <td>障害種別1</td>
                  <td>障害種別2</td>
                  <td>希望勤務地</td>
                  <td>希望職種</td>
              </tr>
            </tbody>
            {allData.map((e:any) => (
              <>
                        <thead className='adminThead'>
                          <tr>
                            <th className='adminTdH2'>プ</th>
                            <th className='adminTheadTh'>{e.name} / {e.job_title}</th>
                            <th className='adminTheadTh'>&nbsp;</th>
                            <th className='adminTheadTh'> &nbsp;</th>
                            <th className='adminTheadTh'>&nbsp;</th>
                            <th className='adminTheadTh'>&nbsp;</th>
                            <th className='adminTheadTh'> </th>
                            <th className='adminTheadTh'> </th>
                            <th className='adminTheadTh'>&nbsp;</th>
                          </tr>
                      </thead>
                  {e.applicant.map((d:any, index:any) => (
                    <>
                        <tbody className='adminTbody'>
                            <tr> 
                                  <td>{index + 1}</td>
                                  <td>{d.id}</td>
                                  <td>{d.name}</td>
                                  <td>{d.age}</td>

                                  <td>{d.address}</td>
                                  <td>{d.disability_category1}</td>
                                  <td>{d.disability_category2}</td>
                                  <td>{d.ideal_prefecture}</td>
                                  <td>{d.ideal_job_category}</td>
                            </tr>
                        </tbody>
                      </>
                   ))} 
              </>
            ))}
          </table>

        </div>
          <div className='btn'>
            <button onClick={OpenModel} className='scoutsBtn'>一斉メール送信</button>

          </div>
      </section>
      {openPopup ?
        <div className="modal_box" >
            <div className="modal_inner" style={{padding:"3%", width:"50%", overflow:"auto"}}>
              <h1 className='adminPopupH1'>送信するテンプレート</h1>
              <select className='adminPopupSelect' name="" id="">
                  <option className='adminPopupOption' value=""><p>テンプレートA</p></option>
                  <option value=""></option>
                  <option value=""></option>
                  <option value=""></option>
              </select>
              <textarea style={{height:"300px"}} placeholder='text' value={msg} onChange={handleChange} name="" id=""></textarea>
              <div style={{display:"flex", justifyContent:"center", marginTop:"30px" , marginBottom:"20px"}}>
                <button className='scoutsBtn' onClick={CheckAdminPopup}>送信を確定</button>
              </div>
              { checkPopup ? <div style={{display:"flex", justifyContent:"center", color: "#FC3737",}}>
                  <p>※誤りがないか確認してクリックしてください</p>
              </div> :null
              }
              
                <p className="close" onMouseUp={CloseModel}><span className="material-icons">close</span></p>
            </div>
            <div className="overlay" onMouseUp={CloseModel}></div>
        </div>
        : null }

        {emailPopUp ?
        <div className="modal_box" >
            <div className="modal_inner backLoad" style={{padding:"3%", width:"50%", overflow:"auto"}}>
              <div className='absimg'>
                <img src="/assets/img/mail.svg"/>
              </div>
              <div className="load-spinner-mail">
                <span className="spinner-mail"></span>
              </div>
                <div className='loader-text'>
                  {progress === mailId ? <>
                  <h3>送信完了: {progress}通 送信済</h3>
                  </>:
                  <>
                    <h1>スカウト送信中 </h1>
                    <h1>{progress} /{mailId} 送信中</h1>
                    <h1 style={{marginBottom : '50px'}}>残り{progress1}</h1>
                    {/* <button onClick={closeEmailModel}>中止</button> */}
                  </>
                  }
                </div>
            </div>
            <div className="overlay"></div>
        </div>
        : null }
      
    </main>
  )
}

export default AdminRecommendApplicants
