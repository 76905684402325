import { articlesImagesTypes } from "../../ActionTypes/articlesImagesTypes";
import { ArticlesImagesActions, ArticlesImagesState } from "../../types/type";




const initialState: ArticlesImagesState = {
  pending: false,
  images: [],
  error: null,
};

export default (state = initialState, action: ArticlesImagesActions,) => {
  switch (action.type) {
    case articlesImagesTypes.FETCH_ARTICLES_IMAGES_REQUEST:
      return {
        ...state,
        pending: false
      };
    case articlesImagesTypes.FETCH_ARTICLES_IMAGES_SUCCESS:
      return {
        ...state,
        pending: true,
        images: action.payload.images,
        error: null
      };
    case articlesImagesTypes.FETCH_ARTICLES_IMAGES_FAILURE:
      return {
        ...state,
        pending: false,
        images: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
