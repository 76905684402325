import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { postcodeTypes } from "../../ActionTypes/postcodeTypes";
import { fetchPostCodeFailure, fetchPostCodeSuccess } from "../../actions/postcodeActions/postcodeActions";


var getPostCode = (id:any) => axios.get(API_BASE_ADDRESS + '/master?postcode=' + id, {headers: { Accept: 'application/json'}}); 

function* fetchPostCodeSaga(id: any) {
  try {
    const response: AxiosResponse = yield call(getPostCode, id.id);
    yield put(
      fetchPostCodeSuccess({
        postcode: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchPostCodeFailure({
        error: e.message
      })
    );
  }
}

function* PostCodeSaga() {
  yield takeLatest(postcodeTypes.FETCH_POSTCODE_REQUEST, fetchPostCodeSaga);
}

export default PostCodeSaga;
