import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminAdministratorsTypes } from "../../ActionTypes/adminAdministratorsTypes";
import { fetchAdminAdministratorsFailure, fetchAdminAdministratorsSuccess } from "../../actions/adminAdministratorsActions/adminAdministratorsActions";



const getAdminAdministrator = (name: any, body:any, id:any) => { 

  if(name == 'get') {
    const config = {
      headers: { 
        Prefer: 'example=example-1', Accept: 'application/json', 
        Authorization: localStorage.jobParachannelAuthAdminToken 
      }
    };
    return axios.get(API_BASE_ADDRESS + '/admin/administrators' , config); 
  } else if (name == 'post'){
    const config = {
      headers: { 
        Prefer: 'example=example-1', Accept: 'application/json', 
        Authorization: localStorage.jobParachannelAuthAdminToken 
      }
    };
    return axios.post(API_BASE_ADDRESS + '/admin/administrators' , {administrator:body},config); 
  } else {
    const config = {
      headers: { 
        Prefer: 'example=example-1', Accept: 'application/json', 
        Authorization: localStorage.jobParachannelAuthAdminToken 
      }
    };
    return axios.put(API_BASE_ADDRESS + '/admin/administrators/' + id , {administrator:body},config); 
  }
  
}


function* fetchAdminAdministratorsSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminAdministrator, action.name, action.body,action.id);
    yield put(
      fetchAdminAdministratorsSuccess({
        administrators: response.data.administrators,
        total_count:response.data.total_count
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminAdministratorsFailure({
        error: e.message
      })
    );
  }
}

function* AdminAdministratorsSaga() {
  yield takeLatest(adminAdministratorsTypes.FETCH_ADMIN_ADMINISTRATORS_REQUEST, fetchAdminAdministratorsSaga);
}
export default AdminAdministratorsSaga;