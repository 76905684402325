import { topRecruiterTypes } from "../../ActionTypes/topRecruiterTypes";
import { TopRecruiterActions, TopRecruiterState } from "../../types/type";



const initialState: TopRecruiterState = {
  pending: false,
  recruiter: [],
  error: null,
};

export default (state = initialState, action: TopRecruiterActions,) => {
  switch (action.type) {
    case topRecruiterTypes.FETCH_TOP_RECRUITER_REQUEST:
      return {
        ...state,
        pending: false
      };
    case topRecruiterTypes.FETCH_TOP_RECRUITER_SUCCESS:
      return {
        ...state,
        pending: true,
        recruiter: action.payload.recruiter,
        error: null
      };
    case topRecruiterTypes.FETCH_TOP_RECRUITER_FAILURE:
      return {
        ...state,
        pending: false,
        recruiter: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
