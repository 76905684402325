import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchAdminScoutsCompanyFailure, fetchAdminScoutsCompanySuccess } from "../../actions/adminScoutsCompanyActions/adminScoutsCompanyActions";
import { adminScoutsCompanyTypes } from "../../ActionTypes/adminScoutsCompanyTypes";

const getAdminScoutsCompany = () => { 
  const config = {
    headers: { 
      Prefer: 'example=example-1', Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/scouts/companies' , config); 
}


function* fetchAdminScoutsCompanySaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminScoutsCompany);
    yield put(
      fetchAdminScoutsCompanySuccess({
        companies: response.data.companies,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminScoutsCompanyFailure({
        error: e.message
      })
    );
  }
}

function* AdminScoutsCompanySaga() {
  yield takeLatest(adminScoutsCompanyTypes.FETCH_ADMIN_SCOUTS_COMPANY_REQUEST, fetchAdminScoutsCompanySaga);
}
export default AdminScoutsCompanySaga;