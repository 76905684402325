import { adminRecruitmentPostTypes } from "../../ActionTypes/adminRecruitmentPostTypes";
import { AdminRecruitmentPostActions, AdminRecruitmentPostState } from "../../types/type";


const initialState: AdminRecruitmentPostState = {
  pending: false,
  recruitments: [],
  error: null,
};

export default (state = initialState, action: AdminRecruitmentPostActions,) => {
  switch (action.type) {
    case adminRecruitmentPostTypes.FETCH_ADMIN_RECRUITMENT_POST_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminRecruitmentPostTypes.FETCH_ADMIN_RECRUITMENT_POST_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitments: action.payload.recruitments,
        error: null
      };
    case adminRecruitmentPostTypes.FETCH_ADMIN_RECRUITMENT_POST_FAILURE:
      return {
        ...state,
        pending: false,
        applicant: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
