import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../redax/reducers/rootReducer";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import Loader from "../../../loader/loader";


function ScoutComplete() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [resourceId, setResourceId] = useState(null);
    const loginData = useSelector((state: RootState) => state.login);
    const [familyName, setFamylyName] = useState(null);
    const [loadScreen, setLoadScreen] = useState(true);
    const location = useLocation();
    const data = location.state;
    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
        //   dispatch(fetchLoginRequest('', '',''))
        } else (
            navigate('/')
        )
      }, [dispatch]);


    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/recruiter/init/');
            }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            setLoadScreen(false);
        }
    }, [loginData.pending]);
      
    function logout() {
        if (localStorage.entryFlag) {
            localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
            localStorage.removeItem('jobParachannelAuthToken')
            navigate('/');
            window.location.reload();
        }
    }      
    function loadBack() {
        let l = data.queryParamsString != null ? data.queryParamsString : ''
        window.location.href = '/recruiter/scouts/?' + l;
    }
    return (
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className ="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">スカウト</h2>
                <ul>
                <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            <div id="scouts" className="entry complete">
                <p>スカウトメッセージは送信されました。</p>
                <div className="btn_box">
                <a href="javascript:void(0)" onClick={loadBack} className="btn">続けて人材を検索する</a>
                <a href="/recruiter/" className="btn back">マイページトップへ戻る</a>
                </div>
            </div>
        </section>
    </main>
    );
  }
  
  export default ScoutComplete;