import { MessagesPostTypes } from "../../ActionTypes/messagesPostTypes ";
import { MessagesPostActions, MessagesPostState } from "../../types/type";



const initialState: MessagesPostState = {
  pending: false,
  messages: [],
  error: null,
};

export default (state = initialState, action: MessagesPostActions,) => {
  switch (action.type) {
    case MessagesPostTypes.FETCH_MESSAGES_POST_REQUEST:
      return {
        ...state,
        pending: false
      };
    case MessagesPostTypes.FETCH_MESSAGES_POST_SUCCESS:
      return {
        ...state,
        pending: true,
        messages: action.payload.messages,
        error: null
      };
    case MessagesPostTypes.FETCH_MESSAGES_POST_FAILURE:
      return {
        ...state,
        pending: false,
        messages: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
