import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchRecruitmentsSearchFailure, fetchRecruitmentsSearchSuccess } from "../../actions/recruitmentsSearchActions/recruitmentsSearchActions";
import { recruitmentsSearchTypes } from "../../ActionTypes/recruitmentsSearchTypes";

var getRecruitmentsSearch = (name: any, queryParams:any) => {
  if(name == 'applicants') {
    return axios.get(API_BASE_ADDRESS + '/applicants/search.json' + queryParams , {headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken}}); 
  } else {
    return axios.get(API_BASE_ADDRESS + '/recruitments/search.json?' + queryParams , {headers: { Accept: 'application/json'}}); 
  }
}

function* fetchRecruitmentsSearchSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getRecruitmentsSearch, queryParams.name, queryParams.queryParams);

    yield put(
      fetchRecruitmentsSearchSuccess({
        recruitmentsSearch: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitmentsSearchFailure({
        error: e.message
      })
    );
  }
}

function* RecruitmentsSearchSaga() {
  yield takeLatest(recruitmentsSearchTypes.FETCH_RECRUIMENTS_SEARCH_REQUEST, fetchRecruitmentsSearchSaga);
}

export default RecruitmentsSearchSaga;
