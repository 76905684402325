import { recruiterReceiptPostTypes } from "../../ActionTypes/recruiterReceiptPostTypes";
import { RecruiterReceiptPostActions, RecruiterReceiptPostState } from "../../types/type";



const initialState: RecruiterReceiptPostState = {
  pending: false,
  recruiterReceipt: [],
  error: null,
  status: []
};

export default (state = initialState, action: RecruiterReceiptPostActions,) => {
  switch (action.type) {
    case recruiterReceiptPostTypes.FETCH_RECRUITER_RECEIPT_POST_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruiterReceiptPostTypes.FETCH_RECRUITER_RECEIPT_POST_SUCCESS:
      return {
        ...state,
        pending: true,
        recruiterReceipt: action.payload,
        status: action.payload.status,
        error: null
      };
    case recruiterReceiptPostTypes.FETCH_RECRUITER_RECEIPT_POST_FAILURE:
      return {
        ...state,
        pending: false,
        recruiterReceipt: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
