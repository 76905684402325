import { recruiterRecruitmentsDetailTypes } from "../../ActionTypes/recruiterRecruitmentsDetailTypes";
import { RecruiterRecruitmentsDetailActions, RecruiterRecruitmentsDetailState } from "../../types/type";



const initialState: RecruiterRecruitmentsDetailState = {
  pending: false,
  recruiterRecruitmentsDetail: [],
  error: null,
};

export default (state = initialState, action: RecruiterRecruitmentsDetailActions,) => {
  switch (action.type) {
    case recruiterRecruitmentsDetailTypes.FETCH_RECRUITER_RECRUIMENTS_DETAIL_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruiterRecruitmentsDetailTypes.FETCH_RECRUITER_RECRUIMENTS_DETAIL_SUCCESS:
      return {
        ...state,
        pending: true,
        recruiterRecruitmentsDetail: action.payload.recruiterRecruitmentsDetail,
        error: null
      };
    case recruiterRecruitmentsDetailTypes.FETCH_RECRUITER_RECRUIMENTS_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        recruiterRecruitmentsDetail: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
