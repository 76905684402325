import { useEffect, useState } from "react";
import MypageSideBar from "../../../components/MypageSideBar/MypageSideBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redax/reducers/rootReducer";
import { useNavigate } from "react-router-dom";
import { fetchLoginRequest } from "../../../redax/actions/loginActions/loginActions";
import { fetchRegionsRequest } from "../../../redax/actions/regionsActions/regionsActions";
import { fetchScoutsApplicantsRequest } from "../../../redax/actions/scoutsApplicantActions/scoutsApplicantActions";
import { fetchScoutsCandidateRequest } from "../../../redax/actions/scoutsCandidateActions/scoutsCandidateActions";
import { fetchScoutsSavedConditionsRequest } from "../../../redax/actions/scoutsSavedConditionsActions/scoutsSavedConditionsActions";
import Loader from "../../loader/loader";
import './scouts.scss';
import { fetchRecruitmentsSearchRequest } from "../../../redax/actions/recruitmentsSearchActions/recruitmentsSearchActions";


function ScoutsIndex() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [resourceId, setResourceId] = useState(null);
    const loginData = useSelector((state: RootState) => state.login);
    const [familyName, setFamylyName] = useState(null);
    const [showSearchDetail, setSearchDetail] = useState(false);
    const [showSearchMain, setShowSearchMain] = useState(true);
    const [employment_statuses, setEmployeementStatuses] = useState<any[]>([]);
    const [job_categories, setJobCategories] = useState<any[]>([]);
    const [job_category_types, setJobCategoryTypes] = useState<any[]>([]);
    const [working_hours, setWorkingHours] = useState<any[]>([]);
    const [work_from_homes, setWorkFromHome] = useState<any[]>([]);
    const [oa_skill_categories, setOaSkillCategories] = useState<any[]>([]);
    const [oa_skills, setOaSkills] = useState<any[]>([]);
    const [languages, setLanguages] = useState<any[]>([]);
    const [levels, setLevels] = useState<any[]>([]);
    const [qualification_categories, setQualificationCategories] = useState<any[]>([]);
    const [qualifications, setQualifications] = useState<any[]>([]);
    const [regionsArr, setRegionsArr] = useState<any[]>([]);
    const [pagination, setPagination] = useState<any[]>([]);
    const [handicap_types, setHandicapTypes] = useState<any>('');
    const [handicaps, setHandicaps] = useState<any>('');
    const [disability_certificate_statuses, setDisabilityCertificateStatuses] = useState<any[]>([]);
    const [disability_certificate_status, setDisabilityCertificateStatus] = useState<any>('');
    const [employment_support_agency, setEmploymentSupportAgency] = useState<any>('');
    const [prefectures, setPrefectures] = useState<any[]>([]);
    const [prefecture_id1, setPrefectureId1] = useState('');
    const regions = useSelector( (state: RootState) => state.regions);
    const scoutsApplicants = useSelector( (state: RootState) => state.scoutsApplicants);
    const candidates = useSelector((state: RootState) => state.scoutsCandidate);
    const [current_page, setCurrentPage] = useState(0);
    const [total_pages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('');
    const [queryParams, setQueryParams] = useState('');
    const [japaneseParams, setJapaneseParams] = useState('');
    const [prefecture_ids, setPrefectureIds] = useState<any[]>([]);
    const [allCheck, setAllcheck] = useState(false);
    const [selectedApplicantIds, setSelectedApplicantIDs] = useState<any[]>([]);
    const [japaneseParamsObject, setJapaneseParamsObject] = useState<any[]>([]);
    const [selectedApplicants, setSelectedApplicants] = useState<any[]>([]);
    const [applicants, setApplicants] = useState<any[]>([]);
    const [publish_recruitments_count, setPublishCount] = useState<any[]>([]);
    const [handicap_type1, setHandicapType1] = useState<any>('');
    const [handicap_type2, setHandicapType2] = useState<any>('');    
    const [filterHandicapType, setFilterHandicapType] = useState<any[]>([]);
    // 選択した値
    const [employment_status, setEmploymentStatus] = useState<any>('');
    const [job_category_type, setjob_category_type] = useState<any>('');
    const [job_category, setjob_category] = useState<any>('');
    const [working_hour, setworking_hour] = useState<any>('');
    const [work_from_home, setwork_from_home] = useState<any>('');
    const [oa_skill_category, setoa_skill_category] = useState<any>('');
    const [oa_skill, setoa_skill] = useState('');
    const [language, setlanguage] = useState<any>('');
    const [level, setlevel] = useState('');
    const [qualification_category, setqualification_category] = useState('');
    const [qualification, setqualification] = useState('');

    // 動的変数
    const [oa_skill_loop_count, setoa_skill_loop_count] = useState(1);
    const [oa_skill_category_1, setoa_skill_category_1] = useState<any>('');
    const [oa_skill_category_2, setoa_skill_category_2] = useState<any>('');
    const [oa_skill_category_3, setoa_skill_category_3] = useState<any>('');
    const [oa_skill_1, setoa_skill_1] = useState<any>('');
    const [oa_skill_2, setoa_skill_2] = useState<any>('');
    const [oa_skill_3, setoa_skill_3] = useState<any>('');
    const [language_loop_count, setlanguage_loop_count] = useState(1);
    const [language_1, setlanguage_1] = useState<any>('');
    const [language_2, setlanguage_2] = useState<any>('');
    const [language_3, setlanguage_3] = useState<any>('');
    const [level_1, setlevel_1] = useState<any>('');
    const [level_2, setlevel_2] = useState<any>('');
    const [level_3, setlevel_3] = useState<any>('');
    const [qualification_loop_count, setqualification_loop_count] = useState(3);
    const [qualification_category_1, setqualification_category_1] = useState<any>('');
    const [qualification_category_2, setqualification_category_2] = useState<any>('');
    const [qualification_category_3, setqualification_category_3] = useState<any>('');
    const [qualification_1, setqualification_1] = useState<any>('');
    const [qualification_2, setqualification_2] = useState<any>('');
    const [qualification_3, setqualification_3] = useState<any>('');
    const [mySelecedPrefectures, setmySelecedPrefectures] = useState<any>([]);
    const scoutsSavedConditions = useSelector((state: RootState) => state.scoutsSavedConditions);
    const [loadScreen, setLoadScreen] = useState(true);
    const [notifyWhenApplicantFinded, setNotify] = useState(false);
    const [selectedAppIds, setSelectedAppIds] = useState<any>([]);
    const [limitCount, setLimitCount] = useState(0);
    const [scoutDisabled, setScoutDisabled] = useState(false);
    const pageTitle = '企業様マイページ｜パラちゃんねる';
    document.title = pageTitle;
    const recruitmentsSearch = useSelector(
      (state: RootState) => state.recruitmentsSearch
    );

    const sortList = [
        { text: '並び替え', value: '' },
        { text: '年齢が低い順', value: 'birthday_low' },
        { text: '年齢が高い順', value: 'birthday_high' },
        { text: '登録日時が新しい順', value: 'created_at' },
        { text: 'ログイン日時が新しい順', value: 'active' },
        { text: '相性のマッチ度の高い順', value: 'compatibility' },
    ];

    const employment_support_agencies = [
      {id: 1, name: '無し'},
      {id: 2, name: '有り'}
    ];
    const queryParamsAll = new URLSearchParams(window.location.search);
    const [selectedPrefectures, setSelectedPrefectures] = useState<any[]>([]);
    const [mySelectedPrefectures, setMySelectedPrefectures] = useState<any[]>([]);
    const [isPrefectureCheck, setIsPrefectureCheck] = useState<boolean>(false);
    const [isloadSavedConditions, setIsLoadSavedConditions ] = useState<boolean>(false);
    const [isSaveParameters, setIsSaveParameters ] = useState<boolean>(false);
    useEffect(() => {
      if (localStorage.getItem('jobParachannelAuthToken')) {
        dispatch(fetchLoginRequest('', '',''))
      } else (
          navigate('/recruiter/login/')
      )
    }, [dispatch]);


    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
              navigate('/recruiter/init/');
            }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            setPublishCount(loginData.login.authenticate.publish_recruitments_count);
            setLimitCount(loginData.login.scout_today_limit);
              if (loginData.login.scout_today_limit <= 0) {
                setScoutDisabled(true);
                window.confirm("本日のスカウト可能残数0名になりました");
              } 
            // 公開求人が0件の場合 アラート画面へ遷移
            if (loginData.login.authenticate.publish_recruitments_count == 0) {
              navigate('/recruiter/scouts/alert/');
            }            
            if(queryParamsAll.toString() != '') {
              dispatch(fetchScoutsApplicantsRequest(queryParamsAll.toString()));
            } else {
              const query = new URLSearchParams(window.location.search)
              query.set('page', '1')
              query.set('sort', '')
              query.set('per', '30')

              // 検索用パラメータ取得
              const searchQuery = (query.toString() ? `?${query}` : '') 
              dispatch(fetchRecruitmentsSearchRequest('applicants', searchQuery));
              // dispatch(fetchScoutsApplicantsRequest('page=2&sort=date&per=30'));
            }         
        }
      }, [loginData.pending]);

      useEffect(() => {
        if (recruitmentsSearch.pending) {
          // URLのqueryパラメータ>検索用パラメータに変換してデータセット
          let search = recruitmentsSearch.recruitmentsSearch;
          if (search.handicap_types) {
            setHandicapType1(search.handicap_types[0] ? search.handicap_types[0] : '')
            setHandicapType2(search.handicap_types[1] ? search.handicap_types[1] : '')
          }
          if (search.disability_certificate_statuses) {
            setDisabilityCertificateStatus(search.disability_certificate_statuses[0] ? search.disability_certificate_statuses[0] : '')
          }
          if (search.employment_statuses) {
            setEmploymentStatus(search.employment_statuses[0] ? search.employment_statuses[0] : '')
          }
          if (search.job_category_types) {
            setjob_category_type(search.job_category_types[0] ? search.job_category_types[0] : '')
          }
          if (search.job_categories) {
            setjob_category(search.job_categories[0] ? search.job_categories[0] : '')
          }
          if (search.prefectures) {
            let prefecture_ids1 : any = []
            search.prefectures.forEach(function(value: any) { 
              prefecture_ids1.push(value.id)
            })
            setPrefectureIds(prefecture_ids1)
            // 全国の場合
            if (search.prefectures.length == 47) {
              setIsPrefectureCheck(true);
            }
          }
          if (search.working_hours) {
            setworking_hour(search.working_hours[0] ? search.working_hours[0] : '')
          }
          if (search.work_from_homes) {
            setwork_from_home(search.work_from_homes[0] ? search.work_from_homes[0] : '')
          }
          if (search.oa_skill_categories) {
            setoa_skill_category_1(search.oa_skill_categories[0] ? search.oa_skill_categories[0] : '')
            setoa_skill_category_2(search.oa_skill_categories[1] ? search.oa_skill_categories[1] : '')
            setoa_skill_category_3(search.oa_skill_categories[2] ? search.oa_skill_categories[2] : '')
            setoa_skill_loop_count(search.oa_skill_categories.length)
          }
          if (search.oa_skills) {
            setoa_skill_1(search.oa_skills[0] ? search.oa_skills[0] : '')
            setoa_skill_2(search.oa_skills[1] ? search.oa_skills[1] : '')
            setoa_skill_3(search.oa_skills[2] ? search.oa_skills[2] : '')
          }
          if (search.languages) {
            setlanguage_1(search.languages[0] ? search.languages[0] : '')
            setlanguage_2(search.languages[1] ? search.languages[1] : '')
            setlanguage_3(search.languages[2] ? search.languages[2] : '')
            setlanguage_loop_count(search.languages.length)
          }
          if (search.levels) {
            setlevel_1(search.levels[0] ? search.levels[0] : '')
            setlevel_2(search.levels[1] ? search.levels[1] : '')
            setlevel_3(search.levels[2] ? search.levels[2] : '')
          }
          if (search.qualification_categories) {
            setqualification_category_1(search.qualification_categories[0] ? search.qualification_categories[0] : '') 
            setqualification_category_2(search.qualification_categories[1] ? search.qualification_categories[1] : '')
            setqualification_category_3(search.qualification_categories[2] ? search.qualification_categories[2] : '')
          }
          if (search.qualifications) {
            setqualification_1(search.qualifications[0] ? search.qualifications[0] : '')
            setqualification_2(search.qualifications[1] ? search.qualifications[1] : '')
            setqualification_3(search.qualifications[2] ? search.qualifications[2] : '')
          }
          
          // URLのqueryパラメータから直接データセット
          // TODO マスタに追加して処理を合わせる
          if (queryParamsAll.get('employment_support_agency')) {
            let too = queryParamsAll.get('employment_support_agency')
            let key = (parseInt(too!) - 1)
            setEmploymentSupportAgency(employment_support_agencies[key])
          }
          let query = createParameter();
          if(query == '') {
            dispatch(fetchScoutsApplicantsRequest('page=1&sort=&per=30'));
          } else {
            setQueryParams(query);
            setShowSearchMain(false);
            dispatch(fetchScoutsApplicantsRequest(query));
          }
        } else if(recruitmentsSearch.error != null) {
          setLoadScreen(false);
          navigate('/error?code=500');
        }
      }, [recruitmentsSearch.pending, recruitmentsSearch.error]);

    
      useEffect(() => {
        if (scoutsApplicants.pending) {
          if(scoutsApplicants.scoutsApplicants.applicants !== undefined) {
            setApplicants(scoutsApplicants.scoutsApplicants.applicants);
            // setShowSearchMain(true);
          }
          if(scoutsApplicants.scoutsApplicants.pagenation !== undefined) {
            setCurrentPage(scoutsApplicants.scoutsApplicants.pagenation.current_page);
            setTotalPages(scoutsApplicants.scoutsApplicants.pagenation.total_pages);
          }
          dispatch(fetchRegionsRequest());
          setLoadScreen(false);          
        } else if(scoutsApplicants.error != null) {
          setLoadScreen(false);
          navigate('/error?code=500');
        }
      }, [scoutsApplicants.pending, scoutsApplicants.error]);


      useEffect(() => {
        if (regions !== undefined && regions.pending) {
            setEmployeementStatuses(regions.regions.employment_statuses);
            setJobCategories(regions.regions.job_categories);
            setJobCategoryTypes(regions.regions.job_category_types);
            setWorkingHours(regions.regions.working_hours);
            setWorkFromHome(regions.regions.work_from_home);
            setOaSkillCategories(regions.regions.oa_skill_categories);
            setOaSkills(regions.regions.oa_skills);
            setLanguages(regions.regions.languages);
            setLevels(regions.regions.levels);
            setQualificationCategories(regions.regions.qualification_categories);
            setQualifications(regions.regions.qualifications);
            setRegionsArr(regions.regions.regions);
            setHandicapTypes(regions.regions.handicap_types);
            setHandicaps(regions.regions.handicaps);
            setDisabilityCertificateStatuses(regions.regions.disability_certificate_statuses);
            setPrefectures(regions.regions.prefectures);
            if(queryParamsAll.toString() != '') {
              if(regions.regions.handicap_types != undefined) {
                let japaneseParams = [];

                if(queryParamsAll.has('handicap_type_ids[]')) {
                  japaneseParams.push('障がい種別：')
                }
                if(queryParamsAll.has('handicap_type_ids[]')) {
                  for(let i = 0; i < regions.regions.handicap_types.length; i++) {
                    for(let j = 0; j < queryParamsAll.getAll('handicap_type_ids[]').length; j++) {
                      if(queryParamsAll.getAll('handicap_type_ids[]')[j] == regions.regions.handicap_types[i].id) {
                        japaneseParams.push(regions.regions.handicap_types[i].name + ', ')
                        if(j == 0) {
                          setHandicapType1(regions.regions.handicap_types[i]);
                        } else {
                          setHandicapType2(regions.regions.handicap_types[i]);
                        }
                      }
                    }
                  }
                }
                if(queryParamsAll.has('disability_certificate_status')) {
                  for(let i = 0; i < regions.regions.disability_certificate_statuses.length; i++) {
                    if(queryParamsAll.get('disability_certificate_status') == regions.regions.disability_certificate_statuses[i].id) {
                      setDisabilityCertificateStatus(regions.regions.disability_certificate_statuses[i]);
                      japaneseParams.push('障がい者手帳有無：' + regions.regions.disability_certificate_statuses[i].name + ', ')
                    }
                  }                
                }
                if(queryParamsAll.has('employment_support_agency')) {
                  for(let i = 0; i < employment_support_agencies.length; i++) {
                    if(queryParamsAll.get('employment_support_agency') == employment_support_agencies[i].id.toString()) {
                      setEmploymentSupportAgency(employment_support_agencies[i]);
                      japaneseParams.push('就労支援有無：' + employment_support_agencies[i].name + ', ')
                    }
                  }
                }
                // 就職カテゴリー
                if(queryParamsAll.has('employment_status')) {
                  for(let i = 0; i < regions.regions.employment_statuses.length; i++) {
                    if(queryParamsAll.get('employment_status') == regions.regions.employment_statuses[i].id.toString()) {
                      setEmploymentStatus(regions.regions.employment_statuses[i]);
                      japaneseParams.push('就職カテゴリー：' + regions.regions.employment_statuses[i].name + ', ')
                    }
                  }
                }
                // 職種
                if(queryParamsAll.has('job_category_type')) {
                  for(let i = 0; i < regions.regions.job_category_types.length; i++) {
                    if(queryParamsAll.get('job_category_type') == regions.regions.job_category_types[i].id.toString()) {
                      setjob_category_type(regions.regions.job_category_types[i]);
                      japaneseParams.push('職種分類：' + regions.regions.job_category_types[i].name + ', ')
                    }
                  }
                }
                if(queryParamsAll.has('job_category')) {
                  for(let i = 0; i < regions.regions.job_categories.length; i++) {
                    if(queryParamsAll.get('job_category') == regions.regions.job_categories[i].id.toString()) {
                      setjob_category(regions.regions.job_categories[i]);
                      japaneseParams.push('職種：' + regions.regions.job_categories[i].name + ', ')
                    }
                  }
                }
                // 勤務地
                if(queryParamsAll.has('prefecture_ids[]')) {
                  japaneseParams.push('勤務地：')
                  if(queryParamsAll.has('pre_all')) {
                    setIsPrefectureCheck(true);
                  }
                }
                if(queryParamsAll.has('prefecture_ids[]')) {
                  let p = [];
                  let r = [];
                  for(let i = 0; i < regions.regions.prefectures.length; i++) {
                    for(let j = 0; j < queryParamsAll.getAll('prefecture_ids[]').length; j++) {
                      if(queryParamsAll.getAll('prefecture_ids[]')[j] == regions.regions.prefectures[i].id) {
                        japaneseParams.push(regions.regions.prefectures[i].name + ', ')
                        p.push(regions.regions.prefectures[i].id);
                        if(r.indexOf(regions.regions.prefectures[i].region_id) == -1) {
                          r.push(regions.regions.prefectures[i].region_id);
                        }
                      }
                    }
                  }
                  setPrefectureIds(p);
                  let c = [];
                  for (let i = 0; i < selectedPrefectures.length; i++) {
                    c.push(selectedPrefectures[i]);
                  }
                  for (let i = 0; i < regions.regions.regions.length; i++) {
                    if(r.indexOf(regions.regions.regions[i].id) > -1) {
                      c[i] = true;
                    }
                  }
                  setSelectedPrefectures(c);
                }
                // 就労時間
                if(queryParamsAll.has('working_hour')) {
                  for(let i = 0; i < regions.regions.working_hours.length; i++) {
                    if(queryParamsAll.get('working_hour') == regions.regions.working_hours[i].id.toString()) {
                      setworking_hour(regions.regions.working_hours[i]);
                      japaneseParams.push('就労時間：' + regions.regions.working_hours[i].name + ', ')
                    }
                  }
                }
                // 在宅希望
                if(queryParamsAll.has('work_from_home')) {
                  if(regions.regions.work_from_homes) {
                    for(let i = 0; i < regions.regions.work_from_homes.length; i++) {
                      if(queryParamsAll.get('work_from_home') == regions.regions.work_from_homes[i].id.toString()) {
                        setwork_from_home(regions.regions.work_from_homes[i]);
                        japaneseParams.push('在宅希望：' + regions.regions.work_from_homes[i].name + ', ')
                      }
                    }
                  }
                }
                // スキル - PC分類
                if(queryParamsAll.has('oa_skill_category[]')) {
                  japaneseParams.push('スキル - PC分類：')
                }
                if(queryParamsAll.has('oa_skill_category[]')) {
                  for(let i = 0; i < regions.regions.oa_skill_categories.length; i++) {
                    for(let j = 0; j < queryParamsAll.getAll('oa_skill_category[]').length; j++) {
                      if(queryParamsAll.getAll('oa_skill_category[]')[j] == regions.regions.oa_skill_categories[i].id) {
                        japaneseParams.push(regions.regions.oa_skill_categories[i].name + ', ')
                        if(j == 0) {
                          setoa_skill_category_1(regions.regions.oa_skill_categories[i]);
                        } else if(j == 1) {
                          setoa_skill_category_2(regions.regions.oa_skill_categories[i]);
                        } else {
                          setoa_skill_category_3(regions.regions.oa_skill_categories[i]);
                        }
                      }
                    }
                  }
                }
                // スキル - PC
                if(queryParamsAll.has('oa_skill[]')) {
                  japaneseParams.push('スキル - PC：')
                }
                if(queryParamsAll.has('oa_skill[]')) {
                  for(let i = 0; i < regions.regions.oa_skills.length; i++) {
                    for(let j = 0; j < queryParamsAll.getAll('oa_skill[]').length; j++) {
                      if(queryParamsAll.getAll('oa_skill[]')[j] == regions.regions.oa_skills[i].id) {
                        japaneseParams.push(regions.regions.oa_skills[i].name + ', ')
                        if(j == 0) {
                          setoa_skill_1(regions.regions.oa_skills[i]);
                        } else if(j == 1) {
                          setoa_skill_2(regions.regions.oa_skills[i]);
                        } else {
                          setoa_skill_3(regions.regions.oa_skills[i]);
                        }
                      }
                    }
                  }
                }
                // スキル - 語学
                if(queryParamsAll.has('language[]')) {
                  japaneseParams.push('スキル - 語学：')
                }
                if(queryParamsAll.has('language[]')) {
                  for(let i = 0; i < regions.regions.languages.length; i++) {
                    for(let j = 0; j < queryParamsAll.getAll('language[]').length; j++) {
                      if(queryParamsAll.getAll('language[]')[j] == regions.regions.languages[i].id) {
                        japaneseParams.push(regions.regions.languages[i].name + ', ')
                        if(j == 0) {
                          setlanguage_1(regions.regions.languages[i]);
                        } else if(j == 1) {
                          setlanguage_2(regions.regions.languages[i]);
                        } else {
                          setlanguage_3(regions.regions.languages[i]);
                        }
                      }
                    }
                  }
                }
                // スキル - 語学レベル
                if(queryParamsAll.has('level[]')) {
                  japaneseParams.push('スキル - 語学レベル：')
                }
                if(queryParamsAll.has('level[]')) {
                  for(let i = 0; i < regions.regions.levels.length; i++) {
                    for(let j = 0; j < queryParamsAll.getAll('level[]').length; j++) {
                      if(queryParamsAll.getAll('level[]')[j] == regions.regions.levels[i].id) {
                        japaneseParams.push(regions.regions.levels[i].name + ', ')
                        if(j == 0) {
                          setlevel_1(regions.regions.levels[i]);
                        } else if(j == 1) {
                          setlevel_2(regions.regions.levels[i]);
                        } else {
                          setlevel_3(regions.regions.levels[i]);
                        }
                      }
                    }
                  }
                }
                // スキル - 資格分類
                if(queryParamsAll.has('qualification_category[]')) {
                  japaneseParams.push('スキル - 資格分類：')
                }
                if(queryParamsAll.has('qualification_category[]')) {
                  for(let i = 0; i < regions.regions.qualification_categories.length; i++) {
                    for(let j = 0; j < queryParamsAll.getAll('qualification_category[]').length; j++) {
                      if(queryParamsAll.getAll('qualification_category[]')[j] == regions.regions.qualification_categories[i].id) {
                        japaneseParams.push(regions.regions.qualification_categories[i].name + ', ')
                        if(j == 0) {
                          setqualification_category_1(regions.regions.qualification_categories[i]);
                        } else if(j == 1) {
                          setqualification_category_2(regions.regions.qualification_categories[i]);
                        } else {
                          setqualification_category_3(regions.regions.qualification_categories[i]);
                        }
                      }
                    }
                  }
                }
                // スキル - 資格
                if(queryParamsAll.has('qualification[]')) {
                  japaneseParams.push('スキル - 資格：')
                }
                if(queryParamsAll.has('qualification[]')) {
                  for(let i = 0; i < regions.regions.qualifications.length; i++) {
                    for(let j = 0; j < queryParamsAll.getAll('qualification[]').length; j++) {
                      if(queryParamsAll.getAll('qualification[]')[j] == regions.regions.qualifications[i].id) {
                        japaneseParams.push(regions.regions.qualifications[i].name + ', ')
                        if(j == 0) {
                          setqualification_1(regions.regions.qualifications[i]);
                        } else if(j == 1) {
                          setqualification_2(regions.regions.qualifications[i]);
                        } else {
                          setqualification_3(regions.regions.qualifications[i]);
                        }
                      }
                    }
                  }
                }
                setJapaneseParamsObject(japaneseParams);
                setQueryParams(queryParamsAll.toString());
                setShowSearchMain(false);
              }
            }
            // setLoadScreen(false);
        }
      }, [regions]);


    function logout() {
        if (localStorage.entryFlag) {
            localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
            localStorage.removeItem('jobParachannelAuthToken')
            navigate('/');
            window.location.reload();
        }
    }

    function loadSavedConditions() {
      // setIsLoadSavedConditions(true);
      navigate('/recruiter/scouts/saved_conditions', {state: {queryParamString: queryParamsAll.toString()}});
    }

    useEffect(() => {
      if (scoutsSavedConditions.pending && isSaveParameters) {
          setLoadScreen(false);
          if(notifyWhenApplicantFinded) {
            window.confirm("この条件に合う人材が登録されたら通知を受け取るよう設定いたしました。\n登録されるまで今暫くお待ちください。");
          } else {
            window.alert("現在の検索条件を保存しました");
          }
      } else if(scoutsSavedConditions.error != null) {
        setLoadScreen(false);
        window.alert("検索条件の保存に失敗しました");
      }
    }, [scoutsSavedConditions]);

    function searchDetail(bool: any) {
      setSearchDetail(bool);
      window.scrollTo({ top: 0 });
      if(bool) {
        setmySelecedPrefectures(prefecture_ids);
        if (prefecture_ids.length == 0) { return }
        prefectures.forEach(v => {
          if (prefecture_ids.includes(v.id)) {
            // $refs[`prefectures_${v.id}`][0].checked = true
            // $refs[`regions_${v.region_id}`][0].checked = true
            // setmySelectRegions(mySelectRegions.splice((v.region_id - 1), 1, true));
          }
        })
      }
    }

    function saveParameters(notifyWhenApplicantFinded1: any ) {
      let query = createParameter();
      let params = localStorage.getItem('japaneseParams');
      if (params == '' || params == null) {
        window.alert("検索条件を指定してください");
        // return
      } else {

        // 条件保存の場合はpage/sortを除外
        const saved = new URLSearchParams(query)
        saved.delete('page')
        saved.delete('sort')
        const savedParams = saved.toString()
        const data = {
          name: params,
          params: savedParams,
          notify_when_applicant_finded: notifyWhenApplicantFinded1
        }
        setNotify(notifyWhenApplicantFinded1);
        setLoadScreen(true);
        setIsSaveParameters(true);
        dispatch(fetchScoutsSavedConditionsRequest(data, 'post'));
      }
    }

    function createParameter () {
      const queryParams = new URLSearchParams()
      let japaneseParams : any = [] 
      // let japaneseParamsObject: any = {}
      // let handicap_types_list = []
      // let oa_skill_categories_list = []
      // let oa_skills_list = []
      // let qualification_categories_list = []
      // let qualifications_list = []
      // let languages_list = []
      // let levels_list = []

      // 障がい種別
      if (handicap_type1 || handicap_type2) {
        japaneseParams.push('障がい種別：');
      } 
      if (handicap_type1) {
        queryParams.append('handicap_type_ids[]', handicap_type1.id);
        japaneseParams.push(handicap_type1.name + ', ');
      }
      if (handicap_type2) {
        queryParams.append('handicap_type_ids[]', handicap_type2.id);
        japaneseParams.push(handicap_type2.name + ', ');
      }

      // 障がい者手帳有無
      if (disability_certificate_status) {
        queryParams.append('disability_certificate_status', disability_certificate_status.id);
        japaneseParams.push('障がい者手帳有無：' + disability_certificate_status.name + ', ');
      }

      // 就労支援有無
      if (employment_support_agency) {
        queryParams.append('employment_support_agency', employment_support_agency.id);
        japaneseParams.push('就労支援有無：' + employment_support_agency.name + ', ');
      }

      // 就職カテゴリー
      if (employment_status) {
        queryParams.append('employment_status', employment_status.id);
        japaneseParams.push('就職カテゴリー：' + employment_status.name + ', ');
      }

      // 職種
      if (job_category_type) {
        queryParams.append('job_category_type', job_category_type.id);
        japaneseParams.push('職種分類：' + job_category_type.name + ', ');
      }

      if (job_category) {
        queryParams.append('job_category', job_category.id);
        japaneseParams.push('職種：' + job_category.name + ', ');
      }

      // 勤務地
      // ???
      if (selectedPrefectures.length > 0) {
        // japaneseParams.push('勤務地：');
        
        
        // let prefecture_names: any[] = [];
        selectedPrefectures.forEach(function(prefecture_id) { 
          queryParams.append('prefecture_ids[]', prefecture_id);
          let name_key = Number(prefecture_id - 1);
          let prefecture = prefectures[name_key];
          japaneseParams.push('勤務地：'+ prefecture.name + ', ');
          // japanese_Params.push('勤務地' + ', ' + prefecture.name);
          // prefecture_names.push(prefecture.name);
        });
        // japaneseParamsObject['勤務地'] = prefecture_names.join(",");
      }

      // 就労時間
      if (working_hour) {
        queryParams.append('working_hour', working_hour.id);
        japaneseParams.push('就労時間：' + working_hour.name + ', ');
      }

      // 在宅希望
      if (work_from_home) {
        queryParams.append('work_from_home', work_from_home.id);
        japaneseParams.push('在宅希望：' + work_from_home.name + ', ');
      }

      // スキル - PC分類
      if (oa_skill_category_1 || oa_skill_category_2 || oa_skill_category_3) {
        japaneseParams.push('スキル - PC分類：');
        
      }
      if (oa_skill_category_1) {
        queryParams.append('oa_skill_category[]', oa_skill_category_1.id);
        japaneseParams.push(oa_skill_category_1.name + ', ');
      }

      if (oa_skill_category_2) {        
        queryParams.append('oa_skill_category[]', oa_skill_category_2.id);
        japaneseParams.push(oa_skill_category_2.name + ', ');
      }

      if (oa_skill_category_3) {        
        queryParams.append('oa_skill_category[]', oa_skill_category_3.id);
        japaneseParams.push( oa_skill_category_3.name + ', ');
      }
      // if (oa_skill_category_1 || oa_skill_category_2 || oa_skill_category_3) {
      //   japaneseParamsObject['スキル - PC分類'] = oa_skill_categories_list.join(",")
      // }

      // スキル - PC
      if (oa_skill_1 || oa_skill_2 || oa_skill_3) {
        japaneseParams.push('スキル - PC：');
      }

      if (oa_skill_1) {
        queryParams.append('oa_skill[]', oa_skill_1.id);
        japaneseParams.push(oa_skill_1.name + ', ');
      }

      if (oa_skill_2) {
        queryParams.append('oa_skill[]', oa_skill_2.id);
        japaneseParams.push(oa_skill_2.name + ', ');
      }

      if (oa_skill_3) {
        queryParams.append('oa_skill[]', oa_skill_2.id);
        japaneseParams.push(oa_skill_2.name + ', ');
      }
      // if (oa_skill_1 || oa_skill_2 || oa_skill_3) {
      //   japaneseParamsObject['スキル - PC'] = oa_skills_list.join(",")
      // }

      // スキル - 語学
      if (language_1 || language_2 || language_3) {
        japaneseParams.push('スキル - 語学：');
      }

      if (language_1) {
        queryParams.append('language[]', language_1.id);
        japaneseParams.push(language_1.name + ', ');
      }

      if (language_2) {          
        queryParams.append('language[]', language_2.id);
        japaneseParams.push(language_2.name + ', ');
      }

      if (language_3) {
        queryParams.append('language[]', language_3.id);
        japaneseParams.push(language_3.name + ', ');
      }
      // if (language_1 || language_2 || language_3) {
      //   japaneseParamsObject['スキル - 語学'] = languages_list.join(",")
      // }

      // スキル - 語学レベル
      if (level_1 || level_2 || level_3) {
        japaneseParams.push('スキル - 語学レベル：')
      }

      if (level_1) {
        queryParams.append('level[]', level_1.id);
        japaneseParams.push(level_1.name + ', ');
      }

      if (level_2) {
        queryParams.append('level[]', level_2.id);
        japaneseParams.push(level_2.name + ', ');
      }
      
      if (level_3) {
        queryParams.append('level[]', level_3.id);
        japaneseParams.push(level_3.name + ', ');
      }
      // if (level_1 || level_2 || level_3) {
      //   japaneseParamsObject['スキル - 語学レベル'] = levels_list.join(",")
      // }

      // スキル - 資格分類
      if (qualification_category_1 || qualification_category_2 || qualification_category_3) {
        japaneseParams.push('スキル - 資格分類：')
      }
      if (qualification_category_1) {
        queryParams.append('qualification_category[]', qualification_category_1.id)
        japaneseParams.push(qualification_category_1.name + ', ')
        // qualification_categories_list.push(qualification_category_1.name)
      }
      if (qualification_category_2) {
        queryParams.append('qualification_category[]', qualification_category_2.id)
        japaneseParams.push(qualification_category_2.name + ', ')
        // qualification_categories_list.push(qualification_category_2.name)
      }
      if (qualification_category_3) {
        queryParams.append('qualification_category[]', qualification_category_3.id)
        japaneseParams.push(qualification_category_3.name + ', ')
        // qualification_categories_list.push(qualification_category_3.name)
      }
      // if (qualification_category_1 || qualification_category_2 || qualification_category_3) {
      //   japaneseParamsObject['スキル - 資格分類'] = qualification_categories_list.join(",")
      // }

      // スキル - 資格
      if (qualification_1 || qualification_2 || qualification_3) {
        japaneseParams.push('スキル - 資格：')
      }
      if (qualification_1) {
        queryParams.append('qualification[]', qualification_1.id)
        japaneseParams.push(qualification_1.name + ', ')
        // qualifications_list.push(qualification_1.name)
      }
      if (qualification_2) {
        queryParams.append('qualification[]', qualification_2.id)
        japaneseParams.push(qualification_2.name + ', ')
        // qualifications_list.push(qualification_2.name)
      }
      if (qualification_3) {
        queryParams.append('qualification[]', qualification_3.id)
        japaneseParams.push(qualification_3.name + ', ')
        // qualifications_list.push(qualification_3.name)
      }
      // if (qualification_1 || qualification_2 || qualification_3) {
      //   japaneseParamsObject['スキル - 資格'] = qualifications_list.join(",")
      // }

      let queryParams2 = queryParams.toString()
      japaneseParams = japaneseParams.join("")
      // japaneseParamsObject = japaneseParamsObject
      // 日本語パラメーター文字列の末尾の", "をトル
      let endStr = japaneseParams.slice(-2)
      if (endStr.toString() === ', ') { japaneseParams = japaneseParams.slice(0, -2) }
      localStorage.setItem('japaneseParams', japaneseParams);
      return queryParams2;
    }

    function clear () {
      setHandicapType1('');
      setHandicapType2('');
      setDisabilityCertificateStatus('');
      setEmploymentSupportAgency('');
      setEmploymentStatus(null);
      setjob_category_type(null);
      setjob_category(null);
      setworking_hour(null);
      setwork_from_home(null);
      setoa_skill_1('');
      setoa_skill_2('');
      setoa_skill_3('');
      setoa_skill_category_1('');
      setoa_skill_category_2('');
      setoa_skill_category_3('');
      setlanguage_1('');
      setlanguage_2('');
      setlanguage_3('');
      setlevel_1('');
      setlevel_2('');
      setlevel_3('');
      setqualification_1(null);
      setqualification_2(null);
      setqualification_3(null);
      setqualification_category_1(null);
      setqualification_category_2(null);
      setqualification_category_3(null);
      setoa_skill_loop_count(1);
      setlanguage_loop_count(1);

      for(let i = 1; i < 4; i++) {
        let key = "oa_skill_" + i;
        const selectedCategory = document.getElementsByName(key)[0];
        if(selectedCategory != undefined) {
          selectedCategory.classList.add('dnone');
          selectedCategory.classList.remove('dshow');
        }
      }
      for(let i = 1; i < 4; i++) {
        let key = "level_" + i;
        const selectedCategory = document.getElementsByName(key)[0];
        if(selectedCategory != undefined) {
          selectedCategory.classList.add('dnone');
          selectedCategory.classList.remove('dshow');
        }
      }
      // 勤務地
      if (!showSearchDetail) {
        setPrefectureIds([]);
        setIsPrefectureCheck(false);
      } else {
        if (isPrefectureCheck) {
          document.getElementsByName("prefecture_all")[0].click()
        } else {
          // document.getElementsByName("prefecture_all")[0].click()
          // document.getElementsByName("prefecture_all")[0].click()
        }
      }

      setShowSearchMain(true);
    }

    const shaping_pagenation = () => {
        if (total_pages === null) return []; // Add a check for total_pages being null
    
        if (total_pages <= 11) {
          return Array.from({ length: total_pages }, (_, index) => index + 1);
        } else if (current_page < 7) {
          return Array.from({ length: 11 }, (_, index) => index + 1);
        } else if (total_pages - current_page <= 5) {
          const diff_current_total = total_pages - current_page;
          const pagenation_start = current_page - (11 - (diff_current_total + 1));
          const pagenation_end = current_page + diff_current_total;
          return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
        } else {
          const pagenation_start = current_page - 5;
          const pagenation_end = current_page + 5;
          return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
        }
    };
    
    function toggleCheckAll () {
      if (!allCheck) {
        setAllcheck(true);
        // 全ての求職者のIDを候補者保存API用配列に入れる
        let c = selectedApplicantIds;
        applicants.map((obj: any) => {
            c.push(obj.id);
        });
        setSelectedApplicantIDs(c);
        // 全ての求職者のオブジェクトをスカウト候補画面遷移時パラメータ用配列に入れる
        setSelectedApplicants(applicants.slice());
      } else {
          setAllcheck(false);
          // checkbox表示用を空にする
          setSelectedApplicantIDs([]);
          // データ送信時用を空にする
          setSelectedApplicants([]);
      }
    }

    function checkSelect(id: any) {
      let c = selectedApplicantIds;
      for(let i = 0; i < c.length; i++) {
          if(c[i] == id) {
              return true;
          }
      }
      return false;
    }

    function handleClick () {

    }
    
    function toggleCheckSelectedApplicants(app: any) {
        let c = [];
        let c2 = [];
        let index = -1;
        for(let i = 0; i < selectedApplicants.length; i++) {
            c.push(selectedApplicants[i]);
        }
        for(let j = 0; j < selectedApplicantIds.length; j++) {
            c2.push(selectedApplicantIds[j]);
        }
        for(let k = 0; k < c2.length; k++) {
            if(c2[k] === app.id) {                
                index = k;
            }
        }
        if (index !== -1) {
          // 求職者1人削除
          c.splice(index, 1);
          c2.splice(index, 1);
        } else {
          // 求職者1人追加
          c.push(app);
          c2.push(app.id);
        }
        setSelectedApplicants(c);
        setSelectedApplicantIDs(c2);
    }

    function filterApplicants () {
      setSearchDetail(false);
      setShowSearchMain(false);      
      // 検索をかけたらpageとsortは初期化
      // TODO 変な挙動する時がある pagerで2ページ目行ってから別の絞り込みをしたとき
      setPage(1);
      setSort('');
      
      let query : any = [] = createParameter();
      if(isPrefectureCheck) {
        query = query + '&pre_all=true';
      }
      navigate('/recruiter/scouts/?' + query);
      window.location.reload();
    }    

    // function checkSelectedHandicapType (value: any) {
    //   if (handicap_type1 != null && handicap_type1.id == value ) { return true }
    //   if (handicap_type2 != null && handicap_type2.id == value ) { return true }
    //   return true
    // }

    function addOaSkillLoopCount() {
      setoa_skill_loop_count(oa_skill_loop_count+1);
    }

    function addLanguageLoopCount() {
      setlanguage_loop_count(language_loop_count+1);
    }

    function filterOaSkills (id: any) {
      let selectedCategory : any = null;
      if(id == 1) {
        selectedCategory = oa_skills.filter( v => v.oa_skill_category_id == oa_skill_category_1.id) 
      } else if(id == 2) {
        selectedCategory = oa_skills.filter( v => v.oa_skill_category_id == oa_skill_category_2.id) 
      } else {
        selectedCategory = oa_skills.filter( v => v.oa_skill_category_id == oa_skill_category_3.id) 
      }
      if (!selectedCategory) { return [] }
      return selectedCategory
    }

    function filterJobCategories () {
      const selectedCategory = job_category_type;
      if (!selectedCategory) { return [] }
      return job_categories.filter( v => v.job_category_type_id == selectedCategory.id)
    }

    function filterQualifications (id: any) {
      let selectedCategory : any = null;
      if(id == 1) {
        selectedCategory = qualification_category_1
      } else if(id == 2) {
        selectedCategory = qualification_category_2
      } else {
        selectedCategory = qualification_category_3
      }
      if (!selectedCategory) { return [] }
      return qualifications.filter( v => v.qualification_category_id == selectedCategory.id)
    }

    /**
     * 全国のチェックボックス
     * componentsをいじれなかったので無理矢理実装
     */
    function checkPrefectureAll (e: any) {
      let checkInfo = e.target.checked;
      let len = regionsArr.length - 1;
      if (checkInfo == true && isPrefectureCheck == false) {
        setIsPrefectureCheck(checkInfo);
        
        for (let i = 0; i <= len; i++) {
          if(!document.getElementsByClassName("area")[0].getElementsByTagName("input")[i].checked) {
            document.getElementsByClassName("area")[0].getElementsByTagName("input")[i].click()
          }
        }
        let c = [];
        for (let i = 0; i < regionsArr.length; i++) {
          c.push(regionsArr[i].id.toString());
        }
        let p = [];
        for (let i = 0; i < prefectures.length; i++) {
          p.push(prefectures[i].id);
        }
        setMySelectedPrefectures(c);
        setSelectedPrefectures(p);
      } else {
        setIsPrefectureCheck(checkInfo);
        for (let i = 0; i <= len; i++) {
          if(document.getElementsByClassName("area")[0].getElementsByTagName("input")[i].checked) {
            document.getElementsByClassName("area")[0].getElementsByTagName("input")[i].click()
          }
        }
        setMySelectedPrefectures([]);
        setSelectedPrefectures([]);
      }
      if (checkInfo == true && isPrefectureCheck == false) {
        setIsPrefectureCheck(checkInfo);
      } else {
        setIsPrefectureCheck(checkInfo);
      }
    }

    const pageIncrement = () => {
      if (page >= total_pages) {
        setPage(total_pages);
        return;
      }
      setPage((prevPage) => prevPage + 1);
      setLoadScreen(true);
      if(queryParamsAll.toString() != '') {
        dispatch(fetchScoutsApplicantsRequest(queryParamsAll.toString() + '&page=' + (page+1)));
      } else {
        dispatch(fetchScoutsApplicantsRequest('page=' + (page+1) + '&sort=' + sort + '&per=30'));
      }
    }

    const pageDecrement = () => {
        if (page <= 1) {
            setPage(1);
            return;
        }
        setPage((prevPage) => prevPage - 1);
        setLoadScreen(true);
        if(queryParamsAll.toString() != '') {
          dispatch(fetchScoutsApplicantsRequest(queryParamsAll.toString() + '&page=' + (page-1)));
        } else {
          dispatch(fetchScoutsApplicantsRequest('page=' + (page-1) + '&sort=' + sort + '&per=30'));
        }
    }

    const updatePage = (p: any) => {
        setPage(p);
        setLoadScreen(true);
        if(queryParamsAll.toString() != '') {
          dispatch(fetchScoutsApplicantsRequest(queryParamsAll.toString() + '&page=' + p));
        } else {
          dispatch(fetchScoutsApplicantsRequest('page=' + p + '&sort=' + sort + '&per=30'));
        }
    }

    const changeSort = (p: any) => {
      setSort(p);
      setLoadScreen(true);
      if(queryParamsAll.toString() != '') {
        dispatch(fetchScoutsApplicantsRequest(queryParamsAll.toString() + '&sort=' + p));
      } else {
        dispatch(fetchScoutsApplicantsRequest('page=' + page + '&sort=' + p + '&per=30'));
      }
    }

    function isCandidatePage() {
      return window.location.pathname.indexOf('candidate') != -1;
    }

    /**
     * 求職者詳細ページの時true、そうでない時false
     **/
    function checkApplicantDetailsPage() {
        return window.location.pathname.search(/scouts\/applicant\/\d+$/g) != -1
    }

    function saveCandidates(selectedApplicantId: any, app: any) {
      // スカウト候補に追加処理が求職者一覧と詳細で異なる
      // 一覧: 追加後画面遷移なし、ボタンを押せなくする
      // 詳細: 追加後画面遷移
      // 一覧でボタンを押せなくするため求職者一覧オブジェクトをstoreで持ちたい
      // store/scouts/index.jsで持って、URLを元に同モジュールのスカウト候補に追加処理で分岐させても良いが
      // 求職者一覧ページでしか使わない処理や求職者一覧オブジェクトをそこに書くのは混乱を招きそうなので
      // ページ単位でstoreのファイルを分けて、そこから共通の処理であるスカウト候補に追加処理が書かれてるscouts/index.jsのsaveCandidates()を呼ぶことにした
      if (!isCandidatePage()) {
          // 求職者一覧ページの時
          if(selectedApplicantId != 0) {
            setSelectedAppIds([selectedApplicantId]);
          } else {
            setSelectedAppIds(selectedApplicantIds);
          }
          dispatch(fetchScoutsCandidateRequest('', 'post', selectedApplicantId != 0 ? [selectedApplicantId] : selectedApplicantIds));
      }
    }

    useEffect(() => {
      if (candidates.pending) {
          // 配列を値渡しでコピー
          let applicantsData: any = [];
          // 対象求職者のスカウト候補ボタンを押せないようにする
          for(let i = 0; i < applicants.length; i++) {
            applicantsData.push(applicants[i]);
          }
          for(let j = 0; j < applicantsData.length; j++) {
            for(let l = 0; l < selectedAppIds.length; l++) {
              if(applicantsData[j].id == selectedAppIds[l]) {
                applicantsData[j].include_scout_candidate = true
              }
            }
          }
          setApplicants(applicantsData);
          window.alert('追加しました')
      } else if(candidates.error != null) {
        setLoadScreen(false);
        navigate('/error?code=500');
      }
    }, [candidates]);

    function loadEntry(app: any) {
      if (selectedApplicants.length > limitCount) {
        window.alert('本日のスカウト可能残数を超えています')
        return
      }
      navigate('/recruiter/scouts/entry/', {state: {applicants: [app], id: app.id, queryParamString: queryParamsAll.toString()}});
    }

  function updateValuePrefectures(e: any, index: any) {
    let c = [];
    for (let i = 0; i < mySelectedPrefectures.length; i++) {
      c.push(mySelectedPrefectures[i]);
    }
    let chk = false;
    for (let i = 0; i < c.length; i++) {
      if (c[i] == e.target.value) {
        chk = true;
        index = i;
      }
    }
    if (!chk) {
      c.push(e.target.value);
      prefectures
        .filter((data) => e.target.value.includes(data.region_id.toString()))
        .map((i: any) => selectedPrefectures.push(i.id));
    } else {
      c.splice(index, 1);
      prefectures
        .filter((data) => e.target.value.includes(data.region_id.toString()))
        .forEach((i) => {
          const indexToRemove = selectedPrefectures.indexOf(i.id);
          if (indexToRemove !== -1) {
            selectedPrefectures.splice(indexToRemove, 1);
          }
        });
    }
    setMySelectedPrefectures(c);
    setIsPrefectureCheck(false);
  }
  function updateValuePrefectures2(e: any, id: any) {
    setSelectedPrefectures((prevSelectedPrefectures) => {
      const newValue = id;
      const isSelected = prevSelectedPrefectures.includes(newValue);

      if (isSelected) {
        const updatedPrefectures = prevSelectedPrefectures.filter(
          (item: any) => item != newValue
        );
        return updatedPrefectures;
      } else {        
        const updatedPrefectures = [...prevSelectedPrefectures, newValue];
        return updatedPrefectures;
      }
    });
    setIsPrefectureCheck(false);
  }

    function setOaSkillById(val: any, id: any) {
      if(id == 1) {
        setoa_skill_1(JSON.parse(val));
      } else if(id == 2) {
        setoa_skill_2(JSON.parse(val));
      } else if(id == 3) {
        setoa_skill_3(JSON.parse(val));
      }
    }

    function setLevelById(val: any, id: any) {
      if(id == 1) {
        setlevel_1(JSON.parse(val));
      } else if(id == 2) {
        setlevel_2(JSON.parse(val));
      } else if(id == 3) {
        setlevel_3(JSON.parse(val));
      }
    }

    function changeQualification(val: any, name: any, id: any) {
      if(name == 'category') {
        if(id == 1) {
          setqualification_category_1(JSON.parse(val));
        } else if(id == 2) {
          setqualification_category_2(JSON.parse(val));
        } else if(id == 3) {
          setqualification_category_3(JSON.parse(val));
        }
      } else {
        if(id == 1) {
          setqualification_1(JSON.parse(val));
        } else if(id == 2) {
          setqualification_2(JSON.parse(val));
        } else if(id == 3) {
          setqualification_3(JSON.parse(val));
        }
      }
    }

    function changeRadio(val: any, name: any, id: any) {
      if(name == 'oa_skill') {
        let key = "oa_skill_" + id
        const selectedCategory = document.getElementsByName(key)[0];
        selectedCategory.classList.remove('dnone');
        selectedCategory.classList.add('dshow');   
        let data = JSON.parse(val)

        if(id == 1) {
          setoa_skill_category_1(JSON.parse(val));
          if(data.name == 'WORD'){
            setoa_skill_1(oa_skills[0]);
          } else if (data.name == 'EXCEL') {
            setoa_skill_1(oa_skills[3]);
          } else if (data.name == "デザイン系・その他事務系") {
            setoa_skill_1(oa_skills[7]);
          } else {
            setoa_skill_1(oa_skills[12]);
          }
        } else if(id == 2) {
          setoa_skill_category_2(JSON.parse(val));
          if(data.name == 'WORD'){
            setoa_skill_1(oa_skills[0]);
          } else if (data.name == 'EXCEL') {
            setoa_skill_1(oa_skills[3]);
          } else if (data.name == "デザイン系・その他事務系") {
            setoa_skill_1(oa_skills[7]);
          } else {
            setoa_skill_1(oa_skills[12]);
          }
        } else if(id == 3) {
          setoa_skill_category_3(JSON.parse(val));
          if(data.name == 'WORD'){
            setoa_skill_1(oa_skills[0]);
          } else if (data.name == 'EXCEL') {
            setoa_skill_1(oa_skills[3]);
          } else if (data.name == "デザイン系・その他事務系") {
            setoa_skill_1(oa_skills[7]);
          } else {
            setoa_skill_1(oa_skills[12]);
          }
        }       
      }
      if(name == 'level') {
        let key = "level_" + id;
        const selectedCategory = document.getElementsByName(key)[0];
        selectedCategory.classList.remove('dnone');
        selectedCategory.classList.add('dshow');
        if(id == 1) {
          setlanguage_1(JSON.parse(val));
        } else if(id == 2) {
          setlanguage_2(JSON.parse(val));
        } else if(id == 3) {
          setlanguage_3(JSON.parse(val));
        }
      }
    }

    function changeHandicap (event: any) {
      const {name, value} = event.target;   
      const filterHandCaps = [];
      if(name == 'handicap_type1') {
        if(value != 0) {       
          let val = JSON.parse(value);
          setHandicapType1(val);
          for (let i = 0; i < handicaps.length; i++) {
            if(val.id == handicaps[i].handicap_type_id){
                filterHandCaps.push({id: handicaps[i].id, handicap_type_id: handicaps[i].handicap_type_id, name: handicaps[i].name,});
            }
          }
          setFilterHandicapType(filterHandCaps);
        }
      }  
      if(name == 'handicap_type2') {
        if(value != 0) {            
          let val2 = JSON.parse(value);
          setHandicapType2(val2);
        }
      }   
    }
      

    return (
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className ="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">スカウト</h2>
                <ul>
                <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            {showSearchDetail ?
            <div id="scouts" className="saved_conditions"> 
                <a onClick={() => searchDetail(false)}><img src="/assets/img/company/icon06.svg" alt=""/>戻る</a>
                <h3>検索条件の設定</h3>
                <form>
                  <dl>
                    <dt>障がい種別</dt>
                    <dd>
                      <div className="flex_box">
                        {/* <select name="handicap_type1" v-model="handicap_type1" className="type2" onChange={(e) => setHandicapType1(JSON.parse(e.target.value))}> */}
                        <select name="handicap_type1" className="type2" onChange={changeHandicap}>
                          <option value='' selected hidden>選択してください</option>
                          {handicap_types && handicap_types.map((data: any, index: any) => (
                              // <>
                              // {checkSelectedHandicapType(data.id) ?
                              <option selected={JSON.stringify(data) == JSON.stringify(handicap_type1)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                            // : <></>}</>
                            ))}
                        </select>
                        <select name="handicap_type2" className="type2" onChange={changeHandicap}>
                          <option value='' selected hidden>選択してください</option>
                          {filterHandicapType && filterHandicapType.map((data: any, index: any) => (
                              // <>
                              // {checkSelectedHandicapType(data.id) ?
                              <option selected={JSON.stringify(data) == JSON.stringify(handicap_type2)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                            // : <></>}</>
                            ))}
                        </select>
                      </div>
                      <span>※複数設定可</span>
                    </dd>
                    <div className="half">
                      <dt>障がい者手帳有無</dt>
                      <dd>
                        <select name="disability_certificate_status" v-model="disability_certificate_status" className="type1" onChange={(e) => setDisabilityCertificateStatus(JSON.parse(e.target.value))}>
                            <option value="" selected hidden></option>
                            {disability_certificate_statuses && disability_certificate_statuses.map((disability_certificate_status1: any, index: any) => (
                              <option selected={JSON.stringify(disability_certificate_status1) == JSON.stringify(disability_certificate_status)} key={disability_certificate_status1.id} value={JSON.stringify(disability_certificate_status1)}>{disability_certificate_status1.name}</option>
                            ))}
                        </select>
                      </dd>
                    </div>
                    <div className="half">
                      <dt>就労支援有無</dt>
                      <dd>
                        <select name="employment_support_agency" v-model="employment_support_agency" className="type1" onChange={(e) => setEmploymentSupportAgency(JSON.parse(e.target.value))}>
                          <option value="" selected hidden></option>
                          {employment_support_agencies && employment_support_agencies.map((employment_support_agency1: any, index: any) => (
                            <option selected={JSON.stringify(employment_support_agency1) == JSON.stringify(employment_support_agency)} key={employment_support_agency1.id} value={JSON.stringify(employment_support_agency1)}>{employment_support_agency1.name}</option>
                          ))}
                          </select>
                      </dd>
                    </div>
                    <div className="half">
                      <dt>就職カテゴリー</dt>
                      <dd>
                        <select name="employment_status" v-model="employment_status" className="type2" onChange={(e) => setEmploymentStatus(JSON.parse(e.target.value))}>
                          <option value="" selected hidden></option>
                          {employment_statuses && employment_statuses.map((data: any, index: any) => (
                            <option selected={JSON.stringify(data) == JSON.stringify(employment_status)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                          ))}
                        </select>
                      </dd>
                    </div>
                    <div className="half">
                      <dt>職種</dt>
                      <dd>
                        <select name="job_category_type" v-model="job_category_type" className="type2" onChange={(e) => setjob_category_type(JSON.parse(e.target.value))}>
                          <option value="" selected hidden></option>
                          {job_category_types && job_category_types.map((data: any, index: any) => (
                            <option selected={JSON.stringify(data) == JSON.stringify(job_category_type)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                          ))}
                        </select>
                        <select name="job_category" v-model="job_category" className="type3" onChange={(e) => setjob_category(JSON.parse(e.target.value))}>
                          <option value="" selected hidden></option>
                          {filterJobCategories().map((data: any, index: any) => (
                            <option selected={JSON.stringify(data) == JSON.stringify(job_category)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                          ))}
                        </select>
                      </dd>
                    </div>
                    <dt>勤務地</dt>
                    <dd>
                      <div className="checkbox_box">
                        <label>
                          <input type="checkbox" id="isPrefectureCheck" onClick={checkPrefectureAll} name="prefecture_all" checked={isPrefectureCheck ? true : false}/>全国
                        </label>                        
                        <div className="checkbox_list area">
                          {regionsArr.map((i: any, index: any) => (
                              <label key={i.id} style={{marginBottom: "10px"}}>
                                <input
                                  type="checkbox"
                                  defaultChecked={prefectures.some(
                                    (prefecture) =>
                                      selectedPrefectures.includes(
                                        prefecture.id
                                      ) && prefecture.region_id == i.id
                                  )}
                                  onChange={(e) =>
                                    {updateValuePrefectures(e, index);}
                                  }
                                  value={i.id}
                                />
                                {i.name}
                                <br />
                              </label>
                            ))}
                            {mySelectedPrefectures.length != 0 && (
                              <div className="checkbox_list area area_child _flex">
                                {prefectures
                                  .filter((data) =>
                                    mySelectedPrefectures.includes(
                                      data.region_id.toString()
                                    )
                                  )
                                  .map((i: any, index: any) => (
                                    <label key={i.id} 
                                      style={{whiteSpace: "nowrap"}}>
                                      <input
                                        type="checkbox"
                                        defaultChecked={selectedPrefectures.includes(i.id)}
                                        onChange={(e) =>
                                          {updateValuePrefectures2(e, i.id);}
                                        }
                                        value={i.id}
                                      />
                                      {i.name}
                                      <br />
                                    </label>
                                  ))}
                              </div>
                            )}
                        </div>
                      </div>
                    </dd>
                    <div className="half">
                      <dt>就労時間</dt>
                      <dd>
                        <select name="working_hour" v-model="working_hour" className="type2" onChange={(e) => setworking_hour(JSON.parse(e.target.value))}>
                          <option value="" selected hidden></option>
                          {working_hours && working_hours.map((data: any, index: any) => (
                            <option selected={JSON.stringify(data) == JSON.stringify(working_hour)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                          ))}
                        </select>
                      </dd>
                    </div>
                    <div className="half">
                      <dt>在宅希望</dt>
                      <dd>
                        <select name="work_from_home" className="type2" onChange={(e) => setwork_from_home(JSON.parse(e.target.value))}>
                          <option value="" selected hidden></option>
                          {work_from_homes && work_from_homes.map((data: any, index: any) => (
                            <option selected={JSON.stringify(data) == JSON.stringify(work_from_home)} key={data.id} value={JSON.stringify(data)}>{data.name2}</option>
                          ))}
                        </select>
                      </dd>
                    </div>
                    <dt>スキル - PC</dt>
                    <dd>
                      <div className="skill_box">
                        {oa_skill_loop_count && Array.from({length: oa_skill_loop_count}, (v, k) => k+1).map((int: any, index: any) => (
                          <>
                            <div className="radio_box">
                              {oa_skill_categories && oa_skill_categories.map((data: any, index: any) => (
                                <>
                                    {int == 1 ?                   
                                      <label>
                                        <input type="radio" checked={JSON.stringify(oa_skill_category_1) == JSON.stringify(data)} name={'oa_skill_category_' + int} key={data.id} value={JSON.stringify(data)} 
                                        onChange={(e) => changeRadio(e.target.value, 'oa_skill', int)}/>{ data.name }
                                      </label>
                                    : int == 2 ?                                  
                                      <label>
                                        <input type="radio" checked={JSON.stringify(oa_skill_category_2) == JSON.stringify(data)} name={'oa_skill_category_' + int} key={data.id} value={JSON.stringify(data)}
                                        onChange={(e) => changeRadio(e.target.value, 'oa_skill', int)}/>{ data.name }
                                      </label>
                                    :                              
                                      <label>
                                        <input type="radio" checked={JSON.stringify(oa_skill_category_3) == JSON.stringify(data)} name={'oa_skill_category_' + int} key={data.id} value={JSON.stringify(data)} 
                                        onChange={(e) => changeRadio(e.target.value, 'oa_skill', int)}/>{ data.name }
                                      </label>
                                    }
                                </>
                              ))}
                            </div>

                            <select name={'oa_skill_' + int} className={oa_skill_category_1 == null && oa_skill_category_2 == null
                              && oa_skill_category_3 == null ? 'type3 dnone' : 'type3'} onChange={(e) => setOaSkillById(e.target.value, int)}>
                              {filterOaSkills(int).map((data: any, index: any) => (
                                <>
                                {int == 1 ?
                                <option selected={JSON.stringify(oa_skill_1) == JSON.stringify(data)} key={data.id} value={JSON.stringify(data)} >{data.name}</option>
                                : int == 2 ?
                                <option selected={JSON.stringify(oa_skill_2) == JSON.stringify(data)} key={data.id} value={JSON.stringify(data)} >{data.name}</option>
                                :
                                <option selected={JSON.stringify(oa_skill_3) == JSON.stringify(data)} key={data.id} value={JSON.stringify(data)} >{data.name}</option>
                                }
                                </>
                              ))}
                            </select>
                          </>
                          ))}
                          {oa_skill_loop_count < 3 ?
                          <a onClick={() => addOaSkillLoopCount()}>PCスキルを追加する</a>
                          : <></>}
                      </div>
                    </dd>
                    <dt>スキル - 語学</dt>
                    <dd>
                      <div className="skill_box">
                        {language_loop_count && Array.from({length: language_loop_count}, (v, k) => k+1).map((int: any, index: any) => (
                          <>
                            <div className="radio_box">
                              {languages && languages.map((data: any, index: any) => (
                                <>
                                {int == 1 ? 
                                  <label>
                                    <input type="radio" checked={JSON.stringify(language_1) == JSON.stringify(data)} name={'language_' + int} key={data.id} value={JSON.stringify(data)} 
                                    onChange={(e) => changeRadio(e.target.value, 'level', int)}/>{ data.name }
                                  </label>
                                : int == 2 ?
                                  <label>
                                    <input type="radio" checked={JSON.stringify(language_2) == JSON.stringify(data)} name={'language_' + int} key={data.id} value={JSON.stringify(data)} 
                                    onChange={(e) => changeRadio(e.target.value, 'level', int)}/>{ data.name }
                                  </label>
                                : 
                                  <label>
                                    <input type="radio" checked={JSON.stringify(language_3) == JSON.stringify(data)} name={'language_' + int} key={data.id} value={JSON.stringify(data)} 
                                    onChange={(e) => changeRadio(e.target.value, 'level', int)}/>{ data.name }
                                  </label>
                                }
                                </>                              
                              ))}
                            </div>

                            <select name={'level_' + int} className={language_1 == null && language_2 == null
                              && language_3 == null ? 'type3 dnone' : 'type3'} onChange={(e) => setLevelById(e.target.value, int)}>
                              <option value="" selected hidden></option>
                              {levels && levels.map((data: any, index: any) => (
                                  <>
                                    {int == 1 ?
                                    <option selected={JSON.stringify(level_1) == JSON.stringify(data)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                                    : int == 2 ?
                                    <option selected={JSON.stringify(level_2) == JSON.stringify(data)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                                    :
                                    <option selected={JSON.stringify(level_3) == JSON.stringify(data)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                                    }
                                  </>
                              ))}
                            </select>
                          </>
                        ))}
                        {language_loop_count < 3 ?
                        <a onClick={() => addLanguageLoopCount()}>語学スキルを追加する</a>
                        : <></>}
                      </div>
                    </dd>
                    <dt>スキル - 資格</dt>
                    <dd>
                      {qualification_loop_count && Array.from({length: qualification_loop_count}, (v, k) => k+1).map((int: any, index: any) => (
                        <div className="flex_box">
                          <select name={'qualification_category_' + int} className="type2" onChange={(e) => changeQualification(e.target.value, 'category', int)}>
                          <option value="" selected hidden></option>
                          {qualification_categories && qualification_categories.map((data: any, index: any) => (
                            <>
                            {int == 1 ?
                            <option selected={JSON.stringify(data) == JSON.stringify(qualification_category_1)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                            : int == 2 ?
                            <option selected={JSON.stringify(data) == JSON.stringify(qualification_category_2)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                            :
                            <option selected={JSON.stringify(data) == JSON.stringify(qualification_category_3)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>}
                            </>
                          ))}
                          </select>
                          <select name={'qualification_' + int} className="type3" onChange={(e) => changeQualification(e.target.value, '', int)}>
                          <option value="" selected hidden></option>
                          {filterQualifications(int).map((data: any, index: any) => (
                            <>
                            {int == 1 ?
                            <option selected={JSON.stringify(data) == JSON.stringify(qualification_1)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                            : int == 2 ?
                            <option selected={JSON.stringify(data) == JSON.stringify(qualification_2)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                            :
                            <option selected={JSON.stringify(data) == JSON.stringify(qualification_3)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>}
                            </>
                          ))}
                          </select>
                        </div>
                      ))}
                    </dd>
                  </dl>
                  <div className="link_box">
                    <button type="button" className="btn type3" onClick={filterApplicants}>検索する</button>
                    <a onClick={() => clear()} className="reset">条件をリセットする</a>
                  </div>
                </form>
              </div>
             :
            <div  id="scouts" className="top">
                <div className="edit_box">
                    <div>
                        <div className="icon"></div>
                        <ul className="pagination">
                            {current_page && current_page > 1 && (
                            <li className="arrow prev">
                                <a onClick={() => pageDecrement()}>
                                <img src="/assets/img/company/icon06.svg" alt="前へ" />
                                </a>
                            </li>
                            )}

                            {shaping_pagenation().map((n: any) => (
                            <li key={n} className={n === current_page ? 'active' : ''}>
                                <a onClick={() => updatePage(n)}>{n}</a>
                            </li>
                            ))}

                            {current_page && current_page < total_pages && (
                            <li className="arrow next">
                                <a onClick={() => pageIncrement()}>
                                <img src="/assets/img/company/icon07.svg" alt="次へ" />
                                </a>
                            </li>
                            )}
                        </ul>
                    </div>
                    <div className="cont">
                        <h3>本日のスカウト可能残数<span><span>{limitCount}</span>名</span></h3>
                        <form>
                          <div>
                            <h4>検索条件</h4>
                            <div className="btn_box1">
                              <a onClick={() => saveParameters(false)}>検索条件の保存</a>
                              <a onClick={() => loadSavedConditions()}>検索条件一覧</a>
                              <a onClick={() => clear()} className="reset">条件をリセットする</a>
                            </div>
                            {!showSearchMain ?
                            <ul>
                              {japaneseParamsObject && japaneseParamsObject.map((item: any, index: any) => (
                                <li key={index}>{ item }</li>
                              ))}
                            </ul>
                            : <></>}
                            {!showSearchMain ?
                            <a onClick={() => setShowSearchMain(!showSearchMain)}><img src="/assets/img/mypage/memo_icon.svg" alt="編集"/></a>
                            : <></>}
                            {showSearchMain ?
                            <dl className="edit_box">
                              <dt>障がい種別</dt>
                                <dd>
                                  {/* <div className="flex_box"> */}
                                  <select name="handicap_type1" className="type2"  onChange={changeHandicap}>
                                    <option value='' selected hidden>選択してください</option>
                                    {handicap_types && handicap_types.map((data: any, index: any) => (
                                        <option selected={JSON.stringify(data) == JSON.stringify(handicap_type1)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                                    ))}
                                  </select>
                                  <select name="handicap_type2" className="type2"  onChange={changeHandicap}>
                                    <option value='' selected hidden>選択してください</option>
                                    {filterHandicapType && filterHandicapType.map((data: any, index: any) => (
                                        <option selected={JSON.stringify(data) == JSON.stringify(handicap_type2)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                                      ))}
                                  </select>
                                {/* </div> */}
                                </dd>
                              {/* <dd>
                                <select name="handicap_type1" v-model="handicap_type1" className="type2" onChange={(e) => setHandicapType1(JSON.parse(e.target.value))}>
                                  <option value='' selected hidden>選択してください</option>
                                  {handicap_types && handicap_types.map((data: any, index: any) => (
                                    <>
                                    {checkSelectedHandicapType(data.id) ?
                                    <option selected={JSON.stringify(data) == JSON.stringify(handicap_type1)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                                  : <></>}</>
                                  ))}
                                </select>
                                <select name="handicap_type2" v-model="handicap_type2" className="type2" onChange={(e) => setHandicapType2(JSON.parse(e.target.value))}>
                                  <option value='' selected hidden>選択してください</option>
                                  {handicap_types && handicap_types.map((data: any, index: any) => (
                                  <>
                                  {checkSelectedHandicapType(data.id) ?
                                  <option selected={JSON.stringify(data) == JSON.stringify(handicap_type2)} key={data.id} value={JSON.stringify(data)}>{data.name}</option>
                                : <></>}</>
                                  ))}
                                </select>
                                <span>※複数設定可</span>
                              </dd> */}
                              <dt>障がい者手帳有無</dt>
                              <dd>
                                <select name="disability_certificate_status" v-model="disability_certificate_status" className="type1" onChange={(e) => setDisabilityCertificateStatus(JSON.parse(e.target.value))}>
                                <option value="" selected hidden></option>
                                {disability_certificate_statuses && disability_certificate_statuses.map((disability_certificate_status1: any, index: any) => (
                                  <option selected={JSON.stringify(disability_certificate_status1) == JSON.stringify(disability_certificate_status)} key={disability_certificate_status1.id} value={JSON.stringify(disability_certificate_status1)}>{disability_certificate_status1.name}</option>
                                ))}
                                </select>
                              </dd>
                              <dt>就労支援有無</dt>
                              <dd>
                                <select name="employment_support_agency" v-model="employment_support_agency" className="type1" onChange={(e) => setEmploymentSupportAgency(JSON.parse(e.target.value))}>
                                <option value="" selected hidden></option>
                                {employment_support_agencies && employment_support_agencies.map((employment_support_agency1: any, index: any) => (
                                  <option selected={JSON.stringify(employment_support_agency1) == JSON.stringify(employment_support_agency)} key={employment_support_agency1.id} value={JSON.stringify(employment_support_agency1)}>{employment_support_agency1.name}</option>
                                ))}
                                </select>
                              </dd>
                            </dl>
                            : <></>}
                            <div className="btn_box2">
                              <button type="button" className="btn type3" onClick={filterApplicants}>検索する</button>
                              <a onClick={() => searchDetail(true)}><img src="/assets/img/company/icon18.png" alt=""/>詳細条件を設定する</a>
                            </div>
                          </div>
                        </form>
                    </div>
                    <form className="list_item">
                        <select onChange={(e) => changeSort(e.target.value)}>
                            {sortList.map((option) => (
                                <option key={option.value} value={option.value}>
                                {option.text}
                                </option>
                            ))}
                        </select>
                        <ul>
                            <li className={!isCandidatePage() ? "active" : ""}> <a href="/recruiter/scouts/">人材一覧</a> </li>
                            <li className={isCandidatePage() ? "active" : ""}> <a href="/recruiter/scouts/candidate/">スカウトリスト</a> </li>
                        </ul>
                        <div>
                           <label><input type="checkbox" v-model="allCheck" checked={allCheck} onChange = {toggleCheckAll}/></label>
                           <p>全員にチェックを入れる</p>  
                           {!isCandidatePage() ?
                           <a  onClick={() => saveCandidates(0, null)} className={`btn green ${selectedApplicants.length === 0 ? 'none-scout' : ''}`}>スカウト候補に一括追加</a>
                           : <></>}
                        </div>
                    </form>
                    <ul className="list_box">
                      {applicants && applicants.map((applicant: any) => (
                        <li key={applicant.id}>
                          <label><input type="checkbox" value={applicant.id} checked={checkSelect(applicant.id)} onChange={(e) =>toggleCheckSelectedApplicants(applicant)} v-model="selectedApplicantIds"/></label>
                          <div className={`item_box ${applicant.active ? "online" : ""}`}>
                            <div>
                              <div>
                                {!checkApplicantDetailsPage() ?
                                <div className="tag_box">
                                  <a href= {'/recruiter/scouts/applicant/' + applicant.id} target="_blank">
                                    <div className="img_box">
                                      <img src={applicant.face_photo || null} alt={applicant.family_name + applicant.first_name} />
                                    </div>
                                    <h4>ID:{ applicant.id }</h4>
                                    <p>&#x2665; 相性{ applicant.compatibilities }%</p>
                                    <div className="tooltip_box">
                                      <div className="tooltip">
                                        <p className="icon"><img src="/assets/img/company/icon32.svg" alt=""/></p>
                                        <p className="text">※相性とは<br/>スキルや経験だけでなく、求職者の求める働き方と採用担当<br/>者の求める人物像との相性を加味して算出しています。</p>
                                      </div>
                                    </div>
                                    </a>
                                  </div>
                                : 
                                <div>
                                  <div className="img_box">
                                      <img src={applicant.face_photo || null} alt={applicant.family_name + applicant.first_name} />
                                  </div>
                                  <h4>ID:{ applicant.id }</h4>
                                  <p>&#x2665; 相性{ applicant.compatibilities }%</p>
                                  <div className="tooltip_box">
                                    <div className="tooltip">
                                      <p className="icon"><img src="/assets/img/company/icon32.svg" alt=""/></p>
                                      <p className="text">※相性とは<br/>スキルや経験だけでなく、求職者の求める働き方と採用担当<br/>者の求める人物像との相性を加味して算出しています。</p>
                                    </div>
                                  </div>
                                </div>
                                }
                              </div>
                              <ul>
                                <li>
                                <span>障害種別<br className="sp_only"/>(手帳有無)</span>
                                {applicant.handicap_qualifications.length > 0 ?
                                    applicant.handicap_qualifications && applicant.handicap_qualifications.map((handicap_qualification: any ,i: any) => (
                                        <>{ handicap_qualification.handicap_type }(手帳{ handicap_qualification.disability_certificate_status })
                                        {i != Object.keys(applicant.handicap_qualifications).length - 1 ? <>/<br className="sp_only"/></> : <></>}
                                        </>
                                    ))
                                : <></>}
                                </li>
                                <li><span>就労支援有無</span>{ applicant.employment_support_agency }</li>
                                <li><span>勤務地希望</span>
                                {applicant.preferred_prefectures.length > 0 ?
                                    applicant.preferred_prefectures && applicant.preferred_prefectures.map((preferred_prefecture: any ,i: any) => (
                                        <>{preferred_prefecture}
                                        {(i != Object.keys(applicant.preferred_prefectures).length - 1) ? <>/<br className="sp_only"/></> : <></>}
                                        </>
                                    ))
                                : <>無し</>}
                                </li>
                              </ul>
                            </div>
                            <div className="btn_box">
                            {!isCandidatePage() ?
                            <a href="javascript:void(0)" onClick={(e) => saveCandidates(applicant.id, applicant)} className={applicant.include_scout_candidate ? 'btn green none-scout' : 'btn green'}>スカウト候補に追加</a>
                            : <></>}
                            <a onClick={() => loadEntry(applicant)} className={scoutDisabled ? "disabled btn orange" : "btn orange"}>スカウト</a>
                            </div>
                            {applicant.scouted_recruitments.length > 0 ?
                                !checkApplicantDetailsPage() ?
                                    <div className="tag_box">
                                        <h5>スカウト済みの案件</h5>
                                        <ul>
                                        {applicant.scouted_recruitments && applicant.scouted_recruitments.map((scouted_recruitment: any ) => (
                                            <li key={'scouted_recruitment' + scouted_recruitment.id}>
                                                <a href={'/recruitments/' + scouted_recruitment.id} target="_blank">{scouted_recruitment.title}</a>
                                            </li>
                                        ))}
                                        </ul>
                                    </div>
                                : <></>
                            : <></>}
                          </div>
                        </li>
                      ))}

                    </ul>
                    <div>
                        <div className="icon"></div>
                        <ul className="pagination">
                            {current_page && current_page > 1 && (
                            <li className="arrow prev">
                                <a onClick={() => pageDecrement()}>
                                <img src="/assets/img/company/icon06.svg" alt="前へ" />
                                </a>
                            </li>
                            )}

                            {shaping_pagenation().map((n: any) => (
                            <li key={n} className={n === current_page ? 'active' : ''}>
                                <a onClick={() => updatePage(n)}>{n}</a>
                            </li>
                            ))}

                            {current_page && current_page < total_pages && (
                            <li className="arrow next">
                                <a onClick={() => pageIncrement()}>
                                <img src="/assets/img/company/icon07.svg" alt="次へ" />
                                </a>
                            </li>
                            )}
                        </ul>
                    </div>
                    {applicants !== undefined && applicants.length == 0 ?
                    <p>スカウト候補はいません</p> : <></>}
                    <p><span>{ selectedApplicants.length }</span>名 選択しています</p>
                    {!isCandidatePage() ?
                    <a onClick={(e) => saveCandidates(0, null)} className={selectedApplicants.length == 0 ? 'btn green none-scout' : 'btn green'}>スカウト候補に一括追加</a>
                    : <a href="javascript:void(0)" onClick={(e) => saveCandidates(0, null)} className={selectedApplicants.length == 0 ? 'btn orange none-scout' : 'btn orange'}>一括スカウト</a>}
                </div>
                <div><a onClick={() => saveParameters(true)} className="btn orange">この人材が登録されたら<br/>通知を受け取る</a></div>
            </div>}
        </section>
    </main>
    );
  }
  
  export default ScoutsIndex;