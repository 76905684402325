
import { initTypes } from "../../ActionTypes/initTypes";
import { regionsTypes } from "../../ActionTypes/regionsTypes";
import {  InitActions, InitState, RegionsActions, RegionsState } from "../../types/type";

const initialState: InitState = {
  pending: false,
  init: [],
  error: null,
};

export default (state = initialState, action: InitActions,) => {
  switch (action.type) {
    case initTypes.FETCH_INIT_REQUEST:
      return {
        ...state,
        pending: false
      };
    case initTypes.FETCH_INIT_SUCCESS:
      return {
        ...state,
        pending: true,
        init: action.payload.init,
        error: null
      };
    case initTypes.FETCH_INIT_FAILURE:
      return {
        ...state,
        pending: false,
        init: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
