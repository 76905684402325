import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import Loader from '../../loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchRegionsRequest } from '../../../redax/actions/regionsActions/regionsActions';
import { fetchAdminAdministratorsRequest } from '../../../redax/actions/adminAdministratorsActions/adminAdministratorsActions';
import snakecaseKeys from 'snakecase-keys';
import { fetchAdminAdministratorsRegionsRequest } from '../../../redax/actions/adminAdministratorsRegionsActions/adminAdministratorsRegionsActions';


const AdminAdministratorNew: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadScreen, setLoadScreen] = useState(false);
  const [roles, setRoles] = useState<any[]>([]);

  const adminAdministratorsRegions = useSelector((state: RootState) => state.adminAdministratorsRegions);
  const administrators = useSelector((state: RootState) => state.adminAdministrators);
  const [name, setName] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [password, setPassword] = useState<any>(null);
  const [roleId, setRoleId] = useState<any>(1);




  function handleSubmit ()  {
    let passwordValidate = /^(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,32}$/;
    let mailformat =/^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let validation_messages = ''
    if(name == '') {
      validation_messages += "名前が入力されていません\n"
    }
    if(email == '' || !mailformat.test(email)) {
      validation_messages += "メールアドレスが正しくありません\n"
    }
    if(password == '' || !passwordValidate.test(password) || password.length <= 8 || password.length >= 32) {
      validation_messages += "パスワードは8文字以上32文字以下で大小英数字を含めてください\n"
    }
    // if(formData.role == '') {
    //   validation_messages += "権限が入力されていません\n"
    // }
    if(validation_messages !== '') {
      window.alert(validation_messages);
    } else {
      let data = {
        email: email,
        name: name,
        password: password,
        role_id: parseInt(roleId)
      }
      dispatch(fetchAdminAdministratorsRequest('post', data, ''));
    }
  };

  useEffect(() => {
    dispatch(fetchAdminAdministratorsRegionsRequest());
}, [dispatch]); 


useEffect(() => {
  if (adminAdministratorsRegions.pending) {
    if(adminAdministratorsRegions.regions.administrator_roles){
      setRoles(adminAdministratorsRegions.regions.administrator_roles)
    }
  }
}, [adminAdministratorsRegions]);

useEffect(() => {
  if (administrators.pending) {
    window.location.href = '/admin/administrators'
  } else {
    // if(administrators.error) {
    //   window.alert(administrators.error);
    // }
    // return
  }
}, [administrators]);



  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader /> : null}
      <section>
        <div className="adminPageWrapper">
          <div className="header">
            <h2 className="title">管理者 新規追加 </h2>
          </div> 
          <div className='body'>
            <div className="">
              <div className="textWrapper">
                <a  onClick={() => navigate('/admin/administrators')} className='blueBtn'>＜ 一覧に戻る</a>
              </div>
            </div>
            <div>
              <div className='form'>
                <div  className='form-groups'>
                  <label htmlFor="name">名前 <span className="requireds">※必須</span></label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e)=> setName(e.target.value)}
                    required
                  />
                </div>
                <div className='form-groups'>
                  <label htmlFor="email">メールアドレス <span className="requireds">※必須</span></label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e)=> setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className='form-groups'>
                  <label htmlFor="password">パスワード <span className="requireds">※必須</span></label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e)=> setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className='form-groups'>
                  <label htmlFor="role">役割 <span className="requireds">※必須</span></label>
                  <select id="role" name="role" value={roleId} onChange={(e)=> setRoleId(e.target.value)}>
                    {roles && roles.map((role:any) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </div>
                <button className='createBtn' style={{padding:'10.5px 20px'}} onClick={handleSubmit}>登録</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AdminAdministratorNew;