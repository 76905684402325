import { adminNewsNewTypes } from "../../ActionTypes/adminNewsNewTypes";
import { FetchAdminNewsNewFailure, FetchAdminNewsNewFailurePayload, FetchAdminNewsNewRequest, FetchAdminNewsNewSuccess, FetchAdminNewsNewSuccessPayload } from "../../types/type";


export const fetchAdminNewsNewRequest = (data: any): FetchAdminNewsNewRequest => ({
  type: adminNewsNewTypes.FETCH_ADMIN_NEWS_NEW_REQUEST,
  data
});

export const fetchAdminNewsNewSuccess = (
  payload: FetchAdminNewsNewSuccessPayload
): FetchAdminNewsNewSuccess => ({
  type: adminNewsNewTypes.FETCH_ADMIN_NEWS_NEW_SUCCESS,
  payload
});

export const fetchAdminNewsNewFailure = (
  payload: FetchAdminNewsNewFailurePayload
): FetchAdminNewsNewFailure => ({
  type: adminNewsNewTypes.FETCH_ADMIN_NEWS_NEW_FAILURE,
  payload
});