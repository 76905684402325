import password from "../../../pages/password/password";
import { resetTypes } from "../../ActionTypes/resetTypes";
import { FetchResetRequest, FetchResetFailure, FetchResetFailurePayload, FetchResetSuccess, FetchResetSuccessPayload } from "../../types/type";




export const fetchResetRequest = (password:any, password_confirm: any, query:any): FetchResetRequest => ({
  type: resetTypes.FETCH_RESET_REQUEST,
  password,
  password_confirm,
  query
});

export const fetchResetSuccess = (
  payload: FetchResetSuccessPayload,
): FetchResetSuccess => ({
  type: resetTypes.FETCH_RESET_SUCCESS,
  payload
});

export const fetchResetFailure = (
  payload: FetchResetFailurePayload
): FetchResetFailure => ({
  type: resetTypes.FETCH_RESET_FAILURE,
  payload
});
