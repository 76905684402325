import { RecruiterPlanPostTypes } from "../../ActionTypes/recruiterPlanPostTypes";
import { FetchRecruiterPlanPostRequest, FetchRecrutierPlanPostSuccessPayload, FetchRecruiterPlanPostPostFailure, FetchRecrutierPlanPostSuccess, FetchRecrutierPlanPostFailure } from "../../types/type";


export const fetchRecruiterPlanPostRequest = (data:any): FetchRecruiterPlanPostRequest => ({
  type: RecruiterPlanPostTypes.FETCH_RECRUITER_PLAN_POST_REQUEST,
  data,
});

export const fetchRecruiterPlanPostSuccess = (
  payload: FetchRecrutierPlanPostSuccessPayload,
): FetchRecrutierPlanPostSuccess => ({
  type: RecruiterPlanPostTypes.FETCH_RECRUITER_PLAN_POST_SUCCESS,
  payload
});

export const fetchRecruiterPlanPostFailure = (
  payload: FetchRecruiterPlanPostPostFailure
): FetchRecrutierPlanPostFailure => ({
  type: RecruiterPlanPostTypes.FETCH_RECRUITER_PLAN_POST_FAILURE,
  payload
});
