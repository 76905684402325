import { useState, useEffect } from 'react';
import { API_BASE_ADDRESS } from '../../redax/API';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginRequest } from '../../redax/actions/loginActions/loginActions';
import { RootState } from '../../redax/reducers/rootReducer';
import { useNavigate } from 'react-router-dom';
import './login.scss';

function Login() {
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginData = useSelector((state: RootState) => state.login);
  const pageTitle = 'ログイン（求職者）｜パラちゃんねる';
  document.title = pageTitle;

  useEffect(() => {
    if(load) {
      dispatch(fetchLoginRequest(email, password, 'Applicant'));
      setLoad(false);
    }
    if (loginData != undefined && loginData.pending) {
      if(localStorage.getItem('jobParachannelAuthToken')){
        // navigate('/mypage/');
        // window.location.reload();
        window.location.href = '/mypage/';
      } else {
        setError(true);
      }
    } else if (loginData !== undefined && loginData.error) {
      setError(true);
    }
  }, [dispatch, load, loginData]);
  
  function login(e: any) {    
    if (/^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email!)) {
      setLoad(true);
      e.preventDefault();
    }
  }

  return (
    <main id="login">
      <section className="wrapper">
        <h2 className="ttl type3">ログイン</h2>
        <div className="input_box">
          <h3 className="ttl">ログイン</h3>
          <form>
          <div className='form'>
          <dl>
              <dt>メールアドレス</dt>
              <dd>
                <input
                  type="email"
                  title="メールアドレス"
                  name="email"
                  v-model="email"
                  // value={email || ''}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </dd>
              <dt>パスワード</dt>
              <dd>
                <input
                  type="password"
                  title="パスワード"
                  name="password"
                  // value={password || ''}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </dd>
            </dl>
            <p style={{ display: error ? 'block' : 'none' }}>
              ログインできませんでした
            </p>
            <button onClick={(e) => login(e)} className="btn type3">
              ログイン
            </button>
            <a href="/password/" className="forget-password">パスワードをお忘れの方はこちら</a>

            <div className="other_login">
              <h3 className="ttl">
                まだアカウントをお持ちでない方は
                <br />
                下のボタンから新規会員登録をしてください。
              </h3>
              <a href="/signup/" className="btn type3 yellow">
                新規会員登録
              </a>
            </div>
          </div>
            
          </form>
        </div>
        <a href="/recruiter/login/">採用担当者様のログインはこちら</a>
      </section>
    </main>
  );
}

export default Login;