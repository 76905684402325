import { adminApplicationsListTypes } from "../../ActionTypes/adminApplicationsListTypes";
import { adminNewsListTypes } from "../../ActionTypes/adminNewsListTypes";
import {
  FetchAdminNewsListRequest,
  FetchAdminNewsListSuccessPayload,
  FetchAdminNewsListSuccess,
  FetchAdminNewsListFailurePayload,
  FetchAdminNewsListFailure
} from "../../types/type";

export const fetchAdminNewsListRequest = (data: any): FetchAdminNewsListRequest => ({
  type: adminNewsListTypes.FETCH_ADMIN_NEWS_LIST_REQUEST,
  data
});

export const fetchAdminNewsListSuccess = (
  payload: FetchAdminNewsListSuccessPayload
): FetchAdminNewsListSuccess => ({
  type: adminNewsListTypes.FETCH_ADMIN_NEWS_LIST_SUCCESS,
  payload
});

export const fetchAdminNewsListFailure = (
  payload: FetchAdminNewsListFailurePayload
): FetchAdminNewsListFailure => ({
  type: adminNewsListTypes.FETCH_ADMIN_NEWS_LIST_FAILURE,
  payload
});