import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminRecruitmentJobOfferTypes } from "../../ActionTypes/adminRecruitmentJobOfferTypes";
import { fetchAdminRecruitmentJobOfferFailure, fetchAdminRecruitmentJobOfferSuccess } from "../../actions/adminRecruitmentJobOfferActions/adminRecruitmentJobOfferActions";


const getAdminRecruitmentJobOffer = (hellowork: any) => { 
  const config = {
    headers: { 
      Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/recruitments/hello_work.json?number=' + hellowork , config); 
}


function* fetchAdminRecruitmentJobOfferSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminRecruitmentJobOffer, action.hellowork);
    yield put(
      fetchAdminRecruitmentJobOfferSuccess({
        jobOffer: response.data,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminRecruitmentJobOfferFailure({
        error: e.message
      })
    );
  }
}

function* AdminRecruitmentJobOfferSaga() {
  yield takeLatest(adminRecruitmentJobOfferTypes.FETCH_ADMIN_RECRUITMENT_JOB_OFFER_REQUEST, fetchAdminRecruitmentJobOfferSaga);
}
export default AdminRecruitmentJobOfferSaga;