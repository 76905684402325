import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruitmentsCopyTypes } from "../../ActionTypes/recruitmentsCopyTypes";
import { fetchRecruitmentsCopyFailure, fetchRecruitmentsCopySuccess } from "../../actions/recruitmentsCopyActions/recruitmentsDetailActions";


var postRecruitmentsCopy = (id:any) => axios.post(API_BASE_ADDRESS + '/recruitments/' + id  +'/copy/', {id:id}, {
headers: {
  Authorization: localStorage.getItem('jobParachannelAuthToken'),
  Accept: '*/*',
}}); 

function* fetchRecruitmentsCopySaga(id: any) {
  try {
    const response: AxiosResponse = yield call(postRecruitmentsCopy , id.id);
    yield put(
      fetchRecruitmentsCopySuccess({
        recruitmentsCopy: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitmentsCopyFailure({
        error: e.message
      })
    );
  }
}

function* RecruitmentsCopySaga() {
  yield takeLatest(recruitmentsCopyTypes.FETCH_RECRUIMENTS_COPY_REQUEST, fetchRecruitmentsCopySaga);
}

export default RecruitmentsCopySaga;
