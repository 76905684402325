import { adminCompanyAllListTypes } from "../../ActionTypes/adminCompanyAllListTypes";
import { FetchAdminCompanyAllListFailure, FetchAdminCompanyAllListFailurePayload, FetchAdminCompanyAllListRequest, FetchAdminCompanyAllListSuccess, FetchAdminCompanyAllListSuccessPayload } from "../../types/type";



export const fetchAdminCompanyAllListRequest = (): FetchAdminCompanyAllListRequest => ({
  type: adminCompanyAllListTypes.FETCH_ADMIN_COMPANY_ALL_LIST_REQUEST,
});

export const fetchAdminCompanyAllListSuccess = (
  payload: FetchAdminCompanyAllListSuccessPayload,
): FetchAdminCompanyAllListSuccess => ({
  type: adminCompanyAllListTypes.FETCH_ADMIN_COMPANY_ALL_LIST_SUCCESS,
  payload
});

export const fetchAdminCompanyAllListFailure = (
  payload: FetchAdminCompanyAllListFailurePayload
): FetchAdminCompanyAllListFailure => ({
  type: adminCompanyAllListTypes.FETCH_ADMIN_COMPANY_ALL_LIST_FAILURE,
  payload
});
