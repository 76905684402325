import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminCompanyAllListTypes } from "../../ActionTypes/adminCompanyAllListTypes";
import { fetchAdminCompanyAllListFailure, fetchAdminCompanyAllListSuccess } from "../../actions/adminComapnyAllListActions/adminComapnyAllListActions";


const config = {
  headers: { 
    Prefer: 'example=example-1', Accept: 'application/json', 
    Authorization: localStorage.jobParachannelAuthAdminToken 
  }
};

var getAdminCompany = () => axios.get(API_BASE_ADDRESS + '/admin/companies/all', config);


function* fetchAdminCompanyAllListSaga() {
  try {
    const response: AxiosResponse = yield call(getAdminCompany);
    yield put(
      fetchAdminCompanyAllListSuccess({
        company: response.data.companies        
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminCompanyAllListFailure({
        error: e.message
      })
    );
  }
}

function* AdminCompanyAllListSaga() {
  yield takeLatest(adminCompanyAllListTypes.FETCH_ADMIN_COMPANY_ALL_LIST_REQUEST, fetchAdminCompanyAllListSaga);
}

export default AdminCompanyAllListSaga;
