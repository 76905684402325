import { useState } from "react";


function Guide13() {

    
    return (
        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">13.応募時のメッセージのやり取りについて(企業・求職者両方)</h3>
          <p>この記事は約3分で読めます。</p>
    
          <p>このページでは求職者が応募したときの面接設定までの企業・求職者のやりとりについて説明します。</p>
    
          <h4 >＜①求職者：ログイン〜応募までの流れ＞</h4>
    
          <p>1.パラちゃんねる求人サイトTOP画面より(<a href="/"  style={{display: 'inline',color: '#00a0e9'}}>ログイン／登録</a>) をクリックします。</p>
          <img src="/assets/img/guide/first.png" alt=""/>
    
          <p>2.メールアドレス・パスワードを入力し、ログインをクリックします。<br/>
          （パスワードを忘れた場合）パスワードをお忘れの方をクリックしてください。</p>
          <img src="/assets/img/guide/13/02.png" alt=""/>
    
          <p>メールアドレスを入力して、再設定メール送信をクリックして下さい。</p>
          <img src="/assets/img/guide/13/03.png" alt=""/>
    
          <p>3.求人一覧より気になる求人をクリックしエントリー応募をします。</p>
          <img src="/assets/img/guide/13/04.png" alt=""/>
          <img src="/assets/img/guide/13/05.png" alt=""/>
    
          <p>4.求人一覧より気になる求人をクリックしエントリー応募をします。<br/>
          （アピール項目を入れると企業側からの印象がアップします。）
          </p>
          <img src="/assets/img/guide/13/06.png" alt=""/>
    
          <p>5.求職者側からの応募が以下のように完了します。</p>
          <img src="/assets/img/guide/13/07.png" alt=""/>
          {/* <h4 style="margin: 100px 0 20px;font-size: 16px;font-weight: bold;">＜②企業側：応募受付〜日程打診までの流れ＞</h4> */}
          <h4>＜②企業側：応募受付〜日程打診までの流れ＞</h4>
    
          <p>1.ログイン後、新着応募がトップ画面に表示されます。</p>
          <img src="/assets/img/guide/13/08.png" alt=""/>
    
          <p>2.お知らせを開くと応募者が確認できます。</p>
          <img src="/assets/img/guide/13/09.png" alt=""/>
    
          <p>3.審査待ちを押し、日程調整に進みます。</p>
          <img src="/assets/img/guide/13/10.png" alt=""/>
    
          <p>4.日程候補作成を押すと日程候補を設定できます。</p>
          <img src="/assets/img/guide/13/11.png" alt=""/>
    
          <p>5.日程を入力し、送信ボタンを押すと候補者へ通知されます。</p>
          <img src="/assets/img/guide/13/12.png" alt=""/>
    
          <p>追記、修正したい場合は修正ボタンを押してください。<br/>
            キャンセルも可能です。</p>
          <img src="/assets/img/guide/13/13.png" alt=""/>
    
          <p>メッセージが送られると以下のようになります。</p>
          <img src="/assets/img/guide/13/14.png" alt=""/>
    
          {/* <h4 style="margin: 100px 0 20px;font-size: 16px;font-weight: bold;">＜③求職者側：日程確定までの流れ＞</h4> */}
          <h4>＜③求職者側：日程確定までの流れ＞</h4>

    
          <p>1.求職者側で、企業から日程が来た場合、マイページのお知らせで通知されます。</p>
          <img src="/assets/img/guide/13/15.png" alt=""/>
    
          <p>2.日程を確認し、該当日程をチェックし、日程確定ボタンを押すと面談が確定します。<br/>
            ※スケジュールにも日程が反映されます。</p>
          <img src="/assets/img/guide/13/16.png" alt=""/>
    
          <p>※日程を再調整したい場合は企業側へ日程の候補を打診しましょう。</p>
    
          <p>3.当日に向けて確認したいことなどはメッセージを入力し、企業側とやり取りが可能です。</p>
          <img src="/assets/img/guide/13/17.png" alt=""/>
    
          <p>4.仮に辞退する場合は、選考辞退のボタンを押すと企業へ通知が送信されます。</p>
          <img src="/assets/img/guide/13/18.png" alt=""/>
    
          <p>以上が応募〜日程調整のやりとりになります。</p>
    
          <a href="/guide/" className="btn type3 nuxt-link-active">一覧へ戻る</a>
        </section>
      </main>
       
    );
  }
  
  export default Guide13;