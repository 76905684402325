import { DownloadApplicationCsvTypes } from "../../ActionTypes/downloadApplicationCsvTypes";
import { FetchDownloadApplicationCsvFailure, FetchDownloadApplicationCsvFailurePayload, FetchDownloadApplicationCsvRequest, FetchDownloadApplicationCsvSuccess, FetchDownloadApplicationCsvSuccessPayload } from "../../types/type";

export const fetchDownloadApplicationCsvRequest = (): FetchDownloadApplicationCsvRequest => ({
  type: DownloadApplicationCsvTypes.FETCH_DOWNLOAD_APPLICATIONCSV_REQUEST,
  applicationCsv: [],
});

export const fetchDownloadApplicationCsvSuccess = (
  payload: FetchDownloadApplicationCsvSuccessPayload,
): FetchDownloadApplicationCsvSuccess => ({
  type: DownloadApplicationCsvTypes.FETCH_DOWNLOAD_APPLICATIONCSV_SUCCESS,
  payload
});

export const fetchDownloadApplicationCsvFailure = (
  payload: FetchDownloadApplicationCsvFailurePayload
): FetchDownloadApplicationCsvFailure => ({
  type: DownloadApplicationCsvTypes.FETCH_DOWNLOAD_APPLICATIONCSV_FAILURE,
  payload
});
