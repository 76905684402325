import { signupTypes } from "../../ActionTypes/signupTypes";
import { 
   FetchSignupFailure, 
   FetchSignupFailurePayload,
   FetchSignupRequest,
   FetchSignupSuccess,
   FetchSignupSuccessPayload
  } from "../../types/type";


export const fetchSignupRequest = (email:any, password:any, resource_type: any): FetchSignupRequest => ({
  type: signupTypes.FETCH_SIGNUP_REQUEST,
  email,
  password,
  resource_type
});

export const fetchSignupSuccess = (
  payload: FetchSignupSuccessPayload,
): FetchSignupSuccess => ({
  type: signupTypes.FETCH_SIGNUP_SUCCESS,
  payload
});

export const fetchSignupFailure = (
  payload: FetchSignupFailurePayload
): FetchSignupFailure => ({
  type: signupTypes.FETCH_SIGNUP_FAILURE,
  payload
});
