import { signupTypes } from "../../ActionTypes/signupTypes";
import { SignupActions,  SignupState } from "../../types/type";

const initialState: SignupState = {
  pending: false,
  signup: [],
  error: null,
};

export default (state = initialState, action: SignupActions,) => {
  switch (action.type) {
    case signupTypes.FETCH_SIGNUP_REQUEST:
      return {
        ...state,
        pending: false
      };
    case signupTypes.FETCH_SIGNUP_SUCCESS:
      return {
        ...state,
        pending: true,
        signup: action.payload.signup,
        error: null
      };
    case signupTypes.FETCH_SIGNUP_FAILURE:
      return {
        ...state,
        pending: false,
        signup: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
