import { adminScoutApplicantsTypes } from "../../ActionTypes/adminScoutMatchingApplicantsTypes";
import { } from "../../ActionTypes/adminScoutMatchingTypes";
import {  AdminScoutApplicantsActionss, AdminScoutApplicantsState } from "../../types/type";


const initialState: AdminScoutApplicantsState = {
  pending: false,
  adminApplicants: [],
  error: null,
};

export default (state = initialState, action: AdminScoutApplicantsActionss,) => {
  switch (action.type) {
    case adminScoutApplicantsTypes.FETCH_ADMIN_SCOUT_APPLICANTS_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminScoutApplicantsTypes.FETCH_ADMIN_SCOUT_APPLICANTS_SUCCESS:
      return {
        ...state,
        pending: true,
        adminApplicants: action.payload.adminApplicants,
        error: null
      };
    case adminScoutApplicantsTypes.FETCH_ADMIN_SCOUT_APPLICANTS_FAILURE:
      return {
        ...state,
        pending: false,
        adminApplicants: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
