import { companiesTypes } from "../../ActionTypes/companiesTypes";
import { previewTypes } from "../../ActionTypes/previewTypes";
import { FetchPreviewFailure, FetchPreviewFailurePayload, FetchPreviewRequest, FetchPreviewSuccess, FetchPreviewSuccessPayload } from "../../types/type";


export const fetchPreviewRequest = (id:any): FetchPreviewRequest => ({
  type: previewTypes.FETCH_PREVIEW_REQUEST,
  id,
});

export const fetchPreviewSuccess = (
  payload: FetchPreviewSuccessPayload,
): FetchPreviewSuccess => ({
  type: previewTypes.FETCH_PREVIEW_SUCCESS,
  payload
});

export const fetchPreviewFailure = (
  payload: FetchPreviewFailurePayload
): FetchPreviewFailure => ({
  type: previewTypes.FETCH_PREVIEW_FAILURE,
  payload
});
