import { adminCompanyRegionsTypes } from "../../ActionTypes/adminCompanyRegionsTypes";
import { AdminCompanyRegionsActions, AdminCompanyRegionsState } from "../../types/type";





const initialState: AdminCompanyRegionsState = {
  pending: false,
  regions: [],
  error: null,
};

export default (state = initialState, action: AdminCompanyRegionsActions,) => {
  switch (action.type) {
    case adminCompanyRegionsTypes.FETCH_ADMIN_COMPANY_REGIONS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case adminCompanyRegionsTypes.FETCH_ADMIN_COMPANY_REGIONS_SUCCESS:
      return {
        ...state,
        pending: true,
        regions: action.payload.regions,
        error: null
      };
    case adminCompanyRegionsTypes.FETCH_ADMIN_COMPANY_REGIONS_FAILURE:
      return {
        ...state,
        pending: false,
        regions: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
