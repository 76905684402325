import { openGoogleAiTypes } from "../../ActionTypes/openGoogleAiTypes";
import { openGoogleAiActions, OpenAiGoogleState } from "../../types/type";


const initialState: OpenAiGoogleState = {
  pending: false,
  history: [],
  error: null,
};

export default (state = initialState, action: openGoogleAiActions,) => {
  switch (action.type) {
    case openGoogleAiTypes.FETCH_OPENAI_HISTORY_REQUEST:
      return {
        ...state,
        pending: false
      };
    case openGoogleAiTypes.FETCH_OPENAI_HISTORY_SUCCESS:
      return {
        ...state,
        pending: true,
        history: action.payload.history,
        error: null
      };
    case openGoogleAiTypes.FETCH_OPENAI_HISTORY_FAILURE:
      return {
        ...state,
        pending: false,
        history: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
