import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { columnFetchListRequest } from "../../redax/actions/columnFetchList/columnFetchListActions";
import { RootState } from "../../redax/reducers/rootReducer";


function ColumnsList(props: { company: any; showMoreBtn: any}) {
    let company: any = [];
    let company_id: any;
    let articles: any[] = [];
    let showMoreBtn = false;
    let title = 'この企業のコラム';
    if(props) {
        company  = props.company;
        company_id =company.id;
        showMoreBtn = props.showMoreBtn;
    }
    const dispatch = useDispatch();

    useEffect(() => {
        if(company_id != undefined) {
            dispatch(columnFetchListRequest(company_id));
        }
      }, [dispatch, company_id]);

      const { columnList } = useSelector(
        (state: RootState) => state.columnList
      );
      if(columnList !== undefined){
        articles = columnList.articles;
      }


      function dispFormattedDate( index: any) {
        for(let i = 0; i < articles.length; i++){
            if(articles[i].id === index){
                const dt = new Date(articles[i].release_date);
                return !dt ? '' : `
                ${dt.getFullYear()}年
                ${dt.getMonth()+1}月
                ${dt.getDate()}日
              `.replace(/\n|\r/g, '');
            }
        }
      }

    return (
        
            <div className = "column_list">
              <h3>{showMoreBtn ? title : company.name + 'のコラム一覧'}</h3>
              <ul>
                  {articles && articles.map((data: any, index: any) => (
                      <li key={index}>
                          <a href={'/columns/' + data.id} >
                              {data.eye_catch ?
                              <div className="img_box">
                                  <img src={data.eye_catch} alt="" />
                              </div>
                              : <></>}
                              <div className="txt_box">
                                  <p className="date">{dispFormattedDate(data.id)}</p>
                                  <p className="column_ttl">{data.title}</p>
                              </div>
                          </a>
                      </li>
                  ))}
              </ul>
              {showMoreBtn ?
              <div className="btn_area">
                  <a className="btn type3 back" href={'/companies/' + company_id}>
                  一覧へ戻る
                  </a>
              </div> : null}
          </div>
          
    );
  }
  
  export default ColumnsList;

