import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { matomeDetailTypes } from "../../ActionTypes/matomeDetailTypes";
import { fetchMatomeDetailFailure, fetchMatomeDetailSuccess } from "../../actions/matomeDetailActions/matomeDetailActions";


  var getMatomeDetail = (data:any) => axios.get(API_BASE_ADDRESS + '/recruit_site/genre/' + data.id + '?' + 'per=' + data.per + '&page=' + data.page, {headers: { Prefer: 'example=example-1', Accept: 'application/json' }}); 


function* fetchMatomeDetailSaga(data:any) {
  try {
    const response: AxiosResponse = yield call(getMatomeDetail,data.data);
    yield put(
      fetchMatomeDetailSuccess({
        matome: response.data.handicap,
        pagination: response.data.pagenation
      })
    );
  } catch (e:any) {
    yield put(
      fetchMatomeDetailFailure({
        error: e.message
      })
    );
  }
}

function* MatomeDetailSaga() {
  yield takeLatest(matomeDetailTypes.FETCH_MATOME_DETAIL_REQUEST, fetchMatomeDetailSaga);
}

export default MatomeDetailSaga;
