import { adminRecruitmentJobOfferTypes } from "../../ActionTypes/adminRecruitmentJobOfferTypes";
import { AdminRecruitmentJobOfferActions, AdminRecruitmentJobOfferState } from "../../types/type";




const initialState: AdminRecruitmentJobOfferState = {
  pending: false,
  jobOffer: [],
  error: null,
};

export default (state = initialState, action: AdminRecruitmentJobOfferActions,) => {
  switch (action.type) {
    case adminRecruitmentJobOfferTypes.FETCH_ADMIN_RECRUITMENT_JOB_OFFER_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminRecruitmentJobOfferTypes.FETCH_ADMIN_RECRUITMENT_JOB_OFFER_SUCCESS:
      return {
        ...state,
        pending: true,
        jobOffer: action.payload.jobOffer,
        error: null
      };
    case adminRecruitmentJobOfferTypes.FETCH_ADMIN_RECRUITMENT_JOB_OFFER_FAILURE:
      return {
        ...state,
        pending: false,
        jobOffer: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
