import { adminNewsNewTypes } from "../../ActionTypes/adminNewsNewTypes";
import {AdminNewsNewActions, AdminNewsNewState } from "../../types/type";

const initialState: AdminNewsNewState = {
  pending: false,
  news: [],
  error: null,
};

export default (state = initialState, action: AdminNewsNewActions,) => {
  switch (action.type) {
    case adminNewsNewTypes.FETCH_ADMIN_NEWS_NEW_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminNewsNewTypes.FETCH_ADMIN_NEWS_NEW_SUCCESS:
      return {
        ...state,
        pending: true,
        news: action.payload.news,
        error: null
      };
    case adminNewsNewTypes.FETCH_ADMIN_NEWS_NEW_FAILURE:
      return {
        ...state,
        pending: false,
        news: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
