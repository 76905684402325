import { articlesSubmitTypes } from "../../ActionTypes/articlesSubmitTypes";
import { ArticlesSubmitActions, ArticlesSubmitState } from "../../types/type";


const initialState: ArticlesSubmitState = {
  pending: false,
  articles: [],
  error: null,
  status: null
};

export default (state = initialState, action: ArticlesSubmitActions,) => {
  switch (action.type) {
    case articlesSubmitTypes.FETCH_ARTICLES_SUBMIT_REQUEST:
      return {
        ...state,
        pending: false,
        status: null
      };
    case articlesSubmitTypes.FETCH_ARTICLES_SUBMIT_SUCCESS:
      return {
        ...state,
        pending: true,
        articles: action.payload.articles,
        error: null,
        status: action.payload.status,
      };
    case articlesSubmitTypes.FETCH_ARTICLES_SUBMIT_FAILURE:
      return {
        ...state,
        pending: false,
        articles: [],
        error: action.payload.error,
        status: 400,
      };
    default:
      return {
        ...state
      };
  }
};
