import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminCompanyRegionsTypes } from "../../ActionTypes/adminCompanyRegionsTypes";
import { fetchAdminCompanyRegionsSuccess, fetchAdminCompanyRegionsFailure } from "../../actions/adminCompanyRegionsActions/adminCompanyRegionsActions";

  var getAdminCompanyRegions = () => axios.get(API_BASE_ADDRESS + '/master?industries=true&companies=true&consideration_conditions=true&number_of_employees=true' , {headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }}); 


function* fetchAdminCompanyRegionsSaga() {
  try {
    const response: AxiosResponse = yield call(getAdminCompanyRegions);
    yield put(
      fetchAdminCompanyRegionsSuccess({
        regions: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminCompanyRegionsFailure({
        error: e.message
      })
    );
  }
}

function* AdminCompanyRegionsSaga() {
  yield takeLatest(adminCompanyRegionsTypes.FETCH_ADMIN_COMPANY_REGIONS_REQUEST, fetchAdminCompanyRegionsSaga);
}

export default AdminCompanyRegionsSaga;
