import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchApplicationCompanyFailure, fetchApplicationCompanySuccess } from "../../actions/applicationCompanyActions/applicationCompanyActions";
import { applicationCompanyTypes } from "../../ActionTypes/applicationCompanyTypes";

// var numb  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

var getApplicationCompany = (company_id:any, page:any) => axios.get(API_BASE_ADDRESS + '/applications/?company_id=' + company_id + '&page=' + page, {headers: {
  Authorization: localStorage.getItem('jobParachannelAuthToken') ? localStorage.getItem('jobParachannelAuthToken') : localStorage.getItem('jobParachannelAuthAdminToken'),
  Accept: '*/*',
}}); 

function* fetchApplicationCopmanySaga(actions: any) {
  try {
    const response: AxiosResponse = yield call(getApplicationCompany, actions.company_id, actions.page);
    yield put(
      fetchApplicationCompanySuccess({
        applicationCompany: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchApplicationCompanyFailure({
        error: e.message
      })
    );
  }
}

function* ApplicationCompanySaga() {
  yield takeLatest(applicationCompanyTypes.FETCH_APPLICATION_COMPANY_REQUEST, fetchApplicationCopmanySaga);
}

export default ApplicationCompanySaga;
