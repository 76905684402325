import { adminRecruitmentCopyTypes } from "../../ActionTypes/adminRecruitmentCopyTypes";
import { AdminRecruitmentCopyActions, AdminRecruitmentCopyState } from "../../types/type";


const initialState: AdminRecruitmentCopyState = {
  pending: false,
  recruitments: [],
  error: null,
};

export default (state = initialState, action: AdminRecruitmentCopyActions,) => {
  switch (action.type) {
    case adminRecruitmentCopyTypes.FETCH_ADMIN_RECRUITMENT_COPY_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminRecruitmentCopyTypes.FETCH_ADMIN_RECRUITMENT_COPY_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitments: action.payload.recruitments,
        error: null
      };
    case adminRecruitmentCopyTypes.FETCH_ADMIN_RECRUITMENT_COPY_FAILURE:
      return {
        ...state,
        pending: false,
        recruitments: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
