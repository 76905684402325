import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redax/reducers/rootReducer";
import { fetchLoginRequest } from "../../../redax/actions/loginActions/loginActions";
import './login.scss';

function RecuiterLogin() {


  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginData = useSelector((state: RootState) => state.login);
  const pageTitle = 'ログイン（企業）｜パラちゃんねる';
  document.title = pageTitle;
  
  useEffect(() => {
    if(load) {
      dispatch(fetchLoginRequest(email, password, 'Companies::Recruiter'));
      setLoad(false);
    }
    if (loginData != undefined && loginData.pending) {
      if(localStorage.getItem('jobParachannelAuthToken')){
        // navigate('/recruiter/');
        // window.location.reload();
        window.location.href = '/recruiter/';
      }else {
        setError(true);
      }
    } else if (loginData !== undefined && loginData.error) {
      setError(true);
    }
  }, [dispatch, load, loginData]);
  
  function login(e: any) {
    if (/^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email!)) {
      setLoad(true);
      e.preventDefault();
    }
  }


    return (
        <main id="login">
        <section className="company wrapper">
          <h2 className="ttl type3">採用担当者様ログイン</h2>
          <div className="input_box">
            <p>ログインIDとパスワードを入力して<br/>
            ログインボタンを押してください。</p>
            <form>
            <div className="form">
              <dl>
                  <dt>ID</dt>
                  <dd>
                    <input
                      type="email"
                      title="メールアドレス"
                      name="email"
                      v-model="email"
                      value={email || ''}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </dd>
                  <dt>PASS</dt>
                  <dd><input type="password" name="password" value={password || ''} onChange={(e) => setPassword(e.target.value)} /></dd>
                </dl>
                  {error ? <p v-show="error">ログインできませんでした。<br/>入力内容に誤りがないかご確認ください。</p>: <></>}
                <button onClick={(e) => login(e)} className="btn type3">採用担当者ログイン</button>
                <a href="/recruiter/password/" className="forget-password">パスワードをお忘れの方はこちら</a>
            </div>

            </form>
          </div>
          
          <p>※企業専用ページ使用可能期間は<br className="sp_only"/>掲載中と掲載終了後1年間です。</p>
          <h3 className="ttl">まだアカウントをお持ちでない企業様は<br className="sp_only"/>下のボタンから新規会員登録をしてください。</h3>
          <a href="/recruiter/signup/" className="btn type3 yellow">新規会員登録</a>
        </section>
      </main>
    );
  }
  
  export default RecuiterLogin;