import { applicationsTypes } from "../../ActionTypes/applicationsTypes";
import { FetchApplicationsFailure, FetchApplicationsFailurePayload, FetchApplicationsRequest, FetchApplicationsSuccess, FetchApplicationsSuccessPayload } from "../../types/type";



export const fetchApplicationsRequest = (queryParams:any, types: any, data: any): FetchApplicationsRequest => ({
  type: applicationsTypes.FETCH_APPLICATIONS_REQUEST,
  queryParams,
  types,
  data,
});

export const fetchApplicationsSuccess = (
  payload: FetchApplicationsSuccessPayload,
): FetchApplicationsSuccess => ({
  type: applicationsTypes.FETCH_APPLICATIONS_SUCCESS,
  payload
});

export const fetchApplicationsFailure = (
  payload: FetchApplicationsFailurePayload
): FetchApplicationsFailure => ({
  type: applicationsTypes.FETCH_APPLICATIONS_FAILURE,
  payload
});
