import { DownloadResumeTypes } from "../../ActionTypes/downloadResumeTypes";
import { DownloadResumeActions, DownloadResumeState } from "../../types/type";

const initialState: DownloadResumeState = {
  pending: false,
  applicantId: [],
  error: null,
};

export default (state = initialState, action: DownloadResumeActions,) => {
  switch (action.type) {
    case DownloadResumeTypes.FETCH_DOWNLOAD_RESUME_REQUEST:
      return {
        ...state,
        pending: false
      };
    case DownloadResumeTypes.FETCH_DOWNLOAD_RESUME_SUCCESS:
      return {
        ...state,
        pending: true,
        applicantId: action.payload.applicantId,
        error: null
      };
    case DownloadResumeTypes.FETCH_DOWNLOAD_RESUME_FAILURE:
      return {
        ...state,
        pending: false,
        applicantId: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
