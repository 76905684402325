import { masterInfoTypes } from "../../ActionTypes/masterInfoTypes";
import {
  FetchMasterInfoFailure,
  FetchMasterInfoFailurePayload,
  FetchMasterInfoRequest,
  FetchMasterInfoSuccess,
  FetchMasterInfoSuccessPayload,
} from "../../types/type";

export const fetchMasterInfoRequest = (param: any): FetchMasterInfoRequest => ({
  type: masterInfoTypes.FETCH_MASTER_INFO_REQUEST,
  param
});

export const fetchMasterInfoSuccess = (
  payload: FetchMasterInfoSuccessPayload,
): FetchMasterInfoSuccess => ({
  type: masterInfoTypes.FETCH_MASTER_INFO_SUCCESS,
  payload
});

export const fetchMasterInfoFailure = (
  payload: FetchMasterInfoFailurePayload
): FetchMasterInfoFailure => ({
  type: masterInfoTypes.FETCH_MASTER_INFO_FAILURE,
  payload
});
