import { scoutsRecruitmentTypes } from "../../ActionTypes/scoutsRecruitmentTypes";
import { FetchScoutsRecruitmentFailure, FetchScoutsRecruitmentFailurePayload, FetchScoutsRecruitmentRequest, FetchScoutsRecruitmentSuccess, FetchScoutsRecruitmentSuccessPayload } from "../../types/type";


export const fetchScoutsRecruitmentRequest = (): FetchScoutsRecruitmentRequest => ({
  type: scoutsRecruitmentTypes.FETCH_SCOUTS_RECRUITMENT_REQUEST,
});

export const fetchScoutsRecruitmentSuccess = (
  payload: FetchScoutsRecruitmentSuccessPayload,
): FetchScoutsRecruitmentSuccess => ({
  type: scoutsRecruitmentTypes.FETCH_SCOUTS_RECRUITMENT_SUCCESS,
  payload
});

export const fetchScoutsRecruitmentFailure = (
  payload: FetchScoutsRecruitmentFailurePayload
): FetchScoutsRecruitmentFailure => ({
  type: scoutsRecruitmentTypes.FETCH_SCOUTS_RECRUITMENT_FAILURE,
  payload
});
