import { scoutsEntryTypes } from "../../ActionTypes/scoutsEntryTypes";
import { FetchScoutsEntryFailure,
   FetchScoutsEntryFailurePayload,
    FetchScoutsEntryRequest, 
    FetchScoutsEntrySuccess,
     FetchScoutsEntrySuccessPayload } from "../../types/type";


export const fetchScoutsEntryRequest = (id: any, name: any, types: any): FetchScoutsEntryRequest => ({
  type: scoutsEntryTypes.FETCH_SCOUTS_ENTRY_REQUEST,
  id,
  name,
  types,
});

export const fetchScoutsEntrySuccess = (
  payload: FetchScoutsEntrySuccessPayload,
): FetchScoutsEntrySuccess => ({
  type: scoutsEntryTypes.FETCH_SCOUTS_ENTRY_SUCCESS,
  payload
});

export const fetchScoutsEntryFailure = (
  payload: FetchScoutsEntryFailurePayload
): FetchScoutsEntryFailure => ({
  type: scoutsEntryTypes.FETCH_SCOUTS_ENTRY_FAILURE,
  payload
});
