import React, { useEffect, useState, CSSProperties  } from 'react';
import MypageSideBar from '../../../../components/MypageSideBar/MypageSideBar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redax/reducers/rootReducer';
import { fetchLoginRequest } from '../../../../redax/actions/loginActions/loginActions';
import { useParams } from 'react-router-dom';
import { fetchApplicationsRequest } from '../../../../redax/actions/applicationsActions/applicationsActions';
import { useNavigate } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import './applicationsDetail.scss';
import Loader from '../../../loader/loader';

const override: CSSProperties = {
    display: "inline-block",
    position: "absolute",
    margin: "20% auto 40%",
    // margin: "0 auto",
    border: "3px outset",
    borderColor: "silver",
    // z-index: "0",
    zIndex: "100"
  };

function RecruiterApplicationsDetail() {
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [application, setApplication] = useState<any>([]);
    const [applicantAppealItem, setApplicantAppealItem] = useState<any>([]);
    const [recruitmentAppealItem, setRecruitmentAppealItem] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [applicant, setApplicant] = useState<any>([]);
    const [recruiter, setRecruiter] = useState<any[]>([]);
    const [applicantHandicap_qualifications, setApplicantHandicap_qualifications] = useState<any[]>([]);
    const [final_education, setFinal_education] = useState(null);
    const [graduation_date, setGraduation_date] = useState(null);
    const [new_graduate, setNew_graduate] = useState(null);
    const [support_agency_exists, setSupport_agency_exists] = useState(null);
    const [applicantJob_career_recent, setApplicantJob_career_recent] = useState<any[]>([]);
    const [arrival_date, setArrival_date] = useState(null);
    const [business_content, setBusiness_content] = useState(null);
    const [company, setCompany] = useState(null);
    const [departure_date, setDeparture_date] = useState(null);
    const [industry, setIndustry] = useState(null);
    const [job_category, setJob_category] = useState(null);
    const [statusName, setStatusName] = useState<any>(null);
    const [job_career_recent_exists, setJob_career_recent_exists] = useState();
    const [update_statuses, setUpdate_statuses] = useState<any[]>([]);
    const [applicantHandicap_types, setApplicantHandicap_types] = useState<any[]>([]);
    const [search_policy, setSearch_policy] = useState(null);
    const [applicant_compatibilities, setApplicant_compatibilities] = useState(null);
    const [working_hour, setWorking_hour] = useState(null);
    const [work_from_home, setWork_from_home] = useState(null);
    const [prefectures, setPrefectures] = useState(null);
    const [commuting_methods, setCommuting_methods] = useState(null);
    const [employment_statuses, setEmployment_statuses] = useState(null);
    const [annual_income, setAnnual_income] = useState(null);
    const [job_categories, setJob_categories] = useState(null);
    const dispatch = useDispatch();
    let param = '';
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");
    const loginData = useSelector((state: RootState) => state.login);  
    const applications = useSelector((state: RootState) => state.applications);
    const { id } = useParams();
    const navigate = useNavigate();
    const [loadScreen, setLoadScreen] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [readLoad, setReadLoad] = useState(true);
    const [newStatusId, setNewStatusId] = useState(0);
    const [oldId, setOldId] = useState(0);
    const [apiType, setApiType] = useState('get');

    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
            dispatch(fetchLoginRequest('', '',''))
          } else (
              navigate('/recruiter/login/')
          )
    }, [dispatch]);

    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/recruiter/init/');
            }
          setResourceId(loginData.login.resource.id);
          setFamylyName(loginData.login.resource.family_name); 
          setRecruiter(loginData.login.resource);
          if(id !== null) {     
            param = '/applications/' + id  
            if(firstLoad) {
                dispatch(fetchApplicationsRequest(param, 'get', null));
                setFirstLoad(false);
            }
          }
        }       
        setLoading(false);
    }, [dispatch, loginData.pending]);

    useEffect(() => {
        if (applications.pending && readLoad) {
            setLoadScreen(false);
            if(apiType !== 'get') {
                // 画面遷移
                let template =''
                switch (newStatusId) {
                case 6: // 内定
                    // template = '【選考結果のご連絡】\n\n'
                    // template += 'この度は、多数の企業から弊社へご応募頂きまして、誠にありがとうございます。\n\n'
                    // template += '選考の結果、貴殿を採用させていただくことが内定しましたのでご連絡いたします。\n'
                    // template += '入社手続きに必要な書類に関しましては、別途ご連絡をさせていただきます。\n\n'
                    // template += '貴殿と働ける日を社員一同心待ちにしております。'
                    myRouterPush(false, null, oldId)
                    break;
                case 8: // 不採用
                    template = '選考の結果、誠に残念ながら、この度は採用を見送らせていただくことになりました。\n'
                    template += 'ご希望に添えず恐縮ではございますが、ご了承くださいますようお願いいたします。'
                    myRouterPush(false, template, oldId)
                    break;
                default:
                    window.location.reload()
                    break;
                }
            } else {                
                setApplication(applications.applications.application); 
                setApplicantAppealItem(applications.applications.application.applicant_appeal_item);
                setRecruitmentAppealItem(applications.applications.application.recruitment_appeal_item); 
                setApplicant(applications.applications.application.applicant);
                setApplicantHandicap_qualifications(applications.applications.application.applicant.handicap_qualifications);
                setFinal_education(applications.applications.application.applicant.educational_background.final_education);
                setGraduation_date(applications.applications.application.applicant.educational_background.graduation_date);
                setNew_graduate(applications.applications.application.applicant.educational_background.new_graduate);
                setSupport_agency_exists(applications.applications.application.applicant.employment_support_agency.support_agency_exists);
                setArrival_date(applications.applications.application.applicant.job_career_recent.arrival_date);
                setBusiness_content(applications.applications.application.applicant.job_career_recent.business_content);
                setCompany(applications.applications.application.applicant.job_career_recent.company);
                setDeparture_date(applications.applications.application.applicant.job_career_recent.departure_date);
                setIndustry(applications.applications.application.applicant.job_career_recent.industry);
                setJob_category(applications.applications.application.applicant.job_career_recent.job_category);
                setJob_career_recent_exists(applications.applications.application.applicant.job_career_recent_exists);
                setUpdate_statuses(applications.applications.application.update_statuses);
                setStatusName(applications.applications.application.status.name);
                setApplicantHandicap_types(applications.applications.application.applicant.handicap_types);
                setSearch_policy(applications.applications.application.applicant.preferred.search_policy);
                setApplicant_compatibilities(applications.applications.application.applicant.preferred_hasmany.applicant_compatibilities);
                setWorking_hour(applications.applications.application.applicant.preferred.working_hour);
                setWork_from_home(applications.applications.application.applicant.preferred.work_from_home);
                setPrefectures(applications.applications.application.applicant.preferred.prefectures);
                setCommuting_methods(applications.applications.application.applicant.preferred.commuting_methods);
                setEmployment_statuses(applications.applications.application.applicant.preferred_hasmany.employment_statuses);
                setAnnual_income(applications.applications.application.applicant.preferred.annual_income);
                setJob_categories(applications.applications.application.applicant.preferred.job_categories);
                if (applications.applications.application.status.id == 1) {
                    console.log("既読処理")
                    param = '/applications/' + id + '/read';
                    setReadLoad(false);
                    dispatch(fetchApplicationsRequest(param, 'get', null));                    
                }
            }
        } else if(applications.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [dispatch, applications, readLoad]);
    
    function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
        }
    }

    function replacedContent(value: any) {
        if (value === null) {
          return null;
        }
        if(value == undefined) {
            return value;
        }
        return value.replaceAll("\n", '<br>');
    }

    function changeUpdateStatus (value: any, id: any) {
        value = JSON.parse(value);
        //「日程調整に進む」
        if (value.id === 0) {
          myRouterPush(true, null, id);
          return
        }
        // 確認メッセージ
        let result = false;
        if (value.id === 6) {
          let str = '';
          str = 'ステータスを【内定通知】に変更しますか。\n'
          str += '変更すると候補者に自動メッセージが送付されます。\n\n'
          str += '＝＝＝＝＝\n'
          str += '【要確認:内定通知】\n'
          str += 'この度は弊社求人にご応募頂き、誠にありがとうございました。選考の結果、採用させていただくことが内定しましたのでご連絡いたします。\n'
          str += 'ご承諾頂ける場合は、内定承諾ボタンをお願いいたします。\n'
          str += '内定承諾後、別途入社手続きのご案内をさせていただきます。ご一緒に働けることを楽しみにしております。\n'
          str += '＝＝＝＝＝\n\n'
          str += 'お間違いのないようご確認ください。'
          result = window.confirm(str);
        } else {
          result = window.confirm('ステータスを【 ' + value.name + ' 】に変更しますか？\n間違ってしまうと戻せませんのでご注意ください。');
        }
        if (!result) {
          return window.location.reload();
        }
        setNewStatusId(value.id);
        setOldId(id);
        setApiType('put');
  
        // サーバとの通信
        setReadLoad(true);
        dispatch(fetchApplicationsRequest('/applications/' + id, 'put', value.id));        
      }

      function myRouterPush(send_schedule: any, template: any, id: any) {
        // setTimeout( () => navigate('/recruiter/applications/' + id + '/messages?send_schedule=' + send_schedule + '&template=' + template), 1500);
        window.location.href = '/recruiter/applications/' + id + '/messages?send_schedule=' + send_schedule + '&template=' + template;
      }

    return (
        <main id="company">
            <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
            {loadScreen ? <Loader /> : <></>}
            <section>
                {/* <div className="flex justify-center items-center"> */}
                <div className='absolute right-0 mr-2 mt-2'>
                {/* <Loader> */}
                    <ClipLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        id="semiTransparenDiv"
                    />
                {/* </Loader> */}
                </div>
                
                <ul className="bread_crumb">
                    <li><a>トップ</a></li>
                    <li><a>企業様マイページ</a></li>
                    <li><a>応募管理 / 応募者詳細</a></li>
                </ul>
                <div className="ttl_box">
                    <h2 className="ttl">応募管理 ＞ 応募者詳細</h2>
                    <ul>
                        <li>
                            <a href="#" onClick={logout}>
                                <img src="/assets/img/company/icon02.svg" alt="" />ログアウト
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="message cont1">
                    <div className="ttl_box">
                            <a href={'/recruiter/applications/' + id +'/'} className="ttl active">
                                <p>応募者詳細</p>
                            {/* <span className="">未入力項目あり</span> */}
                            {/* <img src="/assets/img/mypage/arrow01.png" alt=""/> */}
                            </a>
                            <a href={'/recruiter/applications/' + id +'/messages'} className={  application.unread ? 'new_icon ttl' : 'ttl'}>
                            <p>メッセージ</p>
                            {/* <span className="">未入力項目あり</span>
                            <img src="/assets/img/mypage/arrow01.png" alt=""/> */}
                            </a>
                    </div>
                    <div className="cont_box">
                        <template className='template'>
                            <div className="name_box">
                                {/* <slot /> */}
                                <img src={applicant.face_photo } alt="applicant.family_name + applicant.first_name" />
                                <div>
                                <h3>{ applicant.family_name } { applicant.first_name }
                                    （{ applicant.family_name_kana } { applicant.first_name_kana }）
                                    {applicantHandicap_types && applicantHandicap_types.map((data: any, index: any ) => (
                                        <span className="tag" key={index}>{ data }</span>
                                    ))} 
                                </h3>
                                {/* <!-- <p>最終ログイン日：1週間以内</p> --> */}
                                </div>
                                <div className="spacer" />

                                <template className='template'>
                                    <div>
                                        {update_statuses.length == 0 ?
                                            <select disabled>
                                                <option value="" disabled selected>{ statusName }</option>
                                            </select>
                                        :
                                            <select onChange={(e) => changeUpdateStatus(e.target.value, id)}>
                                                <option value="" disabled selected>{ statusName }</option>
                                                {update_statuses && update_statuses.map((update_status: any ) => (
                                                        <option value={JSON.stringify(update_status)}>{ update_status.name }</option>
                                                ))} 
                                            </select>
                                        }
                                    </div>
                                </template>
                                {application.count_down_alert != '' && application.count_down_alert <= 3 ?
                                    <div className="tooltip_box">
                                        <p className="countdown">残り：{ application.count_down_alert } 日</p>
                                        <div className="tooltip" v-show="application.count_down_alert">
                                            <p className="icon"><img src="~/assets/img/company/icon32.svg" alt=""/></p>
                                            <p className="text">※仕様上、ステータスが「10日間」変更がない場合、自動で「不採用」に変更されます。<br/>
                                            次回選考の日程調整に時間がかかる場合は応募詳細より求職者様の連絡先へ直接ご連絡ください。</p>
                                        </div>
                                    </div>
                                :<></>}
                                <p className="note">※ステータスを変更いただくことで採用活動が次のステップへ移ります。</p>
                            </div>
                        </template>
                            <div className="detail_box">
                                <div>
                                    <h4>企業に伝えたい項目</h4>
                                    <dl className="type1">
                                        <dt>{ applicantAppealItem.appeal_item_key_1 }</dt>
                                        <dd>
                                            <div dangerouslySetInnerHTML={{__html: replacedContent(applicantAppealItem.appeal_item_value_1?.replaceAll('9999-12', '終了月なし（継続中）') ) || ''}}></div>
                                            {/* <div>{applicantAppealItem.appeal_item_value_1}</div> */}
                                        </dd>
                                        <dt>{ applicantAppealItem.appeal_item_key_2 }</dt>
                                        <dd>
                                            <div dangerouslySetInnerHTML={{__html: replacedContent(applicantAppealItem.appeal_item_value_2?.replaceAll('9999-12', '終了月なし（継続中）') ) || ''}}></div>
                                            {/* <div>{applicantAppealItem.appeal_item_value_2}</div> */}
                                        </dd>
                                        <dt>{ applicantAppealItem.appeal_item_key_3 }</dt>
                                        <dd>
                                            <div dangerouslySetInnerHTML={{__html: replacedContent(applicantAppealItem.appeal_item_value_3?.replaceAll('9999-12', '終了月なし（継続中）') ) || ''}}></div>
                                            {/* <div>{applicantAppealItem.appeal_item_value_3}</div> */}
                                        </dd>
                                    </dl>
                                </div>
                                <div>
                                <h4>採用担当者が知りたい項目</h4>
                                <dl className="type1">
                                    <dt>{ recruitmentAppealItem.appeal_item_key_1 }</dt>
                                    <dd>
                                        <div dangerouslySetInnerHTML={{__html: replacedContent(recruitmentAppealItem.appeal_item_value_1?.replaceAll('9999-12', '終了月なし（継続中）')) || ''}}></div>
                                    </dd>
                                    <dt>{ recruitmentAppealItem.appeal_item_key_2 }</dt>
                                    <dd>
                                    <div dangerouslySetInnerHTML={{__html: replacedContent(recruitmentAppealItem.appeal_item_value_2?.replaceAll('9999-12', '終了月なし（継続中）')) || ''}}></div>
                                    </dd>
                                    <dt>{ recruitmentAppealItem.appeal_item_key_3 }</dt>
                                    <dd>
                                    <div dangerouslySetInnerHTML={{__html: replacedContent(recruitmentAppealItem.appeal_item_value_3?.replaceAll('9999-12', '終了月なし（継続中）')) || ''}}></div>
                                    </dd>
                                </dl>
                                </div>
                                <div>
                                <h4>基本情報</h4>
                                <dl className="type2">
                                    <dt>氏名</dt>
                                    <dd>{ applicant.family_name } { applicant.first_name }</dd>
                                    <dt>氏名（フリガナ）</dt>
                                    <dd>{ applicant.family_name_kana } { applicant.first_name_kana }</dd>
                                    <dt>生年月日</dt>
                                    <dd>{ applicant.birthday }</dd>
                                    <dt>障がいの基本情報</dt>
                                    <dd>
                                        {applicantHandicap_qualifications  && applicantHandicap_qualifications.map((data: any, index: any) => (
                                            <><p key={index + 100}>
                                            { data.handicap_type } { data.handicap }<br/>
                                                障がい者手帳 { data.disability_certificate_status }<br/>
                                                    {data.disability_certificate_status == 'あり' ?
                                                        <>{ data.disability_certificate }</>
                                                    :<></>}
                                            </p>
                                            {data.process ?
                                                <p key={index + 200}>発症経緯と経過 { data.process }</p>
                                            :<></>}
                                            {data.characteristics_and_countermeasures ?
                                            <p key={index + 300}>障がい特性と対策 { data.characteristics_and_countermeasures }</p>
                                            :<></>}
                                            </>
                                        ))}
                                    </dd>
                                    <dt>就労上必須の配慮事項</dt>
                                    <dd>
                                        { applicant.required_consideration_conditions }<br/>
                                        <div dangerouslySetInnerHTML={{__html: replacedContent(applicant.consideration_condition_supplemental) || ''}}></div>
                                        {/* <div>{ applicant.consideration_condition_supplemental }</div> */}
                                    </dd>
                                    <dt>郵便番号（半角数字）</dt>
                                    <dd>〒{ applicant.postcode }</dd>
                                    <dt>住所</dt>
                                    <dd>{ applicant.address }<span>※番地は面接が確定次第、公開されます</span></dd>
                                    <dt>メールアドレス</dt>
                                    <dd>{ applicant.email }<span>※面接が確定次第、公開されます</span></dd>
                                    <dt>電話番号</dt>
                                    <dd>{ applicant.mobile_number }<span>※面接が確定次第、公開されます</span></dd>
                                    <dt>最終学歴</dt>
                                    <dd>{ final_education }</dd>
                                    <dt>卒業・卒業見込み年度</dt>
                                    <dd>{ graduation_date }</dd>
                                    <dt>就労支援機関の有無</dt>
                                    <dd>{ support_agency_exists }</dd>
                                    <dt>就職カテゴリー</dt>
                                    <dd>{ new_graduate }</dd>
                                    <dt>直近の職歴</dt>
                                        {job_career_recent_exists === true ?
                                                <dd>
                                                    { company } / { industry }<br/>
                                                    { job_category }<br/>
                                                    { arrival_date } 〜 { departure_date }<br/>
                                                    <div dangerouslySetInnerHTML={{__html: replacedContent(business_content) || ''}}></div>
                                                    {/* <div>{business_content}</div> */}
                                                </dd>
                                        :<></>}
                                        {applicant.job_career_recent_exists === false ?
                                                <dd>{ applicant.job_career_recent }</dd>
                                        :<></>}
                                    <dt>特記事項</dt>
                                    <dd>{ applicant.special_content || '' }</dd>
                                </dl>
                                <br/>
                                <h4>希望条件</h4>
                                <dl className="type2">
                                    <dt>仕事の探し方</dt>
                                    <dd>{ search_policy }</dd>
                                    <dt>働き方</dt>
                                    <dd>{ applicant_compatibilities }</dd>
                                    <dt>就労時間</dt>
                                    <dd>{ working_hour }</dd>
                                    <dt>在宅・リモートワーク<br className="pc_only"/>希望</dt>
                                    <dd>{ work_from_home }</dd>
                                    <dt>希望勤務地</dt>
                                    <dd>{ prefectures }</dd>
                                    <dt>通勤手段</dt>
                                    <dd>{ commuting_methods }</dd>
                                    <dt>希望する雇用形態</dt>
                                    <dd>{ employment_statuses }</dd>
                                    <dt>希望年収</dt>
                                    <dd>{ annual_income }</dd>
                                    <dt>希望職種</dt>
                                    <dd>{ job_categories }</dd>
                                </dl>
                            </div>
                        </div>
                        
                </div>
                <div className="sidebar">
                    {application.other_applications && application.other_applications.length > 0 ?
                        <ul>
                        <h4>この応募者が応募している求人</h4>
                        {application.other_applications.map((data: any) => (
                        <li key={data.id}>
                            <a href={'/recruiter/applications/'+ data.id +'/'}>{ `${data.recruitment.job_title}(求人ID: ${data.recruitment.id})` }</a>
                        </li>
                        ))}                        
                        </ul>
                    : <></>}
                    {application.applicant && application.applicant.navigation_book != null ?
                        <><h4>各種書類ダウンロード</h4>
                        <div className="btn_box2">
                        <a target="_blank" href={application.applicant.navigation_book}>ナビゲーションブック</a>
                        </div></>
                    : <></>}
                </div>
            </div>
        </section>
    </main>
    );
  }
  
  export default RecruiterApplicationsDetail;