import { applicationCreateTypes } from "../../ActionTypes/applicationCreateTypes";
import { ApplicationCreateActions, ApplicationCreateState } from "../../types/type";



const initialState: ApplicationCreateState = {
  pending: false,
  body: [],
  error: null,
};

export default (state = initialState, action: ApplicationCreateActions,) => {
  switch (action.type) {
    case applicationCreateTypes.FETCH_APPLICATION_CREATE_REQUEST:
      return {
        ...state,
        pending: false
      };
    case applicationCreateTypes.FETCH_APPLICATION_CREATE_SUCCESS:
      return {
        ...state,
        pending: true,
        body: action.payload.body,
        error: null
      };
    case applicationCreateTypes.FETCH_APPLICATION_CREATE_FAILURE:
      return {
        ...state,
        pending: false,
        body: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
