import { adminRecruitmentCopyTypes } from "../../ActionTypes/adminRecruitmentCopyTypes";
import { FetchAdminRecruitmentCopyFailure, FetchAdminRecruitmentCopyFailurePayload, FetchAdminRecruitmentCopyRequest, FetchAdminRecruitmentCopySuccess, FetchAdminRecruitmentCopySuccessPayload } from "../../types/type";

export const fetchAdminRecruitmentCopyRequest = (id: any): FetchAdminRecruitmentCopyRequest => ({
  type: adminRecruitmentCopyTypes.FETCH_ADMIN_RECRUITMENT_COPY_REQUEST,
  id
});

export const fetchAdminRecruitmentCopySuccess = (
  payload: FetchAdminRecruitmentCopySuccessPayload
): FetchAdminRecruitmentCopySuccess => ({
  type: adminRecruitmentCopyTypes.FETCH_ADMIN_RECRUITMENT_COPY_SUCCESS,
  payload
});

export const fetchAdminRecruitmentCopyFailure = (
  payload: FetchAdminRecruitmentCopyFailurePayload
): FetchAdminRecruitmentCopyFailure => ({
  type: adminRecruitmentCopyTypes.FETCH_ADMIN_RECRUITMENT_COPY_FAILURE,
  payload
});