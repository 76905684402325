import { loginTypes } from "../../ActionTypes/loginTypes";
import { LoginActions, LoginState } from "../../types/type";

const initialState: LoginState = {
  pending: false,
  login: [],
  error: null,
};

export default (state = initialState, action: LoginActions,) => {
  switch (action.type) {
    case loginTypes.FETCH_LOGIN_REQUEST:
      return {
        ...state,
        pending: false
      };
    case loginTypes.FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        pending: true,
        login: action.payload.login,
        error: null
      };
    case loginTypes.FETCH_LOGIN_FAILURE:
      return {
        ...state,
        pending: false,
        login: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
