import { stepTypes } from "../../ActionTypes/stepTypes";
import { StepActions, StepState } from "../../types/type";

const initialState: StepState = {
  pending: false,
  signup: [],
  error: null,
};

export default (state = initialState, action: StepActions,) => {
  switch (action.type) {
    case stepTypes.FETCH_STEP_REQUEST:
      return {
        ...state,
        pending: false
      };
    case stepTypes.FETCH_STEP_SUCCESS:
      return {
        ...state,
        pending: true,
        signup: action.payload.signup,
        error: null
      };
    case stepTypes.FETCH_STEP_FAILURE:
      return {
        ...state,
        pending: false,
        signup: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
