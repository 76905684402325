import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { articlesTypes } from "../../ActionTypes/articlesTypes";
import { fetchArticlesFailure, fetchArticlesSuccess } from "../../actions/articlesActions/articlesActions";
import { API_BASE_ADDRESS } from "../../API";
import { fetchArticlesRecruiterFailure, fetchArticlesRecruiterSuccess } from "../../actions/articlesRecruiterActions/articlesRecruiterActions";
import { articlesRecruiterTypes } from "../../ActionTypes/articlesRecruiterTypes";

var getRecruiterArticles = (data: any) => axios.get( API_BASE_ADDRESS + '/articles/recruiter?' + data, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 

function* fetchArticlesRecruiterSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getRecruiterArticles, action.data);
    yield put(
      fetchArticlesRecruiterSuccess({
        articles: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchArticlesRecruiterFailure({
        error: e.message
      })
    );
  }
}

function* ArticlesRecruiterSaga() {
  yield takeLatest(articlesRecruiterTypes.FETCH_ARTICLES_RECRUITER_REQUEST, fetchArticlesRecruiterSaga);
}

export default ArticlesRecruiterSaga;
