import { useEffect, useState } from 'react';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { fetchApplicantsRequest } from '../../../redax/actions/applicantsActions/applicantsActions';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchRegionsRequest } from '../../../redax/actions/regionsActions/regionsActions';
import { fetchRecruitmentsApplicationRequest } from '../../../redax/actions/recruitmentsApplicationActions/recruitmentsApplicationActions';
import { useNavigate } from 'react-router-dom';
import { fetchApplicationCompanyRequest } from '../../../redax/actions/applicationCompanyActions/applicationCompanyActions';
import { fetchDownloadApplicationCsvRequest } from '../../../redax/actions/downloadApplicationCsvActions/downloadApplicationCsvActions';
import './applications.scss';
import { fetchApplicationsRequest } from '../../../redax/actions/applicationsActions/applicationsActions';
import Loader from '../../loader/loader';

function RecruiterApplications() {
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);    
    const [resCompanyId, setCompanyId] = useState(null);  
    const [authenticate, setAuthenticate] = useState(null);  
    const [application, setApplication] = useState<any>([]);
    const [applicationBefore, setApplicationBefore] = useState<any>([]);
    const [pages, setPages] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [downCsvPath, setDownCsvPath] = useState('');
    const [update_statuses, setUpdate_statuses] = useState<any>([]);
    const [select1, setSelect1] = useState<any>(null);
    const [select2, setSelect2] = useState(null);
    const [select3, setSelect3] = useState(null);
    const [recruitmentList, setRecruitmentList] = useState<any>([]);
    let applicationStatusList = [];
    let handicapTypeList = [];
    let resPagenation = [];
    let currentStatusName = '';
    const [sort, setSort] = useState('desc');
    let param: any = '';
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const loginData = useSelector((state: RootState) => state.login);
    const { regions  } = useSelector((state: RootState) => state.regions);
    const recruitmentsApplication = useSelector((state: RootState) => state.recruitmentsApplication);
    const applicationCompany = useSelector((state: RootState) => state.applicationCompany);
    const { applicationCsvData  } = useSelector((state: RootState) => state.applicationCsvData);
    const applications = useSelector((state: RootState) => state.applications);
    const [loadScreen, setLoadScreen] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [newStatusId, setNewStatusId] = useState(0);
    const [oldId, setOldId] = useState(0);
    const [apiType, setApiType] = useState('get');
    const pageTitle = '応募管理　一覧｜パラちゃんねる';
    document.title = pageTitle;
    const currentUrl = window.location.href;
    useEffect(() => {
        if (!localStorage.getItem('jobParachannelAuthToken')) {
        
            navigate('/recruiter/login/')
        }
      }, [dispatch]);
    
    useEffect(() => {
        const currentUrlId = currentUrl.split('#')[1];
        setSelect1(currentUrlId);
        // dispatch(fetchLoginRequest('', '',''));
        dispatch(fetchDownloadApplicationCsvRequest()); 
        // dispatch(fetchRegionsRequest());
    }, [dispatch]);

    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/recruiter/init/');
            }
            setResourceId(loginData.login.resource.id);
            setCompanyId(loginData.login.resource.company_id);
            setFamylyName(loginData.login.resource.family_name);
            setAuthenticate(loginData.login.resource.id);
            dispatch(fetchRegionsRequest());
        }      
    }, [dispatch, loginData.pending]);
    
    useEffect(() => {
        if (applications.pending) {
            setApplication(applications.applications.applications);
            resPagenation = applications.applications.pagenation;
            // console.log(resPagenation)
            if (resPagenation !== null && resPagenation !== undefined) {
                setPages(resPagenation.total_pages);
            }
            setLoadScreen(false);
            if(apiType !== 'get') {
                // 画面遷移
                let template =''
                switch (newStatusId) {
                case 6: // 内定
                    // template = '【選考結果のご連絡】\n\n'
                    // template += 'この度は、多数の企業から弊社へご応募頂きまして、誠にありがとうございます。\n\n'
                    // template += '選考の結果、貴殿を採用させていただくことが内定しましたのでご連絡いたします。\n'
                    // template += '入社手続きに必要な書類に関しましては、別途ご連絡をさせていただきます。\n\n'
                    // template += '貴殿と働ける日を社員一同心待ちにしております。'
                    myRouterPush(false, null, oldId)
                    break;
                case 8: // 不採用
                    template = '選考の結果、誠に残念ながら、この度は採用を見送らせていただくことになりました。\n'
                    template += 'ご希望に添えず恐縮ではございますが、ご了承くださいますようお願いいたします。'
                    myRouterPush(false, template, oldId)
                    break;
                default:
                    window.location.reload()
                    break;
                }
            }
        } else if(applications.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }      
    }, [applications, pages]);  

    useEffect(() => {
        if(applicationCsvData.file !== undefined) {
            setDownCsvPath(applicationCsvData.file);
        }   
    }, [applicationCsvData]);  
   
    if (regions !== null) {
        applicationStatusList = regions.application_statuses;
        handicapTypeList = regions.handicap_types;
    }

    useEffect(() => {
        if (authenticate !== null) {
        //   dispatch(fetchApplicantsRequest(authenticate, 'get', 0));
        }
    }, [authenticate]);  

    useEffect(() => {
        if(resCompanyId !== null && resCompanyId !== undefined) {
            dispatch(fetchRecruitmentsApplicationRequest(resCompanyId));
            dispatch(fetchApplicationCompanyRequest(resCompanyId, undefined));
        }
    }, [resCompanyId]);

    useEffect(() => {
        if (recruitmentsApplication.pending) {
            setRecruitmentList(recruitmentsApplication.recruitmentsApplication.recruitments);
            setLoadScreen(false);
        } else if(recruitmentsApplication.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [recruitmentsApplication]);

    useEffect(() => {
        if (applicationCompany.pending && firstLoad) {
            setFirstLoad(false);
            setApplication(applicationCompany.applicationCompany.applications);
            setApplicationBefore(applicationCompany.applicationCompany.applications);
            resPagenation = applicationCompany.applicationCompany.pagenation;
            // console.log(resPagenation)
            if (resPagenation !== null && resPagenation !== undefined) {
                setPages(resPagenation.total_pages);
            }
            setLoadScreen(false);
        } else if(applicationCompany.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [applicationCompany, pages, firstLoad]);      

    function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
        }
    }

    function filterClear () {
        // console.log('clear')
        setSelect1(null);
        setSelect2(null);
        setSelect3(null);
        setApplication(applicationBefore);
        // filterApp();
    }

    function filterApp () {
        let s1 = select1 == null ? '' : select1;
        let s2 = select2 == null ? '' : select2;
        let s3 = select3 == null ? '' : select3;
        param = '/applications/?company_id=' + resCompanyId + '&recruitment_id=' + s1 + '&status_id=' + s2 + '&handicap_type_id=' + s3+ '&page=' + page;
        if(param !== null) {
            setLoadScreen(true);
            dispatch(fetchApplicationsRequest(param, 'get', null));
            // setApplication(applications.applications);
        } else {
            window.alert('サーバーエラー');
        }
    }

    function changeSort (val: any) {        
        let sorts = (val == 'desc') ? 'asc' : 'desc';
        setSort(sorts);
        param = '/applications/?company_id=' + resCompanyId + '&sort=date&order=' + sorts + '&page=' + page;
        if(param !== null) {
            setLoadScreen(true);
            dispatch(fetchApplicationsRequest(param, 'get', null));
            // setApplication(applications.applications);
        } else {
            window.alert('サーバーエラー');
        }
    }

    const pageIncriment = () => {
        if (page >= pages) {
          setPage(pages);
          return;
        }
        setPage((prevPage) => prevPage + 1);
        filterApp();
        // dispatch(fetchRecruitmentsCompanyRequest(companyId, page));
    };
    
    const pageDecriment = () => {
        if (page <= 1) {
            setPage(1);
            return;
        }
        setPage((prevPage) => prevPage - 1);
        filterApp();
        // dispatch(fetchRecruitmentsCompanyRequest(companyId, page));
    };
    
    const updatePage = (p: any) => {
        setPage(p);
        filterApp();
        // dispatch(fetchRecruitmentsCompanyRequest(companyId, page));
    };

    function downloadCSV () { 
        if(downCsvPath !== '') { 
            // console.log(downCsvPath)           
            window.open(downCsvPath, '_blank1', 'noreferrer');
        } else {
            window.alert('CSVダウンロードエラー');
        }

    }

    function changeUpdateStatus (value: any, id: any) {
        value = JSON.parse(value);
        //「日程調整に進む」
        if (value.id === 0) {
          myRouterPush(true, null, id);
          return
        }
        // 確認メッセージ
        let result = false;
        if (value.id === 6) {
          let str = '';
          str = 'ステータスを【内定通知】に変更しますか。\n'
          str += '変更すると候補者に自動メッセージが送付されます。\n\n'
          str += '＝＝＝＝＝\n'
          str += '【要確認:内定通知】\n'
          str += 'この度は弊社求人にご応募頂き、誠にありがとうございました。選考の結果、採用させていただくことが内定しましたのでご連絡いたします。\n'
          str += 'ご承諾頂ける場合は、内定承諾ボタンをお願いいたします。\n'
          str += '内定承諾後、別途入社手続きのご案内をさせていただきます。ご一緒に働けることを楽しみにしております。\n'
          str += '＝＝＝＝＝\n\n'
          str += 'お間違いのないようご確認ください。'
          result = window.confirm(str);
        } else {
          result = window.confirm('ステータスを【 ' + value.name + ' 】に変更しますか？\n間違ってしまうと戻せませんのでご注意ください。');
        }
        if (!result) {
          return window.location.reload();
        }
        setNewStatusId(value.id);
        setOldId(id);
        setApiType('put');
  
        // サーバとの通信
        const data = { status_id: value.id }
        dispatch(fetchApplicationsRequest('/applications/' + id, 'put', value.id));        
      }

      function myRouterPush(send_schedule: any, template: any, id: any) {
        // setTimeout( () => navigate('/recruiter/applications/' + id + '/messages?send_schedule=' + send_schedule + '&template=' + template), 1500);
        window.location.href = '/recruiter/applications/' + id + '/messages?send_schedule=' + send_schedule + '&template=' + template;
      }

      const onChangeSelect = (event: any) => {
        const {name, value} = event.target;
        // console.log(value);
        if(name === 'search1') {
            setSelect1(value);
        }
        if(name === 'search2') {
            setSelect2(value);
        }
        if(name === 'search3') {
            setSelect3(value);
        }
    }

    return (
        <main id="company">
            <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
            {loadScreen ? <Loader /> : <></>}
            <section>
                <ul className="bread_crumb">
                    <li>
                        <a href="/" className="nuxt-link-active">トップ</a>
                    </li> 
                    <li>
                        <a href="/recruiter/" className="nuxt-link-active">企業様マイページ</a>
                    </li> 
                    <li>
                        <a>応募管理</a>
                    </li>
                </ul> 
                <div className="ttl_box">
                    <h2 className="ttl">応募管理 ＞ 一覧</h2> 
                    <ul>
                        <li>
                            <a onClick={logout} href='#'>
                                <img src="/assets/img/company/icon02.svg" alt=""/>ログアウト
                            </a>
                        </li>
                    </ul>
                </div> 
                <div className="apply">
                    <div className="edit_box">
                        <form>
                            <select name="search1" onChange={onChangeSelect} value={select1 || ''}>
                                <option value="" disabled selected>求人</option> 
                                {recruitmentList && recruitmentList.map((recList: any ) => (
                                        <option selected={select1 !== null && (recList.id === select1 ? recList.name === recList.name : recList.name === "")} value={recList.id}>{ recList.job_title + '(求人ID:' + recList.id + ')'}</option>
                                ))}
                            </select> 
                            <select name="search2" onChange={onChangeSelect} value={select2 || ''}>
                                <option value='' disabled selected>ステータス</option>
                                {applicationStatusList && applicationStatusList.map((appStatus: any ) => (
                                        <option value={appStatus.id}>{ appStatus.name }</option>
                                ))} 
                            </select> 
                            <select name="search3" onChange={onChangeSelect} value={select3 || ''}>  
                                <option value='' disabled selected>障がい区分</option>                              
                                {handicapTypeList && handicapTypeList.map((hand: any ) => (
                                        <option value={hand.id}>{ hand.name }</option>
                                ))} 
                            </select> 
                            <button type="button" className="btn type3" onClick={filterApp}>絞り込み</button> 
                            <a className="btn type3 gray" onClick={filterClear}>リセットする</a>
                        </form>
                        {application != undefined && application.length == 0 ?
                            <div>
                                <p>まだ応募はありません</p>
                            </div>
                        :<></>}
                        {application != undefined && application.length > 0  ?
                            <div>
                                <div className="icon">                                
                                    {/* <label>
                                        <input type="checkbox" name="checkbox" />
                                    </label>
                                    <a href="#">
                                        <img src="/assets/img/company/icon05.svg" alt="削除"/>
                                    </a> */}
                                </div> 
                                <div>
                                    {sort === 'desc' ?
                                        <a href="javascript:void(0)" onClick={() => changeSort('desc')}>応募日時：降順
                                            <img src="/assets/img/company/icon29.svg" alt="" />
                                        </a>
                                    :<></>}
                                    {sort === 'asc' ?
                                        <a href="javascript:void(0)" onClick={() =>changeSort('asc')}>応募日時：昇順
                                            <img src="/assets/img/company/icon29.svg" alt=""/>
                                        </a>
                                    :<></>}
                                    <ul className="pagination">
                                        <li className={page <= 1 ? 'arrow prev disabled' : 'arrow prev'}>
                                            <a href="javascript:void(0)" onClick={pageDecriment}>
                                                <img src="/assets/img/company/icon06.svg" alt="次へ" />
                                            </a>
                                        </li> 
                                        {Array.from({ length: pages }, (_, i) => i + 1).map((p) => (
                                            <li key={p} className={`page ${page === p ? 'active' : ''}`}>
                                            <a href="javascript:void(0)" onClick={() => updatePage(p)}>
                                                <span>{p}</span>
                                            </a>
                                            </li>
                                        ))}
                                        <li className={page >= pages ? 'arrow next disabled' : 'arrow next'}>
                                            <a href="javascript:void(0)" onClick={pageIncriment}>
                                                <img src="/assets/img/company/icon07.svg" alt="前へ" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div> 
                        :<></>}
                        <p className="csv_download">
                            <a className="btn type3" onClick={downloadCSV}>CSVダウンロード
                                <img src="/assets/img/company/icon30.svg" alt="" />
                            </a>
                        </p> 
                        <p className="note">※仕様上、ステータスが「10日間」変更がない場合、自動で「不採用」に変更されます。<br/>
                            ※ステータスを変更いただくことで採用活動が次のステップへ移ります。
                        </p> 
                        {application != undefined && application.length > 0 ?
                        <ul className="list_box">
                            {application && application.map((a: any ) => (
                                <li  key={a.id} className={ 8 <= a.status.id ? 'end_cont' : ''}>
                                    <div>
                                        <div className="cont1">
                                            <div className="img_box">
                                                <a href={'/recruiter/applications/' + a.id +'/'}>
                                                    <img src={a.applicant.face_photo} alt={a.applicant.family_name +' '+ a.applicant.first_name}/>
                                                </a>
                                            </div> 
                                            <div className="text_box" style={{ margin: "0"}}>
                                                <a href={'/recruiter/applications/' + a.id +'/'}>
                                                    <h3>
                                                        <span className="_flex">{ a.applicant.family_name } { a.applicant.first_name }（{ a.applicant.family_name_kana } { a.applicant.first_name_kana }）
                                                            <span className="date">{ a.date }</span>
                                                        </span> 
                                                        <span className="_flex">
                                                            <span className={a.status.id == 7 ? "category type3" : "category"}>{ a.status.name }</span>
                                                            {a.applicant.handicap_types && a.applicant.handicap_types.map((h: any ) => (
                                                                <span className="category" key={h}>{ h }</span> 
                                                            ))}
                                                            {a.unread ?
                                                                <span className="category type1">未読メッセージがあります</span>                       
                                                            :<></>}
                                                            {a.status.id == 1 && !a.is_read ?
                                                                <span className="category type1 new">
                                                                    <span>NEW</span>
                                                                </span>                      
                                                            :<></>}
                                                        </span>
                                                    </h3>
                                                </a>
                                                {a.count_down_alert != '' && a.count_down_alert <= 3 ?
                                                    <div className="tooltip_box">
                                                        <p className="countdown">残り：{ a.count_down_alert } 日</p>
                                                        {a.count_down_alert ?
                                                            <div className="tooltip">
                                                                <p className="icon"><img src="/assets/img/company/icon32.svg" alt=""/></p>
                                                                <p className="text">※仕様上、ステータスが「10日間」変更がない場合、自動で「不採用」に変更されます。<br/>
                                                                次回選考の日程調整に時間がかかる場合は応募詳細より求職者様の連絡先へ直接ご連絡ください。</p>
                                                            </div>
                                                        :<></>}
                                                    </div>
                                                :<></>}
                                                <p className="p">生年月日: { a.applicant.birthday }</p>
                                                <p className="p">障がい者手帳: { a.applicant.disability_certificate_exists }</p> 
                                                <p className="p">希望勤務地: { a.applicant.preferred.prefectures || "無" }</p> 
                                                <p className="p">希望職種: { a.applicant.preferred.job_categories || "無" }</p> 
                                                <p className="p">就労支援機関の有無: { a.applicant.support_agency_exists }</p>
                                                <div>
                                                    {a.update_statuses.length == 0 ?
                                                        <select disabled>
                                                            <option value="null" disabled selected>{ a.status.name }</option>
                                                        </select>
                                                    :
                                                    <select v-model="update_status_id" onChange={(e) => changeUpdateStatus(e.target.value, a.id)}>
                                                        <option value="null" disabled selected>{ a.status.name }</option>
                                                        {a.update_statuses && a.update_statuses.map((update_status2: any ) => (
                                                                <option value={JSON.stringify(update_status2)}>{ update_status2.name }</option>
                                                        ))} 
                                                    </select>
                                                    }
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="cont2">
                                            <p>
                                                <a href={'/recruitments/' + a.recruitment.id}>{ `${a.recruitment.job_title}(求人ID: ${a.recruitment.id})` } に応募</a>
                                            </p> 
                                            <p></p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>                         
                        :<></>}
                        {application != undefined && application.length > 0 ?
                            <div className="bottom">
                                <ul className="pagination">
                                    <li className={page <= 1 ? 'arrow prev disabled' : 'arrow prev'}>
                                        <a href="#" onClick={pageDecriment}>
                                            <img src="/assets/img/company/icon06.svg" alt="次へ" />
                                        </a>
                                    </li> 
                                    {Array.from({ length: pages }, (_, i) => i + 1).map((p) => (
                                        <li key={p} className={`page ${page === p ? 'active' : ''}`}>
                                        <a href="#" onClick={() => updatePage(p)}>
                                            <span>{p}</span>
                                        </a>
                                        </li>
                                    ))}
                                    <li className={page >= pages ? 'arrow next disabled' : 'arrow next'}>
                                        <a href="#" onClick={pageIncriment}>
                                            <img src="/assets/img/company/icon07.svg" alt="前へ" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        :<></>}
                    </div>
                </div>
            </section>
        </main>
    );
}

export default RecruiterApplications;