import { RecruiterPlanTypes } from "../../ActionTypes/recruiterPlan";
import { RecruiterPlanActions, RecruiterPlanState } from "../../types/type";


const initialState: RecruiterPlanState = {
  pending: false,
  recruiterPlan: [],
  error: null,
};

export default (state = initialState, action: RecruiterPlanActions,) => {
  switch (action.type) {
    case RecruiterPlanTypes.FETCH_RECRUITER_PLAN_REQUEST:
      return {
        ...state,
        pending: false
      };
      case RecruiterPlanTypes.FETCH_RECRUITER_PLAN_SUCCESS:
      return {
        ...state,
        pending: true,
        recruiterPlan: action.payload.recruiterPlan,
        error: null
      };
    case RecruiterPlanTypes.FETCH_RECRUITER_PLAN_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
