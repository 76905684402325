
import { recruitmentsListTypes } from "../../ActionTypes/recruitmentsListTypes";
import {  RecruitmentsListActions, RecruitmentsListState } from "../../types/type";

const initialState: RecruitmentsListState = {
  pending: false,
  recruitmentsList: [],
  error: null,
};

export default (state = initialState, action: RecruitmentsListActions,) => {
  switch (action.type) {
    case recruitmentsListTypes.FETCH_RECRUIMENTS_LIST_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitmentsListTypes.FETCH_RECRUIMENTS_LIST_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitmentsList: action.payload.recruitmentsList,
        error: null
      };
    case recruitmentsListTypes.FETCH_RECRUIMENTS_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        recruitmentsList: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
