import axios, { AxiosResponse } from "axios";
import { API_BASE_ADDRESS } from "../../API";
import { call, put, takeLatest } from "redux-saga/effects";
import { MessagesPostTypes } from "../../ActionTypes/messagesPostTypes ";
import { fetchRecruiterPlanPostFailure, fetchRecruiterPlanPostSuccess } from "../../actions/recruiterPlanPostActions/recruiterPlanPostActions";
import { RecruiterPlanPostTypes } from "../../ActionTypes/recruiterPlanPostTypes";

var postPlan = (data: any) => axios.post(API_BASE_ADDRESS + "/recruiter/plan", data , {
  headers: {
  Authorization: localStorage.getItem('jobParachannelAuthToken'),
  Accept: '*/*',
  Prefer: 'example=test-1',
  'Content-Type': 'application/json',
  }
}); 
function* fetchRecruiterPlanPostSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(postPlan, queryParams.data);
    yield put(
      fetchRecruiterPlanPostSuccess({
        recrutierPlan: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruiterPlanPostFailure({
        error: e.message
      })
    );
  }
}

function* recruiterPlanPostSaga() {
  yield takeLatest(RecruiterPlanPostTypes.FETCH_RECRUITER_PLAN_POST_REQUEST, fetchRecruiterPlanPostSaga);
}

export default recruiterPlanPostSaga;
