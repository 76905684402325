import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import AdminNav from '../../adminTop/AdminNav';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchRecruiterPlanRequest } from '../../../redax/actions/recruiterPlanActions/recruiterPlanActions';
import { fetchRecruiterPricingRequest } from '../../../redax/actions/recruiterPricingActions/recruiterPricingActions';
import { fetchRecruiterReceiptPostRequest } from '../../../redax/actions/recruiterReceiptPostActions/recruiterReceiptPostActions';

var isCheckPlanName = false;
var isHide = false;
const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 2;
const day = 1;
const YYYY = year + '年' + month + '月' + day + '日';
let total = 0;
let nextTotal = 0;
const ChangeAddress = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recruiterPlan = useSelector((state: RootState) => state.recruiterPlan);
  const pricing = useSelector((state: RootState) => state.recruiterPricing);
  const loginData = useSelector((state: RootState) => state.login);
  const receiptInfo = useSelector((state: RootState) => state.receiptInfo);
  // const [plan, setPlan] = useState();
  const [companyId, setCompanyId] = useState();
  const [planName, setPlanName] = useState();
  const [planPrice, setPlanPrice] = useState();
  const [nextContractPlanName, setNextContractPlanName] = useState();
  const [nextContractPlanPrice, setNextContractPlanPrice] = useState();
  const [currenPlanPrice, setcurrenPlanPrice] = useState<any>();
  const [currentPlanId, setCurrentPlanId] = useState();
  const [currentPlanName, setCurrentPlanName] = useState();
  const [filteredPrice, setFilteredPrice] = useState();
  const [planList, setPlanList] = useState(Boolean);
  const [optionName1, setOptionName1] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [nextTotalPrice, setNextTotalPrice] = useState(0);
  const [currentDrOption, setCurrentDrOption] = useState(false);
  const [currentWarrantyOption, setCurrentWarrantyOption] = useState(false);
  const [planListsName, setPlanListsName] = useState();
  const [planListsName1, setPlanListsName1] = useState();
  const [planListsPrice, setPlanListsPrice] = useState();
  const [planListsPrice1, setPlanListsPrice1] = useState();
  const [nextPlanListsPrice, setNextPlanListsPrice] = useState();
  const [nextPlanListsPrice1, setNextPlanListsPrice1] = useState();
  const [nextPlanListsName, setNextPlanListsName] = useState();
  const [nextPlanListsName1, setNextPlanListsName1] = useState();
  const [monthDirectScoutLimit, setMonthDirectScoutLimit] = useState();
  const [receipt_address, setReceipt_address] = useState<any>();
  const [receipt_address2, setReceipt_address2] = useState<any>();
    
  useEffect(() => {
    if (loginData.pending) {
      setReceipt_address(loginData.login.receipt_address);
      setReceipt_address2(loginData.login.receipt_address2);
    }
  }, [loginData.pending]);

  useEffect(() => {
    if (localStorage.getItem('jobParachannelAuthToken')) {
      // dispatch(fetchLoginRequest('', '',''));
      dispatch(fetchRecruiterPlanRequest());
      dispatch(fetchRecruiterPricingRequest());
    } else (
        navigate('/recruiter/login/')
    )
  }, [dispatch]);
    
  useEffect(() => {
    total = 0;
    nextTotal = 0;
    if(recruiterPlan !== undefined && recruiterPlan.pending && pricing.pending){
      setCompanyId(recruiterPlan.recruiterPlan.company_id);
      var currentName = recruiterPlan.recruiterPlan.current_plan_name;
      if(currentName == "無料" || currentName == "無料プラン") {
        isCheckPlanName = true;
        isHide = false;
      } else {
        isCheckPlanName = false;
        isHide = true;
      }
      const currentPlanId = recruiterPlan.recruiterPlan.current_plan_id;
      setCurrentDrOption(recruiterPlan.recruiterPlan.current_dr_option);
      setCurrentWarrantyOption(recruiterPlan.recruiterPlan.current_warranty_option);
      setCurrentPlanName(recruiterPlan.recruiterPlan.current_plan_name);
      const currentPlan = recruiterPlan.recruiterPlan.plan_lists.find((plan:any) => plan.id === currentPlanId);
      const nextContractPlan = recruiterPlan.recruiterPlan.next_contract;
      const nextContractPlanId = pricing.recruiterPricing.plan_lists.find((plan:any) => plan.id === recruiterPlan.recruiterPlan.next_contract.plan_id);
      const optionName = pricing.recruiterPricing.plan_lists.find((plan:any) => plan.id === currentPlanId);
      const planList = pricing.recruiterPricing.plan_lists; 
      if(nextContractPlanId && nextContractPlanId.length > 0){
        setNextPlanListsName(nextContractPlanId.option[0].option_name);
        setNextPlanListsName1(nextContractPlanId.option[1].option_name);
        setNextPlanListsPrice(nextContractPlanId.option[0].price);
        setNextPlanListsPrice1(nextContractPlanId.option[1].price);
      };           
      if(optionName && optionName.length > 0){
        setPlanListsPrice(optionName.option[0].price);
        setPlanListsPrice1(optionName.option[1].price);
        setMonthDirectScoutLimit(optionName.option[1].month_direct_scout_limit);
        let a = optionName.option[0].option_name;
        let b = optionName.option[1].option_name;
        setPlanListsName(a);
        setPlanListsName1(b);
      }
      if(recruiterPlan.recruiterPlan.current_dr_option && recruiterPlan.recruiterPlan.current_warranty_option ){
        setTotalPrice(optionName.option[0].price + optionName.option[1].price)
      } else if(recruiterPlan.recruiterPlan.current_dr_option && !recruiterPlan.recruiterPlan.current_warranty_option){
        setTotalPrice(optionName.option[0].price)
      } else if (!recruiterPlan.recruiterPlan.current_dr_option && recruiterPlan.recruiterPlan.current_warranty_option) {
        setTotalPrice(optionName.option[1].price)
      } else {
        setTotalPrice(0);
      }
      if (currentPlan && currentPlan.length > 0) {
        setPlanName(currentPlan.name);
        setPlanPrice(currentPlan.price);
      } else {
          // Handle the case where the current plan is not found
      }

      if (nextContractPlan && nextContractPlan.length > 0) {
        nextTotal = 0;
        setNextContractPlanName(recruiterPlan.recruiterPlan.next_contract.plan_name);          
        setNextContractPlanPrice(recruiterPlan.recruiterPlan.next_contract.plan_price);
        for(let j = 0; j < planList.length; j++) {
          if(planList[j].id == recruiterPlan.recruiterPlan.next_contract.plan_id){
            if(recruiterPlan.recruiterPlan.next_contract.dr_option == true) {
              setNextTotalPrice(nextTotal += planList[j].option[0].price);
            }
            if(recruiterPlan.recruiterPlan.next_contract.warranty_option == true) {      
              setNextTotalPrice(nextTotal += planList[j].option[1].price);
            }
          }
        }
      }
      setcurrenPlanPrice(recruiterPlan.recruiterPlan.current_plan_price);
      setCurrentPlanId (recruiterPlan.recruiterPlan.current_plan_id);
      // Find the plan object whose id matches the current_plan_id
      const filteredPlan = recruiterPlan.recruiterPlan.plan_lists.find((plan:any) => plan.id === 1);
      // Retrieve the price from the filtered plan
      setFilteredPrice( filteredPlan ? filteredPlan.price : null );
      if(filteredPlan == undefined){
        setPlanList(false);
      } else {
        setPlanList(true);
      }
      if (optionName && optionName.length > 0) {
        setTotalPrice(optionName.option[0].price + optionName.option[1].price);
      } else {
        // Handle the case where the current plan is not found
      }
    }
  }, [recruiterPlan]);

  function logout() {
    if (localStorage.entryFlag) {
      localStorage.removeItem("entryFlag");
    }
    if (localStorage.jobParachannelAuthToken) {
      localStorage.removeItem("jobParachannelAuthToken");
      navigate("/");
      window.location.reload();
    }
  }

  function changeReceiptAddress(e: any) {
    setReceipt_address(e.target.value);
  }

  function changeReceiptAddressTwo(e: any) {
    setReceipt_address2(e.target.value);
  }

  const receipt_params = {
    "receipt_address": receipt_address,
    "receipt_address2": receipt_address2
  }

  function handlePost() {
    if(window.confirm('宛名変更を完了してもよろしいですか？')) {
      dispatch(fetchRecruiterReceiptPostRequest(receipt_params)); 
    }           
  }

  useEffect(()=> {   
    if(receiptInfo.pending) {     
      if(receiptInfo !== null) { 
        window.location.href = '/recruiter/plan';  
      }
    } else if(receiptInfo.error != null) {
      navigate('/error?code=500');
    } else if(receiptInfo.status == 204) {
      window.location.href = '/recruiter/plan';      
    }
  }, [receiptInfo]);
  
  return (
    <main id='company'>
        <section className='sectionScouts' style={{width:"100%"}}>
          <div style={{display:"flex", justifyContent:"space-between", width:"100%",}}>
            <ul className="bread_crumb">
              <li>
                <a href="/">トップ</a>
              </li>
              <li>
                <a href="/recruiter/">企業様マイページ</a>
              </li>
              <li>
                <a href="#">プラン管理</a>
              </li>
            </ul>
            <div className="ttl_box">
              <ul>
                <li>
                  <a href="#" onClick={logout}>
                    <img src="/assets/img/company/icon02.svg" alt="" />
                    ログアウト
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div style={{width:"100%",padding:"0px 4%"}}>
            <h2 className='adminH2' style={{margin:"0"}}>宛名の変更</h2>
            <hr style={{height:"2px", width:"100%", background:"#00A0E9"}}/>
            <div style={{display:"flex", height:"40px",}}>
              <div style={{marginRight:"10px"}}>
                <p style={{color:"gray"}}>(例)株式会社キャリアート</p>
                <input type="text" name='receiptAddress' onChange={changeReceiptAddress} placeholder={`宛名の変更`} defaultValue={receipt_address} style={{borderRadius:"5px", width:"100%", padding:"10px",marginRight:"10px"}} />
              </div>
              <div style={{marginRight:"10px"}}>
                <p style={{color:"gray"}}>(例)営業部　山田太郎</p>
                <input type="text"  name='receiptAddress2' onChange={changeReceiptAddressTwo} placeholder='営業部　山田太郎' defaultValue={receipt_address2} style={{borderRadius:"5px", width:"100%", padding:"10px",marginRight:"10px"}} />
              </div>
              <div>
                <br />
                <br />
                <button className='scoutsBtn' style={{marginRight:"10px", height:"100%", width:"100px"}} onClick={handlePost}>完了</button>
              </div>
            </div>
            <br />
            </div>
      </section>
    </main>
  )
}

export default ChangeAddress
