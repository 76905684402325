

function Guide2() {

    
    return (
        <main id="guide">
            <section className="detail wrapper">
                <h2 className="ttl type3">使い方ガイド</h2>
                <h3 className="ttl">2.Web履歴書の入力について</h3>
                <p>この記事は約3分で読めます。</p>
            
                <p>このページではWeb履歴書の入力について説明します。</p>
            
                <p>
                    1.パラちゃんねる求人サイトTOP画面より
                    <a href="/login/" style={{ display: 'inline', color: '#00a0e9' }}>ログイン／登録</a>
                    をクリックします。
                </p>
                <img src="/assets/img/guide/first.png" alt=""/>
            
                <p>2.メールアドレス・パスワードを入力し、ログインをクリックします。<br/>
                （パスワードを忘れた場合）パスワードをお忘れの方をクリックしてください。</p>
                <img src="/assets/img/guide/02/02.png" alt=""/>
            
                <p>メールアドレスを入力して、再設定メール送信をクリックして下さい。</p>
                <img src="/assets/img/guide/02/03.png" alt=""/>
            
                <p>3.マイページのメニューより、web履歴書をクリックします。</p>
                <img src="/assets/img/guide/02/04.png" alt=""/>
            
                <p>4.WEB履歴書は、プロフィール、障害について、職務経歴、スキル、自己PR、希望条件、企業に伝えたい項目を入力することで企業へ自身をアピールすることができます。</p>
                <img src="/assets/img/guide/02/05.png" alt=""/>
            
                <p>プロフィールの入力についてです。<br/>
                必須項目等を入力し、一時保存するまたは保存して次へをクリックしてください。<br/>
                ※顔写真はアップロードをクリックして、ファイルをアップロードしてください。</p>
                <img src="/assets/img/guide/02/06.png" alt=""/>
            
                <p>次に障がいについての入力です。</p>
                <img src="/assets/img/guide/02/07.png" alt=""/>
            
                <p>各項目については、ポップアップ画面の選択して入力してください。</p>
            
                <p>必須項目等を入力し、一時保存するまたは保存して次へをクリックしてください。</p>
                <img src="/assets/img/guide/02/08.png" alt=""/>
            
                <p>次に職務経歴の入力になります。職歴数に応じて、直近の職歴を入力してください。<br/>
                （会社名・就業を開始した年・就業を開始した月・就業を終了した年・就業を終了した月・業界・職種カテゴリ・職種の順）<br/>
                またアピールしたい職歴・経験職種を入れることでより選考通過率がアップします。<br/>
                必須項目等を入力し、一時保存するまたは保存して次へをクリックしてください。</p>
                <img src="/assets/img/guide/02/09.png" alt=""/>
            
            
                <p>次にスキルについての入力です。<br/>
                アピールしたいPCスキル・資格・語学を選択入力し、一時保存するまたは保存して次へをクリックしてください。</p>
                <img src="/assets/img/guide/02/10.png" alt=""/>
            
                <p>次に自己PRについての入力です。<br/>
                活かせるスキルや意気込みなどを400字以内で記載してください。<br/>
                また、動画をアップロードすることもできます。（1分以内の動画）<br/>
                一時保存するまたは保存して次へをクリックしてください。</p>
                <img src="/assets/img/guide/02/11.png" alt=""/>
            
                <p>次に、希望条件についてです。<br/>
                仕事探しをする上で希望する条件を入力してください。<br/>
                雇用形態・年収は必須項目になります。<br/>
                入力後、一時保存するまたは保存して次へをクリックしてください。</p>
                <img src="/assets/img/guide/02/12.png" alt=""/>
            
                <p>最後に、企業に伝えたい項目を入力してください。<br/>
                特記事項は就労する上で、企業側に選考前に周知しておきたい情報等を記載してください。<br/>
                入力後、一時保存するまたは完了をクリックすると、WEB履歴書の入力は完了です。</p>
                <img src="/assets/img/guide/02/13.png" alt=""/>
            
                <p>なお、WEB履歴書では、履歴書ダウンロード・職務経歴書をクリックすると、<br/>
                印刷用にダウンロードが可能です。<br/>
                また、すべての入力が行われるとあなたのWEB履歴書の完成度が「100％」になります。</p>
                <img src="~/assets/img/guide/02/14.png" alt=""/>
            
                <p>以上がWEB履歴書の入力になります。</p>
                <a href="/guide/" className="btn type3">一覧へ戻る</a>
            </section>
      </main>
       
    );
  }
  
  export default Guide2;