import { scoutsSavedConditionsTypes } from "../../ActionTypes/scoutsSavedConditionsTypes";
import {
  FetchScoutsSavedConditionsFailure,
  FetchScoutsSavedConditionsFailurePayload,
  FetchScoutsSavedConditionsRequest,
  FetchScoutsSavedConditionsSuccess,
  FetchScoutsSavedConditionsSuccessPayload,
} from "../../types/type";

export const fetchScoutsSavedConditionsRequest = (id: any, name: any): FetchScoutsSavedConditionsRequest => ({
  type: scoutsSavedConditionsTypes.FETCH_SCOUTS_SAVED_CONDITIONS_REQUEST,
  id,
  name,
});

export const fetchScoutsSavedConditionsSuccess = (
  payload: FetchScoutsSavedConditionsSuccessPayload,
): FetchScoutsSavedConditionsSuccess => ({
  type: scoutsSavedConditionsTypes.FETCH_SCOUTS_SAVED_CONDITIONS_SUCCESS,
  payload
});

export const fetchScoutsSavedConditionsFailure = (
  payload: FetchScoutsSavedConditionsFailurePayload
): FetchScoutsSavedConditionsFailure => ({
  type: scoutsSavedConditionsTypes.FETCH_SCOUTS_SAVED_CONDITIONS_FAILURE,
  payload
});
