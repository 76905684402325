import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchApplicantFailure, fetchApplicantSuccess } from "../../actions/applicantActions/applicantActions";
import { applicantTypes } from "../../ActionTypes/applicantTypes";

const getApplicant = (id: any) =>
  axios.get(API_BASE_ADDRESS + '/top/applicant/' + id,  {
    headers: {
      Authorization: localStorage.getItem('jobParachannelAuthToken') ? localStorage.getItem('jobParachannelAuthToken') : localStorage.getItem('jobParachannelAuthAdminToken'),
      Accept: '*/*',
    }
  });

function* fetchApplicantSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getApplicant, action.id);
    yield put(
      fetchApplicantSuccess({
        applicant: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchApplicantFailure({
        error: e.message
      })
    );
  }
}

function* ApplicantSaga() {
  yield takeLatest(applicantTypes.FETCH_APPLICANT_REQUEST, fetchApplicantSaga);
}
export default ApplicantSaga;