import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { GOOGLE_SHEET_KEY } from "../../API";
import { openGoogleAiTypes } from "../../ActionTypes/openGoogleAiTypes";
import { fetchOpenAiGoogleActionsFailure, fetchOpenAiGoogleSuccess } from "../../actions/openGoogleAiActions/openGoogleAiActions";


let PostOpenGoogleAI = (body:any) => fetch('https://script.google.com/macros/s/' + GOOGLE_SHEET_KEY + '/exec', {
    mode: 'no-cors',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
function* fetchOpenAiGoogleSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(PostOpenGoogleAI, action.body);
    yield put(
      fetchOpenAiGoogleSuccess({
        history: response.data        
      })
    );
  } catch (e:any) {
    yield put(
    fetchOpenAiGoogleActionsFailure({
        error: e.message
      })
    );
  }
}

function* OpenAiGoogleSaga() {
  yield takeLatest(openGoogleAiTypes.FETCH_OPENAI_HISTORY_REQUEST, fetchOpenAiGoogleSaga);
}
export default OpenAiGoogleSaga;


