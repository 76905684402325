import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruitmentsTypes } from "../../ActionTypes/recruitmentsTypes";
import { fetchRecruitmentsFailure, fetchRecruitmentsSuccess } from "../../actions/recruitmentsActions/recruitmentsActions";

// var numb  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

var getRecruitments = () => {
  if(!localStorage.jobParachannelAuthToken) {
    return axios.get(API_BASE_ADDRESS + '/recruitments/top', {headers: { Accept: 'application/json'}}); 
  } else {
    return axios.get(API_BASE_ADDRESS + '/recruitments/top', {headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken}}); 
  }
}

function* fetchRecruitmentsSaga() {
  try {
    const response: AxiosResponse = yield call(getRecruitments);
    yield put(
      fetchRecruitmentsSuccess({
        recruitments: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitmentsFailure({
        error: e.message
      })
    );
  }
}

function* RecruitmentsSaga() {
  yield takeLatest(recruitmentsTypes.FETCH_RECRUIMENTS_REQUEST, fetchRecruitmentsSaga);
}

export default RecruitmentsSaga;
