import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { handicapsTypes } from "../../ActionTypes/handicapsTypes";
import { fetchHandicapsFailure, fetchHandicapsSuccess } from "../../actions/handicapsActions/handicapsActions";

// var numb  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

var getHandicaps = () => axios.get(API_BASE_ADDRESS + '/master?handicaps=true&handicap_types=true&consideration_conditions=true', {headers: { Accept: 'application/json', Prefer: 'example=example-1'}});


function* fetchHandicapsSaga() {
  try {
    const response: AxiosResponse = yield call(getHandicaps);
    yield put(
      fetchHandicapsSuccess({
        handicaps: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchHandicapsFailure({
        error: e.message
      })
    );
  }
}

function* HandicapsSaga() {
  yield takeLatest(handicapsTypes.FETCH_HANDICAPS_REQUEST, fetchHandicapsSaga);
}

export default HandicapsSaga;
