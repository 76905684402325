import { useState } from "react";


function Guide14() {

    
    return (
        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">14.【新機能】スカウトについて</h3>
          <p>このページでは新たに追加されたスカウト機能について説明します。</p>
    
          <p className="bg">スカウト機能とは？<br/>
          本サイトに登録している求職者の情報を確認し、自社の求人にあった求職者にメッセージで<br/>
          自社の社風や仕事の詳細の内容、やりがいなど魅力を直接伝えることができます。<br/>
          各機能同様、完全無料でお使いいただけます。</p>
    
          <p>＜使い方：約3分で読めます。＞</p>
    
          <p>1.TOPページ(<a href="/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/</a>) から画面右上の「採用担当者様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/14/01.png" alt=""/>
    
          <p>2.「既に会員の企業様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/14/02.png" alt=""/>
    
          <p>3.登録したメールアドレス・パスワードにて、ログインしてください。<br/>
          パスワードをお忘れの方はこちらから再設定してください。<br/>
          <a href="/recruiter/password/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/recruiter/password/</a></p>
          <img src="/assets/img/guide/14/03.png" alt=""/>
    
          <p>4.マイページ画面左メニューの「<a href="/recruiter/scouts/" style={{display: 'inline',color: '#00a0e9'}}>スカウト</a>」をクリックします。</p>
          <img src="/assets/img/guide/14/04.png" alt=""/>
    
          <p>5.スカウトのトップ画面では、以下3つの検索条件で候補者を絞ることが可能です。<br/>
          ・障がい種別※複数設定可<br/>
          ・障がい者手帳　有無<br/>
          ・就労支援　有無</p>
          <img src="/assets/img/guide/14/05.png" alt=""/>
    
          <p>6.「<a href="/recruiter/scouts/" style={{display: 'inline',color: '#00a0e9'}}>詳細条件を設定する</a>」を押すと、勤務地やスキルなど詳細条件で絞り込みができます。</p>
          <img src="/assets/img/guide/14/06.png" alt=""/>
    
          <p>7.スカウトを送る場合は、以下3パターンです。<br/>
          ①プロフィールを見ながら個別にメッセージを送りたい【推奨】<br/>
          →各候補者の「スカウト」ボタンを押してメッセージ作成画面へ</p>
          <p>②複数人にアプローチしたい<br/>
          →チェックを入れて対象者を絞り、「一括追加」ボタンを押す。</p>
          <p>③検索に出てきた方にメッセージしたい<br/>
          「全員にチェックを入れる」→「一括追加」も可能。</p>
          <p>※スカウト送信の上限人数は1日10名となっていますので、その点ご留意ください。</p>
          <img src="/assets/img/guide/14/07.png" alt=""/>
    
          <p>8.候補者をクリックすると詳細のプロフィールが見られます。<br/>
          ※スキルや経験だけでなく、希望条件も確認できるので、それを踏まえてメッセージを送ると返信率がアップします。</p>
          <img src="/assets/img/guide/14/08.png" alt=""/>
    
          <p>9.「スカウト」を押すと、メッセージ送付画面になります。</p>
          <p>Step1.現在掲載中の求人から、スカウトを送る求人を選んでください。<br/>
          <a href="/recruiter/recruitments/" style={{display: 'inline',color: '#00a0e9'}}>求人内容を確認はこちらから</a></p>
          <img src="/assets/img/guide/14/09.png" alt=""/>
          <p>Step2.メッセージを作成しましょう。<br/>
          以下、テンプレートを活用してください。テンプレートは保存も可能です。</p>
    
          <p>ーーー<br/>
          【貴社のキャッチコピー】<br/>
          <br/>
          ○○様<br/>
          <br/>
          はじめまして。株式会社○○の採用担当です。<br/>
          <br/>
          ○○様のご経歴を拝見し、〇〇という可能性を感じ、ご連絡させていただきました。<br/>
          <br/>
          弊社は〇〇〇〇（企業文化や理念）を大切にしており、現在〇〇〇〇（事業内容）を展開しております。<br/>
          〇〇〇〇（職場の雰囲気）な職場となっております。<br/>
          <br/>
          今回、〇〇という業務をお願いしたいと考えております。<br/>
          <br/>
          まずは一度お会いし、ざっくばらんにお話できればと思っております。<br/>
          お会いできることを楽しみにしています。<br/>
          ーーー</p>
    
          <p>10.メッセージ送信前の確認事項</p>
          <p>・同一人物に同じ求人票を連続してメールしていませんか？<br/>
          ・選考中または不合格通知済みの求職者ではありませんか？<br/>
          ・企業やその求人の魅力を本文に書いていますか？</p>
          <p>不適切なスカウトメールは求職活動の妨げとなります。<br/>
          健全なサイト運営にご協力ください。</p>
    
          <p>11.スカウトの送付・求職者からの返信確認<br/>
          トップ画面のスカウトメニューより「メッセージ履歴」から確認が可能です。</p>
          <img src="/assets/img/guide/14/10.png" alt=""/>
    
          <p>スカウトの説明は以上となります。<br/>
          引き続き、パラちゃんねるをご活用ください。</p>
    
          <a href="/guide/" className="btn type3 nuxt-link-active">一覧へ戻る</a>
        </section>
      </main>
       
    );
  }
  
  export default Guide14;