import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import AdminNav from '../../adminTop/AdminNav';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchRecruiterPlanRequest } from '../../../redax/actions/recruiterPlanActions/recruiterPlanActions';
import { fetchRecruiterPricingRequest } from '../../../redax/actions/recruiterPricingActions/recruiterPricingActions';

var isCheckPlanName = false;
var isHide = false;
const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 2;
const day = 1;
const YYYY = year + '年' + month + '月' + day + '日';
let total = 0;
let nextTotal = 0;
const Plan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recruiterPlan = useSelector((state: RootState) => state.recruiterPlan);
  const pricing = useSelector((state: RootState) => state.recruiterPricing);
  const loginData = useSelector((state: RootState) => state.login);
  // const [plan, setPlan] = useState();
  const [companyId, setCompanyId] = useState();
  const [planName, setPlanName] = useState();
  const [planPrice, setPlanPrice] = useState();
  const [nextContractPlanName, setNextContractPlanName] = useState();
  const [nextContractPlanPrice, setNextContractPlanPrice] = useState();
  const [currenPlanPrice, setcurrenPlanPrice] = useState();
  const [currentPlanId, setCurrentPlanId] = useState();
  const [filteredPrice, setFilteredPrice] = useState();
  const [planList, setPlanList] = useState(Boolean);
  const [optionName1, setOptionName1] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [nextTotalPrice, setNextTotalPrice] = useState(0);
  const [currentDrOption, setCurrentDrOption] = useState(false);
  const [currentWarrantyOption, setCurrentWarrantyOption] = useState(false);
  const [planListsName, setPlanListsName] = useState();
  const [planListsName1, setPlanListsName1] = useState();
  const [planListsPrice, setPlanListsPrice] = useState<any>();
  const [planListsPrice1, setPlanListsPrice1] = useState<any>();
  const [nextPlanListsPrice, setNextPlanListsPrice] = useState<any>();
  const [nextPlanListsPrice1, setNextPlanListsPrice1] = useState<any>();
  const [nextPlanListsName, setNextPlanListsName] = useState();
  const [nextPlanListsName1, setNextPlanListsName1] = useState();
  const [monthDirectScoutLimit, setMonthDirectScoutLimit] = useState();
  const [receipt_address, setReceipt_address] = useState<any>();
  const [receipt_address2, setReceipt_address2] = useState<any>();
    
  useEffect(() => {
    if (loginData.pending) {
      setReceipt_address(loginData.login.receipt_address);
      setReceipt_address2(loginData.login.receipt_address2);
    }
  }, [loginData.pending]);
    
  useEffect(() => {
    if (localStorage.getItem('jobParachannelAuthToken')) {
      // dispatch(fetchLoginRequest('', '',''))
      dispatch(fetchRecruiterPlanRequest());
      dispatch(fetchRecruiterPricingRequest());
    } else (
        navigate('/recruiter/login/')
    )
  }, [dispatch]);
    
  useEffect(() => {
    total = 0;
    nextTotal = 0;
    if(recruiterPlan !== undefined && recruiterPlan.pending && pricing.pending){
      setCompanyId(recruiterPlan.recruiterPlan.company_id);
      var currentName = recruiterPlan.recruiterPlan.current_plan_name;
      if(currentName == "無料" || currentName == "フリープラン") {
        isCheckPlanName = true;
        isHide = false;
      } else {
        isCheckPlanName = false;
        isHide = true;
      }
      const currentPlanId = recruiterPlan.recruiterPlan.current_plan_id;
      setCurrentDrOption(recruiterPlan.recruiterPlan.current_dr_option);
      setCurrentWarrantyOption(recruiterPlan.recruiterPlan.current_warranty_option);
      const currentPlan = recruiterPlan.recruiterPlan.plan_lists.find((plan:any) => plan.id === currentPlanId);
      const nextContractPlan = recruiterPlan.recruiterPlan.next_contract;
      const nextContractPlanId = pricing.recruiterPricing.plan_lists.find((plan:any) => plan.id === recruiterPlan.recruiterPlan.next_contract.plan_id);
      const optionName = pricing.recruiterPricing.plan_lists.find((plan:any) => plan.id === currentPlanId);
      const planList = pricing.recruiterPricing.plan_lists; 
      if(nextContractPlanId && nextContractPlanId.option.length > 0){
        setNextPlanListsName(nextContractPlanId.option[1].option_name);
        setNextPlanListsName1(nextContractPlanId.option[0].option_name);
        setNextPlanListsPrice(nextContractPlanId.option[1].price);
        setNextPlanListsPrice1(nextContractPlanId.option[0].price);
      };           
      if(optionName && optionName.option.length){
        setPlanListsPrice(optionName.option[0].price);
        setPlanListsPrice1(optionName.option[1].price);
        setMonthDirectScoutLimit(optionName.option[1].month_direct_scout_limit);
        let a = optionName.option[0].option_name;
        let b = optionName.option[1].option_name;
        setPlanListsName(a);
        setPlanListsName1(b);
      }
        if(recruiterPlan.recruiterPlan.current_dr_option && recruiterPlan.recruiterPlan.current_warranty_option ){
          setTotalPrice(optionName.option[0].price + optionName.option[1].price);
        } else if(recruiterPlan.recruiterPlan.current_dr_option && !recruiterPlan.recruiterPlan.current_warranty_option){
          setTotalPrice(optionName.option[1].price);
        } else if (!recruiterPlan.recruiterPlan.current_dr_option && recruiterPlan.recruiterPlan.current_warranty_option) {
          setTotalPrice(optionName.option[0].price);
        } else {
          setTotalPrice(0);
        }

      if (currentPlan) {
        setPlanName(currentPlan.name);
        setPlanPrice(currentPlan.price);
      } else {
          // Handle the case where the current plan is not found
      }

      if (nextContractPlan) {
        nextTotal = 0;
        setNextContractPlanName(recruiterPlan.recruiterPlan.next_contract.plan_name);          
        setNextContractPlanPrice(recruiterPlan.recruiterPlan.next_contract.plan_price);
        for(let j = 0; j < planList.length; j++) {
          if(planList[j].id == recruiterPlan.recruiterPlan.next_contract.plan_id){
            if(recruiterPlan.recruiterPlan.next_contract.dr_option == true) {
              setNextTotalPrice(nextTotal += planList[j].option[1].price);
            }
            if(recruiterPlan.recruiterPlan.next_contract.warranty_option == true) {      
              setNextTotalPrice(nextTotal += planList[j].option[0].price);
            }
          }
        }
      }
      setcurrenPlanPrice(recruiterPlan.recruiterPlan.current_plan_price);
      setCurrentPlanId (recruiterPlan.recruiterPlan.current_plan_id);
      // Find the plan object whose id matches the current_plan_id
      const filteredPlan = recruiterPlan.recruiterPlan.plan_lists.find((plan:any) => plan.id === 1);
      // Retrieve the price from the filtered plan
      setFilteredPrice( filteredPlan ? filteredPlan.price : null );
      if(filteredPlan == undefined){
        setPlanList(false);
      } else {
        setPlanList(true);
      }
      if (optionName && optionName.option.length) {
        // setTotalPrice(optionName.option[0].price + optionName.option[1].price);
      } else {
        // Handle the case where the current plan is not found
      }
    }
  }, [recruiterPlan]);

  function logout() {
    if (localStorage.entryFlag) {
      localStorage.removeItem("entryFlag");
    }
    if (localStorage.jobParachannelAuthToken) {
      localStorage.removeItem("jobParachannelAuthToken");
      navigate("/");
      window.location.reload();
    }
  }
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Attach the event listener on component mount
    window.addEventListener('resize', handleResize);
    // console.log(windowSize.width);
    

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  const openBlankTab = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSeDLJEsGIDxz6BPA5ZqaTVZ4SOynQTLuzwseSaThKl3dVthxQ/viewform?embedded=true&pli=1',
      '_blank' // <- This is what makes it open in a new window.
    );
  }
  
  return (
    <main id='company' style={windowSize.height < 824 ? {marginBottom:"0"} : {marginBottom:"100px"}}>
        <MypageSideBar resource={undefined} familyName={undefined} currentList={undefined}/>
        <section style={{width:"100%"}}>
          <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
            <ul className="bread_crumb">
              <li>
                <a href="/">トップ</a>
              </li>
              <li>
                <a href="/recruiter/">企業様マイページ</a>
              </li>
              <li>
                <a href="#">プラン管理</a>
              </li>
            </ul>
            <div className="ttl_box">
              <ul>
                <li>
                  <a href="#" onClick={logout}>
                    <img src="/assets/img/company/icon02.svg" alt="" />
                    ログアウト
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {planList ? 
            <div style={{width:"100%",padding:"0px 4%"}}>
                <ul className ="bread_crumb">
                    <li style={{display:"flex", alignItems:"center"}}><img src="/assets/img/icon.png" style={{width:"20px", marginRight:"10px"}} alt="" /><a href="/recruiter/plan/" style={{textDecoration:"none"}}>プラン内容</a></li>
                    <a href="/recruiter/invoice"><li style={{display:"flex", alignItems:"center"}}><img src="/assets/img/currency-yen.png" style={{width:"25px", marginRight:"10px"}} alt="" />請求一覧</li></a>
                </ul>
                <hr style={{height:"2px", width:"100%", background:"#00A0E9"}}/>
                <div style={{borderRadius:"5px", border:"#C4C4C4 solid 1px", padding:"2%"}}>
                  <h2 className='adminH2' style={{margin:"0"}}>プラン内容</h2>
                  <br />
                  <p className='plan_main_title' style={{color: "#4b4b4b"}}>{planName}　</p>
                  <div className="plan_sub_title_wrapper" style={{color: "#4b4b4b"}}>
                    <span>
                      {!currentDrOption && currentWarrantyOption ? `${planListsName} ${planListsPrice?.toLocaleString()} 円/月 (税別)`: 
                      currentDrOption && !currentWarrantyOption ? `${planListsName1} ${planListsPrice1?.toLocaleString()} 円/月 (税別)`:
                      currentDrOption && currentWarrantyOption ? `${planListsName} ${planListsPrice?.toLocaleString()} 円/月 (税別) 
                      ${planListsName1} ${monthDirectScoutLimit}件 ${planListsPrice1?.toLocaleString()} 円/月 (税別)` : ""}
                      {optionName1.length > 0 && (
                        <>
                          (
                          {optionName1.map((e:any, index:any) => (
                            <span key={index} style={{display:"inline-block", marginRight:"3px", marginLeft:"3px"}}>
                              {e.option_name}
                              {index !== optionName1.length - 1 && ','}
                            </span>
                          ))}
                          )
                        </>
                      )}
                    </span>
                  </div>
                  {nextContractPlanName && (<>
                    <h2 className='adminH2' style={{margin:"0"}}>お支払い金額</h2>
                  <hr />
                  <p className="plan_main_title" style={{color: "#4b4b4b"}}>{(Number(planPrice) + totalPrice).toLocaleString()}円</p>
                  <div className="plan_sub_title_wrapper" style={{color: "#4b4b4b"}}>
                    <span>プラン料金: {(Number(planPrice)).toLocaleString()}円</span><br/>
                    { totalPrice ?
                      <span>オプション料金 :{(Number(totalPrice)).toLocaleString()}円</span>
                      : <></>
                    }
                  </div>
                  <h2 className='adminH2' style={{margin:"0"}}>次回の更新日</h2>
                  <br />
                  <p className="plan_main_title" style={{color: "#4b4b4b"}}>{YYYY}</p>
                  <br />
                  <h2 className='adminH2' style={{margin:"0"}}>次回プラン内容</h2>              
                  <br />
                  <p className='plan_main_title' style={{color: "#4b4b4b"}}>{nextContractPlanName}</p>
                  <div className="plan_sub_title_wrapper" style={{color: "#4b4b4b"}}>
                    <span>{
                      !recruiterPlan.recruiterPlan.next_contract.dr_option && recruiterPlan.recruiterPlan.next_contract.warranty_option ? `${nextPlanListsName1} ${nextPlanListsPrice1?.toLocaleString()} 円/月 (税別)` : 
                      recruiterPlan.recruiterPlan.next_contract.dr_option && !recruiterPlan.recruiterPlan.next_contract.warranty_option ? `${nextPlanListsName} ${monthDirectScoutLimit}件 ${nextPlanListsPrice?.toLocaleString()} 円/月 (税別)` : 
                      recruiterPlan.recruiterPlan.next_contract.dr_option && recruiterPlan.recruiterPlan.next_contract.warranty_option ? `${nextPlanListsName1} ${nextPlanListsPrice1?.toLocaleString()} 円/月 (税別) ${nextPlanListsName} ${monthDirectScoutLimit}件 ${nextPlanListsPrice?.toLocaleString()} 円/月 (税別)` : "" 
                    }</span>
                  </div>
                  <h2 className='adminH2' style={{margin:"0"}}>次回金額（予定）</h2>
                  <br />
                  <p className="plan_main_title" style={{color: "#4b4b4b"}}>{(Number(nextContractPlanPrice) + nextTotalPrice).toLocaleString()}円</p>
                  <div className="plan_sub_title_wrapper" style={{color: "#4b4b4b"}}>
                    <span>プラン料金: {(Number(nextContractPlanPrice)).toLocaleString()}円</span><br/>
                    { nextTotalPrice ?
                      <span>オプション料金 :{(Number(nextTotalPrice)).toLocaleString()}円</span>
                      : <></>
                    }
                  </div>
                  </>)}
                  {/* <button className='scoutsBtn' onClick={()=>{navigate(`/recruiter/planpricing/`)}}>プランを確認する</button> */}
                  <button className='scoutsBtn' style={{marginRight:"10px", marginBottom:"10px"}} onClick={()=>window.location.href = ('/recruiter/planpricing')}>プラン変更</button>
                  <button className='scoutsBtn' onClick={() => { navigate(`/recruiter/plandetail/?pricing`,  {state: {data: currentPlanId}}) }}>プランを確認する</button>
                </div>
                <br />
            </div>
          :<></>}

          { !planList ? 
            <div style={{width:"100%",padding:"0px 4%"}}>
              <ul className ="bread_crumb" style={{marginTop:"30px"}}>
                  <li style={{display:"flex", alignItems:"center"}}><img src="/assets/img/icon.png" style={{width:"20px", marginRight:"10px"}} alt="" /><a href="/recruiter/plan/" style={{textDecoration:"none"}}>プラン内容</a></li>
                  <a href="/recruiter/invoice"><li style={{display:"flex", alignItems:"center"}}><img src="/assets/img/currency-yen.png" style={{width:"25px", marginRight:"10px"}} alt="" />請求一覧</li></a>
              </ul>
              <hr style={{height:"2px", width:"100%", background:"#00A0E9"}}/>
              <div style={{borderRadius:"5px", border:"#C4C4C4 solid 1px", padding:"2%"}}>
                <h2 className='adminH2' style={{margin:"0"}}>プラン内容</h2>
                <br />
                <p>{planName}</p>
                <br />
                {/* <button className='scoutsBtn' onClick={()=>{navigate(`/recruiter/planpricing/`)}}>プランを確認する</button> */}
                <button className='scoutsBtn' style={{marginRight:"10px"}} onClick={()=>window.location.href = ('/recruiter/planpricing')}>プラン変更</button>
                <button className='scoutsBtn' onClick={() => { navigate(`/recruiter/plandetail/?pricing`,  {state: {data: currentPlanId}}) }}>プランを確認する</button>
              </div>
              <button className='scoutsBtn' onClick={() => { navigate(`/recruiter/plandetail/?pricing`,  {state: {data: currentPlanId}}) }}>プランを確認する</button>
            </div>
          :<></>}
          <br />
          <div style={{width:"100%",padding:"0px 4%"}}>
            <div style={{borderRadius:"5px", border:"#C4C4C4 solid 1px", padding:"2%"}}>
              <h2 className='adminH2' style={{margin:"0"}}>登録中の宛名</h2>  
              <br />
              <div>
                {/* <p>⁨株式会社 TEST SITEMASU</p> */}
                {receipt_address == null && receipt_address2 == null ? 
                  <p>未登録</p>
                :
                  <p>{receipt_address} {receipt_address2}</p>
                }
              </div>
              <button className='scoutsBtn' style={{marginRight:"10px"}} onClick={()=>window.location.href = ('/recruiter/changeaddress')}>宛名の変更</button>
            </div>
            <br />
            <p style={{color:"red"}}>変更などご不明な点がございましたらお気軽にお問合せください </p>
            <button className='scoutsBtn' style={{marginRight:"10px"}} onClick={()=>openBlankTab()}>お問合せ</button>
          </div>
      </section>
    </main>
  )
}

export default Plan
