import { recruitmentsStepTwoTypes } from "../../ActionTypes/recruitmentsStepTwoTypes";
import { FetchRecruitmentsStepTwoFailure, FetchRecruitmentsStepTwoFailurePayload, FetchRecruitmentsStepTwoRequest, FetchRecruitmentsStepTwoSuccess, FetchRecruitmentsStepTwoSuccessPayload } from "../../types/type";

export const fetchRecruitmentsStepTwoRequest = (id:any, data: any): FetchRecruitmentsStepTwoRequest => ({
  type: recruitmentsStepTwoTypes.FETCH_RECRUIMENTS_STEP_TWO_REQUEST,
  id,
  data
});

export const fetchRecruitmentsStepTwoSuccess = (
  payload: FetchRecruitmentsStepTwoSuccessPayload,
): FetchRecruitmentsStepTwoSuccess => ({
  type: recruitmentsStepTwoTypes.FETCH_RECRUIMENTS_STEP_TWO_SUCCESS,
  payload
});

export const fetchRecruitmentsStepTwoFailure = (
  payload: FetchRecruitmentsStepTwoFailurePayload
): FetchRecruitmentsStepTwoFailure => ({
  type: recruitmentsStepTwoTypes.FETCH_RECRUIMENTS_STEP_TWO_FAILURE,
  payload
});
