import { interviewsTypes } from "../../ActionTypes/interviewsTypes";
import { FetchInterviewsFailure, FetchInterviewsFailurePayload, FetchInterviewsRequest, FetchInterviewsSuccess, FetchInterviewsSuccessPayload } from "../../types/type";


export const fetchInterviewsRequest = (queryParams: any, types: any): FetchInterviewsRequest => ({
  type: interviewsTypes.FETCH_INTERVIEWS_REQUEST,
  queryParams,
  types,
});

export const fetchInterviewsSuccess = (
  payload: FetchInterviewsSuccessPayload,
): FetchInterviewsSuccess => ({
  type: interviewsTypes.FETCH_INTERVIEWS_SUCCESS,
  payload
});

export const fetchInterviewsFailure = (
  payload: FetchInterviewsFailurePayload
): FetchInterviewsFailure => ({
  type: interviewsTypes.FETCH_INTERVIEWS_FAILURE,
  payload
});
