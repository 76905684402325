import { recruitmentsCopyTypes } from "../../ActionTypes/recruitmentsCopyTypes";
import { FetchRecruitmentsCopyFailure, FetchRecruitmentsCopyFailurePayload, FetchRecruitmentsCopyRequest, FetchRecruitmentsCopySuccess, FetchRecruitmentsCopySuccessPayload } from "../../types/type";

export const fetchRecruitmentsCopyRequest = (id:any): FetchRecruitmentsCopyRequest => ({
  type: recruitmentsCopyTypes.FETCH_RECRUIMENTS_COPY_REQUEST,
  id,
});

export const fetchRecruitmentsCopySuccess = (
  payload: FetchRecruitmentsCopySuccessPayload,
): FetchRecruitmentsCopySuccess => ({
  type: recruitmentsCopyTypes.FETCH_RECRUIMENTS_COPY_SUCCESS,
  payload
});

export const fetchRecruitmentsCopyFailure = (
  payload: FetchRecruitmentsCopyFailurePayload
): FetchRecruitmentsCopyFailure => ({
  type: recruitmentsCopyTypes.FETCH_RECRUIMENTS_COPY_FAILURE,
  payload
});
