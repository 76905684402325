import { adminScoutMatchingJobsTypes } from "../../ActionTypes/adminScoutMatchingTypes"
import { FetchAdminScoutMatchingJobsFailure, FetchAdminScoutMatchingJobsRequest, FetchAdminScoutMatchingJobsSuccess, FetchAdminScoutMatchingJobsSuccessPayload,
  FetchAdminScoutMatchingJobsFailurePayload } from "../../types/type";

export const fetchAdminScoutMatchingJobsRequest = (): FetchAdminScoutMatchingJobsRequest => ({
  type: adminScoutMatchingJobsTypes.FETCH_ADMIN_SCOUT_MATCHING_JOBS_REQUEST,
});

export const fetchAdminScoutMatchingJobsSuccess = (
  payload: FetchAdminScoutMatchingJobsSuccessPayload
): FetchAdminScoutMatchingJobsSuccess => ({
  type: adminScoutMatchingJobsTypes.FETCH_ADMIN_SCOUT_MATCHING_JOBS_SUCCESS,
  payload
});

export const fetchAdminScoutMatchingJobsFailure = (
  payload: FetchAdminScoutMatchingJobsFailurePayload
): FetchAdminScoutMatchingJobsFailure => ({
  type: adminScoutMatchingJobsTypes.FETCH_ADMIN_SCOUT_MATCHING_JOBS_FAILURE,
  payload
});