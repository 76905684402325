

import { countTypes } from "../../ActionTypes/countTypes";
import { CountActions, CountState } from "../../types/type";


const initialState: CountState = {
  pending: false,
  count: [],
  error: null,
};

export default (state = initialState, action: CountActions,) => {
  switch (action.type) {
    case countTypes.FETCH_COUNT_REQUEST:
      return {
        ...state,
        pending: false
      };
    case countTypes.FETCH_COUNT_SUCCESS:
      return {
        ...state,
        pending: true,
        count: action.payload.count,
        error: null
      };
    case countTypes.FETCH_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        count: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
