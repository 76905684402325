import { recruitmentsStatusChangeTypes } from "../../ActionTypes/recruitmentsStatusChangeTypes";
import { FetchRecruitmentsStatusChangeFailure, FetchRecruitmentsStatusChangeFailurePayload, FetchRecruitmentsStatusChangeRequest, FetchRecruitmentsStatusChangeSuccess, FetchRecruitmentsStatusChangeSuccessPayload } from "../../types/type";

export const fetchRecruitmentsStatusChangeRequest = (id:any, queryParams: any): FetchRecruitmentsStatusChangeRequest => ({
  type: recruitmentsStatusChangeTypes.FETCH_RECRUIMENTS_STATUS_CHANGE_REQUEST,
  id,
  queryParams
});

export const fetchRecruitmentsStatusChangeSuccess = (
  payload: FetchRecruitmentsStatusChangeSuccessPayload,
): FetchRecruitmentsStatusChangeSuccess => ({
  type: recruitmentsStatusChangeTypes.FETCH_RECRUIMENTS_STATUS_CHANGE_SUCCESS,
  payload
});

export const fetchRecruitmentsStatusChangeFailure = (
  payload: FetchRecruitmentsStatusChangeFailurePayload
): FetchRecruitmentsStatusChangeFailure => ({
  type: recruitmentsStatusChangeTypes.FETCH_RECRUIMENTS_STATUS_CHANGE_FAILURE,
  payload
});
