import { recruitmentsDeleteTypes } from "../../ActionTypes/recruitmentsDeleteTypes";
import { RecruitmentsDeleteActions, RecruitmentsDeleteState } from "../../types/type";


const initialState: RecruitmentsDeleteState = {
  pending: false,
  recruitmentsDelete: [],
  error: null,
};

export default (state = initialState, action: RecruitmentsDeleteActions,) => {
  switch (action.type) {
    case recruitmentsDeleteTypes.FETCH_RECRUIMENTS_DELETE_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitmentsDeleteTypes.FETCH_RECRUIMENTS_DELETE_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitmentsDelete: action.payload.recruitmentsDelete,
        error: null
      };
    case recruitmentsDeleteTypes.FETCH_RECRUIMENTS_DELETE_FAILURE:
      return {
        ...state,
        pending: false,
        recruitmentsDelete: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
