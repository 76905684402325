import React, { useEffect, useState } from 'react'
import AdminNav from './AdminNav'
import "./adminTop.css"
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redax/reducers/rootReducer';
import { fetchLoginRequest } from '../../redax/actions/loginActions/loginActions';
import Loader from '../loader/loader';
import { fetchAdminRecommendJobsRequest } from '../../redax/actions/adminRecommendJobsActions/adminRecommendJobsActions';
import { fetchAdminRecommendApplicantsRequest } from '../../redax/actions/adminRecommendApplicantsActions/adminRecommendApplicantsActions';


export default function AdminRecommendJobs() {
  const [jobsId, setJobsId] = useState<any>([]);
  const recommendJobs = useSelector((state: RootState) => state.recommendJobs);
  const recommendApplicants = useSelector((state:RootState) => state.recommendApplicants);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [numbers, setNumbers] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [plan2, setplan2] = useState(false);
  const [plan1, setplan1] = useState(true);
  const [plan3, setplan3] = useState(true);
  const [loadScreen, setLoadScreen] = useState(true);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [rd, setRd] = useState(5);
  const [countCnt, setCountCnt] = useState(Number);
  const [showData, setShowData] = useState(false);
  const [dR, setDR] = useState(false);
  const [jobMatching, setJobMatching] = useState(false);
  // let limit = 20;

  const pageDecriment = () => {
    if (page <= 1) {
      setPage(pages);
      return;
    }
    setPage(page - 1);
  }

  const pageIncriment = () => {
    if (page >= pages) {
      setPage(1);
      return;
    }
    setPage(page + 1);
  }
  
  
  useEffect(() => {
    if (!localStorage.getItem('jobParachannelAuthAdminToken')) {
      navigate('/admin/login');
    } else {
      dispatch(fetchLoginRequest('', '', ''));
      dispatch(fetchAdminRecommendJobsRequest());
      setFirstLoad(true);
    }    
  }, [dispatch]);
  
  useEffect(()=> {
    let jobList : any = [];
    if(recommendJobs.pending && firstLoad) {  
      console.log(recommendJobs.jobs);
      jobList = recommendJobs.jobs;
      setJobsId(recommendJobs.jobs);
      setSearchData(recommendJobs.jobs);
      let filteredJobs = recommendJobs.jobs;
      console.log(filteredJobs);
      if (search !== "") {
        filteredJobs = filteredJobs.filter((item: any) => {
          const itemNameLower = item.name.toLowerCase();
          const searchLower = search.toLowerCase();
          return itemNameLower.includes(searchLower);
        });
      }
      // if (!plan3 && !plan1 && !plan2) {
      //   filteredJobs = filteredJobs.filter((e: any) => e.plan === 4);
      // } else {
      //   filteredJobs = filteredJobs.filter((e: any) => {
      //     if (plan3 && plan1 && plan2) {
      //       return true; // Include all plans
      //     } else if (plan3 && e.plan === 3) {
      //       return true;
      //     } else if (plan1 && e.plan === 2) {
      //       return true;
      //     } else if (plan2 && e.plan === 1) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   });
      // }
      if(filteredJobs.length > 0) {      
        let sel = [];
        for(let i = 0; i < filteredJobs.length; i++) {
          for(let j = 0; j < filteredJobs[i].recruitments.length; j++) {
            sel.push(`${filteredJobs[i].recruitments[j].id}`);
          }
        }
        setSelectedItems(sel);
      }
      setJobsId(filteredJobs);
      setShowData(true);      
      setFirstLoad(false);
    } else if(recommendJobs.error != null) {
      navigate('/error?code=500');
    }
    // if(jobList.length > 0) {      
    //   let sel = [];
    //   for(let i = 0; i < jobList.length; i++) {
    //     for(let j = 0; j < jobList[i].recruitments.length; j++) {
    //       sel.push(`${jobList[i].recruitments[j].id}`);
    //     }
    //   }
    //   console.log('sel:', sel)
    //   setSelectedItems(sel);
    // }
  }, [recommendJobs, pages]);

  useEffect(() => {
    if(loadScreen && showData) {
      setLoadScreen(false);
    }
  });

  function checkboxHandler(e:any) {
    const { value, checked } = e.target;
    
    if (checked) {
      setSelectedItems((prevSelectedItems: any) => [...prevSelectedItems, value]);
    } else {
      setSelectedItems((prevSelectedItems: any[]) =>
        prevSelectedItems.filter((item: any) => item !== value)
        );
      } 
    }

  function checkboxDr(e:any) {
    const { value, checked } = e.target;
    setDR(checked);
  }

  function checkboxJobMatching(e:any) {
    const { value, checked } = e.target;
    setJobMatching(checked);
  }
      
  const allClearChkBox = () => {
    setSelectedItems([]);
  }

  function handleScout() {
    if(selectedItems.length == 0) {
      alert('choose');
      return;
    } else {
      let data : any = [];
      let param : any = [];
      // setLoadScreen(true);
      setLoad(true);
      for(let i = 0; i < selectedItems.length; i++) {
        data.push(selectedItems[i]);
      }
      param = data;
      const params = {
        "recruitments": data,
        "scoutCnt": countCnt,
        "isDr": dR,
        "remote": true,
        "job": jobMatching
      } 
      console.log(params);
      dispatch(fetchAdminRecommendApplicantsRequest('get', params));
    }    
  }

  useEffect(()=> {
    setLoadScreen(true);
    if(recommendApplicants.pending && load) {
      setLoad(false);
      allClearChkBox();
      if(loadScreen) {
        setLoadScreen(false);
      }
      navigate('/admin/recommend_matchings/applicants', {state: {data: recommendApplicants.adminApplicants}});
    } else if(recommendApplicants.error != null) {
      if(loadScreen) {
        setLoadScreen(false);
      }
      navigate('/error?code=500');
    }
  }, [recommendApplicants, load]);

  function getCheck(id: any) {
    return selectedItems.includes('' + id) ? true : false;
  }

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Attach the event listener on component mount
    window.addEventListener('resize', handleResize);
    // console.log(windowSize.width);
    

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

// useEffect(() => {
//   const timeoutId = setTimeout(() => {
//     let sel = [];
//     for (let i = 0; i < jobsId.length; i++) {
//       for (let j = 0; j < jobsId[i].recruitments.length; j++) {
//         sel.push(`${jobsId[i].recruitments[j].id}`);
//       }
//     }
//     setSelectedItems(sel);
//   }, 1000);

//   // Cleanup function to clear the timeout if jobsId changes before the timeout completes
//   return () => clearTimeout(timeoutId);
// }, [jobsId]);

function handleFilter() {
  setLoadScreen(true);
  let filteredJobs : any = [];
  filteredJobs = searchData; 
  if (search !== "") {
    filteredJobs = filteredJobs.filter((item: any) => {
      const itemNameLower = item.name.toLowerCase();
      const searchLower = search.toLowerCase();
      return itemNameLower.includes(searchLower);
    });
  }
  
  if (!plan3 && !plan1 && !plan2) {
    filteredJobs = filteredJobs.filter((e: any) => e.plan === 4);
  } else {
    filteredJobs = filteredJobs.filter((e: any) => {
      if (plan3 && plan1 && plan2) {
        return true; // Include all plans
      } else if (plan3 && e.plan === 3) {
        return true;
      } else if (plan1 && e.plan === 2) {
        return true;
      } else if (plan2 && e.plan === 1) {
        return true;
      } else {
        return false;
      }
    });
  }
  if(filteredJobs.length > 0) {      
    let sel = [];
    for(let i = 0; i < filteredJobs.length; i++) {
      for(let j = 0; j < filteredJobs[i].recruitments.length; j++) {
        sel.push(`${filteredJobs[i].recruitments[j].id}`);
      }
    }
    setSelectedItems(sel);
  }
  setJobsId(filteredJobs);
  setShowData(true);
  setLoadScreen(false);
}

function onChangeRD(e:any) {
  setRd(e.target.value);
}


  function handleChange (e:any) {
    if(e == 3){
      setplan3(!plan3);
      // console.log(plan3);      
    } else if (e == 1) {
      setplan1(!plan1);
    } else if (e == 2) {
      setplan2(!plan2);      
    }
  }

  function handleChangeNumber(e:any){
    setCountCnt(e.target.value);    
  }
  
  return (
    <main id='adminHome'>
      <AdminNav/>
      {loadScreen ? <Loader /> : <></>}
      {/* {loadScreen ? <Loader /> :  */}
      <section className='sectionScouts' style={windowSize.width >= 1900 ? {padding:"8px 16px 0 16px"} : {padding:"8px 16px 0 16px"} }>
        <h1 className='adminH1'>お知らせマッチング STEP1: 企業と求人を選択</h1>
        <hr style={{ height:"1px", backgroundColor:"#00A0E9", marginBottom:"12px"}}/>
        <div className='adminJobsTop' style={{display:"flex",}}>
            <div className='adminJobsTop' style={{flex:"1", display:"flex"}}>
              <input type="search" onChange={(e:any) => {setSearch(e.target.value)}} placeholder='名前検索' style={{border:"1px solid gray", borderRadius:"5px" , height:"35px", marginRight:"10px"}}/>
              <label className='adminLabel' onClick={()=>{handleChange("2")}} htmlFor="無料プラン"><input className='adminInputR' onChange={() => handleChange("2")} value="2" checked={plan2} type="checkbox" name="無料プラン" id="" />無料プラン</label>
              <label className='adminLabel' onClick={()=>{handleChange("1")}} htmlFor="スタンダードプラン"><input className='adminInputR' onChange={() => handleChange("1")}  value="1" checked={plan1} type="checkbox" name="スタンダードプラン" id="" />スタンダードプラン</label>
              <label className='adminLabel' onClick={()=>{handleChange("3")}} htmlFor="プロフェッショナルプラン" ><input className='adminInputR' onChange={() => handleChange("3")}  value="3" checked={plan3}  style={{borderRadius:"10px"}} type="checkbox" name="プロフェッショナルプラン" id="" />プロフェッショナルプラン</label>
              {/* <select className='adminSelect1' itemType='number' defaultValue={5} onChange={onChangeRD} style={{color:"gray"}}  name="" id="">
                  <option itemType='number' value="0">上限なし</option>
                  <option itemType='number' value="5">5</option>
                  <option itemType='number' value="10">10</option>
                  <option itemType='number' value="15">15</option>
                  <option itemType='number' value="20">20</option>
                  <option itemType='number' value="25">25</option>
                  <option itemType='number' value="30">30</option>
                  <option itemType='number' value="35">35</option>
                  <option itemType='number' value="40">40</option>
                  <option itemType='number' value="45">45</option>
                  <option itemType='number' value="50">50</option>
              </select> */}
              {/* <option disabled selected hidden value="DR残上限値設定">DR残上限値設定</option> */}
              {/* <input type="number" placeholder='スカウト数' onChange={handleChangeNumber} style={{border:"1px solid gray", borderRadius:"5px" , height:"35px", marginRight:"10px", width:"10%"}}/> */}
              <button id='scoutsInputBtn' onClick={handleFilter} style={{ marginRight:"16px"}}>反映する</button>
            </div>
        </div>

        <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
          <h2 className='adminH2' style={{width:"100%"}}>{jobsId != undefined ? jobsId.length : ""} 社が登録されています。</h2>        
          <div style={{width: "160px"}}>
            <button id='scoutsInputClrBtn' onClick={allClearChkBox} className="btnFont"><p>全チェックを外す</p></button>
          </div>
        </div>
        <div className='scroll' style={{width:"100%" , height:"90%"}}>
            <table className='adminTable' style={{width: "100%",}}>
              <thead className='adminThead' style={{ width: "100%", position: "sticky", top: "0" }}>
                <tr style={{width:"100%" , wordBreak:"break-word", }}>
                  <th className='adminTheadTh'>♯</th>
                  <th className='adminTheadTh'>会社名/求人名</th>
                  <th className='adminTheadTh'>本社/勤務地</th>
                  <th className='adminTheadTh'>職種</th>
                  <th className='adminTheadTh'>雇用形態</th>
                  <th className='adminTheadTh'>給与条件</th>
                  <th className='srtTbody'>採用人数 </th>
                  <th className='srtTbody'>審査待ち </th>
                  <th className='srtTbody'>不採用 </th>
                  <th className='srtTbody'>採用決定 </th>
                  <th className='adminTheadTh'>DR計</th>
                  <th className='adminTheadTh'>DR残</th>
                  <th className='adminTheadTh'> </th>
                </tr>
              </thead>
        {/* {Array.isArray(jobsId) && jobsId.slice((page - 1)*limit, limit + (page - 1)*limit).map((data:any) => ( */}
        { showData ?
          <>
          {jobsId.map((data:any) => (
            <tbody key={data.id} className='adminTbody' style={{position:"inherit"}}>
                    <tr className="scountBgCompany">
                      <td><h2 className='adminTdH2'>{data.plan == 3 ? <p style={{color:"red"}}>プ</p> : 
                                                    data.plan == 1 ? <p style={{color:"red"}}>無</p> : 
                                                    data.plan == 2 ? <p style={{color:"red"}}>ス</p> : "" }</h2></td>
                      <td><h2 className='adminTdH2s'>{data.name}</h2></td>
                      <td><h2 className='adminTdH2s' style={{width:"160px"}}>{}</h2></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      
                      <td className='adminTbodyNum'>{data.entry_hired}</td>
                      <td className='adminTbodyNum'>{data.entry_planned}</td>
                      <td className='adminTbodyNum'>{data.entry_rejected}</td>
                      <td className='adminTbodyNum'>{data.entry_waiting}</td>
                      <td className='adminTbodyNum'>{data.dr_total}</td>
                      <td className='adminTbodyNum'>{data.dr_left}</td>
                      <td className='adminTbodyNum'></td>
                    </tr>
              {data.recruitments.map((e:any, index:any) => (
                  <>
                    <tr key={e.id} style={{wordBreak:"break-word"}}>
                      <td>{index + 1}</td>
                      <td style={{width:"160px"}}><p style={{ wordBreak:"break-word" , textAlign:"start"}}>{e.job_title}</p></td>
                      <td>{e.prefecture}{e.city} </td>
                      <td style={{minWidth:"100px"}}>{e.job_category}</td> 
                      <td style={{width:"120px"}}>{e.employment_status}</td>
                      <td>{e.lower_monthly_basic_salary} ~ {e.upper_monthly_basic_salary}</td>
                      <td className='adminTbodyNum'>{e.entry_hired}</td>
                      <td className='adminTbodyNum'>{e.entry_planned}</td>
                      <td className='adminTbodyNum'>{e.entry_rejected}</td>
                      <td className='adminTbodyNum'>{e.entry_waiting}</td>
                      <td className='adminTbodyNum'>{e.dr_total}</td>
                      <td className='adminTbodyNum'>{e.dr_left}</td>
                      <td style={{paddingRight: "20px"}}><input className="checkScale15" type="checkbox" value={e.id} checked={getCheck(e.id)} onChange={checkboxHandler}/></td>
                  </tr>
                  </>
              ))}
              </tbody>
              ))} 
              </>
            :<></>}
            </table>
          
        </div>
        <br />
        <div>
          <ul className="pagination_circle">
            <li className={"prev " + (page == 1 ? "disabled" : "")}>
              <a onClick={pageDecriment}>前へ</a>
            </li>
            {numbers.map((e:any) => (
            <li className={page == e ? 'active' : ''}>
              <a onClick={() => setPage(e)}>
                <span>{e}</span>
              </a>
            </li>
            ))}
            <li className={"next " + (page == pages ? "disabled" : "")}>
              <a onClick={pageIncriment}>次へ</a>
            </li>
          </ul>
        </div>
        <div style={{width: "100%", display: "flex", justifyContent: "right"}}>
          <input className="checkScale15" type="checkbox" value={''} onChange={checkboxJobMatching}/>&nbsp;&nbsp;
          <label className='adminLabel'>職種マッチングを含める</label>   
          <label className='adminLabel'>1求人あたりの送信数</label>
          <input type="number" placeholder='スカウト数' onChange={handleChangeNumber} style={{border:"1px solid gray", borderRadius:"5px" , height:"35px", marginRight:"10px", width:"10%", marginTop: "10px"}}/>
          <input className="checkScale15" type="checkbox" value={''} onChange={checkboxDr}/>&nbsp;&nbsp;
          <label className='adminLabel'>DR残から送信する</label>          
          <button className='scoutsBtn' onClick={handleScout}>STEP2: 候補者の確認</button>
        </div>
      </section>
    </main>
  );
}

