import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchFavCountSuccess, fetchFavCountFailure  } from "../../actions/favCountActions/favCountActions";
import { favCountTypes } from "../../ActionTypes/favCountTypes";

var getCount = (companyId:any, type: any, name: any) => {
  if(type == 'get' && name == 'company') {
    return axios.get(API_BASE_ADDRESS + '/companies/favorite/' + companyId + '/count', {headers: { Accept: 'application/json'}});
  } else if(type == 'check' && name == 'company') {
    return axios.get(API_BASE_ADDRESS + '/companies/favorite/' + companyId, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken')}});
  } else if(name == 'company') {
    return axios.post(API_BASE_ADDRESS + '/companies/favorite/' + companyId, {}, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken')}});
  } else {
    return axios.post(API_BASE_ADDRESS + '/recruitments/' + companyId + '/favorite', {}, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken')}});
  }
}

function* fetchFavCountSaga(companyId: any) {
  try {
    const response: AxiosResponse = yield call(getCount, companyId.id, companyId.types, companyId.name);

    yield put(
      fetchFavCountSuccess({
        count: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchFavCountFailure({
        error: e.message
      })
    );
  }
}

function* FavCountSaga() {
  yield takeLatest(favCountTypes.FETCH_FAV_COUNT_REQUEST, fetchFavCountSaga);
}

export default FavCountSaga;

