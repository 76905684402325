import { recruitmentsDetailTypes } from "../../ActionTypes/recruitmentsDetailTypes";
import { RecruitmentsDetailActions, RecruitmentsDetailState } from "../../types/type";


const initialState: RecruitmentsDetailState = {
  pending: false,
  recruitmentsDetail: [],
  error: null,
};

export default (state = initialState, action: RecruitmentsDetailActions,) => {
  switch (action.type) {
    case recruitmentsDetailTypes.FETCH_RECRUIMENTS_DETAIL_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitmentsDetailTypes.FETCH_RECRUIMENTS_DETAIL_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitmentsDetail: action.payload.recruitmentsDetail,
        error: null
      };
    case recruitmentsDetailTypes.FETCH_RECRUIMENTS_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        recruitmentsDetail: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
