import { useEffect, useState } from "react";
import MypageSideBar from "../../../components/MypageSideBar/MypageSideBar"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginRequest } from "../../../redax/actions/loginActions/loginActions";
import { RootState } from "../../../redax/reducers/rootReducer";
import { fetchInterviewsRequest } from "../../../redax/actions/interviewsActions/interviewsActions";
import dayjs from 'dayjs';
import Loader from "../../loader/loader";
import './schedules.scss';
import ja from 'dayjs/locale/ja';


function Schedules() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [familyName, setFamylyName] = useState(null);
    const [page, setPage] = useState<any>(0);
    const [frst, setFrst] = useState<any>(true);
    const [oldpage, setOldPage] = useState<any>(0);
    const [resourceId, setResourceId] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(null);
    const [interviewsData, setInterviewsData] = useState<any>([]);
    const [interviewsDatas, setInterviewsDatas] = useState<any>([]);
    const queryParams = new URLSearchParams();
    const queryString = queryParams.toString();
    const queryUrl = queryString ? `?${queryString}` : '';
    const [loadScreen, setLoadScreen] = useState(true);

    useEffect(() => {
      if (!localStorage.getItem('jobParachannelAuthToken')) {
        navigate('/login');
      } 
    }, [navigate, dispatch]);

    const loginData = useSelector((state: RootState) => state.login);
    const interviews = useSelector((state: RootState) => state.interviews);

    useEffect(() => {
        if (loginData.pending) {
          if(!loginData.login.is_init_complete) {
            navigate('/mypage/init/');
          }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            dispatch(fetchInterviewsRequest(queryUrl, 'get'))
        }
      }, [loginData.pending]);

      useEffect(() => {
        if (interviews.pending) {
            setInterviewsData(interviews.interviews)
            setCurrentMonth(interviews.interviews.current_month)
            setInterviewsDatas(interviews.interviews.interviews)
            setLoadScreen(false);
        } else if(interviews.error != null) {
          setLoadScreen(false);
          navigate('/error?code=500');
        }
      }, [interviews, interviewsData, currentMonth, interviewsDatas, page])

      function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
        }
      }

     function filterInterviewByDate(value: any) {
        return interviewsDatas.filter(
          (          i: { start_time: string | number | Date | dayjs.Dayjs | null | undefined; }) => dayjs(i.start_time).date() === value
        );
      }

      function backgroundImageUrl(value: any) {
        return `url("${value}")`;
      }

      function fetchData(value: any) {
        let tmp = page;
        if(value === 1){
            tmp = tmp + 1;
            setPage(tmp)
        } else {
            tmp = tmp -1
            setPage(tmp)
        }
        queryParams.set('p', tmp);
        setLoadScreen(true);
        dispatch(fetchInterviewsRequest(queryParams, 'get'))
      }

      function dateArray () {
        dayjs.locale(ja);
        const month = dayjs(currentMonth).get('month') + 1;
        // 日付の配列を作る
        let dates = interviewsDatas.map((i: any) => dayjs(i.start_time).get('date'));
        // 日付の配列の重複を排除
        dates = dates.filter((x: any, i: any, self: any) => self.indexOf(x) === i);
        // ハッシュを再作成して返す
        return dates.map((d: any) => {
          return { date: d, month, day: dayjs(currentMonth).date(d).format('ddd') }
        })
      }


    return (
        <main id="mypage">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" />
        {loadScreen ? <Loader /> : <></>}
        <section id="mypage_inner">
          <ul className="bread_crumb">
            <li><a href="/">トップ</a></li>
            <li><a href="/mypage/">マイページ</a></li>
            <li><a>スケジュール</a></li>
          </ul>
          <div className="ttl_box">
            <h2 className="ttl">スケジュール</h2>
            <ul>
              <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
            </ul>
          </div>
          <div id="schedule">
            <div className="pagination">
                <a style={{ cursor: "pointer"}} onClick={(e) => { fetchData(-1); }}>
                <span className="material-icons">chevron_left</span>前へ
                </a>
                {currentMonth ?
                <p>{ dayjs(currentMonth).format('YYYY年MM月') }</p>
                : <></>}
                <a style={{ cursor: "pointer"}} onClick={(e) => {  fetchData(1); }}>
                次へ<span className="material-icons">chevron_right</span>
                </a>
            </div>
            {dateArray().length === 0 ?
            <ul>
                <li>予定されているスケジュールはありません</li>
            </ul>
            :
            <ul>
                 {dateArray().map(( d: any) => (
                  <li>
                  <p className="date"><span>{ d.date }</span>{ d.month }月、{ d.day }</p>
                    <ul>
                    {filterInterviewByDate(d.date).map(( i: any) => (
                    <li key={i.id}>
                      <a href={`/mypage/applications/${i.application_id}`}>
                        <p className="time">{dayjs(i.start_time).format('HH:mm')}〜</p>
                        <p className={`category ${i.type.id === 2 ? 'traning' : ''}`}>{i.type.name}</p>
                        <div className="img_box" style={{ backgroundImage: backgroundImageUrl(i.company.logo) }}></div>
                        <p className="name">
                          <span>【{i.recruitment.title}】へ応募</span>
                          {i.company.name }
                        </p>
                      </a>
                    </li>))}
                    </ul>
                   </li>
                   ))}
            </ul>
             }
          </div>
          <div>
            <a href="#" className="btn type3" onClick={() => window.history.go(-1)}>戻る</a>
          </div>
        </section>
      </main>
    );

}

export default Schedules;