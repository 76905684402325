import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../redax/reducers/rootReducer";
import { useEffect, useState } from "react";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import Loader from "../../../loader/loader";
import { fetchHelloRequest } from "../../../../redax/actions/helloActions/helloActions";


export default function RecruiterRecruitmentsCreate() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginData = useSelector((state: RootState) => state.login);
    const recruitmentsCompany = useSelector((state: RootState) => state.recruitmentsCompany);
    const hello = useSelector((state: RootState) => state.hello);
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [recruitments, setRecruitments] = useState<any>([]);
    const [pages, setPages] = useState<any>(null);
    const [jobOfferFive, setjobOfferFive] = useState<any>('');
    const [jobOfferEight, setjobOfferEight] = useState<any>('');
    const [error, setError] = useState(true);
    const [errorjobOfferFiveNumeric, setErrorjobOfferFiveNumeric] = useState(false);
    const [errorjobOfferFivemaxLength, setErrorjobOfferFivemaxLength] = useState(false);
    const [errorjobOfferEightmaxLength, setErrorjobOfferEightmaxLength] = useState(false); 
    const [errorjobOfferEightNumeric, setErrorjobOfferEightNumeric] = useState(false);
    const [loadScreen, setLoadScreen] = useState(false);
    const [errorHello, setErrorHello] = useState(false);
    const pageTitle = '求人投稿・管理　新規作成｜パラちゃんねる';
    document.title = pageTitle;

    useEffect(() => {
        if (loginData.pending) {
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            setCompanyId(loginData.login.company.id);
        }
      }, [loginData.pending]);

      useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
          dispatch(fetchLoginRequest('', '',''))
        } else (
            navigate('/recruiter/login/')
        )
      }, [dispatch]);

    function logout() {
        if (localStorage.entryFlag) {
            localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
            localStorage.removeItem('jobParachannelAuthToken')
            navigate('/');
            window.location.reload();
        }
    }

    function checkStatus () {
      if (!loginData.login.is_active) {
        if (window.confirm('採用活動をおやすみ中です。再開しますか？')) {
          navigate('/recruiter/settings/');
          return
        }
        return
      }
      navigate('/recruiter/recruitments/create/step_1/');
    }

    const onChangeStep = (event: any) => {
      setErrorHello(false);
        const {name, value} = event.target;
        if(name === 'number1') {
          setjobOfferFive(value);
        }
        if(name === 'number2') {
          setjobOfferEight(value);
      }
        Validation(name, value);
    }

  function Validation (name: any, value: any) {
    if(name === 'number1') {
      if(value === '') {
         setError(true);
         setErrorjobOfferFiveNumeric(false)
         setErrorjobOfferFivemaxLength(false)
      } else {
        if(jobOfferEight == '') {
          setError(true);
        } else {
          setError(false);
        }
        if(!/[0-9]/.test(value)){
          setErrorjobOfferFiveNumeric(true)
         }else {
          setErrorjobOfferFiveNumeric(false)
        }
        if(value.length ===5){
          setErrorjobOfferFivemaxLength(false)
        }else {
          setErrorjobOfferFivemaxLength(true)
        }
      }
    }
    if(name === 'number2') {
      if(value === '') {
         setError(true);
         setErrorjobOfferEightNumeric(false)
         setErrorjobOfferEightmaxLength(false)
      } else {
        if(jobOfferFive == '') {
          setError(true);
        } else {
          setError(false);
        }
        if(!/[0-9]/.test(value)){
          setErrorjobOfferEightNumeric(true)
         }else {
          setErrorjobOfferEightNumeric(false)
        }
        if(value.length ===8){
          setErrorjobOfferEightmaxLength(false)
        }else {
          setErrorjobOfferEightmaxLength(true)
        }
      }
    }
  }

  function submit(e: any) {
    e.preventDefault();
    if (!loginData.login.is_active) {
      // e.preventDefault()
      if (window.confirm('採用活動をおやすみ中です。再開しますか？')) {
        navigate('/recruiter/settings/')
        return
      }
    } else {
      if(!error && !errorjobOfferFiveNumeric && !errorjobOfferFivemaxLength && !errorjobOfferEightNumeric && !errorjobOfferEightmaxLength) {
        const helloWorkNumber = jobOfferFive + jobOfferEight;
        setLoadScreen(true);
        dispatch(fetchHelloRequest(helloWorkNumber));
      }
    }
  }

  useEffect(() => {
    if (hello.pending) {
      setLoadScreen(false);
      const jobOfferDTO = toDTO(hello.hello);
      console.log(jobOfferDTO);
      navigate('/recruiter/recruitments/create/step_1/', {state: jobOfferDTO});
    } else if(hello.error != null) {
      setLoadScreen(false);
      setErrorHello(true);
    }
  }, [hello]);

  function toDTO(apiResponse: any) {
    return {
      'jobTitle': apiResponse.job_title,
      'jobTypeId': apiResponse.job_category_type_id,
      'jobCategoryId': apiResponse.job_category_id,
      'typeOfRecruitingCompanyId': apiResponse.type_of_recruiting_company_id,
      'companyId': apiResponse.company_id,
      'companyName': apiResponse.company_name,
      'employmentStatusId': apiResponse.employment_status_id,
      'businessContent': apiResponse.business_content,
      'requiredExperience': apiResponse.required_experience,
      'workFromHomeId': apiResponse.work_location.work_from_home_id,
      'nationwide': apiResponse.work_location.nationwide,
      'postCode': apiResponse.work_location.postcode,
      'locationRegionId': apiResponse.work_location.region_id,
      'locationPrefectureId': apiResponse.work_location.prefecture_id,
      'locationPrefectureName': apiResponse.work_location.prefecture_name,
      'locationCityId': apiResponse.work_location.city_id,
      'locationCityName': apiResponse.work_location.city_name,
      'locationAddress': apiResponse.work_location.address,
      'locationDepartment': apiResponse.work_location.department,
      'locationNearestStation': apiResponse.work_location.nearest_station,
      'locationTransportation': apiResponse.work_location.transportation,
      'locationWorkLocationSupplemental': apiResponse.work_location.work_location_supplemental,
      'contractPeriodId': apiResponse.period.contract_period_id,
      'contractPeriodExists': apiResponse.period.contract_period_exists,
      'contractRenewalExists': apiResponse.period.contract_renewal_exists,
      'fullTimeEmployeeExists': apiResponse.period.full_time_employee_exists,
      'contractPeriodSupplemental': apiResponse.period.contract_period_supplemental,
      'trialPeriodExists': apiResponse.period.trial_period_exists,
      'trialPeriodId': apiResponse.period.trial_period_id,
      'trialPeriodSupplemental': apiResponse.period.trial_period_supplemental,
      'startTime1': apiResponse.work_time.start_time1,
      'endTime1': apiResponse.work_time.end_time1,
      'startTime2': apiResponse.work_time.start_time2,
      'endTime2': apiResponse.work_time.end_time2,
      'startTime3': apiResponse.work_time.start_time3,
      'endTime3': apiResponse.work_time.end_time3,
      'workTimeSupplemental': apiResponse.work_time.work_time_supplemental,
      'restTime': apiResponse.work_time.rest_time,
      'restTimeSupplemental': apiResponse.work_time.rest_time_supplemental,
      'numberOfWorkingDays': apiResponse.work_time.number_of_working_days,
      'numberOfWorkingDaysSupplemental': '',
      'overtimeHoursExists': apiResponse.work_time.overtime_hours_exists,
      'overtimeHoursSupplemental': apiResponse.work_time.overtime_hours_supplemental,
      'holidayIds': apiResponse.holidays.map(function(v: any){ return v['holiday_id'] }),
      'annualHolidayCount': apiResponse.work_time.annual_holiday,
      'vacationIds': apiResponse.vacations.map(function(v: any){ return v['vacation_id'] }),
      'holidaysSupplemental': apiResponse.work_time.holidays_supplemental,
      'salaryFormId': apiResponse.salary.salary_form_id,
      'lowerMonthlyBasicSalary': apiResponse.salary.lower_monthly_basic_salary,
      'upperMonthlyBasicSalary': apiResponse.salary.upper_monthly_basic_salary,
      'annualNumberOfBonusExists': apiResponse.salary.annual_number_of_bonus_exists,
      'annualNumberOfBonus': apiResponse.salary.annual_number_of_bonus,
      'lowerEstimatedAnnualIncome': apiResponse.salary.lower_estimated_annual_income,
      'upperEstimatedAnnualIncome': apiResponse.salary.upper_estimated_annual_income,
      'annualNumberOfRaisesExists': apiResponse.salary.annual_number_of_raises_exists,
      'annualNumberOfRaises': apiResponse.salary.annual_number_of_raises,
      'allowanceIds': apiResponse.allowances.map(function(v: any){ return v['allowance_id'] }),
      'allowancesSupplemental': '',
      'retirementMoneyExists': apiResponse.retirement_money_exists,
      'retirementAge': apiResponse.retirement_age,
      'socialInsuranceIds': apiResponse.social_insurances.map(function(v: any){ return v['social_insurance_id'] }),
      'selectionMethodIds': apiResponse.selection_methods.map(function(v: any){ return v['selection_method_id'] }),
      'benefitSupplemental': apiResponse.benefit_supplemental,
      'selectionMethodSupplemental': apiResponse.selection_method_supplemental,
      'numberOfEmployees': apiResponse.target.number_of_employees
    }
  }

    return (
        <main id="company">
          <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
          {loadScreen ? <Loader /> : <></>}
          <section>
            <ul className="bread_crumb">
              <li><a href="/">トップ</a></li>
              <li><a href="/recruiter/">企業様マイページ</a></li>
              <li><a href="#">求人管理</a></li>
            </ul>
            <div className="ttl_box">
              <h2 className="ttl">求人管理 ＞ 新規</h2>
              <ul>
                <li><a href="#" onClick={logout} ><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
              </ul>
            </div>
            <div className="jobs create1">
              <div>
                <form action="">
                  <h3>ハローワークの求人番号で求人を作成</h3>
                  <p>下記に求人番号を半角英数字でご入力ください。</p>
                  <label htmlFor="">
                    <input v-model="jobOfferFive"  type="text" name="number1" placeholder="5桁" onChange={onChangeStep}  value={jobOfferFive}/>
                    <span>ー</span>
                    <input v-model="jobOfferEight" type="text" name="number2" placeholder="8桁" onChange={onChangeStep}  value={jobOfferEight}/>
                  </label>
                  {error || errorjobOfferFiveNumeric || errorjobOfferFivemaxLength || errorjobOfferEightmaxLength || errorjobOfferEightNumeric? 
                        <div>
                            {error  ?
                                <p>必須項目です</p>
                            : <></>}
                            {errorjobOfferFiveNumeric ||  errorjobOfferEightNumeric  ?
                                <p>求人番号は数字で入力してください</p>
                            : <></>}
                            {errorjobOfferFivemaxLength  ?
                                <p>求人番号の前半は5桁で入力してください</p>
                            : <></>}
                            {errorjobOfferEightmaxLength  ?
                                <p>求人番号の後半は8桁で入力してください</p>
                            : <></>}
                            
                        </div>
                    : <></>}
                    {errorHello ?
                    <div><p>求人データが取得できませんでした。</p></div> : <></>}
                  {/* <p v-if="error">求人データが取得できませんでした。</p> */}
                  <button type="submit" onClick={(e) => submit(e)} className="btn type3" >求人を作成する</button>
                </form>
              </div>
              <p className="note">※1:本機能はハローワークをご利用の企業様のうち、「求人情報のオンライン提供」に同意した求人を転載できます。<br className="pc_only"/>未同意の場合、<a href="https://www.hellowork.mhlw.go.jp/doc/leaflet_kyuujinn_teikyou202001.pdf" target="_blank">こちら</a>から対応方法をご確認ください。(所要時間:約1-2分)<br/>
              ※2:毎週月曜10時時点のハローワーク上の求人情報を抽出しています。</p>
              <a href="javascript:void(0)" className="btn type3" onClick={checkStatus}>ハローワークで求人を<br className="sp_only"/>行っていない企業様はこちら</a>
            </div>
          </section>
        </main>
    )
}
