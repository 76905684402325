import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchPaymentDetailFailure, fetchPaymesntDetailSuccess } from "../../actions/recruiterInvoiceDetailActions/recruiterPaymentDetailActions";
import { RecruiterPaymentDetailTypes } from "../../ActionTypes/recruiterInvoiceDetailTypes";

var getRecruiterInvoiceDetail = (id:any) => {
    return axios.get(API_BASE_ADDRESS + '/recruiter/invoice/' + id , {headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken}}); 
}

function* fetchRecruiterInvoiceDetailSaga(id: any) {
  try {
    const response: AxiosResponse = yield call(getRecruiterInvoiceDetail, id.id);
    yield put(
        fetchPaymesntDetailSuccess({
        recruiterPaymentDetail: response.data        
      })
    );
  } catch (e:any) {
    yield put(
        fetchPaymentDetailFailure({
        error: e.message
      })
    );
  }
}

function* RecruiterInvoiceDetailSaga() {
  yield takeLatest(RecruiterPaymentDetailTypes.FETCH_RECRUITER_PAYMENT_DETAIL_REQUEST, fetchRecruiterInvoiceDetailSaga);
}

export default RecruiterInvoiceDetailSaga;
