import { scoutsApplicantsTypes } from "../../ActionTypes/scoutsApplicantsTypes";
import { FetchScoutsApplicantsFailure, FetchScoutsApplicantsFailurePayload, FetchScoutsApplicantsRequest, FetchScoutsApplicantsSuccess, FetchScoutsApplicantsSuccessPayload } from "../../types/type";




export const fetchScoutsApplicantsRequest = (query:any): FetchScoutsApplicantsRequest => ({
  type: scoutsApplicantsTypes.FETCH_SCOUTS_APPLICANTS_REQUEST,
  query,
});

export const fetchScoutsApplicantsSuccess = (
  payload: FetchScoutsApplicantsSuccessPayload,
): FetchScoutsApplicantsSuccess => ({
  type: scoutsApplicantsTypes.FETCH_SCOUTS_APPLICANTS_SUCCESS,
  payload
});

export const fetchScoutsApplicantsFailure = (
  payload: FetchScoutsApplicantsFailurePayload
): FetchScoutsApplicantsFailure => ({
  type: scoutsApplicantsTypes.FETCH_SCOUTS_APPLICANTS_FAILURE,
  payload
});
