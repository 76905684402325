import { emailChangeTypes } from "../../ActionTypes/emailChangeTypes";
import { FetchEmailChangeFailure, FetchEmailChangeFailurePayload, FetchEmailChangeRequest, FetchEmailChangeSuccess, FetchEmailChangeSuccessPayload } from "../../types/type";

export const fetchEmailChangeRequest = (email:any,): FetchEmailChangeRequest => ({
  type: emailChangeTypes.FETCH_EMAIL_CHANGE_REQUEST,
  email,
});

export const fetchEmailChangeSuccess = (
  payload: FetchEmailChangeSuccessPayload,
): FetchEmailChangeSuccess => ({
  type: emailChangeTypes.FETCH_EMAIL_CHANGE_SUCCESS,
  payload
});

export const fetchEmailChangeFailure = (
  payload: FetchEmailChangeFailurePayload
): FetchEmailChangeFailure => ({
  type: emailChangeTypes.FETCH_EMAIL_CHANGE_FAILURE,
  payload
});
