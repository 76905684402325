import { RecruiterPlanTypes } from "../../ActionTypes/recruiterPlan";
import { FetchRecruiterPlanFailure, FetchRecruiterPlanFailurePayload, FetchRecruiterPlanRequest, FetchRecruiterPlanSuccess, FetchRecruiterPlanSuccessPayload } from "../../types/type";


export const fetchRecruiterPlanRequest = (): FetchRecruiterPlanRequest => ({
  type: RecruiterPlanTypes.FETCH_RECRUITER_PLAN_REQUEST
});

export const fetchRecruiterPlanSuccess = (
  payload: FetchRecruiterPlanSuccessPayload,
): FetchRecruiterPlanSuccess => ({
  type: RecruiterPlanTypes.FETCH_RECRUITER_PLAN_SUCCESS,
  payload
});

export const fetchRecruiterPlanFailure = (
  payload: FetchRecruiterPlanFailurePayload
): FetchRecruiterPlanFailure => ({
  type: RecruiterPlanTypes.FETCH_RECRUITER_PLAN_FAILURE,
  payload
});
