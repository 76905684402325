import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminNav from '../adminTop/AdminNav';
import { RootState } from "../../redax/reducers/rootReducer";
import { fetchAdminCompanyListRequest } from '../../redax/actions/adminCompanyListActions/adminCompanyListActions';
import Loader from '../loader/loader';
import './adminHome.css';
import { fetchSummaryRequest } from '../../redax/actions/summaryActions/summaryActions';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
const AdminHome = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();    
    const summary = useSelector((state:RootState) => state.summary);
    const [loadScreen, setLoadScreen] = useState(false);
    const [statistics, setStatistics] = useState<any>(null);
    const [latestApplicants, setLatestApplicants] = useState<any>([]);
    const [latestCompanies, setlatestCompanies] = useState<any>([]);
    const [latestRecruitments, setLatestRecruitments] = useState<any>([]);
    const [lastLoginCompanies, setLastLoginCompanies] = useState<any>([]);

    function formatDate (value: any) {
        return dayjs(value).locale(ja).format('YYYY.MM.DD (ddd) HH:mm');
    }
    

    
  useEffect(() => {
    dispatch(fetchSummaryRequest())
}, [dispatch]);


useEffect(() => {
    if(summary.pending){
        console.log(summary);
        setStatistics(summary.summary.statistics);
        setLatestApplicants(summary.summary.latest_applicants);
        setLatestRecruitments(summary.summary.latest_recruitments);
        setlatestCompanies(summary.summary.latest_companies);
        setLastLoginCompanies(summary.summary.last_login_companies);
    }
}, [summary]);

  return (
    <main id='adminHome'>
      <AdminNav/>
      {loadScreen ? <Loader></Loader> : <></>}
      <section id='adminHomeStyle'>
        <div className="adminPageWrapper">
            <div className="header">
                <h2 className="title">アクティビティ</h2>
            </div>
          <div className="body">
            <div className="">
              <div className="textWrapper">
                <a onClick={() => navigate('/admin/')} className='blueBtn'>＜ 一つ前に戻る</a>
              </div>
            </div>
            {statistics ? 
            <div>
                <div className='mt20'>
                    <h1>登録数</h1>
                </div>
                <div className='dFlex mt20'>
                    <a href={'/admin/applicants_list/'}>
                      <div className='greyBack'>
                        <div className='dFlex'> 
                          <h3>求職者登録数 <span>{ statistics.total_applicants }</span> 名</h3>  
                          <img src="/assets/img/User.svg" alt="" />
                        </div>
                      </div>
                    </a>
                   <a href="/admin/companies">
                    <div className='greyBack'>
                      <div className='dFlex'>
                        <h3>企業登録数 <span>{ statistics.total_companies }</span>社</h3>
                        <img src="/assets/img/company.svg" alt="" />
                      </div>
                    </div></a> 
                    <a href="/admin/openjob_list">
                      <div className='greyBack'>
                        <div className='dFlex'>
                          <h3>求人登録数 <span>{ statistics.total_recruitments }</span>件</h3>
                          <img src="/assets/img/job.svg" alt="" />
                        </div>
                      </div>
                    </a>
                </div>
                <div className='mt20'>
                    <h1>全体の応募状況</h1>
                </div>
                <div className='greyBack1 mt20'>
                    <div className='dFlex1'>
                        <h3>審査待ち <span>{ statistics.applications.waiting }</span></h3>
                        <h3>面談設定済み <span>{ statistics.applications.interview_arranged }</span></h3>
                        <h3>面談済み <span>{ statistics.applications.interviewed }</span></h3>
                        <h3>実習設定済み <span>{ statistics.applications.training_arranged }</span></h3>
                        <h3>実習済み <span>{ statistics.applications.trained }</span></h3>
                        <h3>内定通知済み <span>{ statistics.applications.unofficially }</span></h3>
                    </div>
                    <div className='dFlex1 mt20'>
                        <h3>採用決定 <span>{ statistics.applications.recruiting }</span></h3>
                        <h3>不採用 <span>{ statistics.applications.rejected }</span></h3>
                        <h3>辞退 <span>{ statistics.applications.decline }</span></h3>
                    </div>
                </div>
            </div>
            : <></>}
            <div className='mt20 adminTable1Wrapper'>
                <h1>新規求職者</h1>
                <table className='table mt20'>
                    <th>♯</th>
                    {/* <th>画像</th> */}
                    <th>求職者名</th>
                    {/* <th>就職カテゴリー</th>
                    <th>障害区分</th>
                    <th> 生年月日 </th>
                    <th>  住所</th>
                    <th> 希望勤務地1/2 </th> */}
                    <th>登録日/最終ログイン日</th>
                    <th style={{textAlign:'center'}}>-</th>
                    {latestApplicants.length != 0 && latestApplicants.map((applicants: any, index: any) => (
                    <tr key={index}>
                      <td>{index +1}</td>
                      {/* <td></td> */}
                      <td>{applicants.family_name} { applicants.first_name }</td>
                      {/* <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td> */}
                      <td>{formatDate(applicants.created_at)}</td>
                      <td style={{textAlign: "center", width: "5%", padding: "5px"}}><button className='btn openBtn' onClick={(e) => {navigate('/admin/applicants_list/' + applicants.id)}}>詳細を見る</button></td>
                    </tr>    
                  ))}
                </table>
            </div>

            <div className='mt20 adminTable1Wrapper'>
                <h1>新規企業</h1>
                <table className='table mt20'>
                    <th>ID</th>
                    <th>企業名</th>
                    {/* <th>業種分類</th>
                    <th>住所</th>
                    <th>契約プラン/次回プラン</th> */}
                    <th> 登録日/最終ログイン </th>
                    <th style={{textAlign:'center'}}>-</th>
                    {latestCompanies.length != 0 && latestCompanies.map((company: any, index: any) => (
                    <tr key={index}>
                      <td>{company.id}</td>
                      <td>{company.name}</td>
                      {/* <td></td>
                      <td></td>
                      <td></td> */}
                      <td>{formatDate(company.created_at)}</td>
                      <td style={{textAlign: "center", width: "5%", padding: "5px"}}><button className='btn openBtn' onClick={(e) => {navigate('/admin/companies/' + company.id)}}>確認する</button></td>
                    </tr>    
                  ))}
                </table>
            </div>

            <div className='mt20 adminTable1Wrapper'>
                <h1>新規公開求人</h1>
                <table className='table mt20'>
                    <th>♯</th>
                    {/* <th>TOP画像</th> */}
                    <th>求人名</th>
                    <th>会社名</th>
                    {/* <th>職種</th>
                    <th> 雇用形態 </th>
                    <th>  本社</th> */}
                    <th> 登録日/更新日</th>
                    <th style={{textAlign:'center'}}>-</th>
                    {latestRecruitments.length != 0 && latestRecruitments.map((recuitment: any, index: any) => (
                    <tr key={index}>
                      <td>{index +1}</td>
                      {/* <td></td> */}
                      <td>{recuitment.job_title}</td>
                      <td>{recuitment.company_name}</td>
                      {/* <td></td>
                      <td></td>
                      <td></td> */}
                      <td>{formatDate(recuitment.created_at)}</td>
                      <td style={{textAlign: "center", width: "5%", padding: "5px"}}><button className='btn openBtn' onClick={(e) => {navigate('/admin/openjob_list/' +  recuitment.id)}}>詳細を見る</button></td>
                    </tr>    
                  ))}
                </table>
            </div>


            <div className='mt20 adminTable1Wrapper'>
                <h1>直近でログインした企業</h1>
                <table className='table mt20'>
                    <th>ID</th>
                    <th>企業名</th>
                    {/* <th>業種分類</th>
                    <th>住所</th>
                    <th>契約プラン/次回プラン</th> */}
                    <th> 登録日/最終ログイン </th>
                    <th style={{textAlign:'center'}}>-</th>
                    {lastLoginCompanies.length != 0 && lastLoginCompanies.map((data: any, index: any) => (
                    <tr key={index}>
                      <td>{index +1}</td>
                      <td>{data.name}</td>
                      {/* <td></td>
                      <td></td>
                      <td></td> */}
                      <td>{formatDate(data.last_login_at)}</td>
                      <td style={{textAlign: "center", width: "5%", padding: "5px"}}><button className='btn openBtn' onClick={(e) => {navigate('/admin/companies/' + data.id)}}>確認する</button></td>
                    </tr>    
                  ))}
                </table>
            </div>
          
          </div>

        </div>
      </section>
    </main>
  )
}

export default AdminHome
