import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminOpenJobRegionsTypes } from "../../ActionTypes/adminOpenJobRegionsTypes";
import { fetchAdminOpenJobRegionsFailure, fetchAdminOpenJobRegionsSuccess } from "../../actions/adminOpenJobRegionsActions/adminOpenJobRegionsActions";

var getRegions = () => axios.get(API_BASE_ADDRESS + '/master?job_categories=true&recruitments=true&work_from_home=true&regions=true&handicap_types=true&appeal_items=true&compatibilities=true&ideal_candidates_unique=true&work_environments=true&consideration_conditions=true&consideration_condition_types=true', {headers: { Accept: 'application/json'}});


function* fetchAdminOpenJobRegionsSaga() {
  try {
    const response: AxiosResponse = yield call(getRegions);
    yield put(
      fetchAdminOpenJobRegionsSuccess({
        regions: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminOpenJobRegionsFailure({
        error: e.message
      })
    );
  }
}

function* AdminOpenJobRegionsSaga() {
  yield takeLatest(adminOpenJobRegionsTypes.FETCH_ADMIN_OPEN_JOB_REGIONS_REQUEST, fetchAdminOpenJobRegionsSaga);
}

export default AdminOpenJobRegionsSaga;
