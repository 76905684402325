import { adminRecruitmentListTypes } from "../../ActionTypes/adminRecruitmentListTypes";
import { adminScoutsTypes } from "../../ActionTypes/adminScoutsTypes";
import {
  FetchAdminScoutsRequest,
  FetchAdminScoutsSuccessPayload,
  FetchAdminScoutsSuccess,
  FetchAdminScoutsFailurePayload,
  FetchAdminScoutsFailure
} from "../../types/type";

export const fetchAdminScoutsRequest = (data: any): FetchAdminScoutsRequest => ({
  type: adminScoutsTypes.FETCH_ADMIN_SCOUTS_REQUEST,
  data
});

export const fetchAdminScoutsSuccess = (
  payload: FetchAdminScoutsSuccessPayload
): FetchAdminScoutsSuccess => ({
  type: adminScoutsTypes.FETCH_ADMIN_SCOUTS_SUCCESS,
  payload
});

export const fetchAdminScoutsFailure = (
  payload: FetchAdminScoutsFailurePayload
): FetchAdminScoutsFailure => ({
  type: adminScoutsTypes.FETCH_ADMIN_SCOUTS_FAILURE,
  payload
});