import { recruitmentsCompanyTypes } from "../../ActionTypes/recruitmentsCompanyTypes";
import { FetchRecruitmentsCompanyFailure, FetchRecruitmentsCompanyFailurePayload, FetchRecruitmentsCompanyRequest, FetchRecruitmentsCompanySuccess, FetchRecruitmentsCompanySuccessPayload } from "../../types/type";

export const fetchRecruitmentsCompanyRequest = (company_id:any, page: any): FetchRecruitmentsCompanyRequest => ({
  type: recruitmentsCompanyTypes.FETCH_RECRUIMENTS_COMPANY_REQUEST,
  company_id,
  page,
});

export const fetchRecruitmentsCompanySuccess = (
  payload: FetchRecruitmentsCompanySuccessPayload,
): FetchRecruitmentsCompanySuccess => ({
  type: recruitmentsCompanyTypes.FETCH_RECRUIMENTS_COMPANY_SUCCESS,
  payload
});

export const fetchRecruitmentsCompanyFailure = (
  payload: FetchRecruitmentsCompanyFailurePayload
): FetchRecruitmentsCompanyFailure => ({
  type: recruitmentsCompanyTypes.FETCH_RECRUIMENTS_COMPANY_FAILURE,
  payload
});
