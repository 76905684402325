import { adminApplicationsListTypes } from "../../ActionTypes/adminApplicationsListTypes";
import { adminRecruitmentListTypes } from "../../ActionTypes/adminRecruitmentListTypes";
import {
  FetchAdminApplicationsListRequest,
  FetchAdminApplicationsListSuccessPayload,
  FetchAdminApplicationsListSuccess,
  FetchAdminApplicationsListFailurePayload,
  FetchAdminApplicationsListFailure
} from "../../types/type";

export const fetchAdminApplicationsListRequest = (data: any): FetchAdminApplicationsListRequest => ({
  type: adminApplicationsListTypes.FETCH_ADMIN_APPLICATIONS_LIST_REQUEST,
  data
});

export const fetchAdminApplicationsListSuccess = (
  payload: FetchAdminApplicationsListSuccessPayload
): FetchAdminApplicationsListSuccess => ({
  type: adminApplicationsListTypes.FETCH_ADMIN_APPLICATIONS_LIST_SUCCESS,
  payload
});

export const fetchAdminApplicationsListFailure = (
  payload: FetchAdminApplicationsListFailurePayload
): FetchAdminApplicationsListFailure => ({
  type: adminApplicationsListTypes.FETCH_ADMIN_APPLICATIONS_LIST_FAILURE,
  payload
});