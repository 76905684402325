import { scoutsApplicantsTypes } from "../../ActionTypes/scoutsApplicantsTypes";
import { ScoutsApplicantsActions, ScoutsApplicantsState } from "../../types/type";

const initialState: ScoutsApplicantsState = {
  pending: false,
  scoutsApplicants: [],
  error: null,
};

export default (state = initialState, action: ScoutsApplicantsActions,) => {
  switch (action.type) {
    case scoutsApplicantsTypes.FETCH_SCOUTS_APPLICANTS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case scoutsApplicantsTypes.FETCH_SCOUTS_APPLICANTS_SUCCESS:
      return {
        ...state,
        pending: true,
        scoutsApplicants: action.payload.scoutsApplicants,
        error: null
      };
    case scoutsApplicantsTypes.FETCH_SCOUTS_APPLICANTS_FAILURE:
      return {
        ...state,
        pending: false,
        scoutsApplicants: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
