import { adminApplicantsCsvTypes } from "../../ActionTypes/adminApplicantsCsvTypes";
import { AdminApplicantsCsvActions, AdminApplicantsCsvState } from "../../types/type";



const initialState: AdminApplicantsCsvState = {
  pending: false,
  csv: [],
  error: null,
};

export default (state = initialState, action: AdminApplicantsCsvActions,) => {
  switch (action.type) {
    case adminApplicantsCsvTypes.FETCH_ADMIN_APPLICANTS_CSV_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminApplicantsCsvTypes.FETCH_ADMIN_APPLICANTS_CSV_SUCCESS:
      return {
        ...state,
        pending: true,
        csv: action.payload.csv,
        error: null
      };
    case adminApplicantsCsvTypes.FETCH_ADMIN_APPLICANTS_CSV_FAILURE:
      return {
        ...state,
        pending: false,
        csv: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
