import { matomeDetailTypes } from "../../ActionTypes/matomeDetailTypes";
import { MatomeDetailActions, MatomeDetailState } from "../../types/type";


const initialState: MatomeDetailState = {
  pending: false,
  matome: [],
  pagination:[],
  error: null,
};

export default (state = initialState, action: MatomeDetailActions,) => {
  switch (action.type) {
    case matomeDetailTypes.FETCH_MATOME_DETAIL_REQUEST:
      return {
        ...state,
        pending: false
      };
    case matomeDetailTypes.FETCH_MATOME_DETAIL_SUCCESS:
      return {
        ...state,
        pending: true,
        matome: action.payload.matome,
        pagination: action.payload.pagination,
        error: null
      };
    case matomeDetailTypes.FETCH_MATOME_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        matome: [],
        pagination:[],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
