import { applicantsTypes } from "../../ActionTypes/applicantsTypes";
import { FetchApplicantsFailure, FetchApplicantsFailurePayload, FetchApplicantsRequest, FetchApplicantsSuccess, FetchApplicantsSuccessPayload } from "../../types/type";

export const fetchApplicantsRequest = (id:any, types: any, a: any): FetchApplicantsRequest => ({
  type: applicantsTypes.FETCH_APPLICANTS_REQUEST,
  id,
  types,
  a
});

export const fetchApplicantsSuccess = (
  payload: FetchApplicantsSuccessPayload,
): FetchApplicantsSuccess => ({
  type: applicantsTypes.FETCH_APPLICANTS_SUCCESS,
  payload
});

export const fetchApplicantsFailure = (
  payload: FetchApplicantsFailurePayload
): FetchApplicantsFailure => ({
  type: applicantsTypes.FETCH_APPLICANTS_FAILURE,
  payload
});
