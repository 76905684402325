

import { regionsTypes } from "../../ActionTypes/regionsTypes";
import {
  FetchRegionsFailure,
  FetchRegionsFailurePayload,
  FetchRegionsRequest,
  FetchRegionsSuccess,
  FetchRegionsSuccessPayload,
} from "../../types/type";

export const fetchRegionsRequest = (): FetchRegionsRequest => ({
  type: regionsTypes.FETCH_REGIONS_REQUEST,
});

export const fetchRegionsSuccess = (
  payload: FetchRegionsSuccessPayload,
): FetchRegionsSuccess => ({
  type: regionsTypes.FETCH_REGIONS_SUCCESS,
  payload
});

export const fetchRegionsFailure = (
  payload: FetchRegionsFailurePayload
): FetchRegionsFailure => ({
  type: regionsTypes.FETCH_REGIONS_FAILURE,
  payload
});
