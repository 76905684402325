import { passwordResetTypes } from "../../ActionTypes/passwordResetTypes";
import { PasswordResetActions, PasswordResetState } from "../../types/type";



const initialState: PasswordResetState = {
  pending: false,
  password: [],
  error: null,
};

export default (state = initialState, action: PasswordResetActions,) => {
  switch (action.type) {
    case passwordResetTypes.FETCH_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        pending: false
      };
    case passwordResetTypes.FETCH_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        pending: true,
        password: action.payload.password,
        error: null
      };
    case passwordResetTypes.FETCH_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        pending: false,
        password: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
