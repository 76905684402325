import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";

import { adminRecruitmentCsvTypes } from "../../ActionTypes/adminRecruitmentCsvTypes";
import { fetchAdminRecruitmentCsvFailure, fetchAdminRecruitmentCsvSuccess } from "../../actions/adminRecruitmentCsvActions/adminRecruitmentCsvActions";

const getAdminRecruitmentCsv = () => {
    return axios.get(API_BASE_ADDRESS + '/admin/recruitments/csv', {
      headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }
    });
}

function* fetchAdminRecruitmentSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminRecruitmentCsv);
    yield put(
      fetchAdminRecruitmentCsvSuccess({
        csv: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminRecruitmentCsvFailure({
        error: e.message
      })
    );
  }
}

function* AdminRecruitmentCsvSaga() {
  yield takeLatest(adminRecruitmentCsvTypes.FETCH_ADMIN_RECRUITMENT_CSV_REQUEST, fetchAdminRecruitmentSaga);
}
export default AdminRecruitmentCsvSaga;