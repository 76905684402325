import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchDownloadResumeSuccess, fetchDownloadResumeFailure } from "../../actions/downloadResumeActions/downloadResumeActions";
import { DownloadResumeTypes } from "../../ActionTypes/downloadResumeTypes";

var getDownloadResume = (applicantId:any) => axios.get(API_BASE_ADDRESS + "/applicants/" + applicantId + '/resume/', {headers: {
    mode: 'cors',
    Authorization: localStorage.getItem('jobParachannelAuthToken') ? localStorage.getItem('jobParachannelAuthToken') : localStorage.getItem('jobParachannelAuthAdminToken'),
    Accept: '*/*',
  },
});
function* fetchDownloadResumeSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getDownloadResume, queryParams.applicantId);
    yield put(
      fetchDownloadResumeSuccess({
        applicantId: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchDownloadResumeFailure({
        error: e.message
      })
    );
  }
}

function* DownloadResumeSaga() {
  yield takeLatest(DownloadResumeTypes.FETCH_DOWNLOAD_RESUME_REQUEST, fetchDownloadResumeSaga);
}

export default DownloadResumeSaga;
