import { recruitmentsDeleteTypes } from "../../ActionTypes/recruitmentsDeleteTypes";
import { FetchRecruitmentsDeleteFailure, FetchRecruitmentsDeleteFailurePayload, FetchRecruitmentsDeleteRequest, FetchRecruitmentsDeleteSuccess, FetchRecruitmentsDeleteSuccessPayload } from "../../types/type";

export const fetchRecruitmentsDeleteRequest = (id:any): FetchRecruitmentsDeleteRequest => ({
  type: recruitmentsDeleteTypes.FETCH_RECRUIMENTS_DELETE_REQUEST,
  id,
});

export const fetchRecruitmentsDeleteSuccess = (
  payload: FetchRecruitmentsDeleteSuccessPayload,
): FetchRecruitmentsDeleteSuccess => ({
  type: recruitmentsDeleteTypes.FETCH_RECRUIMENTS_DELETE_SUCCESS,
  payload
});

export const fetchRecruitmentsDeleteFailure = (
  payload: FetchRecruitmentsDeleteFailurePayload
): FetchRecruitmentsDeleteFailure => ({
  type: recruitmentsDeleteTypes.FETCH_RECRUIMENTS_DELETE_FAILURE,
  payload
});
