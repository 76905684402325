import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import Loader from '../../loader/loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchRegionsRequest } from '../../../redax/actions/regionsActions/regionsActions';
import { fetchAdminAdministratorsRequest } from '../../../redax/actions/adminAdministratorsActions/adminAdministratorsActions';
import { fetchAdminAdministratorsRegionsRequest } from '../../../redax/actions/adminAdministratorsRegionsActions/adminAdministratorsRegionsActions';
const AdminAdministratorDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const { administratorsData } = location.state || {}; 
  const dispatch = useDispatch();
  const [loadScreen, setLoadScreen] = useState(false);
  const [roles, setRoles] = useState<any[]>([]);
  const [naviChk, setNaviCk] = useState(false);
  const [roleId, setRoleId] = useState('');
  const [adminName, setAdminName] = useState('');
 
  const adminAdministratorsRegions = useSelector((state: RootState) => state.adminAdministratorsRegions);
  const administrators = useSelector((state: RootState) => state.adminAdministrators);
  useEffect(() => {
    if (administratorsData) {
      setAdminName(administratorsData.name)
      if(roles.length>0){
          for(let i = 0; i< roles.length; i++){
            if(roles[i].name == administratorsData.role){
              setRoleId(roles[i].id)
            }
          }
      }
    }
  }, [administratorsData, roles]);

  function handleSubmit ()  {
    let validation_messages = ''
    if(adminName == '') {
      validation_messages += "名前が入力されていません\n"
    }
    if(validation_messages !== '') {
      window.alert(validation_messages);
    } else {
      const administrator = {
        name: adminName,
        role_id: roleId
      }
      dispatch(fetchAdminAdministratorsRequest('put', administrator, id));
      setNaviCk(true);
    }
  };

  useEffect(() => {
    dispatch(fetchAdminAdministratorsRegionsRequest());
}, [dispatch]); 




useEffect(() => {
    if (administrators.pending && naviChk) {
        window.location.href = '/admin/administrators'
    }
  }, [administrators]);


useEffect(() => {
  if (adminAdministratorsRegions.pending) {
    if(adminAdministratorsRegions.regions.administrator_roles){
      setRoles(adminAdministratorsRegions.regions.administrator_roles)
    }
  }
}, [adminAdministratorsRegions]);
function handleChangeRoleId(e:any){
  setRoleId(e.target.value)
}
function handleChangeName(e:any){
  setAdminName(e.target.value)
}


  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader /> : null}
      <section>
        <div className="adminPageWrapper">
          <div className="header">
            <h2 className="title">管理者編集  </h2>
          </div> 
          <div className='body'>
            <div className="">
              <div className="textWrapper">
                <a  onClick={() => navigate('/admin/administrators')} className='blueBtn'>＜ 一覧に戻る</a>
              </div>
            </div>
            <div>
              <div className='form'>
                <div  className='form-groups'>
                  <label htmlFor="name">名前 <span className="requireds">※必須</span></label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={adminName}
                    onChange={(e)=>handleChangeName(e)}
                    required
                  />
                </div>
                <div className='form-groups'>
                  <label htmlFor="role">役割 <span className="requireds">※必須</span></label>
                  <select id="role" name="role" value={roleId || ''} onChange={(e)=>handleChangeRoleId(e)} required>
                    {roles && roles.map((role:any) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </div>
                <button  className='createBtn' style={{padding:'10.5px 20px'}}  onClick={handleSubmit}>変更</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AdminAdministratorDetail;