import { adminRecommendApplicantsTypes } from "../../ActionTypes/adminRecommendApplicantsTypes";
import { FetchAdminRecommendApplicantsRequest, FetchAdminRecommendApplicantsSuccessPayload, FetchAdminRecommendApplicantsSuccess, FetchAdminRecommendApplicantsFailurePayload, FetchAdminRecommendApplicantsFailure } from "../../types/type";



export const fetchAdminRecommendApplicantsRequest = (method: any, data: any): FetchAdminRecommendApplicantsRequest => ({
  type: adminRecommendApplicantsTypes.FETCH_ADMIN_RECOMMEND_APPLICANTS_REQUEST,
  method,
  data,
});

export const fetchAdminRecommendApplicantsSuccess = (
  payload: FetchAdminRecommendApplicantsSuccessPayload
): FetchAdminRecommendApplicantsSuccess => ({
  type: adminRecommendApplicantsTypes.FETCH_ADMIN_RECOMMEND_APPLICANTS_SUCCESS,
  payload
});

export const fetchAdminRecommendApplicantsFailure = (
  payload: FetchAdminRecommendApplicantsFailurePayload
): FetchAdminRecommendApplicantsFailure => ({
  type: adminRecommendApplicantsTypes.FETCH_ADMIN_RECOMMEND_APPLICANTS_FAILURE,
  payload
});