

import { recruitmentsSearchTypes } from "../../ActionTypes/recruitmentsSearchTypes";
import { 
  FetchRecruitmentsSearchFailure, 
  FetchRecruitmentsSearchFailurePayload,
  FetchRecruitmentsSearchRequest,
  FetchRecruitmentsSearchSuccess, 
  FetchRecruitmentsSearchSuccessPayload }
   from "../../types/type";


export const fetchRecruitmentsSearchRequest = (name: any, queryParams:any): FetchRecruitmentsSearchRequest => ({
  type: recruitmentsSearchTypes.FETCH_RECRUIMENTS_SEARCH_REQUEST,
  name,
  queryParams,
});

export const fetchRecruitmentsSearchSuccess = (
  payload: FetchRecruitmentsSearchSuccessPayload,
): FetchRecruitmentsSearchSuccess => ({
  type: recruitmentsSearchTypes.FETCH_RECRUIMENTS_SEARCH_SUCCESS,
  payload
});

export const fetchRecruitmentsSearchFailure = (
  payload: FetchRecruitmentsSearchFailurePayload
): FetchRecruitmentsSearchFailure => ({
  type: recruitmentsSearchTypes.FETCH_RECRUIMENTS_SEARCH_FAILURE,
  payload
});
