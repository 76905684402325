import { recruitmentsStatusChangeTypes } from "../../ActionTypes/recruitmentsStatusChangeTypes";
import { RecruitmentsStatusChangeActions, RecruitmentsStatusChangeState } from "../../types/type";


const initialState: RecruitmentsStatusChangeState = {
  pending: false,
  recruitmentsStatusChange: [],
  error: null,
};

export default (state = initialState, action: RecruitmentsStatusChangeActions,) => {
  switch (action.type) {
    case recruitmentsStatusChangeTypes.FETCH_RECRUIMENTS_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitmentsStatusChangeTypes.FETCH_RECRUIMENTS_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitmentsStatusChange: action.payload.recruitmentsStatusChange,
        error: null
      };
    case recruitmentsStatusChangeTypes.FETCH_RECRUIMENTS_STATUS_CHANGE_FAILURE:
      return {
        ...state,
        pending: false,
        recruitmentsStatusChange: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
