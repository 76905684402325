import { adminInvoicePostTypes } from "../../ActionTypes/adminInvoicePostTypes";
import { AdminInvoicePostActions, AdminInvoicePostState } from "../../types/type";


const initialState: AdminInvoicePostState = {
  pending: false,
  adminInvoices: [],
  error: null,
};

export default (state = initialState, action: AdminInvoicePostActions) => {
  switch (action.type) {
    case adminInvoicePostTypes.FETCH_ADMIN_INVOICE_POST_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminInvoicePostTypes.FETCH_ADMIN_INVOICE_POST_SUCCESS:
      return {
        ...state,
        pending: true,
        adminInvoices: action.payload.adminInvoices,
        error: null
      };
    case adminInvoicePostTypes.FETCH_ADMIN_INVOICE_POST_FAILURE:
      return {
        ...state,
        pending: false,
        adminInvoices: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
