import { adminCompanyRegionsTypes } from "../../ActionTypes/adminCompanyRegionsTypes";
import { FetchAdminCompanyRegionsRequest, FetchAdminCompanyRegionsSuccessPayload, FetchAdminCompanyRegionsSuccess, FetchAdminCompanyRegionsFailurePayload, FetchAdminCompanyRegionsFailure } from "../../types/type";



export const fetchAdminCompanyRegionsRequest = (): FetchAdminCompanyRegionsRequest => ({
  type: adminCompanyRegionsTypes.FETCH_ADMIN_COMPANY_REGIONS_REQUEST,
});

export const fetchAdminCompanyRegionsSuccess = (
  payload: FetchAdminCompanyRegionsSuccessPayload,
): FetchAdminCompanyRegionsSuccess => ({
  type: adminCompanyRegionsTypes.FETCH_ADMIN_COMPANY_REGIONS_SUCCESS,
  payload
});

export const fetchAdminCompanyRegionsFailure = (
  payload: FetchAdminCompanyRegionsFailurePayload
): FetchAdminCompanyRegionsFailure => ({
  type: adminCompanyRegionsTypes.FETCH_ADMIN_COMPANY_REGIONS_FAILURE,
  payload
});
