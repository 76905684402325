import { articlesImageTypes } from "../../ActionTypes/articlesImageTypes";
import { ArticlesImageActions, ArticlesImageState } from "../../types/type";

const initialState: ArticlesImageState = {
  pending: false,
  image: [],
  error: null,
};

export default (state = initialState, action: ArticlesImageActions,) => {
  switch (action.type) {
    case articlesImageTypes.FETCH_ARTICLES_IMAGE_REQUEST:
      return {
        ...state,
        pending: false
      };
    case articlesImageTypes.FETCH_ARTICLES_IMAGE_SUCCESS:
      return {
        ...state,
        pending: true,
        image: action.payload.image,
        error: null
      };
    case articlesImageTypes.FETCH_ARTICLES_IMAGE_FAILURE:
      return {
        ...state,
        pending: false,
        image: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
