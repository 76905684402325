import { applicationCompanyTypes } from "../../ActionTypes/applicationCompanyTypes";
import { FetchApplicationCompanyFailure, FetchApplicationCompanyFailurePayload, FetchApplicationCompanyRequest, FetchApplicationCompanySuccess, FetchApplicationCompanySuccessPayload } from "../../types/type";

export const fetchApplicationCompanyRequest = (company_id:any, page: any): FetchApplicationCompanyRequest => ({
  type: applicationCompanyTypes.FETCH_APPLICATION_COMPANY_REQUEST,
  company_id,
  page,
});

export const fetchApplicationCompanySuccess = (
  payload: FetchApplicationCompanySuccessPayload,
): FetchApplicationCompanySuccess => ({
  type: applicationCompanyTypes.FETCH_APPLICATION_COMPANY_SUCCESS,
  payload
});

export const fetchApplicationCompanyFailure = (
  payload: FetchApplicationCompanyFailurePayload
): FetchApplicationCompanyFailure => ({
  type: applicationCompanyTypes.FETCH_APPLICATION_COMPANY_FAILURE,
  payload
});
