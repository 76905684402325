
import { columnFetchItemTypes } from "../../ActionTypes/columnFetchItemTypes";
import { ColumnFetchItemActions, ColumnFetchItemState, ColumnFetchListActions, ColumnFetchListState } from "../../types/type";


const initialState: ColumnFetchItemState = {
  pending: false,
  columnItem: [],
  error: null,
};

export default (state = initialState, action: ColumnFetchItemActions,) => {
  switch (action.type) {
    case columnFetchItemTypes.FETCH_COLUMN_FETCH_ITEM_REQUEST:
      return {
        ...state,
        pending: false
      };
    case columnFetchItemTypes.FETCH_COLUMN_FETCH_ITEM_SUCCESS:
      return {
        ...state,
        pending: true,
        columnItem: action.payload.columnItem,
        error: null
      };
    case columnFetchItemTypes.FETCH_COLUMN_FETCH_ITEM_FAILURE:
      return {
        ...state,
        pending: false,
        columnItem: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
