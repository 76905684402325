import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { scoutsRecruitmentTypes } from "../../ActionTypes/scoutsRecruitmentTypes";
import { fetchScoutsRecruitmentFailure, fetchScoutsRecruitmentSuccess } from "../../actions/scoutsRecruitmentActions/scoutsRecruitmentActions";


var getScoutsRecruitment = () => axios.get(API_BASE_ADDRESS + '/scouts/recruitment' , {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 

function* fetchScoutsRecruitmentSaga() {
  try {
    const response: AxiosResponse = yield call(getScoutsRecruitment);
    yield put(
      fetchScoutsRecruitmentSuccess({
        scoutsRecruitment: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchScoutsRecruitmentFailure({
        error: e.message
      })
    );
  }
}

function* ScoutsRecruitmentSaga() {
  yield takeLatest(scoutsRecruitmentTypes.FETCH_SCOUTS_RECRUITMENT_REQUEST, fetchScoutsRecruitmentSaga);
}

export default ScoutsRecruitmentSaga;
