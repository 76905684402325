import { useState } from "react";


function Guide4() {

    
    return (
        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">4.応募管理・企業とのやり取りについて</h3>
          <p>この記事は約3分で読めます。</p>
    
          <p>このページでは応募管理・企業とのやり取りについて説明します。</p>
    
          <p>
            1.パラちゃんねる求人サイトTOP画面より
            <a href="/login/" style={{ display: 'inline', color: '#00a0e9' }}>ログイン／登録</a>
            をクリックします。
          </p>
          <img src="/assets/img/guide/first.png" alt=""/>
    
          <p>2.メールアドレス・パスワードを入力し、ログインをクリックします。<br/>
          （パスワードを忘れた場合）パスワードをお忘れの方をクリックしてください。</p>
          <img src="/assets/img/guide/04/02.png" alt=""/>
    
          <p>メールアドレスを入力して、再設定メール送信をクリックして下さい。</p>
          <img src="/assets/img/guide/04/03.png" alt=""/>
    
          <p>3.ログイン後、メニューの応募管理をクリックすると、<br/>
          今まで応募した求人やお気に入りの求人を確認することができます。<br/>
          また、マイページ上でも応募済みの求人が確認することができます。<br/>
          企業から選考日程の連絡が来た場合は、お知らせ画面で通知されます。</p>
          <img src="/assets/img/guide/04/04.png" alt=""/>
    
          <p>4.応募管理画面で、選考のやりとりを行うには、対象のエントリー中の求人をクリックして下さい。なお、パラちゃんねるでは前向きな就職活動を目指していくため仕様上、応募求人に関する返信が「10日間」以上経過した場合、自動で「終了済み求人」に変更されます。</p>
          <img src="/assets/img/guide/04/05.png" alt=""/>
    
          <p>5.メッセージ画面では、エントリー後、企業側から日程の打診があると日程の確定依頼が来ます。<br/>
          確定の場合は日程確定ボタン、代わりの日程を打診したい場合は代わりの日程を打診するをクリックし、日程を企業へ提示します。<br/>
          求人情報を見るボタンで求人を確認することも可能です。</p>
          <img src="/assets/img/guide/04/06.png" alt=""/>
    
          <p>日程が確定すると、メッセージ上で日程が送られてきます。</p>
          <img src="/assets/img/guide/04/07.png" alt=""/>
    
          <p>なお、当日の持ち物や交通手段、同席者の可否など選考についてなどを企業へ確認したい場合はメッセージ内容を入力し送信ボタンをクリックしてください。</p>
    
          <p>以上が応募管理・企業とのやり取りについてとなります。</p>
          <a href="/guide/" className="btn type3">一覧へ戻る</a>
        </section>
      </main>
       
    );
  }
  
  export default Guide4;