

import { initTypes } from "../../ActionTypes/initTypes";
import { regionsTypes } from "../../ActionTypes/regionsTypes";
import {
  FetchInitFailure,
  FetchInitFailurePayload,
  FetchInitRequest,
  FetchInitSuccess,
  FetchInitSuccessPayload,
  FetchRegionsFailure,
  FetchRegionsFailurePayload,
  FetchRegionsRequest,
  FetchRegionsSuccess,
  FetchRegionsSuccessPayload,
} from "../../types/type";

export const fetchInitRequest = (): FetchInitRequest => ({
  type: initTypes.FETCH_INIT_REQUEST,
});

export const fetchInitSuccess = (
  payload: FetchInitSuccessPayload,
): FetchInitSuccess => ({
  type: initTypes.FETCH_INIT_SUCCESS,
  payload
});

export const fetchInitFailure = (
  payload: FetchInitFailurePayload
): FetchInitFailure => ({
  type: initTypes.FETCH_INIT_FAILURE,
  payload
});
