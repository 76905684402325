import { useState } from "react";


function Guide7() {

    
    return (
        <main id="guide">
        <section className="detail wrapper">
          <h2 className="ttl type3">使い方ガイド</h2>
          <h3 className="ttl">7.企業アカウント登録について</h3>
          <p>この記事は約3分で読めます。</p>
    
          <p>このページではアカウント登録について説明します。</p>
    
          <p>1.TOPページ(<a href="/" style={{display: 'inline',color: '#00a0e9'}}>https://job.parachannel.jp/</a>) から画面右上の「採用担当者様はこちら」をクリックします。</p>
          <img src="/assets/img/guide/first.png" alt=""/>
    
          <p>2.「新規登録する」をクリックします。</p>
          <img src="/assets/img/guide/07/02.png" alt=""/>
    
          <p>3.メールアドレス・パスワードを設定してください。<br/>
          <span style={{color: '#F00'}}>パスワードは、8文字以上32文字以下で入力してください<br/>
          大文字と小文字を1文字以上含めた半角英数字で入力してください</span></p>
          <img src="/assets/img/guide/07/03.png" alt=""/>
    
          <p>4.仮メール登録のメールが送られます。</p>
          <img src="/assets/img/guide/07/04.png" alt=""/>
    
          <p>5.メール内のURLをクリックして本登録へお進みください。<br/>
          <span>※このURLの有効期限はご登録いただいてから「24時間以内」となります。<br/>
          ※有効期限を過ぎてしまった場合は、再度「新規会員登録」の手続きを行ってください。<br/>
          ※上記URLが２行に渡っている場合等、正しくページにアクセスできないことがあります。<br/>
          その場合は、URLをコピーして、ブラウザのアドレス画面に直接貼り付けアクセスしてください。<br/>
          ※本通知に覚えがない場合は、メールを破棄してください。<br/>
          このまま本登録を行わなければ、24時間経過後に仮登録内容は消去されます。</span></p>
          <img src="/assets/img/guide/07/05.png" alt=""/>
    
          <p>6.初回登録ページに遷移します。会社名や法人番号等を入力ください。</p>
          <img src="/assets/img/guide/07/06.png" alt=""/>
    
          <p>7.担当者情報を記入してください。</p>
          <img src="/assets/img/guide/07/07.png" alt=""/>
          <p>8.登録・求人掲載目的にチェックをつけ、<br/>
          利用規約とプライバシーポリシーの内容を確認し、同意してください。<br/>
          ここまでで初回登録ページは完了です。</p>
          <img src="/assets/img/guide/07/08.png" alt=""/>
    
          <p>9.続いて、会社概要の各情報を入力してください。<br/>
          （※ロゴ・社風・採用担当者情報は必須項目）<br/>
          保存ボタンを押すと入力した情報が、会社概要に反映されます。<br/>
          左メニューページで一度入力した情報を編集・追記・削除が可能です。</p>
          <img src="/assets/img/guide/07/09.png" alt=""/>
    
          <p>続いて、求人を投稿するには<a href="/guide/8/" style={{display: 'inline',color: '#00a0e9'}}>こちら</a>をお読みください。</p>
          <a href="/guide/" className="btn type3 nuxt-link-active">一覧へ戻る</a>
        </section>
      </main>
    );
  }
  
  export default Guide7;