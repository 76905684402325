import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './styles/style.scss';
import Header from './pages/main/header/header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './pages/main/footer/footer';
import { Provider } from "react-redux";
import store from './redax/store';
import Login from './pages/login/login';
import Recruitments from './pages/recruitments/recruitments';
import Indexs from './pages/index/index';
import About from './pages/about/about';
import Detail from './pages/recruitmentsDetail/detail';
import GuideIndex from './pages/guide';
import Guide1 from './pages/guide/1';
import Guide2 from './pages/guide/2';
import Guide3 from './pages/guide/3';
import Guide4 from './pages/guide/4';
import Guide5 from './pages/guide/5';
import Guide6 from './pages/guide/6';
import Guide7 from './pages/guide/7';
import Guide8 from './pages/guide/8';
import Guide9 from './pages/guide/9';
import Guide10 from './pages/guide/10';
import Guide11 from './pages/guide/11';
import Guide12 from './pages/guide/12';
import Guide13 from './pages/guide/13';
import Guide14 from './pages/guide/14';
import Terms1 from './pages/terms/terms1';
import Terms2 from './pages/terms/terms2';
import Privacy from './pages/privacy/privacy';
import Contact from './pages/contact/contact';
import RecuiterLogin from './pages/recruiter/login/login';
import RecruiterSignUp from './pages/recruiter/sign-up/signup';
import SignUp from './pages/signup/signup';
import Companies from './pages/companies/companies';
import MypageApplications from './pages/mypage/applications/applications';
import ScoutsPage from './pages/mypage/scouts/scouts';
import MypageIndex from './pages/mypage/index';
import ProfilePage from './pages/mypage/profile/profile';
import ProfilePage2 from './pages/mypage/profile/profile2';
import ProfilePage3 from './pages/mypage/profile/profile3';
import ProfilePage4 from './pages/mypage/profile/profile4';
import ProfilePage5 from './pages/mypage/profile/profile5';
import ProfilePage6 from './pages/mypage/profile/profile6';
import ProfilePage7 from './pages/mypage/profile/profile7';
import MypageApplicationsDetail from './pages/mypage/applicationsDetail/applicationsDetail';
import Settings from './pages/mypage/settings/settings';
import RecruiterIndex from './pages/recruiter/index';
import RecruiterCompany from './pages/recruiter/company/company';
import Init from './pages/mypage/init/init';
import RecruiterSchedules from './pages/recruiter/schedules/schedules';
import Schedules from './pages/mypage/schedules/schedules';
import Password from './pages/password/password';
import RecruiterPassword from './pages/recruiter/password/password';
import ColumnIndex from './pages/recruiter/columns/index';
import ColumnCreate from './pages/recruiter/columns/create/create';
import Reset from './pages/password/reset';
import RecruiterCompanyMedia from './pages/recruiter/columns/media/media';
import RecruiterRecruitments from './pages/recruiter/recruitments/recruitments';
import RecruiterRecruitmentsCreate from './pages/recruiter/recruitments/create';
import Preview from './pages/recruiter/preview/preview';
import SignUpComplete from './pages/signup/complete';
import RecruiterSignUpComplete from './pages/recruiter/sign-up/complete';
import Columns from './pages/columns/columns';
import RecruiterInit from './pages/recruiter/init/init';
import RecruiterApplications from './pages/recruiter/applications/applications';
import ScoutsIndex from './pages/recruiter/scouts/scouts';
import CandidateIndex from './pages/recruiter/scouts/candidate/candidate';
import Invitation from './pages/recruiter/invitaion/invitation';
import RecruiterRecruitmentsCreateStep_1 from './pages/recruiter/recruitments/create/step';
import ColumnEdit from './pages/recruiter/columns/edit/edit';
import RecruimentsEntry from './pages/recruitmentsEntry/recruitmentsentry';
import RecruimentsConfirm from './pages/recruitmentsEntry/confirm';
import RecruiterApplicationsDetail from './pages/recruiter/applications/applicationsDetail/applicationsDetail';
import HistoryIndex from './pages/recruiter/scouts/history/history';
import RecruiterRecruitmentsCreateStep_2 from './pages/recruiter/recruitments/create/step_2';
import RecruiterSettings from './pages/recruiter/settings/settings';
import RecruitmentsDetail from './pages/recruiter/recruitments/detail/detail';
import Messages from './pages/recruiter/applications/messages/messages';
import ScoutsApplicant from './pages/recruiter/scouts/applicant/applicant';
import SavedCondions from './pages/main/savedcondtions/savedConditions';
import IdPage from './pages/mypage/scouts/company/id';
import RecruiterPasswordReset from './pages/password/reset';
import RecruimentsComplete from './pages/recruitmentsEntry/complete';
import ErrorPage from './pages/error/error';
import HistoryDetail from './pages/recruiter/scouts/historyDetail/historyDetail';
import ScoutSavedCondions from './pages/recruiter/scouts/saved_conditions/saved_conditions';
import ScoutEntry from './pages/recruiter/scouts/entry/entry';
import ScoutComplete from './pages/recruiter/scouts/complete/complete';
import ScoutAlert from './pages/recruiter/scouts/alert/alert';
import ScoutConfirm from './pages/recruiter/scouts/confirm/confirm';
import Recruiter from './pages/recruiter_lp/recruiter_lp';
import Matome from './pages/matome/matome';
import MatomeDetail from './pages/matomeDetail/MatomeDetail';
import AdminTransfer from './pages/adminTop/AdminTransfer';
import AdminScouts from './pages/adminTop/AdminScouts';
import AdminLogin from './pages/admin/AdminLogin';
import AdminTop from './pages/adminTop/AdminHome';
import AdminScoutApplicants from './pages/adminTop/AdminScoutApplicants';
import AdminScoutJobs from './pages/adminTop/AdminScoutJobs';
import Registration from './pages/signup/Registration';
import Regsiter from './pages/recruiter/sign-up/Regsiter';
import NewPage1 from './pages/recruiter/invoice/paymentlist';
import NewPage2 from './pages/recruiter/invoiceDetail/paymentdetail';
import AdminInvoiceCheck from './pages/adminInvoiceCheck/adminInvoiceCheck';
import AdminCompanyList from './pages/AdminCompany/AdminCompanyList';
import AdminCompanyDetail from './pages/AdminCompany/AdminCompanyDetail';
import Paymentdetail from './pages/recruiter/invoiceDetail/paymentdetail';
import Plan from './pages/recruiter/plan/plan';
import Plandetail from './pages/recruiter/plan/plandetail';
import PaymentList from './pages/recruiter/invoice/paymentlist';
import Planupdate from './pages/recruiter/plan/planPricing';
import NewPage from './pages/recruiter/recruitmentsJobs';
import RecruiterPlanConfirm from './pages/recruiter/reruiterPlanPaymentConfirm/recruiterPlanConfirm';
import RecruiterPlanPaymentConfirm from './pages/recruiter/reruiterPlanPaymentConfirm/recruiterPlanConfirm';
import ChangedPlan from './pages/recruiter/planChangeCompleted/ChangedPlan';
import ChangedAddress from './pages/recruiter/planChangeAddress/ChangeAddress';
import ChangeAddress from './pages/recruiter/planChangeAddress/ChangeAddress';
import EmailChange from './pages/mypage/settings/emailChange';
import AdminOpenJobList from './pages/adminTop/AdminOpenJobList/AdminOpenJobList';
import AdminApplications from './pages/adminTop/AdminApplications';
import AdminNews from './pages/adminTop/AdminNews/AdminNews';
import AdminScoutList from './pages/adminTop/AdminScoutList/AdminScoutList';
import AdminAdministratorList from './pages/adminTop/AdminAdministratorList/AdminAdministratorList';
import AdminApplicantsList from './pages/adminTop/AdminApplicants/AdminApplicantsList';
import AdminAdministratorNew from './pages/adminTop/AdminAdministratorList/AdminAdministratorNew';
import AdminAdministratorDetail from './pages/adminTop/AdminAdministratorList/AdminAdministratorDetail';
import AdminScoutListDetail from './pages/adminTop/AdminScoutList/AdminScoutListDetail';
import AdminNewsNew from './pages/adminTop/AdminNews/AdminNewsNew';
import AdminOpenJobNew from './pages/adminTop/AdminOpenJobList/AdminOpenJobNew';
import AdminOpenJobDetail from './pages/adminTop/AdminOpenJobList/AdminOpenJobDetail';
import AdminOpenJobEdit from './pages/adminTop/AdminOpenJobList/AdminOpenJobEdit';
import AdminApplicantsNew from './pages/adminTop/AdminApplicants/AdminApplicantsNew';
import AdminApplicantsDetail from './pages/adminTop/AdminApplicants/AdminApplicantsDetail';
import AdminApplicantsEdit from './pages/adminTop/AdminApplicants/AdminApplicantsEdit';
import AdminCompanyRecuiter from './pages/AdminCompany/Recuiter/recuiter';
import AdminCompanyRecuiterEdit from './pages/AdminCompany/Recuiter/recruiterEdit';
import AdminCompanyNew from './pages/AdminCompany/AdminCompanyNew';
import AdminCompanyEdit from './pages/AdminCompany/AdminCompanyEdit';
import AdminHome from './pages/adminHome/adminHome';
import AdminRecommendJobs from './pages/adminTop/AdminRecommendJobs';
import AdminRecommendApplicants from './pages/adminTop/AdminRecommendApplicants';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <div>
        {!window.location.href.includes('recruiter_lp') && !window.location.href.includes('matome') && !window.location.href.includes('admin') ? 
          <p className="head_contact_link"><a href="/contact/">ご利用や操作方法に関してお困りの方へ</a></p>
        : <></>}
        {!window.location.href.includes('recruiter_lp') && !window.location.href.includes('matome') && !window.location.href.includes('admin') ?
        <Header />
        : <></>}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Indexs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/recruitments" element={<Recruitments />} />
            <Route path="/about" element={<About />} />
            <Route path="/recruitments/:id" element={<Detail />} />
            <Route path="/guide/" element={<GuideIndex />} />
            <Route path="/guide/1/" element={<Guide1 />} />
            <Route path="/guide/2/" element={<Guide2 />} />
            <Route path="/guide/3/" element={<Guide3 />} />
            <Route path="/guide/4/" element={<Guide4 />} />
            <Route path="/guide/5/" element={<Guide5 />} />
            <Route path="/guide/6/" element={<Guide6 />} />
            <Route path="/guide/7/" element={<Guide7 />} />
            <Route path="/guide/8/" element={<Guide8 />} />
            <Route path="/guide/9/" element={<Guide9 />} />
            <Route path="/guide/10/" element={<Guide10 />} />
            <Route path="/guide/11/" element={<Guide11 />} />
            <Route path="/guide/12/" element={<Guide12 />} />
            <Route path="/guide/13/" element={<Guide13 />} />
            <Route path="/guide/14/" element={<Guide14 />} />
            <Route path="/terms1/" element={<Terms1 />} />
            <Route path="/terms2/" element={<Terms2 />} />
            <Route path="/privacy/" element={<Privacy />} />
            <Route path="/contact/" element={<Contact />} />
            <Route path="/recruiter_lp/" element={<Recruiter />} />
            <Route path="/recruiter/login/" element={<RecuiterLogin />} />
            <Route path="/signup/" element={<SignUp />} />
            <Route path="/signup/registration" element={<Registration />} />
            <Route path="/signup/complete" element={<SignUpComplete />} />
            <Route path="/recruiter/signup/" element={<RecruiterSignUp />} />
            <Route path="/recruiter/signup/registration" element={<Regsiter />} />
            <Route path="/recruiter/signup/complete" element={<RecruiterSignUpComplete />} />
            <Route path="/mypage/" element={<MypageIndex />} />
            <Route path="/companies/:id" element={<Companies />} />
            <Route path="/columns/:id" element={<Columns />} />
            <Route path="/mypage/applications" element={<MypageApplications />} />
            <Route path="/mypage/applications/:id" element={<MypageApplicationsDetail />} />
            <Route path="/mypage/scouts" element={<ScoutsPage />} />
            <Route path="/mypage/scouts/company/:id" element={<IdPage />} />
            <Route path="/mypage/profile" element={<ProfilePage />} />
            <Route path="/mypage/profile2" element={<ProfilePage2 />} />
            <Route path="/mypage/profile3" element={<ProfilePage3 />} />
            <Route path="/mypage/profile4" element={<ProfilePage4 />} />
            <Route path="/mypage/profile5" element={<ProfilePage5 />} />
            <Route path="/mypage/profile6" element={<ProfilePage6 />} />
            <Route path="/mypage/profile7" element={<ProfilePage7 />} />
            <Route path="/mypage/schedules" element={<Schedules />} />
            <Route path="/mypage/settings/" element={<Settings />} />
            <Route path="/mypage/settings/email_change/" element={<EmailChange />} />
            <Route path="/recruiter/" element={<RecruiterIndex />} />
            <Route path="/recruiter/company" element={<RecruiterCompany />} />
            <Route path="/mypage/init" element={<Init />} />
            <Route path="/recruiter/schedules/" element={<RecruiterSchedules />} />
            <Route path="/password" element={<Password/>} />
            <Route path="/password/reset" element={<Reset/>} />
            <Route path="/recruiter/password/" element={<RecruiterPassword/>} />
            <Route path="/recruiter/password/reset" element={<RecruiterPasswordReset/>} />
            <Route path="/recruiter/columns/" element={<ColumnIndex />} />
            <Route path="/recruiter/columns/create/" element={<ColumnCreate />} />
            <Route path="/recruiter/columns/edit/:id" element={<ColumnEdit />} />
            <Route path="/recruiter/media/" element={<RecruiterCompanyMedia/>} />
            <Route path="/recruiter/columns/media/" element={<RecruiterCompanyMedia/>} />
            <Route path="/recruiter/recruitments/" element={<RecruiterRecruitments/>} />
            <Route path="/recruiter/recruitments/create/" element={<RecruiterRecruitmentsCreate/>} />
            <Route path="/recruiter/recruitments/create/step_1" element={<RecruiterRecruitmentsCreateStep_1/>} />
            <Route path="/recruiter/recruitments/create/step_2" element={<RecruiterRecruitmentsCreateStep_2/>} />
            <Route path="/recruiter/preview/" element={<Preview />} />
            <Route path="/recruiter/init/" element={<RecruiterInit/>} />
            <Route path="/recruiter/applications/" element={<RecruiterApplications/>} />            
            <Route path="/recruiter/applications/:id" element={<RecruiterApplicationsDetail/>} />
            <Route path="/recruiter/applications/:id/messages" element={<Messages/>} />
            <Route path="/recruiter/scouts/" element={<ScoutsIndex/>} />
            <Route path="/recruiter/scouts/candidate/" element={<CandidateIndex/>} />
            <Route path="/recruiter/scouts/saved_conditions/" element={<ScoutSavedCondions/>} />
            <Route path="/recruiter/scouts/entry/" element={<ScoutEntry/>} />
            <Route path="/recruiter/scouts/confirm/" element={<ScoutConfirm/>} />
            <Route path="/recruiter/scouts/complete/" element={<ScoutComplete/>} />
            <Route path="/recruiter/scouts/alert/" element={<ScoutAlert/>} />
            <Route path="/recruiter/invitation/signup" element={<Invitation/>} />
            <Route path="/recruitments/:id/entry/" element={<RecruimentsEntry/>} />
            <Route path="/recruitments/:id/entry/confirm" element={<RecruimentsConfirm/>} />
            <Route path="/recruitments/:id/entry/complete" element={<RecruimentsComplete/>} />
            <Route path="/recruiter/scouts/history/" element={<HistoryIndex/>} />
            <Route path="/recruiter/scouts/history/:id" element={<HistoryDetail/>} />
            <Route path="/recruiter/settings/" element={<RecruiterSettings/>} />
            <Route path="/recruiter/recruitments/:id" element={<RecruitmentsDetail/>} />
            <Route path="/recruiter/scouts/applicant/:id" element={<ScoutsApplicant/>} />
            <Route path="/saved_conditions/" element={<SavedCondions/>} />
            <Route path="/matome/" element={<Matome/>} />
            <Route path="/matome/detail/:id" element={<MatomeDetail/>} />
            <Route path="/admin/login" element={<AdminLogin/>} />
            <Route path="/admin/" element={<AdminTop/>} />
            <Route path="/admin/home" element={<AdminHome/>} />
            <Route path="/admin/scout_matchings/jobs" element={<AdminScoutJobs/>} />
            <Route path="/admin/recommend_matchings/jobs" element={<AdminRecommendJobs/>} />
            <Route path="/admin/recommend_matchings/applicants" element={<AdminRecommendApplicants/>} />
            <Route path="/admin/scout_matchings/applicants" element={<AdminScoutApplicants/>} />
            <Route path="/admin/scouts" element={<AdminScouts/>} />
            <Route path="/admin/transfer" element={<AdminTransfer/>} />
            <Route path="/recruiter/plan" element={<Plan/>} />
            <Route path="/recruiter/planpricing/" element={<Planupdate/>} />
            <Route path="/recruiter/plandetail/" element={<Plandetail/>} />
            <Route path="/recruiter/invoice" element={<PaymentList/>} />
            <Route path="/recruiter/invoice/:id" element={<Paymentdetail/>} />
            <Route path="/admin/invoice_check" element={<AdminInvoiceCheck/>} />
            <Route path="/admin/companies" element={<AdminCompanyList/>} />
            <Route path="/admin/openjob_list" element={<AdminOpenJobList/>} />
            <Route path="/admin/openjob_list/new" element={<AdminOpenJobNew/>} />
            <Route path="/admin/openjob_list/:id" element={<AdminOpenJobDetail/>} />
            <Route path="/admin/openjob_list/:id/edit" element={<AdminOpenJobEdit/>} />
            <Route path="/admin/applicants_list" element={<AdminApplicantsList/>} />
            <Route path="/admin/applicants_list/new" element={<AdminApplicantsNew/>} />
            <Route path="/admin/applicants_list/:id" element={<AdminApplicantsDetail/>} />
            <Route path="/admin/applicants_list/:id/edit" element={<AdminApplicantsEdit/>} />
            <Route path="/admin/applications" element={<AdminApplications/>} />
            <Route path="/admin/news" element={<AdminNews/>} />
            <Route path="/admin/news/new" element={<AdminNewsNew/>} />
            <Route path="/admin/scout_list" element={<AdminScoutList/>} />
            <Route path="/admin/scout_list/:id" element={<AdminScoutListDetail/>} />
            <Route path="/admin/administrators" element={<AdminAdministratorList/>} />
            <Route path="/admin/administrator_new" element={<AdminAdministratorNew/>} />
            <Route path="/admin/administrators/:id" element={<AdminAdministratorDetail/>} />
            <Route path="/admin/companies/:id" element={<AdminCompanyDetail/>} />
            <Route path="/admin/companies/:id/edit" element={<AdminCompanyEdit/>} />
            <Route path="/admin/companies/new" element={<AdminCompanyNew/>} />
            <Route path="/admin/companies/:id/recruiter" element={<AdminCompanyRecuiter/>} />
            <Route path="/admin/companies/:id/recruiter/:recruiterid" element={<AdminCompanyRecuiterEdit/>} />
            <Route path="jobs/recommended" element={<NewPage/>} />
            <Route path="recruiter/paymentconfirmation" element={<RecruiterPlanPaymentConfirm/>} />
            <Route path="recruiter/changedplan" element={<ChangedPlan/>} />
            <Route path="recruiter/changeaddress" element={<ChangeAddress/>} />
            <Route path="*" element={<ErrorPage/>} />
          </Routes>
        </BrowserRouter>
        {!window.location.href.includes('recruiter_lp') && !window.location.href.includes('matome') && !window.location.href.includes('admin')  ?
        <Footer />
        : <></>}
      </div>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
