import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { scoutsSavedConditionsTypes } from "../../ActionTypes/scoutsSavedConditionsTypes";
import { fetchScoutsSavedConditionsFailure, fetchScoutsSavedConditionsSuccess } from "../../actions/scoutsSavedConditionsActions/scoutsSavedConditionsActions";
import { API_BASE_ADDRESS } from "../../API";


var getScoutsSavedConditions = (id: any, name:any) => {
  if(name == 'get') {
    return axios.get(API_BASE_ADDRESS + '/scout_saved_conditions/', {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 
  } else if(name == 'delete') {
    return axios.delete(API_BASE_ADDRESS + '/scout_saved_conditions/' + id, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 
  } else if(name == 'post') {
    return axios.post(API_BASE_ADDRESS + '/scout_saved_conditions/', id, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 
  }
}

function* fetchScoutsSavedConditionsSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getScoutsSavedConditions, action.id, action.name);
    yield put(
      fetchScoutsSavedConditionsSuccess({
        saved_conditions: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchScoutsSavedConditionsFailure({
        error: e.message
      })
    );
  }
}

function* ScoutsSavedConditionsSaga() {
  yield takeLatest(scoutsSavedConditionsTypes.FETCH_SCOUTS_SAVED_CONDITIONS_REQUEST, fetchScoutsSavedConditionsSaga);
}

export default ScoutsSavedConditionsSaga;
