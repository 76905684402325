import { savedConditionsTypes } from "../../ActionTypes/savedConditionsTypes";
import { FetchSavedConditionsFailure, FetchSavedConditionsFailurePayload, FetchSavedConditionsRequest, FetchSavedConditionsSuccess, FetchSavedConditionsSuccessPayload } from "../../types/type";



export const fetchSavedConditionsRequest = (queryParams:any): FetchSavedConditionsRequest => ({
  type: savedConditionsTypes.FETCH_SAVED_CONDITIONS_REQUEST,
  queryParams
});

export const fetchSavedConditionsSuccess = (
  payload: FetchSavedConditionsSuccessPayload,
): FetchSavedConditionsSuccess => ({
  type: savedConditionsTypes.FETCH_SAVED_CONDITIONS_SUCCESS,
  payload
});

export const fetchSavedConditionsFailure = (
  payload: FetchSavedConditionsFailurePayload
): FetchSavedConditionsFailure => ({
  type: savedConditionsTypes.FETCH_SAVED_CONDITIONS_FAILURE,
  payload
});
