import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchAdminInvoicePostFailure, fetchAdminInvoicePostSuccess } from "../../actions/adminInvoicePostActions/adminInvoicePostActions";
import { adminInvoicePostTypes } from "../../ActionTypes/adminInvoicePostTypes";

const getAdminInvoicePost = (data: any,) => { 
    return axios.post(API_BASE_ADDRESS + '/admin/invoices', data, {
      headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }
    });
  
}

function* fetchAdminInvoicePostSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminInvoicePost, action.data);
    yield put(
      fetchAdminInvoicePostSuccess({
        adminInvoices: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminInvoicePostFailure({
        error: e.message
      })
    );
  }
}

function* AdminInvoicePostSaga() {
  yield takeLatest(adminInvoicePostTypes.FETCH_ADMIN_INVOICE_POST_REQUEST, fetchAdminInvoicePostSaga);
}
export default AdminInvoicePostSaga;