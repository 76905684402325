import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminApplicantsRegionsTypes } from "../../ActionTypes/adminApplicantsRegionsTypes";
import { fetchAdminApplicantsRegionsFailure, fetchAdminApplicantsRegionsSuccess } from "../../actions/adminApplicantsRegionsActions/adminApplicantsRegionsActions";

  var getAdminApplicantsRegions = () => axios.get(API_BASE_ADDRESS + '/master?regions=true&applicants=true&industries=true&job_categories=true&appeal_items=true&oa_skills=true&qualifications=true&languages=true&handicaps=true&handicap_types=true&consideration_conditions=true&work_styles=true&recruitments=true&work_from_home=true' , {headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }}); 


function* fetchAdminApplicantsRegionsSaga() {
  try {
    const response: AxiosResponse = yield call(getAdminApplicantsRegions);
    yield put(
      fetchAdminApplicantsRegionsSuccess({
        regions: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminApplicantsRegionsFailure({
        error: e.message
      })
    );
  }
}

function* AdminApplicantsRegionsSaga() {
  yield takeLatest(adminApplicantsRegionsTypes.FETCH_ADMIN_APPLICANTS_REGIONS_REQUEST, fetchAdminApplicantsRegionsSaga);
}

export default AdminApplicantsRegionsSaga;
