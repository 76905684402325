
import { adminAdministratorsTypes } from "../../ActionTypes/adminAdministratorsTypes";
import { adminScoutsCompanyTypes } from "../../ActionTypes/adminScoutsCompanyTypes";
import { AdminAdministratorsActions, AdminAdministratorsState, AdminScoutsCompanyActions, AdminScoutsCompanyState } from "../../types/type";

const initialState: AdminAdministratorsState = {
  pending: false,
  administrators: [],
  total_count: '',
  error: null,
};

export default (state = initialState, action: AdminAdministratorsActions,) => {
  switch (action.type) {
    case adminAdministratorsTypes.FETCH_ADMIN_ADMINISTRATORS_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminAdministratorsTypes.FETCH_ADMIN_ADMINISTRATORS_SUCCESS:
      return {
        ...state,
        pending: true,
        administrators: action.payload.administrators,
        total_count: action.payload.total_count,
        error: null
      };
    case adminAdministratorsTypes.FETCH_ADMIN_ADMINISTRATORS_FAILURE:
      return {
        ...state,
        pending: false,
        administrators: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
