import { adminApplicantsListTypes } from "../../ActionTypes/adminApplicantsListTypes";
import { adminRecruitmentListTypes } from "../../ActionTypes/adminRecruitmentListTypes";
import {
  FetchAdminApplicantsListRequest,
  FetchAdminApplicantsListSuccessPayload,
  FetchAdminApplicantsListSuccess,
  FetchAdminApplicantsListFailurePayload,
  FetchAdminApplicantsListFailure
} from "../../types/type";

export const fetchAdminApplicantsListRequest = (data: any): FetchAdminApplicantsListRequest => ({
  type: adminApplicantsListTypes.FETCH_ADMIN_APPLICANTS_LIST_REQUEST,
  data
});

export const fetchAdminApplicantsListSuccess = (
  payload: FetchAdminApplicantsListSuccessPayload
): FetchAdminApplicantsListSuccess => ({
  type: adminApplicantsListTypes.FETCH_ADMIN_APPLICANTS_LIST_SUCCESS,
  payload
});

export const fetchAdminApplicantsListFailure = (
  payload: FetchAdminApplicantsListFailurePayload
): FetchAdminApplicantsListFailure => ({
  type: adminApplicantsListTypes.FETCH_ADMIN_APPLICANTS_LIST_FAILURE,
  payload
});