import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import { RootState } from "../../../../redax/reducers/rootReducer";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import { articlesImagesRequest } from "../../../../redax/actions/articlesImagesActions/articlesImagesActions";
import { articlesImageFailure, articlesImageRequest } from '../../../../redax/actions/articlesImageActions/articlesImageActions';
import Loader from "../../../loader/loader";
import './media.scss';

export default function RecruiterCompanyMedia() {
    const dispatch = useDispatch();
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    // const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] =  useState<any>(null);
    const [authenticate, setAuthenticate] = useState(null);
    const [selectedImagePath, setSelectedImagePath] = useState(null);
    const [images, setImages] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [drag, setDrag] = useState(false);
    const [del, setDel] = useState(false);
    const navigate = useNavigate();
    const articlesImage = useSelector((state: RootState) => state.articlesImage);
    const [loadScreen, setLoadScreen] = useState(true);
    
    const breadCrumbs = [
      {
        label: 'トップ',
        path: '/',
      },
      {
        label: '企業様マイページ',
        path: '/recruiter/',
      },
      {
        label: 'メディア',
        path: '#',
      },
    ];

    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
          // dispatch(fetchLoginRequest('', '',''))
        } else (
            navigate('/recruiter/login')
        )
      }, [dispatch]);


      useEffect(() => {
        dispatch(articlesImagesRequest(page))
      }, [dispatch, page]);
      
      const loginData = useSelector((state: RootState) => state.login);
      const articlesImages = useSelector((state: RootState) => state.articlesImages);


      useEffect(() => {
        if (loginData.pending) {
          if(!loginData.login.is_init_complete) {
            navigate('/recruiter/init/');
          }
          setResourceId(loginData.login.resource.id);
          setFamylyName(loginData.login.resource.family_name);
          setAuthenticate(loginData.login.authenticate.resource_type);
        }
      }, [loginData]);

      useEffect(() => {
        if (articlesImages.pending) {
          setLoadScreen(false);
          setImages(articlesImages.images.images);
          setTotalPage(articlesImages.images.pagination.total_pages);
        } else if(articlesImages.error != null) {
          setLoadScreen(false);
          navigate('/error?code=500');
        }
      }, [articlesImages]);

      useEffect(() => {
        if(articlesImage.pending && del) {
          dispatch(articlesImagesRequest(page));
          setDel(false);
        } else if(articlesImage.error != null) {
          setLoadScreen(false);
          navigate('/error?code=500');
        }
      }, [dispatch, articlesImage, del]);

      function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
        }
      }

      function getUrlParam( paramName: any ) {
        var reParam = new RegExp( '(?:[\?&]|&)' + paramName + '=([^&]+)', 'i' );
        var match = window.location.search.match( reParam );

        return ( match && match.length > 1 ) ? match[1] : null;
      }

      function returnFileUrl (filePath: any) {
        var funcNum = getUrlParam( 'CKEditorFuncNum' );
        window.opener.CKEDITOR.tools.callFunction( funcNum, filePath );
        window.close();
      }

      function onDrop(e: any) {
        setDrag(false);
        if (!e || !e.dataTransfer || e.dataTransfer.files.length === 0) return false;  
        var reader = new FileReader();
        reader.onload = function read(event: any) {
          dispatch(articlesImageRequest(event.target.result, 'post', 0));
        };
        reader.readAsDataURL(e.dataTransfer.files[0]);
      }

      function deleteAlert(id: any) {
        if (window.confirm("以下の画像を削除しますか？")) {
          setDel(true);
          setLoadScreen(true);
          dispatch(articlesImageRequest(null, 'delete', id));
        }
      }

      const pageIncrement = () => {
        if (page >= totalPage) {
          setPage(totalPage);
          return;
        }
        setPage((prevPage) => prevPage + 1);
        setLoadScreen(true);
        dispatch(articlesImagesRequest(page))

      };
    
      const pageDecrement = () => {
        if (page <= 1) {
          setPage(1);
          return;
        }
        setPage((prevPage) => prevPage - 1);
        setLoadScreen(true);
        dispatch(articlesImagesRequest(page))
      };
    
      const updatePage = (p: any) => {
        setPage(p);
        setLoadScreen(true);
        dispatch(articlesImagesRequest(page))
      };


      const onSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        
        const files = e.target.files;
        if (!files || files.length === 0) return;
    
        var reader = new FileReader();
        reader.onload = function read(event: any) {
          setLoadScreen(true);
          dispatch(articlesImageRequest(event.target.result, 'post', 0));
        };
        reader.readAsDataURL(files[0]);
        
      };

    function selectImage (i: any)  {
      const updatedImages = images.map((image:any, index: any) => {
        if (index === i) {
          setSelectedImagePath(image.path);
          return { ...image, selected: true };
        } else {
          return { ...image, selected: false };
        }
      });
      setImages(updatedImages);
    }

    return (
        <main id="company" className="media">
          {window.location.pathname.includes('/recruiter/media') ?
            <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
            : <></>}
            {loadScreen ? <Loader /> : <></>}
            <section>
            {window.location.pathname.includes('/recruiter/media') ?
              <>
              <ul className="bread_crumb">
                  {breadCrumbs.map((breadCrumb, i) => (
                    <li key={i}>
                      <a href={breadCrumb.path}>{ breadCrumb.label }</a>
                    </li>
                  ))}
              </ul>
              <div className="ttl_box">
                  <h2 className="ttl">メディア</h2>
                  <ul>
                      <li>
                          <a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt="" />ログアウト</a>
                      </li>
                  </ul>
              </div>
              </>
              : 
              <>
              <h4 className="ckeditor-title">画像リスト</h4>
              </>}
              <div className="media_uploader">
                <form action="./upload.php" method="post">
                  <div id="drop-zone" onDragOver={() => setDrag(true)} onDragLeave={() => setDrag(false)} onDrop={(e) => onDrop(e)}>
                    {!drag ?
                      <p>ファイルをドラッグ＆ドロップ<br /><span>または</span></p>
                      :
                      <p>ドラッグ中</p>}
                      <input
                        type="file"
                        name="file"
                        id="file-input"
                        onChange={onSelect}
                      />
                    </div>
                </form>
              </div>
              <div className="medias">
                {images.length > 0 ?
                <ul className="list_box">
                  {images.map((image: any, i: any) => (
                    <>
                    {window.location.pathname.includes('/recruiter/media') ?
                    <li key={i}>                      
                      <img src={image.path} alt="" className="thumb" />
                      <span onClick={() => deleteAlert(image.id)}><img src="/assets/img/company/icon05.svg" alt="削除"/></span>
                    </li>
                    :
                    <li key={i} onClick={(e) =>selectImage(i)} className={image.selected ? 'active' : ''}>                      
                      <img src={image.path} alt="" className="thumb" />
                      <span onClick={() => deleteAlert(image.id)}><img src="/assets/img/company/icon05.svg" alt="削除"/></span>
                    </li>}
                    </>
                  ))}
                </ul>
                : <></>}
                     <ul className="pagination_circle">
                      <li className={`prev ${page <= 1 ? 'disabled' : ''}`}>
                        <a href="#" onClick={pageDecrement}>前へ</a>
                      </li>
                      {Array.from({ length: totalPage }, (_, i) => i + 1).map((p) => (
                        <li key={p} className={`page ${page === p ? 'active' : ''}`}>
                          <a href="#" onClick={() => updatePage(p)}>
                            <span>{p}</span>
                          </a>
                        </li>
                      ))}
                      <li className={`next ${page >= totalPage ? 'disabled' : ''}`}>
                        <a href="#" onClick={pageIncrement}>次へ</a>
                      </li>
                    </ul>
                    {images.length > 0 && window.location.pathname.includes('/recruiter/columns/media') ?  <button onClick={(e) =>returnFileUrl(selectedImagePath)}> 決定</button> : <></>}
              </div>
            </section>
        </main>
    )
}
