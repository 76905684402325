import { favCountTypes } from "../../ActionTypes/favCountTypes";
import { FetchFavCountFailure, FetchFavCountSuccess, FetchFavCountSuccessPayload, FetchFavCountFailurePayload, FetchFavCountRequest } from "../../types/type";


export const fetchFavCountRequest = (id:any, types: any, name: any): FetchFavCountRequest => ({
  type: favCountTypes.FETCH_FAV_COUNT_REQUEST,
  id,
  types,
  name
});

export const fetchFavCountSuccess = (
  payload: FetchFavCountSuccessPayload,
): FetchFavCountSuccess => ({
  type: favCountTypes.FETCH_FAV_COUNT_SUCCESS,
  payload
});

export const fetchFavCountFailure = (
  payload: FetchFavCountFailurePayload
): FetchFavCountFailure => ({
  type: favCountTypes.FETCH_FAV_COUNT_FAILURE,
  payload
});
