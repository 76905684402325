import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { interviewsTypes } from "../../ActionTypes/interviewsTypes";
import { fetchInterviewsFailure, fetchInterviewsSuccess } from "../../actions/interviewsActions/interviewsActions";
import { API_BASE_ADDRESS } from "../../API";


var getInterviews = (queryParams: any, type: any) => {
  if(type == 'get') {
    return axios.get( API_BASE_ADDRESS  + '/interviews?' +  queryParams, {headers: {Authorization: localStorage.getItem('jobParachannelAuthToken'),  Accept: 'application/json', Prefer: 'example=test-1',}}); 
  } else {
    return axios.post( API_BASE_ADDRESS  + '/interviews', queryParams, {headers: {Authorization: localStorage.getItem('jobParachannelAuthToken'),  Accept: 'application/json', Prefer: 'example=test-1',}}); 
  }
}

function* fetchInterviewsSaga(action:any) {
  try {
    const response: AxiosResponse = yield call(getInterviews, action.queryParams, action.types);
    yield put(
      fetchInterviewsSuccess({
        interviews: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchInterviewsFailure({
        error: e.message
      })
    );
  }
}

function* InterviewsSaga() {
  yield takeLatest(interviewsTypes.FETCH_INTERVIEWS_REQUEST, fetchInterviewsSaga);
}

export default InterviewsSaga;
