import { columnFetchItemTypes } from "../../ActionTypes/columnFetchItemTypes";
import { ColumnFetchItemFailure, ColumnFetchItemFailurePayload, ColumnFetchItemRequest, ColumnFetchItemSuccess, ColumnFetchItemSuccessPayload} from "../../types/type";

export const columnFetchItemRequest = (paramsId:any): ColumnFetchItemRequest => ({
  type: columnFetchItemTypes.FETCH_COLUMN_FETCH_ITEM_REQUEST,
  paramsId,
});

export const columnFetchItemSuccess = (
  payload: ColumnFetchItemSuccessPayload,
): ColumnFetchItemSuccess => ({
  type: columnFetchItemTypes.FETCH_COLUMN_FETCH_ITEM_SUCCESS,
  payload
});

export const columnFetchItemFailure = (
  payload: ColumnFetchItemFailurePayload
): ColumnFetchItemFailure => ({
  type: columnFetchItemTypes.FETCH_COLUMN_FETCH_ITEM_FAILURE,
  payload
});
