import { recruitmentsApplicationTypes } from "../../ActionTypes/recruitmentsApplicationTypes";
import { FetchRecruitmentsApplicationFailure, FetchRecruitmentsApplicationFailurePayload, FetchRecruitmentsApplicationRequest, FetchRecruitmentsApplicationSuccess, FetchRecruitmentsApplicationSuccessPayload } from "../../types/type";

export const fetchRecruitmentsApplicationRequest = (company_id:any): FetchRecruitmentsApplicationRequest => {
  return ({
    type: recruitmentsApplicationTypes.FETCH_RECRUIMENTS_APPLICATION_REQUEST,
    company_id
  });
};

export const fetchRecruitmentsApplicationSuccess = (
  payload: FetchRecruitmentsApplicationSuccessPayload,
): FetchRecruitmentsApplicationSuccess => ({
  type: recruitmentsApplicationTypes.FETCH_RECRUIMENTS_APPLICATION_SUCCESS,
  payload
});

export const fetchRecruitmentsApplicationFailure = (
  payload: FetchRecruitmentsApplicationFailurePayload
): FetchRecruitmentsApplicationFailure => ({
  type: recruitmentsApplicationTypes.FETCH_RECRUIMENTS_APPLICATION_FAILURE,
  payload
});
