import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminApplicantsPostTypes } from "../../ActionTypes/adminApplicantsPostTypes";
import { fetchAdminApplicantsPostFailure, fetchAdminApplicantsPostSuccess } from "../../actions/adminApplicantsPostActions/adminApplicantsPostActions";


const getAdminApplicantsPost = (body: any, method: any, id:any) => { 
  console.log(method);
  const config = {
    headers: { 
      Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  if(method == "POST"){
    return axios.post(API_BASE_ADDRESS + '/admin/applicants' , {applicant: body}, config); 
  } else if (method =="PUT") {
    return axios.put(API_BASE_ADDRESS + '/admin/applicants/'+ id , {applicant: body}, config); 
  } else {
    return axios.delete(API_BASE_ADDRESS + '/admin/applicants/'+ id , config);
  }
 
}


function* fetchAdminApplicantsPostSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminApplicantsPost, action.body, action.method, action.id);
    yield put(
      fetchAdminApplicantsPostSuccess({
        applicants: response.data,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminApplicantsPostFailure({
        error: e.message
      })
    );
  }
}

function* AdminApplicantsPostSaga() {
  yield takeLatest(adminApplicantsPostTypes.FETCH_ADMIN_APPLICANTS_POST_REQUEST, fetchAdminApplicantsPostSaga);
}
export default AdminApplicantsPostSaga;