import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../redax/reducers/rootReducer";
import { fetchSignupRequest } from "../../../redax/actions/signupAction/signupAction";


function SignUp() {
  const [stepList] = useState(['仮登録情報入力', '仮登録メール送信', '本登録完了']);
  const [screen, setScreen] = useState(0);
  // const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [error_msg, setErrorMsg] = useState<boolean>(false);
  const [emailValidate, setemailValidate] = useState<boolean>(false);
  const [emailConfirm, setEmailConfirm] = useState<boolean>(false);
  const [passwordValidate, setpasswordValidate] = useState<boolean>(false);
  const [isSignUp, setSignUp] = useState<boolean>(false);
  const dispatch = useDispatch();
  const signup = useSelector((state: RootState) => state.signup);
  const [email, setEmail] = useState<any>(null);
  const [emailtwo, setEmailTwo] = useState<any>(null);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorPasswordminLength, setErrorPasswordminLength] = useState(false);
  const [errorPasswordmaxLength, setErrorPasswordmaxLength] = useState(false);

  const onChangeEmail = (event: any) => {
    setErrorMsg(false);
    const {name, value} = event.target;
    if(name === 'email1') {
      setEmail(value);
    }
    SelectValidation(name, value);
}
function SelectValidation (name: any, value: any) {
  let mailformat =/^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (value === '') {
        setError(true);
    } else {
        setError(false);
        if(mailformat.test(value)){
          setemailValidate(false);
      } else {
        setemailValidate(true);
        if(emailtwo == null || emailtwo == '') {
          setEmailConfirm(true);
        }
      } 
   }
}
const onChangeEmailTwo = (event: any) => {
  setErrorMsg(false);
  const {name, value} = event.target;
  if(name === 'email2') {
    setEmailTwo(value);
  }
  DataValidation(name, value);
}
function DataValidation (name: any, value: any) {
  if(email===value){
    setEmailConfirm(false);
  }else{
    setEmailConfirm(true);
  }
}

const onChangePassword = (event: any) => {
  const {name, value} = event.target;
  if(name === 'password') {
    setPassword(value);
  }
  Validation(name, value);
}

function Validation (name: any, value: any) {
  let passwordValidate = /^(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,32}$/
  // const passwordValidate = new RegExp('/^(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,32}$/');
  if(value === ''){
    setErrorPassword(true);
    setpasswordValidate(false);
    setErrorPasswordminLength(false);
    setErrorPasswordmaxLength(false);
  } else {
    setErrorPassword(false);
    if(passwordValidate.test(value)){
      setpasswordValidate(false);
      } else {
        setpasswordValidate(true);
    }
    if(value.length >= 8){
      setErrorPasswordminLength(false)
    }else{
      setErrorPasswordminLength(true)
    }
    if(value.length <= 32){
      setErrorPasswordmaxLength(false)
    }else{
      setErrorPasswordmaxLength(true)
    }
  }
}

function signupBtn () {
  if(email == null || email == '') {
    setError(true);
    if(password == null || password == '') {
      setErrorPassword(true);
    }
    return;
  }
  if(email !== emailtwo) {
    return;
  }
  if(password == null || password == '') {
    return;
  }
  setSignUp(true);
}

useEffect(() => {
  if(isSignUp) {
    dispatch(fetchSignupRequest( email, password, 'Companies::Recruiter' ));
    setSignUp(false);
  }
  if(signup !== undefined && signup.pending) {
    window.location.href = "/recruiter/signup/registration";
  } else {
    if(signup.error != null && signup.error !== null) {
      setErrorMsg(true);
    }
    // setScreen(2);
  }
}, [dispatch, isSignUp, signup]);


    return (
      <main id="register">
      <section className="wrapper">
        <div className="ttl_box">
          <h2 className="ttl">新規会員登録</h2>
          <p>登録は<span>1分</span>で完了</p>
        </div>
        <ul className="tab_box">
          {stepList.map((data: any, index: any) => (
            <li key= {index} className={screen === index ? 'active' : ''}>
                <span>STEP{index + 1}</span>{data}
            </li>
            ))}
          </ul>

        {screen === 0 ?  
        <div className="input_box">
        <div className='form'>
          <dl>
            <dt>メールアドレス</dt>
            <dd>
              <input
                type="email"
                title="メールアドレス"
                name="email1"
                value={email || ''}
                onChange={onChangeEmail}
              />
              {error ? 
                <div>
                  <span>必須項目です</span>
                </div>
              : <></>} 
              {emailValidate ? 
                <div>
                  <span>正しいメールアドレスの形式で入力してください</span>
                </div>
              : <></>}
            </dd>

            <dt>メールアドレス<span>(確認用)</span></dt>
            <dd>
              <input
                type="email"
                title="メールアドレス（確認用）"
                name="email2" 
                value={emailtwo || ''}
                onChange={ onChangeEmailTwo}
              />
              {emailConfirm ? 
                <div>
                  <span>確認用アドレスが一致していません</span>
                </div>
              : <></>}
            </dd>

            <dt>パスワード</dt>
            <dd>
              <input
                type="password"
                title="パスワード"
                name="password"
                value={password || ''}
                onChange={onChangePassword}
              />
              {/* {check ? 
                <div>
                  <span v-if="!$v.email.email">大文字と小文字を1文字以上含めた半角英数字で入力してください</span>
                </div>
              : <></>} */}
              {errorPassword || errorPasswordminLength || errorPasswordmaxLength ||passwordValidate ? 
              <div>
                {errorPassword ? 
                <div>
                  <span>必須項目です</span>
                </div>
              : <></>}
              {errorPasswordminLength || errorPasswordmaxLength ? 
                <div>
                  <span>8文字以上32文字以下で入力してください</span>
                </div>
              : <></>}
              {passwordValidate ? 
                <div>
                  <span>大文字と小文字を1文字以上含めた半角英数字で入力してください</span>
                </div>
              : <></>}
              </div>
              :
              <span style={{color: "#888"}}>大文字と小文字を1文字以上含めた半角英数字で入力してください</span> 
              }
            </dd>
          </dl>
          {error_msg ?
            <p className="error_text">このメールアドレスは既に使用されています</p>
           : <></>}
            <button type="submit" onClick={signupBtn} className="btn type3 yellow">登録</button>
        </div>
        </div>
        : <></>}


        {screen === 1 ? 
        <p>
        ご入力いただいたメールアドレス宛に仮登録メールをお送りいたしました。<br/>
        お送りしたメールをご確認いただき、そちらから登録の手順を実行してください。<br/>
        <small>
        ※メールが届かない場合は、間違ったメールアドレスを登録しているか、<br className="pc_only"/>
          迷惑メールフォルダに振り分けられている可能性があります。<br className="pc_only"/>
          ご確認いただき再度ご登録をお願いします。
        </small>
      </p>
        : <></>}

        {screen === 2 ? 
        <div className="wrapper">
          <div className="input_box">
          <h3 className="ttl">エラーが発生しました。</h3>
          <p>恐れ入りますが、時間をおいて再度アクセスしてください。</p>
      </div>
      </div>
        : <></>}
      </section>
    </main>
    );
  }
  
  export default SignUp;


