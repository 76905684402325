import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminRecruitmentEditTypes } from "../../ActionTypes/adminRecruitmentEditTypes";
import { fetchAdminRecruitmentEditFailure, fetchAdminRecruitmentEditSuccess } from "../../actions/adminRecruitmentEditActions/adminRecruitmentEditActions";

const getAdminRecruitmentEdit = (id: any) => { 
  const config = {
    headers: { 
      Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/recruitments/' + id + '/edit', config); 
}


function* fetchAdminRecruitmentEditSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminRecruitmentEdit, action.id);
    yield put(
      fetchAdminRecruitmentEditSuccess({
        recruitments: response.data,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminRecruitmentEditFailure({
        error: e.message
      })
    );
  }
}

function* AdminRecruitmentEditSaga() {
  yield takeLatest(adminRecruitmentEditTypes.FETCH_ADMIN_RECRUITMENT_EDIT_REQUEST, fetchAdminRecruitmentEditSaga);
}
export default AdminRecruitmentEditSaga;