import { adminCompanyListTypes } from "../../ActionTypes/adminCompanyListTypes"
import { FetchAdminCompanyListFailure, FetchAdminCompanyListRequest, FetchAdminCompanyListSuccess, FetchAdminCompanyListSuccessPayload,
  FetchAdminCompanyListFailurePayload } from "../../types/type";

export const fetchAdminCompanyListRequest = (data: any): FetchAdminCompanyListRequest => ({
  type: adminCompanyListTypes.FETCH_ADMIN_COMPANY_LIST_REQUEST,
  data
});

export const fetchAdminCompanyListSuccess = (
  payload: FetchAdminCompanyListSuccessPayload
): FetchAdminCompanyListSuccess => ({
  type: adminCompanyListTypes.FETCH_ADMIN_COMPANY_LIST_SUCCESS,
  payload
});

export const fetchAdminCompanyListFailure = (
  payload: FetchAdminCompanyListFailurePayload
): FetchAdminCompanyListFailure => ({
  type: adminCompanyListTypes.FETCH_ADMIN_COMPANY_LIST_FAILURE,
  payload
});