import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminRecruitmentPostTypes } from "../../ActionTypes/adminRecruitmentPostTypes";
import { fetchAdminRecruitmentPostFailure, fetchAdminRecruitmentPostSuccess } from "../../actions/adminRecruitmentPostActions/adminRecruitmentPostActions";


const getAdminRecruitmentPost = (body: any, method: any, id:any) => { 
  const config = {
    headers: { 
      Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  if(method == "POST"){
    return axios.post(API_BASE_ADDRESS + '/admin/recruitments' , body, config); 

  } else if (method =="PUT") {
    return axios.put(API_BASE_ADDRESS + '/admin/recruitments/'+ id , body, config); 
  } else {
    return axios.delete(API_BASE_ADDRESS + '/admin/recruitments/'+ id , config);
  }
 
}


function* fetchAdminRecruitmentPostSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminRecruitmentPost, action.body, action.method, action.id);
    yield put(
      fetchAdminRecruitmentPostSuccess({
        recruitments: response.data.recruitments,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminRecruitmentPostFailure({
        error: e.message
      })
    );
  }
}

function* AdminRecruitmentPostSaga() {
  yield takeLatest(adminRecruitmentPostTypes.FETCH_ADMIN_RECRUITMENT_POST_REQUEST, fetchAdminRecruitmentPostSaga);
}
export default AdminRecruitmentPostSaga;