import { RecruiterPaymentDetailTypes } from "../../ActionTypes/recruiterInvoiceDetailTypes";
import { RecruiterPaymentDetailActions, RecruiterPaymentDetailState } from "../../types/type";


const initialState: RecruiterPaymentDetailState = {
  pending: false,
  recruiterPaymentDetail: [],
  error: null,
};

export default (state = initialState, action: RecruiterPaymentDetailActions,) => {
  switch (action.type) {
    case RecruiterPaymentDetailTypes.FETCH_RECRUITER_PAYMENT_DETAIL_REQUEST:
      return {
        ...state,
        pending: false
      };
      case RecruiterPaymentDetailTypes.FETCH_RECRUITER_PAYMENT_DETAIL_SUCCESS:
      return {
        ...state,
        pending: true,
        recruiterPaymentDetail: action.payload.recruiterPaymentDetail,
        error: null
      };
    case RecruiterPaymentDetailTypes.FETCH_RECRUITER_PAYMENT_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
