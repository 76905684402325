import { stepTypes } from "../../ActionTypes/stepTypes";
import { 
   FetchStepFailure,
   FetchStepFailurePayload,
   FetchStepRequest,
   FetchStepSuccess,
   FetchStepSuccessPayload
  } from "../../types/type";


export const fetchStepRequest = (query:any): FetchStepRequest => ({
  type: stepTypes.FETCH_STEP_REQUEST,
  query
});

export const fetchStepSuccess = (
  payload: FetchStepSuccessPayload,
): FetchStepSuccess => ({
  type: stepTypes.FETCH_STEP_SUCCESS,
  payload
});

export const fetchStepFailure = (
  payload: FetchStepFailurePayload
): FetchStepFailure => ({
  type: stepTypes.FETCH_STEP_FAILURE,
  payload
});
