import { loginTypes } from "../../ActionTypes/loginTypes";
import { FetchLoginFailure, FetchLoginFailurePayload, 
  FetchLoginRequest,
   FetchLoginSuccess, 
   FetchLoginSuccessPayload 
  } from "../../types/type";


export const fetchLoginRequest = (email:any, password:any, resource_type: any): FetchLoginRequest => ({
  type: loginTypes.FETCH_LOGIN_REQUEST,
  email,
  password,
  resource_type
});

export const fetchLoginSuccess = (
  payload: FetchLoginSuccessPayload,
): FetchLoginSuccess => ({
  type: loginTypes.FETCH_LOGIN_SUCCESS,
  payload
});

export const fetchLoginFailure = (
  payload: FetchLoginFailurePayload
): FetchLoginFailure => ({
  type: loginTypes.FETCH_LOGIN_FAILURE,
  payload
});
