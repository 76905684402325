import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { articlesTypes } from "../../ActionTypes/articlesTypes";
import { fetchArticlesFailure, fetchArticlesSuccess } from "../../actions/articlesActions/articlesActions";
import { API_BASE_ADDRESS } from "../../API";


var getArticles = () => axios.get('https://www.parachannelcafe.jp/wp-json/api/v1/articles', {headers: { Accept: 'application/json'}}); 

function* fetchArticlesSaga() {
  try {
    const response: AxiosResponse = yield call(getArticles);
    yield put(
      fetchArticlesSuccess({
        articles: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchArticlesFailure({
        error: e.message
      })
    );
  }
}

function* ArticlesSaga() {
  yield takeLatest(articlesTypes.FETCH_ARTICLES_REQUEST, fetchArticlesSaga);
}

export default ArticlesSaga;
