import { adminAdministratorsDeleteTypes } from "../../ActionTypes/adminAdministratorsDeleteTypes";
import {  AdminAdministratorsDeleteActions, AdminAdministratorsDeleteState,  } from "../../types/type";


const initialState: AdminAdministratorsDeleteState = {
  pending: false,
  administratorsDelete: [],
  error: null,
};

export default (state = initialState, action: AdminAdministratorsDeleteActions,) => {
  switch (action.type) {
    case adminAdministratorsDeleteTypes.FETCH_ADMIN_ADMINISTRATORS_DELETE_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminAdministratorsDeleteTypes.FETCH_ADMIN_ADMINISTRATORS_DELETE_SUCCESS:
      return {
        ...state,
        pending: true,
        administratorsDelete: action.payload.administratorsDelete,
      };
    case adminAdministratorsDeleteTypes.FETCH_ADMIN_ADMINISTRATORS_DELETE_FAILURE:
      return {
        ...state,
        pending: false,
        administratorsDelete: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
