

import { handicapsTypes } from "../../ActionTypes/handicapsTypes";
import {
  FetchHandicapsFailure,
  FetchHandicapsFailurePayload,
  FetchHandicapsRequest,
  FetchHandicapsSuccess,
  FetchHandicapsSuccessPayload,
} from "../../types/type";

export const fetchHandicapsRequest = (): FetchHandicapsRequest => ({
  type: handicapsTypes.FETCH_HANDICAPS_REQUEST,
});

export const fetchHandicapsSuccess = (
  payload: FetchHandicapsSuccessPayload,
): FetchHandicapsSuccess => ({
  type: handicapsTypes.FETCH_HANDICAPS_SUCCESS,
  payload
});

export const fetchHandicapsFailure = (
  payload: FetchHandicapsFailurePayload
): FetchHandicapsFailure => ({
  type: handicapsTypes.FETCH_HANDICAPS_FAILURE,
  payload
});
