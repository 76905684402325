import { recruitmentsCopyTypes } from "../../ActionTypes/recruitmentsCopyTypes";
import { RecruitmentsCopyActions, RecruitmentsCopyState } from "../../types/type";


const initialState: RecruitmentsCopyState = {
  pending: false,
  recruitmentsCopy: [],
  error: null,
};

export default (state = initialState, action: RecruitmentsCopyActions,) => {
  switch (action.type) {
    case recruitmentsCopyTypes.FETCH_RECRUIMENTS_COPY_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitmentsCopyTypes.FETCH_RECRUIMENTS_COPY_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitmentsCopy: action.payload.recruitmentsCopy,
        error: null
      };
    case recruitmentsCopyTypes.FETCH_RECRUIMENTS_COPY_FAILURE:
      return {
        ...state,
        pending: false,
        recruitmentsCopy: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
