import { adminScoutsDetailTypes } from "../../ActionTypes/adminScoutsDetailTypes";
import { AdminScoutsDetailActions, AdminScoutsDetailState } from "../../types/type";



const initialState: AdminScoutsDetailState = {
  pending: false,
  scout: [],
  error: null,
};

export default (state = initialState, action: AdminScoutsDetailActions,) => {
  switch (action.type) {
    case adminScoutsDetailTypes.FETCH_ADMIN_SCOUTS_DETAIL_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminScoutsDetailTypes.FETCH_ADMIN_SCOUTS_DETAIL_SUCCESS:
      return {
        ...state,
        pending: true,
        scout: action.payload.scout,
      };
    case adminScoutsDetailTypes.FETCH_ADMIN_SCOUTS_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        scout: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
