import { ChangeUpdateStatusTypes } from "../../ActionTypes/changeUpdateStatusTypes";
import { ChangeUpdateStatusActions, ChangeUpdateStatusState } from "../../types/type";



const initialState: ChangeUpdateStatusState = {
  pending: false,
  status_id: [],
  error: null,
};

export default (state = initialState, action: ChangeUpdateStatusActions,) => {
  switch (action.type) {
    case ChangeUpdateStatusTypes.FETCH_CHANGE_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case ChangeUpdateStatusTypes.FETCH_CHANGE_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        pending: true,
        status_id: action.payload.status_id,
        error: null
      };
    case ChangeUpdateStatusTypes.FETCH_CHANGE_UPDATE_STATUS_FAILURE:
      return {
        ...state,
        pending: false,
        status_id: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
