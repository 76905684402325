import axios, { AxiosResponse } from "axios";
import { API_BASE_ADDRESS } from "../../API";
import { call, put, takeLatest } from "redux-saga/effects";
import { MessagesPostTypes } from "../../ActionTypes/messagesPostTypes ";
import { fetchMessagesPostFailure, fetchMessagesPostSuccess } from "../../actions/messagesPostActions/messagesPostActions ";

var getMessages = (data: any, message: any) => axios.post(API_BASE_ADDRESS + '/applications/' + data + '/messages', message, {
  headers: {
  Authorization: localStorage.getItem('jobParachannelAuthToken'),
  Accept: '*/*',
  Prefer: 'example=test-1',
  'Content-Type': 'application/json',
  }
}); 
function* fetchMessagesPostSaga(queryParams: any) {
  try {
    const response: AxiosResponse = yield call(getMessages, queryParams.data, queryParams.message);
    yield put(
      fetchMessagesPostSuccess({
        messages: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchMessagesPostFailure({
        error: e.message
      })
    );
  }
}

function* MessagesPostSaga() {
  yield takeLatest(MessagesPostTypes.FETCH_MESSAGES_POST_REQUEST, fetchMessagesPostSaga);
}

export default MessagesPostSaga;
