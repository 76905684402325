import { MessagesTypes } from "../../ActionTypes/messagesTypes";
import { MessagesActions, MessagesState } from "../../types/type";



const initialState: MessagesState = {
  pending: false,
  messages: [],
  error: null,
};

export default (state = initialState, action: MessagesActions,) => {
  switch (action.type) {
    case MessagesTypes.FETCH_MESSAGES_REQUEST:
      return {
        ...state,
        pending: false
      };
    case MessagesTypes.FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        pending: true,
        messages: action.payload.messages,
        error: null
      };
    case MessagesTypes.FETCH_MESSAGES_FAILURE:
      return {
        ...state,
        pending: false,
        messages: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
