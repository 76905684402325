import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchEmailChangeFailure, fetchEmailChangeSuccess } from "../../actions/emailChangeActions/emailChangeActions";
import { emailChangeTypes } from "../../ActionTypes/emailChangeTypes";


const getEmailChange = (email: any) =>
  axios.post(API_BASE_ADDRESS + + '/email_change/send',{
    email: email,
    resource_type: 'Applicant'
  },{
    headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'), }
  });

function* fetchEmailChangeSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getEmailChange, action.email);
    yield put(
      fetchEmailChangeSuccess({
        email: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchEmailChangeFailure({
        error: e.message
      })
    );
  }
}

function* EmailChangeSaga() {
  yield takeLatest(emailChangeTypes.FETCH_EMAIL_CHANGE_REQUEST, fetchEmailChangeSaga);
}
export default EmailChangeSaga;