import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { columnFetchListTypes } from "../../ActionTypes/columnFetchListTypes";
import { columnFetchListFailure, columnFetchListSuccess } from "../../actions/columnFetchList/columnFetchListActions";
let per = 3;

var getColumnFetchList = (companyId:any) => axios.get(API_BASE_ADDRESS + '/articles?company_id=' + companyId + '&per='+ per , {headers: { Accept: 'application/json'}}); 

function* fetchColumnFetchListSaga(companyId: any) {
  try {
    const response: AxiosResponse = yield call(getColumnFetchList, companyId.companyId);

    yield put(
      columnFetchListSuccess({
        columnList: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      columnFetchListFailure({
        error: e.message
      })
    );
  }
}

function* ColumnFetchListSaga() {
  yield takeLatest(columnFetchListTypes.FETCH_COLUMN_FETCH_LIST_REQUEST, fetchColumnFetchListSaga);
}

export default ColumnFetchListSaga;
