import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchDownloadApplicationCsvSuccess, fetchDownloadApplicationCsvFailure } from "../../actions/downloadApplicationCsvActions/downloadApplicationCsvActions";
import { DownloadApplicationCsvTypes } from "../../ActionTypes/downloadApplicationCsvTypes";

var getDownloadApplicationCsv = () => axios.get(API_BASE_ADDRESS + "/applications/csv", {headers: {
  mode: 'cors',
  Authorization: localStorage.jobParachannelAuthToken,
  Accept: 'application/json'
  },
});
function* fetchDownloadApplicationCsvSaga() {
  try {
    const response: AxiosResponse = yield call(getDownloadApplicationCsv);
    yield put(
      fetchDownloadApplicationCsvSuccess({
        applicationCsvData: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchDownloadApplicationCsvFailure({
        error: e.message
      })
    );
  }
}

function* DownloadApplicationCsvSaga() {
  yield takeLatest(DownloadApplicationCsvTypes.FETCH_DOWNLOAD_APPLICATIONCSV_REQUEST, fetchDownloadApplicationCsvSaga);
}

export default DownloadApplicationCsvSaga;
