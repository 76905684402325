import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchScoutsEntryFailure, fetchScoutsEntrySuccess } from "../../actions/scoutsEntryActions/scoutsEntryActions";
import { scoutsEntryTypes } from "../../ActionTypes/scoutsEntryTypes";

const getEntry = (id: any, name: any, types: any) => {
  if(name == 'template' && types == 'get') {
    return axios.get(API_BASE_ADDRESS + '/scout_templates',  {
      headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken }
    });
  } else if(name == 'template' && types == 'delete') {
    return axios.delete(API_BASE_ADDRESS + '/scout_templates/' + id,  {
      headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken }
    });
  } else if(name == 'template' && types == 'post') {
    return axios.post(API_BASE_ADDRESS + '/scout_templates/', id,  {
      headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken }
    });
  } else if(name == 'scout' && types == 'get') {
    return axios.get(API_BASE_ADDRESS + '/recruitments/scout',  {
      headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken }
    });
  } else if(name == 'scout' && types == 'select') {
    return axios.get(API_BASE_ADDRESS + '/recruitments/scout/selected?' + id,  {
      headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken }
    });
  } else if(name == 'scout' && types == 'post') {
    return axios.post(API_BASE_ADDRESS + '/scouts', id,  {
      headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken }
    });
  }
}

function* fetchScoutsEntrySaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getEntry, action.id, action.name, action.types);
    yield put(
      fetchScoutsEntrySuccess({
        entry: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchScoutsEntryFailure({
        error: e.message
      })
    );
  }
}

function* ScoutsEntrySaga() {
  yield takeLatest(scoutsEntryTypes.FETCH_SCOUTS_ENTRY_REQUEST, fetchScoutsEntrySaga);
}
export default ScoutsEntrySaga;