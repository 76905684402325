import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchRecruiterPricingRequest } from '../../../redax/actions/recruiterPricingActions/recruiterPricingActions';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchRecruiterPlanPostRequest } from '../../../redax/actions/recruiterPlanPostActions/recruiterPlanPostActions';
import { fetchRecruiterPlanRequest } from '../../../redax/actions/recruiterPlanActions/recruiterPlanActions';
import { useLocation } from 'react-router-dom';
import Loader from '../../loader/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const Plandetail = () => {
  const pricing = useSelector((state: RootState) => state.recruiterPricing);
  const plan = useSelector((state: RootState) => state.recruiterPlan);
  const post = useSelector((state:RootState) => state.recruiterPlanPost)
  const [pricingData, setPricingData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPath = window.location.pathname;
  const [id, setId] = useState(null);
  const [data, setData] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const [listPrice, setListPrice] = useState(0);
  const location = useLocation();
  const dataParam = location.state;
  const [ck1, setCk1] = useState(false);
  const [ck2, setCk2] = useState(false);  
  const [loadScreen, setLoadScreen] = useState(false);
  const [checkPricing, setCheckPricing] = useState(false);
  const [sumPrice, setSumPrice] = useState<number>(0);
  const [option1, setOptions1] = useState<number>(0);
  const [option2, setOptions2] = useState<number>(0);
  
  useEffect(() => { 
    if (!localStorage.getItem('jobParachannelAuthToken')) {
      navigate('/recruiter_lp/');
    } else {
      setLoadScreen(true);
      dispatch(fetchRecruiterPricingRequest());
      dispatch(fetchRecruiterPlanRequest());
      setId(dataParam.data);
      setCheckPricing(window.location.href.includes('recruiter/plandetail/?pricing') ? true : false);
    }
  }, [dispatch, id]);
  useEffect(()=> {
    window.scrollTo(0, 0);
    if(pricing.pending && plan.pending) {
      setPricingData(pricing.recruiterPricing.plan_lists);
      if(plan.recruiterPlan.current_dr_option != null){
        setCk1(plan.recruiterPlan.current_dr_option);
      } else {
        setCk1(false);
      }
      if(plan.recruiterPlan.current_warranty_option){
        setCk2(plan.recruiterPlan.current_warranty_option);
      } else {
        setCk2(false);
      }
      if(id){
        setData(pricing.recruiterPricing.plan_lists.find((plan:any) => plan.id == id));
        setOptions(pricing.recruiterPricing.plan_lists.find((plan:any) => plan.id == id).option);
        setSumPrice(pricing.recruiterPricing.plan_lists.find((plan:any) => plan.id == id).price);
        setListPrice(pricing.recruiterPricing.plan_lists.find((plan:any) => plan.id == id).price);
      }
    }  
    let lst : any = [];
    let optionLst : any = [];
    lst = pricing.recruiterPricing.plan_lists;
    let c1, c2 : any;
    if(plan.recruiterPlan.current_dr_option != null) {
      c1 = plan.recruiterPlan.current_dr_option;
    } else {
      c1 = false;
    }
    if(plan.recruiterPlan.current_warranty_option != null) {
      c2 = plan.recruiterPlan.current_warranty_option;
    } else {
      c2 = false;
    }
    if(lst != undefined) {
      for(let i = 0;  i < lst.length; i++) {
        if(lst[i].id == id) {
          optionLst = lst[i].option;
        }
      }      
    }
    if(optionLst.length > 0) {    
      for(let i = 0; i < optionLst.length; i++) {
        if(c1 == true){   //dr  
          setOptions1(optionLst[1].price);        
        } else {
          setOptions1(0);
        }
        if(c2 == true){ //warranty
          setOptions2(optionLst[0].price);        
        } else {
          setOptions2(0);
        }
      }
    }
    setLoadScreen(false);
  }, [pricing, plan]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
    
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  // const recruiterpost = {
  //   "plan_id":id,
  //   "dr_option":ck1,
  //   "warranty_option":ck2
  // }
  function handlePost() {
    navigate("/recruiter/paymentconfirmation", {state:{id, ck1, ck2}})
  };
  useEffect(() => {
    // Attach the event listener on component mount
    window.addEventListener('resize', handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  function checkboxHandler(event: any, value: any, checkboxName: string) {
    // Toggle the state based on the checkbox name
    if (checkboxName === 'ck1') {
      setCk2(prevState => !prevState);
    } else if (checkboxName === 'ck2') {
      setCk1(prevState => !prevState);
    }
  
    // Calculate the new sumPrice based on the updated checkbox state
    setSumPrice(prevSumPrice => {
      if (checkboxName === 'ck1' && !ck2) {
        // Add the price of the checked option to the sumPrice
        return prevSumPrice + value.price;
      } else if (checkboxName === 'ck1' && ck2) {
        // Subtract the price of the unchecked option from the sumPrice
        return prevSumPrice - value.price;
      } else if (checkboxName === 'ck2' && !ck1) {
        // Add the price of the checked option to the sumPrice
        return prevSumPrice + value.price;
      } else if (checkboxName === 'ck2' && ck1) {
        // Subtract the price of the unchecked option from the sumPrice
        return prevSumPrice - value.price;
      }
      // Return previous sumPrice if no change in checkbox state
      return prevSumPrice;
    });
  }
  
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = (n:any) => {
    setShowTooltip(true);
  };

  const handleMouseLeave = (n:any) => {
    setShowTooltip(false);
  };

  const handleMouseClick = (n:any) => {
    if (showTooltip){
      setShowTooltip(false);
    } else{
      setShowTooltip(true);
    }
  };

  const [showTooltip1, setShowTooltip1] = useState(false);

  const handleMouseEnter1 = (n:any) => {
    setShowTooltip1(true);
  };

  const handleMouseLeave1 = (n:any) => {
    setShowTooltip1(false);
  };
  const handleMouseClick1 = (n:any) => {
    if (showTooltip1){
      setShowTooltip1(false);
    } else{
      setShowTooltip1(true);
    }
  };
      
  return (    
    <div className='cont contBottom' style={windowSize.width >= 780 ? {paddingRight:"15%",paddingLeft:"15%"} : {}}>
      {loadScreen ? <Loader /> : <></>}
        <div style={windowSize.width >= 780 ? {display:"flex", alignItems:"center", justifyContent:"space-between"} : {}}>
            <h1 className='newPageDetail1H1'>{data.name}</h1>
            {/* <h1 className='h1B'>{`請求期間の例をPDFで確認する ->`}</h1> */}
        </div>
        <hr style={{height:"1px", background:"#D5D5D5"}}/>
        <br />
        <div className='newPageDetail1Section'>
          <div style={{position:"absolute", height:"500px", width:"100%", display:"contents"}}>
              {
              Number(id) == 1 ? <img src="/assets/img/id6.png" style={{height:"100%", width:"500px"}} alt="" /> : 
              Number(id) == 2 ? <img src="/assets/img/id4.png" style={{height:"100%", width:"500px"}} alt="" /> : 
              Number(id) == 3 ? <img src="/assets/img/id5.png" style={{height:"100%", width:"500px"}} alt="" /> : ""}
                <div className='price'><span className='pricespan'>基本料金</span> ¥ {listPrice.toLocaleString()}</div>
          </div>
          <br />
          <div style={{padding:"0 40px 40px"}}>
            <h2 className='newPageDetail1H2'>基本機能一覧</h2>
            <br />
            <p className='plandetailtext' style={{display:"flex"}}>️<img style={{width:"15px", height:"15px", marginRight:"5px"}} src="/assets/img/newPageCorrect.png" alt="" /> 求人掲載{data.recruitment_limit ? `${data.recruitment_limit}求人` : '上限なし'}</p><br />
            <p className='plandetailtext' style={{display:"flex"}}>️<img style={{width:"15px",height:"15px", marginRight:"5px"}} src="/assets/img/newPageCorrect.png" alt="" />スカウト枠{data.days_scout_limit}件/日</p><br />
            <p className='plandetailtext' style={{display:"flex"}}>️<img style={{width:"15px",height:"15px", marginRight:"5px"}} src="/assets/img/newPageCorrect.png" alt="" />{data.id == 1 ? `スカウト情報: 一部` : `スカウト情報: 全部 ` }</p><br />
            {/* <p className='plandetailtext' style={{display:"flex"}}>️<img style={{width:"15px",height:"15px", marginRight:"5px"}} src="/assets/img/newPageCorrect.png" alt="" />{`コラム記事(${data.month_article_limit}記事/月)`}</p><br /> */}
            <p className='plandetailtext' style={{display:"flex"}}>️<img style={{width:"15px",height:"15px", marginRight:"5px"}} src="/assets/img/newPageCorrect.png" alt="" />事務サポート(初期設定代行、アカウント統合)</p><br />
            <p className='plandetailtext' style={{display:"flex"}}>️<img style={{width:"15px",height:"15px", marginRight:"5px"}} src="/assets/img/newPageCorrect.png" alt="" />求人の掲載場所(求人一覧ボード、プレミアム求人)</p><br />
            <p className='plandetailtext' style={{display:"flex"}}>️<img style={{width:"15px",height:"15px", marginRight:"5px"}} src="/assets/img/newPageCorrect.png" alt="" /> 集客サポート（スカウト代行）</p><br />
            <p className='plandetailtext' style={{display:"flex"}}>️<img style={{width:"15px",height:"15px", marginRight:"5px"}} src="/assets/img/newPageCorrect.png" alt="" />集客サポート（広告配信）</p><br />
            <h2 className='newPageDetail1H2'>必要に応じてオプション追加</h2>
            <br />
            {options.map((e:any, index:any) => (  
              checkPricing && index == 0 && plan.recruiterPlan.current_warranty_option ? 
              <div key={index} style={{ display: "flex", alignItems: "center" }}>
                <img style={{width:"15px",height:"15px", marginRight:"5px"}} src="/assets/img/newPageCorrect.png" alt="" />
                <br />
                <p className='checkboxP' style={{ margin: "0", paddingLeft: "3px" }}>
                  {e.option_name}
                  {e.month_direct_scout_limit ? `${e.month_direct_scout_limit}件` : ""}
                  {e.price.toLocaleString()}円/月 (税別)
                </p>
              </div> 
            : checkPricing && index == 0 && !plan.recruiterPlan.current_warranty_option ?
              <div key={index} style={{ display: "flex", alignItems: "center" }}>                
                <br />
                <p className='checkboxP' style={{ margin: "0", paddingLeft: "3px" }}>
                  {e.option_name}
                  {e.month_direct_scout_limit ? `${e.month_direct_scout_limit}件` : ""}
                  {e.price.toLocaleString()}円/月 (税別)
                </p>
              </div>
            : checkPricing && index == 1 && plan.recruiterPlan.current_dr_option ?
              <div key={index} style={{ display: "flex", alignItems: "center" }}>
                <img style={{width:"15px",height:"15px", marginRight:"5px"}} src="/assets/img/newPageCorrect.png" alt="" />
                <br />
                <p className='checkboxP' style={{ margin: "0", paddingLeft: "3px" }}>
                  {e.option_name}
                  {e.month_direct_scout_limit ? `${e.month_direct_scout_limit}件` : ""}
                  {e.price.toLocaleString()}円/月 (税別)
                </p>
              </div>
            : checkPricing && index == 1 && !plan.recruiterPlan.current_dr_option ?
              <div key={index} style={{ display: "flex", alignItems: "center" }}>                  
                <br />
                <p className='checkboxP' style={{ margin: "0", paddingLeft: "3px" }}>
                  {e.option_name}
                  {e.month_direct_scout_limit ? `${e.month_direct_scout_limit}件` : ""}
                  {e.price.toLocaleString()}円/月 (税別)
                </p>
              </div>
            :
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <br />
              <input
                  style={{width:25, height:25, marginRight:10}}
                  onChange={(event) => checkboxHandler(event, e, `ck${index+1}`)}
                  type="checkbox" 
                  id={`ck${index+1}`} // Set the ID for the checkbox
                  value={e.price} 
                  defaultChecked={index === 0 ? plan.recruiterPlan.current_warranty_option : plan.recruiterPlan.current_dr_option}
              />
              <div style={{position:'relative'}}>
                <label htmlFor={`ck${index + 1}`}>
                  <p
                    className='checkboxP'
                    style={{ margin: "0", paddingLeft: "3px" }}
                  >
                    {e.option_name}
                    {e.month_direct_scout_limit ? `${e.month_direct_scout_limit}件` : ""}
                    {e.price.toLocaleString()}円/月 (税別)&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faQuestionCircle}  
                    onMouseEnter={index == 0 ? handleMouseEnter : handleMouseEnter1}
                    // onClick={index == 0 ? handleMouseClick : handleMouseClick1}
                    onMouseLeave={index == 0 ? handleMouseLeave :handleMouseLeave1 }
                    />
                  </p>
                  <hr style={{ height: "1px", background: "#00A0E9" }} />
                </label>
                {showTooltip && index == 0 ? 
                  <div style={{ position:"absolute", background: '#9b9b9b', color: 'white', borderRadius:"5px", padding: '5px', left: '62%', top: '-105%', transform: 'translateX(5px) translateY(-50%)', zIndex: '1', whiteSpace:"nowrap" , borderColor:"#000 #0000 #0000 #000"}}>
                    月間エントリー数0件の場合、<br />
                    スタンダードプラン・プロフェッショナルプラン<br />
                    を無効として追加オプションのみ請求となります。<br />
                    ※初月以降に適用されます。
                    <div style={{width:"10px",height:"10px", background:"#9b9b9b", position:"absolute", left:"20%", rotate:"45deg"}}></div>
                  </div> 
                  : null
                }
                {
                  showTooltip1 && index == 1 ? 
                  <div style={{ position:"absolute", background: '#9b9b9b', color: 'white', borderRadius:"5px", padding:"5px", left: '68%', top: '-50%', transform: 'translateX(5px) translateY(-50%)', zIndex: '1', whiteSpace:"nowrap" , borderColor:"#000 #0000 #0000 #000"}}>
                    貴社人事担当に代わり月50件のスカウトを実施します。
                    <div style={{width:"10px",height:"10px", background:"#9b9b9b", position:"absolute", left:"20%", rotate:"45deg"}}></div>
                  </div> : null
                }
              </div>
          </div>
            ))}
            <br />
            <h2 style={{fontWeight:"bold", color:"red"}}>
              月の途中でダウングレードしてもその月の間はプランの変更は反映されません。
              <br></br>
              月の途中でプランのアップグレードがある場合は即座にプランの変更が反映されます。</h2>
            <br />
            <br />
            <h1 className='newPageDetail1H1'>合計金額   ¥{`${(sumPrice + option1 + option2).toLocaleString()}` === "NaN" ? 0 : (sumPrice + option1 + option2).toLocaleString()}</h1>
            <br />
            {checkPricing ? 
            <div>
              <button onClick={() => { window.location.href = ('/recruiter/planpricing') }} style={windowSize.width > 780 ?{width:"100%"}:{}} className='scoutsBtn'>変更</button>
              <br />
              <br />
              <button onClick={() => { window.location.href = ('/recruiter/plan') }} style={windowSize.width > 780 ? {backgroundColor:"white" , color:"#00A0E9" ,width:"100%"}:{backgroundColor:"white" , color:"#00A0E9"}} className='scoutsBtn'>戻る</button>
            </div> 
            : 
            <div className='buttonSpace' style={windowSize.width > 780 ? {justifyContent:"space-between"} : {} }>
              <button onClick={handlePost} style={windowSize.width > 780 ?{width:"100%"}:{}} className='scoutsBtn'>変更</button>
              <br />
              <br />
              {/* <button onClick={() => { navigate(`/recruiter/plan/`)}} style={windowSize.width > 780 ? {backgroundColor:"white" , color:"#00A0E9" ,width:"150px"}:{backgroundColor:"white" , color:"#00A0E9"}} className='scoutsBtn'>戻る</button>                </div> */}
              <button onClick={() => { window.location.href = ('/recruiter/planpricing') }} style={windowSize.width > 780 ? {backgroundColor:"white" , color:"#00A0E9" ,width:"100%"}:{backgroundColor:"white" , color:"#00A0E9"}} className='scoutsBtn'>戻る</button> 
            </div>}
          </div>
        </div>
    </div>
  )
}

export default Plandetail