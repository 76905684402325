import { articlesRecruiterDetailTypes } from "../../ActionTypes/articlesRecruiterDetailTypes";
import { ArticlesRecruiterDetailActions, ArticlesRecruiterDetailState } from "../../types/type";




const initialState: ArticlesRecruiterDetailState = {
  pending: false,
  articles: [],
  error: null,
};

export default (state = initialState, action: ArticlesRecruiterDetailActions,) => {
  switch (action.type) {
    case articlesRecruiterDetailTypes.FETCH_ARTICLES_RECRUITER_DETAIL_REQUEST:
      return {
        ...state,
        pending: false
      };
    case articlesRecruiterDetailTypes.FETCH_ARTICLES_RECRUITER_DETAIL_SUCCESS:
      return {
        ...state,
        pending: true,
        articles: action.payload.articles,
        error: null
      };
    case articlesRecruiterDetailTypes.FETCH_ARTICLES_RECRUITER_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        articles: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
