import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { scoutsTypes } from "../../ActionTypes/scoutsTypes";
import { fetchScoutsFailure, fetchScoutsSuccess } from "../../actions/scoutsActions/scoutsActions";

var getScouts = (id: any, page:any) => {
  if(id == 0) {
    return axios.get(API_BASE_ADDRESS + '/scouts?page=' + page, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 
  } else {
    return axios.get(API_BASE_ADDRESS + '/scouts/' + id, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 
  }
}

function* fetchScoutsSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getScouts, action.id, action.page);
    yield put(
      fetchScoutsSuccess({
        scouts: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchScoutsFailure({
        error: e.message
      })
    );
  }
}

function* ScoutsSaga() {
  yield takeLatest(scoutsTypes.FETCH_SCOUTS_REQUEST, fetchScoutsSaga);
}

export default ScoutsSaga;
