import { adminRecruitmentJobOfferTypes } from "../../ActionTypes/adminRecruitmentJobOfferTypes";
import { FetchAdminRecruitmentJobOfferFailure, FetchAdminRecruitmentJobOfferFailurePayload, FetchAdminRecruitmentJobOfferRequest, FetchAdminRecruitmentJobOfferSuccess, FetchAdminRecruitmentJobOfferSuccessPayload } from "../../types/type";

export const fetchAdminRecruitmentJobOfferRequest = (hellowork: any): FetchAdminRecruitmentJobOfferRequest => ({
  type: adminRecruitmentJobOfferTypes.FETCH_ADMIN_RECRUITMENT_JOB_OFFER_REQUEST,
  hellowork
});

export const fetchAdminRecruitmentJobOfferSuccess = (
  payload: FetchAdminRecruitmentJobOfferSuccessPayload
): FetchAdminRecruitmentJobOfferSuccess => ({
  type: adminRecruitmentJobOfferTypes.FETCH_ADMIN_RECRUITMENT_JOB_OFFER_SUCCESS,
  payload
});

export const fetchAdminRecruitmentJobOfferFailure = (
  payload: FetchAdminRecruitmentJobOfferFailurePayload
): FetchAdminRecruitmentJobOfferFailure => ({
  type: adminRecruitmentJobOfferTypes.FETCH_ADMIN_RECRUITMENT_JOB_OFFER_FAILURE,
  payload
});