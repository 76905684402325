import { summaryTypes } from "../../ActionTypes/summaryTypes";
import { FetchSummaryRequest, FetchSummarySuccessPayload, FetchSummarySuccess, FetchSummaryFailurePayload, FetchSummaryFailure } from "../../types/type";


export const fetchSummaryRequest = (): FetchSummaryRequest => ({
  type: summaryTypes.FETCH_SUMMARY_REQUEST,
});

export const fetchSummarySuccess = (
  payload: FetchSummarySuccessPayload,
): FetchSummarySuccess => ({
  type: summaryTypes.FETCH_SUMMARY_SUCCESS,
  payload
});

export const fetchSummaryFailure = (
  payload: FetchSummaryFailurePayload
): FetchSummaryFailure => ({
  type: summaryTypes.FETCH_SUMMARY_FAILURE,
  payload
});
