import { adminRecommendJobsTypes } from "../../ActionTypes/adminRecommendJobsTypes";
import { AdminRecommendJobsActionss, AdminRecommendJobsState } from "../../types/type";



const initialState: AdminRecommendJobsState = {
  pending: false,
  jobs: [],
  error: null,
};

export default (state = initialState, action: AdminRecommendJobsActionss,) => {
  switch (action.type) {
    case adminRecommendJobsTypes.FETCH_ADMIN_RECOMMEND_JOBS_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminRecommendJobsTypes.FETCH_ADMIN_RECOMMEND_JOBS_SUCCESS:
      return {
        ...state,
        pending: true,
        jobs: action.payload.jobs,
        error: null
      };
    case adminRecommendJobsTypes.FETCH_ADMIN_RECOMMEND_JOBS_FAILURE:
      return {
        ...state,
        pending: false,
        applicant: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
