import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { articlesTypes } from "../../ActionTypes/articlesTypes";
import { fetchArticlesFailure, fetchArticlesSuccess } from "../../actions/articlesActions/articlesActions";
import { API_BASE_ADDRESS } from "../../API";
import { fetchArticlesRecruiterDetailFailure, fetchArticlesRecruiterDetailSuccess } from "../../actions/articlesRecruiterDerailActions/articlesRecruiterDerailActions";
import { articlesRecruiterDetailTypes } from "../../ActionTypes/articlesRecruiterDetailTypes";


var getRecruiterArticlesDetail = (id: any) => axios.get( API_BASE_ADDRESS + '/articles/recruiter/' + id, {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken'),}}); 

function* fetchArticlesRecruiterDetailSaga(param: any) {
  try {
    const response: AxiosResponse = yield call(getRecruiterArticlesDetail, param.id);
    yield put(
      fetchArticlesRecruiterDetailSuccess({
        articles: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchArticlesRecruiterDetailFailure({
        error: e.message
      })
    );
  }
}

function* ArticlesRecruiterDetailSaga() {
  yield takeLatest(articlesRecruiterDetailTypes.FETCH_ARTICLES_RECRUITER_DETAIL_REQUEST, fetchArticlesRecruiterDetailSaga);
}

export default ArticlesRecruiterDetailSaga;
