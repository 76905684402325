import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminAdministratorsRegionsTypes } from "../../ActionTypes/adminAdministratorsRegionsTypes";
import { fetchAdminAdministratorsRegionsFailure, fetchAdminAdministratorsRegionsSuccess } from "../../actions/adminAdministratorsRegionsActions/adminAdministratorsRegionsActions";

  var getAdminAdministratorsRegions = () => axios.get(API_BASE_ADDRESS + '/master?administrators=true' , {headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }}); 


function* fetchAdminAdministratorsRegionsSaga() {
  try {
    const response: AxiosResponse = yield call(getAdminAdministratorsRegions);
    yield put(
      fetchAdminAdministratorsRegionsSuccess({
        regions: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminAdministratorsRegionsFailure({
        error: e.message
      })
    );
  }
}

function* AdminAdministratorsRegionsSaga() {
  yield takeLatest(adminAdministratorsRegionsTypes.FETCH_ADMIN_ADMINISTRATORS_REGIONS_REQUEST, fetchAdminAdministratorsRegionsSaga);
}

export default AdminAdministratorsRegionsSaga;
