import { helloTypes } from "../../ActionTypes/helloTypes";
import { FetchHelloFailure,
   FetchHelloFailurePayload,
    FetchHelloRequest, 
    FetchHelloSuccess,
     FetchHelloSuccessPayload } from "../../types/type";



export const fetchHelloRequest = (data:any,): FetchHelloRequest => ({
  type: helloTypes.FETCH_HELLO_REQUEST,
  data,
});

export const fetchHelloSuccess = (
  payload: FetchHelloSuccessPayload,
): FetchHelloSuccess => ({
  type: helloTypes.FETCH_HELLO_SUCCESS,
  payload
});

export const fetchHelloFailure = (
  payload: FetchHelloFailurePayload
): FetchHelloFailure => ({
  type: helloTypes.FETCH_HELLO_FAILURE,
  payload
});
