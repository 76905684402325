import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchRecruitmentsDetailFailure, fetchRecruitmentsDetailSuccess } from "../../actions/recruitmentsDetailActions/recruitmentsDetailActions";
import { recruitmentsDetailTypes } from "../../ActionTypes/recruitmentsDetailTypes";

var getRecruitmentsDetail = (id:any) => {
  if(!localStorage.jobParachannelAuthToken) {
    return axios.get(API_BASE_ADDRESS + '/recruitments/' + id + '/detail', {headers: { Accept: 'application/json'}});
  } else {
    return axios.get(API_BASE_ADDRESS + '/recruitments/' + id + '/detail', {headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken}}); 
  }
}

function* fetchRecruitmentsDetailSaga(id: any) {
  try {
    const response: AxiosResponse = yield call(getRecruitmentsDetail, id.id);
    yield put(
      fetchRecruitmentsDetailSuccess({
        recruitmentsDetail: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitmentsDetailFailure({
        error: e.message
      })
    );
  }
}

function* RecruitmentsDetailSaga() {
  yield takeLatest(recruitmentsDetailTypes.FETCH_RECRUIMENTS_DETAIL_REQUEST, fetchRecruitmentsDetailSaga);
}

export default RecruitmentsDetailSaga;
