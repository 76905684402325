import { adminCompanyDetailTypes } from "../../ActionTypes/adminCompanyDetailTypes"
import { FetchAdminCompanyDetailFailure, FetchAdminCompanyDetailRequest, FetchAdminCompanyDetailSuccess, FetchAdminCompanyDetailSuccessPayload,
  FetchAdminCompanyDetailFailurePayload } from "../../types/type";

export const fetchAdminCompanyDetailRequest = (data: any): FetchAdminCompanyDetailRequest => ({
  type: adminCompanyDetailTypes.FETCH_ADMIN_COMPANY_DETAIL_REQUEST,
  data
});

export const fetchAdminCompanyDetailSuccess = (
  payload: FetchAdminCompanyDetailSuccessPayload
): FetchAdminCompanyDetailSuccess => ({
  type: adminCompanyDetailTypes.FETCH_ADMIN_COMPANY_DETAIL_SUCCESS,
  payload
});

export const fetchAdminCompanyDetailFailure = (
  payload: FetchAdminCompanyDetailFailurePayload
): FetchAdminCompanyDetailFailure => ({
  type: adminCompanyDetailTypes.FETCH_ADMIN_COMPANY_DETAIL_FAILURE,
  payload
});