import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../redax/reducers/rootReducer";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import { fetchScoutsRequest } from "../../../../redax/actions/scoutsActions/scoutsActions";
import Loader from "../../../loader/loader";

function HistoryDetail() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [resourceId, setResourceId] = useState(null);
    const loginData = useSelector((state: RootState) => state.login);
    const scoutsRecruitment = useSelector((state: RootState) => state.scouts);

    const [familyName, setFamylyName] = useState(null);
    const [page, setPage] = useState(1);
    const [loadScreen, setLoadScreen] = useState(true);
    const [scout, setScout] = useState<any>(null);
    const { id } = useParams();
    
    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
        //   dispatch(fetchLoginRequest('', '',''))
        } else (
            navigate('/')
        )
      }, [dispatch]);


    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/recruiter/init/');
            }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            dispatch(fetchScoutsRequest(id, page));
        }
      }, [loginData.pending, page]);
      
      function logout() {
        if (localStorage.entryFlag) {
            localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
            localStorage.removeItem('jobParachannelAuthToken')
            navigate('/');
            window.location.reload();
        }
    }

    useEffect(() => {
        if (scoutsRecruitment.pending) {            
            setScout(scoutsRecruitment.scouts.scout);
            setLoadScreen(false);
        } else if(scoutsRecruitment.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [scoutsRecruitment]);

    function searchCandidates(status: any, scoutId: any) {
        navigate('/recruiter/scouts/candidate?status=' + status + '&scoutId=' + scoutId);
    }

    return (
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className ="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">スカウト</h2>
                <ul>
                <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            {scout ?
            <div id="scouts" className="history detail">
                <h3>{ scout.created_at }</h3>
                <div>
                <div className="tag_box">
                    <ul>
                    {scout.recruitments && scout.recruitments.map((recruitment: any, index: any) => (
                        <li key={index}><a href="#">{ recruitment }</a></li>
                    ))}
                    </ul>
                </div>        
                <ul className="link">
                    <li><a href="#" onClick={() => searchCandidates('sent', scout.id)}>送付<span><span>{ scout.sent_count }</span>件</span></a></li>
                    <li><a href="#" onClick={() => searchCandidates('opened', scout.id)}>開封<span><span>{scout.opened_count}</span>件</span></a></li>
                </ul>
                </div>
                <p>{ scout.content }</p>
                <a href="/recruiter/scouts/history/" className="btn">履歴の一覧へ戻る</a>
            </div>
            : <></>}
        </section>
    </main>
    );
  }
  export default HistoryDetail;