import {adminCompanyDetailTypes } from "../../ActionTypes/adminCompanyDetailTypes";
import {  AdminCompanyDetailActions, AdminCompanyDetailState } from "../../types/type";


const initialState: AdminCompanyDetailState = {
  pending: false,
  company: {},
  purpose_of_postings: [],
  recruiters: [],
  recruitments: [],
  error: null,
};

export default (state = initialState, action: AdminCompanyDetailActions,) => {
  switch (action.type) {
    case adminCompanyDetailTypes.FETCH_ADMIN_COMPANY_DETAIL_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminCompanyDetailTypes.FETCH_ADMIN_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        pending: true,
        company: action.payload.company,
        purpose_of_postings: action.payload.purpose_of_postings,
        recruiters: action.payload.recruiters,
        recruitments: action.payload.recruitments,
        error: null
      };
    case adminCompanyDetailTypes.FETCH_ADMIN_COMPANY_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        company: {},
        purpose_of_postings: [],
        recruiters: [],
        recruitments: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
