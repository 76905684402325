import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { fetchApplicantsRequest } from "../../redax/actions/applicantsActions/applicantsActions";
import { fetchRecruitmentsDetailRequest } from "../../redax/actions/recruitmentsDetailActions/recruitmentsDetailActions";
import { RootState } from "../../redax/reducers/rootReducer";
import { fetchChangeUpdateApplicantsRequest } from "../../redax/actions/changeUpdateApplicantsActions/changeUpdateApplicantsActions";
import { useNavigate } from 'react-router-dom';

function RecruimentsComplete() {
    const recruitmentsDetail = useSelector((state: RootState) => state.recruitmentsDetail);
    const loginData = useSelector((state: RootState) => state.login);
    const { id } = useParams();
    const [resourceId, setResourceId] = useState(null);
    const dispatch = useDispatch();
    const [company_name, setCompany_name] = useState();
    const [job_title, setJob_title] = useState();
    const [appeal_items, setAppealItems] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useState({});
    const [applicantAppealItems, setApplicantAppealItems] = useState({});
    const [recruitmentAppealItems, setRecruitmentAppealItems] = useState({});
    const [firstLoad, setFirstLoad] = useState(false);
    const location = useLocation();
    const changeUpdate = useSelector((state: RootState) => state.changeupdateapplicants);
    const state = location.state;
    const navigate = useNavigate(); 
    
    const [showAppealItems, setShowAppealItems] = useState<any>([
      false, false, false, false, false, false, false
    ]);
    const [selectedAppealItems, setSelectedAppealItems] = useState([
      false, false, false, false, false, false, false
    ]);
    const [isShowAppealItems, setIsShowAppealItems] = useState(false);

    const queryParamsAll = new URLSearchParams(window.location.search);

    const { appeal, appealItems1, appealItems2, appealItems3, appealItems4, appealItems5, appealItems6, appealItems7 } = state;

    useEffect(() => {
        if (loginData.pending) {
          dispatch(fetchApplicantsRequest(loginData.login.resource.id, 'get', 0));
          setResourceId(loginData.login.resource.id);
          dispatch(fetchRecruitmentsDetailRequest(id));
          const applicant2 = {
              applicant : {
                job_career_appeals: appealItems1,
                experience_job_category: appealItems2,
                oa_skills: appealItems3,
                qualification:appealItems4,
                language_study: appealItems5,
                employment_support_agency: appealItems6,
                self_pr: appealItems7,
              }
          };
          setAppealItems([1,2,3,4,5,6,7])
        }
    }, [loginData.pending, dispatch]);

    useEffect(() => {
        if (recruitmentsDetail.pending) {
          setCompany_name(recruitmentsDetail.recruitmentsDetail.recruitment.company);
          setJob_title(recruitmentsDetail.recruitmentsDetail.recruitment.job_title);
        }
      }, [recruitmentsDetail.pending]);

    useEffect(() => {

      if (appeal === undefined) return;
  
      const selectedItemArray = [{}];
  
      if (appeal[0] !== null) selectedItemArray.push(Number(appeal[0]));
      if (appeal[1] !== null) selectedItemArray.push(Number(appeal[1]));
      if (appeal[2] !== null) selectedItemArray.push(Number(appeal[2]));
      if (appeal[3] !== null) selectedItemArray.push(appeal[3]);
      if (appeal[4] !== null) selectedItemArray.push(appeal[4]);
      if (appeal[5] !== null) selectedItemArray.push(appeal[5]);
      
      let c = [];
      for(let i = 0; i < showAppealItems.length; i++) {
        c.push(showAppealItems[i]);
      }
      if (selectedItemArray.includes(1) && appealItems1.length > 0) {
        c[0] = true;
        setSelectedAppealItems(prevItems => [...prevItems.slice(0, 0), true, ...prevItems.slice(1)]);
        setIsShowAppealItems(true);
      }
      if (selectedItemArray.includes(2) && (
        appealItems2.job_category_id_1 !== null && appealItems2.job_category_id_1 !== '' || 
        appealItems2.job_category_id_2 !== null && appealItems2.job_category_id_2 !== '' || 
        appealItems2.job_category_id_3 !== null && appealItems2.job_category_id_3 !== '')) {
        c[1] = true;
        setSelectedAppealItems(prevItems => [...prevItems.slice(0, 1), true, ...prevItems.slice(2)]);
        setIsShowAppealItems(true);
      }
      if (selectedItemArray.includes(3) && (
        appealItems3.word_experience == true || 
        appealItems3.excel_experience == true || 
        appealItems3.design.length > 0 || 
        appealItems3.program.length > 0 || 
        appealItems3.word.length > 0 || 
        appealItems3.excel.length > 0)) {
        c[2] = true;
        setSelectedAppealItems(prevItems => [...prevItems.slice(0, 2), true, ...prevItems.slice(3)]);
        setIsShowAppealItems(true);
      }
      if (selectedItemArray.includes(4) && (
        appealItems4.qualification_id_1 !== null && appealItems4.qualification_id_1 !== '' && appealItems4.qualification_id_1.length !== 0 || 
        appealItems4.qualification_id_2 !== null && appealItems4.qualification_id_2 !== '' && appealItems4.qualification_id_2.length !== 0 || 
        appealItems4.qualification_3 !== null && appealItems4.qualification_3 !== '' && appealItems4.qualification_3 != undefined)) {
        c[3] = true;
        setSelectedAppealItems(prevItems => [...prevItems.slice(0, 3), true, ...prevItems.slice(4)]);
        setIsShowAppealItems(true);
      }
      if (selectedItemArray.includes(5) && (
        appealItems5.language_id_1 !== null && appealItems5.language_id_1 !== '' && appealItems5.language_id_1.length !== 0 || 
        appealItems5.language_id_2 !== null && appealItems5.language_id_2 !== '' && appealItems5.language_id_2.length !== 0 || 
        appealItems5.language_3 != null && appealItems5.language_3 !== '' && appealItems5.language_3 != undefined || 
        appealItems5.level_id_1 !== null && appealItems5.level_id_1 !== '' && appealItems5.level_id_1.length !== 0 || 
        appealItems5.level_id_2 !== null && appealItems5.level_id_2 !== '' && appealItems5.level_id_2.length !== 0)) {
        c[4] = true;
        setSelectedAppealItems(prevItems => [...prevItems.slice(0, 4), true, ...prevItems.slice(5)]);
        setIsShowAppealItems(true);
      }
      if (selectedItemArray.includes(6) && (
        appealItems6.organization !== null && appealItems6.organization !== '' || 
        appealItems6.url !== null && appealItems6.url !== '' || 
        appealItems6.contact_number !== null && appealItems6.contact_number !== ''|| 
        appealItems6.start_date !== null && appealItems6.start_date !== '' || 
        appealItems6.end_date !== null && appealItems6.end_date !== '' || 
        appealItems6.training_content !== null && appealItems6.training_content !== '')) {
        c[5] = true;
        setSelectedAppealItems(prevItems => [...prevItems.slice(0, 5), true, ...prevItems.slice(6)]);
        setIsShowAppealItems(true);
      }
      if (selectedItemArray.includes(7) && appealItems7.content !== null && appealItems7.content !== '') {
        c[6] = true;
        setSelectedAppealItems(prevItems => [...prevItems.slice(0, 6), true]);
        setIsShowAppealItems(true);
      }
      setShowAppealItems(c);
  
    }, [applicantAppealItems, recruitmentAppealItems]);

    function handleCheck(e: any, index: any) {
      const updatedSelectedAppealItems = [...selectedAppealItems];
      updatedSelectedAppealItems[index] = e.target.checked;
      setSelectedAppealItems(updatedSelectedAppealItems);
    }
    
      const submit = async () => {
        if (selectedAppealItems.includes(true) == false) {
          window.alert("反映するアピール項目を選んでください")
          return;
        }
        
        const post_data: { applicant: any } = {
          applicant: {
            job_career_appeals: null,
            experience_job_category: null,
            oa_skills: null,
            qualification:null,
            language_study: null,
            employment_support_agency: null,
            self_pr: null,
          }
        };

        if (selectedAppealItems[0]) {
          post_data.applicant.job_career_appeals = appealItems1;
        } else {
          delete post_data.applicant['job_career_appeals']
        }
        if (selectedAppealItems[1]) {
          post_data.applicant.experience_job_category = appealItems2;
        } else {
          delete post_data.applicant['experience_job_category']
        }
        if (selectedAppealItems[2]) {
          post_data.applicant.oa_skills = appealItems3;
        } else {
          delete post_data.applicant['oa_skills']
        }
        if (selectedAppealItems[3]) {
          post_data.applicant.qualification = appealItems4;
        } else {
          delete post_data.applicant['qualification']
        }
        if (selectedAppealItems[4]) {
          post_data.applicant.language_study = appealItems5;
        } else {
          delete post_data.applicant['language_study']
        }
        if (selectedAppealItems[5]) {
          post_data.applicant.employment_support_agency = appealItems6;
        } else {
          delete post_data.applicant['employment_support_agency']
        }
        if (selectedAppealItems[6]) {
          post_data.applicant.self_pr = appealItems7;
        } else {
          delete post_data.applicant['self_pr']
        }
        setFirstLoad(true);        
        dispatch(fetchChangeUpdateApplicantsRequest(resourceId, post_data.applicant));        
      };

    useEffect(() => {
      if (changeUpdate.pending && firstLoad) {
        window.alert("プロフィールに反映しました")
        navigate('/recruitments/?'+ queryParamsAll);
      } 
    }, [changeUpdate.pending,]);

    return (
        <main id="jobs">
        <section className="entry complete wrapper">
          <h2 className="ttl type3">エントリー（完了）</h2>
          <p>{company_name}「{job_title}」の<br className="sp_only" />求人へのエントリーが完了いたしました。</p>
          {isShowAppealItems ? 
            <>
              <p>
                下記の「反映する」ボタンを押して送信したエントリー項目をプロフィールに保存出来ます。<br />
                <span>※反映したくない項目についてはチェックを外してから「反映する」を押してください。</span>
              </p>
              <div className="complete_cont">
                <div>
                  {appeal_items.map((data, index) =>
                    showAppealItems[index] ? (
                      <label key={data.id}>
                        <input
                          type="checkbox"
                          name="entry"
                          value={data.id}
                          checked={selectedAppealItems[index]}
                          onChange={(e) => {
                            handleCheck(e, index);
                          }}
                        />
                        {showAppealItems[0] && index == 0 && 'アピールしたい職歴' ||
                        showAppealItems[1] && index == 1 && 'アピールしたい経験職種' ||
                        showAppealItems[2] && index == 2 && 'アピールしたいPCスキル' ||
                        showAppealItems[3] && index == 3 && 'アピールしたい資格' ||
                        showAppealItems[4] && index == 4 && 'アピールしたい語学' ||
                        showAppealItems[5] && index == 5 && '就労支援機関' ||
                        showAppealItems[6] && index == 6 && '自己PR' }
                      </label>
                    ) : null
                  )}
                </div>
                <p className="btn">
                  <a className="type3" style={{ cursor: 'pointer' }} onClick={submit}>
                    反映する
                  </a>
                </p>
              </div>
            </>
          : 
            <div>
              <h3>続けて、他の求人も探してみましょう！</h3>
              <p className="btn">
                <a href="/recruitments/">お仕事検索する</a>
              </p>
            </div>
          }
          <p className="btn">
            <a href="/mypage/">マイページへ</a>
          </p>
        </section>
      </main>
    );
  }
  export default RecruimentsComplete;