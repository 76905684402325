
import { columnFetchListTypes } from "../../ActionTypes/columnFetchListTypes";
import { ColumnFetchListActions, ColumnFetchListState } from "../../types/type";


const initialState: ColumnFetchListState = {
  pending: false,
  columnList: [],
  error: null,
};

export default (state = initialState, action: ColumnFetchListActions,) => {
  switch (action.type) {
    case columnFetchListTypes.FETCH_COLUMN_FETCH_LIST_REQUEST:
      return {
        ...state,
        pending: false
      };
    case columnFetchListTypes.FETCH_COLUMN_FETCH_LIST_SUCCESS:
      return {
        ...state,
        pending: true,
        columnList: action.payload.columnList,
        error: null
      };
    case columnFetchListTypes.FETCH_COLUMN_FETCH_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        columnList: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
