import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, CSSProperties  } from 'react';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchScoutsCompanyRequest } from '../../../redax/actions/scoutsCompanyActions/scoutsCompanyActions.';
import '../../../styles/style.css';
import './scouts.scss';
import Loader from '../../loader/loader';

export default function ScoutsPage() {
    let search_criteria = [];
    let recruitmentsLists = [];
    let param;
    let param1;
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [scoutsData, setScoutsData] = useState<any>([]);
    const [companies, setCompanies] = useState<any>([]);
    const [oldCompanies, setOldCompanies] = useState<any>([]);
    const [pagenation, setPagenation] = useState<any>([]);
    const [pages, setPages] = useState<any>(null);
    const [page, setPage] = useState(1);
    const itemsPerPage = 1;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const loginData = useSelector((state: RootState) => state.login);  
    const scoutsCompany = useSelector((state: RootState) => state.scoutsCompany);
    const [loadScreen, setLoadScreen] = useState(true);

    useEffect(() => {
        // dispatch(fetchLoginRequest('', '', ''));
        param = '/scouts/company?page=' + page 
        dispatch(fetchScoutsCompanyRequest(param));
    }, [dispatch]);

    useEffect(() => {
        if (!localStorage.getItem('jobParachannelAuthToken')) {
          navigate('/login');
        } 
      }, [navigate, dispatch]);

    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/mypage/init/');
            }
        }
    }, [loginData.pending]);

    useEffect(() => {
        if(scoutsCompany.pending) {
            setScoutsData(scoutsCompany);
            setCompanies(scoutsCompany.scoutsCompany.companies);
            setPages(scoutsCompany.scoutsCompany.pagenation.total_pages);
            setLoadScreen(false);
        } else if(scoutsCompany.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [scoutsCompany]);
    

    useEffect(() => {
        let tmpe = [];
        if(scoutsCompany.pending && loginData.pending) {
           if(!loginData.login.is_active && companies) {
                for(let i = 0; i< companies.length; i++) {
                    if(!companies[i].unread_scout){
                        tmpe.push(companies[i]);
                    }
                }
           }
        }
        setOldCompanies(tmpe);
        
    }, [scoutsCompany,loginData]);

    function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
        }
    }

    const pageIncrement = () => {
      if (page >= pages) {
        setPage(pages);
        return;
      }
      setPage((prevPage) => prevPage + 1);
    }
  
    const pageDecrement = () => {
      if (page <= 1) {
        setPage(1);
        return;
      }
      setPage((prevPage) => prevPage - 1);
    }
  
    const updatePage = (p: any) => {
      setPage(p);
    }
    
    return (
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className="bread_crumb">
                <li>
                <a href="/">トップ</a>
                </li>
                <li>
                <a href="#">マイページ</a>
                </li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">あなたに興味がある企業</h2>
                <ul>
                <li>
                    <a href="#" onClick={logout}>
                    <img src="/assets/img/company/icon02.svg"  alt=""/>ログアウト </a>
                </li>
                </ul>
            </div>
            <div id="scouts" className="mypage top">
                { companies !== null ?
                    <>
                    {loginData.login.is_active ?
                    <ul>
                        {companies && companies.map((company: any ) => (
                            <li className={ company.unread_scout ? 'online': '' }>
                                <a href={'/mypage/scouts/company/' + company.id} className="ttl active">
                                    <div className='img_box'>
                                        <img src={company.logo == '' ? '/assets/img/company/img02.jpg' : company.logo} alt={company.name}/>
                                    </div>
                                    <div className="text_box">
                                        <div>
                                            <h3>{ company.name } からスカウトが届きました</h3>
                                            <p>{ company.scout.created_at }</p>
                                        </div>
                                        <p>{ company.scout.content }</p>
                                        <ul className="clip">
                                            {company.recruitments && company.recruitments.map((recruitment: any, i: any ) => (
                                                <li key={i}>
                                                    { recruitment.job_title }
                                                </li>
                                            ))} 
                                        </ul>
                                    </div>
                                </a>
                            </li>
                        ))} 
                    </ul>
                    :
                    <ul>
                        {oldCompanies && oldCompanies.map((company: any ) => (
                            <li>
                                <a href={'/mypage/scouts/company/' + company.id} className="ttl active">
                                    <div className='img_box'>
                                        <img src={company.logo == '' ? '/assets/img/company/img02.jpg' : company.logo} alt={company.name}/>
                                    </div>
                                    <div className="text_box">
                                        <div>
                                            <h3>{ company.name } からスカウトが届きました</h3>
                                            <p>{ company.scout.created_at }</p>
                                        </div>
                                        <p>{ company.scout.content }</p>
                                        <ul className="clip">
                                            {company.recruitments && company.recruitments.map((recruitment: any, i: any ) => (
                                                <li key={i}>
                                                    { recruitment.job_title }
                                                </li>
                                            ))} 
                                        </ul>
                                    </div>
                                </a>
                            </li>
                        ))} 
                    </ul>
                    }
                        
                    </>
                        
                        
                :<></>}
                {/* {pages > 1 ?  */}
                <div className="bottom">
                    <div className="icon">
                    </div>
                    <ul className="pagination">
                        <li className={`arrow prev ${page <= 1 ? 'disabled' : ''}`}>
                            <a onClick={pageDecrement}><img src="/assets/img/company/icon06.svg" alt="次へ"/></a>
                        </li>
                        {Array.from({ length: pages }, (_, i) => i + 1).map((p) => (
                            <li key={p} className={`page ${page === p ? 'active' : ''}`}>
                            <a href="#" onClick={() => updatePage(p)}>
                                {p}
                            </a>
                            </li>
                        ))}
                        <li className={`arrow next ${page >= pages ? 'disabled' : ''}`}>
                            <a href="#" onClick={pageIncrement}><img src="/assets/img/company/icon07.svg" alt="前へ"/></a>
                        </li>
                    </ul>
                </div>
                  {/* : <></> } */}
                {companies === undefined && companies === null  ?
                    <p>スカウトはありません</p>
                :<></>}
            </div>
        </section>
      </main>
    );
}