import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import AdminNav from '../../adminTop/AdminNav';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchRecruiterPlanRequest } from '../../../redax/actions/recruiterPlanActions/recruiterPlanActions';
import { fetchRecruiterPricingRequest } from '../../../redax/actions/recruiterPricingActions/recruiterPricingActions';
import { fetchRecruiterPlanPostRequest } from '../../../redax/actions/recruiterPlanPostActions/recruiterPlanPostActions';
import { fetchRecruiterReceiptPostRequest } from '../../../redax/actions/recruiterReceiptPostActions/recruiterReceiptPostActions';

var isCheckPlanName = false;
var isHide = false;
const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 2;
const day = 1;
const YYYY = year + '年' + month + '月' + day + '日';
let total = 0;
let nextTotal = 0;
const RecruiterPlanConfirm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const post = useSelector((state:RootState) => state.recruiterPlanPost);
  const recruiterPlan = useSelector((state: RootState) => state.recruiterPlan);
  const pricing = useSelector((state: RootState) => state.recruiterPricing);
  const loginData = useSelector((state: RootState) => state.login);
  const receiptInfo = useSelector((state: RootState) => state.receiptInfo);
  // const [plan, setPlan] = useState();
  const [companyId, setCompanyId] = useState();
  const [planName, setPlanName] = useState();
  const [planPrice, setPlanPrice] = useState();
  const [nextContractPlanName, setNextContractPlanName] = useState();
  const [nextContractPlanPrice, setNextContractPlanPrice] = useState();
  const [currenPlanPrice, setcurrenPlanPrice] = useState<any>();
  const [currentPlanId, setCurrentPlanId] = useState();
  const [filteredPrice, setFilteredPrice] = useState();
  const [planList, setPlanList] = useState(Boolean);
  const [optionName1, setOptionName1] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [nextTotalPrice, setNextTotalPrice] = useState(0);
  const [currentDrOption, setCurrentDrOption] = useState(false);
  const [currentWarrantyOption, setCurrentWarrantyOption] = useState(false);
  const [planListsName, setPlanListsName] = useState();
  const [planListsName1, setPlanListsName1] = useState();
  const [planListsPrice, setPlanListsPrice] = useState<any>();
  const [planListsPrice1, setPlanListsPrice1] = useState<any>();
  const [nextPlanListsPrice, setNextPlanListsPrice] = useState<any>();
  const [nextPlanListsPrice1, setNextPlanListsPrice1] = useState<any>();
  const [nextPlanListsName, setNextPlanListsName] = useState();
  const [nextPlanListsName1, setNextPlanListsName1] = useState();
  const [sName, setSName] = useState();
  const [sPrice, setSPrice] = useState<any>();
  const [monthDirectScoutLimit, setMonthDirectScoutLimit] = useState();
  const location = useLocation();
  const dataParam = location.state;
  const [sumPrice, setSumPrice] = useState(0);
  const [option1, setOptions1] = useState(0);
  const [option2, setOptions2] = useState(0);
  const [receiptAddress, setReceiptAddress] = useState<any>();
  const [receiptAddressTwo, setReceiptAddressTwo] = useState<any>();
  const [isChangedReceipt, setIsChangedReceipt] = useState(false);
  const [loginInfo, setLoginInfo] = useState<any>([]);
    
  useEffect(() => {
    if (loginData.pending) {
      setLoginInfo(loginData.login);
      setReceiptAddress(loginData.login.receipt_address);
      setReceiptAddressTwo(loginData.login.receipt_address2);
    }
  }, [loginData.pending]);

  useEffect(() => {
    if (localStorage.getItem('jobParachannelAuthToken')) {
      // dispatch(fetchLoginRequest('', '',''))
      dispatch(fetchRecruiterPlanRequest());
      dispatch(fetchRecruiterPricingRequest());
    } else (
        navigate('/recruiter/login/')
    )
  }, [dispatch]);

    
  useEffect(() => {
    total = 0;
    nextTotal = 0;
    if(dataParam == undefined){
      window.location.href = ('/recruiter/plandetail');
    }
    if(recruiterPlan !== undefined && recruiterPlan.pending && pricing.pending){
      setCompanyId(recruiterPlan.recruiterPlan.company_id);
      var currentName = recruiterPlan.recruiterPlan.current_plan_name;
      if(currentName == "無料" || currentName == "無料プラン") {
        isCheckPlanName = true;
        isHide = false;
      } else {
        isCheckPlanName = false;
        isHide = true;
      }
      const currentPlanId = recruiterPlan.recruiterPlan.current_plan_id;
      setCurrentDrOption(recruiterPlan.recruiterPlan.current_dr_option);
      setCurrentWarrantyOption(recruiterPlan.recruiterPlan.current_warranty_option);
      const currentPlan = recruiterPlan.recruiterPlan.plan_lists.find((plan:any) => plan.id === currentPlanId);
      const paramId = recruiterPlan.recruiterPlan.plan_lists.find((plan:any) => plan.id === dataParam.id);
      const nextContractPlan = recruiterPlan.recruiterPlan.next_contract;
      const nextContractPlanId = pricing.recruiterPricing.plan_lists.find((plan:any) => plan.id === dataParam.id);
      const optionName = pricing.recruiterPricing.plan_lists.find((plan:any) => plan.id === dataParam.id);
      const planList = pricing.recruiterPricing.plan_lists; 
      if(nextContractPlanId && nextContractPlanId.option != "" && nextContractPlanId.option.length > 0){
        setNextPlanListsName(nextContractPlanId.option[1].option_name);
        setNextPlanListsName1(nextContractPlanId.option[0].option_name);
        setNextPlanListsPrice(nextContractPlanId.option[1].price);
        setNextPlanListsPrice1(nextContractPlanId.option[0].price);
      };         
      if(paramId){
        setSName(paramId.name);
        setSPrice(paramId.price);
      } 
      if(optionName){
        if(optionName.option == ""){
          setPlanListsPrice(0);
          setPlanListsPrice1(0);
        } else {
          setPlanListsPrice(optionName.option[0].price);
          setPlanListsPrice1(optionName.option[1].price);
          setMonthDirectScoutLimit(optionName.option[1].month_direct_scout_limit);
          let a = optionName.option[0].option_name;
          let b = optionName.option[1].option_name;
          setPlanListsName(a);
          setPlanListsName1(b);
        }
      }
      if(optionName.option == ""){
        setTotalPrice(0);
      } else {
        if(recruiterPlan.recruiterPlan.current_dr_option && recruiterPlan.recruiterPlan.current_warranty_option ){
          setTotalPrice(optionName.option[0].price + optionName.option[1].price)
        } else if(recruiterPlan.recruiterPlan.current_dr_option && !recruiterPlan.recruiterPlan.current_warranty_option){
          setTotalPrice(optionName.option[0].price)
        } else if (!recruiterPlan.recruiterPlan.current_dr_option && recruiterPlan.recruiterPlan.current_warranty_option) {
          setTotalPrice(optionName.option[1].price)
        } else {
          setTotalPrice(0);
        }
      }

      if (currentPlan) {
        setPlanName(currentPlan.name);
        setPlanPrice(currentPlan.price);
      } else {
          // Handle the case where the current plan is not found
      }
      if(dataParam.ck1 && nextPlanListsPrice){
        setOptions2(nextPlanListsPrice);
      } 
      if(dataParam.ck2 && nextPlanListsPrice1){
        setOptions1(nextPlanListsPrice1);
      } 

      if (nextContractPlan) {
        nextTotal = 0;
        setNextContractPlanName(recruiterPlan.recruiterPlan.next_contract.plan_name);          
        setNextContractPlanPrice(recruiterPlan.recruiterPlan.next_contract.plan_price);
        for(let j = 0; j < planList.length; j++) {
          if(planList[j].id == recruiterPlan.recruiterPlan.next_contract.plan_id){
            if(recruiterPlan.recruiterPlan.next_contract.dr_option == true) {
              setNextTotalPrice(nextTotal += planList[j].option[0].price);
            }
            if(recruiterPlan.recruiterPlan.next_contract.warranty_option == true) {      
              setNextTotalPrice(nextTotal += planList[j].option[1].price);
            }
          }
        }
      }
      setcurrenPlanPrice(recruiterPlan.recruiterPlan.current_plan_price);
      setCurrentPlanId (recruiterPlan.recruiterPlan.current_plan_id);
      // Find the plan object whose id matches the current_plan_id
      const filteredPlan = recruiterPlan.recruiterPlan.plan_lists.find((plan:any) => plan.id === 1);
      // Retrieve the price from the filtered plan
      setFilteredPrice( filteredPlan ? filteredPlan.price : null );
      if(filteredPlan == undefined){
        setPlanList(false);
      } else {
        setPlanList(true);
      }
      if(optionName.option == ""){
        setTotalPrice(0);
      } else {
      if (optionName) {
        setTotalPrice(optionName.option[0].price + optionName.option[1].price);
      } else {
        // Handle the case where the current plan is not found
      }}
    }
  }, [recruiterPlan]);

  function logout() {
    if (localStorage.entryFlag) {
      localStorage.removeItem("entryFlag");
    }
    if (localStorage.jobParachannelAuthToken) {
      localStorage.removeItem("jobParachannelAuthToken");
      navigate("/");
      window.location.reload();
    }
  }

  const receipt_params = {
    "receipt_address": receiptAddress,
    "receipt_address2": receiptAddressTwo,
  }          
  
  const recruiterpost = {
    "plan_id": dataParam.id,
    "dr_option": dataParam.ck1,
    "warranty_option": dataParam.ck2,
    "receipt_address": receiptAddress,
    "receipt_address2": receiptAddressTwo,
  }

  function handlePost() {
    if (!receiptAddress || !receiptAddressTwo){
      alert('宛名を入力してください。')
      return 
    }
    if(window.confirm('プラン変更を完了してもよろしいですか？')) {
      dispatch(fetchRecruiterPlanPostRequest(recruiterpost)); 
      dispatch(fetchRecruiterReceiptPostRequest(receipt_params)); 
    }         
      // window.location.href = ('/recruiter/plan');
  }

  useEffect(() => {
    if(post.pending){
      window.location.href = ('/recruiter/changedplan');
    }
  }, [post]);  

  function changeReceiptAddress(e: any) {
    setReceiptAddress(e.target.value);
    if(loginInfo.receipt_address != null) {
      if(!loginInfo.receipt_address.includes(e.target.value)) {
        setIsChangedReceipt(true);
      } else {
        setIsChangedReceipt(false);
      }
    }
  }

  function changeReceiptAddressTwo(e: any) {
    setReceiptAddressTwo(e.target.value);  
    if(loginInfo.receipt_address2 != null) {
      if(!loginInfo.receipt_address2.includes(e.target.value)) {
        setIsChangedReceipt(true);
      } else {
        setIsChangedReceipt(false);
      }
    }
  }
  
  return (
    <main id='company'>
        <section className='sectionScouts' style={{width:"100%"}}>
          <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
            <ul className="bread_crumb">
              <li>
                <a href="/">トップ</a>
              </li>
              <li>
                <a href="/recruiter/">企業様マイページ</a>
              </li>
              <li>
                <a href="#">プラン管理</a>
              </li>
            </ul>
            <div className="ttl_box">
              <ul>
                <li>
                  <a href="#" onClick={logout}>
                    <img src="/assets/img/company/icon02.svg" alt="" />
                    ログアウト
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div style={{width:"100%",padding:"0px 4%"}}>
            <h2 className='adminH2' style={{margin:"0"}}>お支払い確認</h2>
            <hr style={{height:"2px", width:"100%", background:"#00A0E9"}}/>
            <br />
            <div style={{display:"flex"}}>
              <div style={{marginRight:"10px", width: "30%"}}>
                <p>(例)株式会社キャリアート</p>
                <input type="text" placeholder='請求書に印字する会社名等を入力' value={receiptAddress} style={{borderRadius:"5px", width:"100%", padding:"10px",}} name="receipt_address" onChange={changeReceiptAddress}/>
              </div>
              <div style={{width: "30%"}}>
                <p>(例)営業部　山田太郎</p>
                <input type="text" placeholder='請求書に印字する宛名を入力' value={receiptAddressTwo} style={{borderRadius:"5px", width:"100%", padding:"10px"}} name="receipt_address2" onChange={changeReceiptAddressTwo}/>
              </div>
            </div>
            <br />
            <table style={{width:"100%"}}>
                <tr>
                    <td style={{border:"solid 1px", width:"20%", padding:"20px"}}>ご利用内容</td>
                    <td style={{border:"solid 1px", padding:"20px"}}>{sName}</td>
                </tr>
                <tr>
                    <td style={{border:"solid 1px", width:"20%", padding:"20px"}}>基本料金</td>
                    <td style={{border:"solid 1px", padding:"20px"}}>¥{sPrice?.toLocaleString()}</td>
                </tr>
                {dataParam.ck2 == true && dataParam.id != 1 ? <tr>
                    <td style={{border:"solid 1px", width:"20%", padding:"20px"}}>{nextPlanListsName1}</td>
                    <td style={{border:"solid 1px", padding:"20px"}}>¥{nextPlanListsPrice1?.toLocaleString()}/月 (税別)</td>
                </tr> : ""}
                {dataParam.ck1 == true && dataParam.id != 1 ? <tr>
                    <td style={{border:"solid 1px", width:"20%", padding:"20px"}}>{nextPlanListsName}</td>
                    <td style={{border:"solid 1px", padding:"20px"}}>{`${monthDirectScoutLimit}件 ¥${nextPlanListsPrice?.toLocaleString()}/月 (税別)`}</td>
                </tr> : ""}
                <tr>
                    <td style={{border:"solid 1px", width:"20%", padding:"20px"}}>合計金額</td>
                    <td style={{border:"solid 1px", padding:"20px"}}>{ `￥${(sPrice + option1 + option2)?.toLocaleString()}` }</td>
                </tr>
            </table>
            <br />
            <p style={{color:"red"}}>※請求書が作成され毎月請求されます</p>
            <button className='scoutsBtn' style={{marginRight:"10px"}} onClick={handlePost}>プランを確定</button>
            <button className='scoutsBtn' style={{backgroundColor:"white" , color:"#00A0E9"}} onClick={()=>window.location.href = ('/recruiter/planpricing')}>戻る</button>
            <br />
            </div>
      </section>
    </main>
  )
}

export default RecruiterPlanConfirm;

