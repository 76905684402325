import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminApplicantsCsvTypes } from "../../ActionTypes/adminApplicantsCsvTypes";
import { fetchAdminApplicantsCsvFailure, fetchAdminApplicantsCsvSuccess } from "../../actions/adminApplicantsCsvActions/adminApplicantsCsvActions";

const getAdminApplicantsCsv = () => {
    return axios.get(API_BASE_ADDRESS + '/admin/applicants/csv', {
      headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthAdminToken }
    });
}

function* fetchAdminApplicantsSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminApplicantsCsv);
    yield put(
      fetchAdminApplicantsCsvSuccess({
        csv: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminApplicantsCsvFailure({
        error: e.message
      })
    );
  }
}

function* AdminApplicantsCsvSaga() {
  yield takeLatest(adminApplicantsCsvTypes.FETCH_ADMIN_APPLICANTS_CSV_REQUEST, fetchAdminApplicantsSaga);
}
export default AdminApplicantsCsvSaga;