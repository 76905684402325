import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchHelloFailure, fetchHelloSuccess } from "../../actions/helloActions/helloActions";
import { helloTypes } from "../../ActionTypes/helloTypes";

const getHello = (data: any) =>
  axios.get(API_BASE_ADDRESS + '/recruitments/hello_work.json?number=' + data,  {
    headers: { Accept: 'application/json', 'Authorization': localStorage.jobParachannelAuthToken }
  });

function* fetchHelloSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getHello, action.data);
    yield put(
      fetchHelloSuccess({
        hello: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchHelloFailure({
        error: e.message
      })
    );
  }
}

function* HelloSaga() {
  yield takeLatest(helloTypes.FETCH_HELLO_REQUEST, fetchHelloSaga);
}
export default HelloSaga;