import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchPasswordResetFailure, fetchPasswordResetSuccess } from "../../actions/passwordResetActions/passwordResetActions";
import { passwordResetTypes } from "../../ActionTypes/passwordResetTypes";


const getPassword = (email: any, resource_type: any) =>
  axios.post(API_BASE_ADDRESS + '/password_reset/send' , { email: email, resource_type: resource_type},  {
    headers: { Accept: 'application/json' }
  });

function* fetchApplicantSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getPassword, action.email, action.resource_type);
    yield put(
      fetchPasswordResetSuccess({
        password: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchPasswordResetFailure({
        error: e.message
      })
    );
  }
}

function* PasswordResetSaga() {
  yield takeLatest(passwordResetTypes.FETCH_PASSWORD_RESET_REQUEST, fetchApplicantSaga);
}
export default PasswordResetSaga;