import { adminApplicantsDetailTypes } from "../../ActionTypes/adminApplicantsDetailTypes";
import { AdminApplicantsDetailActions, AdminApplicantsDetailState } from "../../types/type";


const initialState: AdminApplicantsDetailState = {
  pending: false,
  applicant: [],
  error: null,
};

export default (state = initialState, action: AdminApplicantsDetailActions,) => {
  switch (action.type) {
    case adminApplicantsDetailTypes.FETCH_ADMIN_APPLICANTS_DETAIL_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminApplicantsDetailTypes.FETCH_ADMIN_APPLICANTS_DETAIL_SUCCESS:
      return {
        ...state,
        pending: true,
        applicant: action.payload.applicant,
        error: null
      };
    case adminApplicantsDetailTypes.FETCH_ADMIN_APPLICANTS_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        applicant: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
