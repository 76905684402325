
import { recruitersTypes } from "../../ActionTypes/recruitersTypes";
import {
  FetchRecruitersFailure,
  FetchRecruitersFailurePayload,
  FetchRecruitersRequest,
  FetchRecruitersSuccess,
  FetchRecruitersSuccessPayload,
} from "../../types/type";

export const fetchRecruitersRequest = (id: any, types: any, sid: any): FetchRecruitersRequest => ({
  type: recruitersTypes.FETCH_RECRUITERS_REQUEST,
  id,
  types,
  sid
});

export const fetchRecruitersSuccess = (
  payload: FetchRecruitersSuccessPayload,
): FetchRecruitersSuccess => ({
  type: recruitersTypes.FETCH_RECRUITERS_SUCCESS,
  payload
});

export const fetchRecruitersFailure = (
  payload: FetchRecruitersFailurePayload
): FetchRecruitersFailure => ({
  type: recruitersTypes.FETCH_RECRUITERS_FAILURE,
  payload
});
