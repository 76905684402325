
import { recruitmentsJobsTypes } from "../../ActionTypes/recruitmentsJobsTypes";
import { RecruitmentsJobsActions, RecruitmentsJobsState,} from "../../types/type";

const initialState: RecruitmentsJobsState = {
  pending: false,
  recruitments: [],
  error: null,
};

export default (state = initialState, action: RecruitmentsJobsActions,) => {
  switch (action.type) {
    case recruitmentsJobsTypes.FETCH_RECRUIMENTS_JOBS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case recruitmentsJobsTypes.FETCH_RECRUIMENTS_JOBS_SUCCESS:
      return {
        ...state,
        pending: true,
        recruitments: action.payload.recruitments,
        error: null
      };
    case recruitmentsJobsTypes.FETCH_RECRUIMENTS_JOBS_FAILURE:
      return {
        ...state,
        pending: false,
        recruitments: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
