import { adminScoutsDetailTypes } from "../../ActionTypes/adminScoutsDetailTypes";
import { FetchAdminScoutsDetailFailure, FetchAdminScoutsDetailFailurePayload, FetchAdminScoutsDetailRequest, FetchAdminScoutsDetailSuccess, FetchAdminScoutsDetailSuccessPayload } from "../../types/type";


export const fetchAdminScoutsDetailRequest = (id: any): FetchAdminScoutsDetailRequest => ({
  type: adminScoutsDetailTypes.FETCH_ADMIN_SCOUTS_DETAIL_REQUEST,
  id
});

export const fetchAdminScoutsDetailSuccess = (
  payload: FetchAdminScoutsDetailSuccessPayload
): FetchAdminScoutsDetailSuccess => ({
  type: adminScoutsDetailTypes.FETCH_ADMIN_SCOUTS_DETAIL_SUCCESS,
  payload
});

export const fetchAdminScoutsDetailFailure = (
  payload: FetchAdminScoutsDetailFailurePayload
): FetchAdminScoutsDetailFailure => ({
  type: adminScoutsDetailTypes.FETCH_ADMIN_SCOUTS_DETAIL_FAILURE,
  payload
});