import React, { useEffect, useState } from 'react'
import './admin.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchLoginRequest } from '../../redax/actions/loginActions/loginActions';
import { RootState } from '../../redax/reducers/rootReducer';

const AdminLogin = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginData = useSelector((state: RootState) => state.login);
  const pageTitle = 'ログイン（企業）｜パラちゃんねる';
  document.title = pageTitle;
  
  useEffect(() => {
    if(load) {
      dispatch(fetchLoginRequest(email, password, 'Administrator'));
      setLoad(false);
    }
    if (loginData != undefined && loginData.pending) {
      if(localStorage.getItem('jobParachannelAuthAdminToken')) {
        window.location.href = '/admin/';
      } else {
        setError(true);
      }
    } else if (loginData !== undefined && loginData.error) {
      setError(true);
    }
  }, [dispatch, load, loginData]);
  
  function login(e: any) {
    if (/^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email!)) {
      setLoad(true);
      e.preventDefault();
    } else {
        setError(true);
    }
  }
  return (
    <body className='adminBody'>
      <div className='inBody'>
        <form id='loginForm'>
          <h1 id='adminH1'>管理者ログイン</h1>
          <h5 id='adminH5'>User ID</h5>
          <input 
            type="email"
            title="メールアドレス"
            placeholder='User ID'
            name="email"
            v-model="email"
            value={email || ''}
            onChange={(e) => {setError(false);setEmail(e.target.value)}}/>
          <h5 id='adminH5'>Password</h5>
          <input type="password" name="password" value={password || ''} onChange={(e) => {setError(false);setPassword(e.target.value)}} placeholder='Password'/>
          <br />
          {error ? <p v-show="error" style={{color:"red"}}>ログインできませんでした。<br/>入力内容に誤りがないかご確認ください。</p>: <></>}
          {/* <input onClick={(e) => login(e)} type="button" value="ログイン" id='adminBtn'/> */}
          <input onClick={(e) => login(e)} type="button" value="ログイン" id='adminBtn'/>
        </form>
      </div>
    </body>
  )
}

export default AdminLogin

