
import { regionsTypes } from "../../ActionTypes/regionsTypes";
import {  RegionsActions, RegionsState } from "../../types/type";

const initialState: RegionsState = {
  pending: false,
  regions: [],
  error: null,
};

export default (state = initialState, action: RegionsActions,) => {
  switch (action.type) {
    case regionsTypes.FETCH_REGIONS_REQUEST:
      return {
        ...state,
        pending: false
      };
    case regionsTypes.FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        pending: true,
        regions: action.payload.regions,
        error: null
      };
    case regionsTypes.FETCH_REGIONS_FAILURE:
      return {
        ...state,
        pending: false,
        regions: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
