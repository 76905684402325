import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { columnFetchListRequest } from "../../redax/actions/columnFetchList/columnFetchListActions";
import { RootState } from "../../redax/reducers/rootReducer";


function OtherColumnsOfCompany(props: { company: any; showMoreBtn: any}) {
    let company: any = [];
    let company_id: any;
    let articles: any[] = [];
    let showMoreBtn = false;
    let title = 'この企業のコラム';
    let page = 1;
    let pages = 1;
    if(props) {
        company  = props.company;
        company_id =company.id;
        showMoreBtn = props.showMoreBtn;
    }
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(columnFetchListRequest(company.id));
      }, [dispatch, company.id]);

      const { columnList } = useSelector(
        (state: RootState) => state.columnList
      );
      if(columnList.articles !== undefined){
        articles = columnList.articles;
      }


      function dispFormattedDate( index: any) {
        for(let i = 0; i < articles.length; i++){
            if(articles[i].id === index){
                const dt = new Date(articles[i].release_date);
                return !dt ? '' : `
                ${dt.getFullYear()}年
                ${dt.getMonth()+1}月
                ${dt.getDate()}日
              `.replace(/\n|\r/g, '');
            }
        }
      }

      function pageDecriment(){
        if (page >= pages) {
          page = pages;
          return;
        }
        page += 1;
      }

      function pageIncriment(){
        if (page <= 1) {
          page = 1;
          return;
        }
        page -= 1;
      }
    // const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    // queryParams.set('consideration_conditions', 'true');
    return (
        <div>
          {articles.length > 0 ? (
            <div className = "column_list">
              <h3>{showMoreBtn ? title : company.name + 'のコラム一覧'}</h3>
              <ul>
                  {articles && articles.map((data: any, index: any) => (
                      <li key={index}>
                          <a href={'/columns/' + data.id} >
                              {data.eye_catch ?
                              <div className="img_box">
                                  <img src={data.eye_catch} alt="" />
                              </div>
                              : <></>}
                              <div className="txt_box">
                                  <p className="date">{dispFormattedDate(data.id)}</p>
                                  <p className="column_ttl">{data.title}</p>
                              </div>
                          </a>
                      </li>
                  ))}
              </ul>
              {showMoreBtn ?
              <div className="btn_area">
                  <a className="btn type3 back" href={'/companies/' + company_id}>
                      もっと見る
                  </a>
              </div> : null}
          </div>
          ) : null}
          {!showMoreBtn ?
          <ul className="pagination_circle">
            <li className={"prev " + (page <= 1 ? "disabled" : "")}>
              <a href="#" onClick={pageDecriment}>前へ</a>
            </li>
            <li className="active">
              <a href="#">
                <span>1</span>
              </a>
            </li>
            <li className={"next " + (page >= 1 ? "disabled" : "")}>
              <a href="#" onClick={pageIncriment}>次へ</a>
            </li>
          </ul> : null}
        </div>
    );
  }
  
  export default OtherColumnsOfCompany;

