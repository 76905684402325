import React, { useState, useEffect } from "react";
import './recruitmentsentry.scss'
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginRequest } from "../../redax/actions/loginActions/loginActions";
import { RootState } from "../../redax/reducers/rootReducer";
import { fetchApplicantsRequest } from "../../redax/actions/applicantsActions/applicantsActions";
import { fetchRegionsRequest } from "../../redax/actions/regionsActions/regionsActions";
import { useLocation, useParams } from "react-router-dom";
import { fetchRecruitmentsDetailRequest } from "../../redax/actions/recruitmentsDetailActions/recruitmentsDetailActions";
import { useNavigate } from 'react-router-dom';
import { fetchHandicapsRequest } from "../../redax/actions/handicapsActions/handicapsActions";
import { fetchApplicationCreateRequest } from "../../redax/actions/applicationsCreateActions/applicationCreateActions";
import Loader from "../loader/loader";

function RecruimentsConfirm() {
  const [applicant, setApplicants] = useState<any>([]);  
  const changeUpdate = useSelector((state: RootState) => state.applicationsCreate);
  const [word_experience, setWordExperience] = useState(false);
  const [excel_experience, setExcelExperience] = useState(false);
  const dispatch = useDispatch();
  const loginData = useSelector((state: RootState) => state.login);
  const applicants = useSelector((state: RootState) => state.applicants);
  const regions = useSelector((state: RootState) => state.regions);
  const recruitmentsDetail = useSelector((state: RootState) => state.recruitmentsDetail);
  const { id } = useParams();
  const [company_name, setCompany_name] = useState();
  const [job_title, setJob_title] = useState();
  const [family_name, setFamily_name] = useState();
  const [first_name, setFirst_name] = useState();
  const [family_name_kana, setFamily_name_kana] = useState();
  const [first_name_kana, setFirst_name_kana] = useState();
  const [birthday, setBirthday] = useState<any>();
  const [postcode, setPostcode] = useState();
  const [graduation_date, setGraduationDate] = useState<string>();
  const [support_agency, setSupportAgency] = useState();
  const [new_graduate, setNewGraduate] = useState();
  const [career_count_id, setCareerCountId] = useState();
  const [company, setCompany] = useState();
  const [industry_id, setIndustryId] = useState();
  const [job_category_id, setJobCategoryid] = useState();
  const [arrival_date, setArrivalDate] = useState<string>();
  const [deprature_date, setDepratureDate] = useState<string>();
  const [special_content, setSpecialContent] = useState();
  const [compatibilities, setCompatibilities] = useState<any[]>([]);
  const [working_hour_id, setWorkingHourId] = useState();
  const [work_from_home_id, setWorkFromHomeId] = useState();
  const [prefecture_id_1, setPrefectureId1] = useState();
  const [prefecture_id_2, setPrefectureId2] = useState();
  const [commuting_methods, setCommutingMethods] = useState();
  const [employment_statuses_id, setEmploymentStatusId] = useState<number[]>([]);
  const [job_category_id_1, setJobCategoryid1] = useState();
  const [job_category_id_2, setJobCategoryid2] = useState();
  const [annual_income_id, setAnnualIncomeId] = useState<number[]>([]);
  const [search_policy_id, setSearchPolicyId] = useState(null);
  const [consideration_conditions_required_exists, setConsiderationConditionsRequiredExist] = useState(false);
  const [consideration_conditions_required, setConsiderationConditionsRequired] = useState<any[]>([]);
  const [consideration_condition_supplemental, setConsiderationConditionSupplemental] = useState('');
  const navigate = useNavigate(); 
  const [face_photo, setFacePhoto] = useState(String);
  const formatDate = (dateString : any) => {
    const [year, month, day] = dateString.split('-').map((component: any) => parseInt(component));
    return `${year}年${month}月${day}日`;
  };
  
  const [address, setAddress] = useState();
  const [prefecture_name, setPrefecture_name] = useState();
  const [city_name, setCity_name] = useState();
  const [email, setEmail] = useState();
  const [mobile_number, setMobile_number] = useState();
  const [final_education_id, setFinal_education_id] = useState();
  const [handicap_qualifications, setHandicapQualifications] = useState<any[]>([]);
  const [language_id_1, setLanguageId1] = useState<any[]>([]);
  const [language_id_2, setLanguageId2] = useState<any[]>([]);
  const [language_3, setLanguage3] = useState<any[]>([]);
  const [level_id_1, setLevelId1] = useState<any[]>([]);
  const [level_id_2,setLevelId2] = useState<any[]>([]);
  const [word, setWord] = useState<any[]>([]);
  const [excel, setExcel] = useState<any[]>([]);
  const [design, setDesign] = useState<any[]>([]);
  const [program, setProgram] = useState<any[]>([]);
  const [qualification_id_1, setQualificationsId1] = useState<any[]>([]);
  const [qualification_id_2, setQualificationsId2] = useState<any[]>([]);
  const [qualification_3, setQualifications3] = useState<any[]>([]);
  const [job_category_1, setJobCategory1] = useState<any[]>([]);
  const [job_category_2, setJobCategory2] = useState<any[]>([]);
  const [job_category_3, setJobCategory3] = useState<any[]>([]);
  const [dumy, setDumy] = useState<number>();
  const [appeal_item_1, setAppeal_item_1] = useState<number>(0);
  const [appeal_item_2, setAppeal_item_2] = useState<number>(0);
  const [appeal_item_3, setAppeal_item_3] = useState<number>(0);
  const [appeal1, setAppeal1] = useState<number>(0);
  const [appeal2, setAppeal2] = useState<number>(0);
  const [appeal3, setAppeal3] = useState<number>(0);
  const [appealL, setAppealL] = useState<any[]>([]);
  const [selfPr, setSelfPr] = useState<any[]>([]);
  const [url, setUrl] = useState<string>();
  const [support_agency_exists, setSupportAgencyExists] =  useState(false);
  const [organization, setOrganization] = useState<string>();
  const [can_contact, setCanContact] = useState<boolean>();
  const [contact_number, setContactNumber] = useState<string>();
  const [end_date, setEndDate] = useState<string>();
  const [start_date, setStartDAte] = useState<string>();
  const [loadScreen, setLoadScreen] = useState(true);
  const [appeals, setAppeals] = useState<any[]>([]);
  const [appeal_1, setAppealitem1] = useState<any[]>([]); 
  const location = useLocation();
  const state = location.state;
  const { appeal, appealItems1, appealItems2, appealItems3, appealItems4, appealItems5, appealItems6, appealItems7 } = state;

  useEffect(() => {
    dispatch(fetchLoginRequest('', '', ''));
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    if (loginData.pending) {
      dispatch(fetchApplicantsRequest(loginData.login.resource.id, 'get', 0));
      dispatch(fetchRegionsRequest());
      dispatch(fetchHandicapsRequest());
      dispatch(fetchRecruitmentsDetailRequest(id));
      setAppeals(appealItems1);
      setAppeal1(Number(appeal[0]));
      setAppeal2(Number(appeal[1]));
      setAppeal3(Number(appeal[2]));
      setAppealL(appeal);
      
      setAppealitem1(appealItems1);

      setJobCategory1(appealItems2.job_category_id_1)
      setJobCategory2(appealItems2.job_category_id_2)
      setJobCategory3(appealItems2.job_category_id_3)

      setWord(appealItems3.word)
      setExcel(appealItems3.excel)
      setDesign(appealItems3.design)
      setProgram(appealItems3.program)
      setWordExperience(appealItems3.word_experience)
      setExcelExperience(appealItems3.excel_experience)

      setQualificationsId1(appealItems4.qualification_id_1)
      setQualificationsId2(appealItems4.qualification_id_2)
      setQualifications3(appealItems4.qualification_3)

      setLanguageId1(appealItems5.language_id_1)
      setLanguageId2(appealItems5.language_id_2)
      setLanguage3(appealItems5.language_3)
      setLevelId1(appealItems5.level_id_1)
      setLevelId2(appealItems5.level_id_2)
      
      setSupportAgencyExists(appealItems6.support_agency_exists)
      setOrganization(appealItems6.organization)
      setCanContact(appealItems6.can_contact)
      setContactNumber(appealItems6.contact_number)
      setStartDAte(appealItems6.start_date)
      setEndDate(appealItems6.end_date)
      setUrl(appealItems6.url)
      setSelfPr(appealItems7.content)
      setLoadScreen(false);
    }
}, [loginData.pending, dispatch]);

useEffect(() => {
  if (applicants.pending) {    
    const applicantData = applicants.applicants.applicant;
    setFamily_name(applicantData.family_name);
    setFirst_name(applicantData.first_name);
    setFamily_name_kana(applicantData.family_name_kana);
    setFirst_name_kana(applicantData.first_name_kana);
    const sourceDate = applicantData.birthday;
    const formattedDate = formatDate(sourceDate);
    setBirthday(formattedDate);
    setPostcode(applicantData.postcode);
    setAddress(applicantData.address);
    setCity_name(applicantData.city_name);
    setPrefecture_name(applicantData.prefecture_name);
    setEmail(applicantData.email);
    setMobile_number(applicantData.mobile_number);
    setFinal_education_id(applicantData.educational_background.final_education_id);
    const [year, month] = applicantData.educational_background.graduation_date.split('-');
    const formattedDate2 = `${year}年${Number(month)}月`;
    setGraduationDate(formattedDate2);
    setSupportAgency(applicantData.employment_support_agency.support_agency_exists);
    setNewGraduate(applicantData.educational_background.new_graduate);
    setCareerCountId(applicantData.job_career_count_id);
    setCompany(applicantData.job_career_recent.company);
    setIndustryId(applicantData.job_career_recent.industry_id);
    setJobCategoryid(applicantData.job_career_recent.job_category_id);
    setConsiderationConditionsRequiredExist(applicantData.consideration_conditions_required_exists);
    setConsiderationConditionsRequired(applicantData.consideration_conditions_required);
    setConsiderationConditionSupplemental(applicantData.consideration_condition_supplemental);

    const arrivalDateValue = applicantData.job_career_recent.arrival_date;
    if (arrivalDateValue) {
      const [year, month] = arrivalDateValue.split('-');
      const formattedDate = `${year}年${Number(month)}月`;
      setArrivalDate(formattedDate);
    } else {
      setArrivalDate(arrivalDateValue);
    }
    const depratureDateValue = applicantData.job_career_recent.departure_date;
    if (depratureDateValue) {
      const [year, month] = depratureDateValue.split('-');
      const formattedDate = `${year}年${Number(month)}月`;
      setDepratureDate(formattedDate);
    } else {
      setDepratureDate(depratureDateValue);
    }
    setSpecialContent(applicantData.special_content);
    setCompatibilities(applicantData.compatibilities);
    setSearchPolicyId(applicantData.preferred.search_policy_id);
    setWorkingHourId(applicantData.preferred.working_hour_id);
    setWorkFromHomeId(applicantData.preferred.work_from_home_id);
    setPrefectureId1(applicantData.preferred.prefecture_id_1);
    setPrefectureId2(applicantData.preferred.prefecture_id_2);
    setCommutingMethods(applicantData.preferred.commuting_methods);
    setEmploymentStatusId(applicantData.employment_statuses);
    setJobCategoryid1(applicantData.preferred.job_category_id_1);
    setJobCategoryid2(applicantData.preferred.job_category_id_2);
    setAnnualIncomeId(applicantData.preferred.annual_income_id);
    setHandicapQualifications(applicantData.handicap_qualifications);
    setFacePhoto(applicantData.face_photo);
    const post_data_update = {
        appeal_item: applicantData.appeal_item,
        qualification: appealItems4,
        self_pr: appealItems7,
        address: applicantData.address,
        birthday: applicantData.birthday,
        city_name: applicantData.city_name,
        city_id: applicantData.city_id,
        compatibilities: applicantData.compatibilities, 
        consideration_condition_supplemental: applicantData.consideration_condition_supplemental,
        consideration_conditions_preferred: applicantData.consideration_conditions_preferred,
        consideration_conditions_preferred_supplemental: applicantData.consideration_conditions_preferred_supplemental,
        consideration_conditions_required: applicantData.consideration_conditions_required,
        consideration_conditions_required_exists: applicantData.consideration_conditions_required_exists,
        educational_background : applicantData.educational_background,
        email: applicantData.email,
        employment_statuses: applicantData.employment_statuses,
        employment_support_agency : appealItems6,
        experience_job_category : appealItems2,
        face_photo: applicantData.face_photo,
        family_name: applicantData.family_name,
        family_name_kana: applicantData.family_name_kana,
        first_name: applicantData.first_name,
        first_name_kana: applicantData.first_name_kana,
        gender_id: applicantData.gender_id,
        handicap_qualifications: applicantData.handicap_qualifications,
        is_active: applicantData.is_active,            
        job_career_appeals: appealItems1,
        job_career_count_id: applicantData.job_career_count_id,
        job_career_recent: applicantData.job_career_recent,
        language_study: appealItems5,
        mobile_number: applicantData.mobile_number,
        navigation_book: applicantData.navigation_book,
        oa_skills: appealItems3,
        postcode: applicantData.postcode,
        prefecture_id: applicantData.prefecture_id,
        prefecture_name: applicantData.prefecture_name,
        preferred: applicantData.preferred,
        region_id: applicantData.region_id,
        special_content: applicantData.special_content
    };
    setApplicants(post_data_update);
  }
}, [applicants.pending]);
let fullAddress = ``
if(prefecture_name) {
    fullAddress += prefecture_name;
}
if(city_name) {
    fullAddress += city_name;
}
if(address) {
    fullAddress += address;
}
useEffect(() => {
    if (recruitmentsDetail.pending) {
      setCompany_name(recruitmentsDetail.recruitmentsDetail.recruitment.company);
      setJob_title(recruitmentsDetail.recruitmentsDetail.recruitment.job_title);
      setAppeal_item_1(recruitmentsDetail.recruitmentsDetail.recruitment.appeal_item.appeal_item_1.id);
      setAppeal_item_2(recruitmentsDetail.recruitmentsDetail.recruitment.appeal_item.appeal_item_2.id);
      setAppeal_item_3(recruitmentsDetail.recruitmentsDetail.recruitment.appeal_item.appeal_item_3.id);
    }
  }, [recruitmentsDetail.pending]);

const findValue = (value: any, masterName: string | number) => {
    const dataSet = regions.regions[masterName];
    if (dataSet === undefined) {
        return null;
    }
    return value ? dataSet.find((v: { id: number; }) => v.id === Number(value))?.name : '未登録';
};
const findValue2 = (value: any, masterName: string | number) => {
    const dataSet = regions.regions[masterName];
    if (dataSet === undefined) {
      return null;
    }
    return value && value !== undefined ? dataSet.find((v: { id: number; }) => v.id === Number(value))?.class_name : '未登録';
  };

  
  const findValue3 = (value: any) => {
    const dataSet = regions.regions.handicaps;
    if (dataSet === undefined) {
      return null;
    }
    return value && value !== undefined ? dataSet.find((v: { id: number; }) => v.id === Number(value))?.handicap_type_id : '未登録';
  };
  const handleBack = () => {
    window.history.back(); 
};

const formatYM = (dateString : any) => {
    
        const [year, month] = dateString != null ? dateString.split('-').map((component: any) => parseInt(component)) : "";

    if(dateString?.includes("9999")){
       return "終了月なし（継続中）"
    } else {
        return dateString
    }
    return `${year}年${month}月`;
  };

const handleSubmit = () => {

    const post_data = {
      recruitment_id: id, 
      applicant_id: loginData.login.resource.id,   
      appeal_item: {
        applicant_appeal_item_id_1: Number(appeal[0]),
        applicant_appeal_item_id_2: Number(appeal[1]),
        applicant_appeal_item_id_3: Number(appeal[2]),
        recruitment_appeal_item_id_1: appeal_item_1,
        recruitment_appeal_item_id_2: appeal_item_2,
        recruitment_appeal_item_id_3: appeal_item_3,
      },
      applicant: applicant,
    };
    
    dispatch(fetchApplicationCreateRequest(post_data));

    if (appeal_item_1 !== 0) {
        appeal.push(appeal_item_1)
    }
    if (appeal_item_2 !== 0) {
        appeal.push(appeal_item_2)
    }
    if (appeal_item_3 !== 0) {
        appeal.push(appeal_item_3)
    }

  };

  useEffect(() => {
    if (changeUpdate.pending) {
      setTimeout( () => navigate('/recruitments/' + id + '/entry/complete', {state: {appeal: appeal, appealItems1: appealItems1, 
        appealItems2: appealItems2,
        appealItems3: appealItems3,
        appealItems4: appealItems4,
        appealItems5: appealItems5,
        appealItems6: appealItems6,
        appealItems7: appealItems7}}), 1500);
    } else if(changeUpdate.error != null) {
        window.alert("既にエントリーしています。")
    }
  }, [changeUpdate]);

    
    return (
        <main id="jobs">
        {loadScreen ? <Loader /> : <></>}
        <section className="entry edit wrapper">
        <h2 className="ttl type3">エントリー（確認）</h2>
        <p>
            { company_name }「{ job_title }」の求人にエントリーいたします。<br/>
            内容を確認の上、送信するを押してください。
        </p>
        <div className="entry_cont" style={{width: "100%"}}>
            <div className="cont1">
                <div>
                    <div className="ttl_box">
                        <h3 className="ttl">企業に伝えたい項目</h3>
                    </div>
                    <dl>
                        {(appeal1 === 1 || appeal2 === 1 || appeal3 === 1) &&
                        <><dt>アピールしたい職歴</dt><dd><div data-v-2bbda8fc="">
                            {appeal_1 && appeal_1.map((item) => 
                                item.company !== null && item.company !== undefined ? (
                                    <p key={item.id}>
                                    {item.company}
                                    {findValue(item.industry_id, 'industries')}<br />
                                    {findValue(item.job_category_id, 'job_categories')}<br />
                                    {formatYM(item.arrival_date)}&nbsp;〜&nbsp;
                                    {formatYM(item.departure_date) || '現在' }<br />
                                    </p>
                                ) : null
                                )}
                                </div></dd></>
                        }
                        {(appeal1 === 2 || appeal2 === 2 || appeal3 === 2) &&
                        <><dt>アピールしたい経験職種</dt><dd><div data-v-2bbda8fc="">
                                    </div><div data-v-2bbda8fc="">
                                    {/* {appeal_2 && appeal_2.map((item) =>  */}
                                            <><span data-v-2bbda8fc="">{findValue(job_category_1, 'job_categories')} </span>
                                            <span data-v-2bbda8fc="">{findValue(job_category_2, 'job_categories')} </span>
                                            <span data-v-2bbda8fc="">{findValue(job_category_3, 'job_categories')} </span></>
                                    {/* )} */}
                                        </div></dd></>
                        }
                        {(appeal1 === 3 || appeal2 === 3 || appeal3 === 3) &&
                        <><dt>アピールしたいPCスキル</dt><dd><div data-v-2bbda8fc="">
                                        Wordスキル {word_experience ? '有' : '無'}<br data-v-2bbda8fc="" />
                                        <div data-v-2bbda8fc="">
                                            <div data-v-2bbda8fc="" role="dialog" tabIndex={0} className="preview">
                                                <div className="txt_cont">
                                                    <p><span>{word.map((id, index) => (
                                                        <React.Fragment key={id}>
                                                            {index > 0 && ' / '}
                                                            {findValue(id, 'oa_skills')}
                                                        </React.Fragment>
                                                    ))}
                                                    </span></p>
                                                </div>
                                            </div>
                                        </div>
                                        Excelスキル {excel_experience ? '有' : '無'}<br data-v-2bbda8fc="" />
                                        <div data-v-2bbda8fc="">
                                            <div data-v-2bbda8fc="" role="dialog" tabIndex={0} className="preview">
                                                <div className="txt_cont">
                                                    <p><span>{excel.map((id, index) => (
                                                        <React.Fragment key={id}>
                                                            {index > 0 && ' / '}
                                                            {findValue(id, 'oa_skills')}
                                                        </React.Fragment>
                                                    ))}
                                                    </span></p>
                                                </div>
                                            </div>
                                        </div>
                                        {design.length != 0 && <>
                                            デザイン系・その他事務系スキル<br data-v-2bbda8fc="" /><div data-v-2bbda8fc="">
                                                <div data-v-2bbda8fc="" role="dialog" tabIndex={0} className="preview">
                                                    <div className="txt_cont">
                                                        <p><span>{design.map((id, index) => (
                                                            <React.Fragment key={id}>
                                                                {index > 0 && ' / '}
                                                                {findValue(id, 'oa_skills')}
                                                            </React.Fragment>
                                                        ))}
                                                        </span></p>
                                                    </div>
                                                </div>
                                            </div></>}
                                        {program.length != 0 && <>
                                            プログラミング系<br data-v-2bbda8fc="" /><div data-v-2bbda8fc="">
                                                <div data-v-2bbda8fc="" role="dialog" tabIndex={0} className="preview">
                                                    <div className="txt_cont">
                                                        <p><span>{program.map((id, index) => (
                                                            <React.Fragment key={id}>
                                                                {index > 0 && ' / '}
                                                                {findValue(id, 'oa_skills')}
                                                            </React.Fragment>
                                                        ))}
                                                        </span></p>
                                                    </div>
                                                </div>
                                            </div></>}
                                    </div></dd></>
                        }
                        {(appeal1 === 4 || appeal2 === 4 || appeal3 === 4) &&
                        <><dt>アピールしたい資格</dt><dd><div data-v-2bbda8fc="">
                                        <span data-v-2bbda8fc="">{findValue(qualification_id_1, 'qualifications') || '未登録'}  </span>
                                        <span data-v-2bbda8fc="">{findValue(qualification_id_2, 'qualifications') || '未登録'}  </span>
                                        <span data-v-2bbda8fc="">{qualification_3}</span>
                                    </div></dd></>
                        }
                        {(appeal1 === 5 || appeal2 === 5 || appeal3 === 5) &&
                        <><dt>アピールしたい語学</dt><dd><div data-v-2bbda8fc="">
                                        <span data-v-2bbda8fc="">{findValue(language_id_1, 'languages')}, {findValue(level_id_1, 'levels')}  </span>
                                        <span data-v-2bbda8fc="">{findValue(language_id_2, 'languages')}, {findValue(level_id_2, 'levels')}  </span>
                                        <span data-v-2bbda8fc="">{language_3}</span>
                                    </div></dd></>
                        }
                        {(appeal1 === 6 || appeal2 === 6 || appeal3 === 6) &&
                        <><dt>就労支援機関</dt><dd><div data-v-2bbda8fc="">
                                        <span data-v-2bbda8fc="">就労支援機関の利用：  {support_agency_exists ? '有' : '無'}</span><br data-v-2bbda8fc="" />
                                        <span data-v-2bbda8fc="">{!support_agency_exists ? null : "機関・企業名："} {!support_agency_exists ? "" : organization}</span><br data-v-2bbda8fc="" />
                                        <span data-v-2bbda8fc="">{!support_agency_exists ? null : "連絡先："}{!support_agency_exists ? "" : contact_number}</span><br data-v-2bbda8fc="" />
                                        <span data-v-2bbda8fc="">{!support_agency_exists ? null : "URL："}{!support_agency_exists ? "" : url}</span><br data-v-2bbda8fc="" />
                                        <span data-v-2bbda8fc="">{!support_agency_exists ? null : `エントリー企業との連携可否：${can_contact ? '可' : '否'}`}</span><br data-v-2bbda8fc="" />
                                        <span data-v-2bbda8fc="">{!support_agency_exists ? null : start_date !== undefined && start_date !== null && formatYM(start_date)} {!support_agency_exists ? "" : "〜"} {!support_agency_exists ? "" : end_date !== undefined && end_date !== null && formatYM(end_date)}<br data-v-2bbda8fc="" /> <div data-v-2bbda8fc=""></div></span>
                                        <span data-v-2bbda8fc="" className="wrap"></span>
                                    </div></dd></>
                        }   
                        {(appeal1 === 7 || appeal2 === 7 || appeal3 === 7) &&
                        <><dt>自己PR</dt><dd><div data-v-2bbda8fc="">
                                        <span data-v-2bbda8fc="">{selfPr}</span><br data-v-2bbda8fc="" />
                                    </div></dd></>
                        }
                        {appealL.length === 1 || appealL.length === 0 &&
                        <><dt>未登録</dt><dd>
                                        <div data-v-2bbda8fc=""> 未入力
                                        </div>
                                    </dd></>
                        }
                        {appealL.length === 1 || appealL.length === 0 &&
                        <><dt>未登録</dt><dd>
                                        <div data-v-2bbda8fc=""> 未入力
                                        </div>
                                    </dd></>
                        }
                        {appealL.length === 2 || appealL.length === 0 &&
                        <><dt>未登録</dt><dd>
                                        <div data-v-2bbda8fc=""> 未入力
                                        </div>
                                    </dd></>
                        }
                    </dl>
                </div>
                <div>
                    <div>
                        <div className="ttl_box">
                        <h3 className="ttl">採用担当者が知りたい項目</h3><small style={{ color: "gray", width: "100%" }}>※記入すると選考通過率UP</small>
                        </div>
                        <dl>
                        {(appeal_item_1 === 1 || appeal_item_2 === 1 || appeal_item_3 === 1) &&
                        <><dt>アピールしたい職歴</dt><dd><div data-v-2bbda8fc="">
                            {appeal_1 && appeal_1.map((item) => 
                                item.company !== null && item.company !== undefined ? (
                                    <p key={item.id}>
                                    {item.company} / {findValue(item.industry_id, 'industries')}<br />
                                    {findValue(item.job_category_id, 'job_categories')}<br />
                                    {formatYM(item.arrival_date)}&nbsp;〜&nbsp;
                                    {formatYM(item.departure_date) || '現在'}<br />
                                    </p>
                                ) : null
                                )}
                                </div></dd></>
                        }
                        {/* {(appeal_item_1 === 1 || appeal_item_2 === 1 || appeal_item_3 === 1) &&
                        <><dt>アピールしたい職歴</dt><dd>
                                            <div data-v-2bbda8fc="">
                                            {company !== null && company !== undefined ? ( <p>
                                                {company} 
                                                {appealItems1.industry_id}<br data-v-2bbda8fc=""/>
                                                {appealItems1.job_category_id}<br data-v-2bbda8fc=""/>
                                                {arrival_date}&nbsp;〜&nbsp;
                                                {formatYM(deprature_date)}<br data-v-2bbda8fc=""/>
                                            </p>) : ('')
                                            }
                                            </div>
                                        </dd></>
                        } */}
                        {(appeal_item_1 === 2 || appeal_item_2 === 2 || appeal_item_3 === 2) &&
                        <><dt>アピールしたい経験職種</dt><dd>
                                            <div data-v-2bbda8fc="">
                                                <span data-v-2bbda8fc="">{findValue(job_category_1, 'job_categories')} </span> 
                                                <span data-v-2bbda8fc="">{findValue(job_category_2, 'job_categories')} </span> 
                                                <span data-v-2bbda8fc="">{findValue(job_category_3, 'job_categories')} </span>
                                            </div>
                                        </dd></>
                        }
                        {(appeal_item_1 === 6 || appeal_item_2 === 6 || appeal_item_3 === 6) &&
                        <><dt>就労支援機関</dt><dd>
                                            <div data-v-2bbda8fc="">
                                            <span data-v-2bbda8fc="">就労支援機関の利用：  {support_agency_exists ? '有':'無'}</span><br data-v-2bbda8fc=""/> 
                                            <span data-v-2bbda8fc="">就労支援機関： </span><br data-v-2bbda8fc=""/> 
                                            <span data-v-2bbda8fc="">連絡先：{contact_number}</span><br data-v-2bbda8fc=""/> 
                                            <span data-v-2bbda8fc="">URL：{url}</span><br data-v-2bbda8fc=""/> 
                                            <span data-v-2bbda8fc="">エントリー企業との連携可否：{can_contact ? '可':'否'}</span><br data-v-2bbda8fc=""/> 
                                            <span data-v-2bbda8fc="">{start_date !== undefined && start_date !== null && formatYM(start_date)} 〜 {end_date !== undefined && end_date !== null &&  formatYM(end_date)}<br data-v-2bbda8fc=""/> <div data-v-2bbda8fc=""></div></span> 
                                            <span data-v-2bbda8fc="" className="wrap"></span>
                                            </div>
                                        </dd></>
                        }
                        {(appeal_item_1 === 8 || appeal_item_2 === 7 || appeal_item_3 === 7) &&
                        <><dt>自己PR</dt><dd>
                                            <div data-v-2bbda8fc="">
                                            <span data-v-2bbda8fc="">{selfPr}</span><br data-v-2bbda8fc=""/>
                                            </div>
                                        </dd></>
                        }
                        {(appeal_item_1 === 5 || appeal_item_2 === 5 || appeal_item_3 === 5) &&
                        <><dt>アピールしたい語学</dt><dd>
                                            <div data-v-2bbda8fc="">
                                                <span data-v-2bbda8fc="">{findValue(language_id_1, 'languages')}, {findValue(level_id_1, 'levels')}  </span>
                                                <span data-v-2bbda8fc="">{findValue(language_id_2, 'languages')}, {findValue(level_id_2, 'levels')}  </span>
                                                <span data-v-2bbda8fc="">{language_3}</span>
                                            </div>
                                        </dd></>
                        }
                        {(appeal_item_1 === 3 || appeal_item_2 === 3 || appeal_item_3 === 3) &&
                        <><dt>アピールしたいPCスキル</dt><dd>
                                            <div data-v-2bbda8fc="">
                                                Wordスキル {word_experience ? '有' : '無'}<br data-v-2bbda8fc="" />
                                                <div data-v-2bbda8fc="">
                                                    <div data-v-2bbda8fc="" role="dialog" tabIndex={0} className="preview">
                                                        <div className="txt_cont">
                                                            <p><span>{word.map((id, index) => (
                                                                <React.Fragment key={id}>
                                                                    {index > 0 && ' / '}
                                                                    {findValue(id, 'oa_skills')}
                                                                </React.Fragment>
                                                            ))}
                                                            </span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                Excelスキル {excel_experience ? '有' : '無'}<br data-v-2bbda8fc="" />
                                                <div data-v-2bbda8fc="">
                                                    <div data-v-2bbda8fc="" role="dialog" tabIndex={0} className="preview">
                                                        <div className="txt_cont">
                                                            <p><span>{excel.map((id, index) => (
                                                                <React.Fragment key={id}>
                                                                    {index > 0 && ' / '}
                                                                    {findValue(id, 'oa_skills')}
                                                                </React.Fragment>
                                                            ))}
                                                            </span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {design.length != 0 && <>
                                                    デザイン系・その他事務系スキル<br data-v-2bbda8fc="" /><div data-v-2bbda8fc="">
                                                        <div data-v-2bbda8fc="" role="dialog" tabIndex={0} className="preview">
                                                            <div className="txt_cont">
                                                                <p><span>{design.map((id, index) => (
                                                                    <React.Fragment key={id}>
                                                                        {index > 0 && ' / '}
                                                                        {findValue(id, 'oa_skills')}
                                                                    </React.Fragment>
                                                                ))}
                                                                </span></p>
                                                            </div>
                                                        </div>
                                                    </div></>}
                                                {program.length != 0 && <>
                                                    プログラミング系<br data-v-2bbda8fc="" /><div data-v-2bbda8fc="">
                                                        <div data-v-2bbda8fc="" role="dialog" tabIndex={0} className="preview">
                                                            <div className="txt_cont">
                                                                <p><span>{program.map((id, index) => (
                                                                    <React.Fragment key={id}>
                                                                        {index > 0 && ' / '}
                                                                        {findValue(id, 'oa_skills')}
                                                                    </React.Fragment>
                                                                ))}
                                                                </span></p>
                                                            </div>
                                                        </div>
                                                    </div></>}
                                            </div>
                                        </dd></>
                        }
                        {(appeal_item_1 === 4 || appeal_item_2 === 4 || appeal_item_3 === 4) &&
                        <><dt>アピールしたい資格</dt><dd>
                                            <div data-v-2bbda8fc="">
                                                <span data-v-2bbda8fc="">{findValue(qualification_id_1, 'qualifications') || '未登録'}  </span>
                                                <span data-v-2bbda8fc="">{findValue(qualification_id_2, 'qualifications') || '未登録'}  </span>
                                                <span data-v-2bbda8fc="">{qualification_3}</span>
                                            </div>
                                        </dd></>
                        }
                        </dl>
                    </div>
                    </div>
                </div>
                <div className="cont2">
                <div className="ttl_box">
                <h3 className="ttl">基本情報</h3>
                </div>
                <dl>
                <dt>顔写真</dt>
                <dd>
                    <div className=""><img style={{width: "160px"}} src={face_photo} alt="face_20220114130144_mh0tUNU4X0JlKAp96Ry5qKvtQ" width="200px"/></div>
                </dd>
                <dt>氏名</dt>
                <dd>{ family_name } { first_name }</dd>
                <dt>氏名（フリガナ）</dt>
                <dd>{ family_name_kana } { first_name_kana }</dd>
                <dt>生年月日</dt>
                <dd>{ birthday }</dd>
                <dt>障がいの基本情報</dt>
                <dd>
                      {handicap_qualifications.map((handicap, index) => (
                        <><><a tabIndex={0} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                              <div className="txt_cont" key={index}>
                                  <p>
                                    {findValue(findValue3(handicap.handicap_id), 'handicap_types')}&nbsp; {findValue(handicap.handicap_id, 'handicaps')} <br />
                                    {handicap.disability_certificate_status_id === 1 &&  '障がい手帳あり'}{handicap.disability_certificate_status_id === 2 &&  '障がい手帳なし'}{handicap.disability_certificate_status_id === 3 && '障がい手帳申請中'}  {findValue(handicap.disability_certificate_status_id, 'disability_certificate_statuses')}<br />
                                    {findValue(handicap.disability_certificate_id, 'disability_certificates')}　{findValue2(handicap.disability_certificate_id, 'disability_certificates')}
                                  </p>
                              </div>
                          </a>
                          {handicap.process !== null && handicap.process.length !== 0 &&
                            <div className="textarea_cont">
                                <h4 className="acc_h4 fixed">
                                    <a href="" className="not_active">発症経緯と経過
                                        <span className="any">任意</span>
                                    </a>
                                </h4> 
                                <p style={{wordWrap: "break-word"}}>{handicap.process}</p>
                            </div>
                          }</>
                          {handicap.characteristics_and_countermeasures !== null && handicap.characteristics_and_countermeasures.length !== 0 &&
                            <div className="textarea_cont">
                                <h4 className="acc_h4 fixed">
                                    <a href="" className="not_active">障がい特性と対策
                                        <span className="any">任意</span>
                                    </a>
                                </h4> 
                                <p style={{wordWrap: "break-word"}}>{handicap.characteristics_and_countermeasures}</p>
                            </div> 
                          }</>    
                      ))}
                  </dd>
                <dt>就労上必須の配慮事項</dt>
                <dd className="">
                  <div>
                    {!consideration_conditions_required_exists ? 
                    <span style={{color: "black"}}>無</span>
                    :
                    <><div className="preview">
                        <div className="txt_cont" style={{borderBottom: "1px #adadad solid", marginBottom:"15px", paddingBottom: "15px"}}>
                          <p><span>{consideration_conditions_required.map((id, index) => (
                              <React.Fragment key={id}>
                                {index > 0 && ' / '}
                                {findValue(id, 'consideration_conditions')}
                              </React.Fragment>
                            ))}</span></p>
                        </div>
                      </div><div className="textarea_cont">
                          <h4 className="acc_h4 fixed"><a className="not_active">配慮事項（その他）<span className="any">任意</span></a></h4>
                          <p>{consideration_condition_supplemental}</p>
                        </div></>
                      }
                    </div>
                </dd>
                <dt>住所</dt>
                <dd>
                    〒 { postcode }<br/>
                    { fullAddress }<br/>
                    <span>※面接が確定次第、企業へ公開されます</span>
                </dd>
                <dt>メールアドレス</dt>
                <dd>{ email }<br/>
                <span>※面接が確定次第、企業へ公開されます</span></dd>
                <dt>電話番号</dt>
                <dd>{ mobile_number }<br/>
                <span>※面接が確定次第、企業へ公開されます</span></dd>
                <dt>最終学歴</dt>
                <dd>{findValue(final_education_id, 'final_educations')}</dd>
                <dt>卒業・卒業見込み年度</dt>
                <dd>{ graduation_date }</dd>
                <dt>就労支援機関の有無</dt>
                <dd>{support_agency ? '有' : '無'}</dd>
                <dt>就職カテゴリー</dt>
                <dd>{new_graduate ? '新卒' : '中途'}</dd>
                <dt>直近の職歴</dt>
                <dd>{career_count_id === 1 ? '職歴なし' : 
                    <p data-v-0c619b69="">
                        {company} / {findValue(industry_id, 'industries')} <br data-v-0c619b69=""/>
                        {findValue(job_category_id, 'job_categories')}<br data-v-0c619b69=""/>
                        {arrival_date}&nbsp;〜&nbsp;{deprature_date?.includes("9999") ? "終了月なし（継続中）" : deprature_date}
                    </p>
                }</dd>
                <dt>特記事項</dt>
                <dd>{special_content}</dd>
                </dl>
            </div>
            <div className="cont2">
                <div className="ttl_box">
                <h3 className="ttl">希望条件</h3>
                </div>
                <dl>
                <dt>仕事の探し方<span className="any">任意</span></dt>
                        <dd>{findValue(search_policy_id, 'search_policies')}</dd>
                <dt>働き方<span className="any">任意</span></dt>
                        <dd>{compatibilities.map((id, index) => (
                                    <React.Fragment key={id}>
                                      {index > 0 && ' / '}
                                      {findValue(id, 'work_styles')}
                                    </React.Fragment>
                                  ))}</dd>
                <dt>就労時間</dt>
                <dd>{findValue(working_hour_id, 'working_hours')}</dd>
                <dt>在宅・リモートワーク希望</dt>
                <dd>{findValue(work_from_home_id, 'work_from_home')}</dd>
                <dt>希望勤務地</dt>
                <dd>
                    <ul>
                    <li>{findValue(prefecture_id_1, 'prefectures')}</li>
                    <li>{findValue(prefecture_id_2, 'prefectures')}</li>
                    </ul>
                </dd>
                <dt>通勤手段</dt>
                <dd>
                    <div role="dialog" tabIndex={0} className="preview">
                        <div className="txt_cont">
                            <a>{findValue(commuting_methods, 'commuting_methods')}</a>
                        </div>
                    </div>
                </dd>
                <dt>希望する雇用形態</dt>
                <dd>
                    <div role="dialog" tabIndex={0} className="preview">
                    <div className="txt_cont">
                        <dd>
                        {employment_statuses_id.map((id, index) => (
                            <React.Fragment key={id}>
                            {index > 0 && ' / '}
                            {findValue(id, 'employment_statuses')}
                            </React.Fragment>
                        ))}
                        </dd>
                    </div>
                    </div>
                </dd>
                <dt>希望年収</dt>
                <dd>{findValue(annual_income_id, 'annual_incomes')}</dd>
                <dt>希望職種</dt>
                <dd>
                    <ul>
                    <li className="display: none;">{findValue(job_category_id_1, 'job_categories')}</li>
                    <li className="display: none;">{findValue(job_category_id_2, 'job_categories')}</li>
                    </ul>
                </dd>
                </dl>
            </div>
            <div className="btn_box cont2">
                <a className="btn type3 back" onClick={handleBack} >戻る</a> <a className="btn type3 submit" onClick={handleSubmit}>送信する<span>＞</span></a>
            </div> 
            </div>
        </section>
        </main>
    );
    
  }
  export default RecruimentsConfirm;